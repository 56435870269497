import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Link, Route, Switch
} from "react-router-dom";
import '../../login/signup.css'
import { ReactComponent as Tick } from '../../../assets/svg/lgcheck.svg';
import PhoneNumberValidate from '../../login/PhoneNumberValidate';
import PasswordStrength from '../../smallcomponents/passwordstrengthbar/PasswordStrength';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import UsernameValidate from '../../login/UsernameValidate';
import Api from '../../../app/api';
import { loginSuccess, loginFailed, loginRequest } from '../../login/store/loginSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { CircularProgress } from '@material-ui/core';
import Auth from '../../../app/auth';
import { Transition } from 'react-transition-group';
import { browserName, browserVersion } from 'react-device-detect';



const Newpersonalprofilesignup = () => {
    const history = useHistory()
    const dispatch = useAppDispatch();
    const { loading, error } = useAppSelector(state => state.login)
    const { os,osversion,browsername,uuid,lat,lng,city,country } = useAppSelector(state => state.profile)
    const [checked, setchecked] = useState(false)

    const [stage, setstage] = useState(1)

    const [password, setpassword] = useState('')
    const [signupvalidate, setsignupvalidate] = useState('')
    const sc: number | null = null
    const [score, setscore] = useState<any>(0)
    const [otp, setotp] = useState("");
    const [otpvalidate, setotpvalidate] = useState("required");

    // stage 1
    const [firstname, setfirstname] = useState('')
    const [surname, setsurname] = useState('')
    const s: any = new Date()
    const [signupdob, setSignupdob] = useState(s);

    // stage 2
    const [signupusername, setSignupusername] = useState("");
    const [usernamevalidate, setusernamevalidate] = useState("required");

    const [signupemail, setSignupemail] = useState("");
    let dg: any = null
    const [signupmailvalidate, setsignupmailvalidate] = useState(dg);
    function validateEmail(email: any) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    // stage 3
    const [phoneNumber, setPhoneNumber] = useState('')
    const [countrycode, setcountrycode] = useState('+61')
    const [valid, setValid] = useState(false)
    const [otploading, setotploading] = useState(false)

    //stage 4
    const [sloading, setsloading] = useState(false)
    let er: any = null
    const [otpsenderror, setotpsenterror] = useState(er)
    const success = (data: any) => {
        setotploading(false)
        setotpsenterror(null)
        setstage(4)
    }
    const failed = (data: any) => {
        setotploading(false)
        const {
            status, text, dev_data, app_data,
        } = data;

        let head;
        let message;
        if (status === 404) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            if (status === 400) {
                if (app_data === 'Phone number already exist') {
                    head = "The phonenumber is already in use.";
                    message = 'please try using another number.'
                } else {
                    head = "Something's not right.";
                    message = 'please try refreshing the page.'
                }
            } else {
                head = "Something's not right.";
                message = 'please try refreshing the page.'
            }

        }

        setotpsenterror({
            head: head,
            message: message
        })
        setstage(3)
        // dispatch(profileFailed(data))
    }
    function OTPsend(number: any) {
        let data = {
            phone: '+' + number
        }
        setotploading(true)
        Api('post', '/api/v1/user/verify/phone/', data, success, failed, history, false, true)
    }
    const success1 = (data: any) => {
        let response = data.data.data
        response['rememberme'] = `${true}`
        let unparseddetails: any = localStorage.getItem('pdetails')

        if(data.device){
            localStorage.setItem('device_id',response.device.id)
            localStorage.setItem('uuid',response.device.unique_id)
        }

        if(unparseddetails){
            let parseddetails = JSON.parse(unparseddetails)
            let newdetails: any = [response].concat(parseddetails)
            if(newdetails.length > 5){
                newdetails = newdetails.filter((itm: any,key: number)=>key < 5)
            }
            localStorage.setItem('pdetails',JSON.stringify(newdetails))
        }else{
            let newdetails: any = [response]
            localStorage.setItem('pdetails',JSON.stringify(newdetails))
        }
        localStorage.setItem(`mtusrtype`, 'personal')
        localStorage.setItem(`mtusr`, JSON.stringify(response))


        Auth('login', response.access, response.refresh);
        setotpsenterror(null);

        dispatch(loginSuccess(data))


    }
    const failed1 = (data: any) => {
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === 404) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try refreshing the page.'
        }

        setotpsenterror({
            head: head,
            message: message
        })
        dispatch(loginFailed({
            status: status,
            text: text,
            dev_data: dev_data,
            app_data: app_data,
            head: head,
            message: message,
        }));
    }
    function executeSignup() {

        let cdcode = '+' + countrycode
        let slice1 = countrycode.length
        let slice2 = phoneNumber.length
        let phn = phoneNumber.slice(slice1, slice2)
        let dt = dayjs(signupdob).format("YYYY-MM-DD")
        // let data = {
        //     "firstname": firstname,
        //     "surname": surname,
        //     "username": signupusername.trim(),
        //     "email": signupemail,
        //     "dob": dt,
        //     "password": password.trim(),
        //     "country_code": cdcode,
        //     "phone": phn,
        //     "otp": otp
        // }
        let notif = localStorage.getItem('notification_token0')
        let deviceid = localStorage.getItem('device_id')
        let uid0 = localStorage.getItem('uuid')
        let postdata = {
            "firstname": firstname,
            "surname": surname,
            "username": signupusername.trim(),
            "email": signupemail,
            "dob": dt,
            "password": password.trim(),
            "country_code": cdcode,
            "phone": phn,
            "otp": otp,
            "device_id": deviceid? deviceid: null,
            "name": browserName+browserVersion,
            "unique_id": uid0?uid0:uuid,
            "latitude": lat,
            "longitude": lng,
            "city": city,
            "country": country,
            "notification_token": notif ? notif : '',
            "installed_app_version": '',
            "os": os,
            "os_version": osversion
        }
        console.log(postdata)
        dispatch(loginRequest())
        Api('post', '/api/v1/user/signup/', postdata, success1, failed1, history, false, true)
    }
    let db: any = null
    const [dobvalidate, setdobvalidate] = useState(db)
    function validatedob(date: any) {
        let currentyear = dayjs(new Date()).format("YYYY")
        let dobyear = dayjs(date).format("YYYY")
        if ((JSON.parse(currentyear) - JSON.parse(dobyear)) > 10) {
            return true
        } else {
            setdobvalidate('You should be more than 10 years old')
            setTimeout(() => {
                setdobvalidate(null)
            }, 3000);
            return false
        }

    }
  return (
    <div className='sgcontainer' style={{ minWidth: '512px', maxWidth: '512px' }}>
    <div className='sghead'>
        <h1> {(stage === 3 || stage === 4) ? 'OTP Verification' : 'Sign up'}</h1>
        <p className='sgmsg'>{'Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events.'}</p>
    </div>
    <div className='multi' style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr' }}>
        
            {
                stage === 2 ? (
                    <Transition in={stage === 2 ? true : false} timeout={500}>
                        {state => (
                            <div className='form sg2'>
                                <UsernameValidate signupusername={signupusername} setSignupusername={setSignupusername}
                                    usernamevalidate={usernamevalidate} setusernamevalidate={setusernamevalidate} />
                                <label className='sglabel'>Your email address
                                    <small className='validateerror signupmailvalidate'>{signupemail !== '' ? signupmailvalidate : null}</small>
                                </label>
                                <input id='mail' type='email' placeholder='you@mutualevents.com'
                                    required
                                    value={signupemail}
                                    onChange={(txt) => {
                                        setSignupemail(txt.target.value);
                                        console.log("chng");
                                        let target: any = txt.currentTarget
                                        if (target.value !== null) {
                                            if (target.previousSibling) {
                                                if (target.previousSibling.lastChild) {
                                                    if (target.previousSibling.lastChild.classList.contains(
                                                        "validateerror"
                                                    )
                                                    ) {
                                                        target.previousSibling.lastChild.classList.remove(
                                                            "validateerror"
                                                        );
                                                    }
                                                }

                                            }

                                        }
                                        const s = document.querySelector(".signupmailvalidate");
                                        console.log(s);
                                        if (validateEmail(txt.target.value) === true) {
                                            console.log(validateEmail(txt.target.value));
                                            console.log("y");
                                            setsignupmailvalidate(null)
                                            if (s) {
                                                if (s.classList.contains("validatewarning") === true) {
                                                    s.classList.remove("validatewarning");
                                                }
                                            }

                                            console.log("yes");
                                        } else {
                                            if (s) {
                                                setsignupmailvalidate("please enter a valid mail id");
                                                s.classList.add("validatewarning");
                                            }

                                        }
                                    }}
                                ></input>
                                <PasswordStrength password={password} setpassword={setpassword} type={'Password'} setsignupvalidate={setsignupvalidate} signupvalidate={signupvalidate} setscore={setscore} />

                                <div className="sglegal">
                                    <div className={checked ? 'lgbox checked' : 'lgbox'} onClick={e => {
                                        setchecked(!checked)
                                    }}>{checked && <Tick />}</div>
                                    <p>By creating a account you agree to the
                                        <Link to='/terms' >Terms of use</Link>{" "}
                                        and our
                                        <Link to='/privacypolicy'>Privacy Policy</Link>
                                    </p>
                                </div>

                                <button type='submit'
                                    className={(signupusername !== '' && usernamevalidate !== 'username already exist' && checked && validateEmail(signupemail) && password !== '' && score > 1) ? 'lgbtn' : 'sgbtn'} style={{ marginTop: '20px' }}
                                    onClick={() => {
                                        // history.push('/signup',3)
                                        console.log({
                                            "firstname": firstname,
                                            "surname": surname,
                                            "username": signupusername.trim(),
                                            "email": signupemail,
                                            
                                            "password": password.trim(),
                                            
                                            "otp": otp
                                        })
                                        if (signupusername !== '' && usernamevalidate !== 'username already exist' && checked && validateEmail(signupemail) && password !== '' && score > 1) {
                                            setstage(3)
                                        }
                                    }}
                                >
                                    Continue
                                </button>
                            </div>
                        )}
                    </Transition>

                ) : (stage === 3 || stage === 4) ? (
                    <>
                        {(otpsenderror && !(Object.keys(otpsenderror).length === 0)) && <div className='errorMessage'>
                            <p>{otpsenderror.head + ' ' + otpsenderror.message}</p>
                            {/* <p>{error.message}</p> */}
                        </div>}
                        <div className='form sg3' style={{ gap: '15px' }} onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                if (stage === 3) {
                                    console.log(3)
                                    OTPsend(phoneNumber)
                                } else {
                                    console.log(4)
                                    executeSignup()
                                }
                            }
                        }}>
                            <PhoneNumberValidate value={phoneNumber} setValue={setPhoneNumber} valid={valid} setValid={setValid} code={countrycode} setCode={setcountrycode} />
                            {stage === 4 && <input
                                type="tel"
                                placeholder="XXXXXX"
                                className="credentials otp"
                                required
                                value={otp}
                                onChange={(e) => {
                                    setotp(e.currentTarget.value);
                                }}
                            ></input>}
                            {stage === 4 && <div style={{ display: 'flex', justifyContent: 'flex-end' }}><small></small><small style={{ color: 'var(--cl_orange', cursor: 'pointer' }}
                                onClick={() => { OTPsend(phoneNumber) }}
                            >Resend</small></div>}
                            <button className="lgbtn"
                                onClick={() => {
                                    if (stage === 3) {
                                        console.log(3)
                                        OTPsend(phoneNumber)
                                        // history.push('/signup',4)
                                        console.log({
                                            "firstname": firstname,
                                            "surname": surname,
                                            "username": signupusername.trim(),
                                            "email": signupemail,
                                            
                                            "password": password.trim(),
                                            
                                            "otp": otp
                                        })
                                        setstage(4)
                                    } else {
                                        console.log(4)
                                        executeSignup()
                                    }
                                }}
                            >
                                {stage === 3 ? (otploading ? (
                                    <div style={{ color: 'white' }}>
                                        <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />

                                    </div>
                                ) : 'Send')
                                    : ((loading) ?
                                        (
                                            <div style={{ color: 'white' }}>
                                                <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />

                                            </div>
                                        ) : 'Signup')}</button>
                        </div>
                    </>
                ) : (
                    <div className='form sg1' onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            if (firstname !== '' && surname !== '' && dayjs(signupdob).format("YYYY-MM-DD") !== dayjs(new Date()).format("YYYY-MM-DD")) {
                                if (validatedob(signupdob)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setstage(2)
                                }

                            }
                        }
                    }}>
                        {/* <div className='sginput'> */}
                        <label className='sglabel'>First name
                            {/* <small className='validateerror'>validate</small> */}
                        </label>
                        <input type='text' placeholder='First name' required value={firstname} onChange={e => { setfirstname(e.currentTarget.value) }}></input>
                        <label className='sglabel'>Surname
                            {/* <small className='validateerror'>validate</small> */}
                        </label>
                        <input type='text' placeholder='Surname' required value={surname} onChange={e => { setsurname(e.currentTarget.value) }}></input>
                        <label className='sglabel'>Date of Birth
                            {dobvalidate && <small className='validateerror'>{dobvalidate}</small>}
                        </label>

                        <DatePicker selected={signupdob} dateFormat="dd/MM/yyyy" onChange={(date: any) => {
                            // console.log(date)
                            // console.log(dayjs(date).format("YYYY-MM-DD"))
                            setSignupdob(date)
                            validatedob(date)
                        }} 
                        
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (firstname !== '' && surname !== '' && dayjs(signupdob).format("YYYY-MM-DD") !== dayjs(new Date()).format("YYYY-MM-DD")) {
                                    if (validatedob(signupdob)) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setstage(2)
                                    }

                                }
                            }
                        }} />

                        <button type='submit'
                            className={(firstname !== '' && surname !== '' && dayjs(signupdob).format("YYYY-MM-DD") !== dayjs(new Date()).format("YYYY-MM-DD")) ? 'lgbtn' : 'sgbtn'}
                            style={{ marginTop: '20px' }}
                            onClick={() => {
                                
                                console.log({
                                    "firstname": firstname,
                                    "surname": surname,
                                    "username": signupusername.trim(),
                                    "email": signupemail,
                                    
                                    "password": password.trim(),
                                    
                                    "otp": otp
                                })
                                // console.log(JSON.parse(localStorage.getItem('res')))
                                if (firstname !== '' && surname !== '' && dayjs(signupdob).format("YYYY-MM-DD") !== dayjs(new Date()).format("YYYY-MM-DD")) {
                                    if (validatedob(signupdob)) {
                                        setstage(2)
                                        // history.push('/signup',2)
                                    }

                                }

                            }}
                        >
                            Continue
                        </button>

                    </div>
                )
            }
        

    </div>
    <div className="signInDiv">
        <p>Already have an account? </p>
        <Link to="/login">Login</Link>
    </div>
</div>
  );
};

export default Newpersonalprofilesignup;
