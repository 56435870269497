import React from 'react'

const FeaturedProfileLoader = () => {
    return (
        <div className='featuredprofile featuredprofileloader skeleton'>
            {/* <img src={dp}></img>
            <div className='featuredNames'>
                <h3>{firstname + ' ' + surname}</h3>
                <span>{username}</span>
            </div>

            */}
        </div>
    )
}

export default FeaturedProfileLoader
