import React, { useState } from 'react';
// import { AiOutlineShareAlt } from 'react-icons/ai'
// import { IoLocationSharp } from 'react-icons/io5'
// import { FiThumbsUp } from 'react-icons/fi'
// import dayjs from 'dayjs';
import EventTile1 from '../../smallcomponents/eventtile/EventTile1';
import { AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookSquare, FaTelegram } from 'react-icons/fa'
import { ImLinkedin } from 'react-icons/im'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
} from "react-share";
import { ReactComponent as Thumb } from '../../../assets/svg/thumbup.svg';
import './add.css'
import { useAppSelector } from '../../../app/hooks';

const EventCreated = () => {
    const {created} = useAppSelector(state => state.addEvent)
    
    // const data = {
    //     "id": 386,
    //     "profile_id": 17,
    //     "profile_firstname": "Johnson",
    //     "profile_surname": "J 😂",
    //     "profile_username": "Johnson",
    //     "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/image_cropper_1629263960583-thumbnail-100x100-70.jpg",
    //     "event_image": [
    //         {
    //             "id": 188,
    //             "image": "https://d1li36dzvy4hoo.cloudfront.net/media/events/user_events/6620.jpg",
    //             "image_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/events/user_events/6620-thumbnail-200x200-70.jpg",
    //             "imagetype": "user_uploaded"
    //         }
    //     ],
    //     "name": "psg4",
    //     "description": "yyttggg",
    //     "start_date": "2021-08-11T17:27:00Z",
    //     "end_date": "2021-10-11T17:27:00Z",
    //     "latitude": "10.5276416",
    //     "longitude": "76.2144349",
    //     "category": [
    //         {
    //             "id": 4,
    //             "name": "Sporting",
    //             "image": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/events/category/sports_2Rya9UJ-thumbnail-200x200-70.jpeg",
    //             "category_icon": "https://d1li36dzvy4hoo.cloudfront.net/media/events/category/icon/sport_9EDQqmE.png",
    //             "app_category_icon": "https://d1li36dzvy4hoo.cloudfront.net/media/events/category/app/icon/Sporting.svg"
    //         }
    //     ],
    //     "review_rating": true,
    //     "review": [],
    //     "total_rating": 0,
    //     "participants": [],
    //     "participants_count": 0,
    //     "kilometer": 36.4,
    //     "slug": "0b523f32-d49a-46b8-8b97-4cd8023a4391",
    //     "permissions": {
    //         "can_view": true,
    //         "can_edit": false,
    //         "can_delete": false,
    //         "can_invite": false,
    //         "can_assign_role": false,
    //         "can_add_organiser": false,
    //         "write_review": true,
    //         "read_review": true,
    //         "edit_review": false,
    //         "can_share": true,
    //         "can_cancel": false,
    //         "canceled": true
    //     },
    //     "cancel_status": true,
    //     "canceled_on": "2021-08-14T10:35:00.191398Z",
    //     "cancel_message": "Hai iam jithin k johnson\n\nis it possible and the fact I was wondering about it I am\nis the same way you could let you for the\n\n\nis that it has to have you seen it to you by phone call you back and\n\n67hhee\nnthe ee ka ee ente ennu ka ee ka ee\n"
    // }
    const URL = window.location.href;
    const pathnow = window.location.pathname;
    const currentURL = URL.replace(pathnow, `/event/${created.slug}`);
    const [copy, setcopy] = useState(false);

    return (
        <div className='eventcreated container minvh100'>
            <div className='eventTileDiv'>
                <h2>See how it looks</h2>
                <EventTile1 event={created} k={1} />
            </div>
            <div className='eventcreatedContent'>
                <div>
                    <div className='noData'>
                        <div className='noDatasvgcontainer'><Thumb /></div>
                        <h2>Your event has been added successfully</h2>
                        <span>Now share it with your friends on social media</span>
                        <div className="NewEventCreatedSocialDiv">
                            <FacebookShareButton url={currentURL} quote={`Checkout this event - ${created.name}.\njoin us in Mutual Events now`}  ><FaFacebookSquare /></FacebookShareButton>
                            <TelegramShareButton url={currentURL} title={`Checkout this event - ${created.name}.\njoin us in Mutual Events now`}  ><FaTelegram /></TelegramShareButton>
                            <TwitterShareButton url={currentURL} title={created.name} via={'join us in Mutual Events now'}><AiOutlineTwitter /></TwitterShareButton>
                            <LinkedinShareButton url={currentURL} title={`Checkout this event - ${created.name}.\njoin us in Mutual Events now`}  ><ImLinkedin /></LinkedinShareButton>
                        </div>
                        <span>or copy the link and share it</span>
                        <div className='shareInputDiv'>
                            <input
                                type="text"
                                value={currentURL}
                                className="shareinput"
                            />
                            <button className='mainBtn' onClick={() => {
                                navigator.clipboard.writeText(currentURL);
                                setcopy(true);
                            }}>{copy ? 'Copied': 'Copylink' }</button>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default EventCreated
