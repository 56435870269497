import React, { useCallback, useEffect, useRef, useState } from "react";
import ModalLayout from "../../../utils/ModalLayout";
import axios from "axios";
import Auth from "../../../../app/auth";
import { baseurl } from "../../../../Data/Data";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    eventparticipantsClear,
  eventparticipantsfailed,
  eventparticipantsload,
  eventparticipantssuccess,
} from "../store/eventSlice";
import Api from "../../../../app/api";
import { useHistory } from "react-router-dom";
import { FaFileDownload, FaFilePdf } from "react-icons/fa";
import { MdPersonAdd } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import CustomFormAttend from "./CustomFormAttend";

const AttendeeListModal = ({ open, handleClose, event, Refresh }: any) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [downloadLoading, setdownloadLoading] = useState(false);
  const [openform, setopenform] = useState(false);

  const [custom_form, setcustom_form] = useState<any>([
    {
      name: "Email",
      type: "email",
      placeholder: "Enter your mail",
      required: true,
    },
    {
      name: "Name",
      type: "text",
      placeholder: "Enter your name",
      required: true,
    },
  ]);

  const { participants, perror, ploading, ppage, ppagination } = useAppSelector(
    (state) => state.event
  );
  const success = (res: any) => {
    dispatch(
      eventparticipantssuccess({
        data: res,
        no: ppage + 1,
        pagination: res.length < 20 ? false : true,
      })
    );
  };
  const failed = (data: any) => {
    dispatch(eventparticipantsfailed(data));
  };

  useEffect(() => {
    if (Object.keys(participants).length === 0) {
      dispatch(eventparticipantsload());
      Api(
        "get",
        `/api/v2/event/attending/${event?.slug}/1/20/`,
        null,
        success,
        failed,
        history,
        true,
        true
      );
    }
  }, []);

  const RefreshList = () => {
    
    dispatch(eventparticipantsClear());
    dispatch(eventparticipantsload());
    Api(
      "get",
      `/api/v2/event/attending/${event?.slug}/1/20/`,
      null,
      success,
      failed,
      history,
      true,
      true
    );
 }

  const observer: any = useRef();

  const paginationRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && ppagination) {
          if (!ploading) {
            dispatch(eventparticipantsload());
            Api(
              "get",
              `/api/v2/event/attending/${event?.slug}/${ppage}/20/`,
              null,
              success,
              failed,
              history,
              true,
              true
            );
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [participants, ploading]
  );

  const downloadGuestList = () => {
    setdownloadLoading(true);

    let token = Auth("token", null, null);

    axios
      .get(`${baseurl}/api/v1/event/list/rsvp-pdf/${event?.slug}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response: any) => {
        console.log("response", response);

        const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${event?.name}-AttendeeList.pdf`;
        alink.click();
        setdownloadLoading(false);
      });
  };

  console.log("participants data", participants);
  return (
    <>
      <ModalLayout open={open} handleClose={handleClose}>
        <div className="tw-h-[75vh] tw-w-[500px] tw-bg-white tw-rounded-md flex flex-col gap-2 p-5">
          <div className="commonHeading !tw-flex-row tw-flex tw-justify-between tw-items-center  w-full">
            <div>
              <h2 className="tw-max-w-min">Attendees</h2>
            </div>

            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-center">
              <div className="tw-shadow-xl tw-border  tw-border-slate-300 p-1 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-cursor-pointer"
                onClick={()=>setopenform(true)}

              >
                <MdPersonAdd size={20} style={{ color: "var(--cl_orange)" }} />
              </div>

              <div
                className="tw-shadow-xl tw-border  tw-border-slate-300 p-1 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-cursor-pointer"
                onClick={downloadGuestList}
              >
                {downloadLoading ? (
                  <CircularProgress
                    size={20}
                    style={{ color: "var(--cl_orange)" }}
                  />
                ) : (
                  <FaFilePdf size={20} style={{ color: "var(--cl_orange)" }} />
                )}
              </div>
            </div>
          </div>
          {participants &&
            participants.map((item: any, key: React.Key | null | undefined) => {
              return (
                <div key={key}>
                  <div className="featuredprofile">
                    <img
                      alt="P_I_C"
                      src={
                        item?.profile_details?.dp ||
                        "https://d20rv3nuwgisca.cloudfront.net/media/defaults/event/default-event-cover.png"
                      }
                    ></img>
                    <div className="featuredNames !tw-max-w-max">
                      {item?.profile_details?.usertype === "guestuser" ? (
                        <div className="tw-flex ">
                          <h3>
                            {item?.profile_details?.custom_form?.Name}
                            <small
                              className="tw-ml-1 tw-p-2 !tw-font-semibold !tw-text-black tw-bg-slate-200 !tw-rounded-lg !tw-w-32 !tw-full tw-truncate"
                              style={{
                                boxShadow:
                                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                              }}
                            >
                              GUEST USER
                            </small>
                          </h3>
                        </div>
                      ) : (
                        <h3>
                          {item?.profile_details?.firstname +
                            " " +
                            item?.profile_details?.surname}
                        </h3>
                      )}
                      <span>{item?.profile_details?.username}</span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </ModalLayout>

      <CustomFormAttend
        open={openform}
        handleClose={() => setopenform(false)}
        inputFields={custom_form}
        setInputFields={setcustom_form}
        event={event}
        Refresh={RefreshList}
      />
    </>
  );
};

export default AttendeeListModal;
