import { createSlice, } from '@reduxjs/toolkit';
import { StoryList, Profile } from './story.interface';

const initialState = {
    loading: false,
    StoryList: [] as StoryList[],
    FollowersStoryList: [] as Profile[],
    page: 1,
    pagination: true,
    error: null
};
export const storyListSlice = createSlice({
    name: 'storyListSlice',
    initialState,
    reducers: {
        storyListRequest: (state) => {
            state.loading = true
        },
        storyListSuccess: (state, action: any) => {
            state.loading = false;
            state.StoryList =  action.payload.data ;
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        storyListFailed: (state, action: any) => {
            state.loading = false;
            state.error = action.payload
        },


        FollowerStoryListRequest: (state) => {
            state.loading = true
        },
        FollowerStoryListSuccess: (state, action: any) => {
            state.loading = false;
            state.FollowersStoryList =  action.payload.data ;
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        FollowerStoryListFailed: (state, action: any) => {
            state.loading = false;
            state.error = action.payload
        }

    }
})
export const {
    storyListRequest,
    storyListSuccess,
    storyListFailed,

    FollowerStoryListRequest,
    FollowerStoryListSuccess,
    FollowerStoryListFailed

} = storyListSlice.actions
export default storyListSlice.reducer
