import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { eventinvitesuccess, addeventinvite, eventinvitefailed, eventinviteload } from '../../pages/Event/store/eventSlice';
import { ReactComponent as Search } from '../../../assets/svg/mainsearch.svg';
import { baseurl } from '../../../Data/Data';

interface Iprop {
    eventId?: any;
}

const Invitesearch = ({eventId}:Iprop) => {


    const [searchuser, setsearchuser] = useState(null)
    const dispatch = useAppDispatch();
    const { page } = useAppSelector(state => state.event)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    useEffect(() => {
        const request = axios.CancelToken.source()
        let data;
        console.log("searching user")
        data = {
            "search_type": "people",
            "keyword": searchuser

        }
        const token = localStorage.getItem(`access_token`);
        let theurl = `/api/v1/search/1/50/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/search/1/50/?auth_profile=${parseddetails[0].id}`
            }

        }
        var config: any = {
            method: 'post',
            url: baseurl + theurl,
            cancelToken: request.token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };
        if (searchuser !== null && searchuser !== '') {
            dispatch(eventinviteload(searchuser))

            axios(config)
                .then((res) => {
                    let paginationStat = res.data.length < 50 ? false : true
                    dispatch(eventinvitesuccess({
                        data: res.data,
                        page: page + 1,
                        pagination: paginationStat,
                    }))
                    // dispatch(eventinvitesuccess({
                    //     data: data,
                    //     no: page1 + 1,
                    //     pagination: paginationStat,
                    //     value: keyword,
                    //     lat: lat,
                    //     lng: lng
                    // }))
                    // console.log("users")
                    // console.log(res.data)
                })
                .catch((error) => {
                    if (error.response && error.response.data) {

                        let statusCode = error.response.status;
                        let statusText = error.response.statusText;
                        let error_dev_data = error.response.data.dev_data;
                        let error_app_data = error.response.data.app_data;
                        let res = {
                            status: statusCode,
                            text: statusText,
                            dev_data: error_dev_data,
                            app_data: error_app_data
                        }
                        dispatch(eventinvitefailed(res))
                    } else {
                        dispatch(eventinvitefailed({
                            status: null,
                            text: 'Network error',
                            dev_data: null,
                            app_data: null
                        }))
                    }
                });
        } else {
            let theurl = `/api/v1/search/friends/1/50/`
            if (unparseddetails) {
                if (currentparsed.is_business_profile) {
                    theurl = `/api/v1/search/friends/1/50/?auth_profile=${parseddetails[0].id}`
                }

            }

            if(eventId){
                theurl = `/api/v1/search/1/20/?search_for=invite&event=${eventId}`
            }
            
            config = {
                method: 'post',
                url: baseurl + theurl,
                cancelToken: request.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                data: {
                    "search_type": "people",
                    "keyword": searchuser
                }
            }
            dispatch(eventinviteload(searchuser))


            axios(config)
                .then((res) => {
                    let paginationStat = res.data.length < 50 ? false : true
                    dispatch(eventinvitesuccess({
                        data: res.data,
                        page: page + 1,
                        pagination: paginationStat,
                    }))
                    // if(mode === 'invite'){
                    // dispatch(inviteSearch(res.data))
                    // }else{
                    //     dispatch(participantSearch(res.data))
                    // }
                    // console.log("users")
                    // console.log(res.data)
                })
                .catch((error) => {

                    if (error.response && error.response.data) {

                        let statusCode = error.response.status;
                        let statusText = error.response.statusText;
                        let error_dev_data = error.response.data.dev_data;
                        let error_app_data = error.response.data.app_data;
                        let res = {
                            status: statusCode,
                            text: statusText,
                            dev_data: error_dev_data,
                            app_data: error_app_data
                        }
                        dispatch(eventinvitefailed(res))
                    } else {
                        dispatch(eventinvitefailed({
                            status: null,
                            text: 'Network error',
                            dev_data: null,
                            app_data: null
                        }))
                    }
                });
        }
        return () => request.cancel()
    }, [searchuser]);
    return (

        <div className='eventinvitesearch' >
            <input type='text' placeholder='Search' onChange={(e) => {
                const target: any = e.currentTarget
                setsearchuser(target.value)
            }} ></input>
            <Search className='cursor' />

        </div>
    )
}

export default Invitesearch
