import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';
import EventTileLong from './EventTileLong'
// import { attending } from '../dummydata'
import { noData } from '../../../../Data/Data';
import dayjs from 'dayjs';
import Api from '../../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { attendEventsSuccess, attendEventsRequest, attendEventsFailed } from '../store/eventsSlice';
import { useHistory } from 'react-router-dom';
import { errors } from '../../../../Data/Error';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import Eventtilelongloader from '../../../smallcomponents/loaders/Eventtilelongloader';

const Attending = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { lat, lng } = useAppSelector(state => state.profile)
    // const lat = '10.8505159'
    // const lng = '76.2710833'
    const {
        attend_list,
        attend_page,
        attend_pagination,
        attend_loading,
        attend_error,
        attendingType,
        SearchDate
    } = useAppSelector(state => state.events)

    const initialDate = dayjs(SearchDate).format("YYYY-MM-DD")

    const head: any = useRef(null)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const success = (data: any) => {
        console.log(data)
        let pgn = data.length < 10 ? false : true
        dispatch(attendEventsSuccess({
            data: data,
            no: attend_page + 1,
            pagination: pgn
        }))
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(attendEventsFailed(data))
    }
    let post_data = {
        date: dayjs(initialDate).format("YYYY-MM-DD"),
        event_type: 'attending'
    }
    useEffect(() => {
        dispatch(attendEventsRequest({ date: dayjs(initialDate).format("YYYY-MM-DD"), no: 1 }))
        let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, post_data, success, failed, history, true, true)
    }, [lat, lng])
    const Refresh = () => {
        head.current.scrollIntoView({ behavior: "smooth", block: "start" });
        dispatch(attendEventsRequest({ date: SearchDate, no: 1 }))
        let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, post_data, success, failed, history, true, true)
    }
    // const LoadMore = () => {
    //     dispatch(attendEventsRequest({ date: SearchDate, no: attend_page }))
    //     // let data = {
    //     //     date: SearchDate,
    //     //     event_type: attendingType
    //     // }
    //     let theurl = `/api/v1/event/list/web/${attend_page}/10/?latitude=${lat}&longitude=${lng}`
    //     if (unparseddetails) {
    //       if (currentparsed.is_business_profile) {
    //         theurl = `/api/v1/event/list/web/${attend_page}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
    //       }
  
    //     }
    //     Api('post', theurl, post_data, success, failed, history, true, true)
    // }
    return (
        <div className='attendingEvents'>
            <div ref={head} className='commonHeading'>
                <h2>Events on the selected date</h2>
                <span>Events you are attending</span>
            </div>
            <div className='eventTileList'>
                {
                    (Object.keys(attend_list).length === 0) ? (
                        (!attend_loading && attend_error === null) &&
                        <div className='noData'>
                            <Warning />
                            <h2>{noData.attending.heading}</h2>
                            <span>{noData.attending.message}</span>
                        </div>
                    ) : (
                        attend_list.map((events:any, key) => {
                            return (
                                <EventTileLong Events={events} k={key} />
                            )
                        })
                    )
                }
                {
                    attend_loading && [...Array(2)].map(() => {
                        return (
                            // <div className="eventileLong skeleton" ></div>
                            <Eventtilelongloader />
                        )
                    })
                }
                {
                    (!attend_loading && attend_error !== null) &&
                    (attend_error.status === null && attend_error.text === null ? (
                        <div className='noData'>
                            <Warning />
                            <h2>{noData.attending.heading}</h2>
                            <span>{noData.attending.message}</span>
                        </div>
                    ) : attend_error.status === null ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Nointernet />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                            </div>
                        </div>
                    ) : attend_error.status === 500 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Try again</button>
                            </div>
                        </div>
                    ) : attend_error.status === 401 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }



            </div>
            {attend_pagination && (
                !attend_loading ? (attend_error === null ? <div className='loadmore'>
                    <button className='mainBtn'>Show more events</button>
                </div> : null) : null)
            }
        </div >
    )
}

export default Attending
