import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Api from '../../../../app/api'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'

import { ReactComponent as Toparrow } from '../../../../assets/svg/cm/infoopen.svg'


import { ReactComponent as Daily } from './dailyapproved.svg'
import { ReactComponent as Disapp } from './dailydisapproved.svg'

import { ReactComponent as Dailymod } from './dailymoderated.svg'
import { ReactComponent as Esc } from './escalatedcontent.svg'
import { ReactComponent as Weekly } from './weekly.svg'


const Contentinfotiles = () => {

    const history = useHistory()

    const { mloading, merror, moderatedstats } = useAppSelector(state => state.moderator)
    const { profile } = useAppSelector(state => state.profile)


    const names = ['daily_approved',
        'daily_disapproved',
        'daily_moderated_content',
        'escalated_content',
        'percentage_approved_to_disapproved',
        'weekly_moderated_content']

    const [info, setinfo] = useState(true)
    return (
        mloading  ? (
            <div className='cm_contentinfo_tiles_container_wrap' >
                <div className={info ? 'cm_contentinfo_tiles_container_open' : 'cm_contentinfo_tiles_container'}>
                    {
                        [...Array(6)].map(() => {
                            return (
                                <div className='cm_infotile '>
                                    <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                                    <span className='skeleton minhe10' style={{ minWidth:'80px',maxWidth: '50px' }}></span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={!info ? 'cm_info_close' : 'cm_info_open'} onClick={() => [
                    setinfo(!info)
                ]}>
                    <Toparrow />
                </div>
            </div>
        ) :
            <div className='cm_contentinfo_tiles_container_wrap' >
                {
                    profile.role === "Admin" ? (
                        <div className={info ? 'cm_contentinfo_tiles_container_open' : 'cm_contentinfo_tiles_container'}>

                            <div className='cm_infotile'>
                                <h1><Dailymod /> {moderatedstats.events_count}</h1>
                                <span>Events count</span>
                            </div >
                            <div className='cm_infotile'>
                                <h1><Dailymod /> {moderatedstats.feed_count}</h1>
                                <span>Feed count</span>
                            </div>
                            <div className='cm_infotile'>
                                <h1><Dailymod /> {moderatedstats.user_count}</h1>
                                <span>User count</span>
                            </div>

                        </div >
                    ) : profile.role === "Content Moderator" ? (<div className={info ? 'cm_contentinfo_tiles_container_open' : 'cm_contentinfo_tiles_container'}>

                        <div className='cm_infotile'>
                            <h1><Daily /> {moderatedstats.daily_approved}</h1>
                            <span>Daily approved</span>
                        </div >
                        <div className='cm_infotile'>
                            <h1><Disapp /> {moderatedstats.daily_disapproved}</h1>
                            <span>Daily disapproved</span>
                        </div>
                        <div className='cm_infotile'>
                            <h1><Dailymod /> {moderatedstats.daily_moderated_content}</h1>
                            <span>Daily moderated content</span>
                        </div>
                        <div className='cm_infotile'>
                            <h1><Esc /> {moderatedstats.escalated_content}</h1>
                            <span>Escalated content</span>
                        </div>
                        <div className='cm_infotile'>
                            <h1><Disapp /> {moderatedstats.percentage_approved_to_disapproved}</h1>
                            <span>Percentage approved to disapproved</span>
                        </div>
                        <div className='cm_infotile'>
                            <h1><Weekly /> {moderatedstats.weekly_moderated_content}</h1>
                            <span>Weekly moderated content</span>
                        </div>

                    </div >) : (
                        <div></div>
                    )}
                <div className={!info ? 'cm_info_close' : 'cm_info_open'} onClick={() => [
                    setinfo(!info)
                ]}>
                    <Toparrow />
                </div>
            </div >

    )
}

export default Contentinfotiles