import React from 'react'
import { useAppSelector } from '../../../../../../app/hooks';

function DailySalesPopup(props: any) {
    const { dailySales } = useAppSelector<any>(state => state.ticketPortal)

    return (

        <div className="dailySalesPopup">

            <div className="popup-header">
                <h4>Revenue generated eventwise</h4>
                <h1 onClick={props.closePopups}>X</h1>
            </div>  <div className='popup-content'> <div className="popup-content-heading">

                <h4>Event Name</h4>
                <h4>Ticket Sold</h4>
                <h4>Revenue Generated</h4>
            </div>
                {/* {
 dailySales && dailySales.table_data ? dailySales.table_data.map((item: any) => {


   }):null
            } */}{


                    props.data.map((item1: any, key: any) => {
                        return (

                            item1.length !== 0 ?
                                <div key={key} className="popup-content-body">
                                    <div>
                                        <img style={{ height: "30px", width: "30px", borderRadius: "50%" }} src={item1['event_image']} alt={item1['event_thumbnail']} />

                                        <p>{item1['name']}</p>
                                    </div>
                                    <p>{item1['tickets_sold']}</p>
                                    <p>{item1['revenue']}</p>
                                </div>


                                : <h1>There is no data</h1>)
                    })

                }
            </div>

        </div >

    )
}

export default DailySalesPopup