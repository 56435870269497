import React, { useState, useEffect, useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useHistory } from 'react-router-dom'
import Api from '../../../../app/api'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { ReactComponent as ArrowDown } from '../../../../assets/svg/selectarrow.svg'
import { moderatorlistrequest, moderatorlist, moderatorlistfailed } from '../store/ContentmoderatorSlice'
import { errors } from '../../../../Data/Error'
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import ModeratorListItem from './ModeratorListItem'


const ListOfModerators = () => {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const { listofmoderatorpage, listofmoderatorpagination, listofmoderators, listofmoderatorserror, listofmoderatorsloading } = useAppSelector(state => state.moderator)

    const [visible, setvisible] = useState(false)
    const [selectedstate, setselectedstate] = useState('initial')
    const [selectedvalue, setvalue] = useState<any>({
        name: 'Content Moderator',
        role_type: 'content_moderator'
    })
    const success = (data: any) => {
        // console.log(data)
        dispatch(moderatorlist({
            data: data,
            no: listofmoderatorpage + 1,
            pagination: data.length >= 20 ? true : false
        }))
    }
    const failed = (data: any) => {
        dispatch(moderatorlistfailed(data))
    }

    useEffect(() => {
        dispatch(moderatorlistrequest(1))
        Api('get', `/api/v1/permissions/content-moderation/super-admin/admin-moderators/1/20/?role_type=${selectedvalue.role_type}`, null, success, failed, history, true, true)
    }, [selectedvalue])


    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (visible && targt && !targt.contains(e.target)) {
                setvisible(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [visible])

    const ScrollPos = (val: any) => {
        const { top } = val
        console.log(top)

        if (top > 0.9) {
            console.log('scroll please')
            if (listofmoderatorpagination && !listofmoderatorsloading) {
                dispatch(moderatorlistrequest(listofmoderatorpage))
                Api('get', `/api/v1/permissions/content-moderation/super-admin/admin-moderators/${listofmoderatorpage}/20/?role_type=${selectedvalue.role_type}`, null, success, failed, history, true, true)
                // fetchsinglechatmessages(openedchatdetails.chat_id, chatmessagespage, history, dispatch)
            }
        }

    }

    return (
        <div className='cm_list_container'>
            <div style={{ height: '32px', display: 'flex', justifyContent: 'space-between' }} ref={vis}>
                <h3>List of moderators</h3>
                <div className='cm_list_roleselect'>
                    <label>Role: </label>
                    <div className='selectbox'
                        onClick={
                            e => {
                                if (!visible) {
                                    setselectedstate('selecting')
                                } else {
                                    setselectedstate('selected')
                                }
                                setvisible(!visible)
                            }
                        }>
                        <div className={'selectbox selectboxinput'}>
                            <p>{selectedvalue.name}</p>
                        </div>
                        <div className={visible ? 'selectdropdown selectactive' : 'selectdropdown selectinactive'}>
                            <span className='selecttriangle'></span>
                            <div className='ul'>
                                <Scrollbars style={{ width: 220, height: 85 }} autoHide>
                                    <li value='content_moderator' onClick={e => {
                                        const target: any = e.currentTarget
                                        setvalue({
                                            name: 'Content Moderator',
                                            role_type: target.getAttribute('value')
                                        })
                                        // setcategoryid(null);
                                    }}
                                    >Content Moderator</li>
                                    <li value='admin' onClick={e => {
                                        const target: any = e.currentTarget
                                        setvalue({
                                            name: 'Admin',
                                            role_type: target.getAttribute('value')
                                        })
                                        // setcategoryid(null);
                                    }}
                                    >Admin</li>

                                </Scrollbars>
                            </div>
                        </div>

                        <ArrowDown className={selectedstate === 'selecting' ? 'selectarrow arrowdown' : selectedstate === 'selected' ? 'selectarrow arrowleft' : 'selectarrow'} style={{ fill: 'black' }} />
                    </div>
                </div>
            </div>
            <Scrollbars autoHide onUpdate={ScrollPos} className='moderatorlistscroll'>
                {
                    Object.keys(listofmoderators).length !== 0 ? (
                        listofmoderators.map((moderator: any, key: number) => {

                            return (
                                <ModeratorListItem moderator={moderator} />
                            )
                        })
                    ) : (
                        !listofmoderatorsloading ?
                            listofmoderatorserror === null ?
                                <div className='noData'>
                                    <span>no moderated posts found</span>
                                </div>
                                : null
                            : null
                    )
                }
                {
                    listofmoderatorsloading && [...Array(10)].map((_, key) => {
                        return (
                            <div className='moderatorlistitem skeleton'>
                                <div className='peopleitemimgloader skeleton' />
                                <h6 className='skeleton minhe20 minwid60'></h6>
                                <span className='skeleton minhe10 minwid60 maxwid60'></span>
                            </div>
                            // <div className={'cm_table_container'}>
                            //     <div className='cm_table_item'>
                            //         <li >
                            //             <div className='skeleton' style={{ height: '40px', width: '40px', borderRadius: '8px' }}></div>
                            //             <small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px' }}></small>
                            //         </li>
                            //         <li><small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px', maxHeight: '10px' }}></small></li>
                            //         <li>
                            //             <button className='cm_content_statbtn skeleton'>

                            //             </button>
                            //         </li>
                            //         <li >
                            //             <small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px', maxHeight: '10px' }}></small>
                            //         </li>
                            //         <li>
                            //             <button className='cm_content_statbtn skeleton' style={{ minWidth: 'auto' }}>

                            //             </button>
                            //         </li>
                            //         <li className='cm_table_imgoverlapped'>
                            //             {
                            //                 [...Array(3)].map((_, key: number) => {
                            //                     return (
                            //                         <div className={`cm_table_usrimg cm_table_usrimg${key} skeleton`} style={{ height: '1rem', width: '1rem', borderRadius: '50%' }} />
                            //                     )
                            //                 })
                            //             }



                            //         </li>
                            //         <li>
                            //             <div className="FeedItemDropdown" >
                            //                 <span className='dots'>
                            //                     <More />
                            //                 </span>

                            //             </div>
                            //         </li>
                            //     </div>
                            // </div>
                        )
                    })
                }
                {
                    listofmoderatorserror !== null ? (
                        listofmoderatorserror.status === null ? (
                            <div className=' errorAlt' >
                                <div className='errormsg'>
                                    <Nointernet />
                                    <h2>{errors.internet.heading}</h2>
                                    <span>{errors.internet.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        ) : listofmoderatorserror.status === 500 ? (
                            <div className=' errorAlt'>
                                <div className='errormsg'>
                                    <Server />
                                    <h2>{errors.server.heading}</h2>
                                    <span>{errors.server.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        ) : listofmoderatorserror.status === 401 ? (
                            <div className=' errorAlt'>
                                <div className='errormsg'>
                                    <NotFound />
                                    <h2>{errors.notFound.heading}</h2>
                                    <span>{errors.notFound.message}</span>
                                </div>
                            </div>
                        ) : (
                            <div className=' errorAlt'>
                                <div className='errormsg'>
                                    <NotFound />
                                    <h2>{errors.common.heading}</h2>
                                    <span>{errors.common.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        )) : null
                }
            </Scrollbars>

        </div>
    )
}

export default ListOfModerators