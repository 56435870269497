
import Api from "../../../../app/api";
import {
    chatsuccess, chatfailed, chatrequest, chatmessagesrequest, chatmessagesfailed, chatmessagesfetch,
    chatmediarequest, chatmediafailed, chatmediasuccess, chatdocfailed, chatdocrequest, chatdocsuccess, chatmedia1success
} from "../store/ChatSlice";


export function fetchchatlist(history: any, dispatch: any, page: number) {

    dispatch(chatrequest(page))

    const success = (res: any) => {
        console.log(res)
        let pg = res.length === 100 ? true : false
        dispatch(chatsuccess({
            data: res,
            no: 2,
            pagination: pg
        }))
    }
    const failed = (error: any) => {
        console.log(error)
        dispatch(chatfailed(error))
    }
    Api('get', '/api/v1/ws/chat/recent/v2/1/100/', null, success, failed, history, true, true)
    // Api('get',null,null,success,failed,history,true,true)
}

export function fetchsinglechatmessages(id: any, page: number, history: any, dispatch: any) {
    console.log(id, 'this is thwse idfjbsdkfjbjkbxdff')
    dispatch(chatmessagesrequest({ page: page }))
    const success = (res: any) => {
        console.log(res)
        console.log(" the real response");

        let pg = res.length === 15 ? true : false
        dispatch(chatmessagesfetch({
            data: res,
            page: page,
            pagination: pg
        }))
    }
    function failed(error: any) {
        console.log(error)
        dispatch(chatmessagesfailed(error))
    }
    Api('get', `/api/v1/ws/chat/history/v2/${id}/${page}/15/`, null, success, failed, history, true, true)
}

export function DeleteChats(selected: any, success: any, failed: any, history: any) {
    Api('post', '/api/v1/ws/chat/delete/', selected, success, failed, history, true, true)
}

export function FetchChatDocuments(id: any, history: any, dispatch: any) {
    dispatch(chatdocrequest())
    const success = (data: any) => {
        console.log(data)
        dispatch(chatdocsuccess({
            'data': data
        }))
    }
    const failed = (error: any) => {
        console.log(error)
        dispatch(chatdocfailed(error))
    }
    Api('get', `/api/v1/ws/chat/group/docs/${id}/1/10/`, null, success, failed, history, true, true)
}
export function FetchChatmedia(id: any, history: any, dispatch: any) {
    dispatch(chatmediarequest({ no: 1 }))
    const success = (response: any) => {
        console.log(response)
        // dispatch(chatmedia1success({ 'data': res }))
        dispatch(chatmedia1success({
            data: response,
            pagination: response.length < 5 ? false : true
        }))
    }
    const failed = (error: any) => {
        console.log(error)

        console.log("I HAVE RUN  YOOOO")
        // dispatch(chatmediafailed(error))
    }
    Api('get', `/api/v1/ws/chat/group/media/${id}/1/5/`, null, success, failed, history, true, true)
}