import React, { useState } from 'react'
import { ReactComponent as Backarrow } from '../../../../assets/svg/chat/chatarrowback.svg';

const ChatNotifications = ({ setchatwindow, chatwindow }: any) => {
    const [reviewstatus, setreviewstatus] = useState(true)
    const [active, setactive] = useState(true);
    const [ploading, setploading] = useState(false)


    const ChangeNotif = () => {
        setploading(true)
        setTimeout(() => {
            setploading(false)
            setactive(!active)
        }, 5000);
    }
    return (
        <div className={chatwindow === 'notifications'? 'chatnotifications minichatmaininactive':'chatnotifications minichatmainactive'}>
            <div className='chatnotificationsheader'>
                <div className='chatnotificationshead' onClick={() => {
                    setchatwindow('main')
                }}>
                    <Backarrow />
                    <h4>Notifications</h4>
                </div>
                <div className={active ? `profileToggle profileToggleActive ${ploading ? 'toggleload' : ''}` : `profileToggle profileToggleInactive ${ploading ? 'toggleload' : ''}`} onClick={
                    () => {
                        ChangeNotif();
                    }
                }>
                    <div className='profileToggleDot'></div>
                </div>
            </div>
        </div>
    )
}

export default ChatNotifications
