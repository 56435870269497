import React, { Fragment } from 'react'
import ImageContainer from '../../../../../utils/ImageLoader/image-container'
import Rating from 'react-rating'
import { ReactComponent as Star1 } from '../../../../../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../../../../../assets/svg/star2.svg';
import '../style.css'
import Scrollbars from 'react-custom-scrollbars-2';
import dayjs from 'dayjs';
import { borderRadius } from '@mui/system';

interface ITicketedEvents {
    EventImage: string;
    EventImageThumb: string;
    EventName: string;
    // EventStartDate: string
    // EventEndDate: string
    // PaymentFromDate: string;
    // PaymentToDate: string;
    GrossIncome: string | number;
    NetIncome: string | number;
    rating: number;
    key: number;
    SoldTickets: string | number
}

const TicketedEvents = ({ EventImage, EventImageThumb, EventName, rating, GrossIncome, NetIncome, key, SoldTickets }: ITicketedEvents) => {
    return (





        <tbody className='table_data_content'>
            <td >
                <div className="TicketedEventItem__name">


                    <img style={{ height: "40px", width: "40px", borderRadius: "50%" }} src={EventImage} alt={EventImageThumb} />

                    {EventName}

                </div> </td>
            <td >{SoldTickets}</td>

            <td>{GrossIncome}</td>
            <td>{NetIncome}</td>


        </tbody>






    )
}

export default TicketedEvents