import React, { useEffect, useRef, useState } from 'react'
import Attending from './components/Attending'
import Hosting from './components/Hosting'

import Calendar from 'react-calendar'
import './events.css'
import 'react-calendar/dist/Calendar.css'
// import './calendar.css'
import '../addevent/components/newCalendar/calendar.css'
import Api from '../../../app/api'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  attendEventsRequest, attendEventsSuccess,
  attendEventsFailed, hostEventsRequest, hostEventsFailed,
  hostEventsSuccess, interestedsEventsFailed, interestedsEventsRequest, interestedsEventsSuccess, attendedEventsOnSelectedDates, hostedEventsOnSelectedDates, interestedEventsOnSelectedDates
} from './store/eventsSlice'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Icon } from '../../../assets/svg/date.svg';
import axios from 'axios'
import { baseurl } from '../../../Data/Data'
import InterestedEvents from './components/InterestedEvents'

const Events = () => {
  const [Tile, setTile] = useState<any>([])
  const [visible, setvisible] = useState(false)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const {

    attend_page, host_page, interested_events_page

  } = useAppSelector(state => state.events)
  // type TileReturn = (date: any, view: any) => string;
  const success = (res: any) => {
    console.log(res, 'response')
    setTile(res)
  }
  const failed = (res: any) => {
    console.log(res, 'res')
    // setTile(res)
  }

  const { lat, lng } = useAppSelector(state => state.profile)
  // const { host_page, attend_page } = useAppSelector(state => state.events)

  let unparseddetails: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(unparseddetails)
  let currentunparsed: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(currentunparsed)

  // const lat = '10.8505159'
  // const lng = '76.2710833'
  const success3 = (data: any) => {
    let pgn = data.length < 10 ? false : true
    dispatch(interestedsEventsSuccess({
      data: data,
      // no: 2,
      no: 1,
      pagination: pgn
    }))
  }
  const failed3 = (data: any) => {
    console.log(data)
    dispatch(interestedsEventsFailed(data))
  }
  const success2 = (data: any) => {
    console.log(data)
    let pgn = data.length < 10 ? false : true
    dispatch(attendEventsSuccess({
      data: data,
      // no:2,
      no: 1,
      pagination: pgn
    }))
  }
  const failed2 = (data: any) => {
    console.log(data)
    dispatch(attendEventsFailed(data))
  }

  const success1 = (data: any) => {

    let pgn = data.length < 10 ? false : true
    dispatch(hostEventsSuccess({
      data: data,
      // no: 2,
      no: 1,
      pagination: pgn
    }))
  }
  const failed1 = (data: any) => {
    console.log(data)
    dispatch(hostEventsFailed(data))
  }
  function eventsOnSelectedDate(date: any) {
    const success8 = (data: any) => {
      console.log('22222222222222222222222222222222222', data['attending']);

      dispatch(attendedEventsOnSelectedDates({
        data: data['attending'],

      }))
      dispatch(hostedEventsOnSelectedDates({
        data: data['hosting'],

      }))
      dispatch(interestedEventsOnSelectedDates({
        data: data['interests'],

      }))
    }
    const failed8 = (data: any) => {

    }

    console.log('dddddddddaaaaaaaaaaaaaaaaatttttttttttteeeeeeee', date);


    Api('post', '/api/v1/event/list/v2/', {
      date: date,
      latitude: `${lat}`,
      longitude: `${lng}`
    }, success8, failed8, history, true, true)
  }
  useEffect(() => {

    document.title = 'Mutual Events - Events'

    Api('post', '/api/v1/event/month/dates/', {
      year: dayjs(new Date()).format("YYYY"),
      // month: dayjs(new Date()).format("MM")
    }, success, failed, history, true, true)
  }, [])



  const [activedate, setactivedate] = useState<any>('')
  const [clickmonth, setclickmonth] = useState<any>('')
  const [onchange, setonchange] = useState<any>('')
  const [selectedDate, setOnSelectedDate] = useState<any>('')

  useEffect(() => {
    if (activedate === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(hostEventsRequest({ date: dayjs(activedate).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(activedate).format("YYYY-MM-DD"),
        event_type: 'myevents'
      }

      const token = localStorage.getItem(`access_token`);
      // let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      let theurl = `/api/v1/event/list/v2/`

      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          // theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
          theurl = `/api/v1/event/list/v2/&auth_profile=${parseddetails[0].id}`

        }

      }
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success1(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed1(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed1({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed1({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [activedate]);

  useEffect(() => {
    if (clickmonth === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(hostEventsRequest({ date: dayjs(clickmonth).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(clickmonth).format("YYYY-MM-DD"),
        event_type: 'myevents'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success1(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed1(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed1({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed1({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [clickmonth]);


  useEffect(() => {
    if (onchange === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(hostEventsRequest({ date: dayjs(onchange).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(onchange).format("YYYY-MM-DD"),
        event_type: 'myevents'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success1(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed1(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed1({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed1({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [onchange]);







  useEffect(() => {
    if (activedate === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(attendEventsRequest({ date: dayjs(activedate).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(activedate).format("YYYY-MM-DD"),
        event_type: 'attending'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success2(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed2(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed2({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed2({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [activedate]);


  useEffect(() => {
    if (clickmonth === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(attendEventsRequest({ date: dayjs(clickmonth).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(clickmonth).format("YYYY-MM-DD"),
        event_type: 'attending'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success2(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed2(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed2({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed2({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [clickmonth]);


  useEffect(() => {
    if (onchange === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(attendEventsRequest({ date: dayjs(onchange).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(onchange).format("YYYY-MM-DD"),
        event_type: 'attending'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success2(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed2(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed2({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed2({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [onchange]);











  useEffect(() => {
    if (activedate === '') {
      //   setvisible(false)
    } else {

      const request = axios.CancelToken.source()
      let data;
      data = {
        year: dayjs(activedate).format("YYYY"),
        month: dayjs(activedate).format("MM")
      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + '/api/v1/event/month/dates/v2/',
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [activedate]);


  useEffect(() => {
    if (clickmonth === '') {
      //   setvisible(false)
    } else {

      const request = axios.CancelToken.source()
      let data;
      data = {
        year: dayjs(clickmonth).format("YYYY"),
        month: dayjs(clickmonth).format("MM")
      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + '/api/v1/event/month/dates/v2/',
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [clickmonth]);












  useEffect(() => {
    if (activedate === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(interestedsEventsRequest({ date: dayjs(activedate).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(activedate).format("YYYY-MM-DD"),
        event_type: 'interests'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success3(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed3(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed3({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed3({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [activedate]);

  useEffect(() => {
    if (clickmonth === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(interestedsEventsRequest({ date: dayjs(clickmonth).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(clickmonth).format("YYYY-MM-DD"),
        event_type: 'interests'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success3(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed3(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed3({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed3({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [clickmonth]);


  useEffect(() => {
    if (onchange === '') {
      //   setvisible(false)
    } else {
      // handleSearch();
      dispatch(interestedsEventsRequest({ date: dayjs(onchange).format("YYYY-MM-DD"), no: 1 }))

      const request = axios.CancelToken.source()
      let data;
      data = {
        date: dayjs(onchange).format("YYYY-MM-DD"),
        event_type: 'interests'
      }
      let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
        }

      }
      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success3(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed3(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed3({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed3({
                status: null,
                text: null,
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [onchange]);



























































  const vis: any = useRef()
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      let targt: any = vis.current
      if (visible && targt && !targt.contains(e.target)) {
        setvisible(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [visible])



  return (
    <div className='container events minvh100'>
      <div className='eventsContainer'>
        <Attending />
        <InterestedEvents />
        <Hosting />
      </div>
      <div className='calendar' ref={vis}>
        {/* <Icon className={!visible ? 'calendaricon calendaractive' : 'calendaricon'} onClick={() => setvisible(!visible)} /> */}
        <div className={visible ? 'calendarWrapper visible' : 'calendarWrapper invisible'}>
          <Calendar
            value={new Date()}

            minDate={new Date('jan 01 2021')}
            onClickDay={(e) => {

              setOnSelectedDate(dayjs(e).format('YYYY-MM-DD'))
              eventsOnSelectedDate(dayjs(e).format('YYYY-MM-DD'))
            }}
            onActiveStartDateChange={(e) => {

              setactivedate(dayjs(e.activeStartDate).format("YYYY-MM-DD"))

              // dispatch(hostEventsRequest({date: dayjs(e.activeStartDate).format("YYYY-MM-DD"),no: 1}))

              // dispatch(attendEventsRequest({date: dayjs(e.activeStartDate).format("YYYY-MM-DD"),no: 1}))


              // Api('post', '/api/v1/event/month/dates/v2/', {
              //     year: dayjs(e.activeStartDate).format("YYYY"),
              //     month: dayjs(e.activeStartDate).format("MM")
              // }, success, failed,history,true,true)

              // Api('post', theurl, {
              //     date: dayjs(e.activeStartDate).format("YYYY-MM-DD"),
              //     event_type: 'myevents'
              // }, success1, failed1,history,true,true)

              // Api('post', theurl, {
              //     date: dayjs(e.activeStartDate).format("YYYY-MM-DD"),
              //     event_type: 'attending'
              // }, success2, failed2,history,true,true)
            }}

            onClickMonth={e => {
              setclickmonth(dayjs(e).format("YYYY-MM-DD"))


              // dispatch(hostEventsRequest({date: dayjs(e).format("YYYY-MM-DD"),no: 1}))
              // dispatch(attendEventsRequest({date: dayjs(e).format("YYYY-MM-DD"),no: 1}))

              // Api('post', '/api/v1/event/month/dates/v2/', {
              //     year: dayjs(e).format("YYYY"),
              //     month: dayjs(e).format("MM")
              // }, success, failed,history,true,true)

              // Api('post', theurl, {
              //     date: dayjs(e).format("YYYY-MM-DD"),
              //     event_type: 'myevents'
              // }, success1, failed1,history,true,true)

              // Api('post', theurl, {
              //     date: dayjs(e).format("YYYY-MM-DD"),
              //     event_type: 'attending'
              // }, success2, failed2,history,true,true)
            }}

            onChange={(value: any, event: any) => {
              setonchange(dayjs(value).format("YYYY-MM-DD"))

              // let date = dayjs(value).format("YYYY-MM-DD");

              // dispatch(hostEventsRequest({date: dayjs(value).format("YYYY-MM-DD"),no: 1}))
              // dispatch(attendEventsRequest({date: dayjs(value).format("YYYY-MM-DD"),no: 1}))

              // Api('post', theurl, {
              //     date: dayjs(value).format("YYYY-MM-DD"),
              //     event_type: 'myevents'
              // }, success1, failed1,history,true,true)

              // Api('post', theurl, {
              //     date: dayjs(value).format("YYYY-MM-DD"),
              //     event_type: 'attending'
              // }, success2, failed2,history,true,true)
            }}

            // tileClassName={

            //   (e) => Tile.find(x => x === dayjs(e.date).format('YYYY-MM-DD')) ? 'highlight' : null
            // }
            // tileClassName={(({ date}) => {

            //   if (Tile.find(val => val === dayjs(date).format('DD-MM-YYYY'))) {
            //     return 'highlight'
            //   }
            // })}
            tileClassName
            ={({ date }: any) => {
              if (Tile.includes(dayjs(date).format('YYYY-MM-DD'))) {
                // console.log("==================================")
                // console.log("IF CON", Tile.includes(dayjs(date).format('YYYY-MM-DD')))
                // console.log("DATE ====>", dayjs(date).format('YYYY-MM-DD'))
                // console.log("==================================")
                return 'highlight'
              } else {
                return null
              }
            }}
          />
        </div>

      </div>

    </div>
  )
}

export default Events
