import React, { useState, useEffect } from 'react'
import {
    Link, useHistory, useLocation,
} from "react-router-dom";
import Layout from './Layout'
import { ReactComponent as Tick } from '../../assets/svg/lgcheck.svg';
import './login.css'
import { CircularProgress } from "@material-ui/core";
import Api from '../../app/api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { loginRequest, loginFailed, loginSuccess } from './store/loginSlice';
import Auth from '../../app/auth';
import { BiHide, BiShow } from 'react-icons/bi'
import axios from 'axios';
// import { Getlocation } from '../../Data/Data';
import { browserName, browserVersion } from 'react-device-detect';



const Login = () => {
    const history = useHistory()
    const { state }: any = useLocation()


    const { loading, error } = useAppSelector(state => state.login)
    const { lat, lng, city, country,os,osversion,uuid } = useAppSelector(state => state.profile)
    const dispatch = useAppDispatch();

    const [checked, setchecked] = useState(true)
    const [mail, setmail] = useState('')
    const [password, setpassword] = useState('')
    const [validatemail, setvalidatemail] = useState(null)
    const [validatepassword, setvalidatepassword] = useState(null)
    const [visible, setvisible] = useState(false)



    /**
     * JavaScript Client Detection
     * (C) viazenetti GmbH (Christian Ludwig)
     */
    // function that() {
        
    //         var unknown: any = '-';
    //         //browser
    //         var nVer = navigator.appVersion;
    //         var nAgt = navigator.userAgent;
    //         var browser = navigator.appName;
    //         var version = '' + parseFloat(navigator.appVersion);
    //         var majorVersion = parseInt(navigator.appVersion, 10);
    //         var nameOffset, verOffset, ix;

    //         // Opera
    //         if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    //             browser = 'Opera';
    //             version = nAgt.substring(verOffset + 6);
    //             if ((verOffset = nAgt.indexOf('Version')) != -1) {
    //                 version = nAgt.substring(verOffset + 8);
    //             }
    //         }
    //         // MSIE
    //         else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    //             browser = 'Microsoft Internet Explorer';
    //             version = nAgt.substring(verOffset + 5);
    //         }
    //         // Chrome
    //         else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    //             browser = 'Chrome';
    //             version = nAgt.substring(verOffset + 7);
    //         }
    //         // Safari
    //         else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    //             browser = 'Safari';
    //             version = nAgt.substring(verOffset + 7);
    //             if ((verOffset = nAgt.indexOf('Version')) != -1) {
    //                 version = nAgt.substring(verOffset + 8);
    //             }
    //         }
    //         // Firefox
    //         else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    //             browser = 'Firefox';
    //             version = nAgt.substring(verOffset + 8);
    //         }
    //         // MSIE 11+
    //         else if (nAgt.indexOf('Trident/') != -1) {
    //             browser = 'Microsoft Internet Explorer';
    //             version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    //         }
    //         // Other browsers
    //         else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    //             browser = nAgt.substring(nameOffset, verOffset);
    //             version = nAgt.substring(verOffset + 1);
    //             if (browser.toLowerCase() == browser.toUpperCase()) {
    //                 browser = navigator.appName;
    //             }
    //         }
    //         // trim the version string
    //         if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
    //         if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
    //         if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

    //         majorVersion = parseInt('' + version, 10);
    //         if (isNaN(majorVersion)) {
    //             version = '' + parseFloat(navigator.appVersion);
    //             majorVersion = parseInt(navigator.appVersion, 10);
    //         }

    //         // mobile version
    //         var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    //         // cookie
    //         var cookieEnabled = (navigator.cookieEnabled) ? true : false;

    //         if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
    //             document.cookie = 'testcookie';
    //             cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
    //         }

    //         // system
    //         var os = unknown;
    //         var clientStrings = [
    //             { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    //             { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    //             { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    //             { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    //             { s: 'Windows Vista', r: /Windows NT 6.0/ },
    //             { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    //             { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    //             { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    //             { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    //             { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    //             { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    //             { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    //             { s: 'Windows CE', r: /Windows CE/ },
    //             { s: 'Windows 3.11', r: /Win16/ },
    //             { s: 'Android', r: /Android/ },
    //             { s: 'Open BSD', r: /OpenBSD/ },
    //             { s: 'Sun OS', r: /SunOS/ },
    //             { s: 'Linux', r: /(Linux|X11)/ },
    //             { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    //             { s: 'Mac OS X', r: /Mac OS X/ },
    //             { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    //             { s: 'QNX', r: /QNX/ },
    //             { s: 'UNIX', r: /UNIX/ },
    //             { s: 'BeOS', r: /BeOS/ },
    //             { s: 'OS/2', r: /OS\/2/ },
    //             { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    //         ];
    //         for (var id in clientStrings) {
    //             var cs = clientStrings[id];
    //             if (cs.r.test(nAgt)) {
    //                 os = cs.s;
    //                 break;
    //             }
    //         }

    //         var osVersion: any = '';

    //         if (/Windows/.test(os)) {
    //             let o: any = /Windows (.*)/.exec(os)
    //             osVersion = o[1];
    //             os = 'Windows';
    //         } else if (os === 'Mac OS X') {
    //             let o: any = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)
    //             osVersion = o[1]
    //         } else if (os === 'Android') {
    //             let o: any = /Android ([\.\_\d]+)/.exec(nAgt)
    //             osVersion = o[1];
    //         } else if ('iOS') {
    //             osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
    //             osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
    //         }
    //         setosversion(osVersion)
    //         setbrowserversion(version)
    //         setbrowser(browser)


    //         let jscd: any = {
    //             browser: browser,
    //             browserVersion: version,
    //             mobile: mobile,
    //             os: os,
    //             osVersion: osVersion,
    //             cookies: cookieEnabled,
    //         };
    //         console.log(jscd)
    //         // alert(osVersion)
        
    // }










































    // function CreateUUID() {
    //     var navigator_info = window.navigator;
    //     var screen_info = window.screen;
    //     var uid: any = navigator_info.mimeTypes.length;
    //     uid += navigator_info.userAgent.replace(/\D+/g, '');
    //     uid += navigator_info.plugins.length;
    //     uid += screen_info.height || '';
    //     uid += screen_info.width || '';
    //     uid += screen_info.pixelDepth || '';
    //     console.log(uid);
    //     setuuid(uid)
    //     let sys: any = navigator
    //     const { userAgentData } = sys
    //     console.log(userAgentData)
    //     console.log(userAgentData.platform)
    //     // alert(uid +userAgentData.platform)
    //     setplatformname(userAgentData.platform)
    //     console.log(navigator_info.userAgent)
    //     setsysname(navigator_info.userAgent)


    // }
    // useEffect(() => {
    //     // Getlocation(dispatch)
    //     // CreateUUID()
    //     // that()
    // }, [])



    const success = (data: any) => {

        console.log(data)
        let response = data
        response['rememberme'] = `${checked}`

        //search history
        response['event_history']= []
        response['people_history'] =[]
        response['business_history'] = []
        // response['search_history']= {
        //     business: [],
        //     events: [],
        //     people: []
        // }
        
        let unparseddetails: any = localStorage.getItem('pdetails')
        localStorage.setItem('remember', `${checked}`)

        if(data.device){
            localStorage.setItem('device_id',data.device.id)
            localStorage.setItem('uuid',data.device.unique_id)
        }

        if(unparseddetails){
            let parseddetails = JSON.parse(unparseddetails)
            let newdetails: any = [response].concat(parseddetails)
            if(newdetails.length > 5){
                newdetails = newdetails.filter((itm: any,key: number)=>key < 5)
            }
            localStorage.setItem('pdetails',JSON.stringify(newdetails))
        }else{
            let newdetails: any = [response]
            localStorage.setItem('pdetails',JSON.stringify(newdetails))
        }
        if (data.is_business_profile) {
            localStorage.setItem(`mtusrtype`, 'business')
        } else {
            localStorage.setItem(`mtusrtype`, 'personal')
        }
        localStorage.setItem(`mtusr`, JSON.stringify(response))
        Auth('login', data.access, data.refresh);


        








        // if(data.device){
        //     localStorage.setItem('device_id0',data.device.id)
        //     localStorage.setItem('uuid0',data.device.unique_id)
        // }
        // localStorage.setItem('remember', `${checked}`)

        // if (data.is_business_profile) {
        //     localStorage.setItem(`mtusr${0}`, JSON.stringify(data))
        //     localStorage.setItem(`mtusr${0}type`, 'business')
        //     Auth('login', data.access, data.refresh);
        // } else {
        //     localStorage.setItem(`mtusr${0}`, JSON.stringify(data))
        //     localStorage.setItem(`mtusr${0}type`, 'personal')
        //     Auth('login', data.access, data.refresh);
        // }






        // for (let i = 0; i < 5; i++) {
        //     if(localStorage.getItem(`user${i}`)){
        //         if(i ===5){
        //             alert('no more then 5 users allowed')
        //         }
        //         continue;
        //     }else{
        //         localStorage.setItem(`user${i}`,data)
        //         Auth('login', data.access, data.refresh);
        //     }

        // }



        dispatch(loginSuccess(data));
        if (state) {
            history.push(state.from)
        }
    }
    const failed = (data: any) => {
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === 404) {
            head = 'Incorrect email or password.'
            message = 'please provide valid credentials.'
        } else if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try refreshing the page.'
        }
        dispatch(loginFailed({
            status: status,
            text: text,
            dev_data: dev_data,
            app_data: app_data,
            head: head,
            message: message,
        }));
    }
    const Signin = () => {
        let notif = localStorage.getItem('notification_token0')
        let deviceid = localStorage.getItem('device_id')
        let uid0 = localStorage.getItem('uuid')
        var data = {
            "username": mail,
            "password": password,
            "device_id": deviceid? deviceid: null,
            "name": browserName+browserVersion,
            "unique_id": uid0?uid0:uuid,
            "latitude": lat,
            "longitude": lng,
            "city": city,
            "country": country,
            "notification_token": notif ? notif : '',
            "installed_app_version": '',
            "os": os,
            "os_version": osversion
        }

        if (mail !== null && password !== null) {
            dispatch(loginRequest())
            Api('post', "/api/v1/user/login/", data, success, failed, history, false, true)
        }
    }


    return (
        <Layout className='lgcontainer'>
            <div className='lg'>
                <h1>Login</h1>
                <p>{'Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events.'}</p>
                {(error && !(Object.keys(error).length === 0)) && <div className='errorMessage'>
                    <p>{error.head + ' ' + error.message}</p>
                    {/* <p>{error.message}</p> */}
                </div>}
                <form className='lgform'
                    onSubmit={
                        e => {

                            e.preventDefault();
                            e.stopPropagation();
                            Signin()
                        }
                    }
                >
                    <label className='lglabel'>
                        {/* Username / Email address / Mobile number */}
                        Your email address
                        <small className='validateerror'>{validatemail}</small>
                    </label>
                    <input id='mail' type='text' className='lgmail' placeholder='help@mutualevents.com'
                        // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                        required value={mail}
                        onChange={e => { setmail(e.currentTarget.value) }}
                        // onInvalid={this.setCustomValidity('Please Enter valid email')}
                        title='Username / Email address / Mobile number'
                    // oninput="setCustomValidity('')"
                    ></input>
                    <label className='lglabel'>Password
                        <small className='validateerror'>{validatepassword}</small>
                    </label>
                    <div className='loginpasswordwrapper'>
                        <input id='password' type={visible ? 'text' : 'password'} className='lgpassword' placeholder='••••••••••••••'
                            title='password'
                            pattern=".{6,}" required value={password} onChange={e => { setpassword(e.currentTarget.value) }}></input>
                        {
                            !visible ? <BiHide className='bihide_login' onClick={() => {
                                setvisible(true)
                            }} /> : <BiShow className='bishow_login' onClick={() => {
                                setvisible(false)
                            }} />
                        }

                    </div>


                    <div className="rememberDiv">
                        <div className={checked ? 'lgbox checked' : 'lgbox'} onClick={e => {
                            setchecked(!checked)
                        }}>
                            {checked && <Tick />}
                        </div>
                        <p>Remember me</p>
                        <Link to="/forgetpassword">Forgot Password?</Link>
                    </div>
                    <button type="submit"
                        className="lgbtn" onClick={() => {
                            let notif = localStorage.getItem('notification_token0')
                            console.log({
                                "username": "tech@mutualevents.com",
                                "password": "##Mutualevents00@",
                                "device_id": '',
                                "name": browserName+browserVersion,
                                "unique_id": uuid,
                                "latitude": lat,
                                "longitude": lng,
                                "city": city,
                                "country": country,
                                "notification_token": notif ? notif : '',
                                "installed_app_version": '',
                                "os": os,
                                "os_version": osversion
                            })
                        }}>
                        {
                            loading === false ? (
                                "Login"
                            ) : (
                                <div style={{ color: 'white' }}>
                                    <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />
                                </div>
                            )
                        }
                    </button>
                </form>
                <div className="lgsignin">
                    <p>Don't have an account? </p>
                    <Link to="/signup">Sign Up</Link>
                </div>
            </div>
        </Layout>
    )
}

export default Login
