import {   createSlice,   } from '@reduxjs/toolkit';
import { CategoryState } from './interface';

const initialState: CategoryState = {
    loading: false,
    categories: [],
    error: null
};
export const exploreSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        categoriesRequest: (state) =>{
            state.loading = true
        },
        categoriesSuccess: (state,action: any)=>{
            state.loading = false;
            state.categories = action.payload;
            state.error = null;
        },
        categoriesFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        }

    }
})
export const { categoriesRequest,categoriesSuccess,categoriesFailed } = exploreSlice.actions
export default exploreSlice.reducer