import React, { useState, useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MapLocation from '../../../smallcomponents/maplocation/MapLocation';
import dayjs from 'dayjs';
import './buyticket.css';
import { Select } from '@mui/material';
import { Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem } from '@material-ui/core';
import { useAppSelector } from '../../../../app/hooks';
import { ReactComponent as Tick } from '../../../../assets/svg/lgcheck.svg';
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg';
import SelectAppusers from './SelectAppusers';
import CommonProfileSelect from '../../../utils/CommonProfileSelect';
import SelectNonuser from './SelectNonuser';
import Close from '@material-ui/icons/Close';
import Api from '../../../../app/api';
import { useHistory } from 'react-router-dom';
import ViewTicket from './ViewTicket';
import { FailedToast, SucessToast } from '../../../utils/toasts';
import { ArrowBack } from '@material-ui/icons';
import { BiArrowBack } from 'react-icons/bi';
import StripeWrapper from '../../../../paymentgateway/StripeWrapper';
import PaypalWrapper from '../../../../paymentgateway/PaypalWrapper';
import { log } from './../../../utils/log';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { Scrollbars } from 'react-custom-scrollbars-2';

import PostcontentModerationLoader from '../../../Tabs/contentmoderator/postcontent/PostcontentModerationLoader';
const style: any = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	// minHeight: '680px',
	// height: '65vh',
	height: 'auto',
	minWidth: 400,
	// width: '50vw',
	width: '100%',
	maxWidth: '788px',
	bgcolor: 'background.paper',
	borderRadius: '12px',
	// border: '2px solid #000',
	boxShadow: 24,
	transition: '0.3s ease-in',
	// p: 4,
};

//=========================================== TICKET COMPONENT

const Ticket = ({ item, start_date, end_date, start_timezone, end_timezone, SelectTicket }: any) => {
	console.log('MALA', item);
	console.log('Sdsd', item?.max_quantity);
	return (
		// This div is last commented
		<div
			className='tikcet__container'
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				// border: '1px solid var(--cl_border_grey)',
				width: '100%',
				padding: '5px',
				borderRadius: '10px',
				boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
			}}
			onClick={() => {
				SelectTicket(item);
			}}>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0px 20px 0px 4px' }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ padding: '3px 0px' }}>
						<h2>{item.category_name}</h2>
					</div>

					<div style={{ padding: '3px 0px' }}>
						<h5 style={{ fontWeight: 400 }}>{item.available_tickets} REMAINING</h5>
					</div>
				</div>

				<div style={{ padding: '3px 0px', display: 'flex', alignItems: 'center' }}>
					<h3>{Number(item?.price) === 0 ? 'FREE' : `${item.price}$`}</h3>
				</div>
			</div>

			{/* 
            <div style={{ width: '100px' }}>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">no</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Selected}
                        label="nos"
                        onChange={(e) => setSelected(e.target.value)}
                    >
                   
                        {[...Array(item?.max_quantity)].map((e, i) => {
                            return <MenuItem key={i} value={i}>{i}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div> */}

			{/* <div>
				<span>Date</span>

				<h4>
					{dayjs.utc(start_date).local().format('DD') === dayjs.utc(end_date).local().format('DD')
						? dayjs.utc(start_date).local().format('ddd, DD MMM YYYY')
						: dayjs.utc(start_date).local().format('ddd, DD MMM') + ' - ' + dayjs.utc(end_date).local().format('ddd, DD MMM YYYY')}
				</h4>
			</div>
			<div>
				<span>Time</span>
				<h4>{dayjs.utc(start_date).local().format('hh:mm A') + ' - ' + dayjs.utc(end_date).local().format('hh:mm A')}</h4>
				{start_timezone && end_timezone && <h4>{start_timezone === end_timezone ? start_timezone : start_timezone - end_timezone}</h4>}
			</div> */}
		</div>
	);
};

//=============================================================>

const BuyEventTicketModal = ({ open, handleClose, event, callBack }: any) => {
	const [Selected, setSelected] = useState<any>();
	const [loader, setloader] = useState<any>(false);

	console.log('Selected', Selected);

	const [CurrentState, setCurrentState] = useState(0);

	var ifConnected = window.navigator.onLine;

	console.log(event, 'mal');

	const SelectTicket = (ticket: any) => {
		setSelected(ticket);
		setCurrentState((prev) => prev + 1);
	};

	const profileid = useAppSelector((state) => state.profile?.profile?.id);
	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);

	const [forme, setforme] = useState(true);
	const [forothers, setforothers] = useState(false);

	const [open1, setOpen1] = useState(false);
	const handleClose1 = () => setOpen1(false);

	const [open2, setOpen2] = useState(false);
	const handleClose2 = () => setOpen2(false);

	const [appusers, setappusers] = useState<any>([]);
	const [nonusers, setnonusers] = useState<any>([]);
	const [totalusers, setTotalusers] = useState<any>([]);

	const [name, setname] = useState('');
	const [email, setemail] = useState('');

	const [purchased, setpurchased] = useState<any>();

	const [value, setValue] = useState('');
	const [code, setCode] = useState('+61');
	const [valid, setValid] = useState(false);

	console.log('nonusers', nonusers);
	let history = useHistory();

	const removeNon = (email: string) => {
		let CurData = [...nonusers];
		let newdata = CurData.filter((e) => e.email !== email);
		setnonusers(newdata);
	};
	const removeApp = (id: string) => {
		let CurData = [...appusers];
		let newdata = CurData.filter((e) => e.id !== id);
		setappusers(newdata);
	};
	const Previous = () => {
		setCurrentState((prev) => (prev > 0 ? prev - 1 : prev));
	};

	const LastStage = () => {
		setCurrentState((prev) => prev + 1);
		let users: any = [];
		for (let i = 0; i < appusers.length; i++) {
			users.push({
				purchased_user_type: 'user',
				profile: appusers[i].id,
				name: null,
				email: null,
				country_code: null,
				phone: null,
			});
		}
		for (let i = 0; i < nonusers.length; i++) {
			users.push({
				purchased_user_type: 'non-user',
				profile: null,
				name: nonusers[i].name,
				email: nonusers[i].email,
				country_code: nonusers[i].country_code,
				phone: nonusers[i].phone,
			});
		}
		if (forme) {
			users.push({
				purchased_user_type: 'user',
				profile: profileid,
				name: null,
				email: null,
				country_code: null,
				phone: null,
			});
		}

		setTotalusers(users);
	};

	const [currentAvailable, setcurrentAvailable] = useState<any>(null);

	const Buyticket_Fx = () => {
		// CheckAvailability()
		setloader(true);

		let data = {
			'number_of_tickets': totalusers.length,
			'ticketdata': totalusers,
			'profile': Selected?.pricing_category === 'Free' ? 0 : profileid,
		};
		let theurl = `/api/v1/event/ticket/availability/${Selected.id}/`;

		const IF_AVAILABLE = (res: any) => {
			console.log('--------available -----', res);
			console.log('00 id', res?.ticket_details_id);
			console.log('--selected price', Selected?.price);
			setcurrentAvailable(res);
			let storeData = {
				'number_of_tickets': totalusers.length,
				'ticketdata': totalusers,
				'profile': Selected?.pricing_category === 'Free' ? 0 : profileid,
				'event': event,
				'currentAvailable': res,
			};

			sessionStorage.setItem('ti-Ed', JSON.stringify(storeData));
			if (Selected?.price > 0) {
				// setCurrentState(4);
				console.log('------------------printed-------------------------', Selected);
				console.log('ticket details id');

				stripeApiFn(res);

				// const [CurrentState, setCurrentState] = useState(0);
			} else {
				const success = (data: any) => {
					console.log(' Sucess data', data);
					setpurchased(data?.data);
					setCurrentState(3);
					SucessToast('Ticket Purchased Sucessfully!');
					callBack(data);
					setloader(false);
				};
				const failed = (data: any) => {
					console.log('Failed data', data);
					FailedToast(data?.app_data);
					setloader(false);
				};
				let theurl = `/api/v1/event/ticket/buy/`;

				if (unparseddetails) {
					if (currentparsed.is_business_profile) {
						theurl = `/api/v1/event/ticket/buy/?auth_profile=${parseddetails[0].id}`;
					}
				}

				let data = {
					'ticket_config': Selected.id,
					'users': totalusers,
				};

				console.log('THY', data);
				Api('post', theurl, data, success, failed, history, true, true);
			}
		};
		const IF_ERROR = (error: any) => {};

		Api('post', theurl, data, IF_AVAILABLE, IF_ERROR, history, true, true);
	};

	type paymenttype = 'Paypal' | 'Stripe';

	const [paymentMethod, setpaymentMethod] = useState<null | paymenttype>(null);

	const selectPaymentMethod = (type: paymenttype) => {
		// setpaymentMethod(type);
	};

	const stripeApiFn = (res: any) => {
		// const success1 = (data: any) => {
		// 	console.log('stripe buy response', data)
		// }
		// const failed1 = () => {

		// }

		let amount: any = (res?.application_charges.variable_charge / 100) * 10 + res?.application_charges.fixed_rate;
		let application_fee_amount = amount * 100;

		// let amount = (Selected?.price) / 10
		// let intData = parseInt(amount)
		// console.log(Math.round(amount))
		console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', application_fee_amount);
		console.log('8888***************** ticket details id**************', res?.ticket_details_id);
		let postdata: any = {
			'currency': event?.connected_account_details?.connected_account_country === 'IN' ? 'INR' : 'AUD',
			'success_url': `${process.env.REACT_APP_SITELINK}ticket-confirm`,
			// 'success_url': 'http://localhost:3000/ticket-confirm',
			'cancel_url': `${process.env.REACT_APP_SITELINK}`,
			'payment_method_types[0]': 'card',
			'line_items[0][price]': Selected?.stripe_price_id,
			'line_items[0][quantity]': totalusers.length,
			'mode': 'payment',
			'payment_intent_data[application_fee_amount]': application_fee_amount,
			'payment_intent_data[description]': `event name: ${event?.name}`,
			'metadata[ticket_details_id]': res?.ticket_details_id.toString(),
			'payment_intent_data[transfer_data][destination]': event?.connected_account_id,
		};
		// let d = new FormData()
		// d.append('currency', 'AUD')
		// d.append('cancel_url', 'https://www.google.com/')
		// d.append('success_url', 'https://mutualevents.com/')
		// d.append('payment_method_types[0]', 'card')
		// d.append('line_items[0][price]', Selected?.stripe_price_id)
		// d.append('line_items[0][quantity]', totalusers.length)
		// d.append('mode', 'payment')
		// d.append('payment_intent_data[application_fee_amount]', `${Number(Selected?.price / 10)}`)
		// d.append('payment_intent_data[description]', `event name: ${event?.name}`)
		// d.append('metadata[ticket_details_id]', currentAvailable.ticket_details_id.toString())
		// d.append('payment_intent_data[transfer_data][destination]', event?.connected_account_i)

		var formBody: any = [];
		for (var property in postdata) {
			var encodedKey = encodeURIComponent(property);
			var encodedValue = encodeURIComponent(postdata[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		formBody = formBody.join('&');

		console.log('========================================post data===================', postdata);
		// Api('post', 'https://api.stripe.com/v1/checkout/sessions', postdata, success1, failed1, history, true, true)
		///dev
		// let stripeMerchantIdentifierTest = 'sk_test_51KqXXGCdQnqEa9PGNLpA1qSZgFzC7k7elLlYk2YvFgUHg2YCWvNNbAs7YE0AL0Gj1NRn7Lfr4iPSPoMZ3XoRm9ks00r2jFf81y';
		let stripeMerchantIdentifierTest = process.env.REACT_APP_STRIPE_KEY;

		if (event?.connected_account_details?.connected_account_country === 'IN') {
			stripeMerchantIdentifierTest = process.env.REACT_APP_STRIPE_KEY_IND;

			// stripeMerchantIdentifierTest = "sk_test_51MGyRPSE73io8AUj1v8oOpYSrdnEyMKU14wAE2MVbpYg7Mu6ZdXex1SKnaXqeF4DnUgrsdRvo1OYEaddTq7T4P1T00VMHeSfsh"
		}

		///prod
		// const stripeMerchantIdentifierTest = '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';
		const theurl = 'https://api.stripe.com/v1/checkout/sessions';
		axios.post(theurl, formBody, {
			headers: {
				'Authorization': `Bearer ${stripeMerchantIdentifierTest}`,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})
			.then((res: any) => {
				// console.log('------------------stripe response----', res)
				const link = document.createElement('a');
				link.href = res?.data.url;
				link.click();
			})
			.catch((err) => {
				console.log('ERROR: ====', err);
			});
	};

	console.log('appusers', appusers);

	return (
		<>
			<Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1rem 2rem .5rem 3rem' }}>
						<h2> Buy Ticket</h2>
						<h5> Too excited to get along! Book your seats now.</h5>
					</div>

					<div
						style={{
							padding: '1rem 3rem',
							display: 'flex',
							gap: '1rem',
							alignItems: 'center',
							borderBottom: '1px solid var(--cl_border_grey)',
						}}>
						<div className='buyticket_eventimage'>
							{event?.event_image
								? event?.event_image.slice(0, 1).map((item: any, key: number) => {
										const { image } = item;
										return <img alt='Event_cover_image' src={image} />;
								  })
								: null}
						</div>
						<div>
							<h4>{event?.name}</h4>

							{ifConnected ? (
								<MapLocation
									latitude={JSON.parse(event?.latitude)}
									longitude={JSON.parse(event?.longitude)}
									locationtext={event?.location}
									location_image={''}
									eventpage={false}
								/>
							) : (
								<span>error</span>
							)}
						</div>
					</div>

					{/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ FIRST STEP :: SELECT TICKET TYPE

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

					{CurrentState === 0 && (
						// <div
						// 	className={'buytikcet__stage'}
						// 	style={{
						// 		padding: '1rem 3rem',
						// 		display: 'flex',
						// 		flexDirection: 'column',
						// 		minHeight: '50vh',
						// 		gap: '.5rem',
						// 		alignItems: 'center',
						// 		overflow: 'auto',
						// 	}}>
						// 	{event?.tickets &&
						// 		event?.tickets.map((item: any, key: number) => {
						// 			return (
						// 				<Ticket
						// 					key={key}
						// 					item={item}
						// 					start_date={event?.start_date}
						// 					end_date={event?.end_date}
						// 					start_timezone={event?.start_timezone}
						// 					end_timezone={event?.end_timezone}
						// 					SelectTicket={SelectTicket}
						// 				/>
						// 			);
						// 		})}
						// </div>
						<Box
							style={{}}
							sx={{
								width: '100%',
								justifyContent: 'center',
								padding: '20px',
								height: event?.tickets.length < 3 ? '220px' : '400px',
							}}>
							{
								// event?.tickets.length === 1 ?
								// 	<div className={'ticket-grid-container'} onClick={() => {
								// 		SelectTicket(event?.tickets[0]);
								// 	}} style={{

								// 	}}>
								// 		<h4 className='ticket-grid-container-head' style={{ color: "gray", maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", }}>{event?.tickets[0].category_name.toUpperCase()}</h4>
								// 		<span style={{ fontSize: ".7rem", marginTop: "6px" }}>{`${event?.tickets[0].available_tickets} REMAINING`}</span>
								// 		<span style={{ marginTop: "10px", fontWeight: "bold", color: "black" }}>{Number(event?.ticket[0].price) === 0 ? 'FREE' : `${event?.ticket[0].price}$`}</span>

								// 	</div>
								// 	:
								<Scrollbars style={{ height: '100%', width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
										{event?.tickets &&
											event?.tickets.map((item: any, key: number) => {
												return (
													<Grid item xs={6}>
														<div
															className={'ticket-grid-container'}
															onClick={() => {
																SelectTicket(item);
															}}
															style={{
																margin: '15px',
																borderRadius: '8px',
																height: '150px',
																width: '330px',
																padding: '14px',
																flexDirection: 'column',
																border: 'solid 1px',
																borderColor: 'lightgray',
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
															}}>
															<h4
																className='ticket-grid-container-head'
																style={{
																	color: 'gray',
																	maxWidth: '100%',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																}}>
																{item?.category_name.toUpperCase()}
															</h4>
															<span
																style={{
																	fontSize: '.7rem',
																	marginTop: '6px',
																}}>{`${item?.available_tickets} REMAINING`}</span>
															<span style={{ marginTop: '10px', fontWeight: 'bold', color: 'black' }}>
																{Number(item?.price) === 0 ? 'FREE' : `${item.price}$`}
															</span>
														</div>
														{/* <div style={{ justifyItems: "center", display: "flex", justifyContent: "center" }}>
												<Button variant="contained">Purchase</Button>
											</div> */}
													</Grid>
												);
											})}
									</Grid>
								</Scrollbars>
							}
						</Box>
					)}

					{/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ SECOND STEP :: SELECT USERS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

					{CurrentState === 1 && (
						<div
							className={'buytikcet__stage'}
							style={{
								padding: '1rem 3rem',
								display: 'flex',
								flexDirection: 'column',
								minHeight: '50vh',
								gap: '.5rem',
								alignItems: 'center',
								overflow: 'auto',
								justifyContent: 'space-between',
							}}>
							<BiArrowBack
								style={{ position: 'absolute', left: 10, top: 8, width: '25px', height: '25px' }}
								onClick={Previous}
							/>
							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', padding: '1.5rem 0', width: '100%' }}>
								<div>
									<span>Created By</span>
									<h4>{event?.profile ? event?.profile.firstname + ' ' + event?.profile.surname : null}</h4>
								</div>
								<div>
									<span>Category</span>
									<h4>{Selected?.category_name}</h4>
								</div>
								<div>
									<span>Tickets left</span>
									<h4>{Selected?.available_tickets}</h4>
								</div>
								<div>
									<span>price</span>
									<h4>{Selected?.price}$</h4>
								</div>
								<div>
									<span>Date</span>

									<h4>
										{dayjs.utc(event?.start_date).local().format('DD') ===
										dayjs.utc(event?.end_date).local().format('DD')
											? dayjs.utc(event?.start_date).local().format('ddd, DD MMM YYYY')
											: dayjs.utc(event?.start_date).local().format('ddd, DD MMM') +
											  ' - ' +
											  dayjs.utc(event?.end_date).local().format('ddd, DD MMM YYYY')}
									</h4>
								</div>
								<div>
									<span>Time</span>
									<h4>
										{dayjs.utc(event?.start_date).local().format('hh:mm A') +
											' - ' +
											dayjs.utc(event?.end_date).local().format('hh:mm A')}
									</h4>
									{event?.start_timezone && event?.end_timezone && (
										<h4>
											{event?.start_timezone === event?.end_timezone
												? event?.start_timezone
												: event?.start_timezone - event?.end_timezone}
										</h4>
									)}
								</div>
							</div>

							<div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
								<div style={{ display: 'flex', gap: '1rem' }}>
									<div
										className={forme ? 'lgbox checked' : 'lgbox'}
										onClick={(e) => {
											setforme(!forme);
										}}>
										{forme && <Tick />}
									</div>
									<p>For Me</p>
								</div>
								<div style={{ display: 'flex', gap: '1rem' }}>
									<div
										className={forothers ? 'lgbox checked' : 'lgbox'}
										onClick={(e) => {
											setforothers(!forothers);
										}}>
										{forothers && <Tick />}
									</div>
									<p>For Others</p>
								</div>
								{forothers && (
									<div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
										<div
											className='cursor'
											onClick={() => {
												// setindex(key)
												setOpen1(true);
											}}
											style={{
												display: 'flex',
												gap: '10px',
												height: '50px',
												padding: ' 15px 10px',
												border: '1px solid rgba(125, 125, 141, 0.16)',
												borderRadius: '8px',
											}}>
											<div
												className=''
												style={{
													width: '100%',
													display: 'grid',
													gridTemplateColumns: '1fr 20px',
													gap: '2rem',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}>
												<div className='ticket_category_itemdetails'>
													<span
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															flexDirection: 'column',
															position: 'relative',
														}}>
														<h4>Select appuser</h4>
														<div
															className='scrollbar-hidden'
															style={{
																position: 'absolute',
																left: '110px',
																top: -8,
																display: 'flex',
																alignItems: 'center',
																gap: '10px',
																overflowX: 'scroll',
																width: '550px',
															}}>
															{appusers.map((item: any, key: number) => {
																return (
																	<div
																		key={key}
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'space-around',
																			padding: '5px 8px',
																			border: '1px solid var(--cl_border_grey)',
																			borderRadius: '8px',
																			whiteSpace: 'nowrap',
																			wordBreak: 'keep-all',
																		}}>
																		{item?.firstname}
																		{item?.lastname}
																		<Close
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();
																				removeApp(item?.id);
																			}}
																		/>
																	</div>
																);
															})}
														</div>
													</span>
												</div>

												<Arrow />
											</div>
										</div>
										<div
											className='cursor'
											onClick={() => {
												// setindex(key)
												setOpen2(true);
											}}
											style={{
												display: 'flex',
												gap: '10px',
												height: '50px',
												padding: ' 15px 10px',
												border: '1px solid rgba(125, 125, 141, 0.16)',
												borderRadius: '8px',
											}}>
											<div
												className=''
												style={{
													width: '100%',
													display: 'grid',
													gridTemplateColumns: '1fr 20px',
													gap: '2rem',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}>
												<div className='ticket_category_itemdetails'>
													<span
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															flexDirection: 'column',
															position: 'relative',
														}}>
														<h4>Add Non user</h4>
														<div
															className='scrollbar-hidden'
															style={{
																position: 'absolute',
																left: '110px',
																top: -8,
																display: 'flex',
																alignItems: 'center',
																gap: '10px',
																overflowX: 'scroll',
																width: '550px',
															}}>
															{nonusers.map((item: any, key: number) => {
																return (
																	<div
																		key={key}
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'space-around',
																			padding: '5px 8px',
																			border: '1px solid var(--cl_border_grey)',
																			borderRadius: '8px',
																			whiteSpace: 'nowrap',
																			wordBreak: 'keep-all',
																		}}>
																		{' '}
																		{item?.firstname} {item?.name}
																		<Close
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();
																				removeNon(item?.email);
																			}}
																		/>
																	</div>
																);
															})}
														</div>
													</span>
												</div>
												<Arrow />
											</div>
										</div>
									</div>
								)}
							</div>

							<button className='mainBtn' style={{ alignSelf: 'flex-end' }} onClick={LastStage}>
								Continue
							</button>
						</div>
					)}

					{/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ THIRD STEP :: PURCHASE TICKET USERS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

					{CurrentState === 2 && (
						<div
							className={'buytikcet__stage'}
							style={{
								padding: '1rem 3rem',
								display: 'flex',
								flexDirection: 'column',
								minHeight: '50vh',
								gap: '.5rem',
								alignItems: 'center',
								overflow: 'auto',
								justifyContent: 'space-between',
							}}>
							<BiArrowBack
								style={{ position: 'absolute', left: 10, top: 8, width: '25px', height: '25px' }}
								onClick={Previous}
							/>

							<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', padding: '1.5rem 0', width: '100%' }}>
								<div>
									<span>Created By</span>
									<h4>{event?.profile ? event?.profile.firstname + ' ' + event?.profile.surname : null}</h4>
								</div>
								<div>
									<span>Category</span>
									<h4>{Selected?.category_name}</h4>
								</div>
								<div>
									<span>Tickets left</span>
									<h4>{Selected?.available_tickets}</h4>
								</div>
								<div>
									<span>Price</span>
									<h4>{Selected?.price}$</h4>
								</div>
								<div>
									<span>Purchable Tickets</span>
									<h4>{Selected?.price}$</h4>
								</div>
								<div>
									<span>Date</span>

									<h4>
										{dayjs.utc(event?.start_date).local().format('DD') ===
										dayjs.utc(event?.end_date).local().format('DD')
											? dayjs.utc(event?.start_date).local().format('ddd, DD MMM YYYY')
											: dayjs.utc(event?.start_date).local().format('ddd, DD MMM') +
											  ' - ' +
											  dayjs.utc(event?.end_date).local().format('ddd, DD MMM YYYY')}
									</h4>
								</div>
								<div>
									<span>Time</span>
									<h4>
										{dayjs.utc(event?.start_date).local().format('hh:mm A') +
											' - ' +
											dayjs.utc(event?.end_date).local().format('hh:mm A')}
									</h4>
									{event?.start_timezone && event?.end_timezone && (
										<h4>
											{event?.start_timezone === event?.end_timezone
												? event?.start_timezone
												: event?.start_timezone - event?.end_timezone}
										</h4>
									)}
								</div>
								<div>
									<span>Total Quantity</span>
									<h4>{totalusers.length}</h4>
								</div>
							</div>
							<button
								className='mainBtn'
								style={{ alignSelf: 'flex-end' }}
								onClick={() => {
									if (totalusers.length <= Selected?.max_quantity) {
										Buyticket_Fx();
									} else {
										FailedToast(`You can Only Purchase  ${Selected?.max_quantity} tickets`);
									}
								}}>
								{loader ? <CircularProgress size={20} color='inherit' /> : 'Purchase'}
							</button>
						</div>
					)}

					{/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ FOURTH STEP :: CONFIRM TICKETS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

					{CurrentState === 3 && (
						<div
							className={'buytikcet__stage '}
							style={{
								padding: '1rem 3rem',
								display: 'flex',
								flexDirection: 'column',
								minHeight: '50vh',
								gap: '.5rem',
								alignItems: 'center',
								overflow: 'auto',
								justifyContent: 'space-between',
								height: '100%',
								maxHeight: '60vh',
								flexWrap: 'wrap',
							}}>
							<BiArrowBack
								style={{ position: 'absolute', left: 10, top: 8, width: '25px', height: '25px' }}
								onClick={Previous}
							/>
							<h2>Your tickets are here!</h2>

							<div
								className='scrollbar-hidden'
								style={{
									height: '100%',
									maxHeight: '60vh',
									minHeight: '100%',
									padding: '10px',
									flexWrap: 'wrap',
									width: '100%',
									display: 'flex',
									overflow: 'scroll',
									gap: '.5rem',
								}}>
								{purchased?.event?.tickets &&
									purchased?.event?.tickets.map((item: any, key: any) => {
										const { category_name, price, purchase_detail } = item;

										return (
											purchase_detail &&
											purchase_detail.map((i: any, k: any) => {
												return (
													<ViewTicket
														event={purchased?.event}
														item={i}
														key={k}
														category_name={category_name}
														price={price}
													/>
												);
											})
										);

										// return <ViewTicket item={item} key={key}/>
									})}
							</div>
							<button className='mainBtn' style={{ alignSelf: 'flex-end' }} onClick={handleClose}>
								Done
							</button>
						</div>
					)}

					{/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ FOURTH STEP :: IF PRICED TICKET CHOOSE PAYMENT OPTION

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

					{CurrentState === 4 && (
						<Fragment>
							{paymentMethod ? (
								// <div>
								// 	{paymentMethod === 'Stripe' ? (
								// 		<div style={{ opacity: .4, pointerEvents: "none" }}>
								// 			<StripeWrapper data={Selected} />
								// 		</div>
								// 	) : (
								// 		<div className='PaypalWrapper' style={{ maxWidth: '750px', minHeight: '200px' }}>
								// 			<PaypalWrapper Selected={Selected} currentAvailable={currentAvailable} totalusers={totalusers} />
								// 		</div>
								// 	)}
								// </div>
								<div style={{ opacity: 0.4, pointerEvents: 'none' }}>
									{/* <StripeWrapper data={Selected} /> */}
									hello
								</div>
							) : (
								<div
									className={'buytikcet__stage'}
									style={{
										padding: '1rem 3rem',
										display: 'flex',
										flexDirection: 'column',
										minHeight: '50vh',
										gap: '.5rem',
										// alignItems: 'center',
										overflow: 'auto',
										// justifyContent: 'space-between',
									}}>
									{/* <PaymentType
										name={'Stripe'}
										icon={'https://logos-world.net/wp-content/uploads/2021/03/Stripe-Emblem.png'}
										onClick={loadStripe}
									/> */}
									{/* <PaymentType
										name={'Paypal'}
										icon={'https://www.freepnglogos.com/uploads/paypal-logo-png-7.png'}
										onClick={selectPaymentMethod}
									/> */}
									<div
										className='tikcet__container'
										onClick={stripeApiFn}
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											// border: '1px solid var(--cl_border_grey)',
											width: '100%',
											padding: '5px',
											borderRadius: '10px',
											boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
										}}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												width: '100%',
												padding: '0px 20px 0px 4px',
											}}>
											<div
												style={{
													display: 'flex',
													gap: '10px',

													// flexDirection: 'column',
												}}>
												{/* <div
													style={{
														maxWidth: '40px',
														minWidth: '40px',
														maxHeight: '40px',
														minHeight: '40px',
														borderRadius: '8px',
													}}>
													<img style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={"https://logos-world.net/wp-content/uploads/2021/03/Stripe-Emblem.png"} alt='' />
												</div> */}

												<div
													style={{
														padding: '3px 0px',
														display: 'flex',
														alignItems: 'center',
													}}>
													<h5
														style={{
															fontWeight: 700,
															alignItems: 'center',
															fontSize: '1rem',
															verticalAlign: 'middle',
														}}>
														Pay Now
													</h5>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</Fragment>
					)}
				</Box>
			</Modal>

			{/* {open! && <SelectAppusers users={appusers} addusers={setappusers} open={open1} handleClose={handleClose1}/>} */}

			{open1 && <CommonProfileSelect open={open1} handleClose={handleClose1} userList={appusers} setUserList={setappusers} />}

			{open2 && (
				<SelectNonuser
					open={open2}
					handleClose={handleClose2}
					name={name}
					email={email}
					setname={setname}
					setemail={setemail}
					userList={nonusers}
					setUserList={setnonusers}
					value={value}
					setValue={setValue}
					valid={valid}
					setValid={setValid}
					code={code}
					setCode={setCode}
				/>
			)}
		</>
	);
};

export default BuyEventTicketModal;

// function PaymentType({ icon, name, onClick }: any) {
// 	return (
// 		<div
// 			className='tikcet__container'
// 			style={{
// 				display: 'flex',
// 				alignItems: 'center',
// 				justifyContent: 'space-between',
// 				// border: '1px solid var(--cl_border_grey)',
// 				width: '100%',
// 				padding: '5px',
// 				borderRadius: '10px',
// 				boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
// 			}}
// 			onClick={() => {
// 				console.log('--------sripe account clickeed')
// 				onClick(name);
// 				// SelectTicket(item);
// 			}}>
// 			<div
// 				style={{
// 					display: 'flex',
// 					justifyContent: 'space-between',
// 					width: '100%',
// 					padding: '0px 20px 0px 4px',
// 				}}>
// 				<div
// 					style={{
// 						display: 'flex',
// 						gap: '10px',

// 						// flexDirection: 'column',
// 					}}>
// 					<div
// 						style={{
// 							maxWidth: '40px',
// 							minWidth: '40px',
// 							maxHeight: '40px',
// 							minHeight: '40px',
// 							borderRadius: '8px',
// 						}}>
// 						<img style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={icon} alt='' />
// 					</div>

// 					<div
// 						style={{
// 							padding: '3px 0px',
// 							display: 'flex',
// 							alignItems: 'center',
// 						}}>
// 						<h5
// 							style={{
// 								fontWeight: 700,
// 								alignItems: 'center',
// 								fontSize: '1rem',
// 								verticalAlign: 'middle',
// 							}}>
// 							{name}
// 						</h5>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }
