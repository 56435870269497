import React, { useState } from 'react'
import { noData } from '../../../../Data/Data';
import Scrollbars from 'react-custom-scrollbars-2';
import MediaModal1 from '../../../smallcomponents/modals/MediaModal1';
import './hideScrollbar.css'
import { height } from '@mui/system';


const NewStories = ({ story, type }: { story: any, type: string }) => {
    const [modalActive, setActive] = useState(false)
    const [index, setindex] = useState(0)

    return (
        <div className='nw_storieswrapper'>
            {
                (Object.keys(story).length) === 0 ? (
                    type === 'user' ? (
                        <div className='nostory'>
                            <span>user haven't posted any stories yet</span>
                        </div>
                    ) : (
                        <div className='nostory'>
                            <h2>{noData.story.heading}</h2>
                            <span>{noData.story.message}</span>
                        </div>)
                ) : (
                    <Scrollbars style={{minHeight:'210px'}} className='nw_storiesscroll' autoHide>
                        {
                            story.map((item: any, k: number) => {
                                const { story_id, profile, story_file, story_image_thumbnail, story_file_thumbnail, story_image } = item;
                                return (
                                    <div

                                        role="button"
                                        style={{
                                            // border: "1px solid",
                                            display: "inline-block",
                                            margin: "0 10px",
                                            width: "160px",
                                            userSelect: "none",
                                            overflow: 'hidden',
                                            cursor: 'pointer',

                                        }}
                                        tabIndex={0}
                                        className="card storyBox"
                                        onClick={()=>{
                                            setindex(k)
                                            setActive(!modalActive)
                                        }}
                                    >
                                        {
                                            <img alt='Story thumbnail' style={{ width: '160px', height: '200px', objectFit: 'cover' }} src={story_image !== null ? story_image : story_file_thumbnail}></img>
                                        }
                                        {/* <div>
                                  <div>{title}</div>
                                  <div style={{ backgroundColor: visible ? "transparent" : "gray" }}>
                                    visible: {JSON.stringify(visible)}
                                  </div>
                                  <div>selected: {JSON.stringify(!!selected)}</div>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: selected ? "green" : "bisque",
                                    height: "200px"
                                  }}
                                /> */}
                                    </div>
                                )
                            })
                        }
                    </Scrollbars>
                )
            }
            

            {
                modalActive && <MediaModal1 data={story} index={index} type={'story'}
                    open={modalActive} setOpen={setActive}
                />
            }
        </div>
    )
}

export default NewStories
