import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { Fetch_Photos } from './../store/action';
import MediaModal1 from '../../../smallcomponents/modals/MediaModal1';
import { noData } from '../../../../Data/Data';
import { ReactComponent as Media } from '../../../../assets/svg/media.svg';
import { errors } from '../../../../Data/Error';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';

const Photos = () => {
    const [modalActive, setActive] = useState(false)
    const [index, setindex] = useState(0)
    // const { eventId }: any = useParams()
    const { event } = useAppSelector(state => state.event)
    let eventId = event.id;
    console.log(useParams())
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {
        profile:prof
    } = useAppSelector(state => state.profile)
    const { photos, perror, ploading, ppage, ppagination } = useAppSelector(state => state.Post)
    useEffect(() => {
        window.scrollTo(0, 0)
    
    }, [])
    useEffect(() => {
        Fetch_Photos(dispatch, history, eventId, 1, [])

    }, [dispatch, history, eventId])

    const observer: any = useRef()

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && ppagination) {
                let page_no = ppage + 1;
                if (!ploading) {
                    Fetch_Photos(dispatch, history, eventId, page_no, photos);
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [dispatch, history, ppagination, ppage, eventId, photos, ploading])

    function handleProfile(id:any){
        if(id ===prof.id){
            history.push('/profile')
        }else{
            history.push(`/user/${id}`)
        }
        // history.push(`/user/${id}`)
    }
    return (
        <div className='mediaGrid'>
            {
                !((Object.keys(photos).length) === 0) ? (
                    photos?.map((item: any, key: number) => {
                        const { post_file, profile_id, profile_dp, profile_firstname, profile_surname, views_count,profile_username } = item;
                        return (
                            <div ref={photos?.length === key + 1 ? lastBookElementRef : null} key={key} className='videoTileContainer' onClick={
                                () => {
                                    setindex(key);
                                    setActive(!modalActive)
                                }
                            }>
                                <img src={post_file} alt='Post image' className='videoTile'></img>
                                {/* <div className='imgOverlay'>
                            </div> */}
                                <div className='imgOverlay'>
                                    <div className="mediatileuserdetails" onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleProfile(profile_username)
                                    }}>
                                        <span className='mediatileusrdetailswrapper'>
                                            <div className="mediatileusrdp">
                                                <img src={profile_dp} alt="P_I_C" />
                                            </div>
                                            <div className="mediatileusrname"><span>{profile_firstname + '.' + profile_surname}</span></div>
                                        </span>
                                    </div>
                                    <div className="mediatileusrviews"><span>{views_count + ' ' + 'VIEWS'}</span></div>
                                </div>

                            </div>
                            // </div>
                        )
                    })
                    ) : ( (!ploading && perror === null) &&
                        <div className='noData'>
                            <Media />
                            <h2>{noData.media.heading}</h2>
                            <span>{noData.media.message}</span>
                        </div>
                    )
            }
            {ploading &&
                [...Array(10)].map(() => {
                    return <div className='videoTileContainer skeleton'></div>
                })
            }
            {
                perror !== null &&            
                (perror.status === null ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <Nointernet />
                            <h2>{errors.internet.heading}</h2>
                            <span>{errors.internet.message}</span>
                            <button className='mainBtn'>Refresh the page</button>
                        </div>
                    </div>
                ) : perror.status === 500 ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <Server />
                            <h2>{errors.server.heading}</h2>
                            <span>{errors.server.message}</span>
                            <button className='mainBtn'>Try again</button>
                        </div>
                    </div>
                ) : perror.status ===  401? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <NotFound />
                            <h2>{errors.notFound.heading}</h2>
                            <span>{errors.notFound.message}</span>
                        </div>
                    </div>
                ) : (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <NotFound />
                            <h2>{errors.common.heading}</h2>
                            <span>{errors.common.message}</span>
                            <button className='mainBtn'>Refresh the page</button>
                        </div>
                    </div>
                ))
            }
            {
                modalActive && <MediaModal1 data={photos} index={index} type={'all'}
                    open={modalActive} setOpen={setActive}
                />
            }
        </div >
    )
}

export default Photos
