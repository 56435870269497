import React, { useState, useEffect, useRef } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'

import EventcontentModeration from '../eventcontent/EventcontentModeration'
import ReportedcontentModeration from '../reported/ReportedcontentModeration'
import PostcontentModeration from '../postcontent/PostcontentModeration'
import ContentModeratorAnalytics from '../analytics/ContentModeratorAnalytics'
import { moderatedstatssuccess, moderatedstatsfailed, moderatedstatsrequest } from '../store/ContentmoderatorSlice'
import Api from '../../../../app/api'
import { ReactComponent as NotFound } from '../../../../assets/svg/development.svg';



const ContentModeratorPaths = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { profile, loading } = useAppSelector(state => state.profile)

    const sucs = (res: any) => {
        console.log(res)
        dispatch(moderatedstatssuccess(res))
    }
    const err = (error: any) => {
        dispatch(moderatedstatsfailed(error))
    }
    useEffect(() => {

        dispatch(moderatedstatsrequest())
        let url = '/api/v1/permissions/content-moderation/stats/'
        if (profile.role === "Content Moderator") {
            url = '/api/v1/permissions/content-moderation/stats/'
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/stats/'
        }

        if(!profile.is_super_admin){
            Api('get', url, null, sucs, err, history, true, true)
        }
        
    }, [])

    return (
        profile.is_super_admin === true ? (
            <Switch>
                <Route path='/content-moderator/home/post'>
                    <PostcontentModeration />
                </Route>
                <Route path='/content-moderator/home/event'>
                    <EventcontentModeration />
                </Route>
                <Route path='/content-moderator/home/reported'>
                    <ReportedcontentModeration />
                </Route>
                <Route path='/content-moderator/home/help'>
                    <div className='cm_path_one'></div>
                </Route>
                <Route path='/content-moderator/home/analytics'>
                    <ContentModeratorAnalytics />
                </Route>
                <Redirect exact to='/content-moderator/home/post' />
            </Switch>
        ) : profile.role === "Admin" ? (
            <div className='cm_paths'>
                <Switch>
                    <Route path='/content-moderator/home/post'>
                        <PostcontentModeration />
                    </Route>
                    <Route path='/content-moderator/home/event'>
                        <EventcontentModeration />
                    </Route>
                    <Route path='/content-moderator/home/reported'>
                        <ReportedcontentModeration />
                    </Route>
                    <Route path='/content-moderator/home/help'>
                        <div className='cm_path_one'></div>
                    </Route>
                    <Route path='/content-moderator/home/analytics'>
                        <ContentModeratorAnalytics />
                    </Route>
                    <Redirect exact to='/content-moderator/home/post' />
                </Switch>

            </div>
        ) : profile.role === "Content Moderator" ? (
            <div className='cm_paths'>
                <Switch>
                    <Route path='/content-moderator/home/post'>
                        <PostcontentModeration />
                    </Route>
                    <Route path='/content-moderator/home/event'>
                        <EventcontentModeration />
                    </Route>
                    <Route path='/content-moderator/home/reported'>
                        <ReportedcontentModeration />
                    </Route>
                    <Route path='/content-moderator/home/help'>
                        <div className='cm_path_one'>
                            <div className='error min500px'>
                                <div className='errormsg'>
                                    <NotFound />
                                    <h2>Under development</h2>
                                    {/* <span></span> */}
                                </div>
                            </div>
                        </div>
                    </Route>
                    <Route path='/content-moderator/home/analytics'>
                        <ContentModeratorAnalytics />
                    </Route>
                    <Redirect exact to='/content-moderator/home/post' />
                </Switch>

            </div>
        ) : (
            <div></div>
        )

    )
}

export default ContentModeratorPaths