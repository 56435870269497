import React from 'react'
import Intro from "../../assets/images/bglogin.png";
import A from "../../assets/images/1.png";
import B from "../../assets/images/3.png";
import C from "../../assets/images/4.png";
import slide1 from "../../assets/images/avatars.png";
import slide2 from "../../assets/images/slide1.png";
import slide3 from "../../assets/images/slide2.png";
import slide4 from "../../assets/images/slide3.png";
import slide5 from "../../assets/images/slide4.png";
import slide6 from "../../assets/images/slide5.png";


import slide31 from "../../assets/images/slide3-1.png";
import slide32 from "../../assets/images/slide3-2.png";
import slide33 from "../../assets/images/slide3-3.png";
import slide34 from "../../assets/images/slide3-4.png";

import slide41 from "../../assets/images/slide41.png";
import slide42 from "../../assets/images/slide42.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './slider.css'

const Slider = () => {
    return (
        <div className="loginPageIntroSection">
            <img alt="intro" className='carosel background icon' src={Intro}></img>
            <img src={A} className='logobg1' alt="overlay icons" />
            <img src={B} className='logobg2' alt="overlay icons"/>
            <img src={C} className='logobg3' alt="overlay icons"/>
            <div className="introOverlay">
                <Carousel 
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={3000}
                >
                    <div className='carouselContent'>
                        <img src={slide1}  alt="slide" className='slidemain'/>
                        <div className='carouselDescription'>
                            <h3>Manage profile</h3>
                            <span>Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events</span>
                            {/* <span>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</span> */}
                        </div>
                    </div>
                    <div className='carouselContent'>
                        <img src={slide2}  alt="slide" className='slidemain'/>
                        <div className='carouselDescription'>
                            <h3>Join the event</h3>
                            <span>Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events</span>
                            {/* <span>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</span> */}
                        </div>
                    </div>
                    <div className='carouselContent'>
                        <img src={slide3}  alt="slide" className='slidemain'/>

                        <img src={slide31}  alt="slide" className='slide31'/>
                        <img src={slide32}  alt="slide" className='slide32'/>
                        <img src={slide33}  alt="slide" className='slide33'/>
                        <img src={slide34}  alt="slide" className='slide34'/>

                        
                        <div className='carouselDescription'>
                            <h3>Browse new events</h3>
                            <span>Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events</span>
                            {/* <span>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</span> */}
                        </div>
                    </div>
                    <div className='carouselContent'>
                        <img src={slide4}  alt="slide" className='slidemain'/>

                        <img src={slide41}  alt="slide" className='slide41'/>
                        <img src={slide42}  alt="slide" className='slide42'/>
                        <div className='carouselDescription'>
                            <h3>Check the media</h3>
                            <span>Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events</span>
                            {/* <span>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</span> */}
                        </div>
                    </div>
                    <div className='carouselContent'>
                        <img src={slide5}  alt="slide" className='slidemain'/>
                        <div className='carouselDescription'>
                            <h3>Be up to date</h3>
                            <span>Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events</span>
                            {/* <span>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</span> */}
                        </div>
                    </div>
                    <div className='carouselContent'>
                        <img src={slide6}  alt="slide" className='slidemain'/>
                        <div className='carouselDescription'>
                            <h3>Speak to people</h3>
                            <span>Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events</span>
                            {/* <span>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</span> */}
                        </div>
                    </div>

                </Carousel>
            </div>
        </div>
    )
}

export default Slider
