import React, { useEffect, useState } from 'react'

import ContentModeratorContent from '../main/ContentModeratorContent'
import ContentPostItem from './ContentPostItem'
import Contentinfotiles from '../main/Contentinfotiles'
import Moderatedposts from './Moderatedposts'


import { ReactComponent as Rightarrow } from '../../../../assets/svg/cm/arrowright.svg'
import { ReactComponent as Btnnext } from '../../../../assets/svg/cm/btnnext.svg'
import { ReactComponent as ReportIcon } from '../../../../assets/svg/cm/reportedcontent.svg'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'

import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';

import Scrollbars from 'react-custom-scrollbars-2'
import Api from '../../../../app/api'
import { useHistory } from 'react-router-dom'
import {
    pmoderationRequest, pmoderationSuccess, pmoderationFailed, postapprove,
    moderatedpostsrequest, moderatedpostssuccess, moderatedpostsfailed, reportedCommentsRequest, reportedCommentsSuccess
} from '../store/ContentmoderatorSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { errors } from '../../../../Data/Error'
import { CircularProgress } from '@material-ui/core'


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@material-ui/icons/Close";

import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning'
import Contentpostitemloader from './Contentpostitemloader'
import ReportedComments from './ReportedCommentCard'

const style: any = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '50vh',
    minWidth: 400,
    width: '30vw',
    bgcolor: 'background.paper',
    borderRadius: 8,
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};

const PostcontentModeration = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { ppage, postmoderation, ppagination, perror, ploading, mloading,
        moderatedposts, moderatedploading, moderatedppagination, moderatedperror, moderatedppage, moderatedComments, moderatedCommentsLoading, moderatedCommentsPage, moderatedCommentsPagination } = useAppSelector(state => state.moderator)
    const { profile } = useAppSelector(state => state.profile)

    // const [scrollposition, setScrollposition] = useState<any>('')
    const [currentPostId, setCurrentPostId] = useState<any>('')
    const [successmodal, setsuccessmodal] = useState(false)
    const [errormodal, seterrormodal] = useState(false)
    const [warningmodal, setwarningmodal] = useState(false)



    const [sc, setsc] = useState<any>(null)
    const [er, seter] = useState<any>(null)
    const [war, setwar] = useState<any>(null)

    const Dosomething = () => {

    }
    // console.log('=============================id====================', currentPostId)
    const [index, setindex] = useState<any>(0)
    const sucs = (res: any) => {
        // console.log('-------------------------res---------------------------------------', res)
        dispatch(pmoderationSuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const err = (error: any) => {
        dispatch(pmoderationFailed(error))
    }
    const Retry = () => {

    }


    useEffect(() => {
        dispatch(pmoderationRequest({
            no: 1
        }))

        let url = '/api/v1/permissions/content-moderation/post/1/10/'
        if (profile.is_super_admin === true) {
            url = '/api/v1/permissions/content-moderation/escalated/super-admin/1/10/?content_type=post'
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/post/escalated/1/10/'
        }
        Api('get', url, null, sucs, err, history, true, true)
    }, [])


    const [aloading, setaloading] = useState(false)
    const approvesuccess = () => {
        setaloading(false)
        // if(postmoderation.length === index-2){
        //     setindex(0)
        // }


        let currentindex = index
        let nextindex = index + 1
        let data = postmoderation[nextindex]
        let len = postmoderation.length
        if (!data && postmoderation.length === index + 1) {
            if (index > 0) {
                setindex(0)
            }
            dispatch(postapprove({
                index: currentindex,
                status: 'approved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(postapprove({
                index: index,
                status: 'approved'
            }))
        }
    }
    const approvefailed = (data: any) => {
        setaloading(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }

    const Approve = () => {
        let post: any = {
            "post": postmoderation[index].post_id
        }
        setaloading(true)
        let url = '/api/v1/permissions/content-moderation/post/approve/'
        if (profile.is_super_admin === true) {
            post = {
                content_type: "post",
                content_type_id: postmoderation[index].post_id,
                moderation_status: "approve"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/post/escalated/approve/'
        }
        Api('post', url, post, approvesuccess, approvefailed, history, true, true)

    }

    const [disloading, setdisloading] = useState(false)
    const Disapprovesuccess = () => {
        setdisloading(false)
        // if(postmoderation.length === index-2){
        //     setindex(0)
        // }
        let currentindex = index
        let nextindex = index + 1
        let data = postmoderation[nextindex]
        let len = postmoderation.length
        if (!data && postmoderation.length === index + 1) {
            if (index > 0) {
                setindex(0)
            }
            dispatch(postapprove({
                index: currentindex,
                status: 'disapproved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(postapprove({
                index: index,
                status: 'disapproved'
            }))
        }
    }
    const Disapprovefailed = (data: any) => {
        setdisloading(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }
    const Disapprove = () => {
        let post: any = {
            "post": postmoderation[index].post_id
        }
        setdisloading(true)
        let url = '/api/v1/permissions/content-moderation/post/disapprove/'
        if (profile.is_super_admin === true) {
            post = {
                content_type: "post",
                content_type_id: postmoderation[index].post_id,
                moderation_status: "disapprove"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/post/escalated/disapprove/'
        }
        Api('post', url, post, Disapprovesuccess, Disapprovefailed, history, true, true)
    }
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [open1, setOpen1] = React.useState(false);
    const handleClose1 = () => setOpen1(false);

    const ScrollPos = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            console.log(ploading)
            if (ppagination && !ploading) {
                dispatch(pmoderationRequest({
                    no: ppage
                }))
                let url = `/api/v1/permissions/content-moderation/post/${ppage}/20/`
                if (profile.is_super_admin === true) {
                    url = `/api/v1/permissions/content-moderation/escalated/super-admin/${ppage}/10/?content_type=post`
                } else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/post/escalated/${ppage}/20/`
                }
                Api('get', url, null, sucs, err, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }
    const sucs1 = (res: any) => {
        console.log(res)
        dispatch(moderatedpostssuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const err1 = (error: any) => {
        dispatch(moderatedpostsfailed(error))
    }
    const ScrollPos1 = (val: any) => {
        const { top } = val
        // console.log(top)


        if (top >= 0.9) {
            console.log(moderatedploading)
            // console.log('scroll please')
            if (moderatedppagination && !moderatedploading) {
                dispatch(moderatedpostsrequest({
                    no: moderatedppage
                }))
                let mode: any = 'get'
                let url = `/api/v1/permissions/content-moderation/post/moderated/${moderatedppage}/10/`
                if (profile.is_super_admin === true) {
                    mode = 'post'
                    url = `/api/v1/permissions/content-moderation/super-admin/moderated/${moderatedppage}/10/?content_type=post`
                } else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/post/escalated/moderated/${moderatedppage}/10/`
                }
                Api(mode, url, null, sucs1, err1, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }
    const commentListPos = (val: any) => {
        const { top } = val;
        // console.log(top)
        if (top >= 0.9) {
            // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            // console.log('-----------------------pagination page----------------', moderatedCommentsPage)
            if (moderatedCommentsPagination) {
                dispatch(reportedCommentsRequest({
                    no: moderatedCommentsPage
                }))
                let theUrl = `/api/v1/permissions/content-moderation/comments/${currentPostId}/${moderatedCommentsPage}/5/`

                const success1 = (res: any) => {
                    // console.log('******************************res*********************', res);

                    dispatch(reportedCommentsSuccess({
                        data: res,
                        pagination: res.length < 5 ? false : true
                    }))
                }
                const failed1 = () => {

                }



                Api('get', theUrl, null, success1, failed1, history, true, true)
            }
        }

    }

    return (
        <div className='cm_path_two'>
            {successmodal &&
                <SuccessModal
                    open={successmodal}
                    setOpen={setsuccessmodal}
                    // success={suc}
                    success={sc}
                />
            }
            {errormodal &&
                <ErrorModal
                    open={errormodal}
                    setOpen={seterrormodal}
                    // success={suc}
                    error={er}
                />
            }
            {warningmodal &&
                <WarningModal
                    open={warningmodal}
                    setOpen={setwarningmodal}
                    // success={suc}
                    warning={war}
                    fun={Dosomething}
                />
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                        <div className='cm_minicontent_header'>
                            <h3 style={{ fontSize: '1.2rem' }}>{'Upcoming content'}</h3>
                        </div>
                        <Scrollbars autoHide onUpdate={ScrollPos}>
                            {postmoderation.map((data: any, key: number) => {
                                return (
                                    <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border' : 'cm_content_item'}>
                                        <img src={data.post_file[0].posted_file_thumbnail} />
                                        <div className='cm_contentitem_info'>
                                            <h4>{data.event.name}</h4>
                                            <p>{data.description}</p>
                                            <div className='cm_contentitem_user'>
                                                <img src={data.profile.dp_thumbnail} />
                                                <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                                            </div>
                                            {/* {!upcoming && <button className='cm_content_statbtn cm_content_statbtn_green'>
                                    <Approved /> Approved
                                </button>} */}

                                        </div>
                                    </div>
                                )
                            })
                            }
                            {
                                ploading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                    <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                </div>
                            }
                        </Scrollbars>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                        <div className='cm_minicontent_header'>
                            <h3 style={{ fontSize: '1.2rem' }}>{'Moderated content'}</h3>
                        </div>
                        <Scrollbars autoHide onUpdate={ScrollPos1}>
                            {
                                !(Object.keys(moderatedposts).length === 0) ? (
                                    moderatedposts.map((data: any, key: number) => {
                                        return (
                                            <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'} onClick={() => {
                                                // history.push(`/feed/${data.slug}`)
                                                window.open(`/feed/${data.slug}`, "_blank");
                                            }}>
                                                <img src={data.post_file[0].posted_file_thumbnail} />
                                                <div className='cm_contentitem_info'>
                                                    <h4>{data.event.name}</h4>
                                                    <p>{data.description}</p>
                                                    <div className='cm_contentitem_user'>
                                                        <img src={data.profile.dp_thumbnail} />
                                                        <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                                                    </div>
                                                    <button className='cm_content_statbtn cm_content_statbtn_green'>
                                                        <Approved /> Approved
                                                    </button>

                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    !moderatedploading ?
                                        moderatedperror === null ?
                                            <div className='noData'>
                                                <span>no moderated posts found</span>
                                            </div>
                                            : null
                                        : null
                                )
                            }
                            {
                                moderatedploading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                    <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                </div>
                            }
                        </Scrollbars>
                    </div>
                </Box>
            </Modal>
            <div className='content_moderator_content-moderator'>
                {!mloading && (!profile.is_super_admin && <Contentinfotiles />)}
                {
                    !(Object.keys(postmoderation).length === 0) ? (
                        postmoderation[index] ? <ContentPostItem data={postmoderation[index]} currentPostId={setCurrentPostId} indexno={index} postsection={true} /> : (
                            <div className='noData'>
                                <Warning />
                                {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                <span>Posts will be displayed here.</span>
                            </div>
                        )
                    ) : (
                        !ploading ?
                            perror === null ?
                                <div className='noData'>
                                    <Warning />
                                    {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                    <span>Posts will be displayed here.</span>
                                </div>
                                : null
                            : null
                    )
                }
                {

                    ploading && (
                        <Contentpostitemloader />
                        // <div className='cm_postitem skeleton' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '40vh' }}>
                        //     <CircularProgress style={{ color: 'var(--cl_orange)' }} />

                        // </div>
                    )
                }
                {
                    perror !== null ? (
                        perror.status === null ? (
                            <div className=''>
                                <div className='errormsg'>
                                    <Nointernet />
                                    <h2>{errors.internet.heading}</h2>
                                    <span>{errors.internet.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        ) : perror.status === 500 ? (
                            <div className=''>
                                <div className='errormsg'>
                                    <Server />
                                    <h2>{errors.server.heading}</h2>
                                    <span>{errors.server.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        ) : perror.status === 401 ? (
                            <div className=' '>
                                <div className='errormsg'>
                                    <NotFound />
                                    <h2>{errors.notFound.heading}</h2>
                                    <span>{errors.notFound.message}</span>
                                </div>
                            </div>
                        ) : (
                            <div className=''>
                                <div className='errormsg'>
                                    <NotFound />
                                    <h2>{errors.common.heading}</h2>
                                    <span>{errors.common.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        )) : null
                }
                {!(Object.keys(postmoderation).length === 0) && <div className='cm_controls'>
                    <div className='cm_controls_1'>
                        <div className='cm_controls_btn cm_controls_btn_inv cursor' onClick={() => {
                            let len = postmoderation.length
                            if (postmoderation.length - (index + 1) <= 3) {
                                if (ppagination) {
                                    ScrollPos({
                                        top: 1
                                    })
                                }
                            }
                            if (index === 0) {
                                setindex(len - 1)
                            } else {
                                setindex(index - 1)
                            }
                        }}><Rightarrow /></div>
                        <div className='cm_controls_btn cursor' onClick={() => {

                            let len = postmoderation.length
                            if (postmoderation.length - (index + 1) <= 3) {
                                if (ppagination) {
                                    ScrollPos({
                                        top: 1
                                    })
                                }
                            }
                            if (index === len - 1) {
                                if (ppagination === false) {
                                    setindex(0)
                                } else {
                                    // dispatch()
                                }
                            } else if (index < len) {
                                setindex(index + 1)
                            }
                        }}><Rightarrow /></div>
                        {/* <div className='cm_controls_btn'><Btnnext /></div> */}
                    </div>
                    {postmoderation[index] && <div className='cm_controls_buttons'>
                        <button className='cm_content_statbtn_big cm_content_statbtn_green' onClick={Approve}>
                            {aloading ? (
                                <CircularProgress style={{ height: '13px', width: '13px', color: 'green' }} />
                            ) : <><Approved /> Approve</>}
                        </button>
                        <button className='cm_content_statbtn_big cm_content_statbtn_red' onClick={Disapprove}>
                            {disloading ? (
                                <CircularProgress style={{ height: '13px', width: '13px', color: 'red' }} />
                            ) : <><UnApproved /> Unapprove</>}
                        </button>
                    </div>}
                    {/* <div className='cm_controls_btn cm_controls_btn_report'>
                        <ReportIcon />
                    </div> */}
                </div>}
                <div style={{ height: "500px", paddingTop: "0px" }}>
                    {moderatedComments.length !== 0 ? <h4>Reported Comments</h4> : null}
                    <br />
                    <Scrollbars autoHide className='reported_posts_comments' onUpdate={commentListPos} >



                        {
                            moderatedComments?.map((item: any, key: any) => {
                                return (
                                    <div style={{ paddingBottom: "6px" }}>
                                        <ReportedComments key={key} data={item} />

                                    </div>
                                )
                            })
                        }

                    </Scrollbars>
                </div>
            </div>
            {/* <Scrollbars className='content_moderator_contents_wrapper' autoHide>
            <div className='content_moderator_contents'>
                <ContentModeratorContent upcoming={true} />
                <ContentModeratorContent upcoming={false} />
            </div>
        </Scrollbars> */}
            <div className='content_moderator_contents_container' >
                <div className='content_moderator_contents_wrapper'>
                    <Scrollbars className='content_moderator_contents' autoHide>

                        {!ploading && <ContentModeratorContent upcoming={'post'} content={postmoderation.slice(index + 1, index + 4)} setOpen={setOpen} index={index} setindex={setindex} ScrollPos={ScrollPos} />}

                        <Moderatedposts setOpen1={setOpen1} indexno={index} postsection={true} />
                        {/* <ContentModeratorContent upcoming={false} /> */}

                    </Scrollbars>
                </div>
            </div>

        </div>
    )
}

export default PostcontentModeration