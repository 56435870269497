import React from 'react'
import '../style.css'

function TicketingContainer(props: any) {
  return (
    <div className='ticketing_container'>
      <div className="ticket_container_heading_row">
        <h5>{props.name ? props.name : "AUD BALANCE"}</h5>
        <h6 style={{ color: "red" }}>View</h6>

      </div>
      <div className="ticketing_container_data">
        <h1>${props.data ? props.data : 0}</h1>
      </div>


    </div>
  )
}

export default TicketingContainer