import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as FcLike } from '../../../../assets/svg/heart.svg';
import { ReactComponent as FcLikePlaceholder } from '../../../../assets/svg/heart2.svg';
import { ReactComponent as FaRegComment } from '../../../../assets/svg/comment.svg';
// import { ReactComponent as AiOutlineShareAlt } from '../../../../assets/svg/share.svg';
import { ReactComponent as FiSend } from '../../../../assets/svg/send.svg';
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
// import Reply from './Reply';
import NewReply from './NewReply';
import Api from '../../../../app/api';
import { CircularProgress } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { Filter } from '../../../../Data/Data';
import { singlefeedcommentdlt } from '../store/singleFeedSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { feedCommentDelete, feedCommentDelete1 } from '../store/feedSlice';
// import { keys } from '@material-ui/core/styles/createBreakpoints';
import { InView } from 'react-intersection-observer';
import ReportPost from '../../../smallcomponents/modals/ReportPost'

import { ImCancelCircle } from 'react-icons/im'
import { RiReplyLine } from 'react-icons/ri'

const NewComment = ({ comments, k, user, feedkey, feedpermissions, branchactive, cmcount, setcmcount }: { comments: any, k: number, user: any, feedkey: number, feedpermissions: any, branchactive: boolean, cmcount: any, setcmcount: any }) => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const dispatch = useAppDispatch()
    let { comment: comm, comment_likes_count, dp, firstname, id, is_liked, profile_id, replies, replies_count, surname, username, permissions } = comments;
    const {
        profile: prof
    } = useAppSelector(state => state.profile)

    const [comment, setcomment] = useState(comm)
    const [commentliked, setcommentliked] = useState(is_liked)
    const [commentlikecount, setcommentlikecount] = useState(comment_likes_count)
    const [replyData, setreplyData] = useState(replies.slice(0, 1))
    const [replycount, setreplycount] = useState(replies_count)

    const [repliesvisibility, setrepliesvisibility] = useState(false)
    let rep: any = 'view replies'
    const [textinreply, settextinreply] = useState(rep)
    const [replyinput, setreplyinput] = useState(false)

    const [pageno, setpageno] = useState(1);

    const [replyload, setreplyload] = useState(false);
    const [replyerror, setreplyerror] = useState(null);
    const [replyover, setreplyover] = useState(replycount === 0 ? true : false)
    const [replyslice, setreplyslice] = useState(1)

    const [replysentload, setreplysentload] = useState(false)
    const [report, setreport] = useState(false)


    const [optionvisible, setoptionvisible] = useState(false)
    // const feedRef = useRef(null);


    const [editstate, seteditstate] = useState(false)
    const [editvalue, seteditvalue] = useState('')
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    const postlikesuccess = (data: any) => {
        console.log('=======post like success===========', data)


    }
    const postlikefailed = (data: any) => {
        console.log(data)
        // setcommentlikecount(commentlikecount -1)
        setcommentlikecount(comment_likes_count)
        setcommentliked(commentliked)
    }
    function postLike(id: any) {
        if (commentliked) {
            setcommentlikecount(commentlikecount - 1)
        } else {
            setcommentlikecount(commentlikecount + 1)
        }

        setcommentliked(!commentliked)
        let theurl = `/api/v1/post/comment/like/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/post/comment/like/?auth_profile=${parseddetails[0].id}`
            }

        }
        Api('post', theurl, { comment: id }, postlikesuccess, postlikefailed, history, true, true)
    }

    useEffect(() => {

        setrepliesvisibility(false)
        setcommentliked(is_liked);
        setcommentlikecount(comment_likes_count);
        setreplyData(replies);
        setreplycount(replies_count);
        // console.log(replies_count,)
        setreplyover(replies_count === 0 ? true : false)
        setpageno(replyData.length)
        setreplyslice(replyData.length)
    }, [k, comments])

    const Replypostsuccess = (data: any) => {
        console.log('reply post success', data);

        console.log(data)
        setreplyData(data)
        console.log(replyover, replycount)
        if (replyover === true && replyData.length < replycount) {
            setreplyover(true)
            // console.log(replyover === true && replyData.length < replycount)

        } else {
            // console.log(replyover === true && replyData.length < replycount)
            setreplyover(false)
        }
        setreplysentload(false)
    }
    const Replypostfailed = (data: any) => {
        console.log(data)
        setreplysentload(false)

    }
    function PostedReply() {
        Api('get', `/api/v1/post/comments/reply/${id}/1/${pageno * 1}/`, null, Replypostsuccess, Replypostfailed, history, true, true)
    }



    const postreplysuccess = (data: any) => {
        console.log('post reply successss', data)
        PostedReply();

        setreplycount(replycount + 1)
        setreplyinput(!replyinput)

    }
    const postreplyfailed = (data: any) => {
        console.log(data)
        setreplysentload(false)
    }
    function PostReply(id: any, comment: any) {

        const rep = Filter(comment)

        setreplysentload(true);
        let theurl = `/api/v1/post/comment/reply/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/post/comment/reply/?auth_profile=${parseddetails[0].id}`
            }

        }
        Api('post', theurl, {
            comment: id,
            reply: rep,
        }, postreplysuccess, postreplyfailed, history, true, true)
    }


    const loadreplysuccess = (data: any) => {
        if (data.length < 1 || replyData.length === replycount) {
            setreplyover(true)
        }
        setreplyData(replyData.concat(data))
        setreplyload(false)
        setpageno(pageno + 1)
        setreplyslice(replyslice + 1)

    }
    const loadreplyfailed = (data: any) => {
        console.log(data)
        setreplyload(false)
        setreplyerror(data.message)
        // setcommenterror(data.message);
    }
    function LoadReplies() {
        setreplyerror(null)
        setreplyload(true)
        Api('get', `/api/v1/post/comments/reply/${id}/${pageno + 1}/1/`, null, loadreplysuccess, loadreplyfailed, history, true, true)
    }
    const thecomment: any = useRef(null)

    const deletesuccess = (data: any) => {
        setcmcount(cmcount - 1)
        console.log(data)
        console.log(data)
        console.log(path)
        console.log(path === '/feed/all')
        console.log(path === '/feed/trending')
        let node: any = thecomment.current;
        let target = node.parentElement.parentElement;
        if (path === '/feed/all') {
            console.log('alllllllllll')
            target.classList.add('fadeout');
            dispatch(feedCommentDelete({ feedkey: feedkey, commentkey: k }))
        } else if (path === '/feed/trending') {
            console.log('trending')
            target.classList.add('fadeout');
            dispatch(feedCommentDelete1({ feedkey: feedkey, commentkey: k }))
        } else {
            console.log('single')
            target.classList.add('fadeout');
            dispatch(singlefeedcommentdlt({ id: id }))
        }
    }
    const deletefailed = (data: any) => {
        console.log(data)
    }

    const Deletecomment = () => {
        let theurl = `/api/v1/post/comment/${id}/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/post/comment/${id}/?auth_profile=${parseddetails[0].id}`
            }

        }
        Api('delete', theurl, null, deletesuccess, deletefailed, history, true, true)
    }

    const [editload, seteditload] = useState(false)
    const editp: any = useRef()

    const editsuccess = (data: any) => {
        console.log(comment, editvalue)
        setcomment(editvalue);
        console.log(data)
        seteditload(false)
        seteditstate(false)
        let targt: any = vis.current

        setTimeout(() => {
            targt.value = editvalue;
        }, 5000);


    }
    const editfailed = (data: any) => {
        console.log(data)
        seteditload(false)
        seteditstate(false)
    }
    const Editcomment = (id: any, comment: any) => {
        seteditload(true)
        let data = {
            "comment": comment
        }
        let theurl = `/api/v1/post/comment/${id}/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/post/comment/${id}/?auth_profile=${parseddetails[0].id}`
            }

        }
        Api('put', theurl, data, editsuccess, editfailed, history, true, true)
    }
    const BeforeEditcomment = (comment: any) => {
        seteditvalue(comment)
        seteditstate(true)
        // let data = {
        //     "comment": "test1"
        // }
        // Api('put', `/api/v1/post/comment/${id}/`, data, editsuccess, editfailed, history, true, true)
    }



    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (optionvisible && targt && !targt.contains(e.target)) {
                setoptionvisible(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [optionvisible])
    return (
        <InView as="div" onChange={(inView, entry) => {
            if (inView === false) {
                setoptionvisible(false)
            }
        }} key={k}>

            {/* <div className="commentsection">
                <div className="root">
                    <div className="rootstart"></div>
                    {branchactive && <div className="rootbranch"></div>}
                </div> */}
            <div className="commentwrapper" ref={thecomment}>
                <div className="comment">

                    <div className="commentcontent">
                        <div className="dpcontainer">
                            <div className="dp">
                                <img src={dp} alt="P_I_C" />
                            </div>
                            {replycount !== 0 && <div className="commentroot"></div>}
                        </div>

                        <div className="userdet">
                            <a className='cursor' href='' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation()
                                if (profile_id === prof.id) {
                                    history.push('/profile')
                                } else {
                                    history.push(`/user/${username}`)
                                }

                            }}>{firstname + ' ' + surname}</a>
                            <span ref={editp}>{editstate ? (
                                <div className="FeedItemReplyBox" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    {/* <div className="FeedItemReplyDp">
                                    <img src={user.dp} className="FeedItemUsrDpImg" alt="" />
                                </div> */}
                                    <div className="FeedItemReplyInputDiv">
                                        <input type="text" className="FeedItemReplyInput" defaultValue={editvalue}
                                            onKeyPress={
                                                e => {
                                                    if (e.currentTarget.value !== null && e.key === 'Enter') {

                                                        Editcomment(id, e.currentTarget.value)
                                                        e.currentTarget.value = ''
                                                    }

                                                }
                                            }
                                            onChange={e => {
                                                seteditvalue(e.currentTarget.value)
                                            }}
                                        />
                                        <div className="FeedItemReplyBtn" style={{ cursor: "pointer" }}
                                            onClick={
                                                e => {
                                                    let target = e.currentTarget;
                                                    if (target) {
                                                        let parent = target.parentElement
                                                        if (parent) {
                                                            let firstchild: any = parent.firstChild;
                                                            if (firstchild) {
                                                                if (firstchild.value !== null) {

                                                                    Editcomment(
                                                                        id,
                                                                        firstchild.value
                                                                    );
                                                                }
                                                                firstchild.value = "";
                                                            }

                                                        }

                                                    }
                                                }
                                            }
                                        >
                                            <FiSend style={{ color: 'var(--cl_orange)', fontSize: '14px' }} />
                                            {editload === false ? (

                                                <span style={{ fontSize: 'var(--font-size-10)' }}>SEND</span>
                                            ) : (

                                                <span
                                                    style={{
                                                        maxHeight: "13px",
                                                        fontSize: "13px",
                                                        overflow: "hidden",
                                                        color: 'var(--cl_orange)'
                                                    }}
                                                >
                                                    <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                                                </span>

                                            )
                                            }
                                        </div>

                                    </div>
                                    {/* <span className='cursor' style={{fontSize:'var(--font-size-10)'}} onClick={()=>{
                                    seteditstate(false)
                                    seteditvalue('')
                                }}>Cancel</span> */}
                                    <div className="FeedItemReplyBtn" style={{ cursor: "pointer" }}
                                        onClick={
                                            e => {
                                                seteditstate(false)
                                                seteditvalue('')
                                            }
                                        }
                                    >
                                        <ImCancelCircle style={{ color: 'var(--cl_orange)', fontSize: '14px' }} />
                                        Cancel
                                    </div>
                                </div>)
                                : comments.comment}</span>
                            <div className="ResponseBox">
                                {/* <span>like</span>
                                        <span>comment</span>
                                        <span>reply</span> */}
                                <div style={{ cursor: "pointer" }}
                                    onClick={
                                        e => {
                                            // if (commentliked === false) {
                                            postLike(id)
                                            // }
                                        }
                                    }
                                >{commentliked === false ? <FcLikePlaceholder className="commentIcons" /> : <FcLike className="commentIcons" />}
                                    <span className="Like">{commentlikecount}</span ></div>
                                {/* <span className="Like">like</span> */}
                                <div>
                                    <FaRegComment className="commentIcons" />
                                    <span className="Like">{replycount} </span>

                                </div>
                                {feedpermissions.add_reply && <span className="Replyicons"
                                    onClick={
                                        (e) => {
                                            // if(replyinput === false) {
                                            setreplyinput(!replyinput)
                                            // }
                                        }
                                    }
                                >{replyinput === false ? <><RiReplyLine />reply</> : <><ImCancelCircle />cancel</>}</span>}
                            </div>

                        </div>
                        <div className="FeedItemDropdown" ref={vis} onClick={e => {
                            e.preventDefault()
                            setoptionvisible(!optionvisible)
                        }}>
                            <span className='dots'>
                                <More />
                            </span>{optionvisible ? <div className='feedoptions'>
                                <span className='feedoptiontrianglecontainer'><span></span></span>
                                {permissions?.can_delete && <li onClick={() => {
                                    Deletecomment()
                                }}>
                                    {/* <AiOutlineDelete /> */}
                                    Delete comment</li>}
                                {permissions?.can_edit && <li onClick={() => {
                                    BeforeEditcomment(comment)
                                }}>
                                    {/* <AiOutlineDelete /> */}
                                    Edit comment</li>}
                                {permissions?.can_report_comment && <li onClick={
                                    e => {
                                        setreport(true)
                                    }
                                }>
                                    {/* <AiOutlineDelete /> */}
                                    Report comment</li>}
                            </div> : null}

                        </div>
                        {
                            report && <ReportPost
                                open={report}
                                setOpen={setreport}
                                postid={id}
                                type={'comment'}
                            />
                        }
                    </div>


                </div>
                {replyinput === true ? (
                    <div className="FeedItemReplyBox" style={{ display: 'flex' }}>
                        <div className="FeedItemReplyDp">
                            <img src={user.dp} className="FeedItemUsrDpImg" alt="P_I_C" />
                        </div>
                        <div className="FeedItemReplyInputDiv">
                            <input type="text" className="FeedItemReplyInput"
                                onKeyPress={
                                    e => {
                                        if (e.currentTarget.value !== null && e.key === 'Enter') {
                                            PostReply(id, e.currentTarget.value)
                                            e.currentTarget.value = ''
                                        }

                                    }
                                }
                            />
                            <div className="FeedItemReplyBtn" style={{ cursor: "pointer" }}
                                onClick={
                                    e => {
                                        let target = e.currentTarget;
                                        if (target) {
                                            let parent = target.parentElement
                                            if (parent) {
                                                let firstchild: any = parent.firstChild;
                                                if (firstchild) {
                                                    if (firstchild.value !== null) {

                                                        PostReply(
                                                            id,
                                                            firstchild.value
                                                        );
                                                    }
                                                    firstchild.value = "";
                                                }

                                            }

                                        }
                                    }
                                }
                            >
                                <FiSend style={{ color: 'var(--cl_orange)', fontSize: '14px' }} />
                                {replysentload === false ? (

                                    <span>SEND</span>
                                ) : (

                                    <span
                                        style={{
                                            maxHeight: "13px",
                                            fontSize: "13px",
                                            overflow: "hidden",
                                            color: 'var(--cl_orange)'
                                        }}
                                    >
                                        <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                                    </span>

                                )
                                }
                            </div>
                        </div>
                    </div>
                ) : null
                }
                {repliesvisibility === true ? (
                    replyData.slice(0, replyslice).map((rep: any, key: number) => {
                        console.log('reppppppppppppllllllliiiiiieeessssssss', rep);

                        return (
                            (replyData.length !== 0) ? (
                                <div className="reply">
                                    <div className="replybranch">
                                        <div className="replyroot"></div>
                                        {/* {
                                            replyData.length === key +1 ? ((!replyover || (replycount > replyData.length)) && <div className="replybranches"></div>):(
                                                <div className="replybranches"></div>
                                            )
                                        } */}
                                        {/* {
                                            replycount === 0 ? null: (
                                                <div className="replybranches"></div>
                                            )
                                        } */}
                                        {/* {
                                            replycount === replyData.length ? null : (
                                                <div className="replybranches"></div>
                                            )
                                        } */}
                                        {replyData.length === key + 1 ? (replycount > replyData.length ? (
                                            replyover === false ? (
                                                <div className="replybranches"></div>
                                            ) : null
                                        ) : null) : <div className="replybranches"></div>}

                                    </div>
                                    <NewReply rep={rep} k={key} user={user} feedkey={feedkey} commentkey={k} repcount={replycount} setrepcount={setreplycount} />
                                </div>
                            ) : null
                        )
                    })
                ) : null
                }
                {
                    replyload === true && replyerror === null ? (
                        <div className="progresslayout" style={{ maxHeight: "15px", color: 'var(--cl_orange)' }}>
                            <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                        </div>
                    ) : (
                        <small style={{ margin: 'auto' }}>{replyerror}</small>
                    )
                }

                {
                    (!replyover || (replycount > replyData.length)) && (
                        <div className="viewreplies cursor"><small
                            onClick={
                                (e) => {
                                    if (textinreply === 'View more') {
                                        LoadReplies();
                                    }
                                    if (repliesvisibility === false) {
                                        setrepliesvisibility(true)
                                        if (replyData.length < replycount) {
                                            settextinreply('View more')
                                        } else {
                                            setreplyslice(replyData.length);
                                            settextinreply(null)
                                        }


                                    }
                                }
                            }
                        >{textinreply}</small></div>
                    )
                }

            </div>
            {/* </div> */}
        </InView>
    )
}

export default NewComment
