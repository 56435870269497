import React, { useState } from 'react'
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
// import Eventtile from '../../eventTiles'
import { connect, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Api from '../../../../app/api';
import { useAppSelector } from '../../../../app/hooks';
import EventTile1 from '../../../smallcomponents/eventtile/EventTile1';
import EventTileLoader from '../../../smallcomponents/loaders/EventTileLoader';
import { CircularProgress } from '@material-ui/core';
import Success from '../../../smallcomponents/modals/Success'
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg'
import './cancel.css'

const CancelEvent = () => {
    const history = useHistory()
    const [value, setvalue] = useState('')
    const [loading1, setloading] = useState(false)
    let sc: any = null
    const [suc, setsuccess] = useState(sc)
    let er: any = null
    const [error1, seterror] = useState(er)
    const { eventId }: any = useParams()
    const { event, loading, error } = useAppSelector(state => state.event)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    const success = (data: any) => {
        console.log(data)
        setloading(false)
        setsuccess({
            head: 'Event has been successfully canceled',
            message: 'Go back to the eventpage',
            button:'Go back to eventpage'
        })
        setopen(true)
        setTimeout(() => {
            setopen(false)
            let theurl = `/api/v1/event/${eventId}/`
            if (unparseddetails) {
              if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/${eventId}/?auth_profile=${parseddetails[0].id}`
              }
      
            }
            Api('get',theurl, null, success, failed, history, true, true)
            history.push(`/${event.id}/event`)
            window.location.reload()
        }, 5000);
    }
    const failed = (data: any) => {
        const {
            status, text, dev_data, app_data,
        } = data;
        setloading(false)
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try refreshing the page.'
        }
        seterror({
            head: head,
            message: message,
        })
        setTimeout(() => {
            seterror(null)
        }, 5000);

    }
    function Cancel() {
        console.log(value.length)
        if (value.length !== 0) {
            setloading(true)
            let params = {
                event: eventId,
                message: value
            }
            let theurl = `/api/v1/event/cancel`
            if (unparseddetails) {
              if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/cancel/?auth_profile=${parseddetails[0].id}`
              }
      
            }
            Api('post', theurl, params, success, failed, history, true, true)
        } else {
            seterror({
                head: 'Please provide a message to let your',
                message: 'participants know why you are canceling the event',
            })
            setTimeout(() => {
                seterror(null)
            }, 5000);
        }

    }

    const [open, setopen] = useState(false)


    return (
        <div className='cancelEvent minvh100'>
            {open &&
                <Success
                    open={open}
                    setOpen={setopen}
                    // success={suc}
                    success={{
                        head: 'Event has been canceled successfully',
                        message: 'Go back to the eventpage',
                        button:'Go back to eventpage',
                        link: `/${eventId}/event`
                    }}
                />
            }

            <div className='canceleventcontent'>
            <small  className='cancelback' onClick={()=>history.goBack()}><Arrow />Back to previous page</small>
                <div className="exploreDivTitle">Cancel event
                </div>

                {(error1 && !(Object.keys(error1).length === 0)) ? (
                    <div className="exploreDivSubTitle errorMessage" style={{ marginBottom: "10px", color: 'red' }}>
                        {error1.head + ' ' + error1.message}
                    </div>
                ) : (
                    <div className="exploreDivSubTitle" style={{ marginBottom: "10px" }}>
                        Let participants know why you want to cancel your event.
                    </div>
                )
                }
                <div className='canceleventmessage'>
                    <p>Message to participants</p>
                    <textarea value={value} onChange={(e) => { setvalue(e.currentTarget.value) }}></textarea>
                    <div className="AddEventFooter">
                        <div className="AddEventCancelBtn"
                            onClick={e => {
                                history.push(`/event/${eventId}`)
                            }}
                        >Keep event</div>
                        <div className="AddEventNextBtn"
                            onClick={e => {
                                Cancel()
                            }}
                        >{
                                loading1 === false ? (
                                    "Cancel event"
                                ) : (
                                    <div style={{ color: 'white' }}>
                                        <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />
                                    </div>
                                )
                            }</div>
                    </div>
                </div>
            </div>
            <div className='canceleventcontainer'>

                {loading === false && error === null ?
                    <EventTile1 event={event} k={1} />
                    // <div></div>
                    : loading === false && error !== null ? (
                        <div></div>
                    ) : <EventTileLoader />}

            </div>

        </div>
    )
}

export default CancelEvent
