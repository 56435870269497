import React from 'react'
import  Slide  from '@mui/material/Slide';

interface Iprops {
	isPrev?: boolean
}

const CommonSlide: React.FC<Iprops>= ({children , isPrev}:any) => {
  return (
    // <Slide direction={isPrev ? "down" :"up"} in={true} mountOnEnter unmountOnExit>
    <>
        {children}
    </>

	// </Slide>

  )
}

export default CommonSlide