import React, { useState, useEffect } from 'react'
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import './customroles.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Api from '../../../app/api';
import { useHistory } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { baseurl } from '../../../Data/Data';
import { businessprofilessuccess, businessprofilesrequest } from '../../pages/newbusinessaccount/store/BusinessprofileSlice';
import axios from 'axios';
import { BiMinus } from 'react-icons/bi'
// import { BsPlus } from 'react-icons/bs';


const useStyles = makeStyles((theme) => ({
    modal: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: 'rgb(0,0,0,0.7)'
    },
    appBar: {
        position: "relative",
        backgroundColor: "white",
    },
    buttonClose: {
        height: "4rem",
        width: "4rem",
        position: "absolute",
        // backgroundColor: 'white',
        color: "red",
        right: "10px",
        zIndex: 100,
    },
    title: {
        marginLeft: theme.spacing(2),
        color: "black",
        flex: 1,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2, 4, 3),
    },
    imgContainer: {
        position: 'relative',
        flex: 1,
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },

}));

const Customroleadd = ({
    dialogStatus,
    dialogAction,
    roles, setroles
}: {
    dialogStatus: any,
    dialogAction: any,
    roles: any,
    setroles: any
}) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const classes = useStyles();
    console.log(roles)

    const [roleinput, setroleinput] = useState<any>('')
    const handleClose = () => {
        dialogAction(false);
    };

    function Addrole(value: any) {
        // alert(value)
        if (value !== null && value !== '' && value.length !== 0 && value !==' ') {
            if (roles.includes(value)) {
                setroleinput('')
            } else {
                setroles([...roles, value])
                setroleinput('')
            }

            // roles = [value].concat(roles)
        }
    }


    return (
        <div style={{ minHeight: '100vh' }}>
            <Modal
                // fullScreen
                open={dialogStatus}
                onClose={handleClose}
                // TransitionComponent={Transition}
                className={classes.modal}
            >
                <div
                    className='customrolemodal'
                    style=
                    {{
                        display: "flex",
                        //   justifyContent: "space-around",
                        //   alignItems: "center",
                        flexDirection: "column",
                        width: '500px',
                        height: '520px',
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        gap: '20px',
                        padding: '20px 30px',
                        fontFamily: "Inter",
                        // border : '3px solid green' , 





                    }}>
                    <h2 style={{ textAlign: 'center', fontWeight: 500, }}>Add custom role</h2>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr', }} className='customrolesinputwrapper'>
                        <input type='text' id='roleinput' autoFocus className='cutomrolesinput' style={{ border: 'none', outline: 'none', backgroundColor: 'var(--cl_bggrey)', height: '40px', borderRadius: '7px', }}
                            value={roleinput}
                            onChange={(e) => {
                                setroleinput(e.currentTarget.value)

                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    Addrole(e.currentTarget.value)
                                }
                            }}

                        />
                        {/* <button onClick={(e)=>{
                            const target: any = e.currentTarget.parentElement?.firstChild
                            Addrole(target.value)
                        }}>Add</button> */}
                    </div>

                    <Scrollbars style={{ height: 430 }} autoHide>
                        <ul>
                            {
                                roles.map((rol: any, k: number) => {
                                    return <li className='customroleitem'>{rol}<div className='customroleicon' onClick={() => {
                                        let newroles = roles.filter((rl: any) => rl !== rol)
                                        setroles(newroles)
                                    }}><BiMinus /></div></li>
                                })
                            }

                            {/* <li><small>Personal profiles</small></li>
                                {
                                    storedprof.length === 0 ? (
                                        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><small>no profiles found</small></li>
                                    ) : (
                                        storedprof.map((user: any, key: any) => {
                                            let { dp_thumbnail, username, firstname, surname, is_business_profile } = user;
                                            return (is_business_profile ? null :
                                                <li className='profileslistitem' onClick={() => {
                                                    ChangeProfile(key, 'personal')
                                                }}>
                                                    <img src={dp_thumbnail} alt='' />
                                                    <div className='profilelistitemcontent'>
                                                        <div className='profilelistitemdetails'>
                                                            <h5>{firstname + " " + surname}</h5>
                                                            <small>{'@' + username}</small>
                                                        </div>
                                                        <div className={(profilestype === 'personal' && key === 0) ? 'roundedcheckbox roundedcheckboxactive' : 'roundedcheckbox'}>
                                                            <div className='roundedcheckboxdot'>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    )
                                }
                                <li><small>Business profiles</small></li>
                                {
                                    businessprofiles.length === 0 && businessloading === false ? (
                                        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><small>no profiles found</small></li>
                                    ) : (
                                        businessprofiles.map((user: any, key: any) => {
                                            let { id, dp_thumbnail, dp, username, firstname, is_business_profile } = user;
                                            // !is_business_profile ? null :
                                            return (
                                                <li className='profileslistitem' onClick={() => {
                                                    // ChangeProfile(key, 'business')
                                                    if(!(profilestype === 'business' && key === 0)){
                                                        ChangetoBusiness(id)
                                                    }
                                                    
                                                }}>
                                                    <img src={dp_thumbnail ? dp_thumbnail : dp} alt='' />
                                                    <div className='profilelistitemcontent'>
                                                        <div className='profilelistitemdetails'>
                                                            <h5>{firstname}</h5>
                                                            <small>{'@' + username}</small>
                                                        </div>
                                                        <div className={(profilestype === 'business' && key === 0) ? 'roundedcheckbox roundedcheckboxactive' : 'roundedcheckbox'}>
                                                            <div className='roundedcheckboxdot'>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    )
                                } */}
                            {/* {
                                    businessloading === true && businesserror === null ? <li className='profileslistitem '>
                                    <div className='skeleton' style={{ width: '48px', height: '48px', borderRadius: '12px' }}></div>
                                    <div className='profilelistitemcontent'>
                                        <div className='profilelistitemdetails'>
                                            <h5 className='maxwid60 minhe10 skeleton' style={{ marginBottom: '10px' }}></h5>
                                            <small className='minwid60 minhe10 skeleton'></small>
                                        </div>
                                    </div>
                                </li> : null
                                } */}




                        </ul>
                    </Scrollbars>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <button className='mainBtnInv' onClick={handleClose}>Cancel</button> 
                    <button className='mainBtn' style={{minWidth:'90px'}} onClick={(e) => {
                        const target: any = document.getElementById('roleinput')
                        Addrole(target.value)
                    }}>Add</button>
                </div>

        </div>

            </Modal >
        </div >
    )
}

export default Customroleadd
