// import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import { ErrorOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import './util.css'



export const FailedToast = (msg?: string) => {

    // toast.custom(<div className="toast__container">
    //     <div className="toast__container__icon">
    //         <ErrorOutlined style={{  color: ' red'}} />
    //     </div>
    //     <div className="toast__container__text">
    //         {msg}
    //     </div>
    // </div>)

    toast.error(msg || "something went wrong!");

}


export const SucessToast = (msg?: string) => {

    // toast.custom(<div className="toast__container">
    //     <div className="toast__container__icon">
    //         <ErrorOutlined style={{  color: ' red'}} />
    //     </div>
    //     <div className="toast__container__text">
    //         {msg}
    //     </div>
    // </div>)

    toast.success(msg || 'Completed Sucessfully!')

}


export const WarnToast = (msg?: string) => {

    // toast.custom(<div className="toast__container">
    //     <div className="toast__container__icon">
    //         <ErrorOutlined style={{  color: ' red'}} />
    //     </div>
    //     <div className="toast__container__text">
    //         {msg}
    //     </div>
    // </div>)

    toast.custom(<p>
        This username is not available. Make sure you are not using existing usernames, swear words and invalid characters. You can use a-z, 0-9 and underscores. Minimum length is 5 characters.</p>
    )

}


export const MessageToast = (data: any) => {

    if (data)

        toast.custom(<div className="toast__msg__wrapper">
            <div className="toast__msg__dp">
                {/* <img src={data?.sender?.dp} alt="" /> */}
                <Avatar src={data?.sender?.dp_thumbnail_1}   sx={{ width: 34, height: 34 }} />
            </div>
            <div className="toast__msg__text">
                <div style={{ display: "flex", alignItems: "center" ,justifyContent: "space-between"}}>
                    <p style={{ fontWeight: 600 }}>{`${data?.sender?.firstname} ${data?.sender?.surname}`} <span style={{ fontWeight: 400}}> sent you a new message.</span>
                    </p>
                    <small>
                        {dayjs.utc(data?.updated_at).local().format('hh:mm a')}
                    </small>
                </div>

                <div>
                    <h5 style={{ color: "grey" ,fontWeight: 500 }}> {data?.message_type === "text" ? data?.message : "1 new message"}</h5>
                </div>
            </div>
        </div>)


}