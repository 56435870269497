import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import StoryView from './StoryView';
import { Profile, StoryList } from './store/story.interface';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './storyCarousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import { GrFormPreviousLink, GrFormNextLink } from 'react-icons/gr'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import "./styles.css";
import { EffectCards } from "swiper";

// import Mediamodalcomments from './Mediamodalcomments';
// import Mediamodalviews from './Mediamodalviews';

// import { ReactComponent as Eye } from '../../../assets/svg/eye.svg';
// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }
SwiperCore.use([Navigation]);

function getModalStyle() {
	const top = 0;
	const left = 0;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: '100%',
		// minHeight:,
		height: '100%',
		// backgroundColor: 'black',
		// border: '2px solid #000',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '12px',
		boxShadow: theme.shadows[5],
		// padding: theme.spacing(2, 8, 3),
	},
}));
interface iprops {
	storyList: any;
	setCurrentStoryKey: any;
	handleClose: any;
	currentStoryKey: number;
	totalLength: number | null;
	open: boolean;
	// StoryData:
}

export default function MyStoryModal({ handleClose, open, setCurrentStoryKey, currentStoryKey, totalLength, storyList }: iprops) {
	console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$ my story modal story key $$$$$$$$$$$$$$$$$$', currentStoryKey)
	const contentStyle = {
		// background: 'salmon',
		width: '100%',
		padding: 20,
		color: 'white',
		// height: '0vh'
	};

	const image = {
		display: 'block',
		maxWidth: '100%',
		height: '100%',
		borderRadius: 4,
	};

	const sliderRef: any = useRef();
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)

	useEffect(() => {
		document.addEventListener('contextmenu', (e) => {
			e.preventDefault();
		});


		return () => {
			document.removeEventListener('contextmenu', (e) => {
				e.preventDefault();
			});
		}
	}, [])


	if (!Array.isArray(storyList)) return null;


	// console.log('sliderRef', sliderRef);
	// console.log('sliderRef', sliderRef.current);
	console.log('...................................nav', navigationPrevRef);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			style={{
				backgroundColor: 'rgba(0, 0, 0, 0.8)', backdropFilter: 'blur(10px)',
				display: 'flex', alignItems: 'center', justifyContent: 'center'
			}}>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


				<Swiper
					effect={"cards"}

					// grabCursor={true}
					slidesPerView={'auto'}
					spaceBetween={1}
					roundLengths={true}
					centeredSlides={true}
					onSlideChange={(swiper) => setCurrentStoryKey(swiper.activeIndex)}
					modules={[EffectCards]}
					className="mySwiper"
					initialSlide={currentStoryKey}
					preventClicks={true}
					preventClicksPropagation={true}

					navigation={{
						prevEl: navigationPrevRef.current!, // Assert non-null
						nextEl: navigationNextRef.current!, // Assert non-null
					}}

				>

					return (
					<SwiperSlide onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
						<>

							<div
								onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
								style={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									zIndex: 1000
								}}>
								<div
									className='story__item__selected'
									onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
								>
									<StoryView
										keyID={0}
										FollowersStory={storyList}
										currentStoryKey={currentStoryKey}
										setCurrentStoryKey={setCurrentStoryKey}
										handleClose={handleClose}
										totalLength={totalLength}
										navigationNextRef={navigationNextRef}
										storyType="mystory"
									/>
								</div>
							</div>
						</>
					</SwiperSlide>
					);
					{/* })} */}
					<div className="swiper__prev" ref={navigationPrevRef}> <GrFormPreviousLink style={{ color: "white", width: "25px", height: "25px" }} onClick={(_) => { console.log('hello'); }} /></div>
					<div className="swiper__next" ref={navigationNextRef}> <GrFormNextLink style={{ color: "white", width: "25px", height: "25px" }} /> </div>
				</Swiper>
			</div>
		</Modal>
	);
}
