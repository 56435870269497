import { createSlice, } from '@reduxjs/toolkit'
import { FeaturedEventState } from './interface';

const initialState: FeaturedEventState = {
    loading: false,
    featuredEvent: [],
    page: 1,
    pagination: true,
    error: null,
    categoriesWl: []

};
export const featuredeventslicewl = createSlice({
    name: 'featuredeventwl',
    initialState,
    reducers: {
        featuredEventRequestWl: (state, action) => {
            state.loading = true;
            state.pagination = true;
            state.loading = true;
            state.error = null;
            state.pagination = true;

            state.featuredEvent = action.payload.no === 1 ? [] : state.featuredEvent;
            state.page = action.payload.no;
        },
        featuredEventSuccessWl: (state, action: any) => {
            // console.log('************************* action ', action.payload)
            // state.loading = false;
            // state.featuredEvent = action.payload.no !== 1 ? (action.payload.no === state.page + 1 ? state.featuredEvent.concat(action.payload.featuredEvent) : state.featuredEvent) : action.payload.featuredEvent;
            // state.page = action.payload.no;
            // state.pagination = action.payload.pagination;
            // state.error = null;
            state.loading = false;
            state.error = null;
            state.featuredEvent = state.featuredEvent.concat(action.payload.data);
            state.page = state.page + 1;
            state.pagination = action.payload.pagination;
        },
        featuredEventFailedWl: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.pagination = false;
        },
        categorySuccessWl: (state, action) => {
            state.categoriesWl = action.payload.data;
        },

        featuredEventRequestWl1: (state, action) => {
            state.loading = true
            if (action.payload === 1) {
                state.featuredEvent = [];
                state.page = 1;
            }
        },
        featuredEventSuccessWl1: (state, action: any) => {
            state.loading = false;
            state.featuredEvent = state.page === 1 ? action.payload.data : state.featuredEvent.concat(action.payload.data);
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        featuredEventFailedWl1: (state, action: any) => {
            state.loading = false;
            state.error = action.payload
        }

    },
})

export const { featuredEventRequestWl, featuredEventFailedWl, featuredEventSuccessWl, categorySuccessWl, featuredEventRequestWl1, featuredEventSuccessWl1, featuredEventFailedWl1
    // feedReplyDelete, feedCommentDelete, feedReplyDelete1, feedCommentDelete1 
} = featuredeventslicewl.actions
export default featuredeventslicewl.reducer