import React, { useEffect, useState } from 'react'
import { Link, NavLink, Redirect, Route, useHistory, useRouteMatch, Switch, useLocation } from 'react-router-dom';
// import axios from 'axios';
import Stories from './components/Stories';


import Followers from '../../pages/followers/Followers';
import Following from '../../pages/following/Following';
import UserEvents from '../../pages/userevents/UserEvents';
import UserEvents1 from '../../pages/userevents/UserEvents1';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Plus } from '../../../assets/svg/change.svg';
import { ReactComponent as Mail } from '../../../assets/svg/mail.svg';
// import { ReactComponent as Loc } from '../../../assets/svg/locwhite.svg';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import { ReactComponent as Calendar } from '../../../assets/svg/calendar.svg';
import { ReactComponent as Follower } from '../../../assets/svg/followers.svg';
import { ReactComponent as Followin } from '../../../assets/svg/following.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'

import { ReactComponent as Event } from '../../../assets/svg/events.svg';
import { noData } from '../../../Data/Data';
import './profile.css'
import './newprofile.css'
import { errors } from '../../../Data/Error';
// import { profile } from './dummydata';
import Api from '../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
// import { ProfileState } from './store/interface';
import { profileSuccess, profileFailed, profileRequest, update_profile, ticketSuccess, ticketFailed, ticketRequest } from './store/profileSlice';
import ProfileTile from '../../smallcomponents/profiletile/ProfileTile';


import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader';
import ProfileMedia from './components/ProfileMedia';
// import "./packages/react-router-dom/examples/Animation/styles.css";
// import {
//     TransitionGroup,
//     CSSTransition
//   } from "react-transition-group";

import imageCompression from 'browser-image-compression';
import { getCroppedImg } from '../../smallcomponents/cropmodals/crop';
import { dataURLtoFile } from '../../smallcomponents/cropmodals/dataURLtoFile';
import Cropper from "react-easy-crop";
import { CircularProgress } from '@material-ui/core';

import ProfileDialog from '../../smallcomponents/cropmodals/ProfileDialog'
import { update_coverPhoto } from './store/action';
import SingleImageModal from '../../smallcomponents/modals/SingleImageModal';

import Mediapage from './components/Mediapage';
import ProfileAnalytics from '../../pages/profileanalytics/ProfileAnalytics';
import ProfileAnalyticsPage from '../../pages/profileanalytics/ProfileAnalyticsPage';
import MiniProfile1 from '../../smallcomponents/miniprofile/MiniProfile1';
import NewStories from './components/NewStories';
// import Media from './components/Media';
// import ProfileEvents from './components/ProfileEvents';
import UserMedia from '../../pages/usermedia/UserMedia'
import Footer from '../../footer/Footer';
import Storiesnew from './components/Storiesnew';
import { IoIosPhotos } from 'react-icons/io'
import ViewTicket from '../../pages/Event/components/ViewTicket';
import { ArrowForward } from '@material-ui/icons';
import UserEventsInitial from '../../pages/userevents/UserEventsInitial';
import UserEventsDetail from '../../pages/userevents/UserEventsDetail';

const Newprofile = () => {
    const { path } = useRouteMatch();
    const [modalActive, setActive] = useState(false)
    const { pathname } = useLocation()

    const { profile, loading, error, media, events, stories, ticket } = useAppSelector(state => state.profile)
    const { isAuth } = useAppSelector(state => state.login)
    const dispatch = useAppDispatch();
    const history = useHistory()
    const success = (data: any) => {
        dispatch(profileSuccess(data))
        console.log(data)
        document.title = `Mutual Events - ${data ? data.firstname + ' ' + data.surname : 'Profile'}`
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(profileFailed(data))
    }

    const ticket_success = (data: any) => {
        dispatch(ticketSuccess(data))
        console.log(data)
    }
    const ticket_failed = (data: any) => {
        console.log(data)
        dispatch(ticketFailed(data))
    }
    useEffect(() => {
        if (!((Object.keys(profile).length) === 0)) {
            document.title = `Mutual Events - ${profile ? profile.firstname + ' ' + profile.surname : 'Profile'}`
        }

        window.scrollTo(0, 0);
        if ((Object.keys(profile).length) === 0 && loading === false) {
            dispatch(profileRequest())
            Api('get', "/api/v1/user/profile/", null, success, failed, history, true, true)
        }
    }, [isAuth])



    useEffect(() => {
        dispatch(profileRequest())
        dispatch(ticketRequest())

        Api('get', "/api/v1/user/profile/", null, success, failed, history, true, true)


        Api('get', "/api/v1/event/ticket/list/1/100/", null, ticket_success, ticket_failed, history, true, true)


    }, [isAuth])
    function Refresh() {
        dispatch(profileRequest())
        Api('get', "/api/v1/user/profile/", null, success, failed, history, true, true)
    }
    function handleFollowing() {
        history.push(`/profile/following`)
    }
    function handleFollowers() {
        history.push(`/profile/followers`)
    }
    function handleMyEvents() {
        history.push(`/profile/events`)
    }








    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => {
        // inputRef.current.click()
        let target = document.getElementById('picture')
        if (target) {
            target.click()
        }

    };

    const i: any = null
    const [image, setImage] = React.useState(i);
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [minZoom, setminZoom] = React.useState(1);

    const [loader, setLoader] = React.useState(false);
    const [alerter, setAlerter] = React.useState(false);
    const [NewHeight, setNewHeight] = React.useState(null);
    const [saveBar, setSaveBar] = React.useState(false);


    const [change, setChange] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [openb, setOpenb] = React.useState(false);
    const [openc, setOpenc] = React.useState(false);
    const [opend, setOpend] = React.useState(false);


    const [seeAll, setseeAll] = useState(false)



    const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
        setCroppedArea(croppedAreaPixels);
    };
    const Setitup = (file: any) => {
        const reader = new FileReader()
        //   console.log('reader',reader)
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
            if (reader) {
                setImage(reader.result);
            }

            setSaveBar(true)

        });
    }

    const onCancel = () => {
        setImage(null)
        setSaveBar(false)
    }



    const onSelectFile = async (event: any) => {
        // console.log("thi sis image", event);
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files && event.target.files.length > 0) {
                if (event.target.files && event.target.files.length > 0) {
                    const nav: any = navigator
                    const { userAgentData } = nav
                    let options;
                    if (userAgentData?.platform === 'macOS') {
                        if (event.target.files[0].size > 1000000) {
                            options = {
                                maxSizeMB: 1,
                                // maxWidthOrHeight: 800,
                                maxWidthOrHeight: 1392,
                                useWebWorker: true
                            }
                            try {
                                const compressedFile = await imageCompression(event.target.files[0], options);
                                console.log(compressedFile)
                                Setitup(compressedFile)
                                // setLoader(false)
                            } catch (error) {
                                console.log(error);
                                // setLoader(false)
                            }
                        } else {
                            console.log('lesser')
                            Setitup(event.target.files[0])
                            // setLoader(false)
                        }
                    } else {
                        options = {
                            maxSizeMB: 5,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true
                        }
                        if (event.target.files[0].size > 2999999) {
                            try {
                                const compressedFile = await imageCompression(event.target.files[0], options);
                                console.log(compressedFile)
                                Setitup(compressedFile)
                                setLoader(false)
                            } catch (error) {
                                console.log(error);
                                // setLoader(false)
                            }
                        } else {
                            console.log('lesser than 3mb')
                            Setitup(event.target.files[0])
                            // setLoader(false)
                        }
                    }
                    // if (event.target.files[0].size > 1000000) {
                    //     console.log('greater')
                    //     // const options = {
                    //     //     maxSizeMB: 1,
                    //     //     maxWidthOrHeight: 800,
                    //     //     useWebWorker: true
                    //     // };
                    //     const nav: any = navigator
                    //     const { userAgentData } = nav
                    //     console.log(userAgentData.platform)
                    //     const options = userAgentData?.platform === 'macOS' ? {
                    //         maxSizeMB: 1,
                    //         // maxWidthOrHeight: 800,
                    //         maxWidthOrHeight: 1392,
                    //         useWebWorker: true
                    //     } : {
                    //         maxSizeMB: 5,
                    //         maxWidthOrHeight: 1920,
                    //         useWebWorker: true
                    //     }
                    //     try {
                    //         const compressedFile = await imageCompression(event.target.files[0], options);
                    //         console.log(compressedFile)
                    //         Setitup(compressedFile)
                    //     } catch (error) {
                    //         console.log(error);
                    //         // alert(error.message)
                    //     }

                    // } else {
                    //     console.log('lesser')
                    //     Setitup(event.target.files[0])
                    // }

                }
                // const reader = new FileReader();
                // reader.readAsDataURL(event.target.files[0]);
                // reader.addEventListener("load", () => {
                //   setImage(reader.result);
                // });
            }
            // const reader = new FileReader()
            // //   console.log('reader',reader)
            //   reader.readAsDataURL(event.target.files[0]);
            //   reader.addEventListener("load", () => {
            //     setImage(reader.result);
            //      setSaveBar(true)   

            //   });
        }
    };

    const onUpload = async () => {
        if (image) {
            setLoader(true);
            setAlerter(false);

            const form_data = new FormData();
            const canvas = await getCroppedImg(image, croppedArea);
            const canvasDataUrl = canvas.toDataURL("image/jpeg");
            const convertedURLtoFile = dataURLtoFile(
                canvasDataUrl,
                "cropped-image.jpeg"
            );
            //   console.log("image", image);
            console.log("convertedURLtoFile", convertedURLtoFile);

            form_data.append("cover_photo", convertedURLtoFile);
            const sucess = (res: any) => {
                setImage(null)
                setSaveBar(false)
                dispatch(update_profile(
                    res.data.data
                ))
            }

            update_coverPhoto(history, dispatch, form_data, sucess)
            // }
        } else {
            // alert(' select an image you fool')
            //   textalert();
        }
    };


    const handleClickOpen = () => { 
        setOpen(true);
    };

    const [openSingle, setOpenSingle] = useState(false)
    const [currentimage, setcurrent] = useState<any>()

    const [stage, setstage] = useState(1);




    return (
        <Switch>
            <Route exact path={'/profile'}>
                <>
                    {
                        error === null ? (
                            loading === false ? (
                                <div className='nw_profile nw_commoncontainer minvh100 '>
                                    {saveBar &&
                                        <div className='shadowBtns'>
                                            <button className="mainBtn" style={{ width: '105px', height: '40px' }} onClick={onCancel}> cancel </button>
                                            <button className="mainBtnInv" style={{ width: '105px', height: '40px', marginRight: '10px', border: 'none' }} onClick={onUpload}>
                                                {loader ? <span
                                                    style={{
                                                        maxHeight: "13px",
                                                        fontSize: "13px",
                                                        overflow: "hidden",
                                                        color: 'white'
                                                    }}
                                                >
                                                    <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                                                </span> : 'Save'} </button>
                                        </div>
                                    }
                                    {open && <ProfileDialog
                                        dialogStatus={open}
                                        dialogAction={setOpen}
                                        // fetchProfile={fetchProfile}
                                        setChange={setChange}
                                        change={'profile'}
                                        currentDp={profile.dp}
                                    />
                                    }
                                    {
                                        openSingle && <SingleImageModal dialogAction={setOpenSingle} dialogStatus={openSingle} currentimg={currentimage} />
                                    }

                                    <div className='nw_coverphotocontainer' >
                                        {
                                            image ? (
                                                //  <div className="crop-containe">
                                                <Cropper
                                                    image={image}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={348 / 95}
                                                    cropSize={{ width: 1392, height: 380 }}
                                                    onCropChange={setCrop}
                                                    onZoomChange={setZoom}
                                                    onCropComplete={onCropComplete}
                                                    objectFit="horizontal-cover"
                                                    // 1392 is the width of the container                        
                                                    onMediaLoaded={(mediaSize) => {

                                                        console.log('mediaSize', mediaSize)
                                                        setminZoom(1392 / mediaSize.width)
                                                        setZoom(1392 / mediaSize.width)
                                                    }}
                                                    maxZoom={15}
                                                    zoomSpeed={0.10}
                                                    minZoom={minZoom}
                                                />


                                                // </div>

                                            ) : (
                                                <img src={profile.cover_photo} className='nw_coverphotocontainerimg' alt='Profile cover photo' onClick={() => {
                                                    setcurrent(profile.cover_photo);
                                                    setOpenSingle(true)
                                                }}></img>
                                            )
                                        }
                                        <input id="picture" type="file" accept="image/*" onChange={onSelectFile} style={{ display: "none" }} />
                                        <Plus onClick={(e: any) => {
                                            triggerFileSelectPopup()
                                        }} />
                                    </div>
                                    <div className='nw_userDetails'>
                                        <div className='nw_user'>
                                            <div className='nw_profiledp'>
                                                <img src={profile.dp} alt="P_I_C" onClick={() => {
                                                    setcurrent(profile.dp);
                                                    setOpenSingle(true)
                                                }}></img>
                                                <input id="myDpInput" type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} />
                                                <Plus onClick={
                                                    (e: any) => {
                                                        handleClickOpen();
                                                    }
                                                } />
                                            </div>
                                            <div className='profname'>
                                                <h1>{profile.firstname + ' ' + profile.surname}</h1>
                                                <span>{'@' + profile.username}</span>
                                            </div>
                                        </div>
                                        <p className='profbio' onClick={() => console.log(profile.bio)}>
                                            {profile.bio}

                                        </p>


                                    </div>
                                    <MiniProfile1 />

                                    <div className='nw_contactrow'>
                                        <h3>Contact</h3>
                                        <div className='nw_contactdetails'>
                                            {!profile.is_business_profile && <div className='contact'>
                                                <Phone />
                                                <p>{profile.phone}</p>
                                            </div>}
                                            {!profile.is_business_profile && <hr></hr>}
                                            <div className='nw_contact'>
                                                <Mail />
                                                <a href={`mailto:${profile.email}`}>{profile.email}</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Storiesnew story={stories} type={'profile'} /> */}
                                    {/* doubts on the name on the bottom of the story*/}
                                    {/* <div>
                                <h2 style={{marginBottom:'10px'}}>Archived stories</h2> */}

                                    <NewStories story={stories} type={'profile'} />
                                    {/* </div> */}

                                    {/* <div className='profileDetails'> */}
                                    {/* <div className='col1'>

                                        <Stories story={stories} type={'profile'} />
                                    </div> */}
                                    {/* <div className='col2'>

                                        <MiniProfile1 />
                                    </div> */}
                                    {/* </div> */}

                                    {/* 
                                    
                                    ===================================================================================
                                    MY TICKETS
                                    ===================================================================================
                                    */}

                                    <div style={{ width: "100%", display: 'flex', flexDirection: 'column', overflow: 'scroll', gap: "30px" }} className="nw_ticketwrapper scrollbar-hidden">

                                        <div style={{ display: 'flex', }}>
                                            <h2> My Tickets</h2>
                                        </div>
                                        {
                                            Array.isArray(ticket) && ticket.slice(0, seeAll ? ticket.length : 2).map((item: any, key) => {

                                                // console.log("TICKET ITEM",item)
                                                console.log("TICKET ITEM", item?.event)

                                                const { tickets } = item?.event
                                                console.log("ticket ??", item)

                                                return (Array.isArray(tickets) && tickets.map((ticketitem: any, ke: any) => {
                                                    const { category_name, price, purchase_detail } = ticketitem
                                                    console.log("TicketItem", ticketitem)

                                                    if (tickets.length === 1) {


                                                        return (<>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <h3> {item?.event?.name}</h3>
                                                                    <h4> - {category_name} Tickets</h4>
                                                                </div>
                                                                {/* <ArrowForward /> */}
                                                            </div>
                                                            <div className="scrollbar-hidden" style={{ display: 'flex', flexDirection: 'row', gap: "10px", overflow: 'scroll' }}>{

                                                                Array.isArray(purchase_detail) && purchase_detail.map((i: any, k: any) => {

                                                                    return (
                                                                        <div style={{ display: "flex" }}>
                                                                            <ViewTicket event={item?.event} item={i} key={k} category_name={category_name} price={price} />
                                                                        </div>)
                                                                })}
                                                            </div>

                                                        </>)


                                                    } else {

                                                        return (
                                                            <>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <h3> {item?.event?.name}</h3>
                                                                        <h4> - {category_name} Tickets</h4>
                                                                    </div>
                                                                    <ArrowForward />
                                                                </div>

                                                                <div className="scrollbar-hidden" style={{ display: 'flex', flexDirection: 'row', overflow: 'scroll', gap: "10px" }}>{

                                                                    Array.isArray(purchase_detail) && purchase_detail.map((i: any, kss: any) => {

                                                                        return (
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                                <ViewTicket event={item?.event} item={i} key={kss} category_name={category_name} price={price} />

                                                                            </div>)
                                                                    })}
                                                                </div>




                                                            </>)

                                                    }




                                                    // return <ViewTicket event={item?.event} item={i} key={k} category_name={category_name} price={price} />
                                                }))


                                            })
                                        }
                                        <span style={{ color: 'var(--cl_orange)', cursor: "pointer", fontWeight: 500, paddingLeft: "10px" }} onClick={() => setseeAll(!seeAll)}>{seeAll ? 'View Less' : "See All"}</span>

                                    </div>


                                    <div className='mw_eventmediacontainer'>
                                        <div className='menuContainer'>
                                            <div className='navContainer'>
                                                <div className={stage === 1 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'}
                                                    onClick={() => { setstage(1); }}
                                                    onDoubleClick={() => {
                                                        history.push('/profile/events')
                                                    }}>Events</div>
                                                <div className={stage === 2 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'}
                                                    onClick={() => { setstage(2); }}
                                                    onDoubleClick={() => {
                                                        history.push('/profile/media')
                                                    }}
                                                >Media</div>
                                                {/* {profile.is_business_profile && <div className={stage === 3 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'}
                                            onClick={() => { setstage(3); }}
                                            onDoubleClick={() => {
                                                history.push('/profile/analytics')
                                            }}
                                        >Analytics</div>} */}
                                            </div>

                                        </div>

                                        {

                                            stage === 1 && (
                                                events.length !== 0 ? (
                                                    // <UserEvents1 />
                                                    <div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap  lg:!tw-flex-wrap ' style={{ maxWidth :"90vw"}}>
                                                        {
                                                            events.map((item, key) => {

                                                                return (
                                                                    <ProfileTile event={item} k={key} />
                                                                )

                                                            })
                                                        }

                                                        {
                                                            (events.length < (profile?.total_events ? profile?.total_events : 0)) && <div className='eventTileBorder'>
                                                                <div className="eventTile skeletoneventtile" style={{ fontSize: '1rem', backgroundColor: 'var(--cl_bggrey)', color: 'var(--cl_textgrey)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }} onClick={
                                                                    () => {
                                                                        history.push(`${pathname}/events`)
                                                                    }
                                                                }>
                                                                    <IoIosPhotos style={{ fontSize: '2.5rem', color: 'var(--cl_textgrey)' }} />
                                                                    See all
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className='min400px centered'>
                                                        <div className='noData'>
                                                            <Event />
                                                            <h2>{noData.events.heading}</h2>
                                                            <span>{noData.events.message}</span>
                                                        </div>
                                                    </div>
                                                ))
                                        }

                                        {stage === 2 && <ProfileMedia media={media} media_count={profile.media_count} />}
                                        {/* {stage === 2 && <UserMedia />} */}

                                        {/* {stage === 3 && <ProfileAnalytics />} */}
                                    </div>

                                </div >
                            ) : (
                                <div className='profile container'>
                                    <div className='coverPhoto skeleton'>
                                    </div>
                                    <div className='profileDetails'>
                                        <div className='col1'>
                                            <div className='userDetails'>
                                                <div className='user'>
                                                    <div className='profileDp skeleton'>

                                                    </div>
                                                    <div className='name'>
                                                        <h1 className='minhe20 minwid150 maxwid150 skeleton' style={{ marginBottom: '20px' }}></h1>
                                                        <span className='minwid60 skeleton minhe10'></span>
                                                    </div>
                                                </div>
                                                {/* <p className='bio skeleton '>
                                    
                                </p> */}
                                                <div>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                </div>


                                            </div>
                                            {/* <Stories story={[]} type={'profile'} /> */}
                                        </div>
                                        <div className='col2'>
                                            <div className='row1'>
                                                <h3>Contact</h3>
                                                <div className='contactDetails'>
                                                    {/* <div className='contact'>
                                        <Loc/>
                                        <p>{profile.}</p>
                                    </div> */}
                                                    <div className='contact'>
                                                        <Phone />
                                                        <p className='minwid200 skeleton minhe10'></p>
                                                    </div>
                                                    <hr></hr>
                                                    <div className='contact'>
                                                        <Mail />
                                                        <p className='minwid200 skeleton minhe10'></p>
                                                        {/* <a href={`mailto:${profile.email}`}>{profile.email}</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row2'>
                                                <div className='popularity'>
                                                    <div className='col' >
                                                        <h3 className='skeleton'></h3>
                                                        <div className='pop'>
                                                            <Follower />
                                                            <span>Followers</span>
                                                        </div>
                                                    </div>
                                                    <div className='col' >
                                                        <h3 className='skeleton'></h3>
                                                        <div className='pop'>
                                                            <Followin />
                                                            <span>Following</span>
                                                        </div>
                                                    </div>
                                                    <div className='col' >
                                                        <h3 className='skeleton'></h3>
                                                        <div className='pop'>
                                                            <Calendar />
                                                            <span>Events</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='btnContainer'>
                                                    <button className='mainBtnInv pointer'>Edit profile</button>

                                                    <button className='mainBtnInv pointer' >My events</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='menuContainer'>
                                        <div className='navContainer'>
                                            <div className={stage === 1 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'} onClick={() => { setstage(1); }}>Events</div>
                                            <div className={stage === 2 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'} onClick={() => { setstage(2); }}>Media</div>

                                        </div>
                                        {stage === 1 &&
                                            <div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap'>
                                                {
                                                    [...Array(10)].map(() => {
                                                        return (

                                                            <EventTileLoader />
                                                        )
                                                    })
                                                }
                                            </div>
                                        }


                                        {stage === 2 && <div className='mediaGrid'>
                                            {
                                                [...Array(10)].map(() => {
                                                    return <div className='videoTileContainer skeleton'></div>
                                                })

                                            }
                                        </div>}

                                    </div>
                                </div>
                            )
                        ) : (
                            error.status === null ? (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <Nointernet />
                                        <h2>{errors.internet.heading}</h2>
                                        <span>{errors.internet.message}</span>
                                        <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                                    </div>
                                </div>
                            ) : error.status === 500 ? (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <Server />
                                        <h2>{errors.server.heading}</h2>
                                        <span>{errors.server.message}</span>
                                        <button className='mainBtn' onClick={Refresh}>Try again</button>
                                    </div>
                                </div>
                            ) : error.status === 401 ? (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <NotFound />
                                        <h2>{errors.notFound.heading}</h2>
                                        <span>{errors.notFound.message}</span>
                                    </div>
                                </div>
                            ) : (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <NotFound />
                                        <h2>{errors.common.heading}</h2>
                                        <span>{errors.common.message}</span>
                                        <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                                    </div>
                                </div>
                            )

                        )}
                    <Footer />
                </>
            </Route>
            <Route exact path={`/profile/followers`}>
                <Followers />
            </Route>
            <Route exact path={`/profile/following`}>
                <Following />
            </Route>
            <Route exact path={`/profile/events/eventdetail`}>
                <UserEventsDetail />
            </Route>
            <Route exact path={`/profile/events`}>
                {/* <UserEvents /> */}
                {(!loading && error === null) && <UserEvents />}
                {loading &&
                    <div className='following container minvh100'>
                        <div className='commonHeading'>
                            <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                            {/* <h2>Following profiles</h2> */}
                        </div>
                        <div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap'>
                            {
                                [...Array(6)].map(() => {
                                    return (
                                        <EventTileLoader />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    error !== null &&
                    (error === 'internet' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ) : error === 'server' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn'>Try again</button>
                            </div>
                        </div>
                    ) : error === 'notFound' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }
            </Route>
            <Route exact path={`/profile/media`}>
                {/* <Mediapage /> */}
                {(!loading && error === null) && <UserMedia />}

                {loading &&
                    <div className='following container minvh100'>
                        <div className='commonHeading'>
                            <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                            {/* <h2>Following profiles</h2> */}
                        </div>
                        <div className='mediaGrid'>
                            {
                                [...Array(6)].map(() => {
                                    return <div className='videoTileContainer skeleton'></div>
                                })
                            }
                        </div>
                    </div>
                }
                {
                    error !== null &&
                    (error === 'internet' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ) : error === 'server' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn'>Try again</button>
                            </div>
                        </div>
                    ) : error === 'notFound' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }
            </Route>
            {/* <Route path="/eventdetail" exact component={UserEventsDetail} /> */}
            {/* {profile.is_business_profile && <Route exact path={`/profile/analytics`}>
                <ProfileAnalyticsPage />
            </Route>} */}
            <Redirect exact to={'/profile'} />
        </Switch>


    )
}

export default Newprofile
