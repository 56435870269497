import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './legal.css'
import { CircularProgress } from "@material-ui/core"
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import Api from '../../app/api'
import { useHistory } from 'react-router'
import { legalSuccess, legalFailed, legalRequest } from './store/legalslice'

const Legal = ({ type }: { type: string }) => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { loading, legal, error } = useAppSelector(state => state.legal)
    const success = (data: any) => {
        dispatch(legalSuccess(data))
    }   
    const failed = (data: any) => {
        dispatch(legalFailed(data))
    }
    useEffect(() => {
        if (Object.keys(legal).length === 0) {
            dispatch(legalRequest())
            Api('get', '/app/general/', null, success, failed, history, false, true)
        }
    }, [type])
    return (
        loading === false && error === null ? (
            <div className='policyLayout'>
                <div dangerouslySetInnerHTML={{
                    __html: type === 'privacy' ? legal.privacy_policy :
                        type === 'guidelines' ? legal.community_guidelines :

                        type === 'accountdeletion' ? legal.account_deletion :

                        
                        legal.terms_conditions
                }} />
            </div>
        ) : loading && error === null ? (
            <div className='policyLayout margincalc'>
                <div style={{color: 'var(--cl_orange)'}}>
                    <CircularProgress color="inherit" />
                </div>
            </div>
        ) : <div className='policyLayout margincalc'>
                <div style={{color: 'var(--cl_orange)'}}>
                    <CircularProgress color="inherit" />
                </div>
            </div>
    )

}

export default Legal
