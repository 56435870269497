import React, { useEffect, useRef, useCallback } from "react";
import { noData } from "../../../Data/Data";
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader'
import FeaturedProfileItemFollowers from '../../smallcomponents/featuredprofileitem/FeaturedProfileItemFollowers'
// import Api from "../../../app/api";
import { followersFailed } from "./store/followersSlice";
import { FetchFollowersPageData } from "../following/store/action";

interface Iprops {
    profileid: string;
}
const Followers = () => {
    const { profileid } = useParams<Iprops>()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { followers, error, loading, page, pagination } = useAppSelector(state => state.followers)
    const { profile } = useAppSelector(state => state.profile)

    useEffect(() => {
        window.scrollTo(0,0);
        const success = (res: any) => {
        }
        const failed = (res: any) => {
            dispatch(followersFailed(res))
        }
        if (Object.keys(profile).length !== 0) {
            // if(!loading){
                FetchFollowersPageData(dispatch, history, profile.id, success, failed, 1, [])
            // }
            
        }

    }, [dispatch, history, profile])

    const observer: any = useRef()

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && pagination) {
                let page_no = page + 1;
                if (!loading) {
                    FetchFollowersPageData(dispatch, history, profile.id, () => { }, () => { }, page_no, followers)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [dispatch, history, pagination, page, profile.id, followers, loading])

    return (
        <div className='followers container minvh100'>
            <div className='commonHeading'>
                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                <h2>Followers profiles</h2>
            </div>
            {<div className="featuredProfilesList">
                {(Object.keys(followers).length === 0) ? (
                    (loading === false && error === null) &&
                    // <div className='eventGrid'>
                        <div className='noData'>
                            <Warning />
                            <h2>{noData.profilefollowers.heading}</h2>
                            <span>{noData.profilefollowers.message}</span>
                            <div className='loadmore'>
                                <button className='mainBtn' onClick={() => history.push('/profile')}>Go back to profile page</button>
                            </div>
                        </div>
                    // </div>

                ) : (
                    followers?.map((item, key) => {
                        if (followers.length === key + 1) {
                            return (
                                <div ref={lastBookElementRef}>
                                    <FeaturedProfileItemFollowers featured={item} k={key} unf={true} place={'followers'} />
                                </div>
                            )
                        } else {
                            return (<FeaturedProfileItemFollowers featured={item} k={key} unf={true} place={'followers'} />)
                        }
                    })
                )
                }
                {loading &&
                    [...Array(6)].map(() => {
                        return (
                            <FeaturedProfileLoader />
                        )
                    })
                }
            </div>}

        </div>
    )
}

export default Followers
