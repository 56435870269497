import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Api from '../../../app/api'
import SuccessModal from '../../smallcomponents/modals/Success'
import ErrorModal from '../../smallcomponents/modals/Error'
import WarningModal from '../../smallcomponents/modals/Warning'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import './notificationsettings.css'

const NotificationSettings = () => {
    const history = useHistory()

    const [open, setopen] = useState(false)
    const [open1, setopen1] = useState(false)
    const [open2, setopen2] = useState(false)
    let s: any = null
    const [sc, setsc] = useState(s)
    let e: any = null
    const [er, seter] = useState(e)
    let w: any = {
        head: '',
        message: '',
        button: '',
        button1: '',
        link: null
    }
    const [war, setwar] = useState(w)






    const [attending, setattending] = useState(false)
    const [post, setpost] = useState(false)
    const [likes, setlikes] = useState(false)
    const [following, setfollowing] = useState(false)
    const [comments, setcomments] = useState(false)
    const [invitation, setinvitation] = useState(false)
    let dt: any = {}
    const [data, setdata] = useState(dt)

    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const success = (data: any) => {
        const { accept_invitation, add_post_to_attending_event, attending_your_event, comment_photo,
            likes_photo, starts_following } = data
        console.log(data)
        setattending(attending_your_event);
        setpost(add_post_to_attending_event);
        setlikes(likes_photo);
        setfollowing(starts_following);
        setinvitation(accept_invitation);
        setcomments(comment_photo);
        setdata({
            attending_your_event: attending_your_event,
            add_post_to_attending_event: add_post_to_attending_event,
            likes_photo: likes_photo,
            comment_photo: comment_photo,
            starts_following: starts_following,
            accept_invitation: accept_invitation
        })
    }
    const failed = (data: any) => {
        console.log(data)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again'
        }
        setwar({
            head: head,
            message: message,
            button: 'Retry',
            button1: 'Cancel',
            link: null
        })
        setTimeout(() => {
            setopen2(false)
            setwar({
                head: '',
                message: '',
                button: '',
                button1: '',
                link: null
            })
            history.push('/notifications')
        }, 5000);
        setopen2(true)

    }
    useEffect(() => {

        Api('get', '/api/v1/user/notification/settings/', null, success, failed, history, true, true)
        window.scrollTo(0,0)
    }, [])
    function Retry() {

        Api('get', '/api/v1/user/notification/settings/', null, success, failed, history, true, true)
    }

    const success1 = (data: any) => {
        console.log(data)
        const { accept_invitation, add_post_to_attending_event, attending_your_event, comment_photo,
            likes_photo, starts_following } = data.data
        setattending(attending_your_event);
        setpost(add_post_to_attending_event);
        setlikes(likes_photo);
        setfollowing(starts_following);
        setinvitation(accept_invitation);
        setcomments(comment_photo);
        console.log()
        setdata(data)
    }
    const failed1 = (data: any) => {
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            setopen1(false)
            seter(null)
        }, 5000);
        setopen1(true)
    }
    function ChangeSetting(which:string) {
        if ((Object.keys(data).length) !== 0) {
            let change = data
            console.log(change)
            console.log(change[which])
            if (change[which] === false) {
                change[which] = true
            } else {
                change[which] = false
            }
            console.log(change)
            let theurl = `/api/v1/user/notification/settings/`
            if (unparseddetails) {
              if (currentparsed.is_business_profile) {
                theurl = `/api/v1/user/notification/settings/?auth_profile=${parseddetails[0].id}`
              }
      
            }
            Api('post', theurl, change, success1, failed1, history, true, true)
        } else {
            seter({
                head: "Something's not right.",
                message: 'Please try refreshing the page and try again',
                button: 'Okay',
                link: null
            })
            setTimeout(() => {
                setopen1(false)
                seter(null)
            }, 5000);
            setopen1(true)
        }
    }
    return (
        <div className='notificationSettings minvh100'>
            <div className='commonHeading'>
                <small onClick={() => {
                    history.goBack()
                }}><Arrow />Back to previous page</small>
                <h2>Notification Settings</h2>
            </div>
            <div className='notificationSettingsContent'>
                <div className='settingsCategory'>
                    <h3>Events</h3>
                    <div className='notificationSettingsContainer'>
                        <div className='notificationSettingsItem'>
                            <p>Notify when someone is attending my event</p>
                            <div className={attending ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('attending_your_event') }}>
                                <div className='addeventdot'></div>
                            </div>
                        </div>
                        <div className='notificationSettingsItem'>
                            <p>Notify when someone adds a post to an event i'm attending</p>
                            <div className={post ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('add_post_to_attending_event') }}>
                                <div className='addeventdot'></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='settingsCategory'>
                    <h3>Friends</h3>
                    <div className='notificationSettingsContainer'>
                        <div className='notificationSettingsItem'>
                            <p>Notify when someone likes my photo</p>
                            <div className={likes ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('likes_photo') }}>
                                <div className='addeventdot'></div>
                            </div>
                        </div>
                        <div className='notificationSettingsItem'>
                            <p>Notify when someone comments on my photo</p>
                            <div className={comments ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('comment_photo') }}>
                                <div className='addeventdot'></div>
                            </div>
                        </div>
                        <div className='notificationSettingsItem'>
                            <p>Notify when someone starts following me</p>
                            <div className={following ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('starts_following') }}>
                                <div className='addeventdot'></div>
                            </div>
                        </div>
                        <div className='notificationSettingsItem'>
                            <p>Notify when someone accept my invitation</p>
                            <div className={invitation ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('accept_invitation') }}>
                                <div className='addeventdot'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {open &&
                <SuccessModal
                    open={open}
                    setOpen={setopen}
                    // success={suc}
                    success={sc}
                />
            }
            {open1 &&
                <ErrorModal
                    open={open1}
                    setOpen={setopen1}
                    // success={suc}
                    error={er}
                />
            }
            {open2 &&
                <WarningModal
                    open={open2}
                    setOpen={setopen2}
                    // success={suc}
                    warning={war}
                    fun={Retry}
                />
            }
        </div>
    )
}

export default NotificationSettings
