import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { ExpandMoreOutlined, Favorite, Share } from "@material-ui/icons";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from 'dayjs';
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'
import Api from "../../../../app/api";
import { useHistory } from 'react-router-dom'

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
    })
}));

export default function ReportedComments(data: any) {
    const [expanded, setExpanded] = React.useState(false);
    const history = useHistory()
    const [approvedState, setApprovedState] = React.useState<any>('')
    //     const [approvedState,setApprovedState]=React.useState<any>(false)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const ApprovedFx = ({ id }: any) => {
        console.log('----id----', id)
        const success = (res: any) => {
            console.log('--------------------------approved------------', res)
            setApprovedState('true')

        }
        const failed = () => {

        }

        let theUrl = `/api/v1/permissions/content-moderation/comments/approve-disapprove/${id}/`;
        Api('post', theUrl, { "action_key": "approved" }, success, failed, history, true, true)

    }
    const DisapprovedFx = ({ id }: any) => {
        const success = (res: any) => {
            console.log('--------------------------disapproved------------', res)
            setApprovedState('false')
        }
        const failed = () => {

        }

        let theUrl = `/api/v1/permissions/content-moderation/comments/approve-disapprove/${id}/`;
        Api('post', theUrl, { "action_key": "disapproved" }, success, failed, history, true, true)

    }
    // categoryData.
    // console.log('--------------------------------the daaaataaaaaa==================', data)
    return (
        <Card sx={{ maxWidth: "100 % ", border: "solid .3px", borderColor: "lightgrey", borderRadius: "12px", padding: "0px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0px" }}>

                <div style={{ display: "flex", flexDirection: "column", width: "60%", }}>

                    <CardHeader
                        avatar={
                            <Avatar src={data.data?.comment_details.commented_profile.profile_dp} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                R
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                {/* <MoreVertIcon /> */}
                            </IconButton>
                        }
                        title={data.data?.comment_details.commented_profile.username}
                        subheader={dayjs.utc(data.data?.comment_details.created_at).local().format('DD/MM/YYYY hh:mm A')}
                    />
                    {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}
                    <CardContent style={{ width: "90%", maxLines: "4", textOverflow: "ellipsis", padding: "0px 0px 10px 10px" }}>
                        <Typography variant="body2" color="text.secondary">
                            {data.data?.comment_details.comment}
                        </Typography>
                    </CardContent>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <div style={{ color: "grey", padding: "6px 6px 6px 6px", margin: "10px", backgroundColor: "lightgrey", justifyItems: "center", borderRadius: "7px", fontWeight: 'bold' }} >{data.data?.category.toUpperCase()}</div>
                    <CardHeader style={{ display: "flex" }}
                        avatar={
                            <Avatar src={data.data?.reported_profile.profile_dp} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                R
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings">
                                {/* <MoreVertIcon /> */}
                            </IconButton>
                        }
                        title={data.data?.reported_profile.username}
                        subheader={dayjs.utc(data.data?.reported_profile.reported_datetime).local().format('DD/MM/YYYY hh:mm A')}

                    />
                </div>
            </div>
            <CardActions style={{ display: "flex", width: "100%", justifyContent: "space-between" }} >
                <div style={{ display: "flex", width: "80%", gap: "10px" }}>
                    <IconButton aria-label="add to favorites">
                        <Favorite />{data.data?.comment_details.likes}
                    </IconButton>
                    {/* <IconButton aria-label="share">
                    <Share />
                </IconButton> */}
                    {
                        data.data?.comment_details.moderated_status ?
                            <div>
                                <button className={data.data?.comment_details.moderated_status === 'approved' ? 'cm_content_statbtn_big cm_content_statbtn_green' : 'cm_content_statbtn_big cm_content_statbtn_red'} >


                                    {data.data?.comment_details.moderated_status === 'approved' ? <><Approved /> Approved</> : <><UnApproved /> Unapproved</>}
                                </button>
                            </div> :
                            approvedState ? <button className={approvedState === 'true' ? 'cm_content_statbtn_big cm_content_statbtn_green' : 'cm_content_statbtn_big cm_content_statbtn_red'} >


                                {approvedState === 'true' ? <><Approved /> Approved</> : <><UnApproved /> Unapproved</>}
                            </button>
                                : <div style={{ display: "flex", }}>
                                    <button className='cm_content_statbtn_big cm_content_statbtn_green' onClick={() => {
                                        console.log(data.data?.id)
                                        let id = data.data?.id
                                        ApprovedFx({ id: id })
                                    }}>


                                        <><Approved /> Approve</>
                                    </button>
                                    <button style={{ marginLeft: "15px" }} className='cm_content_statbtn_big cm_content_statbtn_red' onClick={() => {
                                        let id = data.data?.id
                                        DisapprovedFx({ id: id })
                                    }} >


                                        <><UnApproved /> Unapprove</>
                                    </button>
                                </div>
                    }


                </div>
                {data.data?.comment_replies.length > 0 ? <div style={{ display: "flex", color: "grey", flexDirection: "row", alignItems: "center" }}>
                    Replies {data.data?.comment_replies.length}   <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreOutlined />
                    </ExpandMore>
                </div> : null}

            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {
                    data.data?.comment_replies.map((item: any, key: any) => {
                        return (
                            <CardContent style={{ padding: "0px 10px 5px 10px" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                                    <CardHeader style={{ padding: "0px" }}
                                        avatar={
                                            <Avatar src={item.commented_profile_dp.profile_dp} sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                R
                                            </Avatar>
                                        }
                                        action={
                                            <IconButton aria-label="settings">
                                                {/* <MoreVertIcon /> */}
                                            </IconButton>
                                        }
                                        title={item.commented_profile_dp.username}
                                        subheader={item.reply}
                                    />
                                    <span style={{ fontSize: ".7rem" }}>{dayjs.utc(item.created_at).local().format('DD/MM/YYYY hh:mm A')}</span>
                                </div>

                                <Typography variant="body2" color="text.secondary">

                                </Typography>

                            </CardContent>
                        )
                    })
                }

            </Collapse>
        </Card>
    );
}
