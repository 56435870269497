import React, { useState } from 'react'
import { ReactComponent as Pin } from '../../../assets/svg/pin.svg';
import { ReactComponent as Share } from '../../../assets/svg/share.svg';
import { Events } from '../../Tabs/profile/store/interface';
import dayjs from 'dayjs';
import { Category, EventImage, Review, Participants } from '../../../app/commonInterface';
import { useHistory } from 'react-router-dom';
import EventShare from '../../smallcomponents/modals/EventShareModal'
import { putpreviewdata } from '../../pages/Event/store/eventSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { MdTimer } from 'react-icons/md'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface Profile {
    id: number;
    firstname: string;
    surname: string;
    username: string;
    dp: string;
}
interface Event {
    id?: number;
    profile?: Profile,
    category?: Category[];
    name?: string;
    description?: string;
    start_date?: string;
    end_date?: string;
    latitude?: string;
    longitude?: string;
    event_image?: EventImage[];
    review?: Review[];
    participants?: Participants[],
    total_rating?: number;
    participants_count?: number;
    slug?: string;
    kilometer?: any;
}



const EventTile1 = ({ event }: { event: any, k: number }) => {
    const dispatch = useAppDispatch()
    const [modalActive, setModalActive] = useState(false)
    const history = useHistory()
    const {
        profile: prof
    } = useAppSelector(state => state.profile)

    const { id, profile, name, start_date, latitude, longitude, event_image,
        kilometer, slug, permissions, end_date, category,
        description, alternate_profile ,is_claimable} = event;
    // const kilometer = 42
    function HandleClick() {
        dispatch(putpreviewdata({
            "id": id,
            "profile": profile,
            "invited": [],
            "attending": [],
            "organiser": [],
            "maybe": [],
            "cantgo": [],
            "category": category,
            "main_category": {},
            "review_rating_list": [],
            "total_rating": 0,
            "views": [],
            "tags": [],
            "assigned_role": [],
            "event_image": event_image,
            "posts": [],
            "permissions": permissions,
            "name": name,
            "description": description,
            "start_date": start_date,
            "end_date": end_date,
            "latitude": latitude,
            "longitude": longitude,
            "slug": slug,
            "cancel_status": false,
            "canceled_on": '',
            "cancel_message": '',
        }))
        history.push(`/event/${slug}`)
    }
    const StartToEnd = (start_date: any, end_date: any) => {
        // let start_date = "2022-11-04T01:20:00Z"
        // let end_date = "2022-11-05T13:20:00Z"
        let interval;
        // let endsup = 'st'
        // let startsup = 'rd'
        if (dayjs(start_date).format('YYYY') === dayjs(end_date).format('YYYY')) {
            if (dayjs(start_date).format('MMM') === dayjs(end_date).format('MMM')) {
                if (dayjs(start_date).format('DD') === dayjs(end_date).format('DD')) {
                    interval = dayjs(start_date).local().format('hh:mm A') + " - " + dayjs(end_date).local().format('hh:mm A DD MMM YYYY')
                } else {
                    interval = dayjs(start_date).local().format('hh:mm A') + " - " + dayjs(end_date).local().format('hh:mm A DD ddd MMM YYYY')
                }
            } else {
                interval = dayjs(start_date).local().format('hh:mm A') + " - " + dayjs(end_date).local().format('hh:mm A DD MMM YYYY')
            }
        } else {
            interval = dayjs(start_date).local().format('hh:mm A') + " - " + dayjs(end_date).local().format('hh:mm A DD MMM YYYY')
        }
        return interval
    }
    return (
        <div className='eventTileBorder' >
            <div className="eventTile" key={id}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); HandleClick() }}
            >
                <div className="eventTileImg" style={{ overflow: 'hidden' }} >
                    {event_image ?
                        event_image.slice(0, 1).map((img: any, key: number) => {
                            const { image } = img;
                            return (
                                <img key={key.toString()} src={image} className="eventTileImg" alt="" />
                            )
                        })
                        : null
                    }
                    {(kilometer === "0.0 KM" || kilometer === undefined) ? null : <div onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        window.open(`https://www.google.com/maps/search/${latitude},${longitude}/@${latitude},${longitude},17z`, "_blank")
                    }}
                        className="eventLocDiv"
                    >
                        <Pin style={{ color: 'var(--cl_orange)' }} />
                        {kilometer ? kilometer : '...' + ' KM'}
                    </div>
                    }

                </div>
                <div className="eventDescpDiv" style={{ position: 'relative' }}>
                    <div className="eventUsrDiv" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // history.push(`/user/${profile?.id}`)
                        if (profile?.id === prof.id) {
                            history.push('/profile')
                        } else {
                            history.push(`/user/${profile?.username}`)
                        }


                    }}>
                        <div className="eventUsrDp">
                            <img src={is_claimable ? alternate_profile?.dp : profile?.dp} className="eventUsrDpImg" alt="P_I_C" style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className="eventUsrSub" style={{ cursor: 'pointer' }}
                            onClick={
                                e => {
                                    if(is_claimable) return
                                    e.preventDefault(); e.stopPropagation();
                                    // history.push(`/user/${id}`)
                                    if (profile?.id === prof.id) {
                                        history.push('/profile')
                                    } else {
                                        history.push(`/user/${alternate_profile?.dp || profile?.username}`)
                                    }

                                }
                            }
                        >
                            {/* {profile?.firstname + ' ' + profile?.surname} */}
                            
                            {is_claimable ? alternate_profile?.firstname : (profile?.firstname + ' ' +  profile?.surname)}

                        </div>
                        <div className="eventUsrSub">&#8226;{' ' + dayjs.utc(start_date).local().format('DD MMMM YYYY')}

                        </div>
                    </div>
                    <div className="eventUsrSub" style={{ display: "flex", alignItems: "center", gap: "5px", maxWidth: '100%', margin: '5px 0 0 0px', fontWeight: 500 }}>
                        <MdTimer style={{ color: '#ff5917' }} />{StartToEnd(start_date, end_date)}
                    </div>
                    <div className="eventDescTitle">
                        {name}
                    </div>
                    {/* <h3 className="eventDescTitle" style={{padding:'0',margin:'10px 0 5px 0',}}>
                    {name}
                    </h3> */}
                    <p style={{ width: '100%', margin: '5px 0', color: 'rgb(136, 136, 136)', fontSize: '.7rem', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>{description}</p>

                    <div className="eventfooter" style={{ cursor: "pointer", position: 'absolute', bottom: '5px', right: '20px' }}
                    // onClick={(e) => { e.preventDefault(); e.stopPropagation(); modalOpen(); setuserDp(image) }}
                    >
                        {/* <div><FaRegComment/> Comments</div> */}
                        <div className='shareSvg' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setModalActive(!modalActive) }}><Share /></div>
                        {/* onClick = {Openmodal} */}
                    </div>
                </div>
            </div>
            {/* </Link> */}
            {/* {modal === 'true'? <ShareModalWindow></ShareModalWindow> } */}

            {/* {Modal && <ShareModal
                modalStatus={Modal}
                modalAction={setModal}
                //   ModalLink={ModalLink}
                //   setModalLink={setModalLink}
                userDp={userDp}
                id={theid}
                eventname={eventName}

            />} */}
            {modalActive && <EventShare type={'event'} data={event} open={modalActive} setOpen={setModalActive} />}

        </div>
    )
}

export default EventTile1
