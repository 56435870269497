

import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineArrowDropDown } from 'react-icons/md';

interface Iprops {
    value: any,
    setValue: React.Dispatch<any>
    DataSet: { value: any; label: string }[]
    dropDownStyle?: React.CSSProperties | undefined
    inputStyle?: React.CSSProperties | undefined
    listItemStyle?: React.CSSProperties | undefined
    ContainerClassName?: string
    inputClassName?: string
    placeholder?: string
    width?: string
    onChange?: (target: any, suggestionFx: (target: any) => void) => void
    dataType?: 'number'
    maxLength?: number
    minLength?: number
}





const SelectDropdown = ({ DataSet, value, setValue, inputStyle, dropDownStyle, listItemStyle, ContainerClassName, inputClassName, placeholder, width, onChange, dataType, maxLength, minLength }: Iprops) => {

    const timeRef: any = useRef();

    const timeRefInput: any = useRef();

    useEffect(() => {
        if (!Array.isArray(DataSet)) return
        setValue(DataSet[0])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!timeRef) return;
            if (timeRef.current && !timeRef.current.contains(event.target)) {
                hideSuggestion();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [timeRef]);

    const [Visibility, setVisibility] = useState(false);
    const [SuggestionList, setSuggestionList] = useState(DataSet);
    const hideSuggestion = () => setVisibility(false);
    const showSuggestion = () => {
        if (timeRefInput) {
            timeRefInput?.current?.focus();

        }

        setVisibility(true)
    };
    const Suggestion_fx = (time: any) => {
        if (!time) {
            setSuggestionList(DataSet);
        } else {
            let matches = DataSet.filter((item) => {
                const regex = new RegExp(`${time}`, 'gi');
                return item.label.match(regex);
            });
            let final_list_data: any = [];
            if (matches.length === 0) return
            matches.map((item_val, length) => {
                // if(length < 4) {
                final_list_data.push(item_val);
                // }
                return null;
            });


            setSuggestionList(final_list_data);
        }
    };

    const inputRef :any= useRef(null)
    


    return (

        <div className={ContainerClassName} style={{ width: width }}>

            <div style={{ position: 'relative' }}>
                <MdOutlineArrowDropDown style={{ position: 'absolute', top: '7px', right: "8px", width: "28px", height: '28px', color: "grey", pointerEvents: "none" }} />

                <input
                    ref={inputRef}
                    placeholder={placeholder}
                    style={inputStyle}
                    className={inputClassName}
                    maxLength={maxLength}
                    minLength={minLength}
                    onChange={(e) => {
                        let target;
                        if (dataType === "number") {
                            target = e.target.value.replace(/\D/g, '');

                        } else { target = `${e.target.value}` }

                        if (onChange) {
                            onChange(target, Suggestion_fx)
                        } else {
                            Suggestion_fx(target)

                        }
                    }}
                    // defaultValue={value?.label}
                    value={value?.label}
                    onClick={showSuggestion} />

                {<div
                    style={{
                        position: 'absolute',
                        top: '45px',
                        right: '0px',

                        width: '100%',
                        zIndex: 999,
                        backgroundColor: 'white',
                        borderRadius: '4px',
                    }}>
                    <div
                        className='suggestion-conatiner'
                        ref={timeRef}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            width: '100%',
                            position: 'relative',
                        }}>
                        {Visibility && SuggestionList.length > 0 && (
                            <div className='suggestion-main-layout' style={dropDownStyle}>
                                <ul className='suggestion-list-container' style={{ position: 'relative', padding: "1px 0px 4px 0px" }}>

                                    {SuggestionList &&
                                        SuggestionList.map((item: any, key: number) => {
                                            return (
                                                <>
                                                    <li
                                                        style={listItemStyle}
                                                        className='suggestion-list-item'
                                                        key={key}
                                                        onClick={() => {
                                                            setValue(item);
                                                      
                                                            hideSuggestion();
                                                        }}>
                                                        {item?.label}
                                                    </li>
                                                </>
                                            );
                                        })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>}
            </div>
        </div>



    )
}

export default SelectDropdown