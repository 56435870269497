import React, { useState } from 'react';
import ModalLayout from './../../../utils/ModalLayout';
import PhoneNumberValidate from './../../../login/PhoneNumberValidate';

interface common {
	handleClose: () => void;
	open: boolean;
	userList: [any];
	setUserList: any;
	name: string;
	email: string;
	setname: any;
	setemail: any;
	value: any;
	setValue: any;
	valid: any;
	setValid: any;
	code: any;
	setCode: any;
}
const SelectNonuser = ({
	open,
	handleClose,
	userList,
	setUserList,
	name,
	email,
	setname,
	setemail,
	value,
	setValue,
	valid,
	setValid,
	code,
	setCode,
}: common) => {
	const addUser = () => {
		let CurData = [...userList];

		if (validateEmail(email)) {
			CurData.push({
				name,
				email,
				phone: value,
				country_code: code,
			});

			setUserList(CurData);

            setname('');
            setemail('');
            setCode('+61');
            setValue('');

			handleClose();
		} else {
			seterror(true);
		}

	};

	function validateEmail(email: any) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}
	const clear = () => {
		setname('');
		setemail('');
		setCode('+61');
		setValue('');
	};

	const [error, seterror] = useState(false);

	let btnStatus = false;
	if (name && email && code && value) {
		btnStatus = true;
	}

	return (
		<ModalLayout open={open} handleClose={handleClose} style={{ height: '100vh', overflow: 'hidden' }}>
			<h2 style={{ borderBottom: '1px solid var(--cl_border_grey)', padding: '20px 20px' }}>Add Guest Users</h2>

			<div
				style={{
					padding: '20px',
					backgroundColor: 'white',
					height: '100%',
					minHeight: '500px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}>
				<div className='invitesect1'>
					<div className='AddEventSectTitle'>
						<h4> Name </h4>
						{/* {titleerror && <small>required*</small>} */}
					</div>
					<input
						type='text'
						className='AddEventSectInput'
						onChange={(e) => {
							setname(e.target.value);
						}}
					/>

					<div className='AddEventSectTitle'>
						<h4> Email </h4>
						{error && <small>please add a valid email</small>}
					</div>
					<input
						type='text'
						className='AddEventSectInput'
						onChange={(e) => {
							setemail(e.target.value);
						}}
						onFocus={() => seterror(false)}
					/>
					<PhoneNumberValidate value={value} setValue={setValue} valid={valid} setValid={setValid} code={code} setCode={setCode} />
				</div>

				<div style={{ display: 'flex', alignSelf: 'flex-end', gap: '10px' }}>
					<button style={{ minWidth: '100px' }} className='mainBtnInv' onClick={clear}>
						Reset
					</button>

					<button style={{ minWidth: '100px' }} className={btnStatus ? 'mainBtn' : 'mainBtn btn__inactive'} onClick={addUser}>
						Add User
					</button>
				</div>
			</div>
		</ModalLayout>
	);
};

export default SelectNonuser;
