import { createSlice, } from '@reduxjs/toolkit'

interface ContentModeratorState {
    eloading: boolean;
    eintialloading: boolean;
    eventsmoderation: any;
    epage: number;
    epagination: boolean;
    eerror: any;

    ploading: boolean;
    pintialloading: boolean;
    postmoderation: any;
    ppage: number;
    ppagination: boolean;
    perror: any;
    mloading: boolean;
    merror: any;
    moderatedstats: any;
    moderatedevents: any;
    moderatedloading: boolean;
    moderatederror: any;
    moderatedpagination: boolean;
    moderatedpage: number;
    moderatedposts: any;
    moderatedploading: boolean;
    moderatedperror: any;
    moderatedppagination: boolean;
    moderatedppage: number;
    reportedposts: any;
    reportedpostsloading: boolean;
    reportedpostspagination: boolean;
    reportedpostspage: number;
    reportedpostserror: any;

    reportedevents: any;
    reportedeventsloading: boolean;
    reportedeventspagination: boolean;
    reportedeventspage: number;
    reportedeventserror: any;

    reportedpostsmoderation: any;
    reportedpostsmoderationloading: boolean;
    reportedpostsmoderationloadinginitial: boolean;
    reportedpostsmoderationpagination: boolean;
    reportedpostsmoderationpage: number;
    reportedpostsmoderationerror: any;

    reportedeventsmoderation: any;
    reportedeventsmoderationloading: boolean;
    reportedeventsmoderationloadinginitial: boolean;
    reportedeventsmoderationpagination: boolean;
    reportedeventsmoderationpage: number;
    reportedeventsmoderationerror: any;

    cm_analytics: any;
    analyticsloading: Boolean;
    analyticserror: any;
    analyticschartdata: any;

    analyticsmoderated: any;
    analyticsmoderatedloading: Boolean;
    analyticsmoderatedpage: number;
    analyticsmoderatedpagination: Boolean;
    analyticsmoderatederror: any;

    analyticsreportedby: any;
    analyticsreportedbyloading: Boolean;
    analyticsreportedbypage: number;
    analyticsreportedbypagination: Boolean;
    analyticsreportedbyerror: any;

    listofmoderators: any;
    listofmoderatorserror: any;
    listofmoderatorsloading: boolean;
    listofmoderatorpage: number;
    listofmoderatorpagination: boolean;

    individualanalyticsmoderated: any;
    individualanalyticsmoderatedloading: Boolean;
    individualanalyticsmoderatedpage: number;
    individualanalyticsmoderatedpagination: Boolean;
    individualanalyticsmoderatederror: any;

    moderatedComments: any;
    moderatedCommentsLoading: Boolean;
    moderatedCommentsPage: number;
    moderatedCommentsPagination: Boolean;
    moderatedCommentsInitialLoading: Boolean;

}

const initialState: ContentModeratorState = {
    eloading: false,
    eintialloading: false,
    eventsmoderation: [],
    epage: 1,
    epagination: true,
    eerror: null,

    ploading: false,
    pintialloading: false,
    postmoderation: [],
    ppage: 1,
    ppagination: true,
    perror: null,
    moderatedstats: {},
    mloading: false,
    merror: null,
    moderatedevents: [],
    moderatedloading: false,
    moderatederror: null,
    moderatedpagination: true,
    moderatedpage: 1,

    moderatedposts: [],
    moderatedploading: false,
    moderatedperror: null,
    moderatedppagination: true,
    moderatedppage: 1,
    reportedposts: [],
    reportedpostsloading: false,
    reportedpostspagination: true,
    reportedpostspage: 1,
    reportedpostserror: null,

    reportedevents: [],
    reportedeventsloading: false,
    reportedeventspagination: true,
    reportedeventspage: 1,
    reportedeventserror: null,


    reportedpostsmoderation: [],
    reportedpostsmoderationloading: false,
    reportedpostsmoderationloadinginitial: false,
    reportedpostsmoderationpagination: true,
    reportedpostsmoderationpage: 1,
    reportedpostsmoderationerror: null,

    reportedeventsmoderation: [],
    reportedeventsmoderationloading: false,
    reportedeventsmoderationloadinginitial: false,
    reportedeventsmoderationpagination: true,
    reportedeventsmoderationpage: 1,
    reportedeventsmoderationerror: null,

    cm_analytics: {},
    analyticsloading: true,
    analyticserror: null,
    analyticschartdata: [],

    analyticsmoderated: [],
    analyticsmoderatedloading: false,
    analyticsmoderatedpage: 1,
    analyticsmoderatedpagination: true,
    analyticsmoderatederror: null,

    analyticsreportedby: [],
    analyticsreportedbyloading: false,
    analyticsreportedbypage: 1,
    analyticsreportedbypagination: true,
    analyticsreportedbyerror: null,

    listofmoderators: [],
    listofmoderatorserror: null,
    listofmoderatorsloading: false,
    listofmoderatorpage: 1,
    listofmoderatorpagination: true,

    individualanalyticsmoderated: [],
    individualanalyticsmoderatedloading: false,
    individualanalyticsmoderatedpage: 1,
    individualanalyticsmoderatedpagination: true,
    individualanalyticsmoderatederror: null,

    moderatedComments: [],
    moderatedCommentsLoading: false,
    moderatedCommentsPage: 1,
    moderatedCommentsPagination: true,
    moderatedCommentsInitialLoading: false

};

export const ContentmoderatorSlice = createSlice({
    name: 'moderator',
    initialState,
    reducers: {
        emoderationRequest: (state, action) => {
            state.eloading = true;
            state.epagination = true;
            state.eventsmoderation = action.payload.no === 1 ? [] : state.eventsmoderation;
            state.epage = action.payload.no;
            state.eintialloading = action.payload.no === 1 ? true : false;
        },
        emoderationSuccess: (state, action: any) => {
            state.eloading = false;
            state.eventsmoderation = state.eventsmoderation.concat(action.payload.data);
            state.epage = state.epage + 1;
            state.epagination = action.payload.pagination;
            state.eerror = null;
            state.eintialloading = false;
        },
        emoderationFailed: (state, action) => {
            state.eloading = false;
            state.eerror = action.payload;
            state.eintialloading = false;
        },
        pmoderationRequest: (state, action) => {
            state.ploading = true;
            state.ppagination = true;

            state.postmoderation = action.payload.no === 1 ? [] : state.postmoderation;
            state.ppage = action.payload.no;
            state.pintialloading = action.payload.no === 1 ? true : false;
        },
        pmoderationSuccess: (state, action: any) => {
            state.ploading = false;
            state.postmoderation = state.postmoderation.concat(action.payload.data);
            state.ppage = state.ppage + 1;
            state.ppagination = action.payload.pagination;
            state.perror = null;
            state.pintialloading = false;
        },
        pmoderationFailed: (state, action) => {
            state.ploading = false;
            state.perror = action.payload;
            state.pintialloading = false;
        },
        moderatedstatsrequest: (state) => {
            state.mloading = true;
            state.merror = null;
        },
        moderatedstatssuccess: (state, action) => {
            state.mloading = false;
            state.moderatedstats = action.payload;
            state.merror = null
        },
        moderatedstatsfailed: (state, action) => {
            state.mloading = false;
            state.merror = action.payload;
        },
        moderatedeventsrequest: (state, action) => {
            state.moderatedloading = true;
            state.moderatederror = null;
            state.moderatedpagination = true;
            state.moderatedpage = action.payload.no;
            state.moderatedevents = action.payload.no === 1 ? [] : state.moderatedevents;

        },
        moderatedeventssuccess: (state, action) => {
            state.moderatedloading = false;
            state.moderatederror = null;
            state.moderatedevents = state.moderatedevents.concat(action.payload.data);
            state.moderatedpage = state.moderatedpage + 1;
            state.moderatedpagination = action.payload.pagination;
        },
        moderatedeventsfailed: (state, action) => {
            state.moderatedloading = false;
            state.moderatederror = action.payload;
        },
        moderatedpostsrequest: (state, action) => {
            state.moderatedploading = true;
            state.moderatedperror = null;
            state.moderatedppagination = true;

            state.moderatedposts = action.payload.no === 1 ? [] : state.moderatedposts;
            state.moderatedppage = action.payload.no;
        },
        moderatedpostssuccess: (state, action) => {
            state.moderatedploading = false;
            state.moderatedperror = null;
            state.moderatedposts = state.moderatedposts.concat(action.payload.data);
            state.moderatedppage = state.moderatedppage + 1;
            state.moderatedppagination = action.payload.pagination;
        },
        moderatedpostsfailed: (state, action) => {
            state.moderatedploading = false;
            state.moderatedperror = action.payload;
        },
        eventapprove: (state, action) => {
            let newmoderated = state.eventsmoderation[action.payload.index]
            if (action.payload.status === 'approved') {
                newmoderated['moderated_status'] = 'approved'
                state.moderatedevents = [newmoderated].concat(state.moderatedevents);

            } else if (action.payload.status === 'disapproved') {
                newmoderated['moderated_status'] = 'disapproved'
                state.moderatedevents = [newmoderated].concat(state.moderatedevents);
            }

            let newarray: any = state.eventsmoderation.filter((item: any, key: number) => key !== action.payload.index)
            state.eventsmoderation = newarray

        },
        postapprove: (state, action) => {
            let newmoderated = state.postmoderation[action.payload.index]
            if (action.payload.status === 'approved') {
                newmoderated['moderated_status'] = 'approved'
                state.moderatedposts = [newmoderated].concat(state.moderatedposts)

            } else if (action.payload.status === 'disapproved') {
                newmoderated['moderated_status'] = 'disapproved'
                state.moderatedposts = [newmoderated].concat(state.moderatedposts)
            }

            let newarray: any = state.postmoderation.filter((item: any, key: number) => key !== action.payload.index)
            state.postmoderation = newarray
        },
        reportedeventapprove: (state, action) => {
            let newmoderated = state.reportedeventsmoderation[action.payload.index]
            if (action.payload.status === 'approved') {
                newmoderated['moderated_status'] = 'approved'
                state.reportedevents = [newmoderated].concat(state.reportedevents)

            } else if (action.payload.status === 'disapproved') {
                newmoderated['moderated_status'] = 'disapproved'
                state.reportedevents = [newmoderated].concat(state.reportedevents)
            }
            let newarray: any = state.reportedeventsmoderation.filter((item: any, key: number) => key !== action.payload.index)
            state.reportedeventsmoderation = newarray
        },
        reportedpostapprove: (state, action) => {
            let newmoderated = state.reportedpostsmoderation[action.payload.index]
            if (action.payload.status === 'approved') {
                newmoderated['moderated_status'] = 'approved'
                state.reportedposts = [newmoderated].concat(state.reportedposts)

            } else if (action.payload.status === 'disapproved') {
                newmoderated['moderated_status'] = 'disapproved'
                state.reportedposts = [newmoderated].concat(state.reportedposts)
            }
            let newarray: any = state.reportedpostsmoderation.filter((item: any, key: number) => key !== action.payload.index)
            state.reportedpostsmoderation = newarray
        },


        reportedeventsrequest: (state, action) => {
            state.reportedeventsloading = true;
            state.reportedeventserror = null;
            state.reportedeventspagination = true;
            state.reportedeventspage = action.payload.no;
            state.reportedevents = action.payload.no === 1 ? [] : state.reportedevents;
        },
        reportedeventssuccess: (state, action) => {
            state.reportedeventsloading = false;
            state.reportedeventserror = null;
            state.reportedevents = state.reportedevents.concat(action.payload.data);
            state.reportedeventspage = state.reportedeventspage + 1;
            state.reportedeventspagination = action.payload.pagination;
        },
        reportedeventsfailed: (state, action) => {
            state.reportedeventsloading = false;
            state.reportedeventserror = action.payload;
        },
        reportedpostsrequest: (state, action) => {
            state.reportedpostsloading = true;
            state.reportedpostserror = null;
            state.reportedpostspagination = true;
            state.reportedpostspage = action.payload.no;
            state.reportedposts = action.payload.no === 1 ? [] : state.reportedposts;
        },
        reportedpostssuccess: (state, action) => {
            state.reportedpostsloading = false;
            state.reportedpostserror = null;
            state.reportedposts = state.reportedposts.concat(action.payload.data);
            state.reportedpostspage = state.reportedpostspage + 1;
            state.reportedpostspagination = action.payload.pagination;
        },
        reportedpostsfailed: (state, action) => {
            state.reportedpostsloading = false;
            state.reportedpostserror = action.payload;
        },


        reportedeventsmoderationrequest: (state, action) => {
            state.reportedeventsmoderationloading = true;
            state.reportedeventsmoderationerror = null;
            state.reportedeventsmoderationpagination = true;

            state.reportedeventsmoderation = action.payload.no === 1 ? [] : state.reportedeventsmoderation;
            state.reportedeventsmoderationpage = action.payload.no;
            state.reportedeventsmoderationloadinginitial = action.payload.no === 1 ? true : false;
        },
        reportedeventsmoderationsuccess: (state, action) => {
            state.reportedeventsmoderationloading = false;
            state.reportedeventsmoderationerror = null;
            state.reportedeventsmoderation = state.reportedeventsmoderation.concat(action.payload.data)
            state.reportedeventsmoderationpage = state.reportedeventsmoderationpage + 1;
            state.reportedeventsmoderationloadinginitial = false;
            state.reportedeventsmoderationpagination = action.payload.pagination;
        },
        reportedeventsmoderationfailed: (state, action) => {
            state.reportedeventsmoderationloading = false;
            state.reportedeventsmoderationerror = action.payload;
            state.reportedeventsmoderationloadinginitial = false;
        },
        reportedpostsmoderationrequest: (state, action) => {
            state.reportedpostsmoderationloading = true;
            state.reportedpostsmoderationerror = null;
            state.reportedpostsmoderationpagination = true;

            state.reportedpostsmoderation = action.payload.no === 1 ? [] : state.reportedpostsmoderation;
            state.reportedpostsmoderationpage = action.payload.no;
            state.reportedpostsmoderationloadinginitial = action.payload.no === 1 ? true : false;
        },
        reportedpostsmoderationsuccess: (state, action) => {
            state.reportedpostsmoderationloading = false;
            state.reportedpostsmoderationerror = null;
            state.reportedpostsmoderation = state.reportedpostsmoderation.concat(action.payload.data);
            state.reportedeventsmoderationpage = state.reportedeventsmoderationpage + 1;
            state.reportedpostsmoderationloadinginitial = false;
            state.reportedpostsmoderationpagination = action.payload.pagination;
        },
        reportedpostsmoderationfailed: (state, action) => {
            state.reportedpostsmoderationloading = false;
            state.reportedpostsmoderationerror = action.payload;
            state.reportedpostsmoderationloadinginitial = false;
        },
        analyticsrequest: (state) => {
            state.analyticsloading = true;
            state.analyticserror = null;
        },
        analyticssuccess: (state, action) => {
            state.analyticsloading = false;
            state.analyticserror = null;
            state.cm_analytics = action.payload.data;
            state.analyticschartdata = action.payload.data.monthly_data;
        },
        analyticsfailed: (state, action) => {
            state.analyticsloading = false;
            state.analyticserror = action.payload;
        },
        analyticsmoderatedrequest: (state, action) => {
            if (action.payload === 1) {
                state.analyticsmoderated = []
                state.analyticsmoderatedpage = 1
            }
            state.analyticsmoderatedloading = true;
            state.analyticsmoderatederror = null;

        },
        analyticsmoderatedsuccess: (state, action) => {
            state.analyticsmoderatedloading = false;
            state.analyticsmoderatederror = null;
            state.analyticsmoderated = state.analyticsmoderatedpage === 1 ? action.payload.data : state.analyticsmoderated.concat(action.payload.data)
            state.analyticsmoderatedpagination = action.payload.pagination;
            state.analyticsmoderatedpage = action.payload.no
        },
        analyticsmoderatedfailed: (state, action) => {
            state.analyticsmoderatedloading = false;
            state.analyticsmoderatederror = action.payload;
        },
        reportedbysuccess: (state, action) => {
            state.analyticsreportedby = state.analyticsreportedbypage === 1 ? action.payload.data : state.analyticsreportedby.concat(action.payload.data);
            state.analyticsreportedbyloading = false;
            state.analyticsreportedbypage = state.analyticsreportedbypage + 1
            state.analyticsreportedbypagination = action.payload.pagination
        },
        reportedbyrequest: (state, action) => {
            if (action.payload.no === 1) {
                state.analyticsmoderatedpage = 1
                state.analyticsreportedby = []
                state.analyticsreportedbypagination = true
            }
            state.analyticsreportedbyloading = true
            state.analyticsreportedbyerror = null

        },
        reportedbyfailed: (state, action) => {
            state.analyticsreportedbyloading = false
            state.analyticsreportedbyerror = action.payload
        },
        moderatorlistrequest: (state, action) => {
            if (action.payload === 1) {
                state.listofmoderators = []
                state.listofmoderatorpage = 1
            }

            state.listofmoderatorsloading = true;
            state.listofmoderatorserror = null;
        },
        moderatorlist: (state, action) => {
            state.listofmoderatorsloading = false;
            state.listofmoderatorserror = null;
            state.listofmoderators = state.listofmoderatorpage === 1 ? action.payload.data : state.listofmoderators.concat(action.payload.data);
            state.listofmoderatorpagination = action.payload.pagination;
            state.listofmoderatorpage = action.payload.no;
        },
        moderatorlistfailed: (state, action) => {
            state.listofmoderatorsloading = false;
            state.listofmoderatorserror = action.payload;
        },
        individualanalyticsmoderatedrequest: (state, action) => {
            if (action.payload === 1) {
                state.individualanalyticsmoderated = []
                state.individualanalyticsmoderatedpage = 1
            }
            state.individualanalyticsmoderatedloading = true;
            state.individualanalyticsmoderatederror = null;

        },
        individualanalyticsmoderatedsuccess: (state, action) => {
            state.individualanalyticsmoderatedloading = false;
            state.individualanalyticsmoderatederror = null;
            state.individualanalyticsmoderated = state.individualanalyticsmoderatedpage === 1 ? action.payload.data : state.individualanalyticsmoderated.concat(action.payload.data)
            state.individualanalyticsmoderatedpagination = action.payload.pagination;
            state.individualanalyticsmoderatedpage = action.payload.no
        },
        individualanalyticsmoderatedfailed: (state, action) => {
            state.individualanalyticsmoderatedloading = false;
            state.individualanalyticsmoderatederror = action.payload;
        },
        reportedCommentsRequest: (state, action) => {
            state.moderatedCommentsLoading = true;
            state.moderatedCommentsPagination = true;

            state.moderatedComments = action.payload.no === 1 ? [] : state.moderatedComments;
            state.moderatedCommentsPage = action.payload.no;
            state.moderatedCommentsInitialLoading = action.payload.no === 1 ? true : false;
        },

        reportedCommentsSuccess: (state, action) => {
            // state.moderatedComments = action.payload;
            // state.moderatedComments = [];
            state.moderatedCommentsLoading = false;
            state.moderatedComments = state.moderatedComments.concat(action.payload.data);
            state.moderatedCommentsPage = state.moderatedCommentsPage + 1;
            state.moderatedCommentsPagination = action.payload.pagination;
            // state.perror = null;
            state.moderatedCommentsInitialLoading = false;
        },
        reportedCommentsInitial: (state, action) => {

            state.moderatedComments = []
        }


    },
})

export const { emoderationRequest, emoderationSuccess, emoderationFailed,
    pmoderationRequest, pmoderationSuccess, pmoderationFailed,
    moderatedstatsrequest, moderatedstatssuccess, moderatedstatsfailed,
    moderatedeventsrequest, moderatedeventssuccess, moderatedeventsfailed,
    moderatedpostsrequest, moderatedpostssuccess, moderatedpostsfailed,
    eventapprove, postapprove,
    reportedeventsrequest, reportedeventsfailed, reportedeventssuccess,
    reportedpostsfailed, reportedpostssuccess, reportedpostsrequest,

    reportedeventsmoderationrequest, reportedeventsmoderationfailed, reportedeventsmoderationsuccess,
    reportedpostsmoderationfailed, reportedpostsmoderationsuccess, reportedpostsmoderationrequest,
    reportedeventapprove, reportedpostapprove,
    analyticsrequest, analyticssuccess, analyticsfailed,
    analyticsmoderatedrequest, analyticsmoderatedsuccess, analyticsmoderatedfailed,
    reportedbysuccess, reportedbyrequest, reportedbyfailed,
    moderatorlistrequest, moderatorlist, moderatorlistfailed,
    individualanalyticsmoderatedrequest, individualanalyticsmoderatedsuccess, individualanalyticsmoderatedfailed, reportedCommentsRequest, reportedCommentsSuccess, reportedCommentsInitial
} = ContentmoderatorSlice.actions
export default ContentmoderatorSlice.reducer


