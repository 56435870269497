import React, { useRef, useState } from 'react'
import './featuredprofile.css'
import FollowButton from '../followbutton/FollowButton'
import { Featured } from '../../pages/featuredprofiles/store/interface'
import { useHistory } from 'react-router'
import { CircularProgress } from '@material-ui/core'
import { featuredfollowupdate } from '../../pages/featuredprofiles/store/featuredSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Api from '../../../app/api'
import { removefollowers } from '../../pages/followers/store/followersSlice'


const FeaturedProfileItemFollowers = ({featured,k,unf,place}: {featured: Featured,k: number,unf:boolean,place: any}) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {dp,firstname,follow_request,is_following,id,surname,username}= featured;
    const {
        profile:prof
    } = useAppSelector(state => state.profile)

    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const [loading, setloading] = useState(false)
    const [followng, setfollowng] = useState(is_following)
    const [req, setreq] = useState(follow_request)

    const thefollower: any = useRef(null)

    const Unfollowsuccess = (data: any) => {
        setloading(false)
        let node: any = thefollower.current;
        node.classList.add('fadeout1');
        dispatch(removefollowers(id))

    }
    const Unfollowfailed = (data: any) => {
        console.log(data)
        setloading(false)

    }

    const Remove = (id: any) => {
        setloading(true)
        let data = {
            "follower":id
        }
        let theurl = `/api/v1/user/followers/remove/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/user/followers/remove/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, data, Unfollowsuccess, Unfollowfailed, history, true, true)
    }
    return (
        <div className='featuredprofile' ref={thefollower} onClick={()=>{
            if(id ===prof.id){
                history.push('/profile')
            }else{
                history.push(`/user/${username}`)
            }
        }}>
            <img alt="P_I_C" src={dp}></img>
            <div className='featuredNames'>
                <h3>{firstname + ' ' + surname}</h3>
                <span>{username}</span>
            </div>
            <button className="mainBtnInv"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                Remove(id);
            }}>
            {loading === false ? (
                "Remove"
            ) : (
                <span
                    style={{
                        maxHeight: "13px",
                        fontSize: "13px",
                        overflow: "hidden",
                        color: 'white'
                    }}
                >
                    <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                </span>
            )}
        </button>
        </div>
    )
}

export default FeaturedProfileItemFollowers
