import { AccessTime } from '@material-ui/icons';
import { createSlice, } from '@reduxjs/toolkit';
import { EventState } from './interface';

const initialState: EventState = {
    loading: true,
    event: {},
    id: null,
    loc: null,
    error: null,
    keyword: '',
    InviteList: [],
    Invited: [],
    inviteerror: null,
    inviteload: false,
    page: 1,
    pagination: true,
    participants: [],
    ploading: true,
    perror: null,
    ppage: 1,
    ppagination: true,
    prevdata: false,
    reviewsloading: false,
    reviewspagination: true,
    reviewspage: 1,
    reviewserror: null,
    reviews: [],

};
export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {

  


        eventRequest: (state) => {
            state.loading = true;
            state.error = null;
        },


        eventReset: () => {
			return {...initialState}
		},

        eventSuccess: (state, action: any) => {
            state.loading = false;
            state.event = action.payload;
            state.error = null;
            state.inviteerror = null;
            state.keyword = '';
            state.InviteList = [];
            state.Invited = [];
            state.inviteload = false;
            state.page = 1;
            state.pagination = true;
            state.prevdata = false;
        },
        eventFailed: (state, action: any) => {
            state.loading = false;
            state.error = action.payload
        },
        updaterating: (state, action: any) => {
            state.event.total_rating = action.payload.rating;
            let updated_event = state.event
            // let new_list = state.event.review_rating_list.concat([{
            //     id: 124,
            //     reviewer_id: 48,
            //     reviewer_firstname: 'Nirmal',
            //     reviewer_surname: 'M N',
            //     reviewer_username: 'Nirmalmn',
            //     reviewer_dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_C78E42DE-B2FB-4EBA-891C-61B0E9B8C52E-4913-000002F7360F64D4-thumbnail-100x100-70.jpg',
            //     rating: 2,
            //     review: 'testing monuse',
            //     created_at: '2022-03-22T04:26:39.204113Z',
            //     number_of_reviews: 5
            //   }])
            let new_list = state.event.review_rating_list.concat(action.payload.data)
            console.log("NEW ACTION::", action)

            console.log("NEW LIST::", new_list)
            let new_permissions: any = updated_event.permissions;
            new_permissions['write_review'] = false;
            new_permissions['edit_review'] = true;
            updated_event['permissions'] = new_permissions
            updated_event['review_rating_list'] = new_list
            state.event = updated_event

        },
        eventinviteload: (state, action) => {
            state.inviteload = true;
            state.keyword = action.payload;
            state.InviteList = [];
            state.page = 1;
        },
        eventpaginationinviteload: (state) => {
            state.inviteload = true;
        },
        eventinvitesuccess: (state, action: any) => {
            state.InviteList = action.payload.page === 2 ? action.payload.data : state.InviteList.concat(action.payload.data);
            state.page = action.payload.page;
            state.pagination = action.payload.pagination;
            state.inviteload = false;
        },
        eventinvitefailed: (state, action) => {
            state.inviteerror = action.payload;
            state.inviteload = false;
        },
        addeventinvite: (state, action) => {
            // state.Invited = action.payload
            if (state.Invited.includes(action.payload)) {
                state.Invited = [...state.Invited.filter((id: any) => id !== action.payload)]
            } else {
                state.Invited = [...state.Invited, action.payload]
            }
        },
        addeventinviteclear: (state) => {
            state.InviteList = [];
            state.Invited = [];
            state.inviteload = false;
            state.inviteerror = null;
        },
        eventparticipantsload: (state) => {
            state.ploading = true;
        },
        eventparticipantsClear: (state) => {
            state.participants = [];
            state.ppage = 1;
            state.ppagination = true;
            state.ploading = false;
            state.perror = null;

        },
        eventparticipantssuccess: (state, action: any) => {
            state.participants = action.payload.no !== 1 ? state.participants.concat(action.payload.data) : action.payload.data;
            state.ppage = action.payload.no;
            state.ppagination = action.payload.pagination;
            state.ploading = false;
            state.perror = null;

        },
        eventparticipantsfailed: (state, action) => {
            state.perror = action.payload;
            state.ploading = false;
        },
        updateevent: (state, action) => {
            state.event = action.payload;
            // state.event = {
            //     "id": action.payload.id,
            //     "profile": action.payload.profile,
            //     "invited": action.payload.invited,
            //     "attending": action.payload.attending,
            //     "organiser": action.payload.organiser,
            //     "maybe": action.payload.maybe,
            //     "cantgo": action.payload.cantgo,
            //     "category": action.payload.,
            //     "main_category": action.payload.,
            //     "review_rating_list": action.payload.review_rating_list,
            //     "total_rating": action.payload.,
            //     "views": action.payload.,
            //     "tags": action.payload.,
            //     "assigned_role": action.payload.,
            //     "event_image": action.payload.,
            //     "posts": action.payload.,
            //     "views_count": action.payload.,
            //     "post_count": action.payload.,
            //     "attending_count": action.payload.,
            //     "tags_count": action.payload.,
            //     "invited_count": action.payload.,
            //     "maybe_count": action.payload.,
            //     "cantgo_count": action.payload.,
            //     "organiser_count": action.payload.,
            //     "permissions": action.payload.,
            //     "name": action.payload.,
            //     "description": action.payload.,
            //     "start_date": action.payload.,
            //     "end_date": action.payload.,
            //     "latitude": action.payload.,
            //     "longitude": action.payload.,
            //     "review_rating": action.payload.,
            //     "active_status": action.payload.,
            //     "public_status": action.payload.,
            //     "created_at": action.payload.created_at,
            //     "updated_at": action.payload.updated_at,
            //     "slug": action.payload.slug,
            //     "cancel_status": action.payload.cancel_status,
            //     "canceled_on": action.payload.canceled_on,
            //     "cancel_message": action.payload.cancel_message,
            //     "location": action.payload.location,
            //     "state": action.payload.state,
            //     "country": action.payload.country,
            //     "is_reviewed": action.payload.is_reviewed,
            //     "is_viewed": action.payload.is_viewed,
            //     "review_info": action.payload.review_info,
            //     "creator": action.payload.creator,
            //     "similar_events": action.payload.similar_events,
            //   }
        },
        putpreviewdata: (state, action) => {
            state.event = {};
            state.prevdata = true;
            state.event = action.payload;
            // if(state.event.id === action.payload.id){

            // }else{
            //     state.event = {};
            //     state.prevdata = true;
            //     state.event = action.payload;
            // }

        },
        subscriptionchange: (state, action) => {
            let old: any = state.event
            let oldpermissions: any = old.permissions
            old['subscribers_count'] = action.payload.status === true ? old['subscribers_count'] + 1 : (old['subscribers_count'] > 0 ? old['subscribers_count'] - 1 : 0)
            oldpermissions['is_subscribed'] = action.payload.status
            oldpermissions['can_subscribe'] = !(action.payload.status)
            old.permissions = oldpermissions
            state.event = old
        },
        reviewsrequest: (state) => {
            state.reviewsloading = true;
        },
        reviewssuccess: (state, action) => {
            state.reviewsloading = false;
            state.reviews = action.payload.no !== 1 ? state.reviews.concat(action.payload.data) : action.payload.data;
            state.reviewspage = action.payload.no;
            state.reviewspagination = action.payload.pagination;
            state.reviewserror = null;
        },
        reviewsfailed: (state, action) => {
            state.reviewsloading = false;
            state.reviewserror = null;
        },
        likeEvent: (state, action) => {
            console.log(action.payload)
            // if (state.event.like_details != undefined) {

            //     if (action.payload.eventIsLike === false) {
            //         state.event.like_details.like_count = Number(action.payload.eventLikecount) + 1
            //     } else {
            //         state.event.like_details.like_count = Number(action.payload.eventLikecount) - 1

            //     }
            // }
            // state['event']['like_details'] =  {}
            state['event']['like_details'] = {
                is_liked: !action.payload.eventIsLike,
                like_count: action.payload.eventIsLike ? Number(action.payload.eventLikeCount) - 1 : Number(action.payload.eventLikeCount) + 1,
            }
        },
         generateQrSucess: (state, action) => {
            console.log(action.payload)
            // if (state.event.like_details != undefined) {

            //     if (action.payload.eventIsLike === false) {
            //         state.event.like_details.like_count = Number(action.payload.eventLikecount) + 1
            //     } else {
            //         state.event.like_details.like_count = Number(action.payload.eventLikecount) - 1

            //     }
            // }
            // state['event']['like_details'] =  {}
            state['event']['qr_code_url'] = action.payload?.qr_code_url
        }
        

    }
})
export const { eventRequest, eventSuccess, eventFailed, updaterating,
    eventinviteload, eventinvitesuccess, addeventinvite, eventinvitefailed, addeventinviteclear,
    eventparticipantsfailed, eventparticipantsload, eventparticipantssuccess, updateevent,
    eventpaginationinviteload, putpreviewdata, subscriptionchange, likeEvent,
    reviewsfailed, reviewsrequest, reviewssuccess , eventReset,eventparticipantsClear,
generateQrSucess
} = eventSlice.actions
export default eventSlice.reducer