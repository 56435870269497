import React, { useState } from 'react'
import ModalLayout from '../../../utils/ModalLayout'
import { CircularProgress } from '@material-ui/core';
import Api from '../../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { width } from '@mui/system';
import { accountCreated } from '../../../../components/login/store/loginSlice'
import { stripeAccountCreateFn } from '../store/addEventSlice'
import { baseurl } from '../../../../Data/Data'

// import { Ipayout } from '../../store/ticketportal.interface';
// import { updateSinglePayout, addSinglePayout } from '../../store/tikcetPortalSlice'
// interface Iprop {
//     open: boolean;
//     handleClose: () => void
//     data?: Ipayout
// }
const StripeModal = ({ open, handleClosed }: any) => {
    const history = useHistory();
    const dispatch = useAppDispatch()
    const { profile } = useAppSelector(state => state.profile)
    console.log('profile', profile)
    var list = [
        {
            "country": "Select Your Country",
            "abbrevation": "",
            "hint": "",
            "iso_code": "",
        },
        {
            "country": "Australia",
            "abbrevation": "AU",
            "hint": "BSB",
            "routing_num_count": 6,
            "account_num_count": 9,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "AUD",
        },
        {
            "country": "Austria",
            "abbrevation": "AT",
            "hint": "IBAN",
            "routing_num_count": 11,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Belgium",
            "abbrevation": "BE",
            "hint": "IBAN",
            "routing_num_count": 16,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "BEF",
        },
        {
            "country": "Brazil",
            "abbrevation": "BR",
            "hint": "Bank code-Branch code",
            "routing_num_count": 10,
            "account_num_count": null,
            "have_hiphen": true,
            "routing_1_count": 3,
            "routing_2_count": 5,
            "iso_code": "BRL",
        },
        {
            "country": "Bulgaria",
            "abbrevation": "BG",
            "hint": "IBAN",
            "routing_num_count": 22,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "BGL",
        },
        {
            "country": "Canada",
            "abbrevation": "BG",
            "hint": "Transit number-Institution number",
            "routing_num_count": 9,
            "account_num_count": null,
            "have_hiphen": true,
            "routing_1_count": 5,
            "routing_2_count": 3,
            "iso_code": "CAD",
        },
        {
            "country": "Croatia",
            "abbrevation": "HR",
            "hint": "IBAN",
            "routing_num_count": 21,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "HRK",
        },
        {
            "country": "Cyprus",
            "abbrevation": "CY",
            "hint": "IBAN",
            "routing_num_count": 28,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "CYP",
        },
        {
            "country": "Czech Republic",
            "abbrevation": "CZ",
            "hint": "IBAN",
            "routing_num_count": 24,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "CYP",
        },
        {
            "country": "Denmark",
            "abbrevation": "DK",
            "hint": "IBAN",
            "routing_num_count": 24,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "DKK",
        },
        {
            "country": "Estonia",
            "abbrevation": "EE",
            "hint": "IBAN",
            "routing_num_count": 20,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "DKK",
        },
        {
            "country": "Finland",
            "abbrevation": "FI",
            "hint": "IBAN",
            "routing_num_count": 24,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "FIM",
        },
        {
            "country": "France",
            "abbrevation": "FR",
            "hint": "IBAN",
            "routing_num_count": 27,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "FRF",
        },
        {
            "country": "Germany",
            "abbrevation": "DE",
            "hint": "IBAN",
            "routing_num_count": 22,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "DEM",
        },
        {
            "country": "Gibraltar",
            "abbrevation": "GI",
            "hint": "IBAN",
            "routing_num_count": 23,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "GIP",
        },
        {
            "country": "Greece",
            "abbrevation": "GR",
            "hint": "IBAN",
            "routing_num_count": 27,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "GRD",
        },
        {
            "country": "Hong Kong",
            "abbrevation": "HK",
            "hint": "Clearing Code-Branch code",
            "routing_num_count": 7,
            "account_num_count": 9,
            "have_hiphen": true,
            "routing_1_count": 3,
            "routing_2_count": 3,
            "iso_code": "HKD",
        },
        {
            "country": "Hungary",
            "abbrevation": "HU",
            "hint": "IBAN",
            "routing_num_count": 28,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "HUF",
        },
        {
            "country": "India",
            "abbrevation": "IN",
            "hint": "IFSC Code",
            "routing_num_count": 11,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "INR",
        },
        {
            "country": "Ireland",
            "abbrevation": "IE",
            "hint": "IBAN",
            "routing_num_count": 22,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "IEP",
        },
        {
            "country": "Italy",
            "abbrevation": "IT",
            "hint": "IBAN",
            "routing_num_count": 27,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Japan",
            "abbrevation": "JP",
            "hint": "Bank code-Branch code",
            "routing_num_count": 7,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "JPY",
        },
        {
            "country": "Latvia",
            "abbrevation": "LV",
            "hint": "IBAN",
            "routing_num_count": 21,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Liechtenstein",
            "abbrevation": "LI",
            "hint": "IBAN",
            "routing_num_count": 21,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "CHF",
        },
        {
            "country": "Lithuania",
            "abbrevation": "LT",
            "hint": "IBAN",
            "routing_num_count": 20,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Lexumbourg",
            "abbrevation": "LU",
            "hint": "IBAN",
            "routing_num_count": 20,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Malaysia",
            "abbrevation": "MY",
            "hint": "BIC",
            "routing_num_count": 11,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "MYR",
        },
        {
            "country": "Malta",
            "abbrevation": "MT",
            "hint": "IBAN",
            "routing_num_count": 31,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Mexico",
            "abbrevation": "MX",
            "hint": "CLABE",
            "routing_num_count": 18,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Netherlands",
            "abbrevation": "NL",
            "hint": "IBAN",
            "routing_num_count": 18,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "New Zealand",
            "abbrevation": "NZ",
            "hint": "IBAN",
            "routing_num_count": 18,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "NZD",
        },
        {
            "country": "Norway",
            "abbrevation": "NO",
            "hint": "IBAN",
            "routing_num_count": 15,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "NOK",
        },
        {
            "country": "Portugal",
            "abbrevation": "PT",
            "hint": "IBAN",
            "routing_num_count": 25,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Romania",
            "abbrevation": "RO",
            "hint": "IBAN",
            "routing_num_count": 24,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "RON",
        },
        {
            "country": "Singapore",
            "abbrevation": "SG",
            "hint": "Bank code-Branch code",
            "routing_num_count": 8,
            "account_num_count": null,
            "have_hiphen": true,
            "routing_1_count": 4,
            "routing_2_count": 3,
            "iso_code": "SGD",
        },
        {
            "country": "Slovakia",
            "abbrevation": "SK",
            "hint": "IBAN",
            "routing_num_count": 24,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Slovenia",
            "abbrevation": "SI",
            "hint": "IBAN",
            "routing_num_count": 19,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Spain",
            "abbrevation": "ES",
            "hint": "IBAN",
            "routing_num_count": 24,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "EUR",
        },
        {
            "country": "Sweden",
            "abbrevation": "SE",
            "hint": "IBAN",
            "routing_num_count": 24,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "SEK",
        },
        {
            "country": "Switzerland",
            "abbrevation": "CH",
            "hint": "IBAN",
            "routing_num_count": 21,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "CHF",
        },
        {
            "country": "Thailand",
            "abbrevation": "TH",
            "hint": "Bank code-Branch code",
            "routing_num_count": 8,
            "account_num_count": null,
            "have_hiphen": true,
            "routing_1_count": 3,
            "routing_2_count": 4,
            "iso_code": "THB",
        },
        {
            "country": "United Arab Emirates",
            "abbrevation": "AE",
            "hint": "IBAN",
            "routing_num_count": 23,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "AED",
        },
        {
            "country": "United Kingdom",
            "abbrevation": "GB",
            "hint": "Sort Code",
            "routing_num_count": 6,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "GBP",
        },
        {
            "country": "United States",
            "abbrevation": "US",
            "hint": "Routing Number",
            "routing_num_count": 9,
            "account_num_count": null,
            "have_hiphen": false,
            "routing_1_count": 0,
            "routing_2_count": 0,
            "iso_code": "USD",
        },
    ];
    const payoutCruFx = () => {

        setloader(true)
        const success = (data: any) => {
            if (data?.url) {
                dispatch(accountCreated(true))
                localStorage.setItem('account_create', "true");

            }
            console.log(data)
            dispatch(stripeAccountCreateFn(data))
            localStorage.setItem('account_res', JSON.stringify(data));


            // if (data?.id) {
            //     dispatch(updateSinglePayout(datas['data']))

            // } else {
            //     dispatch(addSinglePayout(datas))
            // }
            const link = document.createElement('a');
            link.href = data?.url;
            link.click();

            setloader(false)

        }
        const failed = (data: any) => {
            setloader(false)

        }

        let returnUrl = window.location.href
        console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', returnUrl)

        let postdataWithUrl = {
            "country": country,
            "account_holder_name": account_holder_name,
            "account_number": account_number,
            "email": email,
            "bank_routing_number": bank_routing_number,
            "bank_account_currency": currency,
            "support_phone": support_phone,
            "url": returnUrl
        }
        // let postdataWithoutUrl = {
        //     "country": country,
        //     "account_holder_name": account_holder_name,
        //     "account_number": account_number,
        //     "email": email,
        //     "bank_routing_number": bank_routing_number,
        //     "bank_account_currency": currency,
        //     "support_phone": support_phone
        // }
        // let postdata
        // if (url) {
        //     postdata = postdataWithUrl
        // } else {
        //     postdata = postdataWithoutUrl
        // }
        // // dispatch(payoutRequest())
        // if (data?.id) {

        console.log('-------------------post data---------------', postdataWithUrl)

        Api('post', '/api/v1/ticketing-portal/connected-account/stripe/', postdataWithUrl, success, failed, history, true, true)

        // } else {

        //     Api('post', '/api/v1/event/bank-details/crud/', postdata, success, failed, history, true, true)
        // }
        // handleClose()

    }

    const [country, setCountry] = useState<any>("")
    const [currency, setCurrency] = useState<any>("")
    const [account_holder_name, setaccount_holder_name] = useState("")
    const [account_number, setaccount_number] = useState("")
    const [bank_routing_number, setbank_routing_number] = useState("")
    const [bank_account_currency, setbank_account_currency] = useState("")
    const [support_phone, setsupport_phone] = useState("")
    const [email, setEmail] = useState<any>(profile ? profile.email : "")
    // const [product_description, setproduct_description] = useState("")
    const [url, seturl] = useState("")
    const [loader, setloader] = useState(false)
    const [routingNumber, setRoutingNumber] = useState<any>('')
    const [nameError, setNameError] = useState<any>(false)
    const [accountNumberError, setAccountNumberError] = useState<any>(false)
    const [emailError, setEmailError] = useState<any>(false)
    const [currencyError, setCurrencyError] = useState<any>(false)
    const [phoneError, setPhoneError] = useState<any>(false)
    const [routingError, setRoutingError] = useState<any>(false)
    const [valueError, setValueError] = useState<any>(false)
    const getInitialState = () => {
        const value = "Orange";
        return value;
    };

    const [value, setValue] = useState(getInitialState);
    let obj
    const handleChange = (e: any) => {
        setValue(e.target.value);
        console.log('-------------------', e.target.value)
        if (e.target.value !== 'Select Your Country') {
            setValueError(false);
        }
        obj = list.find(o => o.country === e.target.value);
        setRoutingNumber(obj?.hint)
        console.log(obj?.iso_code)
        setCurrency(obj?.iso_code)
        setCountry(obj?.abbrevation)



    };

    return (
        <ModalLayout open={open} handleClose={handleClosed} style={{ height: '100vh', overflow: 'hidden' }}>
            <div className="payout__add__modal">
                <div>
                    <h2>Create Account</h2>
                </div>

                <div className="payout__add__modal__inputs">
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}><label htmlFor=""> Country</label><small style={{ color: "red" }}>{valueError ? "required*" : null}</small></div>
                        {/* <input type="text" value={country} onChange={(e) => setcountry(e.target.value)} /> */}
                        <div style={{ height: "30px", width: "100%" }}>
                            <select style={{ height: "30px", width: "100%" }} value={value} onChange={handleChange}>
                                {
                                    list.map((item: any, key: any) => {
                                        return (
                                            <option key={key} value={`${item?.country}`}>{item?.country}</option>

                                        )
                                    })
                                }

                            </select>
                            {/* <p>{`You selected ${value}`}</p> */}
                        </div>

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}><label htmlFor=""> Account holder name</label><small style={{ color: "red" }}>{nameError ? "required*" : null}</small></div>
                        <input type="text" value={account_holder_name} onChange={(e) => {
                            setaccount_holder_name(e.target.value)
                            if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
                                setNameError(false);
                            }
                        }

                        } />

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>  <label htmlFor="">Account number</label><small style={{ color: "red" }}>{accountNumberError ? "required*" : null}</small></div>
                        <input type="text" value={account_number} onChange={(e) => {
                            setaccount_number(e.target.value)
                            if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
                                setAccountNumberError(false);
                            }
                        }
                        } />

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}><label htmlFor="">Email</label><small style={{ color: "red" }}>{emailError ? "required*" : null}</small></div>
                        <input type="text" value={email} onChange={(e) => {
                            setEmail(e.target.value)
                            if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
                                setEmailError(false);
                            }
                        }} />

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}><label htmlFor="">{routingNumber ? routingNumber : "Bank routing number"}</label><small style={{ color: "red" }}>{routingError ? "required*" : null}</small></div>
                        <input type="text" value={bank_routing_number} onChange={(e) => {
                            setbank_routing_number(e.target.value)
                            if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
                                setRoutingError(false);
                            }

                        }} />

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}><label htmlFor=""> currency</label></div>
                        <input type="text" value={currency} readOnly={true} onChange={(e) => {
                            setbank_account_currency(currency)
                            if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
                                setCurrencyError(false);
                            }
                        }
                        } />

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}><label htmlFor=""> phone</label><small style={{ color: "red" }}>{phoneError ? "required*" : null}</small></div>
                        <input type="text" value={support_phone} onChange={(e) => {
                            setsupport_phone(e.target.value)
                            if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
                                setPhoneError(false);
                            }
                        }
                        } />

                    </div>
                    {/* <div>
                        <label htmlFor=""> product  description</label>
                        <input type="text" value={product_description} onChange={(e) => setproduct_description(e.target.value)} />

                    </div> */}
                    {/* <div>
                        <label htmlFor=""> website</label>
                        <input type="text" value={url} onChange={(e) => seturl(e.target.value)} />

                    </div> */}


                </div>
                <div>
                    <button className="mainBtn" onClick={() => {
                        if (account_holder_name !== '' && account_number !== '' && value !== 'Select Your Country' && email !== '' && bank_routing_number !== '' && currency !== '' && support_phone !== '') {
                            payoutCruFx()
                        }
                        if (account_holder_name === '') {
                            setNameError(true)
                        }
                        if (value === 'Select Your Country') {
                            setValueError(true)
                        }
                        if (account_number === '') {
                            setAccountNumberError(true)
                        }
                        if (email === '') {
                            setEmailError(true)
                        }
                        if (bank_routing_number === '') {
                            setRoutingError(true)
                        }
                        if (currency === '') {
                            setCurrencyError(true)
                        }
                        if (support_phone === '') {
                            setPhoneError(true)
                        }
                    }}  >
                        {loader ? <CircularProgress style={{ color: 'var(--cl_orange)' }} /> : (
                            "Create")}
                    </button>

                </div>
            </div>

        </ModalLayout>
    )
}

export default StripeModal