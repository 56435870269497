import { useState } from 'react';
import ModalLayout from '../../../utils/ModalLayout';
import { CircularProgress } from '@material-ui/core';

// import InviteItem from './InviteItem';

const GiftRegistryModal = ({ open, handleClose, shopDetails, setShopDetails }: any) => {
	const [shoptitle, setshopTitle] = useState(shopDetails?.shop_title || '');
	const [shoplink, setshoplink] = useState(shopDetails?.shop_link || '');


	const [loading, setloading] = useState(false);


	const onSubmit = () => {
        setloading(true)
        setShopDetails({
                "shop_title":shoptitle,
                "shop_link":shoplink
        })

        setTimeout(() => {
        handleClose(shoptitle);
        setshopTitle('')
        setshoplink('')
        setloading(false)
        }, 1000);
    };

	return (
		<>
			<ModalLayout open={open} handleClose={()=>handleClose(null)}>
				<div className="tw-p-4">
					<div className='tw-py-2 tw-text-lg tw-font-semibold'>Gift Registry</div>


                        <div className='tw-flex tw-flex-col tw-full tw-gap-2'>
                            <div>
                            <div className='AddEventSectTitle'>
                                Shop Title
                            </div>

                            <input type='text' className='AddEventSectInput tw-w-96' value={shoptitle} onChange={(e) => setshopTitle(e.target.value)} />

                            </div>
                            <div>
                            <div className='AddEventSectTitle '>
                                Shop Link
                            </div>
                            <input type='text' className='AddEventSectInput tw-w-96' value={shoplink} onChange={(e) => setshoplink(e.target.value)} />

                            </div>


                        </div>

<div className="tw-py-4">
<button className='btn' onClick={onSubmit}>

    {
        loading ? <CircularProgress size={20} style={{ color:"white"}} /> : "Add"
    }
    
							
						</button>
</div>
					
					
				</div>
			</ModalLayout>
		</>
	);
};

export default GiftRegistryModal;
