import { ChangeEvent, useState } from 'react';

import ModalLayout from '../../components/utils/ModalLayout';
import Api from '../../app/api';
import { useHistory } from 'react-router-dom';

const RsvpWl = ({ open, handleClose, custom_form, id }: any) => {
	const [submitData, setsubmitData] = useState<any>({});
	const history = useHistory();

	const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
		let data = { ...submitData };
		data[event.target.name] = event.target.value;

		console.log('data', data);
		setsubmitData(data);
	};

	const submit = (e: { preventDefault: () => void }) => {
		e.preventDefault();

		let postData = {
			'event': id,
			'email': submitData?.Email,
			'custom_form': submitData,
		};
		console.log('submitData', submitData);
		const success = (data: any) => {
			console.log(data);
		};
		const failed = (data: any) => {
			console.log(data);
		};
		// if((Object.keys(event).length === 0)){

		let theurl = `/api/v1/event/attend/no-auth/`;
		// {{baseurl}}/api/v1/event/attend/no-auth/
		Api('post', theurl, postData, success, failed, history, false, true);
	};

	return (
		<ModalLayout open={open} handleClose={handleClose}>
			<div className='tw-p-8 tw-flex tw-flex-col tw-justify-center tw-items-center'>
				<form>
					<div className='tw-flex tw-justify-between  '>
						<h2 className='AddEventSectTitle !tw-text-2xl !tw-font-semibold'>Guest Registration Form </h2>
					</div>

					<div className='tw-flex tw-flex-col tw-gap-6 tw-py-2'>
						{custom_form &&
							custom_form.map(
								(
									input: { name: string | undefined; type: string | undefined; placeholder: string | undefined },
									index: number
								) => {
									return (
										<div key={index} className='tw-flex  tw-items-center tw-w-96'>
											<input
												className='AddEventSectInput tw-w-full '
												name={input.name}
												type={input.type}
												placeholder={input.placeholder}
												onChange={(event) => handleFormChange(event)}
											/>
										</div>
									);
								}
							)}
					</div>
					<div className='tw-w-full tw-flex tw-items-center tw-justify-center'>
						<button className='AddEventNextBtn mt-2' onClick={submit}>
							Submit
						</button>
					</div>
				</form>
			</div>
		</ModalLayout>
	);
};

export default RsvpWl;
