import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Clock } from '../../assets/svg/clock.svg'
// import clock from '../assets/svg/clock.svg'
// import Icon from '../assets/images/withoutLoginScroll.png'

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import '../../components/smallcomponents/exploreby/exploreby.css'
import './category.css'
// import Api from '../../../app/api'
// import { categoriesRequest, categoriesSuccess, categoriesFailed } from './store/exploreSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import Api from '../../app/api'
import { categorySuccessWl, featuredEventRequestWl, featuredEventRequestWl1, featuredEventSuccessWl } from './store/feturedEventSliceWl'
// import { ReactComponent as Arrow } from '../assets/svg/rightarrow.svg';

function CategoryComponent({ items, key, categoryFilteringFn, categoryArray }: any) {
    const loading = false
    const error = null
    const history = useHistory();
    const dispatch = useAppDispatch()
    const { categories, } = useAppSelector(state => state.categories)
    const { categoriesWl } = useAppSelector(state => state.featuredeventwl)
    // const success = (data: any) => {
    //     console.log('-------------------------------------', data)
    //     dispatch(categorySuccessWl({ data: data }))
    // }
    // const failed = () => {
    // }

    useEffect(() => {
    }, [])


    const [categoryId, setCategoryId] = useState(0)



    // let ScrollButton = ({ scrollOffset }: any) => {
    //     console.log(scrollOffset)
    //     scrollRef.current.scrollLeft += scrollOffset;
    // }

    return (
        <div style={{ display: "flex", flexDirection: "column", minWidth: "100px", borderColor: "white", paddingTop: "4px", }}
            className={categoryArray.includes(items.id) ? "exploreBoxDiv2" : "exploreBoxDiv1"}
            key={key}
            onClick={() => {
                // setCategoryId(key + 1)
                categoryFilteringFn(items?.id)
            }}

        >
            <div className="exploreBoxIcon">
                <img alt='Category icon' src={items?.category_icon} onError={(e) => {
                    const target = e.currentTarget
                    target.onerror = null;
                    // target.src = clock
                }} />

                {/* <Clock /> */}
            </div>
            <div className="exploreBoxTitle" style={{ fontSize: "14px" }}>{items?.name}</div>
        </div>
    )

}

export default CategoryComponent
