import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Toaster } from 'react-hot-toast';

import { PersistGate } from 'redux-persist/integration/react';
// import {
//   GoogleMap, useJsApiLoader,
//   LoadScript, Marker, InfoWindow, Autocomplete,
// } from '@react-google-maps/api';
// const libraries: any = ["places"]


if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log('new notification arrived')
      console.log("Registration successful, scope is:", registration.scope);
      // alert('registered')
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
      // alert('failed')
    });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

      <BrowserRouter>
        {/* <LoadScript
            googleMapsApiKey='AIzaSyCVHWug3PRbkfovRw5CqYhcQAUFSwTj10g'
            libraries={libraries}
        > */}
        <App />
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          containerStyle={{
            zIndex: 9999 // For the container
           }}
           toastOptions={{
             style: {
               zIndex: 9999 // For toasts
             },
           }}
        />
        {/* </LoadScript> */}
      </BrowserRouter>
    </PersistGate>

    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.unregister();
