import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import ChatSearch from '../common/ChatSearch';
import SettingsDropdown from '../../../topbar/settings/SettingsDropdown';
import NotificationDropdown from '../../../topbar/notification/NotificationDropdown';
import { useHistory } from 'react-router-dom';

const ChatLayout = ({ className, children }: { className: string, children: any }) => {



    const history = useHistory()
    const { profile, loading: profloading, error: proferror } = useAppSelector(state => state.profile)
    const { socket, openedchatkey, recentchat, searchdata, openedchatdetails, searcherror, searchloading, searchpage, searchpagination, searchkeyword } = useAppSelector(state => state.chat)



    return (
        <div className='mainchatlayout'>
            <div className='chat_topbar'>
                <div className='chat_topbarleft'>
                    <img className='cursor' src='https://d20rv3nuwgisca.cloudfront.net/general/logo/mutualevents-minimal-logo.png' onClick={() => {
                        history.push('/')
                    }} />
                    <hr />
                    <h4>Conversations</h4>
                </div>
                <ChatSearch chatwindow={'main'} />
                <div className='chat_righttopbar'>
                    <div className='dropdowns'>
                        <div className='settingsicon'>
                            <SettingsDropdown />
                        </div>
                        <div className='bellcontainer'>
                            <NotificationDropdown />
                        </div>
                    </div>
                    <div className='chat_main_user cursor' onClick={() => {
                        history.push('/profile')
                    }}>
                        {
                            !profloading && proferror === null ? (
                                <div className='chat_main_userDetails' >
                                    <h4>{profile.firstname + ' ' + profile.surname}</h4>
                                    <span>@{profile.username}</span>
                                </div>
                            ) : (
                                <div className='chat_main_userDetails' style={{display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                                    <h4 className='skeleton minhe10' style={{maxWidth:'70px'}}></h4>
                                    <span className='skeleton minhe10' style={{maxWidth:'50px'}}></span>
                                </div>
                            )

                        }
                        {
                            !profloading && proferror === null ? (
                                <div className='chat_main_user_img'>
                                    <img src={profile.dp_thumbnail_1} />
                                </div>
                            ) : (
                                <div className='chat_main_user_img skeleton'>
                                    
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <div className='chatwrapper'>{children}</div>
        </div>
    );
};

export default ChatLayout;
