import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { bothStageUpdate, remain3, addtrigger, currentStageUpdate, addtickets } from '../store/addEventSlice';
import { ReactComponent as Ticketicon } from '../../../../assets/svg/ticket.svg'
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg';
import './addticket.css'


import AddnewticketModal from './AddnewticketModal';
import CommonSlide from './../../../utils/commonSlide';
import Api from '../../../../app/api';
import { useHistory } from 'react-router-dom';

const style: any = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '50vh',
    minWidth: 400,
    width: '50vw',
    maxWidth: '688px',
    bgcolor: 'background.paper',
    borderRadius: 8,
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};

const AddticketsNew = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { currentstage, reachedstage, trigger, ticketcategories, ticketquantity } = useAppSelector(state => state.addEvent)

    const [trigger1, settrigger1] = useState(false)
    const [getCharge, setgetCharge] = useState('')
    useEffect(() => {
        if (currentstage < reachedstage) {
            dispatch(addtrigger('ticket'))
        }

    }, [trigger1])


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
        setindex(null)
    };

    let initialValue = 0
    const [tickettypes, settickettypes] = useState<any>(ticketcategories)
    const [index, setindex] = useState<any>(null)

    // const[open1,setOpen1]= useState(false)
    // const handleClose1 = () => setOpen1(false);

    // const [visible, setvisible] = useState(false)
    // const [selectedstate, setselectedstate] = useState('initial')
    // const [selectedvalue, setvalue] = useState(1)
    // const [categoryid, setcategoryid] = useState<any>(1)

    // const [quantity,setquantity]= useState(1)

    const getApplicationCharges = () => {

        const success = (res: any) => {

            console.log('resss', res)
            setgetCharge(res['application_charges'])
        }
        const failed = () => {

        }
        Api('get', '/api/v1/event/application-charges/', null, success, failed, history, true, true)
    }
    return (
        <CommonSlide>

            <div className='addtickets'>
                {open && <AddnewticketModal ticket={index !== null ? tickettypes[index] : null} index={index} open={open} setOpen={setOpen}
                    handleClose={handleClose} tickettypes={tickettypes} settickettypes={settickettypes}
                    trigger={trigger1} settrigger={settrigger1} getChargeData={getCharge} />}
                {/* {open1&&<ViewAddedticket ticket={tickettypes[index]} open={open1} setOpen={setOpen1} handleClose={handleClose1}/>} */}
                <div className="AddEventContentTitle">Add ticket</div>
                <div className='addeventmaindiv'>


                    <div className="invitesect1" >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                            <div className="AddEventSectTitle" >Ticket category</div>
                            <small >Create all ticket categories, list their prices and available seats here</small>
                        </div>

                        <div className='ticket_category_container'>
                            {/* {
                            [...Array(3)].map(() => {
                                return (
                                    <div className='ticket_category_item cursor' >
                                        <div className='ticket_category_itemicon'>
                                            <Ticketicon />
                                        </div>

                                        <div className='ticket_category_itemdetails_container' style={{ display: 'grid', gridTemplateColumns: '1fr 20px', gap: '2rem' }}>
                                            <div className='ticket_category_itemdetails'>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <h4>Ticket name</h4>
                                                    <h4>$14</h4>
                                                </span>
                                                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <small>paid or not</small>
                                                    <small>Quantity: 1 ticket</small>
                                                </span>
                                            </div>
                                            <Arrow />
                                        </div>
                                    </div>
                                )
                            })
                        } */}
                            {
                                tickettypes.map((ticket: any, key: number) => {
                                    return (
                                        <div className='ticket_category_item cursor' key={key} onClick={() => {
                                            setindex(key)
                                            setOpen(true)
                                        }}>
                                            <div className='ticket_category_itemicon'>
                                                <Ticketicon />
                                            </div>

                                            <div className='ticket_category_itemdetails_container' style={{ display: 'grid', gridTemplateColumns: '1fr 20px', gap: '2rem' }}>
                                                <div className='ticket_category_itemdetails'>
                                                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <h4>{ticket.name}</h4>
                                                        {/* <h4>$14</h4> */}
                                                    </span>
                                                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <small>{ticket.pricing}</small>
                                                        <small>Quantity: {ticket.quantity} ticket</small>
                                                    </span>
                                                </div>
                                                <Arrow />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className='cursor' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', backgroundColor: 'var(--cl_light_orange)', color: 'var(--cl_orange)', height: '40px', borderRadius: '8px', width: '100%', maxWidth: '511px' }} onClick={() => {
                            setOpen(true)
                            getApplicationCharges()
                        }}>
                            Add ticket category
                        </div>
                    </div>
                    <div className="invitesect1" >
                        <div className="AddEventSectTitle" >Total Quantity</div>
                        <input type="number" className="AddEventSectInput" value={tickettypes.length !== 0 ? tickettypes.reduce((previousValue: any, currentValue: any) => previousValue + JSON.parse(currentValue.quantity), initialValue) : 0} readOnly />
                    </div>

                </div>
                <div className="AddEventFooter">
                    <div className="AddEventCancelBtn" onClick={e => {

                        dispatch(bothStageUpdate({ currentstage: 4, reachedstage: 4 }))
                        // dispatch(remain3())
                    }}>Cancel</div>
                    {/* {currentstage < reachedstage ? null :  */}
                    {
                        currentstage < reachedstage ? null : (
                            tickettypes.length > 0 && <div className="AddEventNextBtn" onClick={e => {
                                console.log('==================ticket types============', tickettypes)
                                dispatch(addtickets({
                                    tickets: tickettypes,
                                    total: tickettypes.length !== 0 ? tickettypes.reduce((previousValue: any, currentValue: any) => previousValue + JSON.parse(currentValue.quantity), initialValue) : 0
                                }))
                                dispatch(bothStageUpdate({ currentstage: 6, reachedstage: 6 }))
                            }}
                            >Next Step</div>
                        )

                    }
                    {
                        trigger === 'ticket' ? tickettypes.length > 0 && <div className="AddEventNextBtn" onClick={e => {
                            // dispatch()
                            // dispatch(bothStageUpdate({ currentstage: 6, reachedstage: 6 }))
                            dispatch(currentStageUpdate({ currentstage: reachedstage }))
                        }}
                        >Save changes</div> : null
                    }

                    {/* } */}
                </div>
            </div >
        </CommonSlide>
    )
}

export default AddticketsNew