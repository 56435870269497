import React from 'react'
import dayjs from 'dayjs';
import { Link, NavLink } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { eventNames } from 'process';
import { Description } from '@material-ui/icons';
import './style.css'
const Eventitem = ({ event, is_history }: any) => {
    const { event_image, name, profile, start_date, end_date, slug, id, is_claimable } = event;
    const { profile: pro } = useAppSelector(state => state.profile)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)

    let user_type = localStorage.getItem('mtusrtype')

    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    return (
        <Link to={`/event/${slug}`} className='eventitem links cursor !tw-w-96' style={{ padding :" 8px 8px" }}
            onClick={() => {
                // if (!is_history) {
                //     if (currentparsed['event_history']) {
                //         //mtusr
                //         let event_history: any = currentparsed['event_history'].filter((event: any, key: number) => event.id !== id)

                //         let new_history: any = [event].concat(event_history).slice(0, 3)
                //         currentparsed['event_history'] = new_history
                //         let new_usr = JSON.stringify(currentparsed)
                //         localStorage.setItem('mtusr', new_usr)
                //         if (user_type && user_type === 'personal') {
                //             //pdetails
                //             parseddetails[0] = currentparsed
                //             localStorage.setItem('pdetails', JSON.stringify(parseddetails))
                //         }



                //     } else {
                //         //mtusr
                //         currentparsed['event_history'] = [event]
                //         let new_usr = JSON.stringify(currentparsed)
                //         localStorage.setItem('mtusr', new_usr)
                //         if (user_type && user_type === 'personal') {
                //             //pdetails
                //             parseddetails[0] = currentparsed
                //             localStorage.setItem('pdetails', JSON.stringify(parseddetails))
                //         }
                //     }
                // }

            }}
        >
            <div style={{ padding: "5px", alignItems: "center", justifySelf: "center", marginRight: "10px", }} >
                <div className='eventitemcontent'>
                    <span style={{ fontWeight: "400", fontSize: "15px", }}>{name}</span>
                    {/* <div className='eventitemuser'>
                    <img alt="P_I_C" src={is_claimable ? event?.alternate_profile?.dp : profile.dp_thumbnail} />
                    <div className='eventitemuserdetails'>
                        <span>{is_claimable ? event?.alternate_profile?.firstname : (profile.firstname + " " + profile.surname)}</span>
                        <span className="dot"></span>
                        <span className="">{dayjs(start_date).format("DD MMMM YYYY")}</span>
                        <span className=""> to</span>
                        <span className="">{dayjs(end_date).format("DD MMMM YYYY")}</span>
                    </div>
                </div> */}
                    <span className='eventdescription_search_wl' style={{
                        fontWeight: "200", fontSize: "12px", color: "gray",

                    }}>{event?.description}</span>
                </div>
            </div>
            {/* <img alt='Event image' src={event_image[0].image_thumbnail} /> */}

        </Link>
    )
}

export default Eventitem
