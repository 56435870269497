import React, { useEffect, useState } from 'react'
import { errors } from '../../../../Data/Error'
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import AnalyticsmoderatedItem from './AnalyticsmoderatedItem';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useHistory, useParams } from 'react-router-dom';
import Api from '../../../../app/api';

import { individualanalyticsmoderatedrequest, individualanalyticsmoderatedsuccess, individualanalyticsmoderatedfailed } from '../store/ContentmoderatorSlice';

const IndividualModeratorModeratedList = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { profile } = useAppSelector(state => state.profile)
    const { individualanalyticsmoderated, individualanalyticsmoderatederror, individualanalyticsmoderatedloading, individualanalyticsmoderatedpage, individualanalyticsmoderatedpagination } = useAppSelector(state => state.moderator)
    const { moderatorid }: any = useParams()



    const moderatedsuccess = (data: any) => {
        console.log(data)
        dispatch(individualanalyticsmoderatedsuccess({
            data: data,
            no: individualanalyticsmoderatedpage + 1,
            pagination: data.length >= 20 ? true : false
        }))


    }
    const moderatedfailed = (data: any) => {
        console.log(data)
        dispatch(individualanalyticsmoderatedfailed(data))
    }

    useEffect(() => {
        dispatch(individualanalyticsmoderatedrequest(1))
        let url1 = `/api/v1/permissions/content-moderation/super-admin/list/${moderatorid}/1/20/`

        Api('get', url1, null, moderatedsuccess, moderatedfailed, history, true, true)
    }, [])

    const success = (data: any) => {

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${moderatorid}.csv`);
        document.body.appendChild(link);
        link.click()
    }
    const failed = (data: any) => {
        alert('download failed')
    }
    const Download = () => {
        Api('get', `/api/v1/permissions/content-moderation/super-admin/download/list/${moderatorid}/`, null, success, failed, history, true, true)
    }

    const ScrollPos = (val: any) => {
        const { top } = val
        console.log(top)

        if (top > 0.9) {
            console.log('scroll please')
            if (individualanalyticsmoderatedpagination && !individualanalyticsmoderatedloading) {
                dispatch(individualanalyticsmoderatedrequest(individualanalyticsmoderatedpage))

                Api('get', `/api/v1/permissions/content-moderation/super-admin/list/${moderatorid}/${individualanalyticsmoderatedpage}/20/`, null, moderatedsuccess, moderatedfailed, history, true, true)
                // fetchsinglechatmessages(openedchatdetails.chat_id, chatmessagespage, history, dispatch)
            }
        }

    }
    return (
        <div className='cm_path_one'>
            <div className='cm_indiavidual_wrapper'>
                <div className='cm_detail_table'>

                    <div className='cm_table_head'>
                        <li>Name</li>
                        <li>Date</li>
                        <li>Status</li>
                        <li>Type</li>
                        <li>Reported</li>
                        <li>Reported by</li>
                        <li>Actions</li>
                    </div>
                    <Scrollbars autoHide onUpdate={ScrollPos}>
                        {
                            Object.keys(individualanalyticsmoderated).length !== 0 ? (
                                individualanalyticsmoderated.map((moderated: any, key: number) => {

                                    return (
                                        <AnalyticsmoderatedItem moderated={moderated} k={key} />
                                    )
                                })
                            ) : (
                                !individualanalyticsmoderatedloading ?
                                    individualanalyticsmoderatederror === null ?
                                        <div className='noData'>
                                            <span>no moderated posts found</span>
                                        </div>
                                        : null
                                    : null
                            )
                        }
                        {/* {

                             (
                                <div className='noData'>
                                    <CircularProgress style={{ height: '13px', width: '13px', color: 'var(--cl_orange)' }} />

                                </div>
                            )
                        } */}
                        {
                            individualanalyticsmoderatedloading && [...Array(10)].map((_, key) => {
                                return (
                                    <div className={'cm_table_container'}>
                                        <div className='cm_table_item'>
                                            <li >
                                                <div className='skeleton' style={{ height: '40px', width: '40px', borderRadius: '8px' }}></div>
                                                <small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px' }}></small>
                                            </li>
                                            <li><small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px', maxHeight: '10px' }}></small></li>
                                            <li>
                                                <button className='cm_content_statbtn skeleton'>

                                                </button>
                                            </li>
                                            <li >
                                                <small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px', maxHeight: '10px' }}></small>
                                            </li>
                                            <li>
                                                <button className='cm_content_statbtn skeleton' style={{ minWidth: 'auto' }}>

                                                </button>
                                            </li>
                                            <li className='cm_table_imgoverlapped'>
                                                {
                                                    [...Array(3)].map((_, key: number) => {
                                                        return (
                                                            <div className={`cm_table_usrimg cm_table_usrimg${key} skeleton`} style={{ height: '1rem', width: '1rem', borderRadius: '50%' }} />
                                                        )
                                                    })
                                                }



                                            </li>
                                            <li>
                                                <div className="FeedItemDropdown" >
                                                    <span className='dots'>
                                                        <More />
                                                    </span>

                                                </div>
                                            </li>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            individualanalyticsmoderatederror !== null ? (
                                individualanalyticsmoderatederror.status === null ? (
                                    <div className=' errorAlt' >
                                        <div className='errormsg'>
                                            <Nointernet />
                                            <h2>{errors.internet.heading}</h2>
                                            <span>{errors.internet.message}</span>
                                            {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                        </div>
                                    </div>
                                ) : individualanalyticsmoderatederror.status === 500 ? (
                                    <div className=' errorAlt'>
                                        <div className='errormsg'>
                                            <Server />
                                            <h2>{errors.server.heading}</h2>
                                            <span>{errors.server.message}</span>
                                            {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                        </div>
                                    </div>
                                ) : individualanalyticsmoderatederror.status === 401 ? (
                                    <div className=' errorAlt'>
                                        <div className='errormsg'>
                                            <NotFound />
                                            <h2>{errors.notFound.heading}</h2>
                                            <span>{errors.notFound.message}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className=' errorAlt'>
                                        <div className='errormsg'>
                                            <NotFound />
                                            <h2>{errors.common.heading}</h2>
                                            <span>{errors.common.message}</span>
                                            {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                        </div>
                                    </div>
                                )) : null
                        }
                    </Scrollbars>
                    <button className='mainBtn' onClick={() => {
                        Download()
                    }}>Download csv</button>
                </div>

            </div>
        </div>
    )
}

export default IndividualModeratorModeratedList