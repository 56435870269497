import React, { useEffect, useRef, useCallback, useState } from "react";
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import './featuredprofiles.css'
import FeaturedProfileItem from "../../smallcomponents/featuredprofileitem/FeaturedProfileItem";
import Api from "../../../app/api";
import { featuredSuccess, featuredRequest, featuredFailed } from "./store/featuredSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useHistory } from "react-router-dom";
import FeaturedProfileLoader from "../../smallcomponents/loaders/FeaturedProfileLoader";

const FeaturedProfiles = () => {

  const history = useHistory()

  const dispatch = useAppDispatch()
  const { loading, featured, error, page, pagination } = useAppSelector(state => state.featured)

  const [load, setload] = useState(false)
  const success = (res: any) => {
    console.log(res, 'res')

    dispatch(featuredSuccess({
      featured: res,
      no: page + 1,
      pagination: res.length < 20 ? false : true
    }))
  }
  const failed = (res: any) => {
    console.log(res, 'res')
    dispatch(featuredFailed(res))
  }
  const success1 = (res: any) => {
    console.log(res, 'res')

    dispatch(featuredSuccess({
      featured: res,
      no: 1,
      pagination: res.length < 20 ? false : true
    }))
  }

  useEffect(() => {
    if ((Object.keys(featured).length === 0) || loading) {
      dispatch(featuredRequest())
      Api('get', `/api/v1/user/featured/1/20/`, null, success1, failed, history, true, true)
    }
    // } else {
    //   setload(true)
    //   setTimeout(() => {
    //     setload(false)
    //   }, 2000);
    // }
  }, [])


  const observer: any = useRef()

  const lastBookElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {

      if (entries[0].isIntersecting && pagination) {
        if (!loading) {
          // let pageno = page + 1
          Api('get', `/api/v1/user/featured/${page}/20/`, null, success, failed, history, true, true)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [featured, loading])


  return (
    <div className="featuredProfiles container minvh100">
      <div className='commonHeading'>
        <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
        <h2>Featured profiles</h2>
      </div>
      {(loading === false && error === null) && <div className="featuredProfilesList">
        {/* && load === false */}
        {

          featured.map((item, key) => {
            if (featured.length === key + 1) {
              return (
                <div ref={lastBookElementRef}>
                  <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                </div>
              )
            } else {
              return (<FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />)
            }
          })
        }
        {/* || load */}
        {(loading) &&
          <div className="featuredProfilesList">
            {[...Array(6)].map(() => {
              return (
                <FeaturedProfileLoader />
              )
            })}
          </div>
        }
      </div>}

    </div>
  );
}

export default FeaturedProfiles
