import { createSlice, } from '@reduxjs/toolkit';
import { TodaysEventState } from './interface';

const initialState: TodaysEventState = {
    loading: false,
    categoryEvents: [],
    page: 1,
    pagination: true,
    error: null
};
export const todaysEventSlice = createSlice({
    name: 'todaysEventSlice',
    initialState,
    reducers: {
        categoryEventsRequest: (state) => {
            state.loading = true
        },
        categoryEventsSuccess: (state, action: any) => {
            state.loading = false;
            state.categoryEvents = state.page === 1 ? action.payload.data : state.categoryEvents.concat(action.payload.data);
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        categoryEventsFailed: (state, action: any) => {
            state.loading = false;
            state.error = action.payload
        }

    }
})
export const { categoryEventsRequest, categoryEventsSuccess, categoryEventsFailed } = todaysEventSlice.actions
export default todaysEventSlice.reducer