import React from 'react';
import { Switch, Route } from 'react-router-dom';

import './App.css';
import Topbar from './components/topbar/Topbar';
import Feed from './components/Tabs/feed/Feed';

import AddEdit from './components/Tabs/addevent/AddEdit';
//routes
import ProtectedRoute from './routes/ProtectedRoute';
// import PublicRoute from "./routes/PublicRoute";
// import CommonRoute from "./routes/CommonRoute";
import { useAppSelector } from './app/hooks';

import Chaticon from './components/Tabs/chat/minichat/Chaticon';
import SubApp1 from './SubApp1';
import Event from './components/pages/Event/Event';
import Notifications from './components/pages/notification/Notifications';
import PendingNotifications from './components/pages/pendingnotifications/PendingNotifications';
import Newprofile from './components/Tabs/profile/Newprofile';
import OtherProfile from './components/Tabs/profile/OtherProfile';
import SearchResultScreen from './withoutlogin/EventSearchResult/SearchResultScreen';
import FeedWl from './withoutlogin/Feed/FeedWl';
import EventDetailWl from './withoutlogin/EventDetail/EventDetailWl';
import EventSearchResultWl from './withoutlogin/EventSearchResult/SearchResultScreen';
import HomePage from './withoutlogin/EventSearch/HomePage';
import TicketPaymentSuccessful from './components/pages/Event/components/TicketPaymentSuccessful';
import TicketPaymentFailed from './components/pages/Event/components/TicketPaymentFailed';
const SubApp = () => {
	const { isAuth } = useAppSelector((state) => state.login);
	console.log('---------------------- is auth -----------------------', isAuth);
	return (
		<>
			{isAuth && <Topbar />}
			<Switch>
				<ProtectedRoute path='/profile' isAuth={isAuth} component={Newprofile} />
				<ProtectedRoute path='/user/:user' isAuth={isAuth} component={OtherProfile} />
				<ProtectedRoute path='/feed' isAuth={isAuth} component={Feed} />
				<ProtectedRoute path='/edit' isAuth={isAuth} component={AddEdit} />
				<ProtectedRoute path='/add' isAuth={isAuth} component={AddEdit} />
				<Route path='/eventSearchResult' component={EventSearchResultWl} />

				{/* <ProtectedRoute path="/ticket-confirm" isAuth={isAuth} component={TicketPaymentSuccessful} />
				<ProtectedRoute path="/ticket-failed" isAuth={isAuth} component={TicketPaymentFailed} /> */}


				{isAuth ? (
					<ProtectedRoute path='/event/:eventId' isAuth={isAuth} component={Event} />
				) : (
					<Route path='/event/:eventId' component={EventDetailWl} />
				)}

				<ProtectedRoute path='/notifications' isAuth={isAuth} component={Notifications} />

				{/* <Route path='/eventSearch' component={HomePage} /> */}
				{/* <ProtectedRoute path='/pending-invitations' isAuth={isAuth} component={PendingNotifications} /> */}

				{/* commented by me */}
				{<Route component={SubApp1} />}
				{/* <HomePage /> */}
				{/* <EventDetailWl /> */}
				{/* <SearchResultScreen /> */}
			</Switch>
			{isAuth && <Chaticon />}
		</>

		// <div style={{ height: "100%", width: "100%", border: "solid 5px" }}>
		//   <HomePage />
		// </div>
	);
};

export default SubApp;
