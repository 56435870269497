import React, { useEffect } from 'react'
import DiscoverSearch from './components/DiscoverSearch'
import Home from './components/Home'
// import MiniProfile from '../../smallcomponents/miniprofile/MiniProfile'
import MiniFeaturedProfiles1 from '../../smallcomponents/minifeaturedprofile/MiniFeaturedProfiles1'
import ExploreBy from '../../smallcomponents/exploreby/ExploreBy'
import './browse.css'
import StoryWrapper from '../../pages/story/StoryWrapper'
// import Scrollbars from 'react-custom-scrollbars-2'

const Browse = () => {

    useEffect(() => {
        document.title = 'Mutual Events - Browse'
    }, [])
    return (
        <div className='browse_wrapper container'>
 
            <div className='browse'>
                <div className='browseMain'>
                    <DiscoverSearch passed={null} />
                    <StoryWrapper />
                    <ExploreBy />
                    <Home />
                </div>
                <div className='browseSidebar'>
                    <div className='browseSidebarWrapper' >
                        {/* <MiniProfile/> */}
                        <MiniFeaturedProfiles1 />
                        {/* <Scrollbars style={{height:'100%'}}>
                        <div>
                            abcd
                        </div>
                        {
                            [...Array(25)].map((itm,key)=>{
                                return                        <div style={{height:'40px'}}>
                                {key}
                            </div>
                            })
                        }

                    </Scrollbars> */}
                    </div>

                </div>
            </div>
        </div>
        
    )
}

export default Browse
