import React, { useState } from 'react'
import './featuredprofile.css'
import FollowButton from '../followbutton/FollowButton'
import { Featured } from '../../pages/featuredprofiles/store/interface'
import { useHistory } from 'react-router'
import { useAppSelector } from '../../../app/hooks'

const FeaturedProfileItem = ({featured,k,unf,place}: {featured: Featured,k: number,unf: boolean,place: any}) => {
    const history = useHistory()
    const {dp,firstname,follow_request,following,id,lastname,username,dp_thumbnail}= featured;
    const {
        profile:prof
    } = useAppSelector(state => state.profile)
    
    return (
        <div className='featuredprofile' onClick={()=>{
            if(id ===prof.id){
                history.push('/profile')
            }else{
                history.push(`/user/${username}`)
            }
            
        }}>
            <img alt="P_I_C" src={dp}></img>
            <div className='featuredNames'>
                <h3>{firstname + ' ' + lastname}</h3>
                <span>{username}</span>
            </div>
            {id ===prof.id? null: <FollowButton following={following} requested={follow_request} id={id} unf={unf} place={place}/>}
        </div>
    )
}

export default FeaturedProfileItem
