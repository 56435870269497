import { useEffect, useRef, useState } from "react";



export function useOutsideClose(fun?:()=>void) {
    const [isVisible, setisVisible] = useState(false);
    const handleOpen = () => setisVisible(true)
    const handleClose = () => setisVisible(false)

    const ref :any= useRef()

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!ref) return;
            if (ref.current && !ref.current.contains(event.target)) {
                setisVisible(false);
                if(fun){
                    fun()

                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
    return {isVisible ,handleOpen, handleClose,ref };
}  