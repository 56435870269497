import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import EventDetailWl from './EventDetail/EventDetailWl';
import EventSearchResultWl from './EventSearchResult/SearchResultScreen';

import HomePage from './EventSearch/HomePage';
import FeedWl from './Feed/FeedWl';
import Topbar from './TopBarWl';
import PublicRoute from '../routes/PublicRoute';
import { useAppSelector } from '../app/hooks';
import ForgotPassword from '../components/login/ForgotPassword';
import Signup from '../components/login/Newersignup';
import TicketPaymentSuccessful from '../components/pages/Event/components/TicketPaymentSuccessful';
import TicketPaymentFailed from '../components/pages/Event/components/TicketPaymentFailed';
import Blog from './Blog/Blog';
import CommonRoute from '../routes/CommonRoute';
import Legal from '../components/mobileview/Legal';
import GuestTicket from './../components/guest/GuestTicket';
import SelectCategories from '../components/login/SelectCategories';
import LandingPage from '../components/mobileview/LandingPage';
import WebLandingPage from '../components/landingpage/WebLandingPage';
import HomepageMobile from './EventSearch/HomepageMobile';


function IndexPageWl() {
	const { isAuth } = useAppSelector((state) => state.login);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '0px', margin: '0px' }}>
			<Topbar />

			<Switch>
				<Route path='/feedwl' component={FeedWl} />
				<Route path='/event/:eventId' component={EventDetailWl} />
				<Route exact path="/blog" component={Blog} />
				<Route path='/eventSearchResult' component={EventSearchResultWl} />
				<Route path="/ticket-confirm" component={TicketPaymentSuccessful} />
				<Route path="/ticket-failed" component={TicketPaymentFailed} />
				<Route exact path="/ticket/guest" component={GuestTicket} />
            	<Route exact path="/interested-categories" component={SelectCategories} />
				<Route exact path="/home" component={HomePage} />
				<Route path="/landingpage" component={LandingPage} />
				<Route exact path="/ticketing/landing" component={WebLandingPage} />
            {/* <CommonRoute path="/" mode={'mobile'} type={'terms'} isAuth={isAuth} component={MailVerified} /> */}





				<CommonRoute path="/terms-of-service" mode={'mobile'} type={'terms'} isAuth={isAuth} component={Legal} />
				<CommonRoute path="/user-guidelines" mode={'mobile'} type={'guidelines'} isAuth={isAuth} component={Legal} />
				<CommonRoute path="/privacy-policy" mode={'mobile'} type={'privacy'} isAuth={isAuth} component={Legal} />
				<CommonRoute path="/account-deletion" mode={'mobile'} type={'accountdeletion'} isAuth={isAuth} component={Legal} />



				{/* <Route component={SubApp} /> */}
				<Route component={HomePage} />

				{/* <Route component={HomepageMobile} /> */}

			</Switch>
			<Footer />
		</div>
	);
}

export default IndexPageWl;
