import React from "react";
// import "./style.css";

import { makeStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
// import Slider from "@material-ui/core/Slider";
// import Cropper from "react-easy-crop";
// import WarningIcon from "@material-ui/icons/Warning";
// import ZoomInIcon from "@material-ui/icons/ZoomIn";
// import ZoomOutIcon from "@material-ui/icons/ZoomOut";
// import { CircularProgress } from "@material-ui/core";
import { Modal } from "@material-ui/core";
// import './style.css'
import { useDispatch } from 'react-redux';
// import imageCompression from 'browser-image-compression';

// import { ReactComponent as Play } from '../../../assets/svg/video.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: 'rgb(0,0,0,0.7)',
    zIndex: 10300,
  },
  appBar: {
    position: "relative",
    backgroundColor: "white",
  },
  buttonClose: {
    height: "4rem",
    width: "4rem",
    position: "absolute",
    // backgroundColor: 'white',
    color: "red",
    right: "10px",
    zIndex: 100,
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
  },
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },

}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function SingleImageModal({
  dialogStatus,
  dialogAction,
  //   fetchProfile,
  currentimg,
}: {
  dialogStatus: any,
  dialogAction: any,
  currentimg: any,
}) {
  const classes = useStyles();

  const handleClose = (e: any) => {
    e.preventDefault()
    dialogAction(false);
    // setAlert(false)
    setAlerter(false);

    // setImg('')
  };
  let dispatch = useDispatch()
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => {
    let target = document.getElementById('userdpedit')
    if (target) {
      target.click()
    }
  };

  const i: any = null;
  const [image, setImage] = React.useState(i);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [minzoom, setminZoom] = React.useState(1);

  const [loader, setLoader] = React.useState(false);
  const [alerter, setAlerter] = React.useState(false);

  const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const clear = () => {
    dialogAction(false);
    setImage(null);
    setAlerter(false);
  };
  const textalert = () => {
    setAlerter(true);
  };





  const data = [
    {
      "id": 316,
      "profile_id": 1,
      "profile_firstname": "Jon",
      "profile_surname": "Snow",
      "profile_username": "jonsnow",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/cropped-image_FXBRzyf-thumbnail-100x100-70.jpeg",
      "description": "#world",
      "post_file_id": 159,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/video/videoplayback3.mp4",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/defaults/feed/feed.png",
      "views_count": 0
    },
    {
      "id": 316,
      "profile_id": 1,
      "profile_firstname": "Jon",
      "profile_surname": "Snow",
      "profile_username": "jonsnow",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/cropped-image_FXBRzyf-thumbnail-100x100-70.jpeg",
      "description": "#world",
      "post_file_id": 160,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/video/videoplayback4.mp4",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/defaults/feed/feed.png",
      "views_count": 0
    },
    {
      "id": 250,
      "profile_id": 3,
      "profile_firstname": "Nooh",
      "profile_surname": "Ihza",
      "profile_username": "nooh001",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/dp1_So3TRah-thumbnail-100x100-70.jpeg",
      "description": "",
      "post_file_id": 239,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/image_cropper_1622270263984.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/image_cropper_1622270263984-thumbnail-200x200-70.jpg",
      "views_count": 0
    },
    {
      "id": 249,
      "profile_id": 3,
      "profile_firstname": "Nooh",
      "profile_surname": "Ihza",
      "profile_username": "nooh001",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/dp1_So3TRah-thumbnail-100x100-70.jpeg",
      "description": "",
      "post_file_id": 238,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/image_cropper_1622270129491.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/image_cropper_1622270129491-thumbnail-200x200-70.jpg",
      "views_count": 0
    },
    {
      "id": 248,
      "profile_id": 3,
      "profile_firstname": "Nooh",
      "profile_surname": "Ihza",
      "profile_username": "nooh001",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/dp1_So3TRah-thumbnail-100x100-70.jpeg",
      "description": "",
      "post_file_id": 237,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/image_cropper_1622270017213.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/image_cropper_1622270017213-thumbnail-200x200-70.jpg",
      "views_count": 0
    },
    {
      "id": 247,
      "profile_id": 3,
      "profile_firstname": "Nooh",
      "profile_surname": "Ihza",
      "profile_username": "nooh001",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/dp1_So3TRah-thumbnail-100x100-70.jpeg",
      "description": "",
      "post_file_id": 236,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/image_cropper_1622268973747.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/image_cropper_1622268973747-thumbnail-200x200-70.jpg",
      "views_count": 1
    },
    {
      "id": 246,
      "profile_id": 3,
      "profile_firstname": "Nooh",
      "profile_surname": "Ihza",
      "profile_username": "nooh001",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/dp1_So3TRah-thumbnail-100x100-70.jpeg",
      "description": "",
      "post_file_id": 235,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/image_cropper_1622268191113.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/image_cropper_1622268191113-thumbnail-200x200-70.jpg",
      "views_count": 0
    },
    {
      "id": 242,
      "profile_id": 1,
      "profile_firstname": "Jon",
      "profile_surname": "Snow",
      "profile_username": "jonsnow",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/cropped-image_FXBRzyf-thumbnail-100x100-70.jpeg",
      "description": "I can’t sleep.",
      "post_file_id": 231,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/1739.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/1739-thumbnail-200x200-70.jpg",
      "views_count": 0
    },
    {
      "id": 241,
      "profile_id": 3,
      "profile_firstname": "Nooh",
      "profile_surname": "Ihza",
      "profile_username": "nooh001",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/dp1_So3TRah-thumbnail-100x100-70.jpeg",
      "description": "",
      "post_file_id": 230,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/image_cropper_1622226658346.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/image_cropper_1622226658346-thumbnail-200x200-70.jpg",
      "views_count": 0
    },
    {
      "id": 240,
      "profile_id": 3,
      "profile_firstname": "Nooh",
      "profile_surname": "Ihza",
      "profile_username": "nooh001",
      "profile_dp": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/user/dp/dp1_So3TRah-thumbnail-100x100-70.jpeg",
      "description": "",
      "post_file_id": 229,
      "post_file": "https://d1li36dzvy4hoo.cloudfront.net/media/post/image/image_cropper_1622226527851.jpg",
      "post_thumbnail": "https://d1li36dzvy4hoo.cloudfront.net/media/__sized__/post/image/image_cropper_1622226527851-thumbnail-200x200-70.jpg",
      "views_count": 0
    }
  ]
  function Checkformat(str: string) {
    const n = 3;
    let format = str.slice(str.length - n)
    if (format === "peg") {
      format = str.slice(str.length - 4)
    }
    // console.log(format);
    let r = '';
    const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'GIF', 'jpeg', 'gif']
    const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'MOV', 'mov', 'AVI', 'avi']


    if (photoformats.includes(format)) {
      r = 'photo'
      // console.log('photo')
    }
    else if (videoformats.includes(format)) {
      r = 'video'
      // console.log('video')
    }
    else {
      r = 'unknown'
    }

    // console.log(str.slice(str.length - n));
    return (r);
  }

  const format = Checkformat(currentimg)
  return (
    <div 
    // style={{ minHeight: '100vh' }}
    >
      <Modal
        // fullScreen
        open={dialogStatus}
        onClose={handleClose}
        // TransitionComponent={Transition}
        className='mediasinglemainmodal'
        
      >
        <div className='mediamodalWrapper'>
                <div className='closeBtn' onClick={handleClose}><CloseIcon /></div>
        <div className='carouselWrapper'>
          {

            format === 'video' ?
              <div style={{ display: 'flex', justifyContent: 'center', height: '90%' }}>
                <video controls>
                  <source src={currentimg} type="video/mp4" />
                  <source src={currentimg} type="video/mkv" />
                  <source src={currentimg} type="video/quicktime" />
                  Your browser does not support the video tag.
                </video>
              </div>
              : (

                <div className='imgtr' style={{width:'100%'}}>
                  <TransformWrapper>
                    <TransformComponent>
                      <img alt='Modal image' src={currentimg} style={{ maxHeight: '95%', maxWidth: '80%' }}></img>
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              )
          }
        </div>
        </div>


      </Modal>
    </div>
  );
}
