import React from 'react'
import ImageContainer from '../../../../../utils/ImageLoader/image-container'
import { useAppSelector } from '../../../../../../app/hooks'

// interface IpendingPayments {
//   EventName: string,
//   PendingAmount: string | number;
//   EventImage: string
//   EventImageThumbNail: string
// }

// const PendingPayments = ({ EventName, PendingAmount, EventImage, EventImageThumbNail }: IpendingPayments) => {

//   return (
//     PendingPayments.length !== 0 ? <div className='pending_payments' >
//       <div className="pending_payments_head">
//         <h5>Pending Payments</h5>
//         <h6 style={{ color: "red" }}>View all</h6>

//       </div>

//       <div className="pending_payments_event">
//         <div className="pending_payments_image">
//           <ImageContainer height={30} width={30} borderRadius={15} src={EventImage} thumb={EventImageThumbNail} />

//         </div>
//         <div className="pending_payments_title_amount">

//           <div className="pending_payment_event_title">
//             <small>{EventName}</small>

//           </div>
//           <small >${PendingAmount}</small>
//         </div>

//       </div>
//     </div>

//       : <small style={{ height: "230px", display: 'flex', alignItems: "center", color: "lightgrey", justifyContent: "center" }}>Pending payments display here</small>)
// }

// export default PendingPayments


function PendingPayments() {
  const { fetchOveralldata } = useAppSelector(state => state.ticketPortal)

  return (
    <div className='pending_payments' >
      <div className="pending_payments_head">
        <h5>Pending Payments</h5>
        <h6 style={{ color: "red" }}>View all</h6>

      </div>

      {
        fetchOveralldata && fetchOveralldata.pending_payments
          ? fetchOveralldata.pending_payments.slice(0, 4).map((item, key) => {
            return (
              <div className="pending_payments_event">
                <div className="pending_payments_image">
                  <ImageContainer height={30} width={30} borderRadius={15} src={item.event.event_image} thumb={item.event.event_image_thumbnail} />

                </div>
                <div className="pending_payments_title_amount">

                  <div className="pending_payment_event_title">
                    <small>{item.event.name}</small>

                  </div>
                  <small >{item.pending_amount}</small>
                </div>

              </div>


            )
          }
          ) : <small style={{ height: "230px", display: 'flex', alignItems: "center", color: "lightgrey", justifyContent: "center" }}>Pending Payments display here</small>}
    </div>
  )
}



export default PendingPayments