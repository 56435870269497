import React, { useState, useEffect } from 'react';
// import { useAppDispatch } from '../../../../app/hooks';
import { ReactComponent as Tick } from '../../../assets/svg/lgcheck.svg';
import { addeventinvite } from '../../pages/Event/store/eventSlice';
import { useAppDispatch } from '../../../app/hooks';
// import '../add.css'
// import { invites } from '../store/addEventSlice';


const InviteItem = ({ user, k, check }: { user: any, k: number, check: boolean }) => {
    const { dp_thumbnail, firstname, lastname, id, username } = user;
    const [checked, setchecked] = useState(check)
    const dispatch = useAppDispatch()

    return (
        <div className="AddEventUserItem" onClick={e => {
            setchecked(!checked)
            dispatch(addeventinvite(id))
        }}>
            <div className="AddEventUserSec2">
                {username}
            </div>
            <div className="AddEventUserSec1">
                <div className={checked ? 'lgbox checked' : 'lgbox notchecked'} >
                    {checked && <Tick />}
                </div>
                <img src={dp_thumbnail} alt="P_I_C" className="AddEventUserDpImg" />

                <div className="AddEventUsrName">{firstname + " " + lastname}</div>
            </div>
        </div>
    )
}

export default InviteItem
