import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';
import EventTileLong from './EventTileLong'
// import { attending } from '../dummydata'
import { noData } from '../../../../Data/Data';
import dayjs from 'dayjs';
import Api from '../../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { interestedsEventsSuccess, interestedsEventsRequest, interestedsEventsFailed } from '../store/eventsSlice';
import { useHistory } from 'react-router-dom';
import { errors } from '../../../../Data/Error';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import Eventtilelongloader from '../../../smallcomponents/loaders/Eventtilelongloader';
const InterestedEvents = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { lat, lng } = useAppSelector(state => state.profile)
    // const lat = '10.8505159'
    // const lng = '76.2710833'
    const {
        interested_events_list,
        interested_events_page,
        interested_events_pagination,
        interested_events_loading,
        interested_events_error,
        interestsType,
        SearchDate
    } = useAppSelector(state => state.events)

    // let dt: any = new Date()
    const initialDate= dayjs(SearchDate).format("YYYY-MM-DD")

    const head: any = useRef(null)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)


    const success = (data: any) => {
        console.log(data)
        let pgn = data.length < 10 ? false : true
        dispatch(interestedsEventsSuccess({
            data: data,
            no: interested_events_page + 1,
            pagination: pgn
        }))
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(interestedsEventsFailed(data))
    }
    let post_data = {
        date: dayjs(initialDate).format("YYYY-MM-DD"),
        event_type: 'interests'
    }
    useEffect(() => {
        dispatch(interestedsEventsRequest({ date: dayjs(initialDate).format("YYYY-MM-DD"), no: 1 }))
        let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, post_data, success, failed, history, true, true)
    }, [lat, lng])
    const Refresh = () => {
        head.current.scrollIntoView({ behavior: "smooth", block: "start" });
        dispatch(interestedsEventsRequest({ date: SearchDate, no: 1 }))
        let theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/list/web/${1}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post',theurl, post_data, success, failed, history, true, true)
    }
    // const LoadMore = () => {
    //     dispatch(interestedsEventsRequest({ date: SearchDate, no: interested_events_page }))
    //     let data = {
    //         date: SearchDate,
    //         event_type: interestsType
    //     }
    //     let theurl = `/api/v1/event/list/web/${interested_events_page}/10/?latitude=${lat}&longitude=${lng}`
    //     if (unparseddetails) {
    //       if (currentparsed.is_business_profile) {
    //         theurl = `/api/v1/event/list/web/${interested_events_page}/10/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
    //       }
  
    //     }
    //     Api('post', theurl, post_data, success, failed, history, true, true)
    // }
    return (
        <div className='attendingEvents'>
            <div ref={head} className='commonHeading'>
                <h2>Events of Interests</h2>
                <span>Check out the events your friends are attending</span>
            </div>
            <div className='eventTileList'>
                {
                    (Object.keys(interested_events_list).length === 0) ? (
                        (!interested_events_loading && interested_events_error === null) &&
                        <div className='noData'>
                            <Warning />
                            <h2>{noData.attending.heading}</h2>
                            <span>{noData.attending.message}</span>
                        </div>
                    ) : (
                        interested_events_list.map((events:any, key) => {
                            return (
                                <EventTileLong Events={events} k={key} />
                            )
                        })
                    )
                }
                {
                    interested_events_loading && [...Array(2)].map(() => {
                        return (
                            // <div className="eventileLong skeleton" ></div>
                            <Eventtilelongloader />
                        )
                    })
                }
                {
                    (!interested_events_loading && interested_events_error !== null) &&
                    (interested_events_error.status === null && interested_events_error.text === null ? (
                        <div className='noData'>
                            <Warning />
                            <h2>{noData.attending.heading}</h2>
                            <span>{noData.attending.message}</span>
                        </div>
                    ) : interested_events_error.status === null ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Nointernet />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                            </div>
                        </div>
                    ) : interested_events_error.status === 500 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Try again</button>
                            </div>
                        </div>
                    ) : interested_events_error.status === 401 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }



            </div>
            {interested_events_pagination && (
                !interested_events_loading ? (interested_events_error === null ? <div className='loadmore'>
                    <button className='mainBtn'>Show more events</button>
                </div> : null) : null)
            }
        </div >
    )
}

export default InterestedEvents
