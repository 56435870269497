import React, { useState } from 'react'
import { useAppSelector } from '../../../../../../app/hooks'
import ImageContainer from '../../../../../utils/ImageLoader/image-container'

function RecentPayouts() {
  const { fetchOveralldata } = useAppSelector(state => state.ticketPortal)
  // console.log('------recent payouts', fetchOveralldata);
  return (
    <div className='recent_payouts'>
      <div className="recent_payout_head">
        <h5  >Recent payouts</h5>
        <h6 style={{ color: "red", }}>View all</h6>

      </div>
      {

        fetchOveralldata && fetchOveralldata.recent_payouts && fetchOveralldata.recent_payouts.length !== 0
          ? fetchOveralldata.recent_payouts.slice(0, 4).map((item: any) => {

            return (

              <div className='recent_payouts_event'>
                <div className="recent_payout_image">
                  <ImageContainer height={30} width={30} borderRadius={15} src={item.event.event_image} thumb={item.event.event_image_thumbnail} />

                </div>
                <div className='recent_payout_title_amount'>
                  <div className="recent_payout_event_title">
                    <small>{item ? item.event.name : ""}</small>

                  </div>
                  <small>${item ? item.amount : ""}</small>
                </div>

              </div>



            )
          })
          : <small style={{ height: "230px", display: 'flex', alignItems: "center", color: "lightgrey", justifyContent: "center" }}>RecentPayouts display here</small>}

    </div>
  )
}

export default RecentPayouts