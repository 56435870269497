import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
// import ChatSearch from '../../chat/common/ChatSearch';
import SettingsDropdown from '../../../topbar/settings/SettingsDropdown';
import NotificationDropdown from '../../../topbar/notification/NotificationDropdown';
import { useHistory } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { AiOutlineMenuUnfold } from 'react-icons/ai'

const ContentModeratorLayout = ({ children , hideHome}: any) => {

    const history = useHistory()
    const { profile, loading: profloading, error: proferror } = useAppSelector(state => state.profile)
    const { socket, openedchatkey, recentchat, searchdata, openedchatdetails, searcherror, searchloading, searchpage, searchpagination, searchkeyword } = useAppSelector(state => state.chat)
    const [isActive, setIsActive] = useState(false);

    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (isActive && targt && !targt.contains(e.target)) {
                setIsActive(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive])

    return (
        <div className='content_moderator'>
            {/* <div className='content_moderator_topbar'>

        </div> */}
            <div className='chat_topbar' style={{width:'100vw',position:'fixed'}}>
                <div className='chat_topbarleft'>
                    <img className='cursor' src='https://d20rv3nuwgisca.cloudfront.net/general/logo/mutualevents-minimal-logo.png' onClick={() => {
                        history.push('/')
                    }} />
                    {/* <hr />
                    <h4>Conversations</h4> */}
                </div>
                {/* <ChatSearch chatwindow={'main'} /> */}

                <div className='topBarRight'>
                    <div className='mainTabscontainer' style={{marginRight:'10px'}}>
                        <div className='menubtn' ref={vis} onClick={() => {
                            setIsActive(!isActive)
                        }}><AiOutlineMenuUnfold /></div>
                        <div className={`mainTabs ${isActive ? 'active' : 'inactive'}`} >
                            <span></span>
                          {!hideHome &&  <NavLink className='links navLink' to="/content-moderator/home" activeClassName="navLinkActive">
                                Home
                            </NavLink>}
                            {/* <NavLink className='links navLink' to="/content-moderator/messages" activeClassName="navLinkActive">
                                Messages
                            </NavLink> */}
                            {/* <NavLink className='links navLink' to="/content-moderator/content" activeClassName="navLinkActive">
                                Content
                            </NavLink> */}
                        </div>

                    </div>
                    {/* <div className='dropdowns'>
                            <div className='settingsicon'>
                                <SettingsDropdown />
                            </div>
                            <div className='bellcontainer'>
                                <NotificationDropdown />
                            </div>


                        </div>
                        <div className='navBtn'>
                            <NavLink to={`/${'add'}`} className='mainBtn' activeClassName="mainBtnActive" onClick={() => {
                                dispatch(clearAdd())
                            }}>Add event</NavLink>

                        </div> */}
                </div>

                <div className='chat_righttopbar'>
                    <div className='dropdowns'>
                        <div className='settingsicon'>
                            <SettingsDropdown />
                        </div>
                        <div className='bellcontainer'>
                            <NotificationDropdown />
                        </div>
                    </div>
                    <div className='chat_main_user cursor' onClick={() => {
                        history.push('/profile')
                    }}>
                        {
                            !profloading && proferror === null ? (
                                <div className='chat_main_userDetails' >
                                    <h4>{profile.firstname + ' ' + profile.surname}</h4>
                                    <span>@{profile.username}</span>
                                </div>
                            ) : (
                                <div className='chat_main_userDetails' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                    <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                                    <span className='skeleton minhe10' style={{ maxWidth: '50px' }}></span>
                                </div>
                            )

                        }
                        {
                            !profloading && proferror === null ? (
                                <div className='chat_main_user_img'>
                                    <img src={profile.dp_thumbnail_1} />
                                </div>
                            ) : (
                                <div className='chat_main_user_img skeleton'>

                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <div className='content_moderator_wrapper'>
                {children}
            </div>

        </div>
    )
}

export default ContentModeratorLayout