import React, { useState } from 'react';
import { isBrowser, isAndroid, isIOS } from 'react-device-detect';
import logo from '../../assets/images/landing.png';


// import Screen1 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen1+(3).png';
// import Screen2 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen2+(3).png';
// import Screen3 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen3+(3).png';
// import Screen4 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen4+(3).png';


// import Screen81 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-1+(3).png';
// import Screen82 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-2+(3).png';
// import Screen83 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-3+(3).png';
// import Screen84 from 'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-4+(3).png';

// https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-1+(3).png



// import me_bg from '../../assets/carousel/me_bg.png';

// import Be_Informed from '../../assets/carousel/Be_Informed.webp';

import './landing.css';
import { Link, useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import IphoneCarousel from './iphoneCarousel/IphoneCarousel';



const LandingPage = () => {
	const [menu, setmenu] = useState(false);

	let history = useHistory();

	const openStore = () => {
		if (isAndroid) {
		} else if (isIOS) {
		}
	};
	return (
		<div className='landingpagelayout'>
			{menu && (
				<div className='landingpagemenu'>
					<img alt='Mutual events logo' src={logo}></img>
					<div className='landingpagemenu_links'>
						<Link to='/privacy-policy'>Privacy Policy</Link>
						<Link to='/user-guidelines'>User Guidelines</Link>
						<Link to='/terms-of-service'>Terms of Service</Link>
					</div>
				</div>
			)}
			<div className='landingpagefirstsec'>
				<div className='landingpageheader'>
					<div className='landingpagelogo'>
						<img alt='Mutual events logo' src={logo}></img>
					</div>

					{/* <div
						className='landingpagemenuicon'
						style={menu ? { position: 'fixed', right: '20px' } : {}}
						onClick={(e) => {
							setmenu(!menu);
						}}>
						<div className={menu === true ? 'menubar1open' : 'menubar1'}></div>
						<div className={menu === true ? 'menubar2open' : 'menubar2'}></div>
						<div className={menu === true ? 'menubar3open' : 'menubar3'}></div>
					</div> */}
				</div>
				<div className='firstsecheading'>
					{/* <h1>
                        Mutual Events
                    </h1> */}
					<h2>
						{/* brings people together through events. */}
						Join the world’s fastest growing events-based social network.
					</h2>
					<h3>
						{/* <strong>  Join the world’s fastest growing events-based social network. </strong> */}
						Fill your calendar with upcoming events, schedule your own with friends, and let the world know about those you’ve
						attended on the events app making a splash worldwide.
					</h3>
					{/* <h3>
                        {'Post, Share & connect through events'}
                    </h3> */}
				</div>
				<div className='firstsecimages'>
					<div style={{ width: '100vw' }}>
						<Carousel
							swipeable={false}
							autoPlay={true}
							showArrows={false}
							showStatus={false}
							dynamicHeight={false}
							showThumbs={false}
							showIndicators={false}
							infiniteLoop={true}
							stopOnHover={false}
							transitionTime={1000}

							preventMovementUntilSwipeScrollTolerance={true}>

							<div className='cr_images'>
								<img
									loading='eager'
									src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/hd_cr_images_1.png'}
									alt='example_image'></img>
							</div>
							<div className='cr_images'>
								<img
									loading='eager'
									src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/hd_cr_images_2.png'}
									alt='example_image'></img>
							</div>
							<div className='cr_images'>
								<img
									loading='eager'
									src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/hd_cr_images_3.png'}
									alt='example_image'></img>
							</div>
							<div className='cr_images'>
								<img
									loading='eager'
									src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/hd_cr_images_4.png'}
									alt='example_image'></img>
							</div>
							<div className='cr_images'>
								<img
									loading='eager'
									src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/hd_cr_images_5_BSjl73v.png'}
									alt='example_image'></img>
							</div>
							<div className='cr_images'>
								<img
									loading='eager'
									src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/hd_cr_images_6.png'}
									alt='example_image'></img>
							</div>
						</Carousel>
					</div>

					{/* <div className='first1'><img src={first1} alt='example_image'></img></div> */}
					{/* <div className='first3'><img src={first3} alt='example_image'></img></div> */}
				</div>
			</div>
			<div className='landingpagesecondsec'>
				{/* <a className='landingpagedownloadbutton' href={isAndroid? `https://play.google.com/store/apps/details?id=com.mutualevents.app` 
                    : isIOS ? `https://apps.apple.com/in/app/mutual-events/id1590547493`
                    : `https://play.google.com/store/apps/details?id=com.mutualevents.app`}>
                        <h3>Get it now!</h3>
                    </a> */}

				<div>
					{isAndroid ? (
						<a className='landingpagedownloadbutton' href='https://play.google.com/store/apps/details?id=com.mutualevents.app'>
							<h3>Download Mutual Events</h3>
						</a>
					) : isIOS ? (
						<a className='landingpagedownloadbutton' href='https://apps.apple.com/in/app/mutual-events/id1590547493'>
							<h3>Download Mutual Events</h3>
						</a>
					) : (
						<a className='landingpagedownloadbutton' href='https://play.google.com/store/apps/details?id=com.mutualevents.app'>
							<h3>Download Mutual Events</h3>
						</a>
					)}
					<h3 style={{ color: 'black', textAlign: 'center', padding: '10px 40px' }}>
						{/* <strong> Download Mutual Events.</strong> */}
						<span style={{ color: 'var(--cl_textgrey)' }}> All your events in one place. </span>
					</h3>
				</div>
				{/* <div className='landingpagedetect'>
					<h6>{isAndroid ? 'from playstore' : isIOS ? 'from App store' : null}</h6>
				</div> */}
				<div className='firstsecheading' style={{ marginTop: "30px" }}>
					<h4>Explore public and private events by category, location, and date.</h4>
					<h3>
						Find and book your favourite events. Capture and share your memories, or scroll the feed to discover the experiences of
						your family and friends. Whether you’re looking for entertainment, music, sports, food and drink, family, corporate, or
						cultural events, you’re in the right place.
					</h3>
				</div>
				<div style={{ width: '100vw', padding: '20px 0px 20px 0px', position: 'relative', zIndex: 999, height: '590px', overflow: 'visible'}} className="cs__slide__wrap">
					<Carousel
					className="carousel__landing__page"
						swipeable={false}
						autoPlay={true}
						showArrows={false}
						showStatus={false}
						dynamicHeight={false}
						showThumbs={false}
						showIndicators={false}
						infiniteLoop={true}
						centerMode={true}
						transitionTime={1000}
						centerSlidePercentage={70}
						stopOnHover={false}>
						{/* <HalfSlide first={Screen1} second={Screen2} color={'white'} />
						<HalfSlide first={Screen2} second={Screen3} color={'white'} />
						<HalfSlide first={Screen3} second={Screen4} color={'white'} />
						<HalfSlide first={Screen4} second={Screen1} color={'white'} /> */}
						<div className='new__slide'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen1+(3).png'}
								alt='example_image'></img>
						</div>

						<div className='new__slide'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen2+(3).png'}

								
								alt='example_image'></img>
						</div>

						<div className='new__slide'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen3+(3).png'}

								alt='example_image'></img>
						</div>
						<div className='new__slide'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen4+(3).png'}

								alt='example_image'></img>
						</div>
					</Carousel>
					<div
						style={{
							height: '15%',
							position: 'absolute',
							width: '100vw',
							backgroundColor: '#0181C9',
							bottom: '-2px',
							zIndex: -1,
						}}></div>
				</div>
			</div>
			<div className='landingpagethirdsec' style={{ paddingBottom: '0px' }} >
				<div className='firstsecheading' style={{ paddingTop: '0px' }}>
					<h4>Perhaps you’re looking to create and promote an event?</h4>
					<h3>
						Where would event-lovers be without you? On Mutual Events, you’ll find an engaged audience. We’re all chasing a
						life-changing event – why not make it yours? As an event lister, you can sell event tickets and access detailed
						analytics.
					</h3>
				</div>
				{/* <div className='landingpagesecondimages'> */}
				{/* <div className='third1'><img src={third1} alt='example image'></img></div>
                    <div className='third2'><img src={third2} alt='example image'></img></div> */}

				{/* </div> */}
				{/* <div className='third3'><img src={third3} alt=''></img></div> */}

				<div style={{ width: '100vw', padding: '20px 0px 20px 0px', position: 'relative', zIndex: 999, height: '700px' }} className="sec__screen_carousl">
					<Carousel
					className="carousel__landing__page"

						swipeable={false}
						autoPlay={true}
						showArrows={false}
						showStatus={false}
						dynamicHeight={false}
						showThumbs={false}
						showIndicators={false}
						infiniteLoop={true}
						centerMode={true}
						transitionTime={1000}
						centerSlidePercentage={70}
						stopOnHover={false}>
						{/* <HalfSlide2 first={Screen5} second={Screen6} color={'white'} />
						<HalfSlide2 first={Screen6} second={Screen7} color={'white'} />
						<HalfSlide2 first={Screen7} second={Screen8} color={'white'} />
						<HalfSlide2 first={Screen8} second={Screen5} color={'white'} /> */}
{/* 
						<HalfSlide2 first={Screen81} second={Screen82} color={'white'} />
						<HalfSlide2 first={Screen82} second={Screen83} color={'white'} />
						<HalfSlide2 first={Screen83} second={Screen84} color={'white'} />
						<HalfSlide2 first={Screen84} second={Screen81} color={'white'} /> */}
							<div className='new__slide2'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-1+(3).png'}
								alt='example_image'></img>
						</div>

						<div className='new__slide2'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-2+(3).png'}
								alt='example_image'></img>
						</div>

						<div className='new__slide2'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-3+(3).png'}
								alt='example_image'></img>
						</div>
						<div className='new__slide2'>
							<img
								loading='eager'
								src={'https://mutual-events.s3.us-east-2.amazonaws.com/media/post/image/Screen8-4+(3).png'}
								alt='example_image'></img>
						</div>


					</Carousel>
					{/* <div
                    className='me_bg'
						style={{
							height: '100px',
							position: 'absolute',
							width: '100vw',
							
							bottom: '-0px',
							right: 0,
							zIndex: 9999,
						}}></div> */}
				</div>
			</div>
			<div className='landingpagefooter'>
				<div className='landingpagefooterheading'>
					<h4>
						Start <span>creating</span> your events with Mutual Events!
					</h4>
					<h3>
						Whether you’re the organising type or you’re tagging along for the good times, why not say goodbye to FOMO and hello to
						a jampacked calendar?
					</h3>

					{isAndroid ? (
						<a className='landingpagedownloadbutton' href='https://play.google.com/store/apps/details?id=com.mutualevents.app'>
							<h3>Download Mutual Events</h3>
						</a>
					) : isIOS ? (
						<a className='landingpagedownloadbutton' href='https://apps.apple.com/in/app/mutual-events/id1590547493'>
							<h3>Download Mutual Events</h3>
						</a>
					) : (
						<a className='landingpagedownloadbutton' href='https://play.google.com/store/apps/details?id=com.mutualevents.app'>
							<h3>Download Mutual Events</h3>
						</a>
					)}
				</div>
				<div className='landingpagefooterend'>
					<div className='landingpagefooterlogodiv'>
						<div className='landingpagelogo'>
							<img alt='Mutual events logo' src={logo}></img>
						</div>
						<h6>Mutual Events, the platform for finding, organising, and sharing events.</h6>
					</div>
					<div className='landingpagemenulinks'>
						{/* <div className='landingfootermenu'>
							<h5>Menu</h5>
							<h6 onClick={() => history.push('/')}>Browse</h6>
							<h6 onClick={() => history.push('/')}>Feed</h6>
							<h6 onClick={() => history.push('/')}>Events</h6>
							<h6 onClick={() => history.push('/')}>Profile</h6>
						</div>
						<div className='landingfootermenu'>
							<h5>Programs</h5>
							<h6 onClick={() => history.push('/')}>Trending</h6>
							<h6 onClick={() => history.push('/')}>Discover</h6>
						</div> */}
						<div className='landingfootermenu'>
							<h5>Legal</h5>
							<h6 onClick={() => history.push('/privacy-policy')}>Privacy policy</h6>
							<h6 onClick={() => history.push('/user-guidelines')}> User Guidelines </h6>
							<h6 onClick={() => history.push('/terms-of-service')}>Terms of Service</h6>
						</div>
					</div>
					<div className='landingpagefootercopyright'>
						<h6>
							Copyright @ 2022 by <small>Mutual events</small>
						</h6>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
