import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as Gear } from '../../../assets/svg/gear.svg';
import './miniprofile.css'
import { ReactComponent as Calendar } from '../../../assets/svg/calendar.svg';
import { ReactComponent as Followers } from '../../../assets/svg/followers.svg';
import { ReactComponent as Following } from '../../../assets/svg/following.svg';
import { ReactComponent as Selectprofile } from '../../../assets/svg/selectprofile.svg';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Api from '../../../app/api';
import { profileSuccess, profileFailed, profileRequest } from '../../Tabs/profile/store/profileSlice';
import { businessprofilesrequest, businessprofilessuccess, businessprofilesfailed } from '../../pages/newbusinessaccount/store/BusinessprofileSlice';
import { baseurl } from '../../../Data/Data';
import axios from 'axios';
import Scrollbars from 'react-custom-scrollbars-2';


const MiniProfile = () => {
    const history = useHistory();
    const [isActive, setIsActive] = useState(false);
    let det: any = localStorage.getItem('pdetails')
    console.log(det)
    let details: any = det ? JSON.parse(det) : []
    const [storedprof, setstoredprof] = useState<any>(details)

    // const [profilestype,setprofiletype]=useState<any>(localStorage.getItem('profiletype')?localStorage.getItem('profiletype'):null)
    let profilestype: any = localStorage.getItem(`mtusr${0}type`)

    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (isActive && targt && !targt.contains(e.target)) {
                setIsActive(false)
                // setstoredprof([])
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive])

    const { profile, loading, error } = useAppSelector(state => state.profile)
    const { businessprofiles, loading: businessloading, error: businesserror } = useAppSelector(state => state.business)
    const [active, setactive] = useState(loading ? (profile ? profile.public_status : false) : false);
    function Setactivestate(){
        setactive(profile.public_status)
    }
    useEffect(() => {
        Setactivestate()
        fetchbusinessprofiles(details[0].access)

    }, [profile])
    console.log(profile ? profile.public_status : false)
    const dispatch = useAppDispatch();
    const success = (data: any) => {
        dispatch(profileSuccess(data))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(profileFailed(data))
    }
    useEffect(() => {
        if ((Object.keys(profile).length) === 0  && loading === false) {
            dispatch(profileRequest())
            Api('get', "/api/v1/user/profile/", null, success, failed, history, true, true)
        }
    }, [])
    function handleFollowing() {
        history.push(`/profile/following`)
    }
    function handleFollowers() {
        history.push(`/profile/followers`)
    }
    function handleMyEvents() {
        history.push(`/profile/events`)
    }

    const [popActive, setpop] = useState(false)
    const [ploading, setploading] = useState(false)
    let er: any = null
    const [perror, setperror] = useState(er)

    const success1 = (data: any) => {
        console.log(data)
        setploading(false)
        setactive(data.app_data)
    }
    const failed1 = (data: any) => {
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again.'
        }
        setperror({
            head: head,
            message: message,
        })
        console.log(data)
        setploading(false)
        // setactive(false)
        setpop(true)
        setTimeout(() => {
            setpop(false)
            setperror(null)
        }, 3000);
    }
    function ChangeStat() {

        setploading(true);
        let current: any = localStorage.getItem('mtusr')
        let currentparsed = JSON.parse(current)
        let url = '/api/v1/user/profile/status/'
        if(details[0].is_business_profile){
            url = `/api/v1/user/profile/status/?auth_profile=${currentparsed.id}`
        }
        Api('get', url, null, success1, failed1, history, true, true)
    }
    let dt = {
        "id": 47,
        "country_code": "+91",
        "phone": "8606482451",
        "dob": "1998-10-09",
        "bio": null,
        "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
        "cover_photo": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/cover/cover.png",
        "email_verified": false,
        "is_business_profile": false,
        "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
        "cover_photo_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/cover/cover-thumbnail-300x300.png",
        "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY0NDM4NTc2MywianRpIjoiNmY3MTMyNzkzYjVhNGQ3ZmJhMjAzZjcwNTQ1NTRhNzEiLCJ1c2VyX2lkIjo0OH0.urp4UBO8kypoLnZPDzz0jwMtXU3BBODjGnejV7xl5-E",
        "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM5Mjg4MTYzLCJqdGkiOiI4OWQxYWRlYjhjODM0N2JkOWMxNjY1YzA3ZGM2MGE4MiIsInVzZXJfaWQiOjQ4fQ.mW_6lXrALy6Hbp8FW-8JcN_5tC7KUKlp_EXjgK7xGMk",
        "username": "nirmalnandanan",
        "email": "Nirmal@centrric.com",
        "firstname": "Nirmal",
        "surname": "mn"
    }

    const ChangeProfile = (k: number, proftype: string) => {
        let dt: any = localStorage.getItem('pdetails')
        let tdt: any = JSON.parse(dt)

        console.log(tdt)
        if(dt){

            let changingprofile = tdt[k]
            console.log(changingprofile)
            
            tdt.splice(k, 1)
            console.log(tdt)
            tdt = [changingprofile].concat(tdt)
            console.log(tdt)

            localStorage.setItem(`access_token`, changingprofile.access);
            localStorage.setItem(`refresh_token`, changingprofile.refresh);
            localStorage.setItem(`mtusr`, JSON.stringify(changingprofile))
            localStorage.setItem(`mtusrtype`, 'personal')
            localStorage.setItem('pdetails',JSON.stringify(tdt))

            // window.location.reload()
            window.location.href = '/'
        }


        // console.log(k, proftype)
        // if (k !== 0) {
        //     let bufferuser: any = localStorage.getItem(`mtusr${k}`)
        //     let bufferrefresh: any = localStorage.getItem(`refresh_token${k}`);
        //     let bufferaccess: any = localStorage.getItem(`access_token${k}`);
        //     let buffertype: any = localStorage.getItem(`mtusr${k}type`);

        //     let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
        //     let bufferrefresh0: any = localStorage.getItem(`refresh_token${0}`);
        //     let bufferaccess0: any = localStorage.getItem(`access_token`);
        //     let buffertype0: any = localStorage.getItem(`mtusr${0}type`);


        //     localStorage.setItem(`access_token`, bufferaccess);
        //     localStorage.setItem(`refresh_token${0}`, bufferrefresh);
        //     localStorage.setItem(`mtusr${0}`, bufferuser)
        //     localStorage.setItem(`mtusr${0}type`, buffertype)

        //     localStorage.setItem(`access_token${k}`, bufferaccess0);
        //     localStorage.setItem(`refresh_token${k}`, bufferrefresh0);
        //     localStorage.setItem(`mtusr${k}`, bufferuser0)
        //     localStorage.setItem(`mtusr${k}type`, buffertype0)
        //     window.location.reload()

        // }



    }
    // let tt = {
    //     "firstname": "Harkian",
    //     "username": "harkians",
    //     "email": "squadbreaker@gmail.com",
    //     "is_business_profile": true,
    //     "surname": null,
    //     "country_code": null,
    //     "phone": null,
    //     "id": 58,
    //     "bio": null,
    //     "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/default-profile.png",
    //     "cover_photo": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/cover/default-profile-cover.png",
    //     "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/default-profile-thumbnail-100x100.png",
    //     "cover_photo_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/cover/default-profile-cover-thumbnail-300x300.png",
    //     "email_verified": false,
    //     "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY0NDMyMDAzOSwianRpIjoiMzE0Yzc4ODkyZDc2NGE1ZDk1MTlhNTM5YTVkNzAyODUiLCJ1c2VyX2lkIjo1OX0.aOd1HU0o4xgd3jvDoU0G6RtQDjdSsAIjKH3soxOUyA8",
    //     "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM5MjIyNDM5LCJqdGkiOiJlZTYxODljYjBmMWE0YWQ0OTE2N2FhYWZiOTUwNWQ2ZSIsInVzZXJfaWQiOjU5fQ.HsB_Jro9hL4vpOkQLAnhMb306S_VyeoW_rpReCmt7ig"
    // }
    const switchprofilessuccess = (res: any) => {
        console.log(res)
        // if (profilestype === 'business') {
        //     let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
        //     let array = [JSON.parse(bufferuser0)].concat(res)
        //     // setstoredbprof(array)
        //     dispatch(businessprofilessuccess(array))
        // } else {
        // setstoredbprof(res)
        //dispatch(businessprofilessuccess(res))
        // }

        let bprofile = res.filter((profiles: any) => {
            let current: any = localStorage.getItem('mtusr')
            let currentpro: any = JSON.parse(current)
            return currentpro.id === profiles.id
        })
        let otherprofile = res.filter((profiles: any) => {
            let current: any = localStorage.getItem('mtusr')
            let currentpro: any = JSON.parse(current)
            return currentpro.id !== profiles.id
        })
        dispatch(businessprofilessuccess(bprofile.concat(otherprofile)))
        // console.log(bprofile)

    }
    const switchprofilesfailed = (err: any) => {
        console.log(err)
        dispatch(businessprofilesfailed(err))
    }
    const fetchbusinessprofiles = (usertoken: any) => {
        let dt: any = localStorage.getItem('pdetails')
        let dtls: any = dt ? JSON.parse(dt) : []
        let token = usertoken !== null ? usertoken : dtls[0].access

        if (businessprofiles.length === 0) {
            dispatch(businessprofilesrequest())
            var config: any = {
                method: 'get',
                url: baseurl + '/api/v1/user/switch-profile/',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            };
            axios(config)
                .then((res: any) => {
                    console.log(res.data)
                    switchprofilessuccess(res.data)
                })
                .catch((error: any) => {
                    // const errorMsg = error.message
                    console.log(error)
                });
            // Api('get', '/api/v1/user/switch-profile/', null, switchprofilessuccess, switchprofilesfailed, history, true, true)
        }
    }


    const ChangeProfileTemp = (data: any) => {



        // let ind = 1;
        // for (let index = 0; index < 4; index++) {
        //     if (localStorage.getItem(`access_token${index}`) === undefined || localStorage.getItem(`access_token${index}`) === null) {
        //         ind = index;
        //         break;
        //     }
        // }

        // let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
        // let bufferrefresh0: any = localStorage.getItem(`refresh_token${0}`);
        // let bufferaccess0: any = localStorage.getItem(`access_token`);
        // let buffertype0: any = localStorage.getItem(`mtusr${0}type`);


        // localStorage.setItem(`access_token`, data.access);
        // localStorage.setItem(`refresh_token${0}`, data.refresh);
        // localStorage.setItem(`mtusr${0}`, JSON.stringify(data))
        // localStorage.setItem(`mtusr${0}type`, 'business')


        // localStorage.setItem(`access_token${ind}`, bufferaccess0);
        // localStorage.setItem(`refresh_token${ind}`, bufferrefresh0);
        // localStorage.setItem(`mtusr${ind}`, bufferuser0)
        // localStorage.setItem(`mtusr${ind}type`, buffertype0)


        
        localStorage.setItem(`access_token`, data.access);
        localStorage.setItem(`refresh_token`, data.refresh);
        localStorage.setItem(`mtusr`, JSON.stringify(data));
        localStorage.setItem(`mtusrtype`, 'business');
        localStorage.setItem('remember', `${true}`);
        window.location.reload()





    }





    const switchprofilesuccess = (res: any) => {
        console.log(res)
        ChangeProfileTemp(res)

    }
    const switchprofilefailed = (err: any) => {
        console.log(err)
    }
    const ChangetoBusiness = (id: any) => {
        // Api('post', '/api/v1/user/business-profile/authenticate/', {
        //     "business_profile": id
        // }, switchprofilesuccess, switchprofilefailed, history, true, true)
        // let token = usertoken !== null ? usertoken : localStorage.getItem('accesstoken0')
        // for (let index = 0; index < 4; index++) {
        //     if (localStorage.getItem(`mtusr${index}type`) === 'personal') {
        //         usertoken = localStorage.getItem(`access_token${index}`)
        //     }
        // }
        // let token: any = usertoken !== null ? usertoken :localStorage.getItem('accesstoken0')
        let token : any = details[0].access

        
        let data = {
            "business_profile": id
        }
        var config: any = {
            method: 'post',
            url: baseurl + '/api/v1/user/business-profile/authenticate/',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };
        axios(config)
            .then((res: any) => {
                console.log(res.data)
                switchprofilesuccess(res.data)
            })
            .catch((error: any) => {
                // const errorMsg = error.message
                console.log(error)
            });
    }


    return (
        <div className='miniProfile'>
            <div className='miniProfileTop'>
                <div className='miniprofileicons'>
                    <div className='miniprofileicon'>
                        <Gear className='cursor' onClick={() => {
                            history.push('/business-account-settings')
                            // history.push('/personalinformation')
                            // localStorage.setItem('mtusr3', JSON.stringify(tt))
                            // localStorage.setItem(`access_token${3}`, "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM5MjIyNDM5LCJqdGkiOiJlZTYxODljYjBmMWE0YWQ0OTE2N2FhYWZiOTUwNWQ2ZSIsInVzZXJfaWQiOjU5fQ.HsB_Jro9hL4vpOkQLAnhMb306S_VyeoW_rpReCmt7ig");
                            // localStorage.setItem(`refresh_token${3}`, "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY0NDMyMDAzOSwianRpIjoiMzE0Yzc4ODkyZDc2NGE1ZDk1MTlhNTM5YTVkNzAyODUiLCJ1c2VyX2lkIjo1OX0.aOd1HU0o4xgd3jvDoU0G6RtQDjdSsAIjKH3soxOUyA8");
                            // localStorage.setItem(`mtusr${3}type`, 'business');



                        }} />

                    </div>
                    <div className='miniprofileicon' ref={vis} onClick={() => {

                        if (!isActive) {
                            setIsActive(!isActive)
                            
                            // let users = [];
                            // for (let index = 0; index < 4; index++) {
                            //     if (localStorage.getItem(`mtusr${index}`)) {
                            //         let data: any = localStorage.getItem(`mtusr${index}`)
                            //         let user: any = JSON.parse(data)
                            //         users.push(user)
                            //     }
                            //     if (localStorage.getItem(`mtusr${index}type`) === 'personal') {
                            //         usertoken = localStorage.getItem(`access_token${index}`)
                            //     }
                            // }
                            // setstoredprof(users)


                            // fetchbusinessprofiles(details[0].access)





                        } else {
                            setIsActive(!isActive)
                            // setstoredprof([])
                        }
                    }}>
                        <Selectprofile />
                        <div className={`dropdown profileselectdropdown ${isActive ? 'active' : 'inactive'}`}>
                            <span></span>
                            <ul>
                            <li style={{display:'flex',justifyContent:'space-between'}}><small>Personal profiles</small>
                            <small className='cursor' style={{color:'var(--cl_orange)'}} onClick={()=>{
                                history.push('/personal-account')
                            }}>+ Add personal profile</small></li>
                            <Scrollbars style={storedprof.length > 3 ? { height: '216px' } : storedprof.length > 1 ? { height: '144px' } : { height: '72px' }} autoHide>
                                {
                                    storedprof.length === 0 ? (
                                        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><small>no profiles found</small></li>
                                    ) : (
                                        storedprof.map((user: any, key: any) => {
                                            let { id,dp_thumbnail, username, firstname, surname, is_business_profile } = user;
                                            let crnt: any = localStorage.getItem('mtusr')
                                            let current = JSON.parse(crnt)
                                            return (is_business_profile ? null :
                                                <li className='profileslistitem' onClick={() => {
                                                    if(!(current.id === id)){
                                                        ChangeProfile(key, 'personal')
                                                    }
                                                    
                                                }}>
                                                    <img src={dp_thumbnail} alt='' />
                                                    <div className='profilelistitemcontent'>
                                                        <div className='profilelistitemdetails'>
                                                            <h5>{firstname + " " + surname}</h5>
                                                            <small>{'@' + username}</small>
                                                        </div>
                                                        <div className={(current.id === id) ? 'roundedcheckbox roundedcheckboxactive' : 'roundedcheckbox'}>
                                                            <div className='roundedcheckboxdot'>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    )
                                }
                                </Scrollbars>
                                <li style={{display:'flex',justifyContent:'space-between'}}><small>Business profiles</small>
                            <small className='cursor' style={{color:'var(--cl_orange)'}} onClick={()=>{
                                history.push('/business-account')
                            }}>+ Create business account</small></li>
                            <Scrollbars style={businessprofiles.length > 3 ? { height: '216px' } : businessprofiles.length > 1 ? { height: '144px' } : { height: '72px' }} autoHide>
                                {
                                    businessprofiles.length === 0 && businessloading === false ? (
                                        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><small>no profiles found</small></li>
                                    ) : (
                                        businessprofiles.map((user: any, key: any) => {
                                            let { id, dp_thumbnail, dp, username, firstname, is_business_profile } = user;
                                            // !is_business_profile ? null :
                                            let crnt: any = localStorage.getItem('mtusr')
                                            let current = JSON.parse(crnt)
                                            return (
                                                <li className='profileslistitem' onClick={() => {
                                                    // ChangeProfile(key, 'business')
                                                    if (!(current.id === id)) {
                                                        ChangetoBusiness(id)
                                                    }
                                                }}>
                                                    <img src={dp_thumbnail ? dp_thumbnail : dp} alt='' />
                                                    <div className='profilelistitemcontent'>
                                                        <div className='profilelistitemdetails'>
                                                            <h5>{firstname}</h5>
                                                            <small>{'@' + username}</small>
                                                        </div>
                                                        <div className={(current.id === id) ? 'roundedcheckbox roundedcheckboxactive' : 'roundedcheckbox'}>
                                                            <div className='roundedcheckboxdot'>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    )
                                }
                                {
                                    businessloading === true && businesserror === null ? <li className='profileslistitem '>
                                        <div className='skeleton' style={{ width: '48px', height: '48px', borderRadius: '12px' }}></div>
                                        <div className='profilelistitemcontent'>
                                            <div className='profilelistitemdetails'>
                                                <h5 className='maxwid60 minhe10 skeleton' style={{ marginBottom: '10px' }}></h5>
                                                <small className='minwid60 minhe10 skeleton'></small>
                                            </div>
                                        </div>
                                    </li> : null
                                }
                                </Scrollbars>

                                {/* {
                                [...Array(3)].map((_,key)=>{
                                    if(localStorage.getItem(`mtusr${key}`)){
                                        let data:any = localStorage.getItem(`mtusr${key}`)
                                        let user = JSON.parse(data)
                                        // const {access} = user
                                        console.log(user)
                                        return(key)
                                    }else{
                                        console.log(key,'no')
                                        return null
                                    }
                                    
                                })
                            } */}
                                {/* <li></li>
                                <li></li> */}


                            </ul>
                        </div>
                    </div>

                </div>

                <div className='publicStatus'>
                    <span>Public</span>
                    <div className={!ploading ? (active ? 'profileToggle profileToggleActive' : 'profileToggle profileToggleInactive') : 'profileToggle toggleload'} onClick={
                        () => {
                            ChangeStat();
                        }
                    }>
                        <div className='profileToggleDot'></div>
                    </div>
                    <div className={popActive ? 'errorMessagePop errorMessagePopActive' : 'errorMessagePop errorMessagePopInActive'}>
                        <div className='errorMessageWrapper'>
                            <div className='errorMessageTriangle'></div>
                            <small>{perror?.head}</small>
                            <small>{perror?.message}</small>
                        </div>

                    </div>
                </div>
            </div>
            <div className='miniProfileMid'>
                {
                    loading === false && error === null ? (
                        <div className='profileDp'>
                            <img src={profile.dp} alt="P_I_C"></img>
                        </div>
                    ) : (
                        <div className='profileDp skeleton'>
                        </div>
                    )
                }
                {
                    loading === false && error === null ? (
                        <div className='miniProfileSub'>
                            <h2>{profile.firstname + ' ' + profile.surname}</h2>
                            <span>{'@' + profile.username}</span>
                        </div>
                    ) : (
                        <div className='miniProfileSub'>
                            <h2 className='skeleton minwid100 minhe20'></h2>
                            <span className='skeleton minwid60 minhe10'></span>
                        </div>
                    )
                }


            </div>
            <div className='popularity'>
                <div className='col' onClick={() => { handleFollowers() }}>
                    <h3>{profile.followers_count}</h3>
                    <div className='pop'>
                        <Followers />
                        <span>Followers</span>
                    </div>
                </div>
                <div className='col' onClick={() => { handleFollowing() }}>
                    <h3>{profile.following_count}</h3>
                    <div className='pop'>
                        <Following />
                        <span>Following</span>
                    </div>
                </div>
                <div className='col' onClick={() => { handleMyEvents() }}>
                    <h3>{profile.total_events}</h3>
                    <div className='pop'>
                        <Calendar />
                        <span>Events</span>
                    </div>
                </div>
            </div>
            <div className='btnContainer'>
                <button className='mainBtnInv pointer'
                    onClick={() => { history.push('/personalinformation') }}
                >Edit profile</button>
                {/* <button className='mainBtnInv freeze'>Go live</button> */}
                {/* <button className='mainBtnInv pointer'>My events</button> */}
            </div>
        </div>
    )
}

export default MiniProfile
