import { ChangeEvent, Key, useRef, useState } from "react";
import ModalLayout from "../../../utils/ModalLayout";
import { BsPlusCircleFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Api from "../../../../app/api";
import { useHistory } from "react-router-dom";

const CustomFormAttend = ({
  open,
  handleClose,
  setInputFields,
  inputFields,
  event,
  Refresh
}: any) => {
  const [openadd, setopenadd] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);

  const history = useHistory()

  const [submitData, setsubmitData] = useState<any>({});

  const handleCloseAdd = () => {
    setopenadd(false);
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    let data = { ...submitData };
    data[event.target.name] = event.target.value;

    console.log("data", data);
    setsubmitData(data);
  };

  let unparseddetails: any = localStorage.getItem('pdetails');
  let parseddetails = JSON.parse(unparseddetails);
  let currentunparsed: any = localStorage.getItem('mtusr');
  let currentparsed = JSON.parse(currentunparsed);

  const submit = async(e: any) => {
    e.preventDefault();
    setloading(true)

    const data = {
        "event":event.id,
        "email":submitData.Email,
        custom_form:submitData
     }

    const success = () => { 
        Refresh()
        handleClose()
        setsubmitData({})
        reset()
     }
    const failed = () => {

      }


    let theurl = `/api/v1/event/add-attendees/`;
    if (unparseddetails) {
        if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/add-attendees/?auth_profile=${parseddetails[0].id}`;
        }
    }


    Api('post', theurl, data, success, failed, history, true, true);

    console.log("submitData", submitData);
  };

  const removeInput = (index: number) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };


  const formRef : any = useRef()
  const reset = () => { 
    formRef.current.reset();
   }

 
  return (
    <>
      <ModalLayout open={open} handleClose={handleClose}>
        <div className="tw-flex tw-flex-col tw-p-5 tw-gap-5 tw-w-96">
          <div className="">
            <form ref={formRef}>
              <div className="tw-flex tw-justify-between pb-4 tw-items-center">
                <h4 className="tw-text-2xl font-semibold">Form </h4>
                <BsPlusCircleFill
                  color="var(--cl_orange)"
                  className="tw-cursor-pointer"
                  size={20}
                  onClick={() => setopenadd(true)}
                />
              </div>

              {inputFields.map(
                (input: any, index: number) => {
                  return (  
                    <div
                      key={index}
                      className=""
                    >
                        <label htmlFor="">
                       { input.name}
                        </label>

                        <div
                      className="tw-flex tw-py-2 gap-2 tw-items-center w-full"

                        >

                      <input
                        className="AddEventSectInput tw-w-full"
                        name={input.name}
                        type={'text'}
                        onChange={(event) => handleFormChange(event)}
                      />
                      {input.required ? null : (
                        <MdDelete
                          className="hover:tw-text-red-500"
                          size={20}
                          onClick={() => removeInput(index)}
                        />
                      )}

</div>

                    </div>
                  );
                }
              )}

              <button className="mainBtn tw-mt-4" onClick={submit}>
                Add Attendee
              </button>
            </form>
          </div>
        </div>
      </ModalLayout>

      <AddFieldModal
        open={openadd}
        handleClose={handleCloseAdd}
        inputFields={inputFields}
        setInputFields={setInputFields}
      />
    </>
  );
};

export default CustomFormAttend;

const AddFieldModal = ({ open, handleClose, setInputFields }: any) => {
  const [name, setname] = useState("");

  const addInput = () => {
    setInputFields((prev: any) => [
      ...prev,
      {
        name,
      },
    ]);
    setname("");

    handleClose();
  };

  return (
    <ModalLayout open={open} handleClose={handleClose}>
      <div className="tw-flex tw-flex-col tw-p-5 tw-gap-5">
        <h4 className="AddEventSectTitle">Custom Form </h4>
        <input
          className="AddEventSectInput"
          placeholder="Name"
          value={name}
          onChange={(e) => setname(e.target.value)}
        />

        <button className="mainBtn" onClick={addInput}>
          Add Field
        </button>
      </div>
    </ModalLayout>
  );
};
