import React, { useState, useEffect, useRef, PureComponent } from 'react'
import ContentModeratorContent from '../main/ContentModeratorContent'
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import ContentPostItem from '../postcontent/ContentPostItem'
import { ReactComponent as Rightarrow } from '../../../../assets/svg/cm/arrowright.svg'
import { ReactComponent as Btnnext } from '../../../../assets/svg/cm/btnnext.svg'
import { ReactComponent as ReportIcon } from '../../../../assets/svg/cm/reportedcontent.svg'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import { AiOutlineDelete } from 'react-icons/ai'

import ContentEventItem from '../eventcontent/ContentEventItem'
import Scrollbars from 'react-custom-scrollbars-2'
import { Bar, Line } from 'react-chartjs-2';
import { ReactComponent as NotFound } from '../../../../assets/svg/development.svg';
import { useHistory } from "react-router-dom";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { ReactComponent as Daily } from '../main/dailyapproved.svg'
import { ReactComponent as Disapp } from '../main/dailydisapproved.svg'

import { ReactComponent as Dailymod } from '../main/dailymoderated.svg'
import { ReactComponent as Esc } from '../main/escalatedcontent.svg'
import { ReactComponent as Weekly } from '../main/weekly.svg'
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,

// } from 'chart.js';
import Api from '../../../../app/api'
import { ReactComponent as Arrowup } from './arrowincrease.svg'
import { ReactComponent as Arrowdown } from './arrowdecrease.svg'
import {
    analyticsmoderatedrequest, analyticsmoderatedsuccess, analyticsmoderatedfailed,
    analyticsrequest, analyticssuccess, analyticsfailed
} from '../store/ContentmoderatorSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import dayjs from 'dayjs'
import AnalyticsmoderatedItem from './AnalyticsmoderatedItem'
import { CircularProgress } from '@material-ui/core'

const ContenModeratorAnalyticsLoader = () => {

    const d = new Date();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];





    const options = {
        responsive: true,
        scales: {
            x: {
                ticks: {
                    callback: (val: any) => val + ` ${month[d.getMonth()]}`

                }
            }
        }
        // plugins: {
        //     legend: {
        //         position: 'top' as const,
        //     },
        //     title: {
        //         display: true,
        //         text: 'Chart.js Line Chart',
        //     },
        // },
    };

    const options1 = {
        responsive: true,
        // plugins: {
        //     legend: {
        //         position: 'top' as const,
        //     },
        //     title: {
        //         display: true,
        //         text: 'Chart.js Line Chart',
        //     },
        // },
    };

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} fontSize={11} color={'#7D7D8D'} textAnchor="middle" >
                        {payload.value + ` ${month[d.getMonth()]}`}
                    </text>
                </g>
            );
        }
    }

    class CustomizedYAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dx={-10} fontSize={11} color={'#7D7D8D'} textAnchor="middle" >
                        {payload.value}
                    </text>
                </g>
            );
        }
    }
    const CustomizedDot = (props: any) => {
        const { cx, cy, stroke, payload, value } = props;
        console.log(payload, value)

        if (payload.date === d.getDate()) {
            return (
                <svg x={cx - 7.5} y={cy - 7.5} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.5" cy="7.5" r="5" fill="white" stroke="#040405" stroke-width="5" />
                </svg>
                // <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
                //   <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
                // </svg>
            );
        }

        return (
            <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
                {/* <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" /> */}
            </svg>
        );
    };
    return (
        <div className='cm_path_one'>
            <div className='cm_analytics_wrapper'>
                <div className='cm_analyticschart_container' style={{ padding: '24px' }}>
                    <div style={{ height: '32px' }}><h3>Your activity</h3></div>
                    <div style={{ display: 'flex', padding: '24px 0 ' }}>
                        <div style={{ height: '50px', display: 'flex', gap: '8px', flexDirection: 'column', padding: '0 1rem 0 0', borderRight: '1px dashed #E2E2F0' }}>
                            <span style={{ fontWeight: 400, color: '#7D7D8D', fontSize: '12px' }}>{'Total users & posts moderated today'}</span>

                            <div style={{ display: 'flex', gap: '.5rem' }}><h1 className='skeleton minhe20' style={{ maxWidth: '70px',minWidth:'30px' }}></h1>

                                <button className='cm_content_statbtn cm_content_statbtn_red skeleton' style={{ minWidth: 'auto' }}>
                                    
                                </button>

                            </div>
                        </div>
                        <div style={{ height: '50px', display: 'flex', gap: '8px', flexDirection: 'column', padding: '0 1rem 0 1rem', borderRight: '1px dashed #E2E2F0' }}>
                            <span style={{ fontWeight: 400, color: '#7D7D8D', fontSize: '12px' }}>Daily moderated content</span>

                            <div style={{ display: 'flex', gap: '.5rem' }}><h1 className='skeleton minhe20' style={{ maxWidth: '70px',minWidth:'30px' }}></h1>

                                <button className='cm_content_statbtn cm_content_statbtn_red skeleton' style={{ minWidth: 'auto' }}>
                                    
                                </button>

                            </div>
                        </div>
                        <div style={{ height: '50px', display: 'flex', gap: '8px', flexDirection: 'column', padding: '0 1rem 0 1rem', borderRight: '1px dashed #E2E2F0' }}>
                            <span style={{ fontWeight: 400, color: '#7D7D8D', fontSize: '12px' }}>Weekly moderated content</span>

                            <div style={{ display: 'flex', gap: '.5rem' }}><h1 className='skeleton minhe20' style={{ maxWidth: '70px',minWidth:'30px' }}></h1>
                                <button className='cm_content_statbtn cm_content_statbtn_green' style={{ minWidth: 'auto' }}>
                                   
                                </button>
                            </div>
                        </div>
                        <div style={{ height: '50px', display: 'flex', gap: '8px', flexDirection: 'column', padding: '0 1rem 0 1rem' }}>
                            <span style={{ fontWeight: 400, color: '#7D7D8D', fontSize: '12px' }}>Monthly moderated content</span>
                            <div style={{ display: 'flex', gap: '.5rem' }}><h1 className='skeleton minhe20' style={{ maxWidth: '70px',minWidth:'30px' }}></h1>
                                <button className='cm_content_statbtn cm_content_statbtn_green' style={{ minWidth: 'auto' }}>
                                   
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='cm_linechart' style={{ minHeight: '200px' }}>
                        <ResponsiveContainer>
                            <AreaChart
                                data={[]}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="10%" stopColor="#32A071" stopOpacity={0.16} />
                                        <stop offset="90%" stopColor="#FFFFFF" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tick={<CustomizedAxisTick />} tickFormatter={(label: any) => label + ` ${month[d.getMonth()]}`} axisLine={false} tickLine={false} />
                                <YAxis axisLine={false} tickLine={false} tick={<CustomizedYAxisTick />} />
                                <Tooltip formatter={(value: any, name: any, props: any) => {
                                    console.log(value, props, name)
                                    return [value, 'No moderated contents']

                                }} labelFormatter={(value: any, name: any) => {
                                    return [`${value + " " + month[d.getMonth()]}`]
                                }} />
                                <Area type='stepBefore' dataKey="moderated_content" stroke="#32A071" fillOpacity={1} fill="url(#colorUv)" dot={CustomizedDot} />
                            </AreaChart>
                        </ResponsiveContainer>
                        {/* <Line className='linechart' options={options} data={data1} style={{ maxHeight: '184px' }} /> */}
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridTemplateRows: 'auto auto', gap: '8px', padding: '24px 0' }}>
                        <div className='cm_infotile' style={{ minHeight: '76px', minWidth: '100%', height: 'auto', width: 'auto' }}>
                            
                            <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                            <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                        </div>
                        <div className='cm_infotile' style={{ minHeight: '76px', minWidth: '100%', height: 'auto', width: 'auto' }}>
                        <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                            <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                        </div>
                        <div className='cm_infotile' style={{ minHeight: '76px', minWidth: '100%', height: 'auto', width: 'auto' }}>
                        <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                            <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                        </div >
                        <div className='cm_infotile' style={{ minHeight: '76px', minWidth: '100%', height: 'auto', width: 'auto' }}>
                        <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                            <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                        </div>
                        <div className='cm_infotile' style={{ minHeight: '76px', minWidth: '100%', height: 'auto', width: 'auto' }}>
                        <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                            <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                        </div>
                        <div className='cm_infotile' style={{ minHeight: '76px', minWidth: '100%', height: 'auto', width: 'auto' }}>
                        <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                            <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                        </div>



                    </div >

                </div>
                <div className='cm_detail_table'>

                    <div className='cm_table_head'>
                        <li>Name</li>
                        <li>Date</li>
                        <li>Status</li>
                        <li>Type</li>
                        <li>Reported</li>
                        <li>Reported by</li>
                        <li>Actions</li>
                    </div>
                    <Scrollbars autoHide >

                        {
                            [...Array(10)].map((_, key) => {
                                return (
                                    <div className={'cm_table_container'}>
                                        <div className='cm_table_item'>
                                            <li >
                                                <div className='skeleton' style={{ height: '40px', width: '40px', borderRadius: '8px' }}></div>
                                                <small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px' }}></small>
                                            </li>
                                            <li><small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px', maxHeight: '10px' }}></small></li>
                                            <li>
                                                <button className='cm_content_statbtn skeleton'>

                                                </button>
                                            </li>
                                            <li >
                                                <small className='skeleton minhe10' style={{ minWidth: '70px', maxWidth: '80px', maxHeight: '10px' }}></small>
                                            </li>
                                            <li>
                                                <button className='cm_content_statbtn skeleton' style={{ minWidth: 'auto' }}>

                                                </button>
                                            </li>
                                            <li className='cm_table_imgoverlapped'>
                                                {
                                                    [...Array(3)].map((_, key: number) => {
                                                        return (
                                                            <div className={`cm_table_usrimg cm_table_usrimg${key} skeleton`} style={{ height: '1rem', width: '1rem', borderRadius: '50%' }} />
                                                        )
                                                    })
                                                }



                                            </li>
                                            <li>
                                                <div className="FeedItemDropdown" >
                                                    <span className='dots'>
                                                        <More />
                                                    </span>

                                                </div>
                                            </li>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Scrollbars>
                </div>

            </div>
        </div>
    )
}

export default ContenModeratorAnalyticsLoader