import React, { useEffect, useState } from 'react'
import Api from '../../../../app/api';
import { useAppDispatch } from '../../../../app/hooks';
import { subscriptionchange } from '../store/eventSlice'
import CircularProgress from '@mui/material/CircularProgress';
import { FiCheck } from 'react-icons/fi'
import { ReactComponent as ConfirmIcon } from '../confirm.svg'
import { ReactComponent as SubscribeIcon } from '../subscribe.svg'
import { ReactComponent as ShareIcon } from '../share.svg'
import { useHistory } from 'react-router-dom';
import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
// import WarningModal from '../../../smallcomponents/modals/Warning'


const EventFooter = ({ permissions, subscribers_count, id, sharemodal }: any) => {
    const { is_subscribed, can_subscribe } = permissions
    console.log(permissions)
    console.log(is_subscribed, 'is_subscribed')
    console.log(can_subscribe, 'can_subscribed')

    // const [apiloading, setapiloading] = useState(false);

    const [loadingbtn, setLoading] = React.useState(false);
    const [successbtn, setSuccess] = React.useState(false);
    // const [subscribedstatus, setsubscribedstatus] = useState(is_subscribed)
    // const [subscribablestatus, setsubscribablestatus] = useState(can_subscribe)
    // const [subscribedcount, setsubscribedcount] = useState(subscribers_count)

    const [open, setopen] = useState(false)
    const [open1, setopen1] = useState(false)
    // const [open2, setopen2] = useState(false)

    const [sc, setsc] = useState<any>(null)
    const [er, seter] = useState<any>(null)
    // const [war, setwar] = useState<any>({
    //     head: 'Follow successfull',
    //     message: 'The user has been added to your following',
    //     button: 'Okay',
    //     button1: 'Cancel',
    //     link: null
    // })

    const history = useHistory()
    const dispatch = useAppDispatch()
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    // const Something = () => {

    // }


    // const timer = React.useRef<number>();
    // const buttonSx = {
    //     ...(successbtn && {
    //         bgcolor: 'rgba(255, 89, 23, 0.08)',
    //         '&:hover': {
    //             bgcolor: '#ff5917',
    //         },
    //     }),
    // };
    // React.useEffect(() => {
    //     return () => {
    //         clearTimeout(timer.current);
    //     };
    // }, []);
    const subsuccess = (response: any) => {
        console.log(response)
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false)
        }, 1000);
        setLoading(false);
        setsc(is_subscribed ? {
            head: 'Unsubscribed from the event',
            message: '',
            button: 'Okay',
            link: null
        } : {
            head: 'Subscribed the event',
            message: '',
            button: 'Okay',
            link: null
        })
        setopen(true)
        dispatch(subscriptionchange({ status: !is_subscribed }))
        // setapiloading(false)


    }
    const subfailed = (data: any) => {
        console.log(data)
        setSuccess(false);
        setLoading(false);
        const {
            status
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            setopen1(false)
            seter(null)
        }, 5000);
        setopen1(true)
        // setTimeout(() => {
        //     console.log('api_timeout')
        //     console.log(subscribedstatus, 'state is_subscribed going to change back')
        //     setsubscribedstatus(!subscribedstatus)
        //     setsubscribedcount(subscribers_count)
        // }, 1500);
        // setapiloading(false)
    }

    const handleButtonClick = () => {
        if (!loadingbtn) {
            console.log(is_subscribed, 'is_subscribed')
            if (is_subscribed) {
                // setapiloading(true)
                console.log('unsubscribe')
                let theurl = `/api/v1/event/subscribe/${id}/`
                if (unparseddetails) {
                    if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/event/subscribe/${id}/?auth_profile=${parseddetails[0].id}`
                    }
                }
                // console.log(subscribedstatus, 'is_subscribed in state')
                Api('delete', theurl, null, subsuccess, subfailed, history, true, true)

                setSuccess(false);
                setLoading(true);

                // setTimeout(() => {
                //     console.log('timeoutnow')
                //     console.log(subscribedstatus, 'state is_subscribed')
                //     setLoading(false);
                //     setSuccess(true)
                //     setsubscribedstatus(false)
                //     setsubscribedcount(subscribedcount - 1)
                // }, 1000);
                // setTimeout(() => {
                //     setSuccess(false)
                // }, 2000);
            } else {
                // setapiloading(true)
                console.log('subsribe')
                // console.log(subscribedstatus, 'is_subscribed in state')
                let theurl = `/api/v1/event/subscribe/`
                if (unparseddetails) {
                    if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/event/subscribe/?auth_profile=${parseddetails[0].id}`
                    }
                }
                let postdata = {
                    "event": id
                }
                Api('post', theurl, postdata, subsuccess, subfailed, history, true, true)
                setSuccess(false);
                setLoading(true);

                // setTimeout(() => {
                //     console.log('timeoutnow')
                //     console.log(subscribedstatus, 'state is_subscribed')
                //     setLoading(false);
                //     setSuccess(true)
                //     setsubscribedstatus(true)
                //     setsubscribedcount(subscribedcount + 1)
                // }, 1000);
                // setTimeout(() => {
                //     setSuccess(false)
                // }, 2000);
            }
        }
    };
    return (
        <div className='eventfooter_icons'>
            {/* <div className='eventfooter_item'>
                <ConfirmIcon className='eventfooter_itemicon' />
                Confirm
            </div> */}
            {(permissions?.is_subscribed || permissions?.can_subscribe) && (
                <div className='eventfooter_item cursor' style={{ position: 'relative' }} onClick={handleButtonClick}>
                    <div className={successbtn ? 'eventSubscriptionBtn eventSubscriptionBtn_active' : 'eventSubscriptionBtn '} >
                        {successbtn ?
                            <FiCheck style={{ color: 'var(--cl_orange', width: '15px' }} /> : <SubscribeIcon />}
                        <div className='eventSubCount' title={`${subscribers_count}`}>
                            {subscribers_count > 99 ? subscribers_count.toString().slice(1) + '..' : subscribers_count}
                        </div>
                    </div>
                    {loadingbtn && (
                        <CircularProgress
                            size={42}
                            sx={{
                                color: '#ff5917',
                                position: 'absolute',
                                top: -1,
                                left: -1,
                                zIndex: 1,
                            }}
                        />
                    )}
                    {is_subscribed ? 'Unsubscribe' : 'Subscribe'}
                </div>
            )}
            <div className='eventfooter_item cursor' onClick={() => {
                sharemodal(true)
            }}>
                <ShareIcon className='eventfooter_itemicon' />
                Share
            </div>
            {open &&
                <SuccessModal
                    open={open}
                    setOpen={setopen}
                    // success={suc}
                    success={sc}
                />
            }
            {open1 &&
                <ErrorModal
                    open={open1}
                    setOpen={setopen1}
                    // success={suc}
                    error={er}
                />
            }
        </div>



    )
}

export default EventFooter