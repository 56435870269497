import {   createSlice,   } from '@reduxjs/toolkit';
import { discoverEventsState } from './interface';

const initialState: discoverEventsState = {
    loading: false,
    discoverEvents: [],
    page: 1,
    pagination: true,
    error: null,
    data: {},
    lat: '',
    lng: '',
};
export const discoverSlice = createSlice({
    name: 'discoverEvents',
    initialState,
    reducers: {
        discoverEventsRequest: (state,action: any) =>{
            if(action.payload.no === 1){
                state.discoverEvents =  []
            }
            state.loading = true;
            state.data = action.payload.data;
            state.page = action.payload.no;
        },
        discoverEventsSuccess: (state,action: any)=>{
            state.loading = false;
            state.discoverEvents = state.page === 1 ? action.payload.data: state.discoverEvents.concat(action.payload.data);
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        discoverEventsFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        },
        discoverlocation: (state,action: any)=>{
            state.lat = action.payload.lat;
            state.lng = action.payload.lng;
        }

    }
})
export const { discoverEventsRequest,discoverEventsSuccess,discoverEventsFailed,discoverlocation } = discoverSlice.actions
export default discoverSlice.reducer