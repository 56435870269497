import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import Api from '../../../app/api'
import SuccessModal from '../../smallcomponents/modals/Success'
import ErrorModal from '../../smallcomponents/modals/Error'
import WarningModal from '../../smallcomponents/modals/Warning'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import NotificationItem from '../../smallcomponents/notificationitem/NotificationItem'
import './pendingnotifications.css'

const PendingNotifications = () => {
    const history = useHistory()
    let p: any = []
    const [pending, setpending] = useState(p)
    const [loading,setloading] = useState(false)
    const[pagination,setpagination] = useState(false)
    const [page,setpage]=useState(1)

    const success = (data: any) => {
        console.log(data)
        setpending(data)
    }
    const failed = (data: any) => {
        console.log(data)

    }
    useEffect(() => {
        Api('get', '/api/v1/notification/pending/invitations/1/10/',null, success, failed, history, true, true)
    }, [])


    const observer: any = useRef()

    const notificationsRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading) {
                    // dispatch(notificationsRequest())
                    // Api('get', `/api/v1/notification/${page}/10/`, null, success, failed, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [pending, loading])


    return (
        <div className='notificationSettings minvh100'>
            {/* <div className='container pendingNotifications minvh100'> did for making it inside notifications */}
            <div className='commonHeading'>
                <small onClick={() => {
                    history.push('/notifications')
                }}><Arrow />Back to previous page</small>
                <h2>Pending invitations</h2>
            </div>
            <div className='notificationPageWrapper'>
            
                {

                    !(Object.keys(pending).length === 0) ? (
                        <div className='notificationList'>
                            {
                                pending.map((item: any, key: number) => {
                                    if (pending.length === key + 1) {
                                        return (

                                            <div
                                                ref={notificationsRef}>
                                                <NotificationItem notification={item} k={key} type={'pending'} close={false}/>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            // <div></div>
                                            <NotificationItem notification={item} k={key} type={'pending'} close={false}/>
                                        );
                                    }
                                })
                            }
                        </div>
                    ) : (
                        !loading &&
                        <div className='nodatacontainer' style={{ textAlign: 'center' }}>
                            <div className='nodataicon'>
                                {/* <HiOutlineBan style={{ color: 'var(--cl_orange)', height: '14px', width: '14px' }} /> */}
                            </div>
                            <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                                There’s nothing to tell you… yet!
                            </div>
                            <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                                All your event notifications can be found here, including event
                                invitations, RSVPs, posts to your events, comments on your
                                events and posts, follow requests, and event approvals.
                            </div>
                        </div>
                    )

                }
                {
                    loading && (
                        <div className='notificationList'>
                            {
                                [...Array(10)].map(() => {
                                    return <div className='notificationItem skeleton'></div>
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PendingNotifications
