import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Api from '../../app/api';
import { useHistory } from 'react-router-dom';
// import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
// import { baseurl } from '../../../../Data/Data'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function LoginPopup({ open3, setOpen3, war, setStripeModal }: any) {
    const history = useHistory();
    // const dispatch = useAppDispatch()

    const handleClose = () => {
        setOpen3(false);
    };
    const linkAccount = () => {


    }
    const [open, setOpen] = React.useState(false);

    return (
        <Dialog
            open={open3}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >        <div style={{ border: "none", padding: "20px 20px", borderColor: "white", }}>

                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {/* {war?.message} */}Sign in to Mutual Events to explore! 
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions style={{ borderColor: "white", backgroundColor: "white" }}> */}
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <button className='mainBtn' style={{
                        minWidth: '100px', backgroundColor: "white", color: "black", border: '1px solid var(--cl_lightgrey2)',
                    }} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                        handleClose()

                    }}>Cancel</button>
                    <button className='mainBtn' style={{ minWidth: '100px' }} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                        history.push('/login')
                    }}>Login</button>
                </div>


                {/* </DialogActions> */}
            </div>

        </Dialog>

    )
}

export default LoginPopup