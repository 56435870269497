import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
//components
import NotificationDropdown from './notification/NotificationDropdown';
import SettingsDropdown from './settings/SettingsDropdown';


import { ReactComponent as Search } from '../../assets/svg/mainsearch.svg';
import Logo from '../../assets/logo/logomain.png'
import Api from '../../app/api';
import './topbar.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  topSearchEventsRequest, topSearchEventsFailed, topSearchEventsSuccess,
  topSearchPeopleFailed, topSearchPeopleRequest, topSearchPeopleSuccess,
  topSearchBusinessRequest, topSearchBusinessSuccess, topSearchBusinessFailed,
  topSearchEventsClear, topSearchBusinessClear, topSearchPeopleClear
} from '../pages/topbarsearch/store/TopSearchSlice';
import { clearAdd } from '../Tabs/addevent/store/addEventSlice';
import Eventitem from './Eventitem';
import Peopleitem from './Peopleitem';
import { IoCloseOutline } from 'react-icons/io5';
import axios from 'axios';
import { baseurl } from '../../Data/Data';

// ps: the search history does not retain business profile history only personal profiles
//     business profile history is cleared after switching with other profiles

const Searchdropdown = () => {
  const history = useHistory();
  const location = useLocation()

  const [active, setactive] = useState(false)
  const [value, setvalue] = useState<any>('')
  const [visible, setvisible] = useState(false);
  const dispatch = useAppDispatch()
  const {
    loading1,
    topSearchEvents,
    page1,
    pagination1,
    error1,
    loading2,
    topSearchPeople,
    page2,
    pagination2,
    error2,
    loading3,
    topSearchBusiness,
    page3,
    pagination3,
    error3,
  } = useAppSelector(state => state.topSearch)
  const { lat, lng } = useAppSelector(state => state.profile)

  const { isAuth } = useAppSelector((state) => state.login);


  // useEffect(() => {
  //   if (value === '') {
  //     setvisible(false)
  //   } else {
  //     handleSearch();
  //     if (location.pathname.includes('/search')) {
  //       setvisible(false)
  //     } else {
  //       setvisible(true)
  //     }

  //   }
  // }, [value])








  let unparseddetails: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(unparseddetails)
  let currentunparsed: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(currentunparsed)
  console.log(currentparsed)
  console.log(parseddetails)

  // let searchHistory: any = currentparsed.search_history


  const success = (data: any) => {
    let paginationStat = data.length < 12 ? false : true
    dispatch(topSearchEventsSuccess({
      data: data,
      no: 2,
      pagination: paginationStat,
      value: value,
      lat: lat,
      lng: lng
    }))
    console.log(data)
  }
  const failed = (data: any) => {
    console.log(data)
    dispatch(topSearchEventsFailed(data))
  }
  const success1 = (data: any) => {
    let paginationStat = data.length < 12 ? false : true
    dispatch(topSearchPeopleSuccess({
      data: data,
      no: 2,
      pagination: paginationStat,
      value: value,
      lat: lat,
      lng: lng
    }))
    console.log(data)
  }
  const failed1 = (data: any) => {
    console.log(data)
    dispatch(topSearchPeopleFailed(data))
  }
  const handleSearch = () => {
    let data = {
      search_type: "events",
      keyword: value,
      latitude: lat,
      longitude: lng,
    };
    let data1 = {
      search_type: "people",
      keyword: value,
      latitude: lat,
      longitude: lng,
    };
    let data3 = {
      search_type: "business",
      keyword: value,
      latitude: lat,
      longitude: lng,
    };
    if (value.length !== 0) {


      let theurl = `/api/v1/search/no-auth/${1}/12/`


      if (isAuth) {
        theurl = `/api/v1/search/${1}/12/`
      }

      if (unparseddetails) {
        if (currentparsed.is_business_profile) {

          theurl = `/api/v1/search/no-auth/${1}/12/?auth_profile=${parseddetails[0].id}`

          if (isAuth) {
            theurl = `/api/v1/search/${1}/12/?auth_profile=${parseddetails[0].id}`

          }
        }

      

      }
      dispatch(topSearchEventsRequest({
        value: value,
        newornot: true,
      }))
      Api('post', theurl, data, success, failed, history, isAuth ? true : false, true)
      dispatch(topSearchPeopleRequest({
        value: value,
        newornot: true,
      }))
      Api('post', theurl, data1, success1, failed1, history, isAuth ? true : false, true)
      dispatch(topSearchBusinessRequest({
        value: value,
        newornot: true,
      }))
      Api('post', theurl, data3, success3, failed3, history, isAuth ? true : false, true)


    }
    // setvalue('');
  }




  useEffect(() => {
    if (value === '') {
      // setvisible(false)

      dispatch(topSearchPeopleClear())
    } else {
      if (location.pathname.includes('/search')) {
        setvisible(false)
      } else {
        setvisible(true)
      }
      // handleSearch();
      dispatch(topSearchPeopleRequest({
        value: value,
        newornot: true,
      }))
      const request = axios.CancelToken.source()
      let data;
      console.log("searching user")
      data = {
        search_type: "people",
        keyword: value,
        latitude: lat,
        longitude: lng,
      };
      const token = localStorage.getItem(`access_token`);
      let theurl = `/api/v1/search/no-auth/${1}/12/`

      if(isAuth){
         theurl = `/api/v1/search/1/12/`

      }

      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/search/no-auth/${1}/12/?auth_profile=${parseddetails[0].id}`

          if(isAuth){
            theurl = `/api/v1/search/1/12/?auth_profile=${parseddetails[0].id}`

          }

        }

      }
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization':  isAuth ?  'Bearer ' + token :"No Auth"
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success1(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed1(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed1({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed1({
                status: null,
                text: 'Network error',
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()

    }
  }, [value]);

  useEffect(() => {
    if (value === '') {
      // setvisible(false)
      dispatch(topSearchEventsClear())
    } else {
      if (location.pathname.includes('/search')) {
        setvisible(false)
      } else {
        setvisible(true)
      }
      dispatch(topSearchEventsRequest({
        value: value,
        newornot: true,
      }))
      const request = axios.CancelToken.source()
      let data;
      console.log("searching user")
      data = {
        search_type: "events",
        keyword: value,
        latitude: lat,
        longitude: lng,
      }
      const token = localStorage.getItem(`access_token`);

      let theurl = `/api/v1/search/no-auth/${1}/12/`

     
      if(isAuth){
        theurl = `/api/v1/search/1/12/`

     }


  
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/search/no-auth/${1}/12/?auth_profile=${parseddetails[0].id}`

          if(isAuth){
            theurl = `/api/v1/search/1/12/?auth_profile=${parseddetails[0].id}`

          }

        }

      }
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization':  isAuth ?  'Bearer ' + token :"No Auth"

        },
        data: data
      };


      axios(config)
        .then((res) => {
          success(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed({
                status: null,
                text: 'Network error',
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()

    }
  }, [value]);





  const success3 = (data: any) => {
    let paginationStat = data.length < 12 ? false : true
    dispatch(topSearchBusinessSuccess({
      data: data,
      no: 2,
      pagination: paginationStat,
      value: value,
      lat: lat,
      lng: lng
    }))
    console.log(data)
  }
  const failed3 = (data: any) => {
    console.log(data)
    dispatch(topSearchBusinessFailed(data))
  }


  useEffect(() => {
    if (value === '') {
      // setvisible(false)
      dispatch(topSearchBusinessClear())

    } else {
      if (location.pathname.includes('/search')) {
        setvisible(false)
      } else {
        setvisible(true)
      }
      // handleSearch();
      dispatch(topSearchBusinessRequest({
        value: value,
        newornot: true,
      }))
      const request = axios.CancelToken.source()
      let data;
      console.log("searching businessuser")
      data = {
        search_type: "people",
        keyword: value,
        latitude: lat,
        longitude: lng,
      };
      const token = localStorage.getItem(`access_token`);
      // let theurl = `/api/v1/search/1/12/?search_for=business`

      let theurl = `/api/v1/search/no-auth/${1}/12/?search_for=business`

     
      if(isAuth){
        theurl = `/api/v1/search/1/12/?search_for=business`

     }
      if (unparseddetails) {
        if (currentparsed.is_business_profile) {
          theurl = `/api/v1/search/no-auth/1/12/?search_for=business&auth_profile=${parseddetails[0].id}`
          if(isAuth){
          theurl = `/api/v1/search/1/12/?search_for=business&auth_profile=${parseddetails[0].id}`

          }
        }

      }
      var config: any = {
        method: 'post',
        url: baseurl + theurl,
        cancelToken: request.token,
        headers: {
          'Authorization':  isAuth ?  'Bearer ' + token :"No Auth"

          
        },
        data: data
      };


      axios(config)
        .then((res) => {
          success3(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed3(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed3({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed3({
                status: null,
                text: 'Network error',
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [value]);
















  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      console.log('enter press here! ')
      setvisible(false)
      // handleSearch()
      history.push('/search')

    }
  }

  const vis: any = useRef()
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      let targt: any = vis.current
      if (visible && targt && !targt.contains(e.target)) {
        setvisible(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [visible])
  return (
    // <div className='mainsearchwrapper'>
    <div className='mainSearch' onClick={e => { setactive(!active) }} ref={vis}>
      <input type='text' placeholder='Search' value={value} onChange={(e) => {
        let target = e.currentTarget
        setvalue(target.value)
      }} className={!active ? 'search active' : 'search'}
        onKeyPress={
          e => {
            handleKeyPress(e)
          }
        }
        onFocus={(e) => {
          if (location.pathname.includes('/search')) {
            setvisible(false)
          } else {
            //search history
            setvisible(true)
            console.log(currentparsed)
            console.log(parseddetails)
            // if (e.currentTarget.value !== '') {
            //   setvisible(true)
            // }
          }

        }}></input>
      {value === '' ? (<Search className='cursor' onClick={
        (e: any) => {
          if (value.length !== 0) {
            handleKeyPress(e)
            handleSearch()
          }
        }
      } />) : (
        <IoCloseOutline className='cursor' onClick={
          e => {
            setvalue('')
          }
        } />
      )}

      <div className={`mainsearchdropdown ${visible ? 'active' : 'inactive'}`} onClick={(e) => {
        e.preventDefault();

        setvisible(false)
      }}

      >
        <span className='searchheadings'>Events</span>
        {
          (loading1 === false && error2 === null) ? (
            !(Object.keys(topSearchEvents).length === 0) ? (
              topSearchEvents.slice(0, 3).map((item: any) => {
                return <Eventitem event={item} is_history={false} />
              })
            ) : (
              value === '' ? (
                currentparsed && currentparsed['event_history'] ? (
                  !(Object.keys(currentparsed['event_history']).length === 0) ? (
                    currentparsed['event_history'].slice(0, 3).map((item: any) => {
                      return <Eventitem event={item} is_history={true} />
                    })
                  ) : (
                    <p className='searchnoresults'>No recent history found...</p>
                  )
                ) : (
                  <p className='searchnoresults'>No recent history found...</p>
                )
              ) : (
                <p className='searchnoresults'>No results found...</p>
              )
            )
          ) : (loading1 === true && error2 === null) ? (
            [...Array(3)].map(() => {
              return (
                <div className='eventitem '>
                  <div className='eventitemimgloader skeleton' />
                  <div className='eventitemcontent'>
                    <h6 className='skeleton minhe20 minwid60'></h6>
                    <div className='eventitemuser'>
                      <div className='eventitemuserimgloader skeleton' />
                      <div className='eventitemuserdetails'>
                        <span className='skeleton minhe10 minwid150 maxwid150'></span>

                      </div>
                    </div>
                  </div>
                </div>)
            })
          ) : (
            <p className='searchnoresults'>check your internet connection</p>
          )
        }
        <span className='searchheadings'>People</span>
        {
          (loading2 === false && error2 === null) ? (
            !(Object.keys(topSearchPeople).length === 0) ? (
              topSearchPeople.slice(0, 3).map((item: any) => {
                return <Peopleitem people={item} is_business={false} is_history={false} />
              })
            ) : (
              value === '' ? (
                currentparsed && currentparsed['people_history'] ? (
                  !(Object.keys(currentparsed['people_history']).length === 0) ? (
                    currentparsed['people_history'].slice(0, 3).map((item: any) => {
                      return <Peopleitem people={item} is_business={false} is_history={true} />
                    })
                  ) : (
                    <p className='searchnoresults'>No recent history found...</p>
                  )
                ) : (
                  <p className='searchnoresults'>No recent history found...</p>
                )
              ) : (
                <p className='searchnoresults'>No results found...</p>
              )
            )
          ) : (loading2 === true && error2 === null) ? (
            [...Array(3)].map(() => {
              return (
                <div className='peopleitem '>
                  <div className='peopleitemimgloader skeleton' />
                  <h6 className='skeleton minhe20 minwid60'></h6>
                  <span className='skeleton minhe10 minwid60 maxwid60'></span>
                </div>)
            })
          ) : (
            <p className='searchnoresults'>check your internet connection</p>
          )
        }
        <span className='searchheadings'>Business</span>
        {
          (loading3 === false && error3 === null) ? (
            !(Object.keys(topSearchBusiness).length === 0) ? (
              topSearchBusiness.slice(0, 3).map((item: any) => {
                return <Peopleitem people={item} is_business={true} is_history={false} />
              })
            ) : (
              value === '' ? (
                currentparsed && currentparsed['business_history'] ? (
                  !(Object.keys(currentparsed['business_history']).length === 0) ? (
                    currentparsed['business_history'].slice(0, 3).map((item: any) => {
                      return <Peopleitem people={item} is_business={true} is_history={true} />
                    })

                  ) : (
                    <p className='searchnoresults'>No recent history found...</p>
                  )
                ) : (
                  <p className='searchnoresults'>No recent history found...</p>
                )
              ) : (
                <p className='searchnoresults'>No results found...</p>
              )
            )
          ) : (loading3 === true && error3 === null) ? (
            [...Array(3)].map(() => {
              return (
                <div className='peopleitem '>
                  <div className='peopleitemimgloader skeleton' />
                  <h6 className='skeleton minhe20 minwid60'></h6>
                  <span className='skeleton minhe10 minwid60 maxwid60'></span>
                </div>)
            })
          ) : (
            <p className='searchnoresults'>check your internet connection</p>
          )
        }

      </div>
    </div >
    // </div>
  )
}

export default Searchdropdown
