import React from "react";
import axios from "axios";
import { getCroppedImg } from "./crop";
import { dataURLtoFile } from "./dataURLtoFile";


import "./style.css";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import WarningIcon from "@material-ui/icons/Warning";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { CircularProgress } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import './style.css'
import { useDispatch } from 'react-redux';
import imageCompression from 'browser-image-compression';
import { update_dp } from "../../Tabs/profile/store/action";
import { update_profile } from "../../Tabs/profile/store/profileSlice";
import { useHistory } from 'react-router-dom';
import Api from "../../../app/api";
import { useAppSelector } from "../../../app/hooks";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: 'rgb(0,0,0,0.7)'
    
  },
  appBar: {
    position: "relative",
    backgroundColor: "white",
  },
  buttonClose: {
    height: "4rem",
    width: "4rem",
    position: "absolute",
    // backgroundColor: 'white',
    color: "red",
    right: "10px",
    zIndex: 100,
  },
  title: {
    marginLeft: theme.spacing(2),
    color: "black",
    flex: 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
  },
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },

}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function FullScreenDialog({
  dialogStatus,
  dialogAction,
//   fetchProfile,
  setChange,
  change,
  currentDp
}:{
    dialogStatus: any,
    dialogAction: any,
    // fetchProfile: any,
    setChange: any,
    change: any,
    currentDp: any,
  }) {
  const classes = useStyles();
  const {openedchatdetails} = useAppSelector(state=>state.chat)

  const handleClose = () => {
    dialogAction(false);
    // setAlert(false)
    setAlerter(false);

    // setImg('')
  };
  let dispatch =  useDispatch()
  let history = useHistory()
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => {
      let target = document.getElementById('userdpedit')
      if(target){
          target.click()
      }
  };

const i: any = null;
  const [image, setImage] = React.useState(i);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [minzoom, setminZoom] = React.useState(1);

  const [loader, setLoader] = React.useState(false);
  const [alerter, setAlerter] = React.useState(false);

  const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const clear = () => {
    dialogAction(false);
    setImage(null);
    setAlerter(false);
  };
  const textalert = () => {
    setAlerter(true);
  };
  const Setitup = (file: any)=>{
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        if(reader){
            setImage(reader.result);
        }
        
      });
}

  const onSelectFile = async (event: any) => {
    console.log("thi sis image", event);
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files && event.target.files.length > 0) {

        if(event.target.files[0].size > 1000000){
        
          console.log('greater')
          // const options = {
          //   maxSizeMB: 1,
          //   maxWidthOrHeight: 800,
          //   useWebWorker: true
          // };
          const nav: any = navigator
          const { userAgentData } = nav
          console.log(userAgentData.platform)
          const options = userAgentData?.platform === 'macOS' ? {
              maxSizeMB: 1,
              // maxWidthOrHeight: 800,
              maxWidthOrHeight: 1392,
              useWebWorker: true
          } : {
              maxSizeMB: 5,
              maxWidthOrHeight: 1920,
              useWebWorker: true
          }
          try {
            const compressedFile = await imageCompression(event.target.files[0], options);
            console.log(compressedFile)
            Setitup(compressedFile)
          } catch (error) {
            console.log(error);
            // alert(error.message)
          }
  
        }else{
          console.log('lesser')
          Setitup(event.target.files[0])
        }

      }
      // const reader = new FileReader();
      // reader.readAsDataURL(event.target.files[0]);
      // reader.addEventListener("load", () => {
      //   setImage(reader.result);
      // });
    }
  };

  const onUpload = async () => {
    if (image) {
      setLoader(true);
      setAlerter(false);

      const form_data = new FormData();

      const canvas = await getCroppedImg(image, croppedArea);
      const canvasDataUrl = canvas.toDataURL("image/jpeg");
      const convertedURLtoFile = dataURLtoFile(
        canvasDataUrl,
        "cropped-image.jpeg"
      );
      console.log("convertedURLtoFile", convertedURLtoFile);
        if(change === 'group'){
          // form_data.append("group_icon", convertedURLtoFile);
          setChange(convertedURLtoFile)
          handleClose()
          // const sucess = (res:any) => {
          //   // dispatch(update_profile(res.data.data))
          //   handleClose()
          // }
          

        }
        else if(change === 'groupicon'){
          const iconsuccess =(success:any)=>{
            console.log(success)
            setChange(success.data.data)
            handleClose()
          }
          const iconerror =(error:any)=>{
            console.log(error)
            // handleClose()
          }
          let thedata = new FormData()
          thedata.append('group_icon',convertedURLtoFile)
          Api('put',`/api/v1/ws/chat/group/icon/${openedchatdetails.chat_id}/`,thedata,iconsuccess,iconerror,history,true,true)
          
          
        }else if(change === 'business'){
          setChange(convertedURLtoFile)
          handleClose()
        }else{
          form_data.append("dp", convertedURLtoFile);
          const sucess = (res:any) => {
            dispatch(update_profile(res.data.data))
            handleClose()
           }
           
          update_dp(history,dispatch,form_data,sucess)
        }
      
      // console.log("form_data", form_data);

      // const token = localStorage.getItem("auth");
      // let config;
      // let url =
      //   "https://51c046c6-73be-447a-a38c-d999464b1b85.mutualevents.co/api/v1/user/edit/dp/";
      // config = {
      //   method: "put",
      //   url: url,
      //   headers: {
      //     Authorization: "Bearer " + token,
      //   },
      //   data: form_data,
      // };



    } else {
      textalert();
    }
  };

  return (
    <div style={{ minHeight: '100vh' ,}}>
      <Modal
        // fullScreen
        open={dialogStatus}
        onClose={handleClose}
        // TransitionComponent={Transition}
        className={classes.modal}
        disableEnforceFocus
      >
        <div 
         style=
         {{ 
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            width: '780px' ,
            height: '520px' , 
            backgroundColor:'white' ,
            borderRadius: '10px',
            gap:'20px',
            padding: '5px',
            fontFamily:"Inter",
            // border : '3px solid green' , 


          
            

           }}>
            <div style= {{   
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // flexDirection: "column",
              width: '780px' ,
              height: '65px' , 
              // backgroundColor:'#f5f5f5' ,
              borderRadius: '10px',
              gap:'20px',
              padding: '5px 30px 5px 30px',
              borderBottom : '1px solid var(--cl_light_grey)' , 

           }}>
             <div className='ProfileName' style={{fontSize: '24px'}}>Update profile photo </div>
             <div className='imageClosed' onClick={handleClose}> 
              <CloseIcon /> 
              </div>
              </div>

                {/* <div className={classes.imgContainer}>
                  <img src={currentDp} alt="Cropped" className={classes.img} />
                </div> */}

                {      
                  image ? <div className={classes.cropContainer}>
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={1}
                              onCropChange={setCrop}
                              onZoomChange={setZoom}
                              onCropComplete={onCropComplete}
                              maxZoom={15}
                              zoomSpeed={0.15}                         
                              onMediaLoaded={(mediaSize) => {                
                                  console.log('mediaSize',mediaSize) 
                        
                              }} 
                              minZoom={minzoom}
                            />

                        </div> : 
                                  <div className="profile-update-dp" style={(change=== 'group' || change === 'business' || change === 'groupicon')?{borderRadius:'50%'}:{}}>
                                    <img src={currentDp} alt="Cropped_image" className="Profile-update-PageDpImg " style={(change=== 'group' || change === 'business'|| change === 'groupicon')?{borderRadius:'50%'}:{}}/>
                                  </div>
                        
                }
             
                 

                  <div style={{ 
                            display:'flex',
                            justifyContent:'flex-end',
                            alignItems: 'center',  
                            gap:'20px', 
                            width:'100%',
                            borderTop:"1px solid var(--cl_light_grey)", 
                            padding : '10px',

                            }}>
                        <input type="file" accept="image/*" id='userdpedit' onChange={onSelectFile} style={{ display: "none" }}  />
                        <button className="mainBtnInv" style={{  width:'105px' , height:'40px' , border:'.5px solid var(--cl_light_grey)'}}  
                        onClick={triggerFileSelectPopup}> Choose </button> 
                        <button className="mainBtn" style={{ width:'105px' , height:'40px', marginRight:'10px', border:'none'}} 
                        onClick={onUpload}>{loader ? <span
                          style={{
                            maxHeight: "13px",
                            fontSize: "13px",
                            overflow: "hidden",
                            color:'white'
                          }}
                        >
                          <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                        </span> :  'Upload'} </button> 
                    </div>

          </div>
     
      </Modal>
    </div>
  );
}
