import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as Thumb } from '../../../assets/svg/thumbup.svg';
import { useHistory } from 'react-router';
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from '@material-ui/core';
import '../../../smallcomponents/modals/reportpost.css'
import Api from '../../../../app/api';
import ErrorModal from '../../../smallcomponents/modals/Error'

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
    const top = 48;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 688,
        minHeight: '400px',
        height: 'auto',
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        borderRadius: '12px',
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 8, 3),
    },
    wrapper: {
        padding: theme.spacing(5, 8, 3),
    }
}));

export default function SimpleModal({ open, setOpen, postid, type }: { open: any, setOpen: any, postid: any, type: string }) {
    const history = useHistory()
    const classes = useStyles();
    let msg: any = ''
    const [reportmessage, setreportmessage] = useState(msg)
    const [loader, setloader] = useState(false)
    const [open1, setopen1] = useState(false)
    let er: any = null
    const [reporterror, seterror] = useState(er)
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    //   const [open, setOpen] = React.useState(false);
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const success = (data: any) => {
        setloader(false)
        handleClose()
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        const {
            status, text, dev_data, app_data,
        } = data;
        setloader(false)
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seterror({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setopen1(true)
        setTimeout(() => {
            setopen1(false)
            seterror(null)

        }, 5000);

    }
    const Report = () => {
        console.log(reportmessage)
        if (reportmessage.length !== 0) {
            seterror(null)
            setloader(true)

            if (type === 'post') {
                let theurl = `/api/v1/post/report/`
                if (unparseddetails) {
                    if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/post/report/?auth_profile=${parseddetails[0].id}`
                    }

                }
                Api('post', theurl, {
                    post: postid,
                    category: reportmessage
                }, success, failed, history, true, true
                )
            } else if (type === 'comment') {
                let theurl = `/api/v1/post/report/comment/`
                if (unparseddetails) {
                    if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/post/report/comment/?auth_profile=${parseddetails[0].id}`
                    }

                }
                Api('post', theurl, {
                    comment: postid,
                    category: reportmessage
                }, success, failed, history, true, true
                )
            } else if (type === 'reply') {
                let theurl = `/api/v1/post/report/reply/`
                if (unparseddetails) {
                    if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/post/report/reply/?auth_profile=${parseddetails[0].id}`
                    }

                }
                Api('post', theurl, {
                    reply: postid,
                    category: reportmessage
                }, success, failed, history, true, true
                )
            } else if (type === 'chat') {
                let theurl = `/api/v1/ws/chat/report/`
                console.log(postid)
                let dt = {
                    "chat": postid,
                    "category": reportmessage
                }
                Api('post', theurl, dt, success, failed, history, true, true
                )
            }
        } else {
            seterror({
                head: 'Please select a problem to report',
                message: 'the post',
            })
            setTimeout(() => {
                seterror(null)
            }, 5000);
        }
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div className={classes.wrapper + ' reportPostWrapper'} >
                <div className='closeBtn' onClick={handleClose}><CloseIcon /></div>
                <h2>Report</h2>
                <div>
                    <h3>Please select a problem</h3>

                    {/* {(reporterror && !(Object.keys(reporterror).length === 0)) ? (
                        <span style={{ color: 'red' }}>
                            {reporterror.head + ' ' + reporterror.message}</span>
                    ) : (
                        <span>if someone is in immediate danger. contact us now!</span>
                    )
                    } */}

                    <ul className='reportlist'>
                        <li className={reportmessage === 'spam' ? "FeedSideBarItemActive" : "FeedSideBarItem"} onClick={e => setreportmessage('spam')}>Spam</li>
                        <li className={reportmessage === 'inappropriate' ? "FeedSideBarItemActive" : "FeedSideBarItem"} onClick={e => setreportmessage('inappropriate')}>Inappropriate</li>
                        <li className={reportmessage === 'violence' ? "FeedSideBarItemActive" : "FeedSideBarItem"} onClick={e => setreportmessage('violence')}>Violence</li>
                        <li className={reportmessage === 'harassment' ? "FeedSideBarItemActive" : "FeedSideBarItem"} onClick={e => setreportmessage('harassment')}>Harassment</li>

                    </ul>
                </div>
                <div className='ReportBtnContainer'>
                    <button className='mainBtn' onClick={Report}>
                        {loader ? <span
                            style={{
                                maxHeight: "13px",
                                fontSize: "13px",
                                overflow: "hidden",
                                color: 'white'
                            }}
                        >
                            <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                        </span> : 'Report'}
                    </button>
                </div>
            </div>
            {open1 &&
                <ErrorModal
                    open={open1}
                    setOpen={setopen1}
                    // success={suc}
                    error={reporterror}
                />
            }
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}
