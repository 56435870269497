import React, { useState } from 'react';
import { ReactComponent as Pin } from '../../assets/svg/pin.svg';
import { ReactComponent as Share } from '../../assets/svg/share.svg';
import { ReactComponent as Comments } from '../../assets/svg/comments.svg';

import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { MdTimer } from 'react-icons/md';

import utc from 'dayjs/plugin/utc';
import { Events } from '../../components/Tabs/profile/store/interface';


const EventTileWL = ({ event }: { event: Events; k: number }) => {
    console.log('=============================== event', event)
    let eventDp = [{
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
    }, {
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
    }, {
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
    }, {
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
    }, {
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
    }, {
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
    }]

    let assignedRole = [{
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
        role: "admin",
        username: "jaimon"
    },
    {
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
        role: "admin",
        username: "jaimon"
    }, {
        dp: "https://images.unsplash.com/photo-1557335200-a65f7f032602?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1179&q=80",
        role: "admin",
        username: "jaimon"
    }]

    const {
        slug,
        id,
        name,
        description,
        latitude,
        longitude,
        event_image,
        profile_dp,
        start_date,
        participants,
        total_rating,
        profile_firstname,
        profile_surname,
        profile_username,
        kilometer,
        profile_id,
        category,
        permissions,
        end_date,
        cancel_message,
        cancel_status,
        canceled_on,
        alternate_profile,
        is_claimable
    } = event;


    return (
        <div className='eventTileBorder'>
            <div
                className='eventTile'
                key={2}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                <div className='eventTileImg' style={{ overflow: 'hidden' }}>

                    {event_image
                        ? event_image.slice(0, 1).map((img, key) => {
                            const { image } = img;
                            return <img key={key.toString()} src={image} className='eventTileImg' alt='Event cover photo' />;
                        })
                        : null}
                    {kilometer === '0.0 KM' || kilometer === undefined ? null : (
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(
                                    `https://www.google.com/maps/search/${latitude},${longitude}/@${latitude},${longitude},17z`,
                                    '_blank'
                                );
                            }}
                            className='eventLocDiv'>
                            <Pin style={{ color: 'var(--cl_orange)' }} />
                            {kilometer ? kilometer : '...' + ' KM'}
                        </div>
                    )}
                </div>
                <div className='eventDescpDiv' style={{ position: 'relative' }}>
                    <div
                        className='eventUsrDiv'
                        onClick={(e) => {

                        }}>
                        <div className='eventUsrDp'>
                            <img src={profile_dp} className='eventUsrDpImg' alt='P_I_C' style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div
                            className='eventUsrSub'
                            style={{ cursor: 'pointer', maxWidth: "50%", overflow: "hidden", textOverflow: "ellipsis", }}
                            onClick={(e) => {

                            }}>
                            {"hsbdsh"}
                        </div>
                        <div className='eventUsrSub'>&#8226;{' ' + dayjs.utc().local().format('DD MMMM YYYY')}</div>
                    </div>
                    <div
                        className='eventUsrSub'
                        style={{ display: 'flex', alignItems: 'center', gap: '5px', maxWidth: '100%', margin: '5px 0 0 0px', fontWeight: 500 }}>
                        {/* <MdTimer style={{ color: '#ff5917' }} /> */}
                    </div>
                    {/* <h3 className="eventDescTitle">
                    {name}
                    </h3> */}
                    <div style={{ maxWidth: "50%", overflow: "hidden", textOverflow: "ellipsis", }} className='eventDescTitle'>Dheeraj</div>

                    {/* <p
                        style={{
                            width: '95%',
                            margin: '5px 0',
                            color: 'rgb(136, 136, 136)',
                            fontSize: '.7rem',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            overflow: 'hidden',
                            textOverflow: "ellipsis"
                        }}>
                        {"this event is a test wjhdfi auh fiuh fih fihf fi efheuhsfiu uheu ihf ihf iuhswufhiuh fuihfiuhew ejie hfiuhs fi  ifh iuhsd"}
                    </p> */}
                    <div style={{ position: "absolute", bottom: "23px", left: "20px" }}> <Comments />   comments</div>
                    <div
                        className='eventfooter'
                        style={{ cursor: 'pointer', position: 'absolute', bottom: '5px', right: '20px' }}
                    // onClick={(e) => { e.preventDefault(); e.stopPropagation(); modalOpen(); setuserDp(image) }}
                    >
                        {/* <div><FaRegComment/> Comments</div> */}
                        {/* <div>hello</div> */}
                        <div
                            className='shareSvg'
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                            <Share />
                        </div>
                        {/* onClick = {Openmodal} */}
                    </div>
                </div>
            </div>
            {/* </Link> */}
            {/* {modal === 'true'? <ShareModalWindow></ShareModalWindow> } */}

            {/* {Modal && <ShareModal
                modalStatus={Modal}
                modalAction={setModal}
                //   ModalLink={ModalLink}
                //   setModalLink={setModalLink}
                userDp={userDp}
                id={theid}
                eventname={eventName}

            />} */}
            {/* {modalActive && <EventShare data={event} open={modalActive} setOpen={setModalActive} type={'event'} />} */}
        </div>
    );
};

export default EventTileWL;
