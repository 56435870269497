import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Modal from '@material-ui/core/Modal';

import { ReactComponent as Select } from '../../assets/svg/select.svg';
import { categoriesFailed, categoriesRequest, categoriesSuccess } from '../smallcomponents/exploreby/store/exploreSlice';
import Api from '../../app/api';
import clock from '../../assets/svg/clock.svg'


const EditCategoriesModal = ({ open, handleClose }: any) => {
    const history = useHistory();
    const dispatch = useAppDispatch()
    const { loading, categories, error } = useAppSelector(state => state.categories)
    const { profile } = useAppSelector(state => state.profile)

    const success = (data: any) => {
        dispatch(categoriesSuccess(data))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(categoriesFailed(data))
    }
    useEffect(() => {
        if (categories.length === 0) {
            dispatch(categoriesRequest())
            Api('put', '/api/v1/event/category/', null, success, failed, history, true, true)
        }


    }, [])



    useEffect(() => {

        const cuurentCategories = () => {
            const cursuccess = (data: any) => {
                console.log(data, "Adfsdasda")
                let curSelectedID = []
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        curSelectedID.push(data[i].id)
                    }
                    setSelectedCategories(curSelectedID)
                }
            }
            const curfailed = (data: any) => {
            }

            Api('get', '/api/v1/event/category/store/', null, cursuccess, curfailed, history, true, true)

        }
        cuurentCategories()
    }, [history])



    const [selectedCategories, setSelectedCategories] = useState<any>([])

    const Add_OR_Remove_Category = (categoryID: any) => {
        console.log("I RUN ")
        let currentCategory: any = [...selectedCategories]
        if (currentCategory.includes(categoryID)) {
            setSelectedCategories((prev: any) => prev.filter((id: any) => id !== categoryID))

        } else {
            currentCategory.push(categoryID)
            setSelectedCategories(currentCategory)
        }
    }


    const SaveInterestedFx = () => {
        let postData = {
            category: selectedCategories
        }
        const suc = (res: any) => {
            // history.push('/')
            handleClose()
        }
        const fail = (err: any) => { }


        Api('post', '/api/v1/event/category/store/', postData, suc, fail, history, true, true)

    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)', backdropFilter: 'blur(10px)',
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '30px', backgroundColor: 'white', padding: "30px", borderRadius: "10px", }}>
                <div className='sub__title'>
                   Choose categories that interests you.
                </div>
                <div style={{ paddingTop: '30px', width: '100%' }}>
                    <div style={{ position: 'relative', display: 'grid', gridTemplateColumns: '1fr 1fr auto', gridGap: "20px", width: '100%' }}>



                        {loading === false && error === null ? (
                            Array.isArray(categories) ? (
                                categories.map((items: any, key: number) => {
                                    const { name, id, category_icon } = items;

                                    return (
                                        // currentstage < reachedstage
                                        // &&
                                        selectedCategories.includes(id) ? (
                                            <div
                                                className='exploreBoxDiv'
                                                style={{ border: '1px solid var(--cl_orange)', position: "relative" }}
                                                key={key}
                                                id={id}
                                                onClick={() => {
                                                    // setcategoryerror(false);
                                                    Add_OR_Remove_Category(id)

                                                    // dispatch(addcategories(id));
                                                    // handletrigger();
                                                }}>
                                                <Select id='select' style={{ display: 'block', transition: "1s ease-in-out", position: 'absolute', top: '6px', right: "6px" }} />
                                                <div className='exploreBoxIcon'>
                                                    <img
                                                        alt='Category icon'
                                                        src={category_icon}
                                                        onError={(e) => {
                                                            const target = e.currentTarget;
                                                            target.onerror = null;
                                                            target.src = clock;
                                                        }}></img>
                                                </div>
                                                <div className='exploreBoxTitle'>{name}</div>
                                            </div>
                                        ) : (
                                            <div
                                                className='exploreBoxDiv'
                                                key={key}
                                                id={id}
                                                onClick={() => {
                                                    // setcategoryerror(false);
                                                    Add_OR_Remove_Category(id)

                                                    // dispatch(addcategories(id));
                                                    // handletrigger();
                                                }}>
                                                <div className='exploreBoxIcon'>
                                                    <img
                                                        alt='Category icon'
                                                        src={category_icon}
                                                        onError={(e) => {
                                                            const target = e.currentTarget;
                                                            target.onerror = null;
                                                            target.src = clock;
                                                        }}></img>
                                                </div>
                                                <div className='exploreBoxTitle'>{name}</div>
                                            </div>
                                        )
                                    );
                                })
                            ) : (
                                <div className="Topsearcherror">
                                    <div >No categories found</div>
                                </div>
                            )
                        ) : (
                            <div style={{ position: 'relative', display: 'grid', gridTemplateColumns: '1fr 1fr auto', gridGap: "20px", width: '100%' }}>
                                {[...Array(8)].map((item, key) => {
                                    return (
                                        <div className="exploreBoxDiv skeleton-loader">
                                            <div className="exploreBoxIcon skeleton"></div>
                                            <div className="exploreBoxTitle skeleton minwid60 minhe10" ></div>
                                        </div>
                                    )
                                })}
                            </div>

                        )
                        }


                        {/* <div className='horizontal_indicator'
            >
                <Arrow style={{stroke:'var(--cl_textgrey)'}}/>
            </div> */}
                    </div>
                </div>
                {/* </div> */}

                <div style={{ display: "flex", alignSelf: 'flex-end', paddingTop: "60px", gap: "25px" }}>
                    <button className='mainBtnInv' style={{ minWidth: '100px' }} onClick={() => handleClose()}>
                        Close
                    </button>
                    <button className='mainBtn' style={{ minWidth: '100px' }} onClick={SaveInterestedFx}>
                        Save
                    </button>
                </div>
            </div>
        </Modal>

    )
}

export default EditCategoriesModal