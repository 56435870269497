import {   createSlice,   } from '@reduxjs/toolkit';
import { NotificationsState } from './interface';

const initialState: NotificationsState = {
  loading: false,
  notifications: [],
  page: 1,
  pagination: true,
  error: null
};
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notificationsRequest: (state) => {
      state.loading = true
    },
    notificationsSuccess: (state, action: any) => {
      state.loading = false;
      // state.notifications = action.payload;
      state.notifications = action.payload.no !== 1 ? state.notifications.concat(action.payload.notifications) : action.payload.notifications;
      state.page = action.payload.no;
      state.pagination = action.payload.pagination;
      state.error = null;
    },
    notificationsFailed: (state, action: any) => {
      state.loading = false;
      state.error = action.payload
    },
    notificationsRefreshRequest: (state) => {
      state.loading = true;
      state.notifications = [];
    },
    notificationRefresh: (state,action: any) =>{
      state.loading = false;
      // state.notifications = action.payload;
      state.notifications = action.payload.notifications;
      state.page = action.payload.no;
      state.pagination = action.payload.pagination;
      state.error = null;
    },
    notificationdelete: (state) =>{
      state.notifications = [];
    }

  }
})
export const { notificationsRequest, notificationsSuccess, notificationsFailed,notificationRefresh,notificationsRefreshRequest,notificationdelete } = notificationsSlice.actions
export default notificationsSlice.reducer