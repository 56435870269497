import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Invitesearch from './Invitesearch';
import InviteItem from './InviteItem';
import { bothStageUpdate,remain3 } from '../store/addEventSlice';
import CommonSlide from '../../../utils/commonSlide';


const Invite = () => {
    const eventData = useAppSelector(state => state.addEvent)
    const { invites, invitesearch, invitesearchload, invitesearchfailed, currentstage, reachedstage } = eventData;
    const dispatch = useAppDispatch();
    // function Handleinvites(id: any) {
    //     dispatch({ type: 'INVITES', id: id })
        
    // }
    useEffect(() => {     
        window.scrollTo(0,0)
    }, [])

    return (
        <CommonSlide>
        <div>
            <div className="AddEventContentTitle">Invite users</div>
            <div className='addeventmaindiv'>
                <div className="invitesect1">
                    <div className="AddEventSectTitle">Name</div>
                    <Invitesearch mode={'invite'} />
                    <small>
                        {invites.length + ' users selected'}
                    </small>
                </div>
                <div className="invitesect2">
                    {invitesearchload === false && invitesearchfailed === null ? (
                        invitesearch !== null
                            // && invitesearch !== 'undefined' 
                            ? (
                                invitesearch.map((item: any, key: number) => {
                                    const {  id } = item;
                                    return (
                                        invites.includes(id)?
                                        <InviteItem user={item} k={key} check={true}/>
                                        :<InviteItem user={item} k={key} check={false}/>
                                    )
                                })
                            ) : "search for users"
                    ) : invitesearchload === true && invitesearchfailed === null ? (
                        [...Array(6)].map((item, key) => {
                            return (<div className="AddEventUserItem skeleton-loader"></div>)
                        })
                    ) : (
                        null
                        // <div className='invitefailed'>no users found</div>
                    )
                    }

                </div>
            </div>
            <div className="AddEventFooter">
                <div className="AddEventCancelBtn" onClick={e => {
                    // dispatch(bothStageUpdate({ currentstage: 3, reachedstage: 3 }))
                    dispatch(remain3())
                }}>Cancel</div>
                {currentstage < reachedstage ? null : <div className="AddEventNextBtn" onClick={e => {
                   dispatch(bothStageUpdate({ currentstage: 7, reachedstage: 7 }))
                }}>Next Step</div>}
            </div>
        </div>
        </CommonSlide>
    )
}

export default Invite
