import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Api from '../../app/api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { categoriesFailed, categoriesRequest, categoriesSuccess } from '../smallcomponents/exploreby/store/exploreSlice';
import Layout from './Layout'
import './login.css'
import clock from '../../assets/svg/clock.svg'

import { ReactComponent as Clock } from '../../assets/svg/clock.svg'
import { ReactComponent as Arrow } from '../../assets/svg/rightarrow.svg';

import { ReactComponent as Select } from '../../assets/svg/select.svg';




const SelectCategories = () => {

    const history = useHistory();
    const dispatch = useAppDispatch()
    const { loading, categories, error } = useAppSelector(state => state.categories)
    const {profile}= useAppSelector(state=>state.profile)

    const success = (data: any) => {
        dispatch(categoriesSuccess(data))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(categoriesFailed(data))
    }
    useEffect(() => {
        if (categories.length === 0) {
            dispatch(categoriesRequest())
            Api('get', '/api/v1/event/category/', null, success, failed, history, true, true)
        }

    }, [])


    const [selectedCategories, setSelectedCategories] = useState<any>([])

	const Add_OR_Remove_Category = (categoryID:any) => { 
		console.log("I RUN ")
		let currentCategory : any =[...selectedCategories]
		if(currentCategory.includes(categoryID)){
			setSelectedCategories((prev:any)=> prev.filter((id:any )=> id !== categoryID))

		}else{
			currentCategory.push(categoryID)
			setSelectedCategories(currentCategory)
		}
	 }


     const SaveInterestedFx = () => { 
        let postData = {
            category : selectedCategories
        }
        const suc = (res:any) => { 
            history.push('/')
         }
        const fail = (err:any) => {  }


        Api('post', '/api/v1/event/category/store/', postData, suc, fail, history, true, true)

      }

    



  return (
    <Layout className='sg'>

    <div style={{ height: '100%'  , width:'100%' , display: 'flex' , flexDirection: 'column' , paddingTop: '30px'}}>
        <div className='sub__title'>
        Select from the below list of categories that interests you. 
        </div>
        <div  style={{ paddingTop: '30px' ,width:'100%'}}>
        <div style={{ position: 'relative',  display: 'grid' , gridTemplateColumns: '1fr 1fr auto' , gridGap:"20px" , width:'100%'}}>
             
                 

                    {loading === false && error === null ? (
            	Array.isArray(categories) ? (
                    categories.map((items: any, key: number) => {
                        const { name, id, category_icon } = items;

                        return (
                            // currentstage < reachedstage
                            // &&
                            selectedCategories.includes(id) ? (
                                <div
                                    className='exploreBoxDiv'
                                    style={{ border: '1px solid var(--cl_orange)'  , position:"relative"}}
                                    key={key}
                                    id={id}
                                    onClick={() => {
                                        // setcategoryerror(false);
                                        Add_OR_Remove_Category(id)

                                        // dispatch(addcategories(id));
                                        // handletrigger();
                                    }}>
                                    <Select id='select' style={{ display: 'block' , transition:"1s ease-in-out" ,position:'absolute' , top:'6px' , right:"6px" }} />
                                    <div className='exploreBoxIcon'>
                                        <img
                                            alt='Category icon'
                                            src={category_icon}
                                            onError={(e) => {
                                                const target = e.currentTarget;
                                                target.onerror = null;
                                                target.src = clock;
                                            }}></img>
                                    </div>
                                    <div className='exploreBoxTitle'>{name}</div>
                                </div>
                            ) : (
                                <div
                                    className='exploreBoxDiv'
                                    key={key}
                                    id={id}
                                    onClick={() => {
                                        // setcategoryerror(false);
                                        Add_OR_Remove_Category(id)

                                        // dispatch(addcategories(id));
                                        // handletrigger();
                                    }}>
                                    <div className='exploreBoxIcon'>
                                        <img
                                            alt='Category icon'
                                            src={category_icon}
                                            onError={(e) => {
                                                const target = e.currentTarget;
                                                target.onerror = null;
                                                target.src = clock;
                                            }}></img>
                                    </div>
                                    <div className='exploreBoxTitle'>{name}</div>
                                </div>
                            )
                        );
                    })
                )  : (
                            <div className="Topsearcherror">
                                <div >No categories found</div>
                            </div>
                        )
                    ) : (
                        <div style={{ position: 'relative',  display: 'grid' , gridTemplateColumns: '1fr 1fr auto' , gridGap:"20px" , width:'100%'}}>
                            {[...Array(8)].map((item, key) => {
                                return (
                                    <div className="exploreBoxDiv skeleton-loader">
                                        <div className="exploreBoxIcon skeleton"></div>
                                        <div className="exploreBoxTitle skeleton minwid60 minhe10" ></div>
                                    </div>
                                )
                            })}
                        </div>

                    )
                    }

                
                {/* <div className='horizontal_indicator'
                >
                    <Arrow style={{stroke:'var(--cl_textgrey)'}}/>
                </div> */}
            </div>
        </div>
        {/* </div> */}

        <div style={{  display:"flex",alignSelf : 'flex-end' , paddingTop:"60px" , gap:"25px"}}>
            <button  className='mainBtnInv' style={{ minWidth: '100px' }} onClick={()=> history.push('/')}>
                Skip
            </button>
            <button className='mainBtn' style={{ minWidth: '100px' }} onClick={SaveInterestedFx}>
                Save
            </button>
        </div>
        
    </div>
    </Layout>
  )
}

export default SelectCategories