import React from 'react'
import Popular from '../popular/Popular'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { useHistory } from 'react-router'

const PopularPage = () => {
    const history = useHistory()
    return (
        <div className='popularPage container'>
            <div className='commonHeading'>
                <small onClick={() => {
                    history.push('/')
                }}><Arrow />Back to home page</small>
                    <span></span>

            </div>
            <Popular mode={'pagination'} />
        </div>
    )
}

export default PopularPage
