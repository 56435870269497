import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader'
import { noData } from '../../../Data/Data'
import EventTile1 from '../../smallcomponents/eventtile/EventTile1'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { errors } from '../../../Data/Error'
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader'
import FeaturedProfileItem1 from '../../smallcomponents/featuredprofileitem/FeaturedProfileItem1'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { topSearchEventsRequest, topSearchEventsFailed, topSearchEventsSuccess, 
    topSearchPeopleFailed, topSearchPeopleRequest, topSearchPeopleSuccess,
    topSearchBusinessRequest, topSearchBusinessSuccess, topSearchBusinessFailed } from '../../pages/topbarsearch/store/TopSearchSlice';
import Api from '../../../app/api'
import './topSearch.css'

const TopBarSearch = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {
        loading1,
        topSearchEvents,
        page1,
        pagination1,
        error1,
        loading2,
        topSearchPeople,
        page2,
        pagination2,
        error2,
        page3,
        pagination3,error3,loading3,topSearchBusiness,
        keyword, lat, lng
    } = useAppSelector(state => state.topSearch)
    const {profile} = useAppSelector(state => state.profile)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    const success = (data: any) => {
        let paginationStat = data.length < 12 ? false : true
        dispatch(topSearchEventsSuccess({
            data: data,
            no: page1 + 1,
            pagination: paginationStat,
            value: keyword,
            lat: lat,
            lng: lng
        }))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(topSearchEventsFailed(data))
    }
    const success1 = (data: any) => {
        let paginationStat = data.length < 12 ? false : true
        dispatch(topSearchPeopleSuccess({
            data: data,
            no: page2 + 1,
            pagination: paginationStat,
            value: keyword,
            lat: lat,
            lng: lng
        }))
        console.log(data)
    }
    const failed1 = (data: any) => {
        console.log(data)
        dispatch(topSearchPeopleFailed(data))
    }

    const success3 = (data: any) => {
        let paginationStat = data.length < 12 ? false : true
        dispatch(topSearchBusinessSuccess({
            data: data,
            no: page3 + 1,
            pagination: paginationStat,
            value: keyword,
            lat: lat,
            lng: lng
        }))
        console.log(data)
    }
    const failed3 = (data: any) => {
        console.log(data)
        dispatch(topSearchBusinessFailed(data))
    }
    let data = {
        search_type: "events",
        keyword: keyword,
        latitude: lat,
        longitude: lng,
    };
    const observer: any = useRef()

    const lastEventRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination1) {
                if (!loading1) {
                    // let pageno = page + 1
                    dispatch(topSearchEventsRequest({
                        value: keyword,
                        lat: lat,
                        lng: lng,
                        newornot: false,
                    }))
                    let theurl = `/api/v1/search/${page1}/12/`
                    if (unparseddetails) {
                      if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/search/${page1}/12/?auth_profile=${parseddetails[0].id}`
                      }
              
                    }
                    Api('post', theurl, data, success, failed, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [topSearchEvents, loading1])



    let data1 = {
        search_type: "people",
        keyword: keyword,
        latitude: lat,
        longitude: lng,
    };
    // const observer: any = useRef()

    const lastelment = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination2) {
                if (!loading2) {
                    // let pageno = page + 1
                    dispatch(topSearchPeopleRequest({
                        value: keyword,
                        lat: lat,
                        lng: lng,
                        newornot: false,
                    }))
                    let theurl = `/api/v1/search/${page2}/12/`
                    if (unparseddetails) {
                      if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/search/${page2}/12/?auth_profile=${parseddetails[0].id}`
                      }
              
                    }
                    Api('post',theurl, data1, success1, failed1, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [topSearchPeople, loading2])


    const lastelment1 = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination3) {
                if (!loading3) {
                    // let pageno = page + 1
                    dispatch(topSearchBusinessRequest({
                        value: keyword,
                        lat: lat,
                        lng: lng,
                        newornot: false,
                    }))
                    let theurl = `/api/v1/search/${page3}/12/?search_for=business`
                    if (unparseddetails) {
                      if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/search/${page3}/12/?search_for=business&auth_profile=${parseddetails[0].id}`
                      }
              
                    }
                    Api('post', theurl, data1, success3, failed3, history, true, true)
                    
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [topSearchBusiness, loading3])

    const Refresh1=()=>{
        dispatch(topSearchEventsRequest({
            value: keyword,
            lat: lat,
            lng: lng,
            newornot: false,
        }))
        let theurl = `/api/v1/search/${page1}/12/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/search/${page1}/12/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, data, success, failed, history, true, true)
    }
    const Refresh2=()=>{
        dispatch(topSearchPeopleRequest({
            value: keyword,
            lat: lat,
            lng: lng,
            newornot: false,
        }))
        let theurl = `/api/v1/search/${page2}/12/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/search/${page2}/12/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, data, success1, failed1, history, true, true)
    }
    const Refresh3=()=>{
        dispatch(topSearchBusinessRequest({
            value: keyword,
            lat: lat,
            lng: lng,
            newornot: false,
        }))
        let theurl = `/api/v1/search/${page3}/12/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/search/${page3}/12/?search_for=business&auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, data, success3, failed3, history, true, true)
    } 
    return (
        <div className='container topBarSearch minvh100'>
            <Switch>
            <Route path='/search/business'>
                    <div className='searchPeople'>
                        <div className='searchHead'>
                            <div className='commonHeading'>
                                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                                <h2>{'Results found'}</h2>
                                <span>People based on your search</span>
                            </div>
                            <div className='searchLinkDiv'>
                                <NavLink className='links searchLink' to='/search/event' activeClassName="searchLinkActive">
                                    Events
                                </NavLink>
                                <NavLink className='links searchLink' to='/search/people' activeClassName="searchLinkActive">
                                    People
                                </NavLink>
                                <NavLink className='links searchLink' to='/search/business' activeClassName="searchLinkActive">
                                    Business
                                </NavLink>
                            </div>
                        </div>
                        <div className="featuredProfilesList">
                        {
                            (Object.keys(topSearchBusiness).length === 0) ? (
                                (loading3 === false && error3 === null) &&
                                <div className='eventGrid'>
                                    <div className='noData'>
                                        <Warning />
                                        <h2>{'What can we help you find, ' + profile.firstname + '?'}</h2>
                                        <span>Just type any keyword into the search bar and dive into the deep, wide world of users!</span>
                                        <div className='loadmore'>
                                            <button className='mainBtn' onClick={() => history.push('')}>Go back to homepage</button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                
                                topSearchBusiness.map((item, key) => {
                                        if (topSearchBusiness.length === key + 1) {
                                            return (
                                                <div ref={lastelment1}>
                                                    <FeaturedProfileItem1 featured={item} k={key} unf={true} place={'none'}/>
                                                </div>
                                            )
                                        } else {
                                            return (<FeaturedProfileItem1 featured={item} k={key} unf={true} place={'none'}/>)
                                        }
                                    })
                                
                            )
                        }

                        {loading3 &&
                            
                            [...Array(6)].map(() => {
                                    return (
                                        <FeaturedProfileLoader />
                                    )
                                })
                            
                        }
                        
                            {
                                error3 !== null && (
                                    error3.status === null ? (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.internet.heading}</h2>
                                            <span>{errors.internet.message}</span>
                                            <button className='mainBtn' onClick={Refresh3}>Refresh the page</button>
                                        </div>

                                    ) : error3.status === 500 ? (

                                        <div className='errormsg min500px'>
                                            <Server />
                                            <h2>{errors.server.heading}</h2>
                                            <span>{errors.server.message}</span>
                                            <button className='mainBtn' onClick={Refresh3}>Try again</button>
                                        </div>

                                    ) : error3.status === 401 ? (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.notFound.heading}</h2>
                                            <span>{errors.notFound.message}</span>
                                        </div>

                                    ) : (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.common.heading}</h2>
                                            <span>{errors.common.message}</span>
                                            <button className='mainBtn' onClick={Refresh3}>Refresh the page</button>
                                        </div>

                                    )
                                )
                            }
                            </div>
                    </div>

                </Route>
                <Route path='/search/people'>
                    <div className='searchPeople'>
                        <div className='searchHead'>
                            <div className='commonHeading'>
                                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                                <h2>{'Results found'}</h2>
                                <span>People based on your search</span>
                            </div>
                            <div className='searchLinkDiv'>
                            <NavLink className='links searchLink' to='/search/event' activeClassName="searchLinkActive">
                                    Events
                                </NavLink>
                                <NavLink className='links searchLink' to='/search/people' activeClassName="searchLinkActive">
                                    People
                                </NavLink>
                                <NavLink className='links searchLink' to='/search/business' activeClassName="searchLinkActive">
                                    Business
                                </NavLink>
                            </div>
                        </div>
                        <div className="featuredProfilesList">
                        {
                            (Object.keys(topSearchPeople).length === 0) ? (
                                (loading2 === false && error2 === null) &&
                                <div className='eventGrid'>
                                    <div className='noData'>
                                        <Warning />
                                        <h2>{'What can we help you find, ' + profile.firstname + '?'}</h2>
                                        <span>Just type any keyword into the search bar and dive into the deep, wide world of users!</span>
                                        <div className='loadmore'>
                                            <button className='mainBtn' onClick={() => history.push('')}>Go back to homepage</button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                
                                    topSearchPeople.map((item, key) => {
                                        if (topSearchPeople.length === key + 1) {
                                            return (
                                                <div ref={lastelment}>
                                                    <FeaturedProfileItem1 featured={item} k={key} unf={true} place={'none'}/>
                                                </div>
                                            )
                                        } else {
                                            return (<FeaturedProfileItem1 featured={item} k={key} unf={true} place={'none'}/>)
                                        }
                                    })
                                
                            )
                        }

                        {loading2 &&
                            
                            [...Array(6)].map(() => {
                                    return (
                                        <FeaturedProfileLoader />
                                    )
                                })
                            
                        }
                        
                            {
                                error2 !== null && (
                                    error2.status === null ? (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.internet.heading}</h2>
                                            <span>{errors.internet.message}</span>
                                            <button className='mainBtn' onClick={Refresh2}>Refresh the page</button>
                                        </div>

                                    ) : error2.status === 500 ? (

                                        <div className='errormsg min500px'>
                                            <Server />
                                            <h2>{errors.server.heading}</h2>
                                            <span>{errors.server.message}</span>
                                            <button className='mainBtn' onClick={Refresh2}>Try again</button>
                                        </div>

                                    ) : error2.status === 401 ? (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.notFound.heading}</h2>
                                            <span>{errors.notFound.message}</span>
                                        </div>

                                    ) : (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.common.heading}</h2>
                                            <span>{errors.common.message}</span>
                                            <button className='mainBtn' onClick={Refresh2}>Refresh the page</button>
                                        </div>

                                    )
                                )
                            }
                            </div>
                    </div>

                </Route>
                <Route path='/search/event'>
                    <div className='searchEvent'>
                        <div className='searchHead'>
                            <div className='commonHeading'>
                                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                                <h2>{'Results found'}</h2>
                                <span>Events based on your search</span>
                            </div>
                            <div className='searchLinkDiv'>
                            <NavLink className='links searchLink' to='/search/event' activeClassName="searchLinkActive">
                                    Events
                                </NavLink>
                                <NavLink className='links searchLink' to='/search/people' activeClassName="searchLinkActive">
                                    People
                                </NavLink>
                                <NavLink className='links searchLink' to='/search/business' activeClassName="searchLinkActive">
                                    Business
                                </NavLink>
                            </div>
                        </div>
                        <div className='eventGrid'>
                            {
                                (Object.keys(topSearchEvents).length === 0) ? (
                                    (loading1 === false && error1 === null) &&
                                    <div className='noData'>
                                        <Warning />
                                        <h2>{'What can we help you find, '+  profile.firstname + '?'}</h2>
                                        <span>Just type any keyword into the search bar and dive into the deep, wide world of events!</span>
                                        <div className='loadmore'>
                                            <button className='mainBtn' onClick={() => history.push('')}>Go back to homepage</button>
                                        </div>
                                    </div>
                                ) : (
                                    topSearchEvents.map((event, key) => {
                                        if (topSearchEvents.length === key + 1) {
                                            return (
                                                <div ref={lastEventRef}>
                                                    <EventTile1 event={event} k={key} />
                                                </div>
                                            )
                                        } else {
                                            return (<EventTile1 event={event} k={key} />)
                                        }
                                    })
                                )
                            }
                            {loading1 &&
                                [...Array(6)].map(() => {
                                    return (
                                        <EventTileLoader />
                                    )
                                })
                            }

                        </div>
                        {
                                error1 !== null && (
                                    error1.status === null ? (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.internet.heading}</h2>
                                            <span>{errors.internet.message}</span>
                                            <button className='mainBtn' onClick={Refresh1}>Refresh the page</button>
                                        </div>

                                    ) : error1.status === 500 ? (

                                        <div className='errormsg min500px'>
                                            <Server />
                                            <h2>{errors.server.heading}</h2>
                                            <span>{errors.server.message}</span>
                                            <button className='mainBtn' onClick={Refresh1}>Try again</button>
                                        </div>

                                    ) : error1.status === 401 ? (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.notFound.heading}</h2>
                                            <span>{errors.notFound.message}</span>
                                        </div>

                                    ) : (

                                        <div className='errormsg min500px'>
                                            <NotFound />
                                            <h2>{errors.common.heading}</h2>
                                            <span>{errors.common.message}</span>
                                            <button className='mainBtn' onClick={Refresh1}>Refresh the page</button>
                                        </div>

                                    )
                                )
                            }
                    </div>
                </Route>
                <Redirect to='/search/event' />
            </Switch>
        </div>
    )
}

export default TopBarSearch
