import React, { useState } from 'react'
import SingleImageModal from '../../../smallcomponents/modals/SingleImageModal'
import { ReactComponent as Play } from '../../../../assets/svg/video.svg';
import { ReactComponent as Media } from '../../../../assets/svg/media.svg';
// import ProfileMediaTile from '../../smallcomponents/profilemediatile/ProfileMediaTile';
import MediaModal2 from '../../../smallcomponents/modals/MediaModal2';
import { noData } from '../../../../Data/Data';
import { Checkformat } from '../../../../Data/Data';
import { IoIosPhotos } from 'react-icons/io'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';


const ProfileMedia = ({ media, media_count }: { media: any, media_count: any }) => {
    const [postid, setpostid] = useState<number>(0)
    const [index, setindex] = useState<number>(0)
    const [modalActive, setActive] = useState(false)
    
    const {pathname} = useLocation()
    const history = useHistory();
    
    

    return (
        <div style={{ position: 'relative' }}>
            {
                media.length !== 0 ? (
                    <div className='mediaGrid' 
                    // style={{paddingTop:'0'}}
                    >
                        {
                            media.map((item: any, key: number) => {
                                const { image, post_id, id, views_count } = item;
                                const format = Checkformat(image);
                                return (
                                    format === 'photo' ?

                                        <div className='videoTileContainer' key={key.toString()} onClick={
                                            () => {
                                                setpostid(post_id)
                                                setindex(id);
                                                setActive(!modalActive)
                                            }
                                        }>
                                            <img src={image} alt='Media' className='videoTile'></img>
                                            <div className='imgOverlay profileimgoverlay'>
                                                {/* <div className="mediatileuserdetails" 
                                                onClick={e => {
                                                    e.preventDefault();
                                                    handleProfile(profile_username);
                                                }}
                                                >
                                                    <span className='mediatileusrdetailswrapper'>
                                                        <div className="mediatileusrdp">
                                                            <img src={profile_dp} alt="P_I_C" />
                                                        </div>
                                                        <div className="mediatileusrname"><span>{profile_firstname + '.' + profile_surname}</span></div>
                                                    </span>
                                                </div>
                                                 */}
                                                <div className="mediatileusrviews"><span>{views_count + ' ' + 'VIEWS'}</span></div>
                                            </div>
                                        </div>
                                        : format === 'video' ?
                                            <div className='videoTileContainer' key={key.toString()} onClick={
                                                () => {
                                                    setpostid(post_id)
                                                    setindex(id);
                                                    setActive(!modalActive)
                                                }
                                            }
                                                onMouseEnter={(e) => {
                                                    let target: any = e.currentTarget.firstChild
                                                    target.play()
                                                }} onMouseLeave={(e) => {
                                                    let target: any = e.currentTarget.firstChild
                                                    target.pause()
                                                }}>
                                                <video className='videoTile' muted>
                                                    <source src={image} type="video/mp4" />
                                                    <source src={image} type="video/mkv" />
                                                    <source src={image} type="video/quicktime" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className='videoOverlay profilevideooverlay' style={{ paddingTop: '138.5px', gap: '30px' }}>
                                                    <Play />
                                                    <div className="mediatileusrviews" style={{ alignSelf: 'flex-end', margin: 'auto auto 55px 30px' }}><span>{views_count + ' ' + 'VIEWS'}</span></div>

                                                </div>
                                            </div>
                                            : null
                                )
                            })
                        }
                        {/* media.length < media_count &&   */}
                        {media.length < media_count && <div className='videoTileContainer cursor' style={{ fontSize:'1rem',backgroundColor: 'var(--cl_bggrey)',color:'var(--cl_textgrey)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',gap:'10px' }} onClick={
                            () => {
                                history.push(`${pathname}/media`)
                            }
                        }>
                            <IoIosPhotos style={{fontSize:'2.5rem',color:'var(--cl_textgrey)'}}/>
                            See all
                        </div>}
                    </div>
                ) : (
                    <div className='min400px centered'>
                        <div className='noData'>
                            <Media />
                            <h2>{noData.media.heading}</h2>
                            <span>{noData.media.message}</span>
                        </div>
                    </div>
                )
            }
            {
                modalActive && <MediaModal2 postid={postid} imageid={index} type={'profile'}
                    open={modalActive} setOpen={setActive}
                />
            }
        </div>
    )
}

export default ProfileMedia
