import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './timepicker.css'

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
    },
    "MuiPickersToolbar-root": {
      border: '1px solid red'
    }
  }
});


const theme = createTheme({
  palette: {
    primary: {
      main: '#ff5917',
    },
    secondary: {
      main: '#ce7f5e',
    },
  },

});

interface Iprops {
  label?: string
  style?: React.CSSProperties | undefined
  className?: string
  value: Date | null
  setValue: any
  handleClose?:any
}
export default function TimePicker({ label, style, className, value, setValue ,handleClose }: Iprops) {

  const classes = useStyles();



  return (
    <div style={style} className={className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <StaticTimePicker
            onAccept={handleClose}
            className={classes.root}
            toolbarTitle={label || "Select Time"}
            displayStaticWrapperAs="mobile"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            // ToolbarComponent={(params)=> <div> close</div>}

          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>

  );
}
