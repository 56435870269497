import Api from "../../../../app/api";
import { followersFailed, followersRequest, followersSuccess } from "../../userfollowers/store/userfollowersSlice";
import { userEventsFailed, userEventsRequest, userEventsSuccess } from "../../userprofileevents/store/userProfileEventsSlice";
import { followingFailed, followingRequest, followingSuccess } from "./userfollowingSlice";

export function FetchFollwingPageData( dispatch:any, history:any ,profileid:any,success:any, failed:any ,page_no=1, prev_data:any) {
    // console.log(prev_data,'this the prev_data before  api call')
     let url = `/api/v1/user/following/${profileid}/${page_no}/20/`
        dispatch(followingRequest())
        Api('get', url, null, success, failed, history)?.then( response=> {
            console.log("Followers Data: " , response );
            // const{ data , status}= response
            // if(status === 200){
  
              let final_data = page_no > 1 ? prev_data.concat(response) : response
              let pagination_status;
              if(response.length < 20) {
                  pagination_status = false;
              } else if(response.length === 20 ) {
                  pagination_status = true;
              }
            // dispatch({ type: 'FOLLOWERS_LIST', FollowerList : final_data, pagination: pagination_status, page_no : page_no });
                  dispatch(followingSuccess({
                    following: final_data,
                    no: page_no ,
                    pagination: pagination_status
                    }))
                
            // }
          })
          .catch(function (error) {
            const errorMsg = error.message;
            dispatch(followingFailed({  error : errorMsg }))
            console.log( 'error followers',error);
          });
};

export function FetchFollowersPageData( dispatch:any, history:any ,profileid:any,success:any, failed:any ,page_no=1, prev_data:any) {
  // console.log(prev_data,'this the prev_data before  api call')
   let url = `/api/v1/user/followers/${profileid}/${page_no}/5/`
      dispatch(followersRequest())
      Api('get', url, null, success, failed, history)?.then( response=> {
          console.log("Followers Data: " , response );
          // const{ data , status}= response
          // if(status === 200){

            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if(response.length < 5) {
                pagination_status = false;
            } else if(response.length === 5) {
                pagination_status = true;
            }
          // dispatch({ type: 'FOLLOWERS_LIST', FollowerList : final_data, pagination: pagination_status, page_no : page_no });
                dispatch(followersSuccess({
                  followers: final_data,
                  no: page_no ,
                  pagination: pagination_status
                  }))
              
          // }
        })
        .catch(function (error) {
          const errorMsg = error.message;
          dispatch(followersFailed({  error : errorMsg }))
          console.log( 'error followers',error);
        });
};

export function FetchEventsPageData( dispatch:any, history:any ,profileid:any,success:any, failed:any ,page_no=1, prev_data:any) {
  // console.log(prev_data,'this the prev_data before  api call')
   let unparseddetails: any = localStorage.getItem('pdetails')
   let parseddetails = JSON.parse(unparseddetails)
   let currentunparsed: any = localStorage.getItem('mtusr')
   let currentparsed = JSON.parse(currentunparsed)
    let url = `/api/v1/event/others/${profileid}/${page_no}/10/`
    if (unparseddetails) {
     if (currentparsed.is_business_profile) {
       url = `/api/v1/event/others/${profileid}/${page_no}/10/?auth_profile=${parseddetails[0].id}`
     }
 
   }
      dispatch(userEventsRequest())
      Api('get', url, null, success, failed, history)?.then( response=> {
          console.log("Followers Data: " , response );
          // const{ data , status}= response
          // if(status === 200){

            let final_data = page_no > 1 ? prev_data.concat(response) : response
            let pagination_status;
            if(response.length < 10) {
                pagination_status = false;
            } else if(response.length === 10 ) {
                pagination_status = true;
            }
          // dispatch({ type: 'FOLLOWERS_LIST', FollowerList : final_data, pagination: pagination_status, page_no : page_no });
                dispatch(userEventsSuccess({
                  data: final_data,
                  no: page_no ,
                  pagination: pagination_status
                  }))
              
          // }
        })
        .catch(function (error) {
          const errorMsg = error.message;
          dispatch(userEventsFailed({  error : errorMsg }))

          console.log( 'error followers',error);
        });
};
    