import React from 'react'
import {Route,Redirect} from 'react-router-dom'

// const PublicRoute = ({isAuth,component : Component,...rest}: any) => {
    
//     return (
//         <Route {...rest} render={(props)=>{
//             if(!isAuth){
//                 return <Component/>
//             }else {
//                 return <Redirect to={{pathname:'/',
//                 //  state: {from: props.location}
//                 }}/>
//             }
//         }}/>
//     )
// }

// export default PublicRoute

function PublicRoute({isAuth,component : Component,...rest}: any) {
    return (
        <Route {...rest} render={(props: any)=>{
            if(!isAuth){
                return <Component/>
            }else {
                return <Redirect to={{pathname:'/',
                //  state: {from: props.location}
                }}/>
            }
        }}/>
    )
}

export default PublicRoute
