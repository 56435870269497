import { createSlice } from '@reduxjs/toolkit'
import { LoginState } from './interface';
const initialState: LoginState = {
    isAuth: localStorage.getItem(`access_token`) === null ? false : true,
    // isAuth: false,
    loading: false,
    user: {},
    error: null,
    redirect: null,
    access: null,
    refresh: null,
    connected_account_exist: false,
    connected_account_linked: false
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        loginSuccess: (state, action: any) => {

            state.loading = false;
            state.isAuth = true;
            state.user = action.payload;
            state.error = null;
            state.access = action.payload.access;
            state.refresh = action.payload.refresh;
            state.connected_account_exist = action.payload.connected_account_exist;

            state.connected_account_linked = action.payload.connected_account_linked;
            if (state.connected_account_exist === true) {
                localStorage.setItem('account_create', "true")

            }
            if (state.connected_account_linked === true) {
                localStorage.setItem('account_linked', "true")
            }
        },
        loginFailed: (state, action: any) => {
            state.loading = false;
            // state.isAuth = false;
            state.error = action.payload;
        },
        logout: (state) => {
            state.loading = false;
            // localStorage.remove('access_token')
            state.isAuth = false;
            state.error = null;
            // state.connected_account_exist = false
            // state.connected_account_linked = false
        },
        accountCreated: (state, action: any) => {

            state.connected_account_exist = action.payload
        },
        accountLinked: (state, action: any) => {

            state.connected_account_linked = action.payload

        }
    },
})

export const { loginRequest, loginSuccess, loginFailed, logout, accountCreated, accountLinked } = loginSlice.actions
export default loginSlice.reducer