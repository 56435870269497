import React from 'react'
import TicketPortalComponentLayout from '../../components/TicketPortalComponentLayout'
import './style.css'
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import AllPayment from './tabs/all';
import SucessPayment from './tabs/sucess';
import FailedPayment from './tabs/failed';
import BalancePayment from './tabs/balance';

const PaymentIndex = () => {

  const { path } = useRouteMatch()

  return (
    <TicketPortalComponentLayout header='Payment' >
      <div className="tp__component__top__section">
        <NavLink className="tp__component__nav__item" activeClassName="nav__active2" to={path + "/all"} exact>
          All
        </NavLink>
        <NavLink className="tp__component__nav__item" activeClassName="nav__active2" to={path + '/success'}>
          Success
        </NavLink>
        <NavLink className="tp__component__nav__item" activeClassName="nav__active2" to={path + '/failed'}>
          Failed
        </NavLink>
        <NavLink className="tp__component__nav__item" activeClassName="nav__active2" to={path + '/balances'}>
          Balances
        </NavLink>
      </div>


      <div className="tp__component__sub__section">
        <Switch>
          <Route path={path + '/all'} component={AllPayment} />
          <Route path={path + '/success'} component={SucessPayment} />
          <Route path={path + '/failed'} component={FailedPayment} />
          <Route path={path + '/balances'} component={BalancePayment} />
          <Redirect from={path} to={path + '/all'} />
        </Switch>
      </div>

    </TicketPortalComponentLayout>
  )
}

export default PaymentIndex