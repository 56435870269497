// import React from 'react';
// import logom from '../../assets/carousel/logom.png';
// import blue_line from '../../assets/carousel/blue_line.png';
// import pink_line from '../../assets/carousel/pink_line.png';

// import blue_stat from '../../assets/carousel/blue_stat.png';
// import yellow_stat from '../../assets/carousel/yellow_stat.png';

// import book from '../../assets/carousel/book.png';
// import sell from '../../assets/carousel/sell.png';
// import ticket from '../../assets/carousel/ticket.png';


// import bookview from '../../assets/carousel/bookview.png';
// import sellview from '../../assets/carousel/sellview.png';
// import ticketview from '../../assets/carousel/ticketview.png';
// import crm from '../../assets/carousel/crm.png';



// import blue_icon from '../../assets/carousel/blue_icon.png';
// import black_icon from '../../assets/carousel/black_icon.png';
// import pink_icon from '../../assets/carousel/pink_icon.png';

// import orange_bg from '../../assets/carousel/orange_bg.png';
// import scrn_1 from '../../assets/carousel/scrn_1.png';
// import ios from '../../assets/carousel/ios.png';
// import g_play from '../../assets/carousel/g_play.png';
// import ios_white from '../../assets/carousel/ios_white.png';
// import g_play_white from '../../assets/carousel/g_play_white.png';
// import footer_grad from '../../assets/carousel/footer_grad.png';
// import tick from '../../assets/carousel/tick.png';
// import violet_stat from '../../assets/carousel/violet_stat.png';

import './style.css';
import { useHistory } from 'react-router-dom';

const WebLandingPage = () => {

    let history = useHistory()
    return null
    // return (
    //     <div className='landing__page__container' style={{
    //         backgroundImage: `url(${footer_grad})`,
    //     }}>
    //         <nav style={{ display: 'flex' ,justifyContent:"space-between" ,alignItems: 'center'}}>
    //             <div className='landing__logoMain'>
    //                 <img alt='Logo' src={logom} />
    //             </div>
    //             <div>
    //                 <button className='mainBtn' style={{ width:"100px" , height:"40px" ,fontSize:"1rem" ,padding:"15px 8px" ,borderRadius:"10px"}} onClick={()=>history.push("/signup")}>
    //                     SignUp
    //                 </button>
    //             </div>
    //         </nav>

    //         <main>
    //             <div className='landing__page__sec__1'>
    //                 <div className='sec__1__part__1'>
    //                     <h1>
    //                         Start selling tickets to{' '}
    //                         <span className='relativeposition'>
    //                             {' '}
    //                             big <img className='absolutepostion' src={pink_line} alt='' />{' '}
    //                         </span>{' '}
    //                         &{' '}
    //                         <span className='relativeposition'>
    //                             small <img className='absolutepostion' src={blue_line} alt='' />
    //                         </span>{' '}
    //                         events
    //                     </h1>
    //                     <div className='sec__1__part__21'>
    //                         <img src='https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/landingpage_img1.webp' alt='' />
    //                     </div>
    //                     <h4>
    //                         A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with
    //                         my whole heart.
    //                     </h4>
    //                 </div>
    //                 <div className='sec__1__part__2'>
    //                     <img src='https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/landingpage_img1.webp' alt='' />
    //                 </div>
    //             </div>

    //             <div className='landing__page__sec__2'>
    //                 <div>
    //                     <img src={blue_stat} alt='' />
    //                     <div>
    //                         <h2>$500+ </h2>
    //                         <h5>
    //                             Average earnings of user
    //                         </h5>
    //                     </div>
    //                 </div>
    //                 <div className="border__class" >
    //                     <img src={yellow_stat} alt='' />
    //                     <div>
    //                         <h2>600% </h2>
    //                         <h5>
    //                             More events in neighbourhood
    //                         </h5>
    //                     </div>
    //                 </div>
    //                 <div>
    //                     <img src={violet_stat} alt='' />
    //                     <div>
    //                         <h2>300k+ </h2>
    //                         <h5>
    //                             The number of people using the app
    //                         </h5>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className='landing__page__sec__3'>

    //                 <small>
    //                     FEATURES
    //                 </small>
    //                 <h2>
    //                     Overflowing with useful features
    //                 </h2>
    //                 <h4 className="grey" style={{ maxWidth: " 55vw" }}>
    //                     A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.
    //                 </h4>



    //             </div>


    //             <div className='landing__page__feature__wrapper'>

    //                 <div className='feature__wrapper__left'>
    //                     <img className="book_icon_style" src={book} alt="" />
    //                     <h3> Create and book events</h3>
    //                     <h4 className="grey">
    //                         A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.
    //                     </h4>

    //                     <p>
    //                         <span>
    //                             <img src={tick} alt="" />
    //                             <h5> I am alone, and feel the charm of existence in this spot</h5>
    //                         </span>
    //                         <span>
    //                             <img src={tick} alt="" />
    //                             <h5>I am so happy, my dear friend, so absorbed in the exquisite sense </h5>

    //                         </span>
    //                         <span>
    //                             <img src={tick} alt="" />

    //                             <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                         </span>

    //                     </p>

    //                 </div>
    //                 <div className='feature__wrapper__right'>
    //                     <img src={bookview} alt="" />
    //                 </div>

    //             </div>
    //             <div className='landing__page__feature__wrapper landing__page__feature__wrapperMobView'>


    //                 <div className='feature__wrapper__right'>
    //                     <img src={sellview} alt="" />
    //                 </div>

    //                 <div className='feature__wrapper__left'>
    //                     <img className="book_icon_style" src={sell} alt="" />
    //                     <h3> Create and sell your tickets</h3>
    //                     <h4 className="grey">
    //                         A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.
    //                     </h4>

    //                     <p>
    //                         <span>
    //                             <img src={tick} alt="" />
    //                             <h5> I am alone, and feel the charm of existence in this spot</h5>
    //                         </span>
    //                         <span>
    //                             <img src={tick} alt="" />
    //                             <h5>I am so happy, my dear friend, so absorbed in the exquisite sense </h5>

    //                         </span>

    //                     </p>

    //                 </div>

    //             </div>
    //             <div className='landing__page__feature__wrapper'>

    //                 <div className='feature__wrapper__left'>
    //                     <img className="book_icon_style" src={ticket} alt="" />
    //                     <h3> Manage all tickets</h3>
    //                     <h4 className="grey">
    //                         A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.
    //                     </h4>

    //                     <p>
    //                         <span>
    //                             <img src={tick} alt="" />
    //                             <h5> I am alone, and feel the charm of existence in this spot</h5>
    //                         </span>
    //                         <span>
    //                             <img src={tick} alt="" />
    //                             <h5>I am so happy, my dear friend, so absorbed in the exquisite sense </h5>

    //                         </span>
    //                         <span>
    //                             <img src={tick} alt="" />

    //                             <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                         </span>

    //                     </p>

    //                 </div>
    //                 <div className='feature__wrapper__right'>
    //                     <img src={ticketview} alt="" style={{ background: "white" }} />
    //                 </div>

    //             </div>

    //             <div className='landing__page__sec__3'>

    //                 <small>
    //                     FEATURES
    //                 </small>
    //                 <h2>
    //                     Analyze all your events
    //                 </h2>
    //                 <h4 className="grey" style={{ maxWidth: " 55vw" }}>
    //                     A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.
    //                 </h4>
    //                 <div>
    //                     <img src={crm} alt="" className='contain_image' />
    //                 </div>


    //             </div>

    //             <div className='landing__page__sec__3'>

    //                 <small>
    //                     FEATURES
    //                 </small>
    //                 <h2>
    //                     Plan that fits your scale
    //                 </h2>
    //                 <h4 className="grey" style={{ maxWidth: " 55vw" }}>
    //                     A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.
    //                 </h4>


    //             </div>

    //             <div className='plans__wrapper'>

    //                 <div className='plan__container__1' >
    //                     <div style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
    //                         <span>
    //                             <img src={blue_icon} alt="" />
    //                         </span>

    //                         <p>
    //                             <h4 style={{ fontWeight: 700 }}>
    //                                 Basic plan
    //                             </h4>

    //                             <h5 className="grey">
    //                                 Perfect plan for Starters
    //                             </h5>
    //                         </p>
    //                     </div>

    //                     <div>

    //                         <p>
    //                             <h2>
    //                                 Free
    //                             </h2>

    //                             <h5 className="grey" >
    //                                 A wonderful serenity has taken possession
    //                             </h5>
    //                         </p>

    //                     </div>
    //                     <div>

    //                         <p style={{ display: 'flex', flexDirection: 'column', gap: "1.5rem", borderBottom: "1px solid var(--cl_border_grey)", paddingBottom: "2rem" }}>
    //                             <span>
    //                                 <img src={tick} alt="" />
    //                                 <h5> I am alone, and feel the charm of existence in this spot</h5>
    //                             </span>
    //                             <span>
    //                                 <img src={tick} alt="" />
    //                                 <h5>I am so happy, my dear friend, so absorbed in the exquisite sense </h5>

    //                             </span>
    //                             <span>
    //                                 <img src={tick} alt="" />

    //                                 <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                             </span>
    //                             <span>
    //                                 <img src={tick} alt="" />

    //                                 <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                             </span>

    //                         </p>

    //                     </div>
    //                     <div style={{ paddingTop: "1rem" }}>

    //                         <button >
    //                             Get started
    //                         </button>
    //                     </div>

    //                 </div>


    //                 <div className='plan__container__2 ' >
    //                     <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(255, 178, 23, 1)" }}>
    //                         <h5 style={{ fontWeight: 600, textAlign: "center" }}>
    //                             MOST POPULAR
    //                         </h5>
    //                     </div>
    //                     <div className='inside__2' style={{ padding: ".5rem 2rem 2rem 2rem" }}>

    //                         <div style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
    //                             <span>
    //                                 <img src={black_icon} alt="" />
    //                             </span>

    //                             <p>
    //                                 <h4 style={{ fontWeight: 700 }}>
    //                                     Basic plan
    //                                 </h4>

    //                                 <h5>
    //                                     Perfect plan for Starters
    //                                 </h5>
    //                             </p>
    //                         </div>

    //                         <div>

    //                             <p>
    //                                 <h2>
    //                                     Free
    //                                 </h2>

    //                                 <h5 >
    //                                     A wonderful serenity has taken possession
    //                                 </h5>
    //                             </p>

    //                         </div>
    //                         <div>

    //                             <p style={{ display: 'flex', flexDirection: 'column', gap: "1.5rem", borderBottom: "1px solid var(--cl_border_grey)", paddingBottom: "2rem" }}>

    //                                 <span>
    //                                     <img src={tick} alt="" />
    //                                     <h5> I am alone, and feel the charm of existence in this spot</h5>
    //                                 </span>
    //                                 <span>
    //                                     <img src={tick} alt="" />
    //                                     <h5>I am so happy, my dear friend, so absorbed in the exquisite sense </h5>

    //                                 </span>
    //                                 <span>
    //                                     <img src={tick} alt="" />

    //                                     <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                                 </span>
    //                                 <span>
    //                                     <img src={tick} alt="" />

    //                                     <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                                 </span>

    //                             </p>

    //                         </div>
    //                         <div style={{ paddingTop: "1rem" }}>

    //                             <button style={{ backgroundColor: "#000000" }}>
    //                                 Get started
    //                             </button>
    //                         </div>
    //                     </div>

    //                 </div>


    //                 <div className='plan__container__1 pink__bg' >
    //                     <div style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
    //                         <span>
    //                             <img src={pink_icon} alt="" />
    //                         </span>

    //                         <p>
    //                             <h4 style={{ fontWeight: 700 }}>
    //                                 Basic plan
    //                             </h4>

    //                             <h5 className="grey">
    //                                 Perfect plan for Starters
    //                             </h5>
    //                         </p>
    //                     </div>

    //                     <div>

    //                         <p>
    //                             <h2>
    //                                 Free
    //                             </h2>

    //                             <h5 className="grey" >
    //                                 A wonderful serenity has taken possession
    //                             </h5>
    //                         </p>

    //                     </div>
    //                     <div>

    //                         <p style={{ display: 'flex', flexDirection: 'column', gap: "1.5rem", borderBottom: "1px solid var(--cl_border_grey)", paddingBottom: "2rem" }}>
    //                             <span>
    //                                 <img src={tick} alt="" />
    //                                 <h5> I am alone, and feel the charm of existence in this spot</h5>
    //                             </span>
    //                             <span>
    //                                 <img src={tick} alt="" />
    //                                 <h5>I am so happy, my dear friend, so absorbed in the exquisite sense </h5>

    //                             </span>
    //                             <span>
    //                                 <img src={tick} alt="" />

    //                                 <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                             </span>
    //                             <span>
    //                                 <img src={tick} alt="" />

    //                                 <h5> I should be incapable of drawing a single stroke at the present moment </h5>

    //                             </span>

    //                         </p>

    //                     </div>
    //                     <div style={{ paddingTop: "1rem" }}>

    //                         <button >
    //                             Get started
    //                         </button>
    //                     </div>

    //                 </div>



    //             </div>

    //             <div className='landing__page__sec__3' style={{ padding: ' 6rem 0px' }}>

    //                 <div className="exploring__events__wrapper" style={{
    //                     backgroundImage: `url(${orange_bg})`, backgroundPosition: 'center',
    //                     backgroundSize: 'cover',
    //                     backgroundRepeat: 'no-repeat',
    //                 }}>

    //                     <div className="exploring__events__sec__1">

    //                         <div className="exploring__events__scrn_1">
    //                             <img src={scrn_1} alt="" />
    //                         </div>
    //                     </div>
    //                     <div className="exploring__events__sec__2">
    //                         <small style={{ color: " white", fontWeight: 500 }}>
    //                             GET STARTED
    //                         </small>
    //                         <h2 style={{ color: " white" }}>
    //                             Start exploring events
    //                         </h2>
    //                         <h4 style={{ color: " white" }}>
    //                             A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.
    //                         </h4>
    //                         <div style={{ display: "flex", gap: "1.5rem" }} className="store_logo_wrapper">

    //                             <span >
    //                                 <a href="https://apps.apple.com/in/app/mutual-events/id1590547493" target="_blank"rel="noreferrer">
    //                                     <img className="store__logo" src={ios} alt="" /></a>
    //                             </span>
    //                             <span>
    //                                 <a href="https://play.google.com/store/apps/details?id=com.mutualevents.app" target="_blank"rel="noreferrer">

    //                                     <img className="store__logo" src={g_play} alt="" /> </a>
    //                             </span>

    //                         </div>

    //                     </div>

    //                 </div>

    //             </div>



    //         </main>

    //         <footer style={{ borderTop: "1px solid var(--cl_light_grey)" }}>

    //             <div className='landing__footer__wrapper'>
    //                 <div className='landing__footer__sec__1'>
    //                     <div className='landing__logoMain'>
    //                         <img alt='Mutual events logo' src={logom}></img>
    //                     </div>
    //                     <h5 className='grey' style={{ maxWidth: "600px" }}>Making the world a better place through constructing elegant heirarchies.</h5>
    //                     <h5 className='grey'>Copyright @ 2022 by <span style={{ color: 'black', fontWeight: 600 }}>Mutual events</span></h5>
    //                 </div>
    //                 <div className='landing__footer__sec__2'>
    //                     <div className='footer__content'>
    //                         <h5 style={{ fontWeight: 600, padding: ".5rem 0px" }}>Company</h5>
    //                         <h5 className='grey'>About</h5>
    //                         <h5 className='grey'>Blog</h5>
    //                         <h5 className='grey'>Jobs</h5>
    //                         <h5 className='grey'>Partners</h5>
    //                     </div>

    //                     <div className='footer__content'>
    //                         <h5 style={{ fontWeight: 600, padding: ".5rem 0px" }}>Legal</h5>
    //                         <h5 className='grey' onClick={()=>history.push("/privacy-policy")}>Privacy policy</h5>
    //                         <h5 className='grey' onClick={()=>history.push("/terms-of-service")}> Terms of Service</h5>

    //                         <h5 className='grey' onClick={()=>history.push("/user-guidelines")}> User Guidelines</h5>
    //                     </div>
    //                     <div className='footer__content'>
    //                         <h5 style={{ fontWeight: 600, padding: ".5rem 0px" }}>Get the app</h5>

    //                         <span className='white_icons_wrapper'>
    //                             <a href="https://apps.apple.com/in/app/mutual-events/id1590547493" target="_blank"rel="noreferrer" >

    //                                 <img src={ios_white} alt="" /> </a>
    //                         </span>
    //                         <span className='white_icons_wrapper'>
    //                             <a href="https://play.google.com/store/apps/details?id=com.mutualevents.app" target="_blank"rel="noreferrer">

    //                                 <img src={g_play_white} alt="" />
    //                             </a>
    //                         </span>

    //                     </div>

    //                 </div>

    //             </div>
    //         </footer>

    //         {/* <div className='footer_gradient'>
    //             <img src={footer_grad} alt="" />
    //         </div> */}
    //     </div>
    // );
};

export default WebLandingPage;
