import React, { useEffect, useRef, useState } from 'react'

import { IoCloseOutline } from 'react-icons/io5';
import { ReactComponent as NotificationIcon } from '../../../../assets/svg/chat/chatbell.svg';
import { ReactComponent as Search } from '../../../../assets/svg/mainsearch.svg'
import { Scrollbars } from 'react-custom-scrollbars-2';
import Chatuserlist from '../common/Chatuserlist';
import MiniSingleChatUser from './MiniSingleChatUser';
import MiniChatNotifications from './MiniChatNotifications'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import MiniChatMessages from './MiniChatMessages'
import { useHistory } from 'react-router-dom';
import { fetchchatlist } from '../store/action';
import Draggable from "react-draggable";
// import DragIndicator from '@material-ui/icons/DragHandle'
import { MdOpenInFull, MdOutlineDragIndicator } from 'react-icons/md';
import { ExpandMoreOutlined, ZoomIn, ZoomOutMap } from '@material-ui/icons';
import { Zoom } from '@mui/material';
import Api from '../../../../app/api';
import { usersearch } from '../store/ChatSlice';





const Minichat = ({ isActive, setIsActive }: any) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const [chatwindow, setchatwindow] = useState<any>('main')
    const [searchuser, setsearchuser] = useState<any>('')
    const [searchactive, setsearchactive] = useState(true)
    const [activechatdetails, setactivechatdetails] = useState<any>(null)


    const { socket, recentchat, searchdata, searcherror, searchloading, searchpage, searchpagination, openedchatdetails } = useAppSelector(state => state.chat)
    const Scrollref: any = useRef()
    const Chatscroll = (event: any) => {
        console.log(event.target.scrollTop)
        // console
        if (event.target.scrollTop > 50) {
            setsearchactive(false)
            // setheight(`392px`)
        } else if (event.target.scrollTop < 50) {
            setsearchactive(true)
            // Scrollref.current.nextSibling.height = '320px'
            // setheight(`320px`)
        }
    }

    useEffect(() => {
        // if (isActive) {
        //     let page = 1;
        //     dispatch(chatrequest(page))
        //     Api('get', '/api/v1/ws/chat/recent/v2/1/10/', null, (data: any) => {

        //         dispatch(chatsuccess({
        //             data: data,
        //             no: 2,
        //             pagination: true
        //         }))
        //         console.log(data)
        //         // setrecent(data)
        //     }, (error: any) => {
        //         dispatch(chatfailed(error))
        //     }, history, true, true)
        // }


        // const success =(res:any)=>{
        //     console.log(res.data)
        //     // let pg = res.data.length === 10 ? true : false
        //     dispatch(chatsuccess({
        //       data: res.data,
        //       no: 2,
        //       pagination: true
        //     }))
        // }

        // const failed =(error:any)=>{
        //     console.log(error)

        // }

        if (isActive) {
            fetchchatlist(history, dispatch, 1);
        }

    }, [isActive])

    const success = (data: any) => {

        dispatch(usersearch({ data: data, page: searchpage + 1, chattype: 'search' }))

    }
    const failed = () => {

    }

    const chatSearch = () => {




        let data = {
            "keyword": searchuser
        }

        Api('post', '/api/v1/search/chat/1/10/', data, success, failed, history, true, true)

    }
    return (
        <Draggable handle=".handle">

            <div className={`chatpopup  ${isActive ? 'active' : 'inactive'}`} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>


                <div className={chatwindow === 'main' ? 'minichatmain minichatmainactive' : 'minichatmain minichatmaininactive'}>
                    <div className='minichatheader'>
                        <h4 className='cursor' onClick={() => {
                            history.push('/chat')
                        }}>Chats</h4>
                        <div style={{ display: 'flex', gap: "5px", alignItems: 'center' }}>
                            <div className='whiteiconwrapper' onClick={() => {
                                setchatwindow('notifications')
                            }}>
                                <NotificationIcon />

                            </div>

                            <strong className="handle">
                                {/* <MdOutlineDragIndicator style={{ height: "25px", width: "25px" }} /> */}

                            </strong>
                            <div
                                onClick={() => {
                                    history.push('/chat')
                                }}
                                style={{
                                    height: "40px",
                                    width: "40px",
                                    border: '1px solid var(--cl_lightgrey2)',
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                }}>
                                <MdOpenInFull style={{ height: "20px", width: "20px", opacity: "70%" }} />
                            </div>

                        </div>

                    </div>
                    <div className='minichatsearch'>
                        {/* className={searchactive ?'minichatsearch minichatsearchactive': 'minichatsearch minichatsearchinactive'} */}
                        <div className='minichatinputwrapper'>
                            <input value={searchuser} onChange={(e) => {
                                setsearchuser(e.currentTarget.value)
                                chatSearch()
                            }}></input>
                            {/* <Search /> */}
                            {searchuser === '' ? (
                                <Search className='cursor' onClick={
                                    (e: any) => {
                                        if (searchuser.length !== 0) {
                                            // handleKeyPress(e)
                                            // handleSearch()
                                        }
                                    }
                                } />) : (
                                <IoCloseOutline className='cursor' onClick={
                                    (e: any) => {
                                        setsearchuser('')
                                    }
                                } />
                            )}
                        </div>
                    </div>
                    {/* <Chatuserlist/> */}

                    <Scrollbars ref={Scrollref} className='minichatuserlist' style={{ height: '320px' }} autoHide onScroll={Chatscroll}>
                        {searchuser === '' ?
                            <>
                                {
                                    recentchat?.map((user: any, key: number) => {
                                        return user.starred_status ? <MiniSingleChatUser user={user} k={key}
                                            // activechat={activechat}
                                            // setactivechat={setactivechat}
                                            // socket={socket}
                                            chatwindow={chatwindow}
                                            setchatwindow={setchatwindow}
                                            setactivechatdetails={setactivechatdetails}
                                            activechatdetails={activechatdetails} /> : null
                                    })
                                }
                                {
                                    recentchat?.map((user: any, key: number) => {

                                        return user.starred_status ? null : <MiniSingleChatUser user={user} k={key}
                                            // activechat={activechat}
                                            // setactivechat={setactivechat}
                                            // socket={socket}
                                            chatwindow={chatwindow}
                                            setchatwindow={setchatwindow}
                                            setactivechatdetails={setactivechatdetails}
                                            activechatdetails={activechatdetails} />
                                    })
                                }
                            </>

                            : searchdata?.map((user: any, key: number) => {

                                return <MiniSingleChatUser user={user} k={key}
                                    // activechat={activechat}
                                    // setactivechat={setactivechat}
                                    // socket={socket}
                                    chatwindow={chatwindow}
                                    setchatwindow={setchatwindow}
                                    setactivechatdetails={setactivechatdetails}
                                    activechatdetails={activechatdetails} />
                            })
                        }
                    </Scrollbars>
                </div>
                {/* <ChatUser socket={socket} chatwindow={chatwindow} setchatwindow={setchatwindow} /> */}
                <MiniChatNotifications chatwindow={chatwindow} setchatwindow={setchatwindow} />
                {/* {openedchatdetails &&  */}

                <MiniChatMessages
                    user={''}
                    chatwindow={chatwindow} setchatwindow={setchatwindow} />
            </div>
        </Draggable>
    )
}

export default Minichat