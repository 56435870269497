import { createSlice } from '@reduxjs/toolkit';
import { ViewRepeatEvent } from '../../../pages/Event/store/interface';
// import { HomeState } from '../../browse/store/interface';
import { EditEventState } from './interface';

const initialState: EditEventState = {
    id: null,
    mode: null,
    currentstage: 1,
    reachedstage: 1,
    imagename: 'click here',
    imageid: null,
    imagefile: null,
    imagetype: null,
    title: '',
    organisers: [],
    tags: [],
    categories: [],
    description: '',
    rating: 1,
    maincategory: [],
    eventtype: 1,
    lat: -33.865143,
    lng: 151.2099,
    startdate: '',
    enddate: '',
    // from: '',
    // to: '',
    invites: [],
    assigned: [],
    trigger: null,
    invitesearch: [],
    invitesearchload: false,
    invitesearchfailed: null,
    participantsearch: [],
    participantsearchload: false,
    participantsearchfailed: null,
    organiserlist: [],
    tagslist: [],
    location: '',
    state: '',
    country: '',
    locationimage: null,
    created: {},
    uploadedimg: null,

    startUTC: {},
    endUTC: {},
    only_invited_guests: false,
    public_status:true,
    current_alternate_profile:null,
    is_claimable:false,
    alternate_profile:null ,
    alternate_profile_username : "",
    alternate_profile_dp : "",
    repeat_event: {} as ViewRepeatEvent,

    repeating_event: false,
    single_day_event:true,
    multi_day_event:false,
    repeat_type:"daily",
    frequency:1,
    repeat_ending_datetime: "",
    days:[],
    dates :[],
    months:[],
    custom:{
        type:'first',
        day:1
        
    },
    isMonthCustom:false,
    isYearCustom:false,
    all_day_event:false,
    shop_details : null,
    custom_form : null
};
export const addEventSlice = createSlice({
    name: 'edit',
    initialState,
    reducers: {
        clearedit: (state) => {
            state.id = null;
            state.mode = null;
            state.currentstage = 1;
            state.reachedstage = 1;
            state.imagename = 'click here';
            state.imageid = null;
            state.imagefile = null;
            state.imagetype = null;
            state.title = '';
            state.organisers = [];
            state.tags = [];
            state.categories = [];
            state.description = '';
            state.rating = 1;
            state.eventtype = 1;
            state.lat = 0;
            state.lng = 0;
            state.startdate = '';
            state.enddate = '';
            // state.from = '';
            // state.to = '';
            state.invites = [];
            state.assigned = [];
            state.trigger = null;
            state.invitesearch = [];
            state.invitesearchload = false;
            state.invitesearchfailed = null;
            state.participantsearch = [];
            state.participantsearchload = false;
            state.participantsearchfailed = null;
            state.organiserlist = [];
            state.tagslist = [];
            state.locationimage = null;
            state.startUTC = {};
            state.endUTC = {};
            state.only_invited_guests = false;
            state.is_claimable = false;
            state.alternate_profile = null;
            state.public_status = true;
            state.current_alternate_profile = null;
            state.alternate_profile_dp = '';
        },
        editcreateStart: (state, action: any) => {
            // state.id = null;
            // state.mode = null;
            // state.currentstage = 1;
            // state.reachedstage = 1;
            // state.imagename = 'click here';
            // state.imageid = null;
            // state.imagefile = null;
            // state.imagetype = null;
            // state.title = '';
            // state.organisers = [];
            // state.tags = [];
            // state.categories = [];
            // state.description = '';
            // state.rating = 1;
            // state.eventtype = 'venue';
            // state.lat = 0;
            // state.lng = 0;
            // state.startdate = '';
            // state.enddate = '';
            // state.from = '';
            // state.to = '';
            // state.invites = [];
            // state.assigned = [];
            // state.trigger = null;
            // state.invitesearch = [];
            // state.invitesearchload = false;
            // state.invitesearchfailed = null;
            // state.participantsearch = [];
            // state.participantsearchload = false;
            // state.participantsearchfailed = null;
            // state.organiserlist = [];
            // state.tagslist = [];

            state.id = action.payload.id;
            state.currentstage = action.payload.current;
            state.reachedstage = action.payload.reached;
            state.imagename = action.payload.url;
            state.imageid = action.payload.imageid;
            state.imagefile = { name: action.payload.url };
            state.imagetype = action.payload.imgtype;
            state.title = action.payload.title;
            state.organisers = action.payload.org;
            state.tags = action.payload.tags;
            state.categories = action.payload.categories;
            state.description = action.payload.desc;
            state.rating = action.payload.rating;
            state.eventtype = action.payload.eventtype;
            state.lat = action.payload.lat;
            state.lng = action.payload.lng;
            state.startdate = action.payload.start;
            state.enddate = action.payload.end;
            // state.from = action.payload.from;
            // state.to = action.payload.to;
            state.invites = action.payload.invites;
            state.assigned = action.payload.assigned;
            state.organiserlist = action.payload.orglist;
            state.tagslist = action.payload.taglist;
            state.locationimage = null;

            state.startUTC = action.payload.start_timezone;
            state.endUTC = action.payload.end_timezone;
            state.only_invited_guests = action.payload.only_invited_guests
            state.is_claimable = action.payload.is_claimable
            state.current_alternate_profile = action.payload.current_alternate_profile
            state.public_status = action.payload.public_status;
            state.location = action.payload.location
            state.repeat_event = action.payload.repeat_event
            state.shop_details = action.payload.shop_details
            state.custom_form = action.payload.custom_form

            



        },
        editchangeMode: (state, action: any) => {
            // state.mode = action.mode;
            state.imagetype = action.payload.imgtype;
        },
        ebothStageUpdate: (state, action: any) => {
            state.currentstage = action.payload.currentstage;
            state.reachedstage = action.payload.reachedstage;
        },
        ecurrentStageUpdate: (state, action: any) => {
            state.currentstage = action.payload.currentstage;
        },
        reachedStageUpdate: (state, action: any) => {
            state.reachedstage = action.payload.reachedstage;
        },
        egeneral: (state, action: any) => {
            console.log("Action" , action.payload)
            state.imagename = action.payload.imagename === null ? state.imagename : action.payload.imagename.name;
            state.imagefile = action.payload.imagename === null ? state.imagename : action.payload.imagename;
            state.title = action.payload.title;
            state.categories = action.payload.categories;
            state.description = action.payload.about;
            state.rating = action.payload.rating;
            state.is_claimable = action.payload.is_claimable;
            state.alternate_profile = action.payload.alternate_profile;
            state.alternate_profile_username = action.payload.alternate_profile_username;
            state.alternate_profile_dp = action.payload.alternate_profile_dp;
            state.shop_details = action.payload.shop_details
            state.custom_form = action.payload.custom_form
        },
        elocation: (state, action: any) => {
            state.eventtype = 1;
            state.lat = action.payload.lat;
            state.lng = action.payload.lng;
            state.location = action.payload.loc;
            state.state = action.payload.state;
            state.country = action.payload.country;
        },
        elocationimage: (state, action: any) => {
            state.locationimage = action.payload.image;
        },
        etime: (state, action: any) => {
            state.startdate = action.payload.start;
            state.enddate = action.payload.end;
            // state.from = action.payload.from;
            // state.to = action.payload.to;
        },
        einviteSearchLoad: (state) => {
            state.invitesearchload = true;
            state.invitesearchfailed = null;
        },
        einviteSearch: (state, action: any) => {
            state.invitesearch = action.payload;
            state.invitesearchload = false;
            state.invitesearchfailed = null;
        },
        einviteSearchFailed: (state, action: any) => {
            state.invitesearchfailed = action.payload;
            state.invitesearchload = false;
        },
        einvites: (state, action: any) => {
            if (state.invites.includes(action.payload)) {
                state.invites = [...state.invites.filter((id) => id !== action.payload)]
            } else {
                state.invites = [...state.invites, action.payload]
            }
        },

        eparticipantSearchLoad: (state) => {
            state.participantsearchload = true;
            state.participantsearchfailed = null;
        },
        eparticipantSearch: (state, action: any) => {
            state.participantsearch = action.payload;
            state.participantsearchload = false;
            state.participantsearchfailed = null;
        },
        eparticipantSearchFailed: (state, action: any) => {
            state.participantsearchfailed = action.payload;
            state.participantsearchload = false;
        },
        eassigned: (state, action: any) => {
            if (action.payload.role === 'Assign role') {
                state.assigned = [...state.assigned.filter(
                    (value) => value.id !== action.payload.id
                )]
            } else {
                state.assigned = [...state.assigned.filter(
                    (value) => value.id !== action.payload.id
                )]
                state.assigned = [...state.assigned,
                {
                    id: action.payload.id,
                    role: action.payload.role
                }
                ]
            }
        },
        addcategories: (state, action: any) => {
            if (state.categories.includes(action.payload)) {
                state.categories = [...state.categories.filter((id) => id !== action.payload)]
            } else {
                // state.categories = [...state.categories, action.payload]
                state.categories = [action.payload]
            }
        },
        eremain1: (state) => {
            state.currentstage = 1;
            state.reachedstage = 1;
            state.eventtype = 1;
            state.lat = 0;
            state.lng = 0;
            // state.startdate = '';
            // state.enddate = '';
            // state.from = '';
            // state.to = '';
            // state.invites = [];
            // state.assigned = [];
        },
        eremain2: (state) => {
            state.currentstage = 2;
            state.reachedstage = 2;
            state.startdate = '';
            state.enddate = '';
            // state.from = '';
            // state.to = '';
        },
        eremain3: (state) => {
            state.currentstage = 3;
            state.reachedstage = 3;
            state.invites = [];
        },
        eremain4: (state) => {
            state.currentstage = 4;
            state.reachedstage = 4;
            state.assigned = [];
        },
        edittrigger: (state, action: any) => {
            state.trigger = action.payload;
        },
        edittriggerclear: (state) => {
            state.trigger = null;
        },
        editeventtype: (state, action) => {
            state.eventtype = action.payload;
        },
        organiserlistadd: (state, action: any) => {
            state.organisers = [...state.organisers, action.payload.org];
            state.organiserlist = [...state.organiserlist, action.payload.orglist]
        },
        organiserlistremove: (state, action: any) => {
            state.organisers = [...state.organisers.filter((value) => value !== action.payload.org)];
            state.organiserlist = [...state.organiserlist.filter((val) => val.id !== action.payload.org)]
        },
        taglistadd: (state, action: any) => {
            state.tags = [...state.tags, action.payload.tag];
            state.tagslist = [...state.tagslist, action.payload.taglist]
        },
        taglistremove: (state, action: any) => {
            state.tags = [...state.tags.filter((value) => value !== action.payload.tag)];
            state.tagslist = [...state.tagslist.filter((val) => val.id !== action.payload.tag)]
        },


    }
})
export const {
    eremain1, eremain2, eremain3, eremain4, edittrigger, edittriggerclear, editeventtype,
    clearedit, editcreateStart, ebothStageUpdate, editchangeMode, elocationimage,
    ecurrentStageUpdate, egeneral, einviteSearch, einviteSearchFailed,
    einviteSearchLoad, eparticipantSearch, eparticipantSearchFailed,
    eparticipantSearchLoad, elocation, reachedStageUpdate, etime, einvites, eassigned,
    addcategories,
    organiserlistadd, organiserlistremove, taglistadd, taglistremove
} = addEventSlice.actions

export default addEventSlice.reducer