import React, { useEffect, useState } from 'react'
import './followbutton.css'
import { CircularProgress } from '@material-ui/core'
import Api from '../../../app/api'
import { useHistory, useRouteMatch } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { removefollowing } from '../../pages/following/store/followingSlice'
import { featuredfollowupdate } from '../../pages/featuredprofiles/store/featuredSlice'
import { userchangestat } from '../../Tabs/profile/store/otherProfileSlice'

const FollowButton = ({ following, requested, id, unf, place, featuredkey }: { following: boolean | undefined, requested: boolean | undefined, id: any, unf: boolean, place: any, featuredkey?: any }) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const [loading, setloading] = useState(false)
    const [followng, setfollowng] = useState(following)
    const [req, setreq] = useState(requested)


    function setstates(){
        setfollowng(following)
        setreq(requested)
    }
    useEffect(() => {
        setstates()

    }, [following,requested])
    const success = (data: any) => {
        if (data.data) {
            setfollowng(data.data.following)
            setreq(data.data.follow_request)
            if (place === "featured") {
                dispatch(featuredfollowupdate(
                    {
                        key: featuredkey,
                        following: data.data.following,
                        req: data.data.follow_request
                    }))
            }else if(place === "otherprofile"){
                dispatch(userchangestat(
                    {   
                        type: "follow",
                        key: featuredkey,
                        following: data.data.following,
                        req: data.data.follow_request
                    }))
            }
        }

        // setfollowng(true)
        setloading(false)
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        setloading(false)
    }
    const Follow = (id: any) => {
        setloading(true)
        let theurl = `/api/v1/user/follow/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/user/follow/?auth_profile=${parseddetails[0].id}`
          }
    
        }
        Api('post', theurl, { following: id }, success, failed, history, true, true)
    }


    const Unfollowsuccess = (data: any) => {
        console.log(data)
        setloading(false)
        setfollowng(false)

        if (data.data) {
            // setfollowng(data.data.following)
            // setreq(data.data.follow_request)
            if (place === "otherprofile") {

                dispatch(userchangestat(
                    {   
                        type: "follow",
                        key: featuredkey,
                        following: data.data.following,
                        req: data.data.follow_request
                    }))
            }
        }
        // if (place === 'following') {
        //     dispatch(removefollowing(id))
        // }
        // if(data.app_data === "Connection removed."){
        //     setfollowng(false)
        // }
    }
    const Unfollowfailed = (data: any) => {
        console.log(data)
        setloading(false)

    }

    const Unfollow = (id: any) => {
        setloading(true)
        let theurl = `/api/v1/user/follow/${id}/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/user/follow/${id}/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('delete', theurl, null, Unfollowsuccess, Unfollowfailed, history, true, true)
    }




    return (
        <button className={
            `${followng ? "mainBtnInv"
                : req ? "mainBtnInv"
                    : "mainBtn"
            }`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (followng === false) {
                    if (req === false) {
                        Follow(id);
                    }
                } else {
                    if (unf === true) {
                        Unfollow(id)
                    }
                }
            }}>
            {loading === false ? (
                followng ? (
                    (unf ? 'Unfollow' :
                        "Following")
                ) : req ? (
                    "Requested"
                ) : (
                    "Follow"
                )
            ) : (
                <span
                    style={{
                        maxHeight: "13px",
                        fontSize: "13px",
                        overflow: "hidden",
                        color: 'white'
                    }}
                >
                    <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                </span>
            )}
        </button>
    )
}

export default FollowButton