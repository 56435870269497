import React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material';
// import CloseIcon from "@material-ui/icons/Close";

const ContentCustomDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-rounded': {
	  borderRadius : '12px',
	},
  }));

interface Iprops {
	open: boolean;
	handleClose: () => void;
    style?: React.CSSProperties | undefined
}

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ModalLayout: React.FC<Iprops> = ({ children, open, handleClose ,style }) => {
	return (
		<ContentCustomDialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby='alert-dialog-slide-description'
		 style={ style ? {...style, borderRadius : '50% !important' } : { borderRadius : '50% !important' }} 
		 className={' !tw-rounded-xl'} >
			{children}
            {/* <CloseIcon onClick={handleClose} style={{position: 'fixed' , right: '1vw' , top: '1vh'}} /> */}
		</ContentCustomDialog>
	);
};

export default ModalLayout;