import React, { useEffect, useState, useLayoutEffect } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";
import './App.css';
import MainApp from './MainApp'
//routes

import CommonRoute from "./routes/CommonRoute";
// others
import {
  isMobile
} from "react-device-detect";
import { useAppDispatch, useAppSelector } from "./app/hooks";


import { getToken } from "firebase/messaging";
import { colorHelper, sitelink } from "./Data/Data";
import logo from './assets/images/iconnew.png';
import { addmessagetochat, getsocket } from "./components/Tabs/chat/store/ChatSlice";
import { store } from "./app/store";
import ContentModerator from "./components/Tabs/contentmoderator/main/ContentModerator";
import LandingPage from "./components/mobileview/LandingPage";
import Legal from "./components/mobileview/Legal";
import MailVerified from "./components/pages/mailverified/MailVerified";
import Chat from "./components/Tabs/chat/Chat/Chat";
import WebLandingPage from "./components/landingpage/WebLandingPage";
import GuestTicket from "./components/guest/GuestTicket";
import SelectCategories from "./components/login/SelectCategories";
import HomePage from "./withoutlogin/EventSearch/HomePage";
import EventDetailWl from "./withoutlogin/EventDetail/EventDetailWl";

function App() {

  const history = useHistory()
  const { isAuth } = useAppSelector(state => state.login)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { profile } = useAppSelector(state => state.profile)

  if (isMobile) {
    // redirect to the mobile web application
    window.location.replace('http://m.staging.mutualevents.co');
  }


  return (
    <div className="App">
      <MainApp />
    </div>
  );
}
export default App;

