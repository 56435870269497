import React, { useState } from 'react'
import 'react-phone-input-2/lib/style.css'
// import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
// import { isPossiblePhoneNumber,parsePhoneNumber } from 'react-phone-input-2'
import { useEffect } from 'react'
import { useSearchUser } from '../smallcomponents/tagsinput/useSearchUser'
import { CheckCircle } from '@material-ui/icons';

const PhoneNumberValidate = ({ value, setValue, valid, setValid, code, setCode }: any) => {

    // let cdcode = '+' + code
    // let slice1 = code.length
    // let slice2 = value.length
    // let phn = value.slice(slice1, slice2)


    const [curVal, setcurVal] = useState(value)

    const { loading: phoneLoading, data: phoneData, error: phoneError } = useSearchUser(value, '/api/v1/user/validations/check/phone/', true, {
        country_code: code.includes("+") ? code : "+" + code,
        phone: value
    })


useEffect(() => {

    if(phoneData?.data?.app_data === "Phone Ok"){
        setValid(true)

    }else{

        setValid(false)
    }
  
 
}, [phoneData, setValid])


    return (
        <>
            <div className="" style={{width :"100%"}}>
                <div style={{ marginBottom: "5px" , display:"flex" ,justifyContent: 'space-between', alignItems:"center" }} className="" >
                <label className='sglabel'>Mobile </label>
                    {/* {titleerror && <small>required*</small>} */}

                    <div style={{ display: 'flex', justifyContent: 'space-between', }}>


{phoneError && <><small style={{ color: "red", display: 'flex', alignItems: 'center', gap: "3px" }}>
    {phoneError?.response?.data?.app_data}
    {/* <HelpOutline style={{ color: "#e7eb1ef", width: "18px", height: "18px" }} /> */}

</small>

</>

}
{
    valid  && <CheckCircle style={{ color: "#09e75f", width: "18px", height: "18px" }} />
}
</div>
                </div>
                {/* <small className="Signupvalidate phnnumbervalidate">
                </small> */}
           
            </div>
            <PhoneInput
                placeholder="Enter phone number"
                value={curVal}
                country='au'
           
                // country={61}

                // defaultCountry="AU"
                onChange={(val: any, country: any, excludeCountries: any) => {
                    console.log(val)
                    console.log("country",country)
                    setcurVal(val)
                    setCode(country.dialCode)
                    setValue(val.slice(country.dialCode.length))
                }} />
        </>
    )
}

export default PhoneNumberValidate
