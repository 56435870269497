import React from 'react'
import Toprated from '../toprated/Toprated'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { useHistory } from 'react-router'

const TopratedPage = () => {
    const history = useHistory()
    return (
        <div className='topratedPage container'>
            <div className='commonHeading'>
                <small onClick={() => {
                    history.push('/')
                }}><Arrow />Back to home page</small>
                <span></span>
            </div>
            <Toprated mode={'pagination'} />
        </div>
    )
}

export default TopratedPage
