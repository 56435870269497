import React, { useEffect, useState } from 'react'
import { ReactComponent as Delete } from '../../../assets/svg/trash.svg';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
// import { profile } from '../../Tabs/profile/dummydata';
import './personalinformation.css'
import { CircularProgress } from '@material-ui/core';
import { profileSuccess, profileFailed, profileRequest,update_profile } from '../../Tabs/profile/store/profileSlice';
import Api from '../../../app/api';
import { useHistory } from 'react-router';
import ProfileDialog from '../../smallcomponents/cropmodals/ProfileDialog'
import {IoReturnUpBackOutline} from 'react-icons/io5'
import {BiArrowBack} from 'react-icons/bi'

const PersonalInfo = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { profile, loading, error, media, events, stories } = useAppSelector(state => state.profile)
  const { firstname, surname, username, phone, bio, dp } = profile
  const success = (data: any) => {
    dispatch(profileSuccess(data))
    console.log(data)
  }
  const failed = (data: any) => {
    console.log(data)
    dispatch(profileFailed(data))
  }
  useEffect(() => {
    if ((Object.keys(profile).length) === 0  && loading === false) {
      dispatch(profileRequest())
      Api('get', "/api/v1/user/profile/", null, success, failed, history, true, true)
    }
  }, [])

  const [FirstName, setFirstName] = useState(firstname);
  const [LastName, setLastName] = useState(surname);
  const [UserName, setUserName] = useState(username);
  const [Website, setWebsite] = useState('');
  const [Address, setAddress] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState(phone);
  const [About, setAbout] = useState(bio);
  const [UserVerify, setUserVerify] = useState(true);
  const [Loader, setLoader] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [change, setChange] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  function EditDetails(data: any, success: any, failed: any) {
    const token = localStorage.getItem("auth");
    Api('put', "/api/v1/user/edit/", data, success, failed, history,true,true)
  }
  const success1 = (data: any) => {
    // window.location.reload()
    console.log(data)
}
const failed1 = (data: any) => {
    console.log(data)

}
  const updateUser = () => {
    setLoader(true);

    let PostData = {
      firstname: FirstName,
      surname: LastName,
      username: UserName,
      bio: About,
    }

    const success = (res: any) => {
      console.log('sucess response', res)
      setLoader(false);
      dispatch(update_profile(res.data))
      // Api('get', "/api/v1/user/profile/", null, success1, failed1, history,true,true)
      history.push('/profile')

    }
    const failed = (res: any) => {
      console.log(`faield resposne`, res)
      setLoader(false);
    }

    EditDetails(PostData, success, failed)

  }
  return (
    <div className="personalinformaion-layout">
      {open && <ProfileDialog
        dialogStatus={open}
        dialogAction={setOpen}
        // fetchProfile={fetchProfile}
        setChange={setChange}
        change={change}
        currentDp={dp}
      />
      }
      <div className='backBtn'><BiArrowBack onClick={()=>{
        history.goBack()
      }}/></div>

      <div className="personalinformaion-content">


        <div className="personalinformaion-title"> Personal Information</div>

        <div className="personalinformaion-top-section">
          <div className="title"> {`${firstname || ''} ${surname || ''}`}</div>
          <div className="avatar">
            <img alt='P_I_C' src={dp} />
          </div>
          <div className="avatar-buttons">
            <div className="invBtn"
              onClick={
                e => {
                  handleClickOpen();
                }
              }
            >
              Change
            </div>
            {/* <div className="invBtn2">
              <Delete />
            </div> */}
          </div>

          <div className="endnote">
            We recommend  uploading atleast 512x512 photo of yourself.
            JPG or PNG.Max size of 1 MB.
          </div>
        </div>


        <div className="personalinformaion-middle-section">


          <div className="input-box">
            <p> First name </p>
            <input type="text"
              // value ={ ( typeof FirstName === 'undefined' || FirstName === null ) ? firstname : FirstName }
              value={FirstName}
              onChange={(e) => { setFirstName(e.target.value) }}
            />
          </div>
          <div className="input-box">
            <p> Last name </p>
            <input type="text"
              // value ={ ( typeof LastName === 'undefined' || LastName === null ) ? surname : LastName }
              value={LastName}
              onChange={(e) => { setLastName(e.target.value) }}

            />
          </div>
          <div className="input-box">
            <p> Username</p>
            <input type="text"
              //  value ={ ( typeof UserName === 'undefined' || UserName === null ) ? username : UserName }
              value={UserName}
              onChange={(e) => {
                setUserName(e.target.value)
                // Validateusername(e.target.value)
              }}
            />
          </div>

          <div className="input-box">
            <p> Phone number </p>
            <input type="text"
              // value ={ ( typeof PhoneNumber === 'undefined' || PhoneNumber === null ) ? phone : PhoneNumber }
              value={PhoneNumber}
              onChange={(e) => { setPhoneNumber(e.target.value) }}
              style={{ color: 'lightgrey' }}
            />
          </div>


          <div className="input-box" >
            <p> About </p>
            <textarea style={{ height: '150px', width: '688px' }}
              value={About}
              // value ={ ( typeof About === 'undefined' || About === null ) ? bio : About }
              onChange={(e) => { setAbout(e.target.value) }}
            />
          </div>


        </div>


        <div className="personalinformaion-end-section">
          <div className="invBtn"
            onClick={e => { history.goBack() }}
          >Cancel</div>
          <div className="btn"
            onClick={() => updateUser()}
          >

            {
              Loader ? (
                <CircularProgress size={18} color="inherit" />
              ) : 'Save changes'

            }
          </div>
        </div>

      </div>
      {/* {open && <Dialog
            dialogStatus={open}
            dialogAction={setOpen}
            fetchProfile={fetchProfile}
            setChange={setChange}
            change={change}
            currentDp={ProfileData.dp || Default}
          />
          } */}
    </div>

  )
}

export default PersonalInfo
