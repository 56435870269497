import React, { useState } from 'react';
import './featuredprofile.css';
import FollowButton from '../followbutton/FollowButton';
import { Featured } from '../../pages/featuredprofiles/store/interface';
import { useHistory } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { featuredfollowupdate } from '../../pages/featuredprofiles/store/featuredSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Api from '../../../app/api';

const FeaturedProfileItem2 = ({ featured, k, unf, place }: { featured: Featured; k: number; unf: boolean; place: any }) => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { dp, dp_thumbnail, firstname, follow_request, is_following, id, surname, username, usertype, custom_form } = featured;

	const { profile: prof } = useAppSelector((state) => state.profile);
	const [loading, setloading] = useState(false);
	const [followng, setfollowng] = useState(is_following);
	const [req, setreq] = useState(follow_request);

	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);

	const success = (data: any) => {
		if (data.data) {
			setfollowng(data.data.following);
			setreq(data.data.follow_request);
			dispatch(
				featuredfollowupdate({
					key: k,
					following: data.data.following,
					req: data.data.follow_request,
				})
			);
		}

		// setfollowng(true)
		setloading(false);
		console.log(data);
	};
	const failed = (data: any) => {
		console.log(data);
		setloading(false);
	};
	const Follow = (id: any) => {
		setloading(true);
		let theurl = `/api/v1/user/follow/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/user/follow/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('post', theurl, { following: id }, success, failed, history, true, true);
	};

	const Unfollowsuccess = (data: any) => {
		console.log(data);
		setloading(false);
		setfollowng(false);
		// if (place === 'following') {
		//     dispatch(removefollowing(id))
		// }
		// if(data.app_data === "Connection removed."){
		//     setfollowng(false)
		// }
	};
	const Unfollowfailed = (data: any) => {
		console.log(data);
		setloading(false);
	};

	const Unfollow = (id: any) => {
		setloading(true);
		let theurl = `/api/v1/user/follow/${id}/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/user/follow/${id}/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('delete', theurl, null, Unfollowsuccess, Unfollowfailed, history, true, true);
	};
	return (
		<div
			className='featuredprofile'
			onClick={() => {
				if (id === prof.id) {
					history.push('/profile');
				} else {
					history.push(`/user/${username}`);
				}
			}}>
			<img alt='P_I_C' src={dp || "https://d20rv3nuwgisca.cloudfront.net/media/defaults/event/default-event-cover.png"} ></img>
			<div className='featuredNames !tw-max-w-max' >
				{usertype === 'guestuser' ? (
					<div className='tw-flex '>
						<h3>{custom_form?.Name}
                        <small
							className='tw-ml-1 tw-p-2 !tw-font-semibold !tw-text-black tw-bg-slate-200 !tw-rounded-lg !tw-w-32 !tw-full tw-truncate'
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
							}}>
							GUEST USER
						</small>
                         </h3>

					
					</div>
				) : (
					<h3>{firstname + ' ' + surname}</h3>
				)}
				<span>{username}</span>
			</div>
			{/* <FollowButton following={is_following} requested={follow_request} id={id} unf={unf} place={place}/> */}
			{/* <button className={
            `${followng ? "mainBtnInv"
                : req ? "mainBtnInv"
                    : "mainBtn"
            }`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log(unf,followng)
                if (followng === false) {
                    if (req === false) {
                        Follow(id);
                    } 
                }else {
                    console.log('unf',true)
                    if (unf === true) {
                        console.log('unf true')
                        Unfollow(id)

                    }
                }
            }}>
            {loading === false ? (
                followng ? (
                    (unf ? 'Unfollow' :
                    "Following")
                ) : req ? (
                    "Requested"
                ) : (
                    "Follow"
                )
            ) : (
                <span
                    style={{
                        maxHeight: "13px",
                        fontSize: "13px",
                        overflow: "hidden",
                        color: 'white'
                    }}
                >
                    <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                </span>
            )}
        </button> */}
		</div>
	);
};

export default FeaturedProfileItem2;
