import React, { useEffect } from 'react'
import Recommended from '../../../pages/recommended/Recommended'
import Popular from '../../../pages/popular/Popular'
import Toprated from '../../../pages/toprated/Toprated'
import Api from '../../../../app/api'
import { homeRequest,homeFailed,homeSuccess } from '../store/homeSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { useHistory } from 'react-router-dom'
const Home = () => {
    const dispatch = useAppDispatch();
    const history = useHistory()
    const success = (data: any) => {
        dispatch(homeSuccess(data))
        // console.log(data)
    }
    const failed = (data: any) => {
        // console.log(data)
        dispatch(homeFailed(data))
    }
    const {lat,lng} = useAppSelector(state => state.profile)
    // const lat = '10.8505159'
    // const lng = '76.2710833'
    console.log(lat,lng)
    let data = {
        latitude: lat,
        longitude: lng,
    }
    
    useEffect(() => {
        // if()
        dispatch(homeRequest())
        let crnt: any = localStorage.getItem('mtusr')
        let current = JSON.parse(crnt)

        let c : any = localStorage.getItem('pdetails')
        let url= `/api/v1/event/explore/v2/`
        if(c){
            let parsedc = JSON.parse(c)
            url= current.is_business_profile? `/api/v1/event/explore/v2/?auth_profile=${parsedc[0].id}`:`/api/v1/event/explore/v2/`
    
        }

        Api('post',url,data,success,failed,history,true,true)
    }, [lat,lng])
    return (
        <div className='home minvh100'>
            <Recommended mode={'loadmore'}/>
            <Popular mode={'loadmore'}/>
            <Toprated mode={'loadmore'}/>
        </div>
    )
}

export default Home
