import React, { useState } from 'react'
import dayjs from 'dayjs'
import { makeStyles } from "@material-ui/core/styles";
import Rating from 'react-rating'
import { ReactComponent as Star1 } from '../../../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../../../assets/svg/star2.svg';
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg';
import './eventreview.css'
import { useHistory, useParams } from 'react-router-dom';
import { Filter, HandleTime } from '../../../../Data/Data';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { updaterating } from '../store/eventSlice';
import Api from '../../../../app/api';
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    rating: {
        transform: 'scale(1.5)',
        marginLeft: '10px'
    }
}));


const MiniEventReview = ({ permissions, reviewList, count ,review_info}: any) => {
    const { eventId }: any = useParams()
    const dispatch = useAppDispatch()
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
console.log("review_info",review_info)
    const { event } = useAppSelector(state => state.event)
    const {profile} = useAppSelector(state=>state.profile)

    const classes = useStyles();
    console.log(permissions)
    console.log(reviewList)
    const history = useHistory()

    const [value, setValue] = useState(0);
    const [reviewload, setreviewload] = useState(false)
    // const [reviewvisible, setreviewvisible] = useState(permissions?.write_review)
    // const [initial, setinitial] = useState(event.total_rating)
    // const [reviewed, setreviewed] = useState(event.is_reviewed)
    const [reviewMessage, setMessage] = useState('')
    const [editReveiwed, seteditReveiwed] = useState(false)
    console.log("review_info reviewMessage",reviewMessage)

    const success1 = (data: any) => {
        
        // setreviewvisible(false)
        setMessage('')
        // setinitial(event.total_rating)
        console.log("I am data::",data)
        dispatch(updaterating({
            rating: data.data.total_rating,
            data: {
                id: 0,
                reviewer_id: profile.id,
                reviewer_firstname: profile.firstname,
                reviewer_surname: profile.surname,
                reviewer_username: profile.username,
                reviewer_dp: profile.dp,
                rating: data.data.total_rating,
                review: reviewMessage,
                created_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
                number_of_reviews: 1
              }
        }))
        setreviewload(false)
    }
    const failed1 = (data: any) => {
        console.log(data)
        setreviewload(false)
        // setreviewvisible(false)
        setMessage('')
        // setinitial(event.total_rating)
    }
    function PostReview(filtered:any) {
        let data = {
            "event": event.id,
            "rating": value,
            "review": filtered,
        };
        // dispatch(updaterating(2))
        let theurl = `/api/v1/event/review/write/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/review/write/?auth_profile=${parseddetails[0].id}`
            }

        }
        setreviewload(true)
        Api('post', theurl, data, success1, failed1, history, true, true)
    }
    const EditReview = (filtered:any , reviewId:number) => { 
        let data = {
            "rating": value,
            "review": filtered,
        };
        // dispatch(updaterating(2))
        let theurl = `/api/v1/event/review/edit/${reviewId}/`
        // {{baseurl}}/api/v1/event/review/edit/95/?auth_profile=53
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/review/edit/${reviewId}/?auth_profile=${parseddetails[0].id}`
            }

        }
        setreviewload(true)
        Api('put', theurl, data, success1, failed1, history, true, true)
     }

    const ReviewEvent = (value: any) => {
        setValue(value)
    }

    return (
        <div className='event_review_wrapper'>
            <h3>Reviews
                {/* {count > 2?<span>Show all</span>: null} */}
            </h3>
            <div className='reviews_container'>
                {/* <Rating
                    initialRating={value}
                    emptySymbol={<Star2/>}
                    fullSymbol={<Star1 />}
                    // readonly
                // readonly={permissions?.write_review === true ? false : true}
                onChange={ReviewEvent}
                // onClick={() => {
                //     // alert('nthuvada')
                //     if (permissions?.write_review === false) {
                //         let target: any = refalert.current
                //         if (target) {
                //             target.style.display = 'block'
                //             setTimeout(() => {
                //                 target.style.display = 'none'
                //             }, 3000);
                //         }
                //     }
                // }}
                /> */}
                {/* {
                    permissions?.write_review && (
                        <div className='reviewcontainer'>
                            <span className='reviewtriangle'></span>
                            <textarea className='reviewinput' placeholder='Write your review...'
                                onChange={(e) => {
                                    let target = e.currentTarget
                                    setMessage(Filter(target.value))
                                }}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        setreviewload(true)
                                        PostReview();
                                        // Review(reviewvisible, e.currentTarget.value)
                                    }
                                }}></textarea>
                            <div className='reviewbtncontainer'>
                                <div></div>
                                <div className="AddEventCancelBtn reviewbtn"
                                    onClick={e => {
                                        setreviewvisible(false);
                                        setValue(0);
                                        setreviewload(false);
                                        setMessage('')
                                    }
                                    }>Cancel</div>
                                <div className="AddEventNextBtn reviewbtn"
                                    onClick={e => {
                                        setreviewload(true);
                                        PostReview();
                                        // Review(reviewvisible, document.getElementsByClassName('reviewinput').value)

                                    }}>{reviewload ? (<span style={{ maxHeight: '13px', fontSize: '13px', overflow: 'hidden' }}><CircularProgress style={{ height: '13px', width: '13px' }} color='inherit' /></span>) : 'Post review'}</div>
                            </div>
                        </div>)
                } */}
                {reviewList ? Object.keys(reviewList).length !== 0 ? (
                    reviewList.slice(0, 3).map((reviewitem: any, key: number) => {
                        const {
                            id,
                            reviewer_id,
                            reviewer_firstname,
                            reviewer_surname,
                            reviewer_username,
                            reviewer_dp,
                            rating,
                            review,
                            created_at,
                            number_of_reviews
                        } = reviewitem
                        return (
                            <div className='event_review_item' key={key.toString()}>
                                <div className='event_review_img'>
                                    <img src={reviewer_dp} />
                                </div>
                                <div className='event_review_details'>
                                    <h4>{reviewer_firstname + ' ' + reviewer_surname}</h4>
                                    <small className='no_of_reviews'>{number_of_reviews} reviews</small>
                                    <div className='event_review_box'><Rating
                                        initialRating={rating}
                                        emptySymbol={<Star2 />}
                                        fullSymbol={<Star1 />}
                                        readonly
                                    /> <small className='no_of_reviews'>{HandleTime(created_at)}</small></div>
                                    <p>{review}</p>
                                </div>
                            </div>
                        )
                    })) : (
                    <span className='no_reviews'>no reviews yet</span>
                )
                    : null

                }
                {count > 2 ? <div className='event_review_showall' onClick={() => {
                    history.push(`/event/${eventId}/reviews`)
                }}><span>See all reviews</span><Arrow path='var(--cl_orange)' /></div> : null}

                {(permissions.write_review || editReveiwed )&& <div style={{ margin: '10px 0 10px 0' }}>
                    {/* <h4 style={{margin:'10px 0'}}>Write review</h4> */}

                    <textarea className='reviewinput' placeholder='Write your review...'
                    value={reviewMessage}
                        onChange={(e) => {
                            let target = e.currentTarget
                            setMessage(target.value)
                            // setMessage(Filter(target.value))
                        }}
                    // onKeyPress={e => {
                    //     if (e.key === 'Enter') {
                    //         setreviewload(true)
                    //         // PostReview();
                    //         // Review(reviewvisible, e.currentTarget.value)
                    //     }
                    // }}
                    ></textarea>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px', padding: '10px 0' }}>
                        {/* <button className='mainBtnInv'>Cancel</button> */}

                        <div style={{ padding: '0px 0 15px 10px', display: 'flex', gap: '25px' }}>
                            <Rating
                                initialRating={value}
                                emptySymbol={<Star2 />}
                                fullSymbol={<Star1 />}
                                className={classes.rating}
                                // style={{height:'40px'}}
                                // quiet={true}
                                // readonly
                                // readonly={permissions?.write_review === true ? false : true}
                                onChange={ReviewEvent}
                            // onClick={() => {
                            //     // alert('nthuvada')
                            //     if (permissions?.write_review === false) {
                            //         let target: any = refalert.current
                            //         if (target) {
                            //             target.style.display = 'block'
                            //             setTimeout(() => {
                            //                 target.style.display = 'none'
                            //             }, 3000);
                            //         }
                            //     }
                            // }}
                            />
                            <span style={{ fontSize: '13px' }}>{value}/5.0</span>
                        </div>
                        {
                            editReveiwed ?  <button className='mainBtn' onClick={()=>{
                                // console.log(Filter(reviewMessage))
                                EditReview(Filter(reviewMessage),review_info?.id)
                            }}>{reviewload?<CircularProgress style={{color:'white',height:'20px',width:'20px'}}/>:'Edit review'}</button> :
                       
                        <button className='mainBtn' onClick={()=>{
                            // console.log(Filter(reviewMessage))
                            PostReview(Filter(reviewMessage))
                        }}>{reviewload?<CircularProgress style={{color:'white',height:'20px',width:'20px'}}/>:'Post review'}</button>
                    }
                    </div>




                </div>}
                {
                    permissions.edit_review && !editReveiwed && (
                        <button className='mainBtn' onClick={()=>{

                            setMessage(review_info.review)
                            setValue(review_info.rating)
                            seteditReveiwed(true)
                            // console.log(Filter(reviewMessage))
                            // PostReview(Filter(reviewMessage))
                        }}>{reviewload?<CircularProgress style={{color:'white',height:'20px',width:'20px'}}/>:'Edit review'}</button>
                    )
                }


            </div>
        </div>
    )
}

export default MiniEventReview