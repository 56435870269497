import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import SingleImageModal from '../../../smallcomponents/modals/SingleImageModal';
import { IoMdDownload } from 'react-icons/io'
import { MdAutoAwesome, MdDone, MdDoneAll, MdSchedule } from 'react-icons/md'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as BsPlayFill } from '../../../../assets/svg/video.svg';
import utc from 'dayjs/plugin/utc'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { CircularProgress } from '@material-ui/core';
import { chatreplyactive, deleteMessagesSelected } from '../store/ChatSlice';
import { FolderOpen } from '@material-ui/icons';
import Api from '../../../../app/api';

dayjs.extend(utc)


const SentMessage = ({ msg, key, mini = false }: any) => {
  const { id, sender, active_status, chat_reference_id,
    created_at, message, message_type, read_status, uploaded_files,
    location_image, addressline_2, contact_info, justsend, reply_status, reply_reference_id } = msg;

  const { socket: unparsedsocket, openedchatdetails } = useAppSelector(state => state.chat)
  const menuref: any = useRef()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [curimg, setcurimg] = useState<any>(null)
  const [modalopen, setmmodalopen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation();
    console.log(event)
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget)
  };
  const handleClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    console.log('close triggered')
  };

  function rightClick(clickEvent: any) {
    clickEvent.preventDefault();
    console.log('clicked')
    // setmenu(k)
    handleClick(clickEvent)
  }
  if (menuref) {
    if (menuref.current) {
      // if(!mini){
      menuref.current.oncontextmenu = rightClick;
      // }

    }
  }


  //   function rightClick(clickEvent: any) {
  //     clickEvent.preventDefault();
  //     if (justsend) {

  //     } else {
  //         setmenu(k)
  //     }

  // }
  // if (menuref) {
  //     if (menuref.current) {
  //         menuref.current.oncontextmenu = rightClick;
  //     }
  // }

  // useEffect(() => {
  //     const checkIfClickedOutside = (e: any) => {
  //         // If the menu is open and the clicked target is not within the menu,
  //         // then close the menu
  //         let targt: any = menuref.current
  //         if (menu === k && targt && !targt.contains(e.target)) {
  //             // setIsActive(false)
  //             setmenu(null)
  //         }
  //     }

  //     document.addEventListener("mousedown", checkIfClickedOutside)

  //     return () => {
  //         // Cleanup the event listener
  //         document.removeEventListener("mousedown", checkIfClickedOutside)
  //     }
  // }, [menu])

  const deleteChatFx = ({ id, chat_reference_id }: any) => {
    const success = (res: any) => {
      dispatch(deleteMessagesSelected(id))
      setAnchorEl(null);

    }
    const failed = () => {

    }

    let data = {
      "chat": chat_reference_id,
      "messages": [id]
    }

    Api('post', '/api/v1/ws/chat/message/delete/v2/', data, success, failed, history, true, true)
  }





  return (
    // <div className='sentmessage'>

    // </div>
    <div className='repliermessagecontainer1' id={`${id}`} ref={menuref}
      // style={{ border:"2px solid blue" }}

      onClick={() => {
        console.log(msg)


        // if(message_type ==='location'){
        //     window.open(`https://www.google.com/maps/search/${JSON.parse(message.split(/,/g)[0])},${JSON.parse(message.split(/,/g)[1])}/@${JSON.parse(message.split(/,/g)[0])},${JSON.parse(message.split(/,/g)[1])},17z`, "_blank")
        // }
      }}
      onMouseOver={() => {
        if (menuref) {
          if (menuref.current) {
            // if(!mini){
            menuref.current.oncontextmenu = rightClick;
            // }

          }
        }
      }}
    >
      {modalopen && <SingleImageModal dialogStatus={modalopen} dialogAction={setmmodalopen} currentimg={curimg} />}
      {
        message_type === 'location' ? (
          <p className={justsend && justsend === true ? 'justsend' : ''}><a style={{ textDecoration: 'none', color: 'white', textAlign: 'center', width: '100%' }}
            href={`https://www.google.com/maps/search/${JSON.parse(message.split(/,/g)[0])},${JSON.parse(message.split(/,/g)[1])}/@${JSON.parse(message.split(/,/g)[0])},${JSON.parse(message.split(/,/g)[1])},17z`}
            target="_blank">
            <img style={{ width: '100%', height: '100px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px' }} src={location_image}></img>{addressline_2}</a>
          </p>
        ) : message_type === 'file' ? (
          <p className={justsend && justsend === true ? 'justsend' : ''} onClick={() => {
            // history.push(uploaded_files[0].uploaded_file)
            window.open(uploaded_files[0].uploaded_file, "_blank")
          }} >
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ height: '40px', width: '40px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }}>
                <IoMdDownload color='var(--cl_orange)' />
              </div>
              <span>{uploaded_files[0].uploaded_file.replace('https://d20rv3nuwgisca.cloudfront.net/staging/media/chat/chat_files/', '')}</span>
            </div>
            <div style={
              { display: "flex", alignItems: "center", gap: "6px" }
            }>
              <small style={{ color: "white", display: "flex", justifyContent: "flex-end", width: "100%", fontSize: ".8rem", paddingRight: "1px" }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>
              {
                read_status ? <MdDoneAll style={{ fontSize: '.9rem' }} /> : (justsend ? <MdSchedule /> : <MdDone style={{ fontSize: '.6rem' }} />)
              }
            </div>

          </p>
        ) : message_type === 'contact' ? (<p style={{ display: 'flex', gap: '10px' }} className={justsend && justsend === true ? 'justsend' : ''}>
          <img style={{ height: '40px', width: '40px', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%' }} src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png'} />
          <div>
            <h3>{contact_info.name}</h3>
            <span>{contact_info.mobile}</span>
          </div>
        </p>
        ) : message_type === 'image' ? (<p className={justsend && justsend === true ? 'justsend' : ''} style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative' }}>
          <img alt="" onClick={() => {
            setcurimg(uploaded_files[0].uploaded_file)
            setmmodalopen(true)
          }} style={mini ? {
            minHeight: '100px', maxWidth: '200px', maxHeight: '200px',
            width: '400px', height: '400px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px'
          } : { width: '400px', maxHeight: '200px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px' }} src={justsend && justsend === true ? (msg.uploaded_files[0].uploading_file ? JSON.parse(msg.uploaded_files[0].uploading_file) : null) : uploaded_files[0].uploaded_file}></img>
          <div style={{ position: "absolute", bottom: "10px", right: "15px" }}>
            <small style={{ padding: "4px", color: "white", borderRadius: "6px", backgroundColor: "rgba(0, 0, 0, 0.2)", }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>
            {
              read_status ? <MdDoneAll style={{ fontSize: '1rem', fontWeight: "bold", }} /> : (justsend ? <MdSchedule /> : <MdDone style={{ fontSize: '.6rem' }} />)
            }
          </div>

        </p>
        ) : message_type === 'video' ? (<p className={justsend && justsend === true ? 'justsend' : ''} style={{ padding: '2px', backgroundColor: 'transparent' }}>
          <div className='cursor' style={mini ? {
            minHeight: '100px', maxWidth: '200px', maxHeight: '200px', position: 'relative',
            width: '400px', height: '400px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px'
          } : { width: '400px', height: '400px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px', position: 'relative' }} onClick={() => {
            setcurimg(uploaded_files[0].uploaded_file)
            setmmodalopen(true)
          }}>
            <video style={mini ? {
              minHeight: '100px', maxWidth: '200px', maxHeight: '200px',
              width: '400px', height: '400px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px'
            } : { width: '400px', height: '400px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px' }}>
              <source src={uploaded_files[0].uploaded_file} type="video/mp4" />
              <source src={uploaded_files[0].uploaded_file} type="video/mkv" />
              <source src={uploaded_files[0].uploaded_file} type="video/quicktime" />
              Your browser does not support the video tag.
            </video>
            <div className="videooverlayicon" style={{ height: '100%', top: '0', left: '0' }}>
              <BsPlayFill />
            </div>
          </div>


        </p>
        ) : reply_status ? (
          <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end", }}>
            <div style={{ display: 'flex', width: "200px", flexDirection: 'column', alignItems: 'flex-end', justifyContent: "flex-end" }}>
              <p style={{ display: 'flex', width: "200px", flexDirection: 'column', background: 'var(--cl_light_orange)', color: 'var(--cl_orange)', padding: '12px 0px 0px 0px' }}
                // padding: '12px 16px 25px 16px'
                onClick={(e) => {
                  e.preventDefault()
                  let target = document.getElementById(`${reply_reference_id.id}`)
                  console.log(reply_reference_id.id)
                  console.log('--------------------------------------ssssssss', reply_reference_id);

                  console.log(target)
                  // if (target) {
                  //   target.scrollIntoView({ behavior: "smooth", block: "start" })
                  // } else {
                  //   let scrolltarget: any = document.getElementsByClassName('miniuserchatcontent')
                  //   if (scrolltarget) {
                  //     console.log(scrolltarget)
                  //     if (scrolltarget[0].firstChild) {
                  //       console.log(scrolltarget[0].firstChild)
                  //       if (scrolltarget[0].firstChild.firstChild) {
                  //         scrolltarget[0].firstChild.lastChild.scrollIntoView(true)
                  //       }
                  //     }
                  //     // scrolltarget.firstChild.firstChild.scrollIntoView(true)
                  //     // scrolltarget.firstChild.scrollTo(0,0)
                  //   }
                  //   // window.scrollTo(0,0)
                  // }
                }}>
                <span style={{ padding: '6px 16px 8px 1rem', display: 'flex', flexDirection: 'column', gap: '2px', }}>
                  {openedchatdetails && openedchatdetails.chat_type === 'group' && <h4>{reply_reference_id.sender.firstname}</h4>}
                  {reply_reference_id.message_type === 'image' ? (
                    <div style={{ display: 'flex', gap: '.1rem', fontSize: '0.8rem', alignItems: 'center' }}>
                      <img style={{ height: '15px', width: '15px', borderRadius: '3px' }} src={reply_reference_id.uploaded_files[0].uploaded_file} />
                      Photo
                    </div>
                  ) : reply_reference_id.message_type === 'video' ? (
                    <div style={{ display: 'flex', gap: '.1rem', fontSize: '0.8rem', alignItems: 'center' }}>
                      <BsPlayFill />
                      video
                    </div>
                  )
                    : reply_reference_id.message_type === 'file' ?
                      <span style={{ display: "flex", alignItems: "center", gap: "2px" }}><FolderOpen /> file</span>
                      :
                      <div style={{ display: "flex", alignItems: "center", textOverflow: "ellipsis", overflow: "hidden", maxLines: "1" }}><span>{reply_reference_id.message}</span></div>
                  }
                </span>

                <p style={{ position: 'relative', minWidth: '200px', padding: '12px 16px 6px 16px', }}>
                  {/* transform: 'translateY(-13px)'  */}
                  {message}
                  <div style={{ width: '100%', maxWidth: '100%', color: 'white', fontSize: '.6rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: '3px' }}>
                    <small style={{ paddingRight: '2px' }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>
                    {
                      read_status ? <MdDoneAll style={{ fontSize: '.6rem' }} /> : (justsend ? <MdSchedule /> : <MdDone style={{ fontSize: '.6rem' }} />)
                    }


                  </div>
                </p>
              </p>

            </div>
          </div>
        ) :
          // <p className={justsend && justsend === true ? 'justsend' : ''} >{message}</p>
          (<p style={{ minWidth: '70px', padding: '12px 16px 6px 16px' }}>
            <div>
              {message}
            </div>

            <div style={{ width: '100%', maxWidth: '100%', color: 'white', fontSize: '.9rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: '3px' }}>
              <small style={{ paddingRight: '2px' }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>
              {
                read_status ? <MdDoneAll style={{ fontSize: '.8rem' }} /> : (justsend ? <MdSchedule /> : <MdDone style={{ fontSize: '.6rem' }} />)
              }


            </div>
          </p>)
        // (
        //   <div style={{ color: "white", position: "relative", padding: "12px 16px 6px 16px", border: "solid 1px", borderColor: "lightgrey", backgroundColor: 'var(--cl_orange)', borderRadius: " 12px 12px 4px 12px" }}>
        //     <div style={{ display: "flex", justifyContent: "flex-start" }}>{message}</div>
        //     <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
        //       <small style={{ paddingRight: '2px' }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>
        //       {
        //         read_status ? <MdDoneAll style={{ fontSize: '1rem' }} /> : (justsend ? <MdSchedule /> : <MdDone style={{ fontSize: '1rem' }} />)
        //       }
        //     </div>
        //   </div>
        // )
      }
      {/* message_type ==='text'?  */}

      {/* {
                justsend && justsend === true ? null :
                    menu === k &&
                    <div className='chatmessageoptions'>
                        <span></span>
                        <ul>
                            <li>Delete for me</li>
                            <li>Delete</li>
                            <li>Copy</li>
                        </ul>
                    </div>
            } */}

      {anchorEl && <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          dispatch(chatreplyactive({ mode: true, details: msg }))
          console.log('------------button clicked')

        }}>
          Reply
        </MenuItem>


        <MenuItem onClick={(e) => {
          // Deletechats()
          e.preventDefault()
          e.stopPropagation()


          deleteChatFx({ id, chat_reference_id })
        }}>
          Delete for me
        </MenuItem>
        {/* {selectedchats.length < 2 && !starred_status && <MenuItem onClick={Starchat}>Star chat</MenuItem>}
        {starred_status && (selectedchats.length === 1 || selectedchats.length === 0 && <MenuItem onClick={UnStarchat}>Unstar chat</MenuItem>)}
        {selectedchats.length < 2 && <MenuItem onClick={()=>{
          if(mute_status){
            UnMutechat()
          }else{
            Mutechat()
          }
          }}>{mute_status? 'Unmute':'Mute'}</MenuItem>} */}
      </Menu>

      }
    </div>
  );
};

export default SentMessage;


 // <MenuItem onClick={() => {
          //   // dispatch(chatselected({chat_id: user.chat_id,details: user}))
          // }}>
          //   Delete
          // </MenuItem>