export const errors:any = {
    server: {
        heading: 'Somethings wrong with our Server!',
        message: 'Please be patient while we try to fix it'
    },
    internet: {
        heading : 'Check your internet connection and try again',
        message: "We couldn't connect to the server"
        // heading : 'Somethings wrong with your network',
        // message: 'Please check your internet connection'
    },
    notFound: {
        heading: 'Page not found',
        message: 'Page you are looking for is no longer here or has been removed'
    },
    common: {
        heading: "Something's not right",
        message: 'please try refreshing the page'
    }
}
export const statuscodeerrors: any = {
    400:{
        heading: "Something's not right",
        message: 'please try refreshing the page'
    },
    404:{
        heading: 'Page not found',
        message: 'Page you are looking for is no longer here or has been removed'
    },
    405:{
        heading: "405",
        message: 'Method not allowed'
    },
    408:{
        heading: "408",
        message: 'Request time-out error'
    },
    413:{
        heading: "413",
        message: 'Request entity too large'
    },
    414:{
        heading: "414",
        message: 'Request URL too large'
    },
    415:{
        heading: "415",
        message: 'Unsupported Media Type'
    },
    429:{
        heading: "Too many requests",
        message: 'Please try after sometime'
    },
    500:{
        heading: 'Somethings wrong with our Server!',
        message: 'Please be patient while we try to fix it'
    },
    502:{
        heading: '502',
        message: 'Bad Gateway.'
    },
    503:{
        heading: 'Service temporarily unavailable',
        message: 'Our servers are undergoing maintanence please be patient'
    },
    505:{
        heading: 'Sorry',
        message: 'Unexpected error'
    }
}
export const successcodes: any = {
    200:{
        head: 'this is a success'
    },
    201:{

    },
    202:{

    },
    203:{

    },
    205:{

    },
    206:{

    }
}