import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import { AiOutlineDelete } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'




const AnalyticsmoderatedItem = ({ moderated, k }: any) => {
    const { content_type, moderated_content, created_at, is_reported } = moderated
    const len = moderated_content.reported_count
    const [optionvisible, setoptionvisible] = useState(false)
    const feedRef = useRef(null);
    const history = useHistory()

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = feedRef.current
            if (optionvisible && targt && !targt.contains(e.target)) {
                setoptionvisible(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [optionvisible])



    return (
        <div className={(k === 0) ? 'cm_table_container' : 'cm_table_container cm_table_container_border'}>
            <div className='cm_table_item'>
                <li >
                    {content_type === 'post' ? (<img className='cm_table_img' src={moderated_content ? moderated_content.images[0] ? moderated_content.images[0].posted_file_thumbnail : '' : ''} />
                    ) : (
                        <img className='cm_table_img' src={moderated_content ? moderated_content.images[0] ? moderated_content.images[0].image_thumbnail : '' : ''} />
                    )}
                    {content_type === 'post' ? moderated_content.event_name : moderated_content.name}
                </li>
                <li>{dayjs(created_at).format('DD MMM, HH:mm')}</li>
                <li>

                    {moderated_content.moderated_status && (
                        moderated_content.moderated_status === 'approved' ? (
                            <button className='cm_content_statbtn cm_content_statbtn_green'>
                                <Approved /> Approved
                            </button>
                        ) : moderated_content.moderated_status === 'disapproved' ? (
                            <button className='cm_content_statbtn cm_content_statbtn_red'>
                                <><UnApproved /> Unapproved</>
                            </button>
                        ) : null
                    )

                    }
                </li>
                <li>
                    {content_type}
                </li>
                <li>
                    {
                        is_reported ? (
                            <button className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto' }}>
                                Yes
                            </button>
                        ) : <p>_</p>

                    }

                </li>
                <li className='cm_table_imgoverlapped'>
                    {
                        moderated_content.reported_by.map((data: any, key: number) => {
                            return (
                                <img className={`cm_table_usrimg cm_table_usrimg${key}`} src={data.profile_dp} />
                            )
                        })
                    }

                    {len > 3 && <div className='cm_table_usrimgcount'>+{len - 3}</div>}

                </li>
                <li>
                    <div className="FeedItemDropdown" style={{display:'flex',alignItems:'center'}} ref={feedRef} onClick={e => {
                        e.preventDefault()
                        setoptionvisible(!optionvisible)
                    }}>
                        <span className='dots'>
                            <More />
                        </span>{optionvisible ? <div className='feedoptions'>
                            <span className='feedoptiontrianglecontainer'><span></span></span>
                            {/* <li >
                                <AiOutlineDelete />
                                Delete post</li> */}
                                <li onClick={()=>{
                                    if(content_type === 'post'){
                                        history.push(`/feed/${moderated_content.post_slug}`)
                                    }else if(content_type === 'event'){
                                        history.push(`/event/${moderated_content.event_slug}`)
                                    }
                                    
                                }}>{
                                    content_type === 'post'? 'Go to post': content_type === 'event'? 'Go to event': null
                                }</li>
                                {content_type === 'post' && <li onClick={()=>{
                                    history.push(`/event/${moderated_content.event_slug}`)
                                }}>{
                                    'Go to event'
                                }</li>}
                        </div> : null}

                    </div>
                </li>
            </div>
        </div>
    )
}

export default AnalyticsmoderatedItem