import React, { useRef, useCallback, useEffect } from 'react'
// import { profile } from '../../profile/dummydata'
// import FeedItem from './FeedItem'
import NewFeedItem from './Newfeeditem'
import Api from '../../../../app/api'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { feedRequest, feedSuccess, feedFailed, feedRequest1, feedSuccess1 } from '../store/feedSlice'
import { useHistory } from 'react-router-dom'
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { errors } from '../../../../Data/Error'
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';

const FeedList = ({ mode }: { mode: string }) => {
    const { lat, lng } = useAppSelector(state => state.profile)
    console.log(lat, lng)
    // const lat = '10.8505159'
    // const lng = '76.2710833'
    const dispatch = useAppDispatch()
    const history = useHistory()

    // const { loading, feed, page, pagination, error } = feedData
    const { loading, feed, page, pagination, error,loading1 } = useAppSelector(state => state.feed)
    // feedData={{ loading: loading,
    //     feed: feed, page: page, pagination: pagination, error: error }}

    const { profile } = useAppSelector(state => state.profile)
        let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const success = (res: any) => {
        console.log(res, 'res')
        if (mode === 'all') {
            dispatch(feedSuccess({
                feed: res,
                no: page + 1,
                pagination: res.length < 5 ? false : true
            }))
        } else {
            dispatch(feedSuccess1({
                feed: res,
                no: page + 1,
                pagination: res.length < 5 ? false : true
            }))
        }
    }
    const failed = (res: any) => {
        console.log(res, 'res')
        // if (mode === 'all') {
        dispatch(feedFailed(res))


    }
    useEffect(() => {
        let crnt: any = localStorage.getItem('mtusr')
        let current = JSON.parse(crnt)

        let c : any = localStorage.getItem('pdetails')
        let parsedc = JSON.parse(c)
        if (mode === 'all') {
            if (!loading) {
                dispatch(feedRequest())

                let url= current.is_business_profile? `/api/v1/post/feeds/v3/${page}/5/?latitude=${lat}&longitude=${lng}&auth_profile=${parsedc[0].id}`:`/api/v1/post/feeds/v3/${page}/5/?latitude=${lat}&longitude=${lng}`
                Api('get', url, null, success, failed, history, true, true)
            }

        } else {
            if (!loading1) {
                dispatch(feedRequest1())
                let url= current.is_business_profile? `/api/v1/post/feeds/trending/${page}/5/?latitude=${lat}&longitude=${lng}&auth_profile=${parsedc[0].id}`:`/api/v1/post/feeds/trending/${page}/5/?latitude=${lat}&longitude=${lng}`
                Api('get',url, null, success, failed, history, true, true)
            }

        }
        window.scrollTo(0, 0)
    }, [mode])

    // const [FeedLoader,setfeedloader]=useState(false)
    const observer: any = useRef()
    const lastBookElementRef: any = useCallback((node: any) => {

        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading) {
                    if (mode === 'all') {
                        let theurl = `/api/v1/post/feeds/v3/${page}/5/?latitude=${lat}&longitude=${lng}`
                        if (unparseddetails) {
                          if (currentparsed.is_business_profile) {
                            theurl = `/api/v1/post/feeds/v3/${page}/5/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
                          }
                  
                        }
                        dispatch(feedRequest())
                        Api('get', theurl, null, success, failed, history, true, true)
                    } else {
                        dispatch(feedRequest1())
                        let theurl = `/api/v1/post/feeds/trending/${page}/5/?latitude=${lat}&longitude=${lng}`
                        if (unparseddetails) {
                          if (currentparsed.is_business_profile) {
                            theurl = `/api/v1/post/feeds/trending/${page}/5/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
                          }
                  
                        }
                        Api('get', theurl, null, success, failed, history, true, true)
                    }

                }
            }
        })
        if (node) observer.current.observe(node)
    }, [feed, loading])



    const Retry = () => {
        if (mode === 'all') {
            dispatch(feedRequest())
            let theurl = `/api/v1/post/feeds/v3/${page}/5/?latitude=${lat}&longitude=${lng}`
            if (unparseddetails) {
              if (currentparsed.is_business_profile) {
                theurl = `/api/v1/post/feeds/v3/${page}/5/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
              }
      
            }
            Api('get', theurl, null, success, failed, history, true, true)
        } else {
            dispatch(feedRequest1())
            let theurl = `/api/v1/post/feeds/trending/${page}/5/?latitude=${lat}&longitude=${lng}`
            if (unparseddetails) {
              if (currentparsed.is_business_profile) {
                theurl = `/api/v1/post/feeds/trending/${page}/5/?latitude=${lat}&longitude=${lng}&auth_profile=${parseddetails[0].id}`
              }
      
            }
            Api('get', theurl, null, success, failed, history, true, true)
        }
    }
    return (
        <div className="FeedList" >
            {
                // FeedLoader === false  ? (
                !(Object.keys(feed).length === 0) ? (
                    feed.map((item: any, key: number) => {
                        if (feed.length === key + 1) {
                            return (
                                <div ref={lastBookElementRef} key={key}>
                                    <NewFeedItem item={item} k={key} user={profile} />
                                </div>
                            )
                        } else {
                            return (
                                <NewFeedItem item={item} k={key} user={profile} />
                            )
                        }
                    })
                ) : (
                    !loading ?
                        error === null ?
                            <div className='noData'>
                                <Warning />
                                <h2>Jackpot - this is where all the action happens!</h2>
                                <span>Posts from you and your connections will be displayed here.</span>
                            </div>
                            : null
                        : null
                )
            }
            {
                loading ? (
                    [...Array(4)].map(() => {
                        return (
                            <div className="FeedItemLayout skeleton">
                                <div className="FeedItemTopBar skeleton" style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
                                    <div className="FeedItemUsr">
                                        <div className="FeedItemUsrDp " style={{ backgroundColor: 'white' }}>

                                        </div>
                                        <div className="FeedItemUsrDetails">
                                            <div
                                                className="FeedItemUsrName minwid100 minhe10"
                                                style={{ backgroundColor: 'white' }}
                                            ><div className="eventUsrSub " style={{ backgroundColor: 'white' }}></div>

                                            </div>
                                            {/* <div className="FeedItemUsrTime skeleton-loader1"><div className="eventUsrSub skeleton-loader"></div></div> */}
                                            <div className="FeedItemEventTime">
                                                <div className="eventUsrSub minhe10 minwid60" style={{ marginTop: '5px', backgroundColor: 'white' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='FeedItemImageContainer inverse' style={{ minHeight: '500px', backgroundColor: 'white' }}></div>
                                <div className='FeedItemEventFooterContainer skeleton' style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
                                    <div className="FeedItemEventFooter " >
                                        <div className="FeedItemEventDp " style={{ backgroundColor: 'white' }}>
                                        </div>
                                        <div className="FeedItemUsrDetails">
                                            <div
                                                className="FeedItemEventName minwid100 minhe10"
                                                style={{ backgroundColor: 'white' }}><div className="eventUsrSub skeleton-loader1" style={{ minWidth: '200px' }}></div>

                                            </div>
                                            <div className="FeedItemEventTime">
                                                <div className="eventUsrSub minhe10 minwid60" style={{ marginTop: '5px', backgroundColor: 'white' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="FeedItemIconShareDiv" >
                                    <div className="FeedItemIconDiv">

                                    </div>

                                </div>

                            </div>
                        )
                    })
                ) : null
            }
            {
                error !== null ? (
                    error.status === null ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Nointernet />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn' onClick={Retry}>Retry</button>
                            </div>
                        </div>
                    ) : error.status === 500 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn' onClick={Retry}>Retry</button>
                            </div>
                        </div>
                    ) : error.status === 401 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn' onClick={Retry}>Retry</button>
                            </div>
                        </div>
                    )) : null
            }
        </div>
    )
}

export default FeedList
