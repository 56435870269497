import React, { useState } from 'react';
import MapLocation from '../../../smallcomponents/maplocation/MapLocation';
import { QRCodeCanvas } from 'qrcode.react';

import './buyticket.css';
import TicketDetailModal from './TicketDetailModal';

const ViewTicket = ({ event, item, price, category_name }: any) => {
    var ifConnected = window.navigator.onLine;

    const [openModal, setOpenModal] = useState(false)
    const modalClose = () => setOpenModal(false)

    return (<>
        <div className='viewticket__wrapper' onClick={()=>setOpenModal(true)}>
            <div className='viewticket'>
                <div
                    style={{
                        padding: '1rem 2rem',
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                        height: '50%',
                        border: '1px solid var(--cl_light_grey)',
                        // border: '1px solid red',
                        borderRadius: '24px 24px 0px 0px',
                        overflow: 'hidden'


                    }}>
                    <div className='buyticket_eventimage'>
                        {event?.event_image
                            ? event?.event_image.slice(0, 1).map((item: any, key: number) => {
                                const { image } = item;
                                return <img alt='Event_cover_image' style={{ height: '75px', width: '75px' }} src={image} />;
                            })
                            : null}
                    </div>
                    <div>
                        <h4>{event?.name}</h4>

                        {ifConnected ? (
                            <MapLocation
                                latitude={JSON.parse(event?.latitude)}
                                longitude={JSON.parse(event?.longitude)}
                                locationtext={event?.location}
                                location_image={''}
                                eventpage={false}
                            />
                        ) : (
                            <span>error</span>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        padding: '1rem 2rem',
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                        height: '50%',
                        backgroundColor: 'var(--cl_orange)',
                        borderTop: '1px dashed white',
                        borderRadius: '0px 0px 24px 24px',
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: "0px 10px" }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: "4px" }}>
                            <h5 style={{ color: "white" }}>{item?.ticket_code}</h5>
                            <h5 style={{ color: "white" }}> {category_name} </h5>
                            <h5 style={{ color: "white" }}>{Number(price) === 0 ? 'FREE' : `${price}$`}</h5>

                        </div>
                        <div style={{ paddingRight: "20px" }}>
                            {/* <img src='' alt='' /> */}
                            <QRCodeCanvas value={item?.ticket_code} bgColor="#ff5917" size={70} />

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <TicketDetailModal open={openModal} handleClose={modalClose} event={event} item={item} category_name={category_name} price={price}/>

        </>
    );
};

export default ViewTicket;
