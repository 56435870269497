import React, { useEffect, useState } from 'react'
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { useHistory } from 'react-router';
import Api from '../../../app/api';
import './maplocation.css'
import {
  LoadScript
} from '@react-google-maps/api';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { MAP_API_KEY } from '../../../Data/Data';

const libraries: any = ["places"]


const MapLocation = ({ latitude: lat, longitude: lng, locationtext, location_image, eventpage }: any) => {
  // { latitude: number, longitude: number,props: any }
  const [pos, setpos] = useState('')
  const [map, setmap] = useState('')
  const history = useHistory()
  const success = (data: any) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    setmap(url)
    console.log(data)
  }
  const failed = (data: any) => {
    console.log(data)
    setmap('')
  }
  function Recall() {
    geocodeByLatLng({ lat, lng })
      .then(results => {
        console.log(results)
        setpos(results[0].formatted_address)

      })
      .catch(error => {
        console.error(error);
      });
  }
  useEffect(() => {
    // Api('get', `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
    // &markers=color:ff5917%7C${lat},${lng}&key=AIzaSyCVHWug3PRbkfovRw5CqYhcQAUFSwTj10g/`, { responseType: 'blob' }, success, failed, history, false, false)
    if (locationtext === null || locationtext === '') {

      geocodeByLatLng({ lat, lng })
        .then(results => {
          console.log(results)
          setpos(results[0].formatted_address)

        })
        .catch(error => {
          console.error(error);
        });
      setTimeout(() => {
        Recall()
      }, 3000);

    }

  }, [lat, lng])
  return (
    eventpage ? (
      <a
        href={`https://www.google.com/maps/search/${lat},${lng}/@${lat},${lng},17z`}
        target="_blank" className='singleeventmapscontainer !tw-w-full md:!tw-w-[312px]'  rel="noreferrer">
        <div className='mapimage !tw-w-full md:!tw-w-[312px]' >
          <img  className="!tw-w-full md:!tw-w-[312px]" alt='Map' src={location_image ? location_image : `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
                &markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`}></img>
        </div>
        <div className='maplocation'>
          <p>{locationtext ? (locationtext === '' ? pos : locationtext) : pos}</p>
        </div>
      </a>
    ) : (
      <span className='cm_eventlocation cursor' style={{lineClamp:2,WebkitLineClamp:2,textOverflow:'ellipsis',height:'2ch',overflow:'hidden'}} onClick={() => {
        window.open(`https://www.google.com/maps/search/${lat},${lng}/@${lat},${lng},17z`, "_blank")
      }}>{locationtext ? (locationtext === '' ? (pos === '' ? lat + ',' + lng : pos) : locationtext) : (pos === '' ? lat + ',' + lng : pos)}
      </span>)
  )
}

// export default MapLocation
export default GoogleApiWrapper({
  apiKey: MAP_API_KEY,
  libraries: ["places"]
})(MapLocation);