import React, { useState } from 'react';
import './story.css';
import StoryTile from './StoryTile';
// import Stories, { WithSeeMore } from '@daverupp/react-insta-stories';
import Stories, { WithSeeMore } from 'react-insta-stories';

import ModalLayout from '../../utils/ModalLayout';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetch_follower_story_list, fetch_story_list } from './store/action';
import { useHistory } from 'react-router-dom';
import { StoryList } from './store/story.interface';
import StoryView from './StoryView';
import { Carousel } from 'react-responsive-carousel';
import Slider from "react-slick";
import StoryModal from './storyModal';
import StoryLoader from './storyLoader';
import MyStoryTile from './MyStoryTile';
import MyStoryModal from './MyStoryModal';

const contentStyle = {
	background: 'salmon',
	width: '100%',
	padding: 20,
	color: 'white',
};

const customSeeMore = {
	textAlign: 'center',
	fontSize: 14,
	bottom: 20,
	position: 'relative',
};
const image = {
	display: 'block',
	maxWidth: '100%',
	borderRadius: 4,
};

const stories2 = [
	{
		content: ({ action, isPaused }: any) => {
			return (
				<div style={contentStyle}>
					<h1>Hey All 👋</h1>
					<h1>Check shivam's journey story.</h1>

					{/* <pre>
              <code style={code}>here they are 😄 -></code>
            </pre> */}
					<img style={image} alt='' src='https://i.ibb.co/fY1DmQW/8aacdef9ba37db60c7a96271877cfbb5.jpg'></img>
					<h4>stories creted by Cubestop travel app</h4>
				</div>
			);
		},
	},
	{
		content: ({ action, story }: any) => {
			return (
				<WithSeeMore story={story} action={action}>
					<div style={{ background: 'pink', padding: 20 }}>
						<h1 style={{ marginTop: '100%', marginBottom: 0 }}>🌝</h1>
						<h1 style={{ marginTop: 5 }}>Here is the location journey story created for the shivam's journey.</h1>
					</div>
				</WithSeeMore>
			);
		},
		//   seeMoreCollapsed: ({ toggleMore, action }:any) => (
		//     <p style={customSeeMore} onClick={() => toggleMore(true)}>
		//       A custom See More message →
		//     </p>
		//   ),
		seeMore: ({ close }: any) => (
			<div
				style={{
					maxWidth: '100%',
					height: '100%',
					padding: 40,
					background: 'white',
				}}>
				<h2>Just checking the see more feature.</h2>
				<p style={{ textDecoration: 'underline' }} onClick={close}>
					Go on, close this popup.
				</p>
			</div>
		),
		duration: 1000,
	},
	// {
	//   content: ({ action, isPaused }) => {
	//     return (
	//       <div style={contentStylestoryback}>
	//         <img style={image} src="https://i.ibb.co/MGbfDTH/Group-13.png"></img>
	//       </div>
	//     );
	//   }
	// },
	// {
	//   content: ({ action, isPaused }) => {
	//     return (
	//       <div style={contentStylestoryback}>
	//         <img
	//           style={image}
	//           src="https://downloadwap.com/thumbs2/wallpapers/p2/2019/nature/48/c62b256713551358.jpg"
	//         ></img>
	//       </div>
	//     );
	//   }
	// },

	// {
	//   url: "https://picsum.photos/1080/1920"
	//   seeMore: ({ close }) => (
	//     <div
	//       style={{
	//         maxWidth: "100%",
	//         height: "100%",
	//         padding: 40,
	//         background: "white"
	//       }}
	//     >
	//       <h2>Just checking the see more feature.</h2>
	//       <p style={{ textDecoration: "underline" }} onClick={close}>
	//         Go on, close this popup.
	//       </p>
	//     </div>
	//   )
	// },
	// {
	//   url:
	//     "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
	//   type: "video"
	// },
	// {
	//   content: Story2
	// }
];

const StoryWrapper = () => {
	const dispatch = useAppDispatch();
	let history = useHistory();

	const profileID = useAppSelector((state) => state.profile?.profile?.id);

	const StoryData = useAppSelector((state) => state?.story);

	const [currentStoryKey, setCurrentStoryKey] = useState(0);
	const [myCurrentStoryKey, setMyCurrentSToryKey] = useState(0);

	useEffect(() => {
		console.log('***************** mounted ******************')
		console.log('my story data^^^^^^^^^^^^^^^^^^', StoryData)
		if (profileID) {
			fetch_story_list(history, dispatch, profileID, 1, 15);
			fetch_follower_story_list(history, dispatch, profileID, 1, 15);
		}
	}, [history, dispatch, profileID]);

	const [storyOn, setstoryOn] = useState(false);
	const [myStoryOn, setMyStoryOn] = useState(false);

	const viewStoryFx = () => {
		setstoryOn(true);
	};
	const viewMyStoryFx = () => {
		setMyStoryOn(true);

	}
	const handleClose = () => {
		setstoryOn(false);
		setMyStoryOn(false);

	}; const myHandleClose = () => {
		setMyStoryOn(false);

	};
	if ((StoryData?.FollowersStoryList.length <= 0) && (StoryData?.StoryList.length <= 0)) return null
	// if (Array.isArray(StoryData?.FollowersStoryList) && (StoryData?.FollowersStoryList.length <= 0)) return null
	// if (Array.isArray(StoryData?.StoryList) && (StoryData?.StoryList.length <= 0)) return null

	console.log('-------------------story-----------------', StoryData);

	return (
		<div className='story__wrapper__container scrollbar-hidden'>
			{
				// Array.isArray(StoryData) &&
				// 	StoryData.map((item: any, key: any) => {
				// 		return <><MyStoryTile viewStoryFx={viewMyStoryFx} item={item} setCurrentStoryKey={setMyCurrentSToryKey} Itemkey={key} /></>
				// 	})

				StoryData?.StoryList.length > 0 && <MyStoryTile viewStoryFx={viewMyStoryFx} item={StoryData?.StoryList[0]} setCurrentStoryKey={setMyCurrentSToryKey} />
			}


			{

				StoryData?.FollowersStoryList.length > 0 && Array.isArray(StoryData?.FollowersStoryList) ?

					(StoryData?.FollowersStoryList.map((item, key) => {
						return (
							<>
								<StoryTile viewStoryFx={viewStoryFx} item={item} setCurrentStoryKey={setCurrentStoryKey} Itemkey={key} />
							</>
						);
					})) : (
						(!StoryData?.FollowersStoryList || StoryData?.loading) && [...Array(6)].map((item, key) => {
							return <StoryLoader />
						})
					)
			}
			{
				(StoryData?.loading) && [...Array(6)].map((item, key) => {
					return <StoryLoader />
				})
			}


			{
				storyOn && (

					<StoryModal open={storyOn} handleClose={handleClose}
						FollowerList={StoryData.FollowersStoryList}
						currentStoryKey={currentStoryKey}
						setCurrentStoryKey={setCurrentStoryKey}
						totalLength={StoryData.FollowersStoryList ? StoryData.FollowersStoryList.length : null}

					/>)
			}
			{
				myStoryOn && (

					<MyStoryModal open={myStoryOn} handleClose={myHandleClose}
						storyList={StoryData.StoryList}
						currentStoryKey={myCurrentStoryKey}
						setCurrentStoryKey={setMyCurrentSToryKey}
						totalLength={StoryData.StoryList ? StoryData.StoryList.length : null}

					/>)
			}
		</div>
	);
};

export default StoryWrapper;
