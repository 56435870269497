import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { eassigned } from '../store/editEventSlice';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/selectarrow.svg'
import { BsPlus } from 'react-icons/bs'

interface A {
    id: number,
    role: string,
}
const ParticipantItem = ({ user, k,open,setopen,roles,setroles }: { user: any, k: number ,open:any, setopen: any,roles: any,setroles: any }) => {
    const dispatch = useAppDispatch()

    const eventData = useAppSelector(state => state.editEvent)
    const { assigned: check }: { assigned: A[] | null } = eventData;

    const { dp, firstname, lastname, id, username } = user;

    let initial;
    if (check.length !== 0) {
        let array = check.filter((item: any) => item.id === id)

        initial = array.length !== 0 ? array[0].role : null
    } else {
        initial = null
    }

    let Roles = ['Admin', 'Manager', 'Support'];
    const [selectedvalue, setvalue] = useState(initial !== null ? initial : 'Assign role')
    const [visible, setvisible] = useState(false)
    const [selectedstate, setselectedstate] = useState('initial')



    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e:any) => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          let targt: any = vis.current
          if (visible && targt && !targt.contains(e.target)) {
            setvisible(false)
          }
        }
    
        document.addEventListener("mousedown", checkIfClickedOutside)
    
        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [visible])
    return (
        <div className="AddEventUserItem">
            <div ref={vis} className='selectbox1'
                onClick={
                    e => {
                        e.preventDefault()
                        if (!visible) {
                            setselectedstate('selecting')
                        } else {
                            setselectedstate('selected')
                        }
                        setvisible(!visible)
                    }
                }>
                <div className='selectbox1 selectboxinput1' >
                    <p>{selectedvalue}</p>
                </div>
                <div className={visible ? 'selectdropdown selectactive ' : 'selectdropdown selectinactive'}>
                    <span className='selecttriangle'></span>
                    <div className='ul1'>
                        <Scrollbars style={{ width: 220, height: 200 }} autoHide>
                            <li value='Assign role'
                                onClick={e => {
                                    e.preventDefault()
                                    const target: any = e.currentTarget
                                    dispatch(eassigned({
                                        id: id,
                                        role: target.getAttribute('value')
                                    }))
                                    setvalue(target.getAttribute('value'))
                                    setvisible(false)
                                }}
                            >None</li>
                            {

                                roles.map((item: any, key: number) => {

                                    return (
                                        <li id={id} value={item} onClick={
                                            e => {
                                                e.preventDefault()
                                                // alert(item)
                                                const target: any = e.currentTarget
                                                dispatch(eassigned({
                                                    id: id,
                                                    role: target.getAttribute('value')
                                                }))
                                                setvalue(target.getAttribute('value'))
                                                setvisible(false)
                                            }}>{item}</li>
                                    )

                                })
                            }
                            <li style={{backgroundColor:'var(--cl_bggrey)',textAlign:'center',display:'flex',alignItems:'center',justifyContent:'center',paddingRight:'10px'}} onClick={(e)=>{
                                e.preventDefault()
                                setopen(true)
                            }}><BsPlus/> Custom</li>
                        </Scrollbars>
                    </div>
                </div>

                <ArrowDown className={selectedstate === 'selecting' ? 'selectarrow1 arrowdown' : selectedstate === 'selected' ? 'selectarrow1 arrowleft' : 'selectarrow1'} />
            </div>
            <div className="AddEventUserSec2" style={{ marginLeft: 'auto', marginRight: '10px' }}>
                {username}
            </div>
            <div className="AddEventUserSec1">
                <div className="AddEventUserDp">
                    <img src={dp} alt="P_I_C" className="AddEventUserDpImg" />
                </div>
                <div className="AddEventUsrName">{firstname + " " + lastname}</div>
            </div>
        </div>
    )
}

export default ParticipantItem
