import { Divider, Pagination, PaginationItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FeaturedEvent from '../EventSearch/FeaturedEvent'
import SingleEventWl from '../EventSearch/SingleEventWl'
import './searchresultscreen.css'
import SearchResultContainer from './SearchResultContainer'
import { useAppSelector, } from '../../app/hooks'
import Api from '../../app/api'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks';
import {
    topSearchEventsRequestWl, topSearchEventsFailedWl, topSearchEventsSuccessWl,
    topSearchBusinessRequest, topSearchBusinessSuccess, topSearchBusinessFailed,
    topSearchEventsClearWl, topSearchBusinessClear, searchResultFaildWl, searchResultRequestWl, searchResultSuccessWl
} from '../EventSearch/store/topSearchSlice'
import { featuredEventFailedWl, featuredEventSuccessWl, featuredEventRequestWl } from '../EventSearch//store/feturedEventSliceWl';

import Scrollbars from 'react-custom-scrollbars-2';

function SearchResultScreen() {
    const location : any = useLocation();
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {
        loading1,
        topSearchEvents,
        page1,
        pagination1,
        error1,
        loading2,
        topSearchPeople,
        page2,
        pagination2,
        error2,
        loading3,
        topSearchBusiness,
        page3,
        pagination3,
        error3,
    } = useAppSelector(state => state.topSearchWl)
    const { loading, featuredEvent, page, pagination, error, } = useAppSelector(state => state.featuredeventwl)
    const { lat, lng } = useAppSelector(state => state.profile)
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ newwww', topSearchEvents)
    // const { location?.state?.detail }: any = location.state
    const success = (res: any) => {

        console.log("PREVV ERROR RES" , res)

        dispatch(searchResultSuccessWl({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }

    const failed = (err: any) => {
    }
    const success1 = (response: any) => {
        console.log('------------------------response---------------------', response)
        dispatch(featuredEventSuccessWl({
            data: response,
            pagination: response.length < 10 ? false : true
        }))
    }
    const failed1 = () => {

    }
    useEffect(() => {
        dispatch(searchResultRequestWl({ no: 1 }))
        let data = {
            // search_type: "events",
            keyword: location?.state?.detail,
            latitude: lat,
            longitude: lng,
        };
        let theurl = `/api/v1/search/event/no-auth/1/10/`

        Api('post', theurl, data, success, failed, history, false, true)
        dispatch(featuredEventRequestWl({ no: 1 }))

        Api('get', `/api/v1/event/featured/no-auth/1/10/`, null, success1, failed1, history, false, true)


    }, [])
    const ScrollPos1 = (pageno: any) => {
        // console.log(top)


            console.log(loading2)
            // console.log('scroll please')
                dispatch(searchResultRequestWl({ no: page2 }))

                let data = {
                    // search_type: "events",
                    keyword: location?.state?.detail,
                    latitude: lat,
                    longitude: lng,
                };
                let url = `/api/v1/search/event/no-auth/${pageno}/10/`

                Api('post', url, data, success, failed, history, false, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
    }
    const ScrollPos2 = (val: any) => {
        const { top } = val
        // console.log(top)


        if (top >= 0.9) {
            console.log(loading)
            // console.log('scroll please')
            if (pagination && !loading) {
                dispatch(featuredEventRequestWl({ no: page }))

                let url = `/api/v1/event/featured/no-auth/${page}/10/`

                Api('get', url, null, success, failed, history, false, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }
    }

    const [totalCount, settotalCount] = useState<number | undefined>(0)


    useEffect(() => {
        if (topSearchEvents && topSearchEvents.length > 0) {

            if (topSearchEvents[0]?.total_count) {

                let count = 0

                if (topSearchEvents[0]?.total_count % 10 === 0) {
                    count = (topSearchEvents[0]?.total_count) / 10;
                }
                else {
                    count = Math.floor((topSearchEvents[0]?.total_count + 10 - 1) / 10);
                }

                settotalCount(count)


            }


        }

        return () => {

        }
    }, [topSearchEvents])




    console.log("TOP SEARCH EVENTS ===>", topSearchEvents)

    console.log("totalCount ===>", totalCount)

    return (
        <div className="event__search_result" style={{ marginTop: "180px", width: "100%", height: '100%', padding: "0px 180px 0px 280px", }}>
            <div style={{ display: "flex", flexDirection: "row" }}>

                <div style={{ display: "flex", flexDirection: "column", width: "60%", marginRight: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "row", margin: "0px 0px 15px 0px", }}> <h3>Results for:</h3>
                            <h3 style={{ color: "red" }}>{location?.state?.detail}</h3></div>
                        <span style={{ marginTop: "17px", marginBottom: "20px", margin: "0px 0px 30px 0px", color: "lightgray" }}>Discover a melting pot of events.</span>
                    </div>
                    {!loading ? topSearchEvents.length !== 0 ? < div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>

                        {/* <Scrollbars style={{ height: "1000px" }} onUpdate={ScrollPos1} autoHide> */}

                        {
                            topSearchEvents?.map((item: any, key: any) => {
                                return <SearchResultContainer item={item} key={key} />
                            })
                        }

                        {/* </Scrollbars> */}
                    </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        Search Result is Empty
                    </div> :
                        [...Array(5)].map(() => {
                            return (
                                <div className='skeleton' style={{ border: "solid 1px", borderColor: "lightgray", borderRadius: "10px", margin: "10px 30px 10px 0px", width:"100%" , minWidth:"min-content" , padding:"10px"  }}>
                                    <div style={{ height: "150px" }}>

                                    </div>
                                </div>
                            )
                        })

                    }

                </div>
                <div style={{ display: "flex", flexDirection: "column", padding: "0px 70px", width: "40%", }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h3 style={{ marginBottom: "30px" }}>Featured Events</h3>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", }}>

                        <Scrollbars style={{ height: "1000px" }} onUpdate={ScrollPos2} autoHide>

                            {
                                featuredEvent?.map((item: any, key: any) => {
                                    return (
                                        <div style={{ display: "flex", marginBottom: "10px" }}>
                                            <SingleEventWl item={item} key={key} />

                                        </div>
                                    )

                                })
                            }

                        </Scrollbars>
                    </div>
                </div>

            </div>

            <div>

                <Pagination count={totalCount} style={{ color: "var(--cl_orange)" }} variant="outlined"  onChange={(_,n)=>ScrollPos1(n)}/>
            </div>

        </div >
    )
}

export default SearchResultScreen