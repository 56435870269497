
import Layout from './Layout'
import React, { useState } from 'react';
import {
    Link,
    useHistory
} from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import Api from '../../app/api';
import { BiArrowBack } from 'react-icons/bi'
import axios from 'axios';
import SuccessModal from '../smallcomponents/modals/Success'
import ErrorModal from '../smallcomponents/modals/Error'
import { baseurl } from '../../Data/Data';

const ForgotPassword = () => {

    let history = useHistory();
    let fg: any = null
    const [forgetmail, setforgetmail] = useState(fg)
    const [forgetmailvalidate, setforgetmailvalidate] = useState('error')
    const [loading, setloading] = useState(false)
    const [vloading, setvloading] = useState(false)
    const [error, seterror] = useState('')
    const [token, settoken] = useState('');

    const [open, setopen] = useState(false)
    const [open1, setopen1] = useState(false)

    let s: any = null
    const [sc, setsc] = useState(s)
    let e: any = null
    const [er, seter] = useState(e)




    const [stage, setstage] = useState(0)
    let ot: any = null
    const [otp, setotp] = useState(ot)
    function validateEmail(email: any) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const [newpassword, setnewpassword] = useState()
    const [confirmpassword, setconfirmpassword] = useState()
    const success = (data: any) => {
        // dispatch(profileSuccess(data))
        setvloading(false)

        console.log(data)
        if (data.app_data.account_active === false) {
            seterror("Account with this credential doesn't exist or have been deactivated")
        } else {
            // if (data.app_data.email_verified === false) {
            //     if (data.app_data.phone_verified) {
            //         seterror('This email has not been verified please try using phone number')
            //     }
            //     else {
            //         seterror('This email has not been verified with the account')
            //     }
            // } else {
            //     setstage(1)
            // }
            setstage(1)
        }
    }
    const failed = (data: any) => {
        setvloading(false)
        console.log(data)
        if (data.app_data === 'We could not find a matching record. Please double check and try again') {
            seterror("Account with this credentials doen't exist, please double check and try again")
        } else {
            seterror('Something went wrong, check the email address and try again')
        }

        // dispatch(profileFailed(data))
    }

    function Email() {
        setvloading(true)
        let dat = {
            "input_data": forgetmail
        }
        Api('post', '/api/v1/user/verification/otp/generate/', dat, success, failed, history, false, true)
    }
    const success1 = (data: any) => {
        seterror('')
        settoken(data.app_data)
        setvloading(false)
        console.log(data)
        setstage(2)
    }
    const failed1 = (data: any) => {
        setvloading(false)
        console.log(data)
        if (data.app_data === 'Invalid Otp') {
            seterror("Invalid Otp")
        } else {
            seterror('Something went wrong, check the input and try again')
        }

    }

    function Verify() {
        setvloading(true)
        let dat = {
            "input_data": forgetmail,
            "otp": otp
        }
        Api('post', '/api/v1/user/verification/otp/verify/', dat, success1, failed1, history, false, true)
    }
    const success2 = (data: any) => {
        seterror('')
        setvloading(false)
        console.log(data)
        setstage(2)
    }
    const failed2 = (data: any) => {
        setvloading(false)
        console.log(data)
        if (data.app_data === 'Invalid Otp') {
            seterror("Invalid Otp")
        } else {
            seterror('Something went wrong, check the input and try again')
        }

    }

    function Reset() {
        let data = {
            "new_password": newpassword,
            "confirm_password": confirmpassword
        }
        var config: any = {
            method: 'put',
            // url: 'https://51c046c6-73be-447a-a38c-d999464b1b85.mutualevents.co/api/v1/user/password/forget/',
            url: baseurl + '/api/v1/user/password/forget/',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };
        axios(config)
            .then((res) => {
                // setsc({
                //     head: 'Your password is reset',
                //     message: 'login to return back to mutual events',
                //     button: 'Okay',
                //     link: null
                // })
                setstage(3)
                // setopen(true)
                setTimeout(() => {
                    // setopen(false)
                    // setsc(null)
                    history.push('/login')
                }, 5000);

            })
            .catch((error) => {
                const errorMsg = error.message
                seter({
                    head: 'Something went wrong',
                    message: 'please try again later',
                    button: 'Okay',
                    link: null
                })
                setTimeout(() => {
                    setopen1(false)
                    seter(null)
                }, 5000);
                setopen1(true)
            });

        // Api('put', '/api/v1/user/password/forget/', data, success2, failed2, history, false, true)
    }
    return (
        <Layout className='fg'>
            {/* <> */}
            <div className='backBtn'><BiArrowBack onClick={() => {
                history.goBack()
            }} /></div>
            {
                stage === 3 ? (
                    // <div className='fg'>
                    < div className='forgotpassword' >
                        < h1 >Done</h1 >
                        <p>Your password has been reset, Go back to login page to login </p>
                        <button className="lgbtn" onClick={e => { history.push('/login') }}>
                            Go back to login</button>
                    </div>
                    // </div>
                ) :
                    stage === 2 ? (
                        // <div className='fg'>
                        < div className='forgotpassword' >
                            < h1 >Reset password</h1 >
                            {/* <form> */}
                            <label>New password</label>
                            <input type='password' placeholder='New Password' className="credentials" value={newpassword}
                                onChange={e => {
                                    let tgt: any = e.currentTarget
                                    setnewpassword(tgt.value)
                                }}
                                // onKeyPress={(e) => {
                                //     e.preventDefault();
                                //     e.stopPropagation();
                                //     if (e.key === 'Enter') {
                                //         document.getElementById('scnd')?.click();
                                //     }
                                //     let tgt: any = e.currentTarget
                                //     setnewpassword(tgt.value)
                                // }}
                                onKeyUp={(e) => {
                                    e.stopPropagation();
                                    if (e.key === "Enter") {
                                        document.getElementById('scnd')?.click();
                                    }
                                }}
                            ></input>
                            <label>Confirm password</label>
                            <input id='scnd' type='password' placeholder='Confirm Password' className="credentials" value={confirmpassword}

                                // onKeyPress={(e) => {
                                //     e.preventDefault();
                                //     e.stopPropagation();
                                //     if (e.key === 'Enter') {
                                //         Reset()
                                //     }
                                //     let tgt: any = e.currentTarget
                                //     setconfirmpassword(tgt.value)
                                // }}
                                onChange={e => {
                                    let tgt: any = e.currentTarget
                                    setconfirmpassword(tgt.value)
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        Reset()
                                    }
                                }}
                            ></input>
                            <button className="lgbtn" style={{ marginTop: '20px' }}
                                onClick={e => { Reset() }}
                            >
                                {loading === false ? 'Reset password' :
                                    <div style={{ color: 'white' }}>
                                        <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />

                                    </div>
                                }</button>
                            {/* </form> */}
                        </div>
                        // </div>
                    ) : (
                        < div className='forgotpassword' >
                            {/* <BsArrowLeft style={{ fontSize: '34px', color: 'grey', cursor: 'pointer' }} onClick={
                                    e => {

                                    history.push('/login');
                                    }
                                }
                                /> */}
                            < h1 > Forgot Password ?</h1 >
                            {/* <p>Enter your email , username or mobile number to receive an otp to reset the password</p> */}
                            <p>Enter your username or email to receive an otp to reset the password</p>
                            {(error && !(Object.keys(error).length === 0)) && <div className='errorMessage'>
                                <p>{error}</p>
                                {/* <p>{error.message}</p> */}
                            </div>}
                            <form>
                                <label>Enter Username or Email</label>
                                <input type='text' placeholder='Email' className="credentials"
                                    required
                                    title='Username / Mobile number / Email address'
                                    onChange={txt => {
                                        let target = txt.target
                                        setforgetmail(target.value)
                                        // const f = document.querySelector('.forgetmailvalidate')
                                        // if (validateEmail(txt.target.value) === true) {
                                        //     if (f) {
                                        //         if (f.classList.contains('validatewarning') === true) {
                                        //             f.classList.remove('validatewarning')
                                        //         }
                                        //     }
                                        // }
                                        // else {
                                        //     setforgetmailvalidate('please enter a valid mail id')
                                        //     if (f) {
                                        //         f.classList.add('validatewarning')
                                        //     }
                                        // }
                                    }}
                                ></input>
                                <small className="forgetmailvalidate">{stage !== 1 ? forgetmailvalidate : null}</small>
                                {stage === 1 && <div style={{ gap: "10px" }}>
                                    <div style={{ marginBottom: "10px" }}> <label >Enter Confirmation Code</label></div>
                                    <input
                                        type="tel"
                                        placeholder="XXXXXX"
                                        className="credentials otp"
                                        required
                                        value={otp}
                                        onChange={(e) => {
                                            let target = e.currentTarget
                                            setotp(target.value);
                                        }}
                                    ></input>
                                </div>}

                                <button className="lgbtn"
                                    onClick={
                                        e => {
                                            e.preventDefault()
                                            let target: any = e.currentTarget
                                            if (stage === 0) {
                                                if ((forgetmail !== null)) {
                                                    // && (validateEmail(forgetmail) === true)
                                                    Email()
                                                    // history.push('/verificationsent')
                                                    // setforgetmail('')
                                                }
                                                else {
                                                    setforgetmailvalidate('please enter a valid mail id')
                                                    if (target) {
                                                        if (target.previousSibling) {
                                                            target.previousSibling.classList.add('validateerror')
                                                        }
                                                    }
                                                    // console.log(e.currentTarget.previousSibling)
                                                }
                                            } else {
                                                Verify()
                                            }
                                        }
                                    }
                                >
                                    {vloading === false ? (stage === 1 ? 'Verify' : 'Send') :
                                        <div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <CircularProgress color='inherit' style={{ height: "13px", width: "13px" }} />
                                        </div>
                                    }
                                </button>
                                {/* </Link> */}
                            </form>
                        </div >
                    )
            }
            {open &&
                <SuccessModal
                    open={open}
                    setOpen={setopen}
                    // success={suc}
                    success={sc}
                />
            }
            {open1 &&
                <ErrorModal
                    open={open1}
                    setOpen={setopen1}
                    // success={suc}
                    error={er}
                />
            }
            {/* </> */}
        </Layout>
    )
}

export default ForgotPassword
