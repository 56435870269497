import React, { useState, useRef, useCallback, useEffect } from 'react'
// import FeedItem from './FeedItem'
import NewFeedItem from './Newfeeditem'
import Api from '../../../../app/api'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { singleFeedFailed, singleFeedRequest, singleFeedSuccess } from '../store/singleFeedSlice'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';

const SingleFeed = ({ feedid: yo }: { feedid: any }) => {
    const { feedid }: any = useParams()
    console.log(feedid)
    const { lat, lng,profile } = useAppSelector(state => state.profile)
    // const lat = '10.8505159'
    // const lng = '76.2710833'
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { loading, singleFeed, error } = useAppSelector(state => state.singlefeed)
    const { isAuth } = useAppSelector(state => state.login)
    const success = (res: any) => {
        console.log(res, 'res')
        dispatch(singleFeedSuccess(res))
    }
    const failed = (res: any) => {
        console.log(res, 'res')
        dispatch(singleFeedFailed(res))
    }
    useEffect(() => {
        if (isAuth) {
            if(!loading){
                dispatch(singleFeedRequest())
                Api('get', `/api/v1/post/${feedid}/`, null, success, failed, history, true, true)
            }

        } else {
            if(!loading){
            dispatch(singleFeedRequest())
            Api('get', `/api/v1/event/share/${feedid}/`, null, success, failed, history, false, true)
            }
        }
        window.scrollTo(0,0)

    }, [])




    return (
        <div className="FeedList" >
            {
                // FeedLoader === false  ? (
                !(Object.keys(singleFeed).length === 0) ? (

                    <NewFeedItem item={singleFeed} k={1} user={profile} />

                ) : (!loading &&
                    <div className='noData'>
                        <Warning />
                        <h2>Jackpot - this is where all the action happens!</h2>
                        <span>Posts from you and your connections will be displayed here.</span>
                    </div>
                )
            }
            {
                loading ? (
                    [...Array(1)].map(() => {
                        return (
                            <div className="FeedItemLayout skeleton">
                                <div className="FeedItemTopBar skeleton" style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
                                    <div className="FeedItemUsr">
                                        <div className="FeedItemUsrDp " style={{ backgroundColor: 'white' }}>

                                        </div>
                                        <div className="FeedItemUsrDetails">
                                            <div
                                                className="FeedItemUsrName minwid100 minhe10"
                                                style={{ backgroundColor: 'white' }}
                                            ><div className="eventUsrSub " style={{ backgroundColor: 'white' }}></div>

                                            </div>
                                            {/* <div className="FeedItemUsrTime skeleton-loader1"><div className="eventUsrSub skeleton-loader"></div></div> */}
                                            <div className="FeedItemEventTime">
                                                <div className="eventUsrSub minhe10 minwid60" style={{ marginTop: '5px', backgroundColor: 'white' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='FeedItemImageContainer inverse' style={{ minHeight: '500px', backgroundColor: 'white' }}></div>
                                <div className='FeedItemEventFooterContainer skeleton' style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
                                    <div className="FeedItemEventFooter " >
                                        <div className="FeedItemEventDp " style={{ backgroundColor: 'white' }}>
                                        </div>
                                        <div className="FeedItemUsrDetails">
                                            <div
                                                className="FeedItemEventName minwid100 minhe10"
                                                style={{ backgroundColor: 'white' }}><div className="eventUsrSub skeleton-loader1" style={{ minWidth: '200px' }}></div>

                                            </div>
                                            <div className="FeedItemEventTime">
                                                <div className="eventUsrSub minhe10 minwid60" style={{ marginTop: '5px', backgroundColor: 'white' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="FeedItemIconShareDiv" >
                                    <div className="FeedItemIconDiv">

                                    </div>

                                </div>

                            </div>
                        )
                    })
                ) : null
            }
        </div>
    )

}

export default SingleFeed
