import React from 'react'
import Recommended from '../recommended/Recommended'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { useHistory } from 'react-router'

const RecommendedPage = () => {
    const history = useHistory();
    return (
        <div className='recommendedPage container minvh100'>
            <div className='commonHeading'>
                <small onClick={() => {
                    history.push('/')
                }}><Arrow />Back to home page</small>
                <span></span>
            </div>
            <Recommended mode={'pagination'} />
        </div>
    )
}

export default RecommendedPage
