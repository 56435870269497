import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { ReactComponent as Rightarrow } from '../../../../../../assets/svg/cm/arrowright.svg'
import dayjs from 'dayjs';


import { useAppSelector } from '../../../../../../app/hooks';
import DailySalesPopup from './dailySalesPopup';

function DailySalesTable({ data }: any) {

    const [popup, setPop] = useState(false)
    const { dailySales } = useAppSelector<any>(state => state.ticketPortal)
    const [popupData, setPopupData] = useState([])

    const handleClickOpen = (data: any) => {
        setPop(true)
        // console.log('gggggggggggggg', data['event_data']);

        setPopupData(data['event_data'])
    }
    const closePopup1 = () => {
        setPop(false)
    }
    return (
        <div className="daily_sales_scrollbar">
            <Scrollbars autoHide  >

                {/* {
                    data && data.map((item: any, key: any) => {
                        console.log('daaaaaaaaaaaaaa', item); */}

                {/* // return ( */}

                {
                    dailySales && dailySales.table_data ? dailySales.table_data.map((item: any) => {
                        let formattedDate = dayjs(item['date']).format('MMM DD')
                        return (
                            <div className="dailysales_table_b">
                                <tbody className='dailysales_table_body'>


                                    <div>
                                        {formattedDate}</div>
                                    <div>{item['total_tickets_sold']}</div>
                                    <div>{item['total_income']}</div>

                                    <div className='revenue_generated_events_head'>
                                        {/* {
                                            setPopupData(item)

                                        } */}
                                        {item && item['event_data'].slice(0, 3).map((item1: any) => {


                                            return (



                                                <img style={{ height: "30px", width: "30px", borderRadius: "50%" }} src={item1['event_image']} alt={item1['event_thumbnail']} />


                                            )
                                        })
                                        }

                                        {
                                            item['event_data'].length !== 0 ? <div className='arrow_dailysales'>
                                                <button onClick={() => handleClickOpen(item)}><Rightarrow /> </button>
                                            </div>
                                                : <div>nil</div>}
                                    </div>



                                </tbody>
                            </div>
                        )
                    })

                        : null}
                {/* // ) */}
                {/* })
                } */}

            </Scrollbars >
            {

                popup ?

                    <DailySalesPopup closePopups={closePopup1} data={popupData} />
                    : null
            }

        </div >
    )
}

export default DailySalesTable