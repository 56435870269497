import React, { useState, useEffect } from 'react';
import './invitepeople.css';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
// import Invitesearch from '../../Tabs/addevent/components/Invitesearch';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as Search } from '../../../assets/svg/mainsearch.svg';
import InviteItem from './InviteItem';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import { errors } from '../../../Data/Error';
import Invitesearch from './Invitesearch';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { addeventinviteclear } from '../../pages/Event/store/eventSlice';
import Api from '../../../app/api';
import SuccessModal from '../../smallcomponents/modals/Success';
import ErrorModal from '../../smallcomponents/modals/Error';
import { useHistory } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { eventinviteload, eventinvitesuccess, eventinvitefailed, eventpaginationinviteload } from '../../pages/Event/store/eventSlice';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
	modal: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgb(0,0,0,0.7)',
	},
	paper: {
		// // border: '2px solid #000',
		// display: 'flex',
		// flexDirection: 'column',
		// alignItems: 'center',
		// // justifyContent: 'center',
		borderRadius: '12px',
		boxShadow: theme.shadows[5],
	},
	wrapper: {
		padding: theme.spacing(5, 8, 3),
	},
}));

const InvitePeople = ({ dialogStatus, dialogAction, eventId }: { dialogStatus: any; dialogAction: any; eventId: any }) => {
	const history = useHistory();
	const [open, setopen] = useState(false);
	const [open1, setopen1] = useState(false);
	const [open2, setopen2] = useState(false);
	let s: any = null;
	const [sc, setsc] = useState(s);
	let e: any = null;
	const [er, seter] = useState(e);

	const { inviteerror, InviteList, Invited, page, pagination, inviteload, event, keyword } = useAppSelector((state) => state.event);
	const { id, category, name, organiser, state, country } = event;

	const classes = useStyles();

	const handleClose = () => {
		dialogAction(false);
	};
	const dispatch = useAppDispatch();

	const [ld, setld] = useState(false);

	const success = (data: any) => {
		setld(false);
		setsc({
			head: 'Invite has been sent',
			message: 'The users will receive the request to attend the event',
			button: 'Okay',
			link: null,
		});
		setopen(true);
		setTimeout(() => {
			setopen(false);
			setsc(null);
			handleClose();
		}, 2000);
	};
	const failed = (data: any) => {
		setld(false);
		const { status, text, dev_data, app_data } = data;
		let head;
		let message;
		if (status === null) {
			head = 'Check your internet connection and try again.';
			message = "We couldn't connect to the server";
		} else if (status === 500) {
			head = 'Somethings wrong with our Server!';
			message = 'Please be patient while we try to fix it.';
		} else {
			head = "Something's not right.";
			message = 'please try again later';
		}
		seter({
			head: head,
			message: message,
			button: 'Okay',
			link: null,
		});
		setTimeout(() => {
			setopen1(false);
			seter(null);
		}, 5000);
		setopen1(true);
	};
	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);
	console.log('INVITE EVENT', event);
	const Invite = () => {
		setld(true);
		let orgId = organiser.map((item: any) => item.id);
		let cat = category?.map((item) => item.id);
		let data = {
			'category': cat,
			'name': name,
			'organiser': orgId,
			'invitation': Invited,
			'participants': [],
			'tag': [],
			'state': state,
			'country': country,
			'latitude': event.latitude,
			'longitude': event.longitude,
			'start_date': event.start_date?.toString(),
			'end_date': event.end_date?.toString(),
		};
		let theurl = `/api/v1/event/edit/${id}/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/edit/${id}/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('put', theurl, data, success, failed, history, true, true);
	};

	const success2 = (res: any) => {
		let paginationStat = res.data.length < 12 ? false : true;
		dispatch(
			eventinvitesuccess({
				data: res.data,
				page: page + 1,
				pagination: paginationStat,
			})
		);
	};
	const failed2 = (error: any) => {
		if (error.response && error.response.data) {
			let statusCode = error.response.status;
			let statusText = error.response.statusText;
			let error_dev_data = error.response.data.dev_data;
			let error_app_data = error.response.data.app_data;
			let res = {
				status: statusCode,
				text: statusText,
				dev_data: error_dev_data,
				app_data: error_app_data,
			};
			dispatch(eventinvitefailed(res));
		} else {
			dispatch(
				eventinvitefailed({
					status: null,
					text: 'Network error',
					dev_data: null,
					app_data: null,
				})
			);
		}
	};
	let dt = {
		'search_type': 'people',
		'keyword': keyword,
	};
	const ScrollPos = (val: any) => {
		const { scrollHeight, scrollTop, clientHeight } = val.target;
		let bottom = scrollHeight - scrollTop === clientHeight;
		if (bottom) {
			if (pagination && !inviteload) {
				// dispatch(notificationsRequest())
				dispatch(eventpaginationinviteload());
				let theurl = `/api/v1/search/${page}/12/`;
				if (keyword !== null && keyword !== '') {
					if (unparseddetails) {
						if (currentparsed.is_business_profile) {
							theurl = `/api/v1/search/${page}/12/?auth_profile=${parseddetails[0].id}`;
						}
					}
				} else {
					let theurl = `/api/v1/search/friends/${page}/12/`;
					if (unparseddetails) {
						if (currentparsed.is_business_profile) {
							theurl = `/api/v1/search/friends/${page}/12/?auth_profile=${parseddetails[0].id}`;
						}
					}
				}

				Api('post', theurl, dt, success2, failed2, history, true, true);

				// let page_no_val = Profile_page + 1;
				// fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
			}
		}
	};

	const Next = () => {
		dispatch(eventpaginationinviteload());
		let theurl = `/api/v1/search/${page}/12/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/search/${page}/12/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('post', theurl, dt, success2, failed2, history, true, true);
	};

	const Refresh = () => {
		dispatch(eventpaginationinviteload());
		let theurl = `/api/v1/search/1/12/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/search/1/12/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('post', theurl, dt, success2, failed2, history, true, true);
	};
	let ifConnected = window.navigator.onLine;
	return (
		<div style={{ minHeight: '100vh' }}>
			<Modal
				// fullScreen
				open={dialogStatus}
				onClose={handleClose}
				// TransitionComponent={Transition}
				className={classes.modal}>
				<div className={`invitepeople ${classes.paper}`}>
					<div className='inviteheading'>
						<h2>Invite more people</h2>
					</div>
					<div className='invitepeoplecontent'>
						<div className='invitesect1'>
							{/* <div className="AddEventSectTitle">Name</div>
                            <Invitesearch mode={'invite'} />
                            <small>
                                {invites.length + ' users selected'}
                            </small> */}
							{/* <div className='eventinvitesearch' >
                                <input type='text' placeholder='Search' ></input>
                                <Search className='cursor' />

                            </div> */}
							<Invitesearch eventId={eventId} />
							<small>{Invited.length + ' users selected'}</small>
						</div>
						{/* <div > */}

						<Scrollbars
							className='eventinvitelist'
							// onScroll={(e)=>{ScrollPos(e)}}
						>
							{InviteList.map((item: any, key: number) => {
								const { id } = item;
								return (
									// !inviteload &&
									Invited.includes(id) ? (
										<InviteItem user={item} k={key} check={true} />
									) : (
										<InviteItem user={item} k={key} check={false} />
									)
								);
							})}
							{inviteload &&
								[...Array(6)].map((item, key) => {
									return <div className='AddEventUserItem skeleton-loader'></div>;
								})}
							{inviteerror !== null &&
								(inviteerror.text === 'Network error' ? (
									!ifConnected && (
										<div className='errormsg'>
											<Nointernet />
											<h2>{errors.internet.heading}</h2>
											<span>{errors.internet.message}</span>
										</div>
									)
								) : inviteerror.status === 500 ? (
									<div className='errormsg'>
										<Server />
										<h2>{errors.server.heading}</h2>
										<span>{errors.server.message}</span>
									</div>
								) : inviteerror.status === 400 ? (
									<div className='errormsg'>
										<NotFound />
										<h2>{errors.common.heading}</h2>
										<span>{errors.common.message}</span>
									</div>
								) : null)}
						</Scrollbars>

						{/* <Scrollbars className="eventinvitelist" > */}
						{/* <InfiniteScroll
                        height={400}
                            dataLength={InviteList.length} //This is important field to render the next data
                            next={Next}
                            hasMore={true}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                            // below props only if you need pull down functionality
                            refreshFunction={Refresh}
                            pullDownToRefresh
                            pullDownToRefreshThreshold={50}
                            pullDownToRefreshContent={
                                <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                            }
                            releaseToRefreshContent={
                                <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                            }
                        >
                            {InviteList.map((item: any, key: number) => {
                                const { id } = item;
                                return (
                                    Invited.includes(id) ?
                                        <InviteItem user={item} k={key} check={true} />
                                        : <InviteItem user={item} k={key} check={false} />
                                )
                            })}
                        </InfiniteScroll> */}
						{/* </Scrollbars> */}

						{/* {invitesearchload === false && invitesearchfailed === null ? (
                                invitesearch !== null
                                    // && invitesearch !== 'undefined' 
                                    ? (
                                        invitesearch.map((item: any, key: number) => {
                                            const { id } = item;
                                            return (
                                                invites.includes(id) ?
                                                    <InviteItem user={item} k={key} check={true} />
                                                    : <InviteItem user={item} k={key} check={false} />
                                            )
                                        })
                                    ) : "search for users"
                            ) : invitesearchload === true && invitesearchfailed === null ? (
                                [...Array(6)].map((item, key) => {
                                    return (<div className="AddEventUserItem skeleton-loader"></div>)
                                })
                            ) : (
                                null
                                // <div className='invitefailed'>no users found</div>
                            )
                            } */}

						{/* </div> */}
						<div className='eventinvitebuttons'>
							<button
								className='mainBtnInv'
								onClick={() => {
									handleClose();
									dispatch(addeventinviteclear());
								}}>
								Cancel
							</button>
							<button
								className='mainBtn minwid100'
								onClick={Invite}
								disabled={Invited?.length === 0}
								style={{ opacity: Invited.length === 0 ? 0.5 : 1 }}>
								{ld ? (
									<div style={{ color: 'white' }}>
										<CircularProgress color='inherit' style={{ height: '20px', width: '20px' }} />
									</div>
								) : (
									'Send Invites'
								)}
							</button>
						</div>
					</div>
					{open && (
						<SuccessModal
							open={open}
							setOpen={setopen}
							// success={suc}
							success={sc}
						/>
					)}
					{open1 && (
						<ErrorModal
							open={open1}
							setOpen={setopen1}
							// success={suc}
							error={er}
						/>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default InvitePeople;
