import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ModalLayout from './ModalLayout';
import { useAppSelector } from '../../app/hooks';
import { baseurl } from '../../Data/Data';
import { ReactComponent as Tick } from '../../assets/svg/lgcheck.svg';



interface common {
    handleClose: () => void
    open: boolean
    userList: [any]
    setUserList: any
}
const CommonProfileSelect = ({ handleClose, open, userList, setUserList }: common) => {





    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');
    const [data, setdata] = useState<any>([]);

    const { lat, lng } = useAppSelector(state => state.profile)
    let unparseddetails: any = localStorage.getItem('pdetails');
    let parseddetails = JSON.parse(unparseddetails);
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const SearchProfile = (text: string) => {
        setloading(true);
        const request = axios.CancelToken.source();
        const token = localStorage.getItem(`access_token`);
        // let data;
        // console.log('searching user');
        // data = {
        // 	'search_type': 'people',
        // 	'keyword': text,
        // };

        let data = {
            search_type: "people",
            keyword: text,
            latitude: lat,
            longitude: lng,
        };

        let theurl = `/api/v1/search/1/12/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/search/1/12/?&auth_profile=${parseddetails[0].id}`
            }

        }


        var config: any = {
            method: 'post',
            url: baseurl + theurl,
            cancelToken: request.token,
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            data: data,
        };

        axios(config)
            .then((res) => {
                if (res?.status === 200) {
                    setdata(res?.data || []);
                }
                setloading(false);

                console.log(res);
                console.log(res.data);
            })
            .catch((error) => {
                // const errorMsg = error.message
                setloading(false);

                seterror(error?.message || "Something went wrong!")
                console.log(error);
                // console.log(errorMsg);
            });
    };

    useEffect(() => {
        SearchProfile('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const addUser = (data: any) => {
        let CurData = [...userList]
        if (CurData.filter(e => e.id === data?.id).length > 0) {

            let newdata = CurData.filter(e => e.id !== data?.id)
            setUserList(newdata)
        } else {
            CurData.push(data)
            setUserList(CurData)
        }

        //   if(userList.some(user => user.id === id)){

    }

    // const [toogle, settoogle] = useState(true)

    // const clear = () => { 

    //     let arr:any = []
    //     setUserList(arr)
    //     // settoogle(!toogle)
    //  }

    console.log("User List", userList)

    return (

        <ModalLayout open={open} handleClose={handleClose} style={{ height: '100vh', overflow: 'hidden' }}>
            <div style={{ padding: '20px', backgroundColor: 'white', height: '100%' }}>
                <div className='AddEventContentTitle' style={{ paddingBottom: '0px', height: '60px', justifyContent: 'space-between' }}>
                    Select App Users
                </div>

                <input
                    type='text'
                    className='AddEventSectInput'
                    autoFocus
                    onChange={(e) => {
                        SearchProfile(e.target.value);
                    }}
                />

                <div
                    className='invitesect2'
                    style={{ display: 'flex', flexDirection: 'column', width: '100%', flexWrap: 'nowrap', height: '70vh', overflow: 'auto' }}>
                    {!loading && error === ''
                        ? data.map((item: any, key: number) => {
                            // return <InviteItem user={item} k={key} check={false} selectProfile={selectProfile} selectedProfile={selectedProfile}/>;

                            const { id } = item;

                            if (userList.some(user => user.id === id)) {

                                return <InviteItem user={item} k={key} check={true} addUser={addUser} />
                            } else {
                                return <InviteItem user={item} k={key} check={false} addUser={addUser} />

                            }

                        })
                        : loading && error !== ''
                            ? [...Array(6)].map((item, key) => {
                                return <div className='AddEventUserItem skeleton-loader'></div>;
                            })
                            : null}
                </div>
                <div>
                    <div style={{ display: 'flex', alignSelf: 'flex-end', gap: '10px', padding: "20px 0px 0px 0px", justifyContent: 'flex-end' }}>
                        {/* <button style={{ minWidth: '100px' }} className='mainBtnInv' onClick={clear}>
						Reset
					</button> */}

                        <button style={{ minWidth: '100px' }} className={'mainBtn'} onClick={handleClose}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </ModalLayout>
    );

}

export default CommonProfileSelect



const InviteItem = ({ user, k, check, addUser }: { user: any; k: number; check: boolean, addUser: any }) => {
    const { dp, firstname, lastname, username } = user;
    const [checked, setchecked] = useState(check);
    return (
        <div className='AddEventUserItem'>
            <div className='AddEventUserSec2'>{username}</div>
            <div className='AddEventUserSec1'>
                <div className={checked ? 'lgbox checked' : 'lgbox notchecked'} onClick={e => {
                    setchecked(!checked)
                    addUser(user)
                }}>
                    {checked && <Tick />}
                </div>
                <img src={dp} alt='P_I_C' className='AddEventUserDpImg' />

                <div className='AddEventUsrName'>{firstname + ' ' + lastname}</div>
            </div>
        </div>
    );
};
