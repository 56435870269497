import React, { useState, useEffect, useRef, PureComponent, useLayoutEffect } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AnalyticsChart = ({ analyticschartdata }: any) => {
    const d = new Date();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // const [change, setchange] = useState(true)
    // useLayoutEffect(() => {
    //     setchange(true)
    // }, [])

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} fontSize={11} color={'#7D7D8D'} textAnchor="middle" >
                        {payload.value + ` ${month[d.getMonth()]}`}
                    </text>
                </g>
            );
        }
    }

    class CustomizedYAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dx={-10} fontSize={11} color={'#7D7D8D'} textAnchor="middle" >
                        {payload.value}
                    </text>
                </g>
            );
        }
    }
    const CustomizedDot = (props: any) => {
        const { cx, cy, stroke, payload, value } = props;
        console.log(payload, value)

        if (payload.date === d.getDate()) {
            return (
                <svg x={cx - 7.5} y={cy - 7.5} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.5" cy="7.5" r="5" fill="white" stroke="#040405" stroke-width="5" />
                </svg>
                // <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
                //   <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
                // </svg>
            );
        }

        return (
            <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
                {/* <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" /> */}
            </svg>
        );
    };
    return (
        <div className='cm_linechart' style={{ minHeight: '200px' }}>
            <ResponsiveContainer>
                <AreaChart
                    data={analyticschartdata}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="#32A071" stopOpacity={0.16} />
                            <stop offset="90%" stopColor="#FFFFFF" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tick={<CustomizedAxisTick />} tickFormatter={(label: any) => label + ` ${month[d.getMonth()]}`} axisLine={false} tickLine={false} />
                    <YAxis axisLine={false} tickLine={false} tick={<CustomizedYAxisTick />} />
                    <Tooltip formatter={(value: any, name: any, props: any) => {
                        console.log(value, props, name)
                        return [value, 'No moderated contents']

                    }} labelFormatter={(value: any, name: any) => {
                        return [`${value + " " + month[d.getMonth()]}`]
                    }} />
                    <Area type='stepBefore' dataKey="moderated_content" stroke="#32A071" fillOpacity={1} fill="url(#colorUv)" dot={CustomizedDot} />
                </AreaChart>
            </ResponsiveContainer>
            {/* <Line className='linechart' options={options} data={data1} style={{ maxHeight: '184px' }} /> */}
        </div>
    )
}

export default AnalyticsChart