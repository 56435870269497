import React, { useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { bothStageUpdate, remain3 } from '../store/addEventSlice';
import { ReactComponent as Ticketicon } from '../../../../assets/svg/ticket.svg';
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg';
import './addticket.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as Select } from '../../../../assets/svg/select.svg';
import freeicon from './free.svg';
import paidicon from './paid.svg';
import donationicon from './donation.svg';
import clock from '../../../../assets/svg/clock.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/selectarrow.svg';
import PayoutModal from '../../ticketportal/screens/payments/components/PaymentDetailModal';
import StripeModal from './StripeModal';
import WarningModal from '../../../smallcomponents/modals/Warning'
import AddEventPopup from './addEventPopup';
import Api from '../../../../app/api';
import { useHistory } from 'react-router-dom';
import { accountLinked } from '../../../../components/login/store/loginSlice'
import { Console } from 'console';

const style: any = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minHeight: '50vh',
	minWidth: 400,
	// width: '50vw',
	width: '500px',
	maxWidth: '688px',
	bgcolor: 'background.paper',
	borderRadius: '12px',
	// border: '2px solid #000',
	boxShadow: 24,
	// p: 4,
};

const AddnewticketModal = ({ ticket, index, open, setOpen, handleClose, tickettypes, settickettypes, trigger, settrigger, newModalOpen, getChargeData }: any) => {
	console.log(ticket);

	const maincategory = [
		{
			id: 0,
			app_category_icon: freeicon,
			name: 'Free',
		},
		{
			id: 1,
			app_category_icon: paidicon,
			name: 'Paid',
		},
		{
			id: 2,
			app_category_icon: donationicon,
			name: 'Donation',
		},
	];
	let newarr;
	let arr;
	let thearr;
	const history = useHistory();
	const dispatch = useAppDispatch();
	const [eventtype, changeType] = useState(ticket ? ticket.pricingid : 0);

	const [quantity, setquantity] = useState<any>(ticket ? ticket.quantity : 1);
	const [maxquantity, setmaxquantity] = useState<any>(ticket ? ticket.maxquantity : 1);
	const modalClose = () => setStripeConnectModal(false)

	const [ticketname, setticketname] = useState(ticket ? ticket.name : '');
	const [ticketNameError, setTicketNameError] = useState<any>(false)
	const [ticketprice, setTicketprice] = useState<any>(ticket ? ticket.price : '');
	const [ticketPriceError, setTicketPriceError] = useState<any>(false)
	const [stripeConnectModal, setStripeConnectModal] = useState<any>(true);
	const [StripeModalOpen, setStripeModalOpen] = useState<any>(false)
	const [open3, setOpen3] = useState<any>(false)
	const [war, setwar] = useState<any>(null)
	const [attendeePays, setAttendeePays] = useState(0)
	const [payout, setPayout] = useState(0)
	const [paidTicket, setPaidTicket] = useState<any>(ticket ? ticket.pricing === 'Paid' ? true : false : false)
	const [collectFromUser, setCollectFromUser] = useState<any>(ticket ? ticket.collectTaxFromUsers : false)
	// const [trigger, settrigger] = useState(false)
	const { connected_account_exist, connected_account_linked } = useAppSelector(state => state.login)
	const { stripeAccountCreate } = useAppSelector(state => state.addEvent)

	const handleClosed = () => {
		setStripeModalOpen(false)
	}
	console.log('exist', connected_account_exist)
	console.log('linked', connected_account_linked)

	useEffect(() => {

		if (Object.keys(stripeAccountCreate).length !== 0) {

			const success = (data: any) => {
				dispatch(accountLinked(true))
				localStorage.setItem("account_linked", "true")
			}
			const failed = (data: any) => {

			}


			Api('post', '/api/v1/ticketing-portal/connected-account/link/status/', {
				"account_id": stripeAccountCreate?.account_id
			}, success, failed, history, true, true)
		}


	}, [])
	const setPayoutValues = (value: any, checked?: any) => {

		let amount = ((Number(getChargeData?.variable_charge) / 100) * 10) + Number(getChargeData?.fixed_rate);

		if (checked === true) {
			setAttendeePays(Number(value) + Number(amount))
			setPayout(value)
		} else {
			setAttendeePays(value)
			setPayout(Number(value) - Number(amount))
		}


	}
	return (
		<Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
			<Box sx={style}>
				<div style={{ padding: '1.5rem', minHeight: '50vh', display: 'flex', flexDirection: 'column' }}>
					{open3 &&
						<AddEventPopup open3={open3} setOpen3={setOpen3} war={war} setStripeModal={setStripeModalOpen} />
					}
					{
						StripeModalOpen && <StripeModal open={StripeModalOpen} handleClosed={handleClosed} />
					}
					<div className='cm_minicontent_header'>
						<h3 style={{ fontSize: '1.5rem' }}>{'Add ticket'}</h3>
						<CloseIcon onClick={handleClose} className='cursor' />
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '2rem 0' }}>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
							<div className=''>Pricing category</div>
							<div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
								{maincategory.map((cat: any, key: number) => {

									const { app_category_icon, id, name } = cat;
									// return eventtype === id ? (
									// 	<div
									// 		className='exploreBoxDiv ticketpricingcategory'
									// 		style={{ border: '1px solid var(--cl_orange)' }}
									// 		key={key}
									// 		onClick={
									// 			() => {
									// 				console.log('clicked')

									// 			}
									// 		}
									// 		id={id}>
									// 		<div className='exploreBoxIcon'>
									// 			<img
									// 				alt='Category icon'
									// 				src={app_category_icon}
									// 				onError={(e) => {
									// 					const target: any = e.currentTarget;
									// 					target.onerror = null;
									// 					target.src = clock;
									// 				}}></img>
									// 		</div>
									// 		<div className='exploreBoxTitle'>{name}</div>
									// 		<Select id='select' style={{ display: 'block' }} />
									// 	</div>
									// ) : (
									// 	<div
									// 		className='exploreBoxDiv'
									// 		style={{ opacity: id === 0 ? 1 : 0.5, pointerEvents: id === 0 ? "all" : "none" }}

									// 		key={key}
									// 		id={id}
									// 		onClick={() => {
									// 			console.log('clicked');

									// 			if (ticket) {
									// 				settrigger(true);
									// 			}
									// 			changeType(id);
									// 		}}>
									// 		<div className='exploreBoxIcon'>
									// 			<img
									// 				alt='Category icon'
									// 				src={app_category_icon}
									// 				onError={(e: any) => {
									// 					const target = e.currentTarget;
									// 					target.onerror = null;
									// 					target.src = clock;
									// 				}}></img>
									// 		</div>
									// 		<div className='exploreBoxTitle'>{name}</div>
									// 	</div>
									// );
									console.log('-------------collect from user----------', collectFromUser)
									return (
										<div
											className='exploreBoxDiv ticketpricingcategory'
											style={{ border: eventtype === id ? '1px solid var(--cl_orange)' : '' }}
											key={key}
											onClick={
												() => {
													setOpen3(false)
													setPaidTicket(false)
													changeType(id)
													// setStripeModalOpen(true)

													if (id === 1) {
														setPaidTicket(true)
														if (connected_account_exist === false && connected_account_linked === false) {
															setOpen3(true);
															setwar({
																head: 'Create account',
																message: `You should  create an account for creating ticket `,
																buttonTrue: 'Create',
																buttonFalse: 'Cancel',
																create: "true"

															})
														}

														if (connected_account_exist === true && connected_account_linked === false) {
															setOpen3(true);
															setwar({
																head: 'Link account',
																message: `You should link your account for creating ticket `,
																buttonTrue: 'Link account',
																buttonFalse: 'Cancel',
																create: "false"
															})
														}

													}
												}
											}
											id={id}>
											<div className='exploreBoxIcon'>
												<img
													alt='Category icon'
													src={app_category_icon}
													onError={(e) => {
														const target: any = e.currentTarget;
														target.onerror = null;
														target.src = clock;
													}}></img>
											</div>
											<div className='exploreBoxTitle'>{name}</div>
											{eventtype === id ? <Select id='select' style={{ display: 'block' }} /> : null}
										</div>
									);

								})}
							</div>
						</div>
						<div className='' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}><div className=''>Name</div><small style={{ color: "red" }}>{ticketNameError ? "required*" : null}</small></div>
							<input
								type='text'
								className='ticketnormalinput'
								value={ticketname}
								onChange={(e: any) => {
									let val: any = e.currentTarget;
									if (ticket) {
										settrigger(true);
									}
									if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
										setTicketNameError(false);
									}
									setticketname(val.value);
								}}
							/>
						</div>
						{paidTicket && <div className='' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
							<div style={{ display: "flex", justifyContent: "space-between" }}><div className=''>Price</div><small style={{ color: "red" }}>{ticketPriceError ? "required*" : null}</small></div>
							<input
								type='number'
								className='ticketnormalinput'
								value={ticketprice}
								min='1'
								max='9999'
								onChange={(e: any) => {
									let val: any = e.currentTarget;
									if (ticket) {
										settrigger(true);
									}
									if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
										setTicketPriceError(false);
									}
									setTicketprice(val.value);
									setPayoutValues(val.value)
								}}
							/>
						</div>
						}
						{paidTicket && <div style={{ display: "flex", gap: "12px" }}>
							<input checked={collectFromUser} onChange={e => {
								setCollectFromUser(e.target.checked)
								setPayoutValues(ticketprice, e.target.checked)
							}} style={{ height: "24px", width: "24px", borderRadius: "6px", }} type="checkbox" /><span>Make customers pay the transaction fees</span>
						</div>
						}
						{paidTicket && ticketprice > 0 && <div style={{ display: "flex", paddingRight: "20px" }}>
							<div style={{ display: "flex", flexDirection: "column", marginRight: "40px" }}>
								<span style={{ fontWeight: "500" }}>Attendee pays</span>
								<span style={{ color: "var(--cl_orange)", fontSize: "18px" }}>{`${attendeePays} AUD`}</span>
							</div>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<span style={{ fontWeight: "500" }}>Payout</span>
								<span style={{ color: "var(--cl_orange)", fontSize: "18px" }}>{`${payout} AUD`}</span>
							</div>
						</div>}
						<div style={{ display: 'flex', gap: '1rem' }}>
							<div className='' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<div className=''>Quantity</div>
								<input
									type='number'
									className='ticketnormalinput'
									min='1'
									max='9999'
									style={{ maxWidth: "80px" }}

									value={quantity}
									onChange={(e) => {
										let chg: any = e.currentTarget;
										if (ticket) {
											settrigger(true);
										}
										console.log(parseInt(maxquantity));
										console.log(parseInt(chg.value));
										console.log(parseInt(maxquantity) > parseInt(chg.value));
										if (parseInt(maxquantity) > parseInt(chg.value)) {
											setmaxquantity(chg.value);
										}
										setquantity(chg.value);
									}}
								/>
							</div>
							<div className='' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<div className=''>Max Quantity</div>
								<input
									type='number'
									min='1'
									style={{ maxWidth: "80px" }}
									max={quantity}
									className='ticketnormalinput'
									value={maxquantity}
									onChange={(e) => {
										let val: any = e.currentTarget;
										if (ticket) {
											settrigger(true);
										}
										console.log(parseInt(val.value));
										console.log(parseInt(quantity));
										console.log(parseInt(val.value) > parseInt(quantity));

										if (parseInt(val.value) > parseInt(quantity)) {
											setquantity(val.value);
										}
										setmaxquantity(val.value);
									}}
								/>
							</div>
							{/* <div className='selectbox'
                                    onClick={
                                        e => {
                                            if (!visible) {
                                                setselectedstate('selecting')
                                            } else {
                                                setselectedstate('selected')
                                            }
                                            setvisible(!visible)
                                        }
                                    }>
                                    <div className={(selectedvalue === 1) ? 'selectbox selectboxinput selectboxinputclr' : 'selectbox selectboxinput'}>
                                        <p>{selectedvalue}</p>
                                    </div>
                                    <div className={visible ? 'selectdropdown selectactive' : 'selectdropdown selectinactive'}>
                                        <span className='selecttriangle'></span>
                                        <div className='ul'>
                                            <Scrollbars style={{ width: 220, height: 300 }} autoHide>
                                                <li value='All categories' onClick={e => {
                                                    const target: any = e.currentTarget
                                                    setvalue(target.getAttribute('value'))
                                                    setcategoryid(null);
                                                }}
                                                >All</li>
                                                {

                                                    [...Array(quantity)].map((item: any, key: number) => {
                                                        // const { id, name } = item;
                                                        return (
                                                            <li value={key+1} key={key} onClick={
                                                                e => {
                                                                    const target: any = e.currentTarget
                                                                    setvalue(target.getAttribute('value'));
                                                                    setcategoryid(key+1);
                                                                }}>{key+1}</li>
                                                        )

                                                    })
                                                }
                                            </Scrollbars>
                                        </div>
                                    </div>

                                    <ArrowDown className={selectedstate === 'selecting' ? 'selectarrow arrowdown' : selectedstate === 'selected' ? 'selectarrow arrowleft' : 'selectarrow'} />
                                </div> */}
						</div>
						<div className='ticketmodalfooter'>
							<div className='AddEventCancelBtn' onClick={handleClose}>
								Cancel
							</div>
							{/* {currentstage < reachedstage ? null :  */}
							{

								paidTicket === true ?
									connected_account_exist && connected_account_linked && <div
										className='AddEventNextBtn'
										onClick={(e) => {
											// let newarr ={
											//     "pricing_category":maincategory[eventtype].name,
											//     "category_name":ticketname,
											//     "price": 0,
											//     "ticket_count": quantity,
											//     "max_quantity": maxquantity,
											//     'pricingid': eventtype
											// }
											if (ticketname === '') {
												setTicketNameError(true)
											}
											if (ticketprice === '') {
												setTicketPriceError(true)
											}
											if (ticketname !== '' && ticketprice !== '') {
												let newarr = {
													name: ticketname,
													pricing: maincategory[eventtype].name,
													quantity: quantity,
													maxquantity: maxquantity,
													pricingid: eventtype,
													price: ticketprice,
													collectTaxFromUsers: collectFromUser

												};
												if (ticket) {
													let thearr = tickettypes;
													thearr[index] = newarr;
													settickettypes(thearr);
												} else {
													let arr = tickettypes.concat([newarr]);
													console.log('event array', arr)
													settickettypes(arr);
												}

												handleClose();
											}
										}}>
										{ticket && trigger ? 'Save changes' : 'Add ticket'}

									</div> : <div
										className='AddEventNextBtn'
										onClick={(e) => {
											// let newarr ={
											//     "pricing_category":maincategory[eventtype].name,
											//     "category_name":ticketname,
											//     "price": 0,
											//     "ticket_count": quantity,
											//     "max_quantity": maxquantity,
											//     'pricingid': eventtype
											// }
											if (ticketname === '') {
												setTicketNameError(true)
											}
											if (ticketname !== '') {
												let newarr = {
													name: ticketname,
													pricing: maincategory[eventtype].name,
													quantity: quantity,
													maxquantity: maxquantity,
													pricingid: eventtype,
													price: 0,
													collectTaxFromUsers: collectFromUser
												};
												if (ticket) {
													let thearr = tickettypes;
													thearr[index] = newarr;
													settickettypes(thearr);
												} else {
													let arr = tickettypes.concat([newarr]);
													console.log('--------event free----', arr)

													settickettypes(arr);
												}

												handleClose();
											}
										}}>
										{ticket && trigger ? 'Save changes' : 'Add ticket'}

									</div>}
							{/* {stripeConnectModal && <StripeModal open={stripeConnectModal} handleClose={modalClose} />} */}

							{/* } */}

						</div>



					</div>

					{/* <div>
                            

                        </div> */}

				</div>
			</Box>
		</Modal>
	);
};

export default AddnewticketModal;
