import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { assigned } from '../store/addEventSlice';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ReactComponent as ArrowDown } from '../../../../assets/svg/selectarrow.svg'
import { BsPlus } from 'react-icons/bs'
import Customroleadd from '../../../smallcomponents/customroleadd/Customroleadd';
import { useHistory } from 'react-router-dom';
import Api from '../../../../app/api';
import { FailedToast, SucessToast } from '../../../utils/toasts';

interface A {
    id: number,
    role: string,
}
const AssignParticipant = ({ eventid, user, k, open, setopen, roles, setroles }: { user: any, k: number, open: any, setopen: any, roles: any, setroles: any, eventid: any }) => {
    const dispatch = useAppDispatch()

    const eventData = useAppSelector(state => state.addEvent)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    let history = useHistory()
    const { assigned: check }: { assigned: A[] | null } = eventData;

    const { dp, firstname, lastname, id, username, surname } = user;

    let initial;
    if (check.length !== 0) {
        let array = check.filter((item: any) => item.id === id)

        initial = array.length !== 0 ? array[0].role : null
    } else {
        initial = null
    }

    // let Roles = ['Admin', 'Manager', 'Support','DOUCHEBAAAAAAAAGLONGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG','Working'];
    const [selectedvalue, setvalue] = useState(user?.role !== null ? user.role : 'Assign role')
    const [visible, setvisible] = useState(false)
    const [selectedstate, setselectedstate] = useState(user.role ? "selected" :'initial')
    const [added, setadded] = useState(false)


    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (visible && targt && !targt.contains(e.target)) {
                setvisible(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [visible])

    const assignFx = () => {

        const success = () => {
            SucessToast()
            setadded(true)
            setvisible(false)

        }
        const failed = (res: any) => {
            FailedToast(res?.app_data)

        }
        let data = {
            "event": eventid,
            "participant": user.id,
            "role": selectedvalue
        }



        let theurl = `/api/v1/event/assign/role/`
        // {{baseurl}}/api/v1/event/assign/role/?auth_profile=4
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/assign/role/?auth_profile=${parseddetails[0].id}`
            }
        }

        console.log("I have run")
        Api('post', theurl, data, success, failed, history, true, true)

    }
    console.log("VISISBLE" , visible)

    return (
        <>
            <div className="AddEventUserItem"  >
                <div ref={vis} className='selectbox1'
                style={{ display : user?.usertype === "guestuser" ? "none" :""}}
                    onClick={
                        e => {
                            e.preventDefault();e.stopPropagation();
                            if (!visible) {
                                setselectedstate('selecting')
                            } else {
                                setselectedstate('selected')
                            }
                            setvisible(true)
                        }
                    }>
                    <div className='selectbox1 selectboxinput1' style={{ color: user?.assigned_role || added ? "green" : "black" }} >
                        <p>{selectedvalue}</p>
                    </div>
                    <div className={visible ? 'selectdropdown selectactive ' : 'selectdropdown selectinactive'} onClick={e => e.preventDefault()}>
                        <span className='selecttriangle'></span>
                        <div className='ul1'>

                            <Scrollbars style={{ width: 220, minHeight: "200px" }} autoHide >
                                <li style={{ backgroundColor: 'var(--cl_bggrey)', textAlign: 'center', paddingRight: '10px', justifyContent: 'center', alignItems: 'center' }} onClick={(e) => {
                                    e.preventDefault()
                                    setopen(true)
                                }}><BsPlus /> Custom</li>
                                {/* <li value='Assign role'
                                    onClick={e => {
                                        e.preventDefault();
                                        const target: any = e.currentTarget
                                        dispatch(assigned({
                                            id: id,
                                            role: target.getAttribute('value')
                                        }))
                                        setvalue(target.getAttribute('value'))
                                        setvisible(true)
                                    }}
                                >None</li> */}
                                {

                                    roles.map((item: any, key: number) => {

                                        return (
                                            <li id={id} value={item} onClick={
                                                e => {
                                                    e.preventDefault()

                                                    const target: any = e.currentTarget
                                                    dispatch(assigned({
                                                        id: id,
                                                        role: target.getAttribute('value')
                                                    }))
                                                    setvalue(target.getAttribute('value'))
                                                    setvisible(true)
                                                }}>{item}</li>
                                        )

                                    })
                                }

                            </Scrollbars>
                            <div style={{ backgroundColor: 'var(--cl_bggrey)', textAlign: 'center', display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: "5px 10px" }} onClick={(e) => {
                                // e.preventDefault()
                            }}>


                                <button className="mainBtnInv" style={{ minWidth: "80px" }} onClick={(e) => {e.preventDefault(); e.stopPropagation();setvisible(false) }} >
                                    Cancel
                                </button>
                                <button className='mainBtn' style={{ minWidth: "80px" }} onClick={() => assignFx()}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        <ArrowDown className={selectedstate === 'selecting' ? 'selectarrow1 arrowdown' : selectedstate === 'selected' ? 'selectarrow1 arrowleft' : 'selectarrow1'} />}
                </div>
                <div className="AddEventUserSec2" style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {username}
                </div>
                <div className="AddEventUserSec1">
                    <div className="AddEventUserDp">
                        <img src={dp || 
                        "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/default-profile.png"

                        } alt="P_I_C" className="AddEventUserDpImg" />
                    </div>
                    <div className="AddEventUsrName">{(firstname || user?.custom_form?.Name || '' ) + " " + (lastname || surname || '')}</div>
                </div>
            </div>


        </>
    )
}

export default AssignParticipant
