import React,{useState,useEffect} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Bg from '../../assets/images/footerbg.png'
import Logo from '../../assets/images/logowhite.png'
import './footer.css'

const Footer: React.FC = () => {
    const history = useHistory()

    return (
        <div className="footer" >
            <div className='footerContainer' style={{ backgroundImage: `url(${Bg})` }}>
                <div className='footerContent tw-px-6 tw-py-8 lg:tw-px-32 lg:tw-py-20'>
                    <div className='subscribe'>
                        <h1 className='!tw-text-2xl lg:!tw-text-3xl'>Subscribe to receive the latest updates!</h1>
                        <div className='subscribeInput'>
                            <input type="email" placeholder="Your email" className="subscribeMail tw-w-80" ></input>
                            <button className="mainBtn">Subscribe</button>
                        </div>
                    </div>
                    <hr></hr>
                    <div className='footerMain'>
                        <div className='footerMain1 lg:tw-flex tw-justify-between'>
                            <div className='footerSection1 tw-pt-4 tw-max-w-md '>
                                <img src={Logo} alt='Mutual events logo white' className="footerlogo" onClick={() => {
                                    history.push('/')
                                }}></img>
                                <span>Mutual Events brings people together through events. Discover events that match user defined interests, organize an event and create your own ticketing system.</span>
                            </div>
                            <div className='footerSection2 tw-my-1 lg:flex tw-w-full lg:tw-gap-32 lg:tw-px-8 lg:tw-py-8'>
                                <div className='footerSub'>
                                    <h3>Menu</h3>
                                    <span onClick={() => {
                                        history.push('/')
                                    }}>Browse</span>
                                    <span onClick={() => {
                                        history.push('/feed')
                                    }}>Feed</span>
                                    <span onClick={() => {
                                        history.push('/events')
                                    }}>Events</span>
                                    <span onClick={() => {
                                        history.push('/profile')
                                    }}>Profile</span>
                                </div>
                                <div className='footerSub tw-my-1'>
                                    <h3>Programs</h3>
                                    <span onClick={() => {
                                        history.push('/trending')
                                    }}>Trending</span>
                                    <span onClick={() => {
                                        history.push('/discover')
                                    }}>Discover</span>
                                </div>
                                <div className='footerSub tw-my-1'>
                                    <h3>Legal</h3>
                                    <span onClick={() => {
                                        history.push('/privacy-policy')
                                    }}>Privacy Policy</span>
                                    <span onClick={() => {
                                        history.push('/terms-of-service')
                                    }}>Terms of Service</span>
                                    <span onClick={() => {
                                        history.push('/user-guidelines')
                                    }}>User Guidelines</span>
                                </div>
                            </div>
                        </div>
                        <div className='footerMain2 tw-flex tw-gap-2 tw-py-2'>
                            <span>Copyright © 2021 by </span>
                            <p>Mutual Events.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
