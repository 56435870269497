import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ReactComponent as Select } from '../../../../assets/svg/select.svg';
import clock from '../../../../assets/svg/clock.svg';

import SingleDay from '../../../../assets/svg/singleday.svg';
import MultiDay from '../../../../assets/svg/multiday.svg';
import CalendarIndex from './newCalendar/calendar';
import ClockTimePicker from '../../../utils/timepicker/Timepicker';
import DatePicker from 'react-datepicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import '../add.css';
import { UTCtimezones } from '../../../../Data/Data';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import SelectDropdown from '../../../utils/SelectDropdown';
import { NumberListFx, DatesFx, monthArray } from '../../../utils/SelectionListFuctions';
import ToggleSwitch from '../../../utils/toogleSwitch';
import Radio from '@mui/material/Radio';
import { bothStageUpdate, time, remain2, addtrigger, currentStageUpdate, addtriggerclear } from '../store/addEventSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useOutsideClose } from '../../../utils/useOutsideClose';
import { timeZonejson } from '../../../../Data/timeZones';


var weekday = require('dayjs/plugin/weekday')

dayjs.extend(dayjsPluginUTC);
dayjs.extend(weekday)
// var timezone = require('dayjs/plugin/timezone')
// dayjs.extend(timezone)
const dropDownStyleNew = {
	width: '100%',
}
interface Iweeks {
	label: "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday"
	value: 1 | 2 | 3 | 4 | 5 | 6 | 7
}

// interface IDates {
// 	label: "1" | "2"| "3"| "4"| "5"| "6"| "7"| "8"| "9"|"10"|"11"|"12"|"13"|"14"|"15"|"16"|"17"|"18"|"19"|"20"|"21"|"22"|"23"|"24"|"25"|"26"|"27"|"28"|"29"|"30"|"31"
// 	value: 1 |2 |3 |4 |5 |6 |7 |8 |9 |10 |11 |12 |13 |14 |15 |16 |17 |18 |19 |20 |21 |22 |23 |24 |25 |26 |27 |28 |29 |30 |31
// }
interface IMonths {
	label: "Jan" | "Feb" | "Mar" | "Apr" | "May" | "Jun" | "Jul" | "Aug" | "Sep" | "Oct" | "Nov" | "Dec"
	value: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#ff5917',
		},
		secondary: {
			main: '#ce7f5e',
		},
	},

});


const customWeek = [
	{ label: "First", value: 1 },
	{ label: "Second", value: 2 },
	{ label: "Third", value: 3 },
	{ label: "Fouth", value: 4 },
	{ label: "Fifth", value: 5 },
	{ label: "Last", value: 6 },

]

const customWeekDay = [
	{ label: "Sunday", value: 1 },
	{ label: "Monday", value: 2 },
	{ label: "Tuesday", value: 3 },
	{ label: "Wednesday", value: 4 },
	{ label: "Thursday", value: 5 },
	{ label: "Friday", value: 6 },
	{ label: "Saturday", value: 7 },
	{ label: "day", value: 8 },


]

const WeekDay = [
	{ label: "sunday", value: 1 },
	{ label: "monday", value: 2 },
	{ label: "tuesday", value: 3 },
	{ label: "wednesday", value: 4 },
	{ label: "thursday", value: 5 },
	{ label: "friday", value: 6 },
	{ label: "saturday", value: 7 },
]

const UpdatedDateTime = () => {

	const dispatch = useAppDispatch()
	const eventData = useAppSelector((state) => state.addEvent);
	const { currentstage, reachedstage, trigger, startdate, enddate, startUTC, endUTC , repeating_event , repeat_event} = eventData;



	const [startDate, setStartDate] = useState<Date | null>(new Date(dayjs().set('hour', 8).set('minute', 0).set('second', 0)));
	const [endDate, setEndDate] = useState<Date | null>(new Date(dayjs().set('hour', 17).set('minute', 0).set('second', 0)));

	const [ismultiDay, setismultiDay] = useState(false);

 

	// const [starting, setstarting] = useState<any>(new Date(startdate));

	// const [ending, setending] = useState<any>(new Date(enddate));
	const [selectedDates, setselectedDates] = useState<Date[]>()


	// const [fromTimeOpen, setFromTimeOpen] = useState<any>(false);
	// const [toTimeOpen, setToTimeOpen] = useState<any>(false);
	// const handleCloseFrom = () => setFromTimeOpen(false);
	// const handleCloseTo = () => setToTimeOpen(false);

	const [fromerr, setfromerr] = useState<any>(null);
	const [toerr, settoerr] = useState<any>(null);
	// const [starterrUTC, setstarterrUTC] = useState<any>(null);
	// const [enderrUTC, setenderrUTC] = useState<any>(null);

	const [timer, settimer] = useState<any>();


	const handleChangeTime1 = (newValue: any) => {
		if (JSON.stringify(newValue) === 'null') {
			settoerr('Invalid Date');
			console.log('invalid');
		} else {
			setStartDate(newValue);

			settoerr(null);
		}
	};
	const handleChangeTime2 = (newValue: any) => {
		if (JSON.stringify(newValue) === 'null') {
			settoerr('Invalid Date');
			console.log('invalid');
		} else {
			setEndDate(newValue);
			setRepeatEndDate(new Date(dayjs(newValue).add(1, "d")))
			settoerr(null);
		}
	};

	const handleChangeDate1 = (date: any) => {
		setStartDate(date)
		if (!ismultiDay) {
			setEndDate(new Date(dayjs(date).add(8, "h")));
			setRepeatEndDate(new Date(dayjs(date).add(1, "d").add(8, "h")))

		}

		if(dayjs(endDate).diff(date) < 0){
			if(ismultiDay){
				setEndDate(new Date(dayjs(date).add(1, "d")));
				setRepeatEndDate(new Date(dayjs(date).add(2, "d").add(8, "h")))
			}else{
				setEndDate(new Date(dayjs(date).add(8, "h")));
				setRepeatEndDate(new Date(dayjs(date).add(1, "d").add(8, "h")))
			}

		}
	}
	const handleChangeDate2 = (date: any) => {
		setEndDate(date);
		setRepeatEndDate(new Date(dayjs(date).add(1, "d")))
	}



	function findUserTimezone(timezones: any) {
		return timezones.utc.includes(Intl.DateTimeFormat().resolvedOptions().timeZone);
	}

	let USER_TIMEZONE = timeZonejson.find(findUserTimezone);

	// const [selectedTimezone, setSelectedTimezone] = useState<any>(startUTC ? startUTC?.text : Intl.DateTimeFormat().resolvedOptions().timeZone);
	const [selectedTimezone, setSelectedTimezone] = useState<any>(USER_TIMEZONE);

	const timeRef: any = useRef();
	const timeRefInput: any = useRef();

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (!timeRef) return;
			if (timeRef.current && !timeRef.current.contains(event.target)) {
				hideSuggestion();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [timeRef]);

	const [Visibility, setVisibility] = useState(false);
	const [SuggestionList, setSuggestionList] = useState(timeZonejson);
	const hideSuggestion = () => setVisibility(false);
	const showSuggestion = () => {
		if (timeRefInput) {
			console.log(timeRefInput);
			timeRefInput?.current?.focus();
		}

		setVisibility(true);
	};
	const Suggestion_fx = (time: any) => {
		if (!time) {
			setSuggestionList(timeZonejson);
		} else {
			let matches = timeZonejson.filter((item) => {
				const regex = new RegExp(`${time}`, 'gi');
				return item.text.match(regex);
			});
			let final_list_data: any = [];
			if (matches.length === 0) return;
			matches.map((item_val, length) => {
				// if(length < 4) {
				final_list_data.push(item_val);
				// }
				return null;
			});

			setSuggestionList(final_list_data);
		}
	};

	const handleDateTimeFx = () => {
		console.log('=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>');


		let startTimeUTC;
		let endTimeUTC;

		if (startDate && endDate) {

			if (Number(selectedTimezone?.offset) === USER_TIMEZONE?.offset) {
				startTimeUTC = dayjs.utc(new Date(startDate)).format('YYYY-MM-DDTHH:mm:00');
				endTimeUTC = dayjs.utc(new Date(endDate)).format('YYYY-MM-DDTHH:mm:00');
			} else {
				startTimeUTC = dayjs(startDate).add(-1 * selectedTimezone?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')
				endTimeUTC = dayjs(endDate).add(-1 * selectedTimezone?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')
			}



			console.log("startTimeUTC", startTimeUTC)
			console.log("endTimeUTC", endTimeUTC)

			console.log('<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=');
		}
	};
	// console.log("START DATE", startDate)
	// console.log("END DATE", endDate)





	const handleRepeat = (type: "daily" | "weekly" | "monthly" | "yearly", repeatCount: number) => {
		if (!startDate && !endDate) return null


		if (type === "daily") {
			console.log('=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>');

			let neededDate: Date[] = []
			const dateStart = dayjs(startDate)
			const dateEnd = dayjs(endDate)
			let maxEventDays = Math.floor(dateEnd.diff(dateStart, 'days') / repeatCount)
			console.log("maxEventDays", maxEventDays)
			let temp = dayjs(startDate)
			for (let i = 0; i < maxEventDays; i++) {
				neededDate.push(temp)
				temp = dayjs(temp).add(repeatCount, 'day')
			}
			console.log("All Days", neededDate)
			console.log('<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=');
			setselectedDates(neededDate)

		}
		else if ('weekly') {


			console.log('=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>');

			let neededDate: Date[] = []
			const dateStart = dayjs(startDate)
			const dateEnd = dayjs(endDate)
			let maxEventDays = Math.floor(dateEnd.diff(dateStart, 'days') / repeatCount)
			let tempDateArray = []
			let temp = dayjs(startDate)

			for (let i = 0; i < maxEventDays; i++) {
				tempDateArray.push(temp)
				temp = dayjs(temp).add(1, 'day')
			}

			for (let i = 0; i < tempDateArray.length; i++) {
				dayjs(tempDateArray[i]).weekday(7);
			}

			console.log("maxEventDays", maxEventDays)


			// let temp = dayjs(startDate)
		}
		else if ('monthly') {

		} else if ('yearly') {



		} else {
			console.log("Oh Shit!!!")
		}


	}



	const [allDay, setAllDay] = useState(false)
	const [repeat, setRepeat] = useState(false)
	const [repeatEndDate, setRepeatEndDate] = useState<Date | null>(new Date(dayjs(startDate).add(1, "d").add(8, "h")));
	const handleChangeEnd = (newValue: any) => {
		if (JSON.stringify(newValue) === 'null') {
			settoerr('Invalid Date');
			console.log('invalid');
		} else {
			setRepeatEndDate(newValue);
			settoerr(null);
		}
	};




	const [repeatType, setRepeatType] = useState<"daily" | "weekly" | "monthly" | "yearly">("daily")
	const [frequency, setfrequency] = useState<{ label: any, value: any }>()

	const [repeatedWeekdays, setRepeatedWeekdays] = useState<Iweeks[]>([{ label: 'sunday', value: 1 }])
	const [repeatedMonths, setRepeatedMonths] = useState<IMonths[]>([{ label: 'Jan', value: 1 }])

	const [repeatedDates, setRepeatedDates] = useState<number[]>([1])
	const [customWeekDayVal, setcustomWeekDayVal] = useState({ label: "Sunday", value: 1 })
	const [customDayVal, setcustomDayVal] = useState({ label: "First", value: 1 })


	const [isToggledYear, setIsToggledYear] = useState(true);
	const [isToggledMonth, setIsToggledMonth] = useState(true);
	const [isToggledYearCustom, setIsToggledYearCustom] = useState(false);
	const [isToggledMonthCustom, setIsToggledMonthCustom] = useState(false);
	const onToggleMonth = () => setIsToggledMonth(!isToggledMonth);
	const onToggleYear = () => { setIsToggledYear(!isToggledYear) };
	const onToggleMonthCustom = () => setIsToggledMonthCustom(!isToggledMonthCustom);
	const onToggleYearCustom = () => setIsToggledYearCustom(!isToggledYearCustom);


	// const fromRef : any= useRef()
	// const {Visibility:isFrom  , open : openFrom} = useOutsideClose()


	const handleRepeatType = (type: "daily" | "weekly" | "monthly" | "yearly") => {
		setRepeatType(type)
		if (type === "daily") {
			setRepeatedDates([1])
			setRepeatedWeekdays([{ label: 'sunday', value: 1 }])
			setRepeatedMonths([{ label: 'Jan', value: 1 }])
			setcustomDayVal({ label: "First", value: 1 })
			setcustomWeekDayVal({ label: "Sunday", value: 1 })


		} else if (type === 'weekly') {
			setRepeatedDates([1])
			setRepeatedWeekdays([{ label: 'sunday', value: 1 }])
			setRepeatedMonths([{ label: 'Jan', value: 1 }])
			setcustomDayVal({ label: "First", value: 1 })
			setcustomWeekDayVal({ label: "Sunday", value: 1 })



		} else if (type === "monthly") {

			setRepeatedDates([1])
			setRepeatedWeekdays([{ label: 'sunday', value: 1 }])
			setRepeatedMonths([{ label: 'Jan', value: 1 }])
			setcustomDayVal({ label: "First", value: 1 })
			setcustomWeekDayVal({ label: "Sunday", value: 1 })


		} else {
			setRepeatedDates([1])
			setRepeatedWeekdays([{ label: 'sunday', value: 1 }])
			setRepeatedMonths([{ label: 'Jan', value: 1 }])
			setcustomDayVal({ label: "First", value: 1 })
			setcustomWeekDayVal({ label: "Sunday", value: 1 })

		}
	}

	const handleRepeatedWeeks = (week: Iweeks) => {
		let tempArray = [...repeatedWeekdays]
		if (tempArray.some((item) => item.label === week.label)) {
			if (tempArray.length === 1) return
			setRepeatedWeekdays((prev: any) => prev.filter((item: any) => item.label !== week.label))
		} else {
			tempArray.push(week)
			setRepeatedWeekdays(tempArray.sort((a, b) => a.value - b.value))
		}
	}

	const handleRepeatedMonths = (month: any) => {
		let tempArray = [...repeatedMonths]
		if (tempArray.some((item) => item.label === month.label)) {
			if (tempArray.length === 1) return
			setRepeatedMonths((prev: any) => prev.filter((item: any) => item.label !== month.label))
		} else {
			tempArray.push(month)
			setRepeatedMonths(tempArray.sort((a, b) => a.value - b.value))
		}
	}

	const handleRepeateDates = (date: number) => {
		let tempArray = [...repeatedDates]
		if (tempArray.includes(date)) {
			if (tempArray.length === 1) return
			setRepeatedDates((prev: any) => prev.filter((item: any) => item !== date))
		} else {
			tempArray.push(date)
			// arr.sort((a, b) => a.name > b.name ? 1 : -1);
			setRepeatedDates(tempArray.sort((a, b) => a - b))
		}
	}






	const NextStepFx = () => {
		let startTimeUTC;
		let endTimeUTC;
		let repeatTimeUTC;


		if (startDate && endDate) {

			console.log("intl", dayjs.utc(new Date(startDate)).format('YYYY-MM-DDTHH:mm:00'))
			console.log("sele", dayjs(startDate).add(-1 * selectedTimezone?.offset, 'h').format('YYYY-MM-DDTHH:mm:00'))


			if (Number(selectedTimezone?.offset) === USER_TIMEZONE?.offset) {

				console.log("i Run")
				startTimeUTC = dayjs.utc(new Date(startDate)).format('YYYY-MM-DDTHH:mm:00');
				endTimeUTC = dayjs.utc(new Date(endDate)).format('YYYY-MM-DDTHH:mm:00');
				if (repeatEndDate) {
					repeatTimeUTC = dayjs.utc(new Date(repeatEndDate)).format('YYYY-MM-DDTHH:mm:00');
				}


			} else {
				console.log("i  2 Run")

				startTimeUTC = dayjs(startDate).add(-1 * selectedTimezone?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')
				endTimeUTC = dayjs(endDate).add(-1 * selectedTimezone?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')
				repeatTimeUTC = dayjs(repeatEndDate).add(-1 * selectedTimezone?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')

			}
		}
		if (startTimeUTC && endTimeUTC) {
			console.log("I HAVE UPDATEDED")
			dispatch(addtriggerclear());
			dispatch(
				time({
					start: startTimeUTC,
					end: endTimeUTC,
					starttimezone: selectedTimezone?.name,
					endtimezone: selectedTimezone?.name,
					repeating_event: repeat,
					single_day_event: !ismultiDay,
					multi_day_event: ismultiDay,
					repeat_type: repeatType,
					frequency: frequency?.value,
					repeat_ending_datetime: repeatTimeUTC,
					days: repeatedWeekdays,
					dates: isToggledMonthCustom ? [] : repeatedDates,
					months: isToggledYearCustom ? [] : repeatedMonths,
					custom: {
						type: customDayVal?.label,
						day: customWeekDayVal?.value
					},
					isToggledMonthCustom,
					isToggledYearCustom,
					allDay,
					repeat_event:{
						id: 0,
						repeating_type: repeatType,
						frequency_of_repeating:frequency?.value ,
						days: repeatedWeekdays,
						dates: repeatedDates,
						months: repeatedMonths,
						custom_type: customDayVal,
						custom_day: customWeekDayVal,
						repeat_ending_datetime: repeatTimeUTC,
						all_day_event : allDay
					}



				})
			);
			dispatch(currentStageUpdate({ currentstage: 4 }));


		}

	}

	const handleDayType = (type: string) => {
		if (type === "single") {

			setEndDate(new Date(dayjs(startDate).add(8, "h")))
			setRepeatEndDate(new Date(dayjs(startDate).add(1, "d").add(8, "h")))


		} else {
			setEndDate(new Date(dayjs(startDate).add(1, "d").add(8, "h")))
			setRepeatEndDate(new Date(dayjs(startDate).add(2, "d").add(8, "h")))

		}
	}

	function getNumberWithOrdinal(n: any) {
		var s = ["th", "st", "nd", "rd"],
			v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	}


	const { ref:fromRef, handleOpen:handleOpenFrom, handleClose:handleCloseFrom, isVisible:fromTimeOpen } = useOutsideClose()
	const { ref:toRef, handleOpen:handleOpenTo, handleClose:handleCloseTo, isVisible:toTimeOpen } = useOutsideClose()


	useEffect(() => {

		function findCurrentEditTimezone(timezones: any) {
			return timezones.name === startUTC ||  timezones.name === startUTC.replace("UTC" ,"GMT") 
		}
		if (repeat_event && repeating_event) {

			console.log("I hAVe RUN")
			// let cur_weekDays: any = WeekDay.filter((item) => repeat_event?.days.includes(item?.value))
			// let cur_months: any = monthArray.filter((item) => repeat_event?.months.includes(item?.value))
			let repeatT: any = repeat_event?.repeating_type

			setRepeatedMonths(repeat_event?.months)
			setRepeatedDates(repeat_event?.dates)
			setRepeatedWeekdays(repeat_event?.days)
			setfrequency({ label: repeat_event?.frequency_of_repeating, value: repeat_event?.frequency_of_repeating })
			setAllDay(repeat_event?.all_day_event)
			setRepeat(true)
			setRepeatType(repeatT)
			console.log("repeatEvent" , repeat_event)

			console.log(repeatType , "repeatType")
			if(startUTC &&  startdate && enddate){
				let CUR_SELECTED_TIMEZONE = timeZonejson.find(findCurrentEditTimezone);
	
				console.log("CUR_SELECTED_TIMEZONE",CUR_SELECTED_TIMEZONE)
	
	
				if(CUR_SELECTED_TIMEZONE){
	
					// let startDateUtc = new Date(dayjs(startdate).add(-CUR_SELECTED_TIMEZONE?.offset, 'h').format('YYYY-MM-DDTHH:mm:00'))
	
					setStartDate(new Date(dayjs(startdate).add(CUR_SELECTED_TIMEZONE?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')))
					setEndDate(new Date(dayjs(enddate).add(CUR_SELECTED_TIMEZONE?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')))
				
					setRepeatEndDate(new Date(dayjs(repeat_event?.repeat_ending_datetime).add(CUR_SELECTED_TIMEZONE?.offset, 'h').format('YYYY-MM-DDTHH:mm:00')))
		
					setSelectedTimezone(CUR_SELECTED_TIMEZONE)
				}
				
			
			}
			// setRepeatType
		}
	


	}, [repeat_event])







	return (
		<div className='dateandtime_wrapper'>
			<div className='AddEventContentTitle'>{'Date & Time'}</div>
			<div className='addeventmaindiv' style={{ marginTop: '0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div className='datetime__section__layout'>
					<div className='datetime__section__wrapper'>
						<div className='title__wrapper'>
							<p> Type</p>
						</div>
						<div className='datetime__types'>
							<div onClick={() => handleDayType("single")}>
								<SelectType type={'Single Day'} icon={SingleDay} active={ismultiDay ? false : true} ismultiDay={ismultiDay} setismultiDay={setismultiDay} />
							</div>
							<div onClick={() => handleDayType("multi")}>
								<SelectType type={'Multiple Days'} icon={MultiDay} active={ismultiDay ? true : false} ismultiDay={ismultiDay} setismultiDay={setismultiDay} />
							</div>

						</div>
					</div>

					<div className='datetime__section__wrapper'>
						<div className='title__wrapper'>
							<p> Date </p>
							<div className='addeventratingstatus'>
								<div className='AddEventSectTitle selectnone'>All Day?</div>
								<div
									className={allDay ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
									onClick={() => setAllDay(!allDay)}>
									<div className='addeventdot'></div>
								</div>
							</div>
						</div>
						<div className='datetime__selectdatetime__wrapper'>
							<div className='start__datetime'>
								<div className='AddEventSectTitle'>Starts:{fromerr && <small>{fromerr}</small>}</div>
								{DateTimeRenderer(
									startDate,
									handleChangeDate1,
									// startTime,
									handleChangeTime1,
									handleOpenFrom,
									fromTimeOpen,
									handleCloseFrom,
									allDay,
									false,
									null,
									fromRef


								)}
							</div>
							<div className='end__datetime' >
								<div className='AddEventSectTitle'>Ends:{toerr && <small>{toerr}</small>}</div>
								{DateTimeRenderer(endDate, handleChangeDate2,
									//  endTime,
									handleChangeTime2, handleOpenTo, toTimeOpen, handleCloseTo, allDay, !ismultiDay, new Date(dayjs(startDate).add(1, "d")), toRef)}
							</div>
						</div>
					</div>

					<div className='datetime__section__wrapper'>
						<div className='title__wrapper'>
							<p> Repeat </p>
							<div className='addeventratingstatus'>
								<div
									className={repeat ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
									onClick={() => setRepeat(!repeat)}>
									<div className='addeventdot'></div>
								</div>
							</div>
						</div>
						<div className={repeat ? 'reoccur__wrapper' : "reoccur__wrapper__hide"} style={{ opacity: repeat ? 1 : 0.5, pointerEvents: repeat ? "all" : "none" }}>
							<div className='frequency__wrapper'>
								<div className='AddEventSectTitle'>Frequency:</div>
								<div className='frequency__types__wrapper'>
									<div className={repeatType === "daily" ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatType("daily")}>Daily</div>
									<div className={repeatType === "weekly" ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatType("weekly")}>Weekly</div>
									<div className={repeatType === "monthly" ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatType("monthly")}>Monthly</div>
									<div className={repeatType === "yearly" ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatType("yearly")}>yearly</div>
								</div>
							</div>
							<div className='frequency__wrapper'>
								<div className='AddEventSectTitle'>Every:</div>
								<div className='everyonce__wrapper'>
									<div style={{ display: "flex", alignItems: 'center', gap: "8px" }}>
										<SelectDropdown
											DataSet={NumberListFx()}
											value={frequency}
											setValue={setfrequency}
											ContainerClassName='multi__select__wrapper'
											width='70px'
											dropDownStyle={dropDownStyleNew}
										/>
										<small>
											{repeatType === "daily" && (frequency?.value === 1 ? 'day' : 'days')}
											{repeatType === "weekly" && (frequency?.value === 1 ? 'week' : 'weeks')}
											{repeatType === "monthly" && (frequency?.value === 1 ? 'month' : 'months')}
											{repeatType === "yearly" && (frequency?.value === 1 ? 'year' : 'years')}
										</small>
									</div>

								</div>
							</div>

							{repeatType === "daily" && <Fragment></Fragment>}
							{repeatType === "weekly" && <Fragment>
								<div className='frequency__wrapper'>
									<div className='AddEventSectTitle'>
										on:

										{/* <ToggleSwitch isToggled={isToggled} onToggle={onToggle}/> */}
									</div>

									<div className='frequency__types__wrapper'>
										<div className={repeatedWeekdays && repeatedWeekdays.some((item) => item?.label === "sunday") ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatedWeeks({ label: "sunday", value: 1 })}>S</div>
										<div className={repeatedWeekdays && repeatedWeekdays.some((item) => item?.label === "monday") ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatedWeeks({ label: "monday", value: 2 })}>M</div>
										<div className={repeatedWeekdays && repeatedWeekdays.some((item) => item?.label === "tuesday") ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatedWeeks({ label: "tuesday", value: 3 })}>T</div>
										<div className={repeatedWeekdays && repeatedWeekdays.some((item) => item?.label === "wednesday") ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatedWeeks({ label: "wednesday", value: 4 })}>W</div>
										<div className={repeatedWeekdays && repeatedWeekdays.some((item) => item?.label === "thursday") ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatedWeeks({ label: "thursday", value: 5 })}>T</div>
										<div className={repeatedWeekdays && repeatedWeekdays.some((item) => item?.label === "friday") ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatedWeeks({ label: "friday", value: 6 })}>F</div>
										<div className={repeatedWeekdays && repeatedWeekdays.some((item) => item?.label === "saturday") ? "frequency__type r__selected" : "frequency__type"} onClick={() => handleRepeatedWeeks({ label: "saturday", value: 7 })}>S</div>
									</div>
								</div>

							</Fragment>}
							{repeatType === "monthly" && <Fragment>

								<div className='frequency__wrapper'>
									<div className='AddEventSectTitle' style={{ alignItems: "center" }}>
										on the :
										<ThemeProvider theme={theme}>
											<Radio
												checked={isToggledMonth}
												// onClick={() => (!isToggledMonth || isToggledMonthCustom) && onToggleMonth()}
												onClick={() => { (isToggledMonthCustom && onToggleMonthCustom()); (!isToggledMonth && onToggleMonth()) }}

												value="a"
												name="radio-buttons"
												inputProps={{ 'aria-label': 'A' }}
											/>
										</ThemeProvider>

										{/* <ToggleSwitch isToggled={isToggledMonth} onToggle={onToggleMonth} /> */}
									</div>

									<div className='frequency__types__wrapper__month' style={{ opacity: isToggledMonth ? 1 : 0.5, pointerEvents: isToggledMonth ? "all" : "none" }}>

										{
											DatesFx().map((item, index) => {

												let selected = repeatedDates.includes(item?.value)
												return <div className={selected ? 'frequency__type__month m__selected' : 'frequency__type__month'} onClick={() => handleRepeateDates(item?.value)}>{item?.label}</div>
											})
										}


									</div>



								</div>
								<div className='frequency__wrapper'>
									<div className='AddEventSectTitle' style={{ alignItems: "center" }}>
										Each :
										<ThemeProvider theme={theme}>
											<Radio
												checked={isToggledMonthCustom}
												// onClick={() => (isToggledMonth || !isToggledMonthCustom) && onToggleMonthCustom()}
												onClick={() => { (isToggledMonth && onToggleMonth()); (!isToggledMonthCustom && onToggleMonthCustom()) }}

												// onClick={() => { (isToggledYearCustom && onToggleYearCustom()); (!isToggledYear && onToggleYear()) }}

												value="a"
												name="radio-buttons"
												inputProps={{ 'aria-label': 'A' }}
											/>
										</ThemeProvider>

										{/* <ToggleSwitch isToggled={isToggledMonth} onToggle={onToggleMonth} /> */}
									</div>


									<div style={{ display: "flex", gap: "15px"  ,  opacity: isToggledMonthCustom ? 1 : 0.5, pointerEvents: isToggledMonthCustom ? "all" : "none" }} >
										<SelectDropdown
											DataSet={customWeek}
											value={customDayVal}
											setValue={setcustomDayVal}
											ContainerClassName='multi__select__wrapper'
											width='120px'
											dropDownStyle={dropDownStyleNew}
										/>
										<SelectDropdown
											DataSet={customWeekDay}
											value={customWeekDayVal}
											setValue={setcustomWeekDayVal}
											ContainerClassName='multi__select__wrapper'
											width='120px'
											dropDownStyle={dropDownStyleNew}
										/>
									</div>


								</div>
							</Fragment>}
							{repeatType === "yearly" && <Fragment>
								<div className='frequency__wrapper'>
									<div className='AddEventSectTitle' style={{ alignItems: "center" }}>
										On the:
										<ThemeProvider theme={theme}>
											<Radio
												checked={isToggledYear}

												onClick={() => (!isToggledYear || isToggledYearCustom) && onToggleYear()}

												// onClick={() => { (isToggledYearCustom && onToggleYearCustom()); (!isToggledYear && onToggleYear()) }}


												// new


												// onClick={() => (isToggledMonth || !isToggledMonthCustom) && onToggleMonthCustom()}
												// onClick={() => (isToggledYear || !isToggledYearCustom) && onToggleYearCustom()}

												value="a"
												name="radio-buttons"
												inputProps={{ 'aria-label': 'A' }}
											/>
										</ThemeProvider>

									</div>

									<div className='frequency__types__wrapper__year' style={{ opacity: isToggledYear ? 1 : 0.5, pointerEvents: isToggledYear ? "all" : "none" }}>
										{
											monthArray.map((item, index) => {
												let selected = repeatedMonths.some((i) => i?.label === item?.label)
												return <div className={selected ? 'frequency__type__year m__selected' : 'frequency__type__year'} onClick={() => handleRepeatedMonths(item)}>{item?.label}</div>
											})
										}
									</div>


								</div>
								<div className='frequency__wrapper'>
									<div className='AddEventSectTitle' style={{ alignItems: "center" }}>
										Each:
										<ThemeProvider theme={theme}>
											<Radio
												checked={isToggledYearCustom}
												// onClick={() => { (isToggledYear && onToggleYear()); (!isToggledYearCustom && onToggleYearCustom()) }}

												onClick={() => (isToggledYear || !isToggledYearCustom) && onToggleYearCustom()}

												value="a"
												name="radio-buttons"
												inputProps={{ 'aria-label': 'A' }}
											/>
										</ThemeProvider>

									</div>

									<div style={{ display: "flex", gap: "15px"  ,  opacity: isToggledYearCustom ? 1 : 0.5, pointerEvents: isToggledYearCustom ? "all" : "none" }}>

										<SelectDropdown
											DataSet={customWeek}
											value={customDayVal}
											setValue={setcustomDayVal}
											ContainerClassName='multi__select__wrapper'
											width='120px'
											dropDownStyle={dropDownStyleNew}
										/>
										<SelectDropdown
											DataSet={customWeekDay}
											value={customWeekDayVal}
											setValue={setcustomWeekDayVal}
											ContainerClassName='multi__select__wrapper'
											width='120px'
											dropDownStyle={dropDownStyleNew}
										/>
									</div>


								</div>

							</Fragment>}

							<div className='frequency__wrapper'>
								<div className='AddEventSectTitle'>Repeat Enddate :</div>
								<div className='frequency__types__wrapper'>

									{DateTimeRenderer(
										repeatEndDate,
										setRepeatEndDate,
										handleChangeEnd,
										handleOpenFrom,
										fromTimeOpen,
										handleCloseFrom,
										true,
										false,
										new Date(dayjs(endDate).add(1, "d"))

									)}
								</div>

							</div>

							<p style={{ fontStyle: "italic", width: '100%', fontWeight: 500 }}>
								{repeat &&
									(
										`* Event will occur every ${frequency?.value}` +
										(repeatType === "daily" ? (frequency?.value === 1 ? ' day' : ' days') : "") +

										(repeatType === "weekly" ? (frequency?.value === 1 ? ' week' : ' weeks') : "") +

										(repeatType === "monthly" ? (frequency?.value === 1 ? ' month' : ' months') : "") +

										(repeatType === "yearly" ? (frequency?.value === 1 ? ' year' : ' years') : "") +

										(repeatType === "weekly" ? ' on' + repeatedWeekdays.map((item) => ` ${item?.label}`) : "") +

										(isToggledMonth &&
											repeatType === "monthly" ? ' on the' + repeatedDates.map((item) => ` ${getNumberWithOrdinal(item)}`) : "") +

										(isToggledMonthCustom &&
											repeatType === "monthly" ? ' on the ' + customDayVal?.label.toLocaleLowerCase() + " " + customWeekDayVal?.label.toLowerCase() : ""
										) +

										(
											(isToggledYear && isToggledYearCustom) &&
												repeatType === "yearly" ? ' on the ' + customDayVal?.label.toLocaleLowerCase() + " " + customWeekDayVal?.label.toLocaleLowerCase() + " of " + repeatedMonths.map((item) => ` ${item?.label}`) : "") +

										(
											(isToggledYear && !isToggledYearCustom) &&
												repeatType === "yearly" ? ' in' + repeatedMonths.map((item) => ` ${item?.label}`) : "")


										+ ".")
								}
							</p>
						</div>

					</div>
					<div className='datetime__section__wrapper'>
						<div className='title__wrapper'>
							<p> Timezone</p>
						</div>
						<div className='timezone__wrapper'>
							<div className='AddEventSectTitle'>Select timezone:</div>
							{SelectTimeZoneRenderer(selectedTimezone, showSuggestion, timeRef, Visibility, SuggestionList, timeRefInput, Suggestion_fx, setSelectedTimezone, hideSuggestion)}
						</div>
					</div>
					{/* <div className='datetime__section__wrapper'>
						<div className='title__wrapper'>
							<p>Alert</p>
						</div>
						<div className='alert__wrapper'>
							<div className='AddEventSectTitle'>Set alert:</div>
							<SelectDropdown
								DataSet={NumberListFx()}
								value={timer}
								setValue={settimer}
								ContainerClassName='multi__select__wrapper'
								width='70px'
								dropDownStyle={dropDownStyleNew}
							/>
						</div>
					</div> */}
				</div>
				<div className='datetime__calendar__layout tw-hidden lg:tw-block'>
					<CalendarIndex startDate={startDate} endDate={endDate} dateArray={selectedDates} />
				</div>
			</div>

			<div className='AddEventFooter'>
				<div
					className='AddEventCancelBtn'
					onClick={(e) => {
						// dispatch({ type: 'BOTHSTAGEUPDATE', currentstage: 1, reachedstage: 1 })
						handleRepeat(repeatType, frequency?.value)
					}}>
					Cancel
				</div>
				{/* {trigger === 'datetime' ? ( */}
				{/* <div id='dateandtimesave' className='AddEventNextBtn' onClick={handleDateTimeFx}>
					Save changes
				</div> */}
				{/* ) : currentstage < reachedstage ? null : ( */}
				<div id='dateandtimecancel' className='AddEventNextBtn' onClick={NextStepFx} >
					Next Step
				</div>
				{/* )} */}
			</div>
		</div>
	);
};

export default UpdatedDateTime;





function SelectTimeZoneRenderer(selectedTimezone: any, showSuggestion: () => void, timeRef: any, Visibility: boolean, SuggestionList: { value: string; abbr: string; offset: number; isdst: boolean; text: string; utc: string[]; }[], timeRefInput: any, Suggestion_fx: (time: any) => void, setSelectedTimezone: React.Dispatch<any>, hideSuggestion: () => void) {
	return <div className='timezone__select__wrapper' style={{ position: 'relative' }}>
		<input placeholder='UTC Timezone' style={{}} value={selectedTimezone?.text} onClick={showSuggestion} />

		{<div
			style={{
				position: 'absolute',
				top: '45px',
				right: '0px',
				width: '320px',
				zIndex: 999,
				backgroundColor: 'white',
				borderRadius: '4px',
			}}>
			<div
				className='suggestion-conatiner'
				ref={timeRef}
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					width: '100%',
					position: 'relative',
				}}>
				{Visibility && SuggestionList.length > 0 && (
					<div className='suggestion-main-layout'>
						<ul className='suggestion-list-container' style={{ position: 'relative' }}>
							<li
								className='suggestion-list-item'
								style={{
									padding: 0,
									position: 'sticky',
									top: '0px',
									marginTop: '0px',
									borderRadius: '0px',
								}}>
								<input
									placeholder='Search'
									style={{
										maxWidth: '100%',
										borderBottom: '1px solid grey',
										borderRadius: '0px',
									}}
									ref={timeRefInput}
									autoFocus
									// value={selectedTimezone?.text}
									// onClick={showSuggestion}
									onChange={(e) => {
										// setSelectedTimezone(e.target.value);
										// setCountry(e.target.value);
										Suggestion_fx(e.target.value);
									}} />
							</li>
							{SuggestionList &&
								SuggestionList.map((item: any, key: number) => {
									return (
										<>
											<li
												className='suggestion-list-item'
												key={key}
												onClick={() => {
													setSelectedTimezone(item);
													hideSuggestion();
												}}>
												{item.text}
											</li>
										</>
									);
								})}
						</ul>
					</div>
				)}
			</div>
		</div>}
	</div>;
}

function DateTimeRenderer(
	dateVal: Date | null,
	setDateVal: React.Dispatch<React.SetStateAction<Date | null>>,
	// timeVal: Date | null,
	handleChange: (newValue: any) => void,
	handleOpenClock: () => void,
	ClockOpen: any,
	handleCloseClock: () => void,
	allDay: boolean,
	disabled?: boolean,
	minDate?: Date | null,
	ref?:any

) {
	return (
		<div className='datetime__renderer__wrapper'>
			<DatePicker
				disabled={disabled}
				selected={dateVal}
				minDate={minDate}
				dateFormat='dd/MM/yyyy'
				onChange={(e: Date) => {
					setDateVal(e);
				}}
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns}>

				{
					!allDay &&
					<div className='input__timepicker' >
						<TimePicker
							disabled={allDay}
							label='Time'
							value={dateVal}
							disableOpenPicker={true}
							onChange={handleChange}
							renderInput={(params) => <TextField {...params} onClick={() => handleOpenClock()} />}
						/>
						{ClockOpen && (

							<div style={{ position: 'relative' }} ref={ref}>
								<ClockTimePicker
									value={dateVal}
									setValue={handleChange}
									label='Start time'
									style={{ position: 'absolute', top: '10px', zIndex: 100, left: 0 }}
									handleClose={handleCloseClock}
								/>

							</div>
						)}
					</div>
				}



			</LocalizationProvider>
		</div>
	);
}

function SelectType({ type, icon, active, setismultiDay, ismultiDay }: any) {
	return (
		<div className='exploreBoxDiv' style={{ border: active ? '1px solid var(--cl_orange)' : "", position: 'relative' }} onClick={() => active ? '' : setismultiDay(!ismultiDay)}>
			{
				active &&
				<Select id='select' style={{ display: 'block', transition: '1s ease-in-out', position: 'absolute', top: '6px', right: '6px' }} />}
			<div className='exploreBoxIcon'>
				<img
					alt='Category icon'
					style={{ width: '14px', height: '14px' }}
					src={icon}
					onError={(e) => {
						const target = e.currentTarget;
						target.onerror = null;
						target.src = clock;
					}}></img>
			</div>
			<div className='exploreBoxTitle'>{type}</div>
		</div>
	);
}
