import {   createSlice,   } from '@reduxjs/toolkit';

interface Legal{
    id?: number;
    community_guidelines?: any;
    privacy_policy?: any;
    terms_conditions?: any;  
    account_deletion?:any
}
interface Legalstate{
    loading: boolean;
    legal: Legal,
    error: any;
}

const initialState: Legalstate = {
    loading: false,
    legal: {},
    error: null
};
export const legalSlice = createSlice({
    name: 'legal',
    initialState,
    reducers: {
        legalRequest: (state) =>{
            state.loading = true
        },
        legalSuccess: (state,action: any)=>{
            state.loading = false;
            state.legal = action.payload;
            state.error = null;
        },
        legalFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        },
    }
})
export const { legalRequest,legalSuccess,legalFailed } = legalSlice.actions
export default legalSlice.reducer