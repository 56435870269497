import React, { useState } from "react";
import { useHistory, Route, Redirect, Link, Switch } from "react-router-dom";
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import './search.css'

const Search = () => {
    const history = useHistory();
    const [active, setactive] = useState(false)
    return (
        <div className='searchPage container'>
            <Switch>
                <Route path='/search/peoples'>
                    <div className='searchHeading'>
                        <div className='commonHeading'>
                            <small onClick={()=>history.push('/')}><Arrow />Back to home page</small>
                            <h3>People
                            </h3>
                            <span>Users based on your search</span>
                        </div>
                        <div className='searchmodediv'>
                            <Link to='/search/events' className={!active ? 'searchmodebtn searchmodebtninactive' : 'searchmodebtn searchmodebtnactive'} onClick={e => {
                                setactive(false)
                            }}><span>Events</span></Link>
                            <Link to='/search/peoples' className={active ? 'searchmodebtn searchmodebtninactive' : 'searchmodebtn searchmodebtnactive'} onClick={e => {
                                setactive(true)
                            }}><span>People</span></Link>
                        </div>
                    </div>

                    <div className='searchContent'></div>
                </Route>
                <Route path='/search/events' >
                    <div className='searchHeading'>
                        <div className='commonHeading'>
                            <small onClick={()=>history.push('/')}><Arrow />Back to previous page</small>
                            <h3>Events
                            </h3>
                            <span>Events based on your search</span>
                        </div>
                        <div className='searchmodediv'>
                            <Link to='/search/events' className={!active ? 'searchmodebtn searchmodebtninactive' : 'searchmodebtn searchmodebtnactive'} onClick={e => {
                                setactive(false)
                            }}><span>Events</span></Link>
                            <Link to='/search/peoples' className={active ? 'searchmodebtn searchmodebtninactive' : 'searchmodebtn searchmodebtnactive'} onClick={e => {
                                setactive(true)
                            }}><span>People</span></Link>
                        </div>
                    </div>

                    <div className='searchContent'></div>
                </Route>
                <Redirect to='/search/events' />
            </Switch>

        </div>
    )
}

export default Search
