import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Clock } from '../../../assets/svg/clock.svg'
import clock from '../../../assets/svg/clock.svg'
import './exploreby.css'
import Api from '../../../app/api'
import { categoriesRequest, categoriesSuccess, categoriesFailed } from './store/exploreSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';

const ExploreBy = () => {
    const history = useHistory();
    const dispatch = useAppDispatch()
    const { loading, categories, error } = useAppSelector(state => state.categories)
    const { profile } = useAppSelector(state => state.profile)

    const success = (data: any) => {
        dispatch(categoriesSuccess(data))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(categoriesFailed(data))
    }
    useEffect(() => {
        if (categories.length === 0) {
            dispatch(categoriesRequest())
            Api('get', '/api/v1/event/category/', null, success, failed, history, true, true)
        }

    }, [])

    function useHorizontalScroll() {
        const elRef = useRef();
        useEffect(() => {
            const el: any = elRef.current;
            if (el) {
                const onWheel = (e: any) => {
                    // console.log(e)
                    if (e.deltaY === 0) return;
                    e.preventDefault();
                    el.scrollTo({
                        left: el.scrollLeft + e.deltaY,
                        behavior: "smooth"
                    });
                };
                el.addEventListener("wheel", onWheel);
                return () => el.removeEventListener("wheel", onWheel);
            }
        }, []);
        return elRef;
    }

    const scrollRef: any = useHorizontalScroll();



    const [arrowvisible, setarrowvisible] = useState(false)

    return (
        <div className="exploreDivLayout">
            {/* <div className="exploreDivHead">
                <div className="exploreDivTitle">Explore by</div>
                <div className="exploreDivSubTitle">What can we help you to find, {'name'}?</div>
            </div> */}
            <div className='commonHeading'>
                <h2>Explore by</h2>
                <span>What can we help you to find, {profile?.firstname}?</span>
            </div>
            <div style={{ position: 'relative', height: '71px', display: 'flex' }}>
                <div className="exploreSubDiv" ref={scrollRef}
                    style={{ position: 'relative' }}
                    // onMouseEnter={e=>{
                    //     console.log(e)
                    //     window.addEventListener('scroll',()=>{
                    //         e.preventDefault()
                    //         console.log(e)
                    //     })
                    // }} onMouseLeave={e=>{console.log(e)}} onScroll={e=>{console.log(e)}}
                    onMouseEnter={(e) => {
                        console.log(e)
                        setarrowvisible(true)
                    }}
                    onMouseLeave={(e) => {
                        setarrowvisible(false)
                    }}
                >
                    {
                        loading === false && error === null &&
                        <div className="exploreBoxDiv"
                            onClick={() => { history.push('/todays_events') }}
                        >
                            <div className="exploreBoxIcon">
                                <Clock />
                            </div>
                            <div className="exploreBoxTitle">Today's Events</div>
                        </div>
                    }

                    {loading === false && error === null ? (
                        !(Object.keys(categories).length === 0) ? (
                            categories.map((items: any, key: any) => {
                                return (
                                    <div className="exploreBoxDiv" key={key}
                                        onClick={
                                            e => {
                                                // if(Category_id !== items.id) {
                                                // dispatch( {type: 'CATEGORY_ID',  category_id :items.id } )
                                                // dispatch( {type: 'EXPLORE_DATA_SUCCESS', explore_data : [], pagination: true, page_no : 1 } )
                                                // }
                                                history.push(`/category/${items.id}`, items.name)
                                            }
                                        }
                                    >
                                        <div className="exploreBoxIcon">
                                            <img alt='Category icon' src={items.category_icon} onError={(e) => {
                                                const target = e.currentTarget
                                                target.onerror = null;
                                                target.src = clock
                                            }} />
                                            {/* <object type="image/svg+xml" data={items.app_category_icon}>
                                            
                                            <img src={items.app_category_icon} />
                                        </object> */}
                                            {/* <embed type="image/svg+xml"  className='embed' src={items.app_category_icon} /> */}
                                            {/* <object type="image/svg+xml" className='embed' data={items.app_category_icon}></object> */}


                                        </div>
                                        <div className="exploreBoxTitle">{items.name}</div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="Topsearcherror">
                                <div >No categories found</div>
                            </div>
                        )
                    ) : (
                        <div className="exploreSubDiv">
                            {[...Array(6)].map((item, key) => {
                                return (
                                    <div className="exploreBoxDiv skeleton-loader">
                                        <div className="exploreBoxIcon skeleton"></div>
                                        <div className="exploreBoxTitle skeleton minwid60 minhe10" ></div>
                                    </div>
                                )
                            })}
                        </div>

                    )
                    }

                </div>
                <div className='horizontal_indicator'
                >
                    <Arrow style={{ stroke: 'var(--cl_textgrey)' }} />
                </div>
            </div>
        </div>
    );
}

export default ExploreBy
