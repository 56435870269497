import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import Api from '../../../app/api'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader'
import FeaturedProfileItem from '../../smallcomponents/featuredprofileitem/FeaturedProfileItem'
import { eventparticipantsfailed, eventparticipantsload, eventparticipantssuccess } from '../Event/store/eventSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import AssignParticipant from '../../Tabs/addevent/components/AssignParticipant'
import Customroleadd from '../../smallcomponents/customroleadd/Customroleadd'


const ParticipantsPage = ({id}:any) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { eventId }: any = useParams()


    const { participants, perror, ploading, ppage, ppagination } = useAppSelector(state => state.event)




    const success = (res: any) => {
        dispatch(eventparticipantssuccess({
            data: res,
            no: ppage + 1,
            pagination: res.length < 20 ? false : true
        }))
        // setloading(false)
        // setdata(data)
    }
    const failed = (data: any) => {
        dispatch(eventparticipantsfailed(data))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        if((Object.keys(participants).length === 0)){
            dispatch(eventparticipantsload())
            Api('get', `/api/v2/event/attending/${eventId}/1/20/`, null, success, failed, history, true, true)
        }
    }, [])



    const observer: any = useRef()

    const notificationsRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && ppagination) {
                if (!ploading) {
                    dispatch(eventparticipantsload())
                    Api('get', `/api/v2/event/attending/${eventId}/${ppage}/20/`, null, success, failed, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [participants, ploading])

    const [Roles, setroles] = useState<any>(['Admin', 'Manager', 'Support', "Assistant"])
    const [open, setopen] = useState(false)



    return (
        <div className='container participantsPage minvh100'>
            <div className='commonHeading'>
                <small onClick={() => history.goBack()}><Arrow />Back to event page</small>
                <h2>Participants</h2>
            </div>
            <div className="featuredProfilesList">
                {!(Object.keys(participants).length === 0) &&
                    (participants.map((item: any, key: number) => {
                        
                        if (participants.length === key + 1) {
                            return (
                                <div ref={notificationsRef}>
                                    {/* <FeaturedProfileItem featured={item} k={key} unf={true} place={''} /> */}
                                    <AssignParticipant user={item?.profile_details} k={key} open={open} setopen={setopen} roles={Roles} setroles={setroles} eventid={id} />

                                </div>
                            )
                        } else {
                            return (
                            // <FeaturedProfileItem featured={item} k={key} unf={true} place={''} />
                            <AssignParticipant user={item?.profile_details} k={key} open={open} setopen={setopen} roles={Roles} setroles={setroles}   eventid={id}/>

                            )
                        }
                    }))
                }

                {ploading &&

                    [...Array(6)].map(() => {
                        return (
                            <FeaturedProfileLoader />
                        )
                    })

                }
            </div>
            {
                open && <Customroleadd
                    dialogAction={setopen}
                    dialogStatus={open}
                    roles={Roles}
                    setroles={setroles}
                />
            }
        </div>
    )
}

export default ParticipantsPage
