import React,{useEffect} from 'react'
import { Route} from 'react-router-dom'

// const CommonRoute = ({type,path,mode, isAuth, component: Component, ...rest }:{type:string,path:string,mode:string,isAuth:boolean,component:any}) => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, [type])
//     return (
//         <Route {...rest} render={(props) => {
//             if (isAuth && mode === 'desktop') {
//                 return (
//                     <div className='wrappercommon'>
//                         <Component type={type} />
//                     </div>

//                 )
//             } else {
//                 return (<Component type={type} />)
//             }
//         }} />
//     )
// }




function CommonRoute({type,mode, isAuth, component: Component, ...rest }: any) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [type])
    return (
        <Route {...rest} render={(props) => {
            if (isAuth && mode === 'desktop') {
                return (
                    <div className='wrappercommon'>
                        <Component type={type} />
                    </div>
                )
            } else {
                return (<Component type={type} />)
            }
        }} />
    )
}

export default CommonRoute


