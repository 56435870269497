import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import './App.css';
import SubApp from './SubApp';
import HomePage from './withoutlogin/EventSearch/HomePage';
//routes
import ProtectedRoute from './routes/ProtectedRoute';
// others
import { isMobile } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from './app/hooks';

import Api from './app/api';
import { profileRequest, profileFailed, profileSuccess } from './components/Tabs/profile/store/profileSlice';
import { Getlocation, SOCKET_ENDPOINT } from './Data/Data';

import { getAnalytics } from 'firebase/analytics';

import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging/sw';

import { getToken } from 'firebase/messaging';
import { colorHelper, sitelink } from './Data/Data';
import logo from './assets/images/iconnew.png';
import { addmessagetochat, getsocket } from './components/Tabs/chat/store/ChatSlice';
import { store } from './app/store';
import ContentModerator from './components/Tabs/contentmoderator/main/ContentModerator';
import Chat from './components/Tabs/chat/Chat/Chat';
import ClaimableEvents from './components/Tabs/claimableEvents';
import LandingPage from './components/mobileview/LandingPage';
import WebLandingPage from './components/landingpage/WebLandingPage';
import GuestTicket from './components/guest/GuestTicket';
import SelectCategories from './components/login/SelectCategories';
import TicketportalIndex from './components/Tabs/ticketportal/TicketportalIndex';

import FeedWl from './withoutlogin/Feed/FeedWl';
import EventDetailWl from './withoutlogin/EventDetail/EventDetailWl';
import EventSearchResultWl from './withoutlogin/EventSearchResult/SearchResultScreen';
import IndexPageWl from './withoutlogin';
import Login from './components/login/Login';
import PublicRoute from './routes/PublicRoute';
import Signup from './components/login/Newersignup';
import ForgotPassword from './components/login/ForgotPassword';
import TicketPaymentSuccessful from './components/pages/Event/components/TicketPaymentSuccessful';
import TicketPaymentFailed from './components/pages/Event/components/TicketPaymentFailed';
const MainApp = () => {
	//MainApp because location permission asked in mobile app

	const history = useHistory();
	const { isAuth } = useAppSelector((state) => state.login);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { profile } = useAppSelector((state) => state.profile);

	const firebaseApp = initializeApp({
		apiKey: 'AIzaSyB_CgG3ZkmbfbzHSx7FQrnGvHAos3kqbC4',
		authDomain: 'mutual-events-app.firebaseapp.com',
		projectId: 'mutual-events-app',
		storageBucket: 'mutual-events-app.appspot.com',
		messagingSenderId: '764255163967',
		appId: '1:764255163967:web:ed5f094e4045d4a6cb8a8a',
		measurementId: 'G-KEZ55DFJMY',
	});
	const messaging: any = getMessaging(firebaseApp);
	const analytics = getAnalytics(firebaseApp);

	let prevmessage: any = null;

	function Showmessage(dt1: any) {
		const greeting = new Notification(dt1.notification.title, {
			body: dt1.notification.body,
			// icon: dt.notification.image
			// icon: dt1.data.appcontent_dp,
			icon: logo,
			image: dt1.notification.image,
			// image: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/events/user_events/2702.jpg',
		});
		// setTimeout(() => {
		//   if(no > 0){
		//     no - 1
		//   }
		// }, 5000);
		// setTimeout(() => greeting.close(), 7 * 1000);
		greeting.onclick = () => {
			// history.push('/notifications')
			switch (dt1.data.appcontent_type) {
				case 'event':
					window.open(`${sitelink}event/${dt1.data.appcontent_id}`, '_self');
					break;
				case 'post':
					window.open(`${sitelink}feed/${dt1.data.appcontent_id}`, '_self');
					break;
				case 'story':
					window.open(`${sitelink}profile/${dt1.data.appcontent_id}`, '_self');
					break;
				// case 'connection':
				//   // window.open(`${sitelink}feed/${dt1.data.appcontent_id}`, "_self")
				//   break;
				default:
					window.open(`${sitelink}notifications`, '_self');
					break;
			}
		};
	}

	// useEffect(() => {
	function Messagereceived(newmessage: any) {
		if (newmessage) {
			const { data: data1 }: any = newmessage;
			let dt1 = data1['firebase-messaging-msg-data'];
			if (prevmessage) {
				const { data }: any = prevmessage;
				let dt = data['firebase-messaging-msg-data'];
				if (dt.data.appcontent_id === dt1.data.appcontent_id) {
					// setprevmessage(newmessage);

					if (prevmessage.timeStamp !== newmessage.timeStamp) {
						prevmessage = newmessage;
						Showmessage(dt1);
					} else {
						if (dt.from !== dt1.from) {
							prevmessage = newmessage;
							Showmessage(dt1);
						}
					}
				} else {
					prevmessage = newmessage;
					Showmessage(dt1);
				}
			} else {
				// setprevmessage(newmessage);
				prevmessage = newmessage;
				Showmessage(dt1);
				// const greeting = new Notification(dt1.notification.title, {
				//   body: dt1.notification.body,
				//   // icon: dt.notification.image
				//   icon: dt1.data.appcontent_dp,
				//   image: dt1.notification.image
				// });
				// setTimeout(() => greeting.close(), 7 * 1000);
				// greeting.onclick = () => { history.push('/notifications') };
			}
		}
	}
	// }, [newmessage])

	const NotifiactionAction = (message: any) => {
		// const {data}: any= message;
		// let dt = data['firebase-messaging-msg-data']

		// setnewmessage(message)
		Messagereceived(message);
	};

	const NotificationTokenGen = () => {
		if (messaging) {
			let token_k = localStorage.getItem('notification_token0');
			if (token_k) {
				let token_key = token_k.toString();
				let appendData = new FormData();
				appendData.append('token', token_key);
				Api(
					'post-form',
					'/api/v1/user/token/set/',
					appendData,
					(su: any) => {
						// alert('token key success')
						console.log(su);
					},
					(er: any) => {
						console.log(er);
					},
					history,
					true,
					true
				);
			} else {
				getToken(messaging, { vapidKey: messaging.vapidKey })
					.then((token) => {
						console.log(token);
						let token_key = token.toString();
						let appendData = new FormData();
						localStorage.setItem('notification_token0', token_key);
						appendData.append('token', token_key);

						Api(
							'post-form',
							'/api/v1/user/token/set/',
							appendData,
							(su: any) => {
								// alert('token key success')
								console.log(su);
							},
							(er: any) => {
								console.log(er);
							},
							history,
							true,
							true
						);
					})
					.catch((er) => {
						console.log(er);
					});
			}

			navigator.serviceWorker.addEventListener('message', (message) => NotifiactionAction(message));
		}
	};
	function notifyMe() {
		if (!('Notification' in window)) {
			console.warn('This browser does not support desktop notification');
		} else if (Notification.permission === 'granted') {
			NotificationTokenGen();
		} else if (Notification.permission !== 'denied') {
			Notification.requestPermission(function (permission) {
				if (permission === 'granted') {
					NotificationTokenGen();
				}
			});
		}
		// else if (Notification.permission === "default") {
		//   Notification.requestPermission(function (permission) {
		//     if (permission === "granted") {
		//       var notification = new Notification("Hi there!");
		//     }
		//   });
		// }
	}

	useEffect(() => {
		if (isAuth) {
			notifyMe();
		}
		// CreateUUID()
		Getlocation(dispatch);
	}, [isAuth]);
	//dispatch and notifyme given cause of warning

	const { openedchatdetails } = useAppSelector((state) => state.chat);

	function SocketConnect() {
		let token = localStorage.getItem(`access_token`);
		let wsStart = 'wss://';
		// let endpoint = wsStart + '51c046c6-73be-447a-a38c-d999464b1b85.mutualevents.co:443/ws/api/v2/connect/'
		// let endpoint = wsStart + 'deep.mutualevents.co:443/ws/api/v2/connect/'
		let endpoint = wsStart + SOCKET_ENDPOINT;

		let socket = new WebSocket(endpoint + '?token=' + token);
		socket.onopen = function (event: any) {
			console.log('*************socket open****************', socket);

			dispatch(getsocket({ socket }));
		};
		socket.onclose = function (event: any) {
			// Inform the user about the error.
			if (event.code !== 1000) {
				// Error code 1000 means that the connection was closed normally.
				// Try to reconnect.

				if (!navigator.onLine) {
					SocketConnect();
					// alert("You are offline. Please connect to the Internet and try again.");
				} else {
					SocketConnect();
					// alert('socket closed but u are online')
				}
			} else {
				// alert('socket just closed')
			}
		};
		socket.onerror = function (event: any) {
			// console.log(event)
			// Object.keys(event).map((itm: any, key: number) => {
			//   console.log(event[key])
			// })
			// alert('socket error')
		};
		socket.onmessage = (res: any) => {
			console.log('=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>');
			console.log(res);
			console.log('<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=<=');

			let message = JSON.parse(res.data);
			console.log('---------------message-------', message);
			console.log('---------------message data-------', message.data);

			if (message.data) {
				let profilestate = store.getState().profile.profile;
				dispatch(
					addmessagetochat({
						data: message.data,
						type: 'message',
						profileid: profilestate.id,
					})
				);
				if (message.data.sender) {
					if (message.data.sender.id !== profilestate.id) {
						if (openedchatdetails) {
							if (openedchatdetails.chat_id === message.data.chat_reference_id) {
								// console.log({
								//   'message': message.data.message,
								//   'message_type': message.data.message_type,
								//   'contact_info': message.data.contact_info,
								//   'reply_reference_id': message.data.reply_reference_id,
								//   'connection_type': 'msg_read_status',
								//   'chat_id': message.data.chat_reference_id,
								//   'chat_msg_id': message.data.id,
								// })

								socket.send(
									JSON.stringify({
										'message': message.data.message,
										'message_type': message.data.message_type,
										'contact_info': message.data.contact_info,
										'reply_reference_id': message.data.reply_reference_id,
										'connection_type': 'msg_read_status',
										'chat_id': message.data.chat_reference_id,
										'chat_msg_id': message.data.id,
									})
								);
							}
						}
					}
				}
			} else if (message.read_status) {
				console.log('----------message read status---------', message.read_status);

				dispatch(
					addmessagetochat({
						data: message.read_status,
						type: 'read',
					})
				);
			} else {
				// alert('online stat came... check it out')
				// console.log(message)
			}
		};
	}

	const success1 = (data: any) => {
		dispatch(profileSuccess(data));
	};
	const failed = (data: any) => {
		dispatch(profileFailed(data));
	};
	useEffect(() => {
		if (isAuth) {
			SocketConnect();
		}
		// let unparsed = localStorage.getItem('pdetails');
		if (!isAuth) {
			let rem = localStorage.getItem('remember');
			console.log(rem);
			if (rem === null) {
				// if(unparsed){
				//   let parsed = JSON.parse('pdetails');
				//   parsed.slice(0,1)
				//   if(parsed.length !== 0){
				//     parsed.map((prof: any,key: number)=>{
				//       if(prof.rememberme && prof.rememberme === 'true'){
				//         localStorage.setItem('remember', 'true')
				//         localStorage.setItem(`mtusrtype`, 'personal')
				//         localStorage.setItem(`mtusr`, JSON.stringify(prof))

				//         let restofthem = parsed.filter((pr: any)=>prof.id !== pr.id)
				//         let newdetails = [prof].concat(restofthem)
				//         localStorage.setItem('pdetails',JSON.stringify(newdetails))
				//         Auth('login',prof.access,prof.refresh)
				//         return;
				//       }
				//     })
				//   }else{
				//     localStorage.removeItem(`access_token`);
				//     localStorage.removeItem(`refresh_token${0}`);
				//   }
				// }
				localStorage.removeItem(`access_token`);
				localStorage.removeItem(`refresh_token${0}`);
			} else {
				if (rem === 'false') {
					localStorage.removeItem(`access_token`);
					localStorage.removeItem(`refresh_token${0}`);

					// let parsed = JSON.parse('pdetails');
					// parsed.slice(0,1)
					// if(parsed.length !== 0){
					//   parsed.map((prof: any,key: number)=>{
					//     if(prof.rememberme && prof.rememberme === 'true'){
					//       localStorage.setItem('remember', 'true')
					//       localStorage.setItem(`mtusrtype`, 'personal')
					//       localStorage.setItem(`mtusr`, JSON.stringify(prof))

					//       let restofthem = parsed.filter((pr: any)=>prof.id !== pr.id)
					//       let newdetails = [prof].concat(restofthem)
					//       localStorage.setItem('pdetails',JSON.stringify(newdetails))
					//       Auth('login',prof.access,prof.refresh)
					//       return;
					//     }
					//   })
					// }else{
					//   localStorage.removeItem(`access_token`);
					//   localStorage.removeItem(`refresh_token${0}`);
					// }
				}
			}
		}

		if (isAuth) {
			// function getCoords() {
			//   return new Promise((resolve, reject) =>
			//     navigator.permissions ?

			//       // Permission API is implemented
			//       navigator.permissions.query({
			//         name: 'geolocation'
			//       }).then(permission =>
			//         // is geolocation granted?
			//         permission.state === "granted"
			//           ? navigator.geolocation.getCurrentPosition(pos => resolve(pos.coords))
			//           : resolve(null)
			//       ) :

			//     // Permission API was not implemented
			//     reject(new Error("Permission API is not supported"))
			//   )
			// }

			// getCoords().then(coords => console.log(coords))

			// if (navigator.geolocation) {
			//   navigator.geolocation.getCurrentPosition(success, error, options);

			//   // let locpermission: any = window.confirm('Allow Mutual Events to use your location \n We need your location to show you the nearest events')
			//   // if(locpermission){
			//   //   console.log('got')
			//   //   navigator.geolocation.getCurrentPosition(success, error, options);
			//   // }else{
			//   //   console.log('no hope')
			//   // }

			// } else {
			//   // alert('Geolocation is not supported by the browser')
			// }
			colorHelper(2, profile);
			// if ((Object.keys(profile).length) === 0 && loading === false) {

			dispatch(profileRequest());
			Api('get', '/api/v1/user/profile/', null, success1, failed, history, true, true);
			// }
		}
	}, [isAuth]);

	const path = useLocation();

	console.log('path', path);
	//isAuth is new here lookout
	return (
		<Switch>
			<ProtectedRoute path='/content-moderator' isAuth={isAuth} component={ContentModerator} />
			<ProtectedRoute path='/claimable-events' isAuth={isAuth} component={ClaimableEvents} />
			<ProtectedRoute path='/ticketing-portal' isAuth={isAuth} component={TicketportalIndex} />

			<ProtectedRoute path='/chat' isAuth={isAuth} component={Chat} />
			{/* <Route path="/landingpage" component={LandingPage} /> */}
			<Route exact path='/ticket/guest' component={GuestTicket} />
			<Route exact path='/interested-categories' component={SelectCategories} />
			<PublicRoute exact path="/signup" isAuth={isAuth} component={Signup} />
            <PublicRoute exact path="/forgetpassword" isAuth={isAuth} component={ForgotPassword} />

            {/* <PublicRoute exact path="/ticket-confirm" isAuth={isAuth} component={TicketPaymentSuccessful} />
            <PublicRoute exact path="/ticket-failed" isAuth={isAuth} component={TicketPaymentFailed} /> */}



			{/* <Route path='/feedwl' component={FeedWl} />
            <Route path='/eventwl/:eventId' component={EventDetailWl} />
            <Route path='/eventSearchResult' component={EventSearchResultWl} /> */}
			{/* */}

			{isAuth ? (
				<>
					<Route component={SubApp} />
				</>
			) : (
				<>
					{path?.pathname === '/login' ? (
						<>
							<PublicRoute exact path='/login' isAuth={isAuth} component={Login} />
						</>
					) : (
						<>
							<Route component={IndexPageWl} />
						</>
					)}
				</>
			)}

			
		</Switch>
	);
};

export default MainApp;
