import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import FeaturedProfileItem from '../../../components/smallcomponents/featuredprofileitem/FeaturedProfileItem'
import './miniFeaturedwl.css'
import Api from '../../../app/api'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { featuredSuccess, featuredFailed, featuredRequest } from '../../../components/pages/featuredprofiles/store/featuredSlice'
import FeaturedProfileLoader from '../../../components/smallcomponents/loaders/FeaturedProfileLoader'
import Scrollbars from 'react-custom-scrollbars-2'
import FeaturedProfileItemWl from './featuredprofileitemwl'


const MiniFeaturedProfiles = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { loading, featured, error, page, pagination } = useAppSelector(state => state.featured)
    const success = (data: any) => {
        dispatch(featuredSuccess({
            featured: data,
            no: 1,
            pagination: data.length < 5 ? false : true
        }))
    }
    const success1 = (res: any) => {

        dispatch(featuredSuccess({
            featured: res,
            no: page + 1,
            pagination: res.length < 20 ? false : true
        }))
    }
    const failed = (data: any) => {
        dispatch(featuredFailed(data))
    }
    useEffect(() => {
        if ((Object.keys(featured).length === 0) || loading) {
            dispatch(featuredRequest())
            Api('get', `/api/v1/user/featured/no-auth/1/20/`, null, success, failed, history, false, true)
        }
    }, [])

    const ScrollPos = (val: any) => {
        const { top } = val
        // console.log(top)
        // const { scrollHeight, scrollTop, clientHeight } = val.target;
        // let bottom = scrollHeight - scrollTop === clientHeight
        if (top > 0.99) {
            console.log('scroll please')
            if (pagination && !loading) {
                dispatch(featuredRequest())
                Api('get', `/api/v1/user/featured/no-auth/${page}/20/`, null, success1, failed, history, false, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }
    return (
        <div className='miniFeatured'>
            <div className='miniFeaturedHead'>
                <h2>Featured profiles</h2>
                <Link to='/login'>See all</Link>
            </div>
            <Scrollbars className='miniFeaturedListScroll' autoHide onUpdate={ScrollPos}>
                {
                    featured.map((item, key) => {
                        return (<FeaturedProfileItemWl featured={item} k={key} unf={false} place={'featured'} />)
                    })
                }
                {(loading === true || error !== null) &&
                    // <div className="featuredProfilesList">
                    [...Array(10)].map(() => {
                        return (
                            <FeaturedProfileLoader />
                        )
                    })
                    // </div>
                }
            </Scrollbars>
        </div>
    )
}

export default MiniFeaturedProfiles
