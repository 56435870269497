import React, { useState, useEffect, useRef } from 'react'
// import PlacesAutocomplete, {
//     geocodeByAddress,
//     getLatLng,
//   } from "react-places-autocomplete";
import './select.css'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import './discover.css'
import './datepicker.css'
import { ReactComponent as CalendarIcon } from '../../../../assets/svg/calendar.svg'
import { ReactComponent as Search } from '../../../../assets/svg/mainsearch.svg'
import { ReactComponent as ArrowDown } from '../../../../assets/svg/selectarrow.svg'
import { Scrollbars } from 'react-custom-scrollbars-2';
import Api from '../../../../app/api';
// import {
//     GoogleMap, useJsApiLoader,
//     LoadScript, Marker, InfoWindow, Autocomplete,
// } from '@react-google-maps/api';
import { categoriesSuccess, categoriesFailed, categoriesRequest } from '../../../smallcomponents/exploreby/store/exploreSlice';
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { discoverEventsRequest, discoverEventsSuccess, discoverEventsFailed } from '../../../pages/discoverpage/store/discoverSlice';
import dayjs from 'dayjs';
import Discoverlocation from './Discoverlocation';
// import { LocalCafeOutlined } from '@material-ui/icons';
// import { IoCloseOutline } from 'react-icons/io5';

// const libraries: any = ["places"]

const DiscoverSearch = ({passed}: any) => {

    const history = useHistory();
    const dispatch = useAppDispatch()
    // const [address, setAddress] = useState('')
    // const [coordinates, setCoordinates] = useState({
    //     lat: null,
    //     lng: null
    // });
    const { categories: cat } = useAppSelector(state => state.categories)
    const { page, lat, lng,loading } = useAppSelector(state => state.discover)
    const success = (data: any) => {
        dispatch(categoriesSuccess(data))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(categoriesFailed(data))
    }
    useEffect(() => {
        if (cat.length === 0) {
            dispatch(categoriesRequest())
            Api('get', '/api/v1/event/category/', null, success, failed, history, true, true)
        }

    }, [])
    // const d: any = new Date();
    // const [date, setDate] = useState<any>(passed? (passed.search_date !== ''? passed.searchdate: null):null)
    const [date, setDate] = useState<any>(passed && passed.search_date ? passed.search_date: null)
    const [dateselected, setDateselected] = useState(passed?((passed.search_date !== null || passed.search_date !== null) ? true: false):false)


    const [visible, setvisible] = useState(false)
    const [selectedstate, setselectedstate] = useState(passed?( passed.category? 'selected':'initial'): 'intial')
    const [selectedvalue, setvalue] = useState(passed ? passed.catvalue:'All categories')
    const [categoryid, setcategoryid] = useState<any>(passed? passed.category:null)
    const [keyword, setKeyword] = useState(passed? passed.keyword:'')
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    // let l: any = {
    //     lat: '',
    //     lng: ''
    // }

    // const [loc, setloc] = useState(l)

    // const [lt, setlt] = useState<any>(null)

    //     const [ln, setln] = useState<any>(null)

    // const change = (val: any) => {
    //     const { label } = val;
    //     console.log(val)
    //     geocodeByAddress(label)
    //         .then(results => getLatLng(results[0]))
    //         .then(({ lat, lng }) => {
    //             setloc({ lat, lng })
    //         });
    // }


    const success1 = (data: any) => {
        let pgn = data.length < 12 ? false : true
        dispatch(discoverEventsSuccess({
            data: data,
            no: page + 1,
            pagination: pgn
        }))
        console.log(data)
    }
    const failed1 = (data: any) => {
        console.log(data)
        dispatch(discoverEventsFailed(data))
    }
    const Discover = (type: any) => {


        let dt = date === null ? '' : dayjs(date).format("YYYY-MM-DD");
        let catid = categoryid === null ? '' : categoryid
        let D = {
            keyword: keyword,
            category: catid,
            latitude: lat,
            longitude: lng,
            search_date: dt,
        }
        if(!loading){
            if (type === null) {
                if (keyword !== '' || categoryid !== null || lat !== '' || lng !== '' || date !== null || date !== '') {
                    console.log(window.location.pathname)
                    if (window.location.pathname !== '/discover') {
                        // history.push('/discover');
                        let pass = {
                            keyword: keyword,
                            category: catid,
                            catvalue: selectedvalue,
                            latitude: lat,
                            longitude: lng,
                            search_date: date,
                            address: ''
                        }
                        history.push({
                            pathname: '/discover',
                            state: pass
                        });
                    }
                    dispatch(discoverEventsRequest({ data: D, no: 1 }))
                    let theurl = `/api/v1/search/browse/1/12/`
                    if (unparseddetails) {
                        if (currentparsed.is_business_profile) {
                            theurl = `/api/v1/search/browse/1/12/?auth_profile=${parseddetails[0].id}`
                        }
                    }
                    Api('post', theurl, D, success1, failed1, history, true, true)
                }
            } else {
                let D1 = {
                    keyword: keyword,
                    category: catid,
                    latitude: type.lat,
                    longitude: type.lng,
                    search_date: dt,
                }
                let pass = {
                    keyword: keyword,
                    category: catid,
                    catvalue: selectedvalue,
                    latitude: type.lat,
                    longitude: type.lng,
                    search_date: date,
                    address: type.address
                }
                if (window.location.pathname !== '/discover') {
                    // history.push('/discover');
                    history.push({
                        pathname: '/discover',
                        state: pass
                    });
                }
                dispatch(discoverEventsRequest({ data: D1, no: 1 }))
                let theurl = `/api/v1/search/browse/1/12/`
                if (unparseddetails) {
                    if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/search/browse/1/12/?auth_profile=${parseddetails[0].id}`
                    }
                }
                Api('post', theurl, D1, success1, failed1, history, true, true)
            }
        }
        

        // let dt = dateselected ? dayjs(date).format("YYYY-MM-DD") : '';



    }
    var ifConnected = window.navigator.onLine;

    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (visible && targt && !targt.contains(e.target)) {
                setvisible(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [visible])
    return (
        <div className='discoverSearchDiv' ref={vis}>
            <h2>Discover events around you</h2>
            <form className="select">
                <input type="text" placeholder="Keyword" value={keyword} onChange={txt => { setKeyword(txt.target.value) }} className="discoverInput keyword"></input>
                <div className='selectbox'
                    onClick={
                        e => {
                            if (!visible) {
                                setselectedstate('selecting')
                            } else {
                                setselectedstate('selected')
                            }
                            setvisible(!visible)
                        }
                    }>
                    <div className={(selectedvalue === 'All categories') ? 'selectbox selectboxinput selectboxinputclr' : 'selectbox selectboxinput'}>
                        <p>{selectedvalue}</p>
                    </div>
                    <div className={visible ? 'selectdropdown selectactive' : 'selectdropdown selectinactive'}>
                        <span className='selecttriangle'></span>
                        <div className='ul'>
                            <Scrollbars style={{ width: 220, height: 300 }} autoHide>
                                <li value='All categories' onClick={e => {
                                    const target: any = e.currentTarget
                                    setvalue(target.getAttribute('value'))
                                    setcategoryid(null);
                                }}
                                >All</li>
                                {

                                    cat.map((item: any, key: number) => {
                                        const { id, name } = item;
                                        return (
                                            <li id={id} value={name} key={key} onClick={
                                                e => {
                                                    const target: any = e.currentTarget
                                                    setvalue(target.getAttribute('value'));
                                                    setcategoryid(id);
                                                }}>{name}</li>
                                        )

                                    })
                                }
                            </Scrollbars>
                        </div>
                    </div>

                    <ArrowDown className={selectedstate === 'selecting' ? 'selectarrow arrowdown' : selectedstate === 'selected' ? 'selectarrow arrowleft' : 'selectarrow'} />
                </div>
                <div className='datepickerwrapper'>
                    <DatePicker selected={date} dateFormat="dd/MM/yyyy"
                        placeholderText='DD/MM/YYYY'
                        onChange={(date) => {
                            // alert(dayjs(date).format("DD-MM-YYYY"))
                            setDateselected(true)
                            setDate(date)
                        }}
                    />
                    <CalendarIcon />
                    {/* {
                            date !== null? <IoCloseOutline onClick={(e)=>{
                                e.preventDefault()
                                e.stopPropagation()
                                setDate(null)
                            }}/>:<CalendarIcon />
                        } */}

                </div>

                {ifConnected ? <Discoverlocation Discover={Discover} passedaddress={passed? passed.address: null}/> : null}

                <button className="mainBtn" onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    Discover(null);
                }}><Search />Search</button>
            </form>
        </div>
    )
}

export default DiscoverSearch
