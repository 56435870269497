import React from 'react';
import { IntersectionObserverProps } from 'react-intersection-observer';

const useIntersectionObserver = ({ target, onIntersect, threshold = 0.1, rootMargin = '0px' }:any) => {
	React.useEffect(() => {
		const observer = new IntersectionObserver(onIntersect, {
			rootMargin,
			threshold,
		});
		const current = target.current;
		observer.observe(current);
		return () => {
			observer.unobserve(current);
		};
	});
};
export default useIntersectionObserver;
