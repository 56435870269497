import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { noData } from '../../../Data/Data';
import { hosting } from '../../Tabs/events/dummydata';
import { useHistory } from 'react-router-dom';
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader';
import Api from '../../../app/api';
import { useDispatch } from 'react-redux';
// import { todaysEventsSuccess, todaysEventsFailed, todaysEventsRequest } from '../../Tabs/events/store/eventsSlice';
import { categoryEventsSuccess, categoryEventsFailed, categoryEventsRequest } from '../../pages/categoryevents/store/categorySlice';

// import { categoryEventsSuccess } from './store/todaysEventSlice';
import { useAppSelector } from '../../../app/hooks';
import EventTile from '../../smallcomponents/eventtile/EventTile';
import { errors } from '../../../Data/Error';
// import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';

const TodaysEvents = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { loading, pagination, page, categoryEvents, error } = useAppSelector(state => state.categoryEvents)
    const success = (data: any) => {
        let pgn = data.length < 12 ? false : true
        dispatch(categoryEventsSuccess({ data: data, no: page + 1, pagination: pgn }))
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(categoryEventsFailed(data))
    }
    const observer: any = useRef()
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    const lastEventRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading) {
                    // let pageno = page + 1
                    let theurl = `/api/v1/event/today/${page}/12/`
                    if (unparseddetails) {
                        if (currentparsed.is_business_profile) {
                            theurl = `/api/v1/event/today/${page}/12/?auth_profile=${parseddetails[0].id}`
                        }

                    }
                    Api('get', theurl, null, success, failed, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [categoryEvents, loading])

    useEffect(() => {
        dispatch(categoryEventsRequest(1))
        let theurl = `/api/v1/event/today/1/12/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/today/1/12/?auth_profile=${parseddetails[0].id}`
            }

        }
        Api('get', theurl, null, success, failed, history, true, true)
    }, [])


    function Refresh() {

    }
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^', categoryEvents)
    return (
        <div className='container'>
            <div className='commonHeading'>
                <small onClick={() => {
                    history.push('/')
                }}><Arrow />Back to home page</small>
                <h2>Today’s events</h2>
                <span>Results based on category</span>
            </div>
            <div className='eventGrid'>
                {

                    // !(Object.keys(categoryEvents).length === 0) ? (
                    //     categoryEvents.map((item: any, key) => {
                    //         return (<EventTile event={item} k={key} />)
                    //     })
                    // ) : (
                    //     !loading &&
                    //     <div className='noData'>
                    //         <Warning />
                    //         <h2>{noData.yours.heading}</h2>
                    //         <span>{noData.yours.message}</span>
                    //         <div className='loadmore'>
                    //             <button className='mainBtn' onClick={() => {
                    //                 history.push('/')
                    //             }}>Go back to homepage</button>
                    //         </div>
                    //     </div>
                    // )
                    (Object.keys(categoryEvents).length === 0) ? (
                        !loading &&
                        <div className='noData'>
                            <Warning />
                            <h2>{noData.hosting.heading}</h2>
                            <span>{noData.hosting.message}</span>
                            <div className='loadmore'>
                                <button className='mainBtn' onClick={() => history.push('')}>Go back to homepage</button>
                            </div>
                        </div>
                    ) : (
                        categoryEvents.map((event: any, key) => {
                            if (categoryEvents.length === key + 1) {
                                return (
                                    <div ref={lastEventRef}>
                                        <EventTile event={event} k={key} />
                                    </div>
                                )
                            } else {
                                return (<EventTile event={event} k={key} />)
                            }
                        })
                    )

                }
                {
                    loading && [...Array(6)].map(() => {
                        return (
                            <EventTileLoader />
                        )
                    })
                }
                {
                    error !== null && (
                        error.status === null ? (

                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                            </div>

                        ) : error.status === 500 ? (

                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Try again</button>
                            </div>

                        ) : error.status === 401 ? (

                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>

                        ) : (

                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                            </div>

                        )
                    )
                }
            </div>

        </div>
    )
}

export default TodaysEvents


