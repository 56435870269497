import React from 'react'
import Rating from 'react-rating';
import { ReactComponent as Share } from '../../../assets/svg/share.svg';
import { ReactComponent as Star1 } from '../../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../../assets/svg/star2.svg';
import { Events } from '../../Tabs/profile/store/interface';
import dayjs from 'dayjs';
import './profiletile.css'
import { useHistory } from 'react-router';

import {MdTimer} from 'react-icons/md'
import { StartToEnd } from '../../../Data/Data';

const ProfileTile = ({ event }: { event: Events, k: number }) => {
    const history = useHistory()
    const { id, name, description, latitude, longitude, event_image, profile_dp, start_date, participants, total_rating, profile_firstname, profile_surname, profile_id, slug,end_date } = event;
    function HandleClick(eventId: number | undefined) {
        history.push(`/event/${slug}`)
    }

    return (
        <div className='eventTileBorder' >
            <div className="eventTile" key={id}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); HandleClick(id) }}
            >
                <div className="eventTileImg" style={{ overflow: 'hidden' }} >
                    {event_image ?
                        event_image.slice(0, 1).map((img, key) => {
                            const { image, image_thumbnail } = img;
                            return (
                                <img src={image} className="eventTileImg" alt="Event cover photo" />
                            )
                        })
                        : null
                    }
                    {/* <img src={!(Object.keys(event_image).length===0)? event_image[0].image: null} className="eventTileImg1" alt="" style={{ minHeight: '100%', width: '100%' }} /> */}
                </div>
                <div className="eventDescpDiv">
                    <div className="eventUsrDiv">
                        <div className="eventUsrDp">
                            <img src={profile_dp} className="eventUsrDpImg" alt="P_I_C" style={{ width: '100%', height: '100%' }} />
                        </div>
                        <div className="eventUsrSub" style={{ cursor: 'pointer' }}
                            onClick={
                                e => {
                                    e.preventDefault(); e.stopPropagation();
                                    // history.push(`/user/${profile_id}`)

                                }
                            }
                        >{profile_firstname + ' ' + profile_surname}</div>
                        {/* <div className="eventUsrSub">&#8226;{' ' + dayjs(start_date).format('DD MMMM YYYY')}

                        </div> */}
                    </div>
                    <div className="eventDescTitle">
                        {name}
                    </div>
                    <div className='profileeventpreview'>
                        {/* <div className='participantpreview'>
                            {
                                participants ?
                                    // participants.length !== 0 ?
                                    participants.slice(0, 3).map((item, key) => {
                                        const { dp } = item;
                                        return (
                                            <div className="eventUsrDp">
                                                <img src={dp} className="eventUsrDpImg" alt="P_I_C" style={{ width: '100%', height: '100%' }} />
                                            </div>
                                        )

                                    })
                                    : null
                                // : null
                            }

                        </div> */}
                        <div className='ratingpreview'
                        >
                            <div className="eventUsrSub" style={{ display: "flex", alignItems: "center", gap: "5px",maxWidth:'100%' }}>
                            {/* {dayjs(start_date).format('hh:mm A') + " - " + dayjs(end_date).format('hh:mm A')} */}
                            <MdTimer style={{color:'#ff5917'}}/>{StartToEnd(start_date,end_date)}
                                {/* <span>
                                    {total_rating + ".0/5.0"}
                                </span>
                                <Rating
                                    initialRating={total_rating}
                                    emptySymbol={<Star2 />}
                                    fullSymbol={<Star1 />}
                                    readonly
                                /> */}
                            </div>
                        </div>


                    </div>
                    <div className="eventfooter" style={{ cursor: "pointer" }}
                    // onClick={(e) => { e.preventDefault(); e.stopPropagation(); modalOpen(); setuserDp(image) }}
                    >
                        {/* <div><FaRegComment/> Comments</div> */}
                        <div ><Share /></div>
                        {/* onClick = {Openmodal} */}
                    </div>
                </div>

                {/* {Modal && <ShareModal
                modalStatus={Modal}
                modalAction={setModal}
                //   ModalLink={ModalLink}
                //   setModalLink={setModalLink}
                userDp={userDp}
                id={theid}
                eventname={eventName}

            />} */}

            </div>
        </div>
    )
}

export default ProfileTile
