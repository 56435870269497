import React, { useState } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as Attending } from '../../../assets/svg/attending.svg';
import { ReactComponent as Posts } from '../../../assets/svg/posts.svg';
import { ReactComponent as Views } from '../../../assets/svg/views.svg';
import '../../pages/Event/event.css';
import './minieventtabs.css';

const MiniEventTabs = ({ active }: { active: string }) => {
    const { eventId }: any = useParams()
    const history = useHistory()
    const { loading, event, error } = useAppSelector(state => state.event)

    return (
        loading === false && error === null ? (
            <div className='miniEventTab tw-gap-2 md:tw-gap-0'>
                <NavLink className='eventTab' to={`/event/${eventId}/posts`} activeClassName="eventTabActive">
                    <div className='eventTabIcon'>
                        <Posts />
                    </div>
                    <h5>{event.post_count}</h5>
                    <span>Posts</span>
                </NavLink>
                <NavLink className='eventTab' to={`/event/${eventId}/views`} activeClassName="eventTabActive">
                    <div className='eventTabIcon'>
                        <Views />
                    </div>
                    <h5>{event.views_count}</h5>
                    <span>Views</span>
                </NavLink>
                <NavLink className='eventTab' to={`/event/${eventId}/attending`} activeClassName="eventTabActive">
                    <div className='eventTabIcon'>
                        <Attending />
                    </div>
                    <h5>{event.attending_count}</h5>
                    <span>Attending</span>
                </NavLink>
            </div>

            // <div className="singleEventPopularity">

            //     <div className={active === 'post' ? "singleEventPopTab singleEventPopTabActive" : "singleEventPopTab"}
            //         onClick={
            //             () =>
            //                 history.push(`/event/posts`)
            //         }
            //     >
            //         <div className={active === 'post' ? "singleEventFooterIcon singleEventFooterIconActive" : "singleEventFooterIcon"} >
            //             <Posts style={{ color: 'var(--cl_orange)' }} />
            //         </div>
            //         <div className={active === 'post' ? "featuredProfilesTitle featuredProfilesTitleActive" : "featuredProfilesTitle"}>
            //             {event.post_count}
            //         </div>
            //         <div className={active === 'post' ? "featuredProfileUsrname featuredProfileUsrnameActive" : "featuredProfileUsrname"}>Posts</div>
            //     </div>

            //     <div className={active === 'views' ? "singleEventPopTab singleEventPopTabActive" : "singleEventPopTab"}
            //         onClick={
            //             () =>
            //                 history.push(`/event/views`)
            //         }
            //     >
            //         <div className={active === 'views' ? "singleEventFooterIcon singleEventFooterIconActive" : "singleEventFooterIcon"} >
            //             <Views style={{ color: 'var(--cl_orange)' }} /></div>
            //         <div className={active === 'views' ? "featuredProfilesTitle featuredProfilesTitleActive" : "featuredProfilesTitle"}>
            //             {event.views_count}
            //         </div>
            //         <div className={active === 'views' ? "featuredProfileUsrname featuredProfileUsrnameActive" : "featuredProfileUsrname"}>Views</div>
            //     </div>

            //     <div className={active === 'attending' ? "singleEventPopTab singleEventPopTabActive" : "singleEventPopTab"}
            //         // onClick ={
            //         // ()=>
            //         //     handleAttending(theid,data.attending_count)
            //         // }
            //         onClick={
            //             () =>
            //                 history.push(`/event/attending`)
            //         }
            //     >
            //         <div className={active === 'attending' ? "singleEventFooterIcon singleEventFooterIconActive" : "singleEventFooterIcon"} >
            //             <Attending style={{ color: 'var(--cl_orange)' }} /></div>
            //         <div className={active === 'attending' ? "featuredProfilesTitle featuredProfilesTitleActive" : "featuredProfilesTitle"}>
            //             {event.attending_count}
            //         </div>
            //         <div className={active === 'attending' ? "featuredProfileUsrname featuredProfileUsrnameActive" : "featuredProfileUsrname"}>Attending</div>
            //     </div>


            // </div>
        ) : loading === true && error === null ? (
            <div className="singleEventPopularity">

                <div className="singleEventPopTab skeleton-loader"
                >
                </div>

                <div className="singleEventPopTab skeleton-loader">
                </div>

                <div className="singleEventPopTab skeleton-loader" >
                </div>


            </div>
        ) : null
    )
}

export default MiniEventTabs
