import React from 'react';

const ChatuserSkeleton = () => {
    return <div className='chat_userskeleton'>
        <div className='chat_user_dp skeleton'></div>
        <div className='chat_user_details' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
            <div className='chat_user_name'>
                <h4 className='minhe10 minwid150 maxwid60 skeleton'></h4>
            </div>
            <div className='chat_user_recent'>
                <span className='minhe10 maxwid80p minwid100 skeleton'></span>
                <div className='user_mute'></div>

            </div>
        </div>
    </div >;
};

export default ChatuserSkeleton;
