/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/svg/rightarrow.svg';
import { ReactComponent as Dat } from '../../assets/svg/eventdate.svg';
import { ReactComponent as CancelIcon } from '../../assets/svg/cancelevent11.svg';
import { ReactComponent as DashboardIcon } from '../../assets/svg/Dashboard.svg';
// import './event.css';
import { useHistory, useParams } from 'react-router-dom';
import Api from '../../app/api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { eventSuccess, eventFailed, eventRequest, eventReset } from '../../components/pages/Event/store/eventSlice';
import Rating from 'react-rating';
import { ReactComponent as Star1 } from '../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../assets/svg/star2.svg';
import { ReactComponent as Confirm } from '../../assets/svg/confirm_wl.svg';
import { ReactComponent as Share } from '../../assets/svg/share_wl.svg';
import { ReactComponent as AddImage } from '../../assets/svg/addImage_wl.svg';
import { ReactComponent as Subscribe } from '../../assets/svg/subscribe_wl.svg';

import dayjs from 'dayjs';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { noData } from '../../Data/Data';
import { ReactComponent as Warning } from '../../assets/svg/error/nodata.svg';
import './eventdetailwl.css';
import utc from 'dayjs/plugin/utc';
import EventFooterWl from './EventFooterWl';
import MiniEventTabsWl from './MiniEventTabsWl';
import EventTileWL from './eventTilewl';
import { ReactComponent as OrangeLike } from '../../assets/svg/orange_like.svg';
import BuyEventTicketModal from './buyTicketModalWl';
import EventShareModal from '../../components/smallcomponents/modals/EventShareModal';
import LoginPopup from '../components/loginwl_popup';
import RsvpWl from './RsvpWl';
import { AiOutlineQrcode } from 'react-icons/ai';
import { CircularProgress } from '@material-ui/core';
import QRcodeModal from '../../components/pages/Event/QRcodeModal';
import { MdRsvp } from 'react-icons/md';
import { IoTicketSharp } from 'react-icons/io5';

dayjs.extend(utc);

const EventDetailWl = () => {
	const history = useHistory();
	const { eventId }: any = useParams();
	const dispatch = useAppDispatch();

	const { event, error, loading } = useAppSelector((state) => state.event);

	const {
		event_image,
		similar_events,
		name,
		description,
		total_rating,
		start_date,
		end_date,
		assigned_role,
		attending,
		attending_count,

		start_timezone,
		end_timezone,
		tickets,
		permissions,
		profile,
		posts,
		post,
		like_details,
		review_rating,
		custom_form,
		id,
		qr_code_url,
	} = event;
	const [allPost, setallPost] = useState<any>([]);

	useEffect(() => {
		window.scrollTo(0, 0);

		const success = (data: any) => {
			dispatch(eventSuccess(data));
			console.log(data);
		};
		const failed = (data: any) => {
			console.log(data);
			dispatch(eventFailed(data));
		};
		// if((Object.keys(event).length === 0)){
		dispatch(eventRequest());

		let theurl = `/api/v1/event/no-auth/${eventId}/`;

		Api('get', theurl, null, success, failed, history, false, true);

		return () => {
			dispatch(eventReset());
		};

		// ViewPostFx(eventId);
		// }
	}, [dispatch, eventId, history]);
	// var ifConnected = window.navigator.onLine;

	const refalert = useRef(null);

	const [open, setOpen] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const handleClose = () => setOpen(false);
	const [open3, setOpen3] = useState(false);

	const handleModalOpen = () => {
		setOpen3(true);

		console.log('CLICKED');
	};

	const [openRSVP, setOpenRSVP] = useState(false);

	const handleCloseRSVP = () => setOpenRSVP(false);

	// const [modalActive, setModalActive] = useState(false);

	const [qrModal, setQrModal] = useState(false);

	return (
		<>
			<div style={{ height: '100%', width: '100%' }} className='wl_event_wrapper'>
				<div style={{ width: '100%' }} className='event container'>
					<div className='eventImage'>
						{event_image?.slice(0, 1).map((item: any, key: number) => {
							const { image } = item;
							return (
								<img
									alt='Event cover_image'
									src={image}
									onClick={() => {
										// setcurrent(image);
										// setOpenSingle(true);
									}}></img>
							);
						})}
					</div>
					<div className='eventContent'>
						{modalActive && <EventShareModal data={event} open={modalActive} setOpen={setModalActive} type={'event'} />}

						<div className='eventcol1Wl'>
							<h2>{name}</h2>
							<div className='eventUserDiv'>
								<img alt='P_I_C' src={profile?.dp}></img>
								<span className='eventprofilename cursor' onClick={() => {}}>
									<div>by {profile?.firstname + '' + profile?.surname}</div>

									{
										// review_rating &&
										<div className='ratingpreview'>
											<div
												// className='eventUsrSub'
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '5px',
													maxWidth: '150px',
													textAlign: 'left',
												}}>
												{/* <span>{total_rating + '.0/5.0'}</span>   */}
												{
													<Rating
														initialRating={total_rating}
														emptySymbol={<Star2 />}
														fullSymbol={<Star1 />}
														readonly
													/>
												}
											</div>
											<small
												ref={refalert}
												style={{ display: 'none', color: 'var(--cl_orange' }}
												className='signupvalidate'>
												You have already reviewed this event or it has been blocked!
											</small>
										</div>
										// )
									}
								</span>

								<div className='wl_small_screen' style={{ justifyContent: 'flex-end', flexGrow: 1 }}>
									{tickets && tickets.length !== 0 ? (
										<div
											onClick={() => {
												setOpen(true);
											}}
											className='mainBtn'>
											Buy Ticket
										</div>
									) : null}
								</div>
							</div>

							<div className='wl_small_screen'>
								{
									//! needed
								}
								{/* <EventFooterWl
                                id={3}
                                permissions={''}
                                subscribers_count={''}
                                sharemodal={setModalActive}
                            /> */}

								<div className='eventDate'>
									<Dat />
									<div className='eventDateDetailed'>
										<h5>
											{dayjs.utc(start_date).local().format('DD') === dayjs.utc(end_date).local().format('DD')
												? dayjs.utc(start_date).local().format('ddd, DD MMM YYYY')
												: dayjs.utc(start_date).local().format('ddd, DD MMM') +
												  ' - ' +
												  dayjs.utc(end_date).local().format('ddd, DD MMM YYYY')}
										</h5>
										<span>
											{dayjs.utc(start_date).local().format('hh:mm A') +
												' - ' +
												dayjs.utc(end_date).local().format('hh:mm A')}
										</span>
										{start_timezone && end_timezone && (
											<span>{start_timezone === end_timezone ? start_timezone : start_timezone - end_timezone}</span>
										)}
									</div>
								</div>
								<MiniEventTabsWl active='' handleModalOpen={handleModalOpen} />
							</div>

							<div className='eventDescriptionWl'>
								<div style={{ display: 'flex', gap: '5%', marginBottom: '10px' }}>
									{/* <div style={{ display: "flex", alignItems: "center", gap: '5px' }}>
                                    <Confirm />
                                    <span style={{ fontWeight: "400", fontSize: "12" }}>Confirm</span>
                                </div>  */}
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Subscribe />
										<span>Subscribe</span>
									</div>
									{/* <div style={{ display: "flex", alignItems: "center", gap: '10px' }}>
                                    <AddImage />
                                    <span>Confirm</span>
                                </div>  */}

									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<Share
											onClick={() => {
												setModalActive(true);
											}}
										/>
										<span>Share</span>
									</div>
									<div>
													{(permissions?.rsvp && custom_form )&& (
								<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} onClick={()=> setOpenRSVP(true)}>
			

<div className='  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2' style={{  backgroundColor:"var(--cl_light_orange)"}}>
										<MdRsvp size={22} color='#FF5917'/>
									</div>

										<span> RSVP </span>

								</div>
							)}
									</div>
								</div>
								{description === '' ? null : <h2 style={{ marginBottom: '7px' }}>About</h2>}
								<p>{description}</p>
							</div>

							{/* <=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=> */}

							<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
								<div className='commonHeading tw-whitespace-nowrap '>
									<h2>
										Gallery
										{/* <Arrow /> */}
									</h2>
								</div>
								{/* <div style={{ border: "1px solid green", display: "flex", gap: "3px", flexWrap: "wrap", alignItems: "flex-start" ,padding:"10px 0px" ,justifyContent:"flex-start" }}> */}
								<div className='test_grid_layout' style={{ display: 'grid', gridGap: '3px' }}>
									{Array.isArray(post) && post.length > 0 ? (
										post.slice(0, 9).map((item: any, key) => {
											return (
												<div
													onClick={() => {
														// setgalleyIndex(key);
														// setgalleryView(true)
														handleModalOpen();
													}}
													style={{
														borderRadius: '10px',
														display: 'grid',
														// maxWidth: '150px',
														maxHeight: '180px',
														width: '100%',
														height: '100%',
														overflow: 'hidden',
														position: 'relative',
													}}>
													<img
														src={item?.post_thumbnail_1}
														alt=''
														style={{
															objectFit: 'cover',
															width: '100%',
															height: '100%',
															borderRadius: '10px',
														}}
													/>
													<div
														style={{
															position: 'absolute',
															zIndex: 1,
															display: 'flex',
															gap: '4px',
															padding: '8px',
															// justifyContent: 'space-around',
															bottom: '0px',
															width: '100%',
														}}>
														<div
															style={{
																display: 'flex',
																width: '30px',
																height: '30px',
																overflow: 'hidden',
																borderRadius: '50%',
																boxShadow:
																	'box-shadow: rgba(255, 255, 255, 0.945) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;',
															}}>
															<img
																style={{
																	objectFit: 'cover',
																	width: '100%',
																	height: '100%',
																}}
																src={item?.profile_dp}
																alt=''
															/>
														</div>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<p
																style={{
																	color: 'white',
																	textShadow: '1px 2px 3px #666',
																	fontSize: '12px',
																	fontWeight: 500,
																}}>
																{item?.profile_firstname + ' ' + item.profile_surname}
															</p>
														</div>
													</div>
												</div>
											);
										})
									) : (
										<div className='noData' style={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
											<Warning />
											<h2>{noData.similar.heading}</h2>
											<span>{'Event photos are shown here'}</span>
										</div>
									)}
								</div>
							</div>

							{/* <=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=> */}
						</div>

						{/* <div className='eventcol2'>
                        {tickets && tickets.length !== 0 ? (
                            <div className='buyticket_event_1'>

                                <div
                                    className='mainBtn'
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => {
                                        // history.push(`/event/${eventId}/tickets`)
                                        setOpen(true);
                                    }}>
                                    Buy tickets
                                </div>
                            </div>
                        ) : null}
                    </div> */}

						<div className='eventcol3'>
							{Number(like_details?.like_count) > 0 ? (
								<div
									style={{
										height: '48px',
										width: '100%',
										backgroundColor: 'var(--cl_light_orange)',
										borderRadius: '12px',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}>
									<OrangeLike />
									<span style={{ color: 'var(--cl_orange)', marginLeft: '10px' }}>
										{`${like_details?.like_count}`} {like_details?.like_count === 1 ? 'like' : 'likes'}
									</span>
								</div>
							) : null}
							<MiniEventTabsWl active='' handleModalOpen={handleModalOpen} />
							{permissions?.buy_ticket && (
													
													<div className='eventCancel' onClick={()=>setOpen(true)}>

									{tickets && tickets.length !== 0 ? (
										<>
										
											<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
										<IoTicketSharp size={22} className='tw-text-orange-500' />
									</div>

									<div className='eventOptions'>
										<h5>Get your tickets</h5>
										<span>Collect yout tickets.</span>
									</div>
										</>
									) : null}
								</div>
							)}

							<div className='eventDate'>
								<Dat />
								<div className='eventDateDetailed'>
									<h5>
										{dayjs.utc(start_date).local().format('DD') === dayjs.utc(end_date).local().format('DD')
											? dayjs.utc(start_date).local().format('ddd, DD MMM YYYY')
											: dayjs.utc(start_date).local().format('ddd, DD MMM') +
											  ' - ' +
											  dayjs.utc(end_date).local().format('ddd, DD MMM YYYY')}
									</h5>
									<span>
										{dayjs.utc(start_date).local().format('hh:mm A') +
											' - ' +
											dayjs.utc(end_date).local().format('hh:mm A')}
									</span>
									{start_timezone && end_timezone && (
										<span>{start_timezone === end_timezone ? start_timezone : start_timezone - end_timezone}</span>
									)}
								</div>
							</div>

							{(permissions?.rsvp && custom_form )&& (
								<div className='eventCancel' onClick={()=> setOpenRSVP(true)}>
			

<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
										<MdRsvp size={22} className='tw-text-orange-500' />
									</div>

									<div className='eventOptions'>
										<h5>RSVP</h5>
										<span>Update your RSVP </span>
									</div>

								</div>
							)}

							{qr_code_url && (
								<div
									className='eventCancel'
									onClick={() => {
										if (qr_code_url) {
											setQrModal(true);
										}
									}}>
									<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
										<AiOutlineQrcode size={22} className='tw-text-orange-500' />
									</div>
									<div className='eventOptions'>
										<h5>Scan QR Code</h5>
										<span>Share this event via QR</span>
									</div>

									<Arrow />
								</div>
							)}

							<div className='eventAssigned'>
								<h3>Assigned Role</h3>
								<div className='eventAssignedUsers'>
									{assigned_role && !(Object.keys(assigned_role).length === 0) ? (
										<Scrollbars style={{ height: 136 }}>
											{assigned_role.map((item: any, key: number) => {
												const { firstname, dp, id, role, surname, username, status } = item;
												console.log('Assigned Role :', item);
												return (
													<div className='featuredprofile' id={`${key}`} onClick={() => {}}>
														<img alt='P_I_C' src={dp}></img>
														<div className='featuredNames'>
															<h3>
																{role}{' '}
																<span
																	style={{
																		paddingLeft: '3px',
																		color: 'var(--cl_orange)',
																		fontStyle: 'italic',
																	}}>
																	{status === 'pending' && '(Pending)'}
																</span>
															</h3>
															<span>{'@' + username}</span>
														</div>
													</div>
												);
											})}
										</Scrollbars>
									) : (
										<div className='notAssigned'>
											<span>No roles have been assigned yet</span>
										</div>
									)}
								</div>
							</div>
							<div className='eventParticipants'>
								<h3>
									Participants
									<a
										onClick={() => {
											history.push(`/login`);
										}}>
										See all
									</a>
								</h3>
								<div className='eventParticipantsDp'>
									{attending && !(Object.keys(attending).length === 0) ? (
										attending.slice(0, 5).map((item: any, key: number) => {
											const { id, dp, firstname, surname, username } = item;
											return (
												<img
													alt='P_I_C'
													src={dp}
													title={firstname + ' ' + surname}
													className='cursor'
													onClick={() => {}}
												/>
											);
										})
									) : (
										<span className='no'>There are no participants</span>
									)}
									{attending_count && attending_count > 5 ? (
										<div className='eventParticipantsCount'>
											<span>{attending_count > 5 ? '+' + (attending_count - 5) : null}</span>
										</div>
									) : null}
								</div>
							</div>

							{/* <div
                            className='eventDashboard'
                            onClick={() => {
                                history.push(`/event/${eventId}/dashboard`);
                            }}>
                            <DashboardIcon />
                            <div className='eventOptions'>
                                <h5>Dashboard</h5>
                                <span>Manage your event</span>
                            </div>
                            <Arrow />
                        </div>

                        <div
                            className='eventCancel'
                            onClick={() => {
                                history.push(`/event/${eventId}/cancel`);
                            }}>
                            <CancelIcon />
                            <div className='eventOptions'>
                                <h5>Cancel event</h5>
                                <span>Let user know why</span>
                            </div>
                            <Arrow />
                        </div> */}
						</div>
					</div>

					<div className='similarEvents'>
						<div className='commonHeading'>
							<h2>
								Similar events
								<Arrow />
							</h2>
						</div>
						<div className='eventGrid'>
							{similar_events
								? Object.keys(similar_events).length === 0
									? !loading && (
											<div className='noData'>
												<Warning />
												<h2>{noData.similar.heading}</h2>
												<span>{noData.similar.message}</span>
											</div>
									  )
									: similar_events.map((item: any, key: number) => {
											return <EventTileWL event={item} k={key} />;
									  })
								: null}
						</div>
					</div>
				</div>
				{open3 && <LoginPopup open3={open3} setOpen3={setOpen3} war={null} />}
			</div>

			<RsvpWl open={openRSVP} handleClose={handleCloseRSVP} custom_form={custom_form} id={id} />
			<QRcodeModal url={qr_code_url} currentURL={''} title={name} text={description} open={qrModal} handleClose={() => setQrModal(false)} />
			{open && <BuyEventTicketModal open={open} handleClose={handleClose} event={event} />}

		</>
	);
};

export default EventDetailWl;
