import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import MiniEventDetail from '../../smallcomponents/minieventdetail/MiniEventDetail'
// import FeaturedProfileItem from '../../smallcomponents/featuredprofileitem/FeaturedProfileItem'
import FeaturedProfileItem2 from '../../smallcomponents/featuredprofileitem/featuredprofileitem2'
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader'
import { useHistory, useParams } from 'react-router-dom'
import Api from '../../../app/api'
import { eventAttendingSuccess, eventAttendingFailed, eventAttendingRequest,
     eventAttendingsearchFailed, eventAttendingsearchSuccess, eventAttendingsearchrequest } from './store/eventAttendingSlice'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { ReactComponent as Search } from '../../../assets/svg/mainsearch.svg';
import { IoSearch } from 'react-icons/io5'
import axios from 'axios'
import { baseurl } from '../../../Data/Data'

const EventAttending = () => {
    const history = useHistory()
    const { eventId }: any = useParams()

    const dispatch = useAppDispatch()
    const { eventAttending, error, loading, page, pagination,
        searchpagination, searchpage, searchdata, searchkeyword, searchloading, searcherror
    } = useAppSelector(state => state.eventAttending)
    const success = (res: any) => {
        console.log(res, 'res')

        dispatch(eventAttendingSuccess({
            eventAttending: res,
            no: 1,
            pagination: res.length < 15 ? false : true
        }))
    }
    const success1 = (res: any) => {
        console.log(res, 'res')

        dispatch(eventAttendingSuccess({
            eventAttending: res,
            no: page + 1,
            pagination: res.length < 15 ? false : true
        }))
    }
    const failed = (res: any) => {
        console.log(res, 'res')
        dispatch(eventAttendingFailed(res))
    }

    useEffect(() => {
        // if ((Object.keys(views).length === 0) || loading) {
        dispatch(eventAttendingRequest())
        Api('get', `/api/v2/event/attending/${eventId}/1/15/`, null, success, failed, history, true, true)
        window.scrollTo(0, 0)
        // }
    }, [])

    const observer: any = useRef()

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading) {
                    // let pageno = page + 1
                    dispatch(eventAttendingRequest())
                    Api('get', `/api/v2/event/attending/${eventId}/${page}/15/`, null, success1, failed, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [eventAttending, loading])











    const [value, setvalue] = useState<any>('')

    const success2 = (res: any) => {
        console.log(res, 'res')

        dispatch(eventAttendingsearchSuccess({
            views: res,
            no: searchpage + 1,
            pagination: res.length < 15 ? false : true
        }))
    }

    const failed2 = (res: any) => {
        console.log(res, 'res')
        dispatch(eventAttendingsearchFailed(res))
    }



    useEffect(() => {
        if (value === '') {

        } else {
            dispatch(eventAttendingsearchrequest({
                value: value,
                newornot: true,
            }))
            const request = axios.CancelToken.source()
            let data;
            console.log("searching user")

            const token = localStorage.getItem(`access_token`);
            var config: any = {
                method: 'get',
                url: baseurl + `/api/v2/event/attending/${eventId}/1/15/?keyword=${value}`,
                // url: baseurl +`/api/v1/event/views/${eventId}/1/15/`,
                cancelToken: request.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            };


            axios(config)
                .then((res) => {
                    success2(res.data)
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        if (error.response.status === 401) {
                            console.log('401')
                            // AuthErrorValidation(Recall)
                        } else {
                            // console.log('not 401')
                            let statusCode = error.response.status;
                            let statusText = error.response.statusText;
                            let error_dev_data = error.response.data.dev_data;
                            let error_app_data = error.response.data.app_data;
                            let res = {
                                status: statusCode,
                                text: statusText,
                                dev_data: error_dev_data,
                                app_data: error_app_data
                            }
                            failed2(res)
                            // return res
                        }
                    } else {
                        // console.log(error.message)
                        // console.log('Something went Wrong');
                        if (error.message) {
                            failed2({
                                status: null,
                                text: error.message,
                                dev_data: null,
                                app_data: null
                            })
                        } else {
                            failed2({
                                status: null,
                                text: 'Network error',
                                dev_data: null,
                                app_data: null
                            })
                        }
                        // return {
                        //     status: null,
                        //     text: 'Network error',
                        //     dev_data: null,
                        //     app_data: null
                        // }
                    }
                });
            return () => request.cancel()
        }
    }, [value]);





    const observer1: any = useRef()

    const lastEventRef = useCallback(node => {
        if (observer1.current) observer1.current.disconnect()
        observer1.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && searchpagination) {
                if (!searchloading) {
                    // let pageno = page + 1
                    dispatch(eventAttendingsearchrequest({
                        value: value,
                        newornot: false,
                    }))
                    Api('get', `/api/v2/event/attending/${eventId}/${searchpage}/15/?keyword=${value}`, null, success2, failed2, history, true, true)
                }
            }
        })
        if (node) observer1.current.observe(node)
    }, [searchdata, searchloading])











    return (
        <div className='eventAttending container minvh100'>
            <MiniEventDetail mode={'attending'} />
            <div className='eventspeoplesearch'>
                <h4>Search people</h4>
                <div className='eventssearchpeopleinput'>
                    <input type='text' placeholder='eg. john doe' value={value} onChange={(e) => {
                        setvalue(e.currentTarget.value);
                    }}></input>
                    <IoSearch />
                </div>
            </div>
            {value === '' ? (<div className="featuredProfilesList">
                {(Object.keys(eventAttending).length === 0) ? (
                    (loading === false && error === null) &&
                    <div className='noData'>
                        <Warning />
                        {/* <h2>{'People who saw your posts are shown here'}</h2> */}
                        <span>People who are attending your event are shown here</span>
                        <div className='loadmore'>
                            <button className='mainBtn' onClick={() => history.push(`/event/${eventId}`)}>Go back to eventpage</button>
                        </div>
                    </div>) : (
                    eventAttending.map((item, key) => {
                        if (eventAttending.length === key + 1) {
                            return (
                                <div ref={lastBookElementRef}>
                                    <FeaturedProfileItem2 featured={item?.profile_details} k={key} unf={false} place={'none'} />
                                </div>
                            )
                        } else {
                            return (<FeaturedProfileItem2 featured={item?.profile_details} k={key} unf={false} place={'none'} />)
                        }
                    }))

                }
                {loading &&
                    [...Array(6)].map(() => {
                        return (
                            <FeaturedProfileLoader />
                        )
                    })
                }
            </div>) : (
                <div className="featuredProfilesList">

                    {
                        (Object.keys(searchdata).length === 0) ? (
                            (searchloading === false && searcherror === null) &&
                            <div className='noData'>
                                <Warning />
                                {/* <h2>{'People who saw your posts are shown here'}</h2> */}
                                <span>People who are attending your event are shown here</span>
                                <div className='loadmore'>
                                    <button className='mainBtn' onClick={() => history.push(`/event/${eventId}`)}>Go back to eventpage</button>
                                </div>
                            </div>) : (

                            searchdata.map((item, key) => {
                                if (searchdata.length === key + 1) {
                                    return (
                                        <div ref={lastEventRef}>
                                            <FeaturedProfileItem2 featured={item} k={key} unf={true} place={'none'} />
                                        </div>
                                    )
                                } else {
                                    return (<FeaturedProfileItem2 featured={item} k={key} unf={true} place={'none'} />)
                                }
                            })

                        )
                    }
                    {searchloading &&

                        [...Array(6)].map(() => {
                            return (
                                <FeaturedProfileLoader />
                            )
                        })

                    }
                </div>
            )}
        </div>
    )
}

export default EventAttending
