import {   createSlice,   } from '@reduxjs/toolkit';
import { TopSearchState } from './interface';

const initialState: TopSearchState = {
  loading1: false,
  topSearchEvents: [],
  page1: 1,
  pagination1: true,
  error1: null,

  loading2: false,
  topSearchPeople: [],
  page2: 1,
  pagination2: true,
  error2: null,

  loading3: false,
  topSearchBusiness: [],
  page3: 1,
  pagination3: true,
  error3: null,

  keyword: null,
  lat: '',
  lng: '',


};
export const TopSearchSlice = createSlice({
  name: 'topSearch',
  initialState,
  reducers: {
    topSearchEventsRequest: (state, action: any) => {
      // if(state.keyword !== action.payload.value){
      //     state.page1 =1;
      //     state.topSearchEvents = []
      // }
      if (action.payload.newornot) {
        state.page1 = 1;
        state.topSearchEvents = []
      }
      state.loading1 = true;
      state.pagination1 = true;

    },
    topSearchEventsSuccess: (state, action: any) => {
      state.loading1 = false;
      state.topSearchEvents = state.page1 === 1 ? action.payload.data : state.topSearchEvents.concat(action.payload.data);
      state.page1 = action.payload.no;
      state.pagination1 = action.payload.pagination;
      state.error1 = null;
      state.keyword = action.payload.value;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    },
    topSearchEventsFailed: (state, action: any) => {
      if(action.payload.status === null){
        // state.error1 = action.payload
        // state.pagination1 = false
      }else{
        state.loading1 = false;
        state.error1 = action.payload
        state.pagination1 = false
      }

    },
    topSearchEventsClear:(state)=>{
      state.loading1 = false;
      state.topSearchEvents = [];
      state.page1= 1;
      state.error1= null;
      state.pagination1 = true;
      state.keyword = null;
      state.lat = '';
      state.lng = '';
    },
    topSearchPeopleRequest: (state, action: any) => {
      // if(state.keyword !== action.payload.value){
      //     state.page2 =1;
      //     state.topSearchPeople = []
      // }
      if (action.payload.newornot) {
        state.page2 = 1;
        state.topSearchPeople = []
      }
      state.loading2 = true
      state.keyword = action.payload.value;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
      state.pagination2 = true;
    },
    topSearchPeopleSuccess: (state, action: any) => {
      state.loading2 = false;
      state.topSearchPeople = state.page2 === 1 ? action.payload.data : state.topSearchPeople.concat(action.payload.data);
      state.page2 = action.payload.no;
      state.pagination2 = action.payload.pagination;
      state.error2 = null;
      state.keyword = action.payload.value;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    },
    topSearchPeopleFailed: (state, action: any) => {
      if(action.payload.status === null){
        // state.error1 = action.payload
        // state.pagination1 = false
      }else{
        state.loading2 = false;
        state.error2 = action.payload
        state.pagination2 = false
      }

    },
    topSearchPeopleClear:(state)=>{
      state.loading2 = false;
      state.topSearchPeople = [];
      state.page2= 1;
      state.error2= null;
      state.pagination2 = true;
      state.keyword = null;
      state.lat = '';
      state.lng = '';
    },
    topSearchBusinessRequest: (state, action: any) => {
      // if(state.keyword !== action.payload.value){
      //     state.page2 =1;
      //     state.topSearchBusiness = []
      // }
      if (action.payload.newornot) {
        state.page3 = 1;
        state.topSearchBusiness = []
      }
      state.loading3 = true
      state.keyword = action.payload.value;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
      state.pagination3 = true;
    },
    topSearchBusinessSuccess: (state, action: any) => {
      state.loading3 = false;
      state.topSearchBusiness = state.page3 === 1 ? action.payload.data : state.topSearchBusiness.concat(action.payload.data);
      state.page3 = action.payload.no;
      state.pagination3 = action.payload.pagination;
      state.error3 = null;
      state.keyword = action.payload.value;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    },
    topSearchBusinessFailed: (state, action: any) => {
      if(action.payload.status === null){
        // state.error1 = action.payload
        // state.pagination1 = false
      }else{
        state.loading3 = false;
        state.error3 = action.payload
        state.pagination3 = false
      }

    },
    topSearchBusinessClear:(state)=>{
      state.loading3 = false;
      state.topSearchBusiness = [];
      state.page3= 1;
      state.error3= null;
      state.pagination3 = true;
      state.keyword = null;
      state.lat = '';
      state.lng = '';
    },

  }
})
export const { topSearchEventsRequest, topSearchEventsSuccess, topSearchEventsFailed,
  topSearchPeopleRequest, topSearchPeopleSuccess, topSearchPeopleFailed,
   topSearchBusinessRequest, topSearchBusinessSuccess, topSearchBusinessFailed,
   topSearchEventsClear,topSearchBusinessClear,topSearchPeopleClear } = TopSearchSlice.actions
export default TopSearchSlice.reducer