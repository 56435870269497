import React, { useState, useEffect } from 'react'
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import './switchprofilemodal.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Api from '../../../app/api';
import { useHistory } from 'react-router';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { baseurl } from '../../../Data/Data';
import { businessprofilessuccess, businessprofilesrequest } from '../../pages/newbusinessaccount/store/BusinessprofileSlice';
import axios from 'axios';


const useStyles = makeStyles((theme) => ({
    modal: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: 'rgb(0,0,0,0.7)'
    },
    appBar: {
        position: "relative",
        backgroundColor: "white",
    },
    buttonClose: {
        height: "4rem",
        width: "4rem",
        position: "absolute",
        // backgroundColor: 'white',
        color: "red",
        right: "10px",
        zIndex: 100,
    },
    title: {
        marginLeft: theme.spacing(2),
        color: "black",
        flex: 1,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2, 4, 3),
    },
    imgContainer: {
        position: 'relative',
        flex: 1,
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },

}));

const SwitchProfileModal = ({
    dialogStatus,
    dialogAction,
}: {
    dialogStatus: any,
    dialogAction: any
}) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const classes = useStyles();
    const { businessprofiles, loading: businessloading, error: businesserror } = useAppSelector(state => state.business)
    let crnt: any = localStorage.getItem('mtusr')
    let profilestype: any = localStorage.getItem(`mtusrtype`)
    let det: any = localStorage.getItem('pdetails')
    console.log(det)
    let details: any = det ? JSON.parse(det) : []
    const [storedprof, setstoredprof] = useState<any>(details)



    // let users = [];

    // for (let index = 0; index < 4; index++) {
    //     if (localStorage.getItem(`mtusr${index}`)) {
    //         let data: any = localStorage.getItem(`mtusr${index}`)
    //         let user: any = JSON.parse(data)
    //         users.push(user)
    //     }
    // }
    // setstoredprof(users)

    const handleClose = () => {
        dialogAction(false);
    };
    let usertoken = null;
    const switchprofilessuccess = (res: any) => {
        console.log(res)
        // if (profilestype === 'business') {
        //     let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
        //     let array = [JSON.parse(bufferuser0)].concat(res)
        //     // setstoredbprof(array)
        //     dispatch(businessprofilessuccess(array))
        // } else {
        // setstoredbprof(res)
        //dispatch(businessprofilessuccess(res))
        // }

        let bprofile = res.filter((profiles: any) => {
            let current: any = localStorage.getItem('mtusr0')
            let currentpro: any = JSON.parse(current)
            return currentpro.id === profiles.id
        })
        let otherprofile = res.filter((profiles: any) => {
            let current: any = localStorage.getItem('mtusr0')
            let currentpro: any = JSON.parse(current)
            return currentpro.id !== profiles.id
        })
        dispatch(businessprofilessuccess(bprofile.concat(otherprofile)))
        // console.log(bprofile)

    }
    const fetchbusinessprofiles = (usertoken: any) => {
        let token = usertoken !== null ? usertoken : localStorage.getitem('accesstoken0')

        if (businessprofiles.length === 0) {
            dispatch(businessprofilesrequest())
            var config: any = {
                method: 'get',
                url: baseurl + '/api/v1/user/switch-profile/',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            };
            axios(config)
                .then((res: any) => {
                    console.log(res.data)
                    switchprofilessuccess(res.data)
                })
                .catch((error: any) => {
                    // const errorMsg = error.message
                    console.log(error)
                });
            // Api('get', '/api/v1/user/switch-profile/', null, switchprofilessuccess, switchprofilesfailed, history, true, true)
        }
    }
    useEffect(() => {
        // let users = [];
        // for (let index = 0; index < 4; index++) {
        //     if (localStorage.getItem(`mtusr${index}`)) {
        //         let data: any = localStorage.getItem(`mtusr${index}`)
        //         let user: any = JSON.parse(data)
        //         users.push(user)
        //     }
        //     if (localStorage.getItem(`mtusr${index}type`) === 'personal') {
        //         usertoken = localStorage.getItem(`access_token${index}`)
        //     }
        // }
        // setstoredprof(users)


        // fetchbusinessprofiles()
    }, [])

    const ChangeProfile = (k: number, proftype: string) => {
        let dt: any = localStorage.getItem('pdetails')
        let tdt: any = JSON.parse(dt)


        console.log(tdt)
        if(dt){

            let changingprofile = tdt[k]
            console.log(changingprofile)
            
            tdt.splice(k, 1)
            console.log(tdt)
            tdt = [changingprofile].concat(tdt)
            console.log(tdt)

            localStorage.setItem(`access_token`, changingprofile.access);
            localStorage.setItem(`refresh_token`, changingprofile.refresh);
            localStorage.setItem(`mtusr`, JSON.stringify(changingprofile))
            localStorage.setItem(`mtusrtype`, 'personal')
            // handleClose()
            localStorage.setItem('pdetails',JSON.stringify(tdt))

            // window.location.reload()
            window.location.href = '/'
        }

        console.log(k, proftype)
        // if (k !== 0) {
        //     let bufferuser: any = localStorage.getItem(`mtusr${k}`)
        //     let bufferrefresh: any = localStorage.getItem(`refresh_token${k}`);
        //     let bufferaccess: any = localStorage.getItem(`access_token${k}`);
        //     let buffertype: any = localStorage.getItem(`mtusr${k}type`);

        //     let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
        //     let bufferrefresh0: any = localStorage.getItem(`refresh_token${0}`);
        //     let bufferaccess0: any = localStorage.getItem(`access_token`);
        //     let buffertype0: any = localStorage.getItem(`mtusr${0}type`);


        //     localStorage.setItem(`access_token`, bufferaccess);
        //     localStorage.setItem(`refresh_token${0}`, bufferrefresh);
        //     localStorage.setItem(`mtusr${0}`, bufferuser)
        //     localStorage.setItem(`mtusr${0}type`, buffertype)

        //     localStorage.setItem(`access_token${k}`, bufferaccess0);
        //     localStorage.setItem(`refresh_token${k}`, bufferrefresh0);
        //     localStorage.setItem(`mtusr${k}`, bufferuser0)
        //     localStorage.setItem(`mtusr${k}type`, buffertype0)
        //     window.location.reload()

        // }



    }


    const ChangeProfileTemp = (data: any) => {

        console.log(data)
        // let bufferuser: any = localStorage.getItem(`mtusr${k}`)
        // let bufferrefresh: any = localStorage.getItem(`refresh_token${k}`);
        // let bufferaccess: any = localStorage.getItem(`access_token${k}`);
        // let buffertype: any = localStorage.getItem(`mtusr${k}type`);

        // let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
        // let bufferrefresh0: any = localStorage.getItem(`refresh_token${0}`);
        // let bufferaccess0: any = localStorage.getItem(`access_token`);
        // let buffertype0: any = localStorage.getItem(`mtusr${0}type`);


        localStorage.setItem(`access_token`, data.access);
        localStorage.setItem(`refresh_token`, data.refresh);
        localStorage.setItem(`mtusr`, JSON.stringify(data));
        localStorage.setItem(`mtusrtype`, 'business');
        localStorage.setItem('remember', `${true}`);

        // localStorage.setItem(`access_token${1}`, bufferaccess0);
        // localStorage.setItem(`refresh_token${1}`, bufferrefresh0);
        // localStorage.setItem(`mtusr${1}`, bufferuser0)
        // localStorage.setItem(`mtusr${1}type`, buffertype0)

        // window.location.reload()
        window.location.href = '/'



    }

    const switchprofilesuccess = (res: any) => {
        console.log(res)
        ChangeProfileTemp(res)

    }
    const switchprofilefailed = (err: any) => {
        console.log(err)
    }

    const ChangetoBusiness = (id: any) => {
        let dt: any = localStorage.getItem('pdetails')
        let dtls: any = dt ? JSON.parse(dt) : []
        let token = dtls[0].access
        var config: any = {
            method: 'post',
            url: baseurl + '/api/v1/user/business-profile/authenticate/',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: {
                "business_profile": id
            },
        };
        axios(config)
            .then((res: any) => {
                console.log(res.data)
                switchprofilesuccess(res.data)
            })
            .catch((error: any) => {
                // const errorMsg = error.message
                console.log(error)
            });

        // Api('post', '/api/v1/user/business-profile/authenticate/', {
        //     "business_profile": id
        // }, switchprofilesuccess, switchprofilefailed, history, true, true)
    }

    return (
        <div style={{ minHeight: '100vh' }}>
            <Modal
                // fullScreen
                open={dialogStatus}
                onClose={handleClose}
                // TransitionComponent={Transition}
                className={classes.modal}
            >
                <div
                    className='switchprofilemodal'
                    style=
                    {{
                        display: "flex",
                        //   justifyContent: "space-around",
                        //   alignItems: "center",
                        flexDirection: "column",
                        width: '500px',
                        height: '520px',
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        gap: '20px',
                        padding: '20px',
                        fontFamily: "Inter",
                        // border : '3px solid green' , 
                    }}>
                    <h2 style={{ textAlign: 'center' }}>Switch profile</h2>
                    <Scrollbars style={{ height: 430 }} autoHide>
                        <ul>
                            <li style={{display:'flex',justifyContent:'space-between'}}><small>Personal profiles</small>
                            <small className='cursor' style={{color:'var(--cl_orange)'}} onClick={()=>{
                                history.push('/personal-account')
                                handleClose();

                            }}>+ Add personal profile</small></li>
                            {
                                storedprof.length === 0 ? (
                                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><small>no profiles found</small></li>
                                ) : (
                                    storedprof.map((user: any, key: any) => {
                                        let { id,dp_thumbnail, username, firstname, surname, is_business_profile } = user;
                                        let current = JSON.parse(crnt)
                                        return (is_business_profile ? null :
                                            <li className='profileslistitem' onClick={() => {
                                                if(!(current.id === id)){
                                                    ChangeProfile(key, 'personal')
                                                }
                                                
                                            }}>
                                                <img src={dp_thumbnail} alt='' />
                                                <div className='profilelistitemcontent'>
                                                    <div className='profilelistitemdetails'>
                                                        <h5>{firstname + " " + surname}</h5>
                                                        <small>{'@' + username}</small>
                                                    </div>
                                                    <div className={(current.id === id) ? 'roundedcheckbox roundedcheckboxactive' : 'roundedcheckbox'}>
                                                        <div className='roundedcheckboxdot'>

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                )
                            }
                            <li style={{display:'flex',justifyContent:'space-between'}}><small>Business profiles</small>
                            <small className='cursor' style={{color:'var(--cl_orange)'}} onClick={()=>{
                                history.push('/business-account')
                                handleClose();
                            }}>+ Create business account</small></li>
                            {
                                businessprofiles.length === 0 && businessloading === false ? (
                                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><small>no profiles found</small></li>
                                ) : (
                                    businessprofiles.map((user: any, key: any) => {
                                        let { id, dp_thumbnail, dp, username, firstname, is_business_profile } = user;
                                        // !is_business_profile ? null :
                                        let crnt: any = localStorage.getItem('mtusr')
                                        let current = JSON.parse(crnt)
                                        return (
                                            <li className='profileslistitem' onClick={() => {
                                                // ChangeProfile(key, 'business')
                                                if (!(current.id === id)) {
                                                    ChangetoBusiness(id)
                                                }
                                            }}>
                                                <img src={dp_thumbnail ? dp_thumbnail : dp} alt='' />
                                                <div className='profilelistitemcontent'>
                                                    <div className='profilelistitemdetails'>
                                                        <h5>{firstname}</h5>
                                                        <small>{'@' + username}</small>
                                                    </div>
                                                    <div className={(current.id === id) ? 'roundedcheckbox roundedcheckboxactive' : 'roundedcheckbox'}>
                                                        <div className='roundedcheckboxdot'>

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                )
                            }
                            {
                                businessloading === true && businesserror === null ? <li className='profileslistitem '>
                                    <div className='skeleton' style={{ width: '48px', height: '48px', borderRadius: '12px' }}></div>
                                    <div className='profilelistitemcontent'>
                                        <div className='profilelistitemdetails'>
                                            <h5 className='maxwid60 minhe10 skeleton' style={{ marginBottom: '10px' }}></h5>
                                            <small className='minwid60 minhe10 skeleton'></small>
                                        </div>
                                    </div>
                                </li> : null
                            }
                        </ul>
                    </Scrollbars>
                </div>

            </Modal>
        </div>
    )
}

export default SwitchProfileModal
