import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Play } from '../../../../assets/svg/video.svg';
import { Fetch_Videos } from '../store/action';
import MediaModal1 from '../../../smallcomponents/modals/MediaModal1';
import { noData } from '../../../../Data/Data';
import { ReactComponent as Media } from '../../../../assets/svg/media.svg';
import { errors } from '../../../../Data/Error';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';

const Videos = () => {
    const [modalActive, setActive] = useState(false)
    const [index, setindex] = useState(0)
    // const { eventId }: any = useParams()
    const { event } = useAppSelector(state => state.event)
    let eventId = event.id;
    console.log(useParams())
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { videos, verror, vloading, vpage, vpagination } = useAppSelector(state => state.Post)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        Fetch_Videos(dispatch, history, eventId, 1, [])
    }, [dispatch, history, eventId])

    const Refresh =()=>{
        Fetch_Videos(dispatch, history, eventId, 1, [])
    }

    const observer: any = useRef()

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && vpagination) {
                let page_no = vpage + 1;
                if (!vloading) {
                    Fetch_Videos(dispatch, history, eventId, page_no, videos);
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [dispatch, history, vpagination, vpage, eventId, videos, vloading])
    return (
        <div className='mediaGrid'>
            {
                !((Object.keys(videos).length) === 0) ? (
                    videos?.map((item: any, key: number) => {
                        const { post_file } = item;
                        return (
                            <div ref={videos?.length === key + 1 ? lastBookElementRef : null} key={key} className='videoTileContainer' onClick={
                                () => {
                                    setindex(key);
                                    setActive(!modalActive)
                                }
                            }
                            onMouseEnter={(e)=>{
                                let target: any = e.currentTarget.firstChild
                                target.play()
                            }} onMouseLeave={(e)=>{
                                let target: any = e.currentTarget.firstChild
                                target.pause()
                            }}
                            >
                                <video className='videoTile' muted>
                                    <source src={post_file} type="video/mp4" />
                                    <source src={post_file} type="video/mkv" />
                                    <source src={post_file} type="video/quicktime" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='videoOverlay'>
                                    <Play />
                                </div>
                            </div>
                        )
                    })
                ) : ((!vloading && verror === null) &&
                    <div className='noData'>
                        <Media />
                        <h2>{noData.media.heading}</h2>
                        <span>{noData.media.message}</span>
                    </div>
                )
            }
            {vloading &&
                [...Array(10)].map(() => {
                    return <div className='videoTileContainer skeleton'></div>
                })
            }
            {
                verror !== null &&
                (verror.status === null ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <Nointernet />
                            <h2>{errors.internet.heading}</h2>
                            <span>{errors.internet.message}</span>
                            <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                        </div>
                    </div>
                ) : verror.status === 500 ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <Server />
                            <h2>{errors.server.heading}</h2>
                            <span>{errors.server.message}</span>
                            <button className='mainBtn' onClick={Refresh}>Try again</button>
                        </div>
                    </div>
                ) : verror.status === 401 ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <NotFound />
                            <h2>{errors.notFound.heading}</h2>
                            <span>{errors.notFound.message}</span>
                        </div>
                    </div>
                ) : (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <NotFound />
                            <h2>{errors.common.heading}</h2>
                            <span>{errors.common.message}</span>
                            <button className='mainBtn' onClick={Refresh}>Refresh the page</button>
                        </div>
                    </div>
                ))
            }
            {
                modalActive && <MediaModal1 data={videos} index={index} type={'all'}
                    open={modalActive} setOpen={setActive}
                />
            }
        </div>
    )
}

export default Videos
