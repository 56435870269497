import React from 'react'
const GuestTicket = () => {
  return (
    <div>

        <h1>HEllo</h1>
    </div>
  )
}

export default GuestTicket