import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { getCroppedImg } from "./crop";
import { dataURLtoFile } from "./dataURLtoFile";
import WarningIcon from "@material-ui/icons/Warning";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import imageCompression from 'browser-image-compression';
import { useDispatch } from "react-redux";
import { editchangeMode } from "../../Tabs/addevent/store/editEventSlice";
import "./style.css";
import { useAppDispatch } from "../../../app/hooks";
import Api from "../../../app/api";

const useStyles = makeStyles((theme) => ({
    modal: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    appBar: {
        position: "relative",
        backgroundColor: "white",
    },
    buttonClose: {
        height: "4rem",
        width: "4rem",
        position: "absolute",
        // backgroundColor: 'white',
        color: "red",
        right: "10px",
        zIndex: 100,
    },
    title: {
        marginLeft: theme.spacing(2),
        color: "black",
        flex: 1,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[2],
        padding: theme.spacing(2, 4, 3),
    },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function FullScreenDialog({
    dialogStatus,
    dialogAction,
    imagename,
    setimagename,
    //   setChange,
    //   change,
    type,
    trigger,
}: {
    dialogStatus: boolean,
    dialogAction: any,
    imagename: any,
    setimagename: any,
    // setChange: any,
    // change: any,
    type: any,
    trigger: any,
}) {
    const classes = useStyles();

    const handleClose = () => {
        dialogAction(false);
        // setAlert(false)
        setAlerter(false);

        // setImg('')
    };
    const dispatch = useAppDispatch();
    const inputRef = React.useRef();

    const triggerFileSelectPopup = () => {
        //   inputRef.current.click()
        const target = document.getElementById('imageadd')
        if (target) {
            target.click()
        }
        console.log('click')
    };
    const i: any = null
    const [image, setImage] = React.useState(i);
    const [name, setName] = React.useState(null);

    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const z: any = 1
    const [zoom, setZoom] = React.useState(z);
    const [loader, setLoader] = React.useState(false);
    const [alerter, setAlerter] = React.useState(false);

    const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
        setCroppedArea(croppedAreaPixels);
    };

    const clear = () => {
        dialogAction(false);
        setImage(null);
        setAlerter(false);
    };
    const textalert = () => {
        setAlerter(true);
        setTimeout(() => {
            setAlerter(false);
        }, 3000);
    };
    const Setitup = (file: any) => {
        const reader = new FileReader();
        setName(file);
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
            setImage(reader.result);
            setLoader(false)
            console.log("setimage", reader);
        });
    }

    const onSelectFile = async (event: any) => {
        setLoader(true)

        console.log("thi sis image", event);
        if (event.target.files && event.target.files.length > 0) {
            console.log(event.target.files[0])
            const nav: any = navigator
            const { userAgentData } = nav
            let options;
            if (userAgentData?.platform === 'macOS') {
                if (event.target.files[0].size > 1000000) {
                    options = {
                        maxSizeMB: 1,
                        // maxWidthOrHeight: 800,
                        maxWidthOrHeight: 1392,
                        useWebWorker: true
                    }
                    try {
                        const compressedFile = await imageCompression(event.target.files[0], options);
                        console.log(compressedFile)
                        Setitup(compressedFile)
                        setLoader(false)
                    } catch (error) {
                        console.log(error);
                        setLoader(false)
                    }
                } else {
                    console.log('lesser')
                    Setitup(event.target.files[0])
                    setLoader(false)
                }
            } else {
                options = {
                    maxSizeMB: 5,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }
                if (event.target.files[0].size > 2999999) {
                    try {
                        const compressedFile = await imageCompression(event.target.files[0], options);
                        console.log(compressedFile)
                        Setitup(compressedFile)
                        setLoader(false)
                    } catch (error) {
                        console.log(error);
                        setLoader(false)
                    }
                } else {
                    console.log('lesser than 3mb')
                    Setitup(event.target.files[0])
                    setLoader(false)
                }
            }



            // if (event.target.files[0].size > 1000000) {
            //     console.log('greater')
            //     // let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'

            // const nav: any = navigator
            // const { userAgentData } = nav
            // console.log(userAgentData.platform)
            // const options = userAgentData?.platform === 'macOS' ? {
            //     maxSizeMB: 1,
            //     // maxWidthOrHeight: 800,
            //     maxWidthOrHeight: 1392,
            //     useWebWorker: true
            // } : {
            //     maxSizeMB: 5,
            //     maxWidthOrHeight: 1920,
            //     useWebWorker: true
            // }
            //     console.log(options)
            //     try {
            //         const compressedFile = await imageCompression(event.target.files[0], options);
            //         // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            //         // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            //         // console.log(compressedFile)
            //         // const a = window.URL.createObjectURL(compressedFile)
            //         // console.log(a)


            //         // Compressedupload(compressedFile);
            //         console.log(compressedFile)
            //         Setitup(compressedFile)
            //         setLoader(false)
            //     } catch (error) {
            //         console.log(error);
            //         setLoader(false)
            //         //   alert(error.message)
            //     }

            // } else {
            //     console.log('lesser')
            //     Setitup(event.target.files[0])
            //     setLoader(false)
            // }


            // Setitup(event.target.files[0])
            // setLoader(false)

            // const reader = new FileReader();
            // setName(event.target.files[0].name);
            // reader.readAsDataURL(event.target.files[0]);
            // reader.addEventListener("load", () => {
            //   setImage(reader.result);
            //   setLoader(false)
            //   console.log("setimage", reader);
            // });
        }
    };

    const onUpload = async () => {
        if (image) {

            setAlerter(false);
            console.log(croppedArea)
            const canvas = await getCroppedImg(image, croppedArea);
            const canvasDataUrl = canvas.toDataURL("image/jpeg");
            const convertedURLtoFile = dataURLtoFile(canvasDataUrl, 'cropped.jpeg');

            if (type === 'edit') {

                // const success = (data: any) => {
                //     console.log(data)
                // }
                // const failed = (data: any) => {
                //     console.log(data)
                // }
                // console.log(convertedURLtoFile)
                // console.log(convertedURLtoFile.name)
                // console.log(typeof convertedURLtoFile)
                // console.log(typeof convertedURLtoFile.name)


                // let d = new FormData();

                // d.append("event_image", convertedURLtoFile);

                // Api('put', `/api/v1/event/image/451/`,d,success,failed,true,true)
                // console.log()
                setimagename(convertedURLtoFile);
                dispatch(editchangeMode({ imgtype: 'file' }))
                trigger()
            } else {
                // console.log('edit')
                setimagename(convertedURLtoFile);
                console.log(convertedURLtoFile)
                trigger()
            }

            handleClose();
        } else {
            // alert(' select an image you fool')
            textalert();
        }
    };


    return (
        <div>
            <Dialog
                fullScreen
                open={dialogStatus}
                onClose={handleClose}
                // TransitionComponent={Transition}
                className={classes.modal}
            >
                <IconButton
                    className={classes.buttonClose}
                    //   color="red"
                    onClick={clear}
                    aria-label="close"
                >
                    <CloseIcon fontSize="large" />
                </IconButton>

                <div className="container1">
                    <input
                        type="file"
                        accept="image/*"
                        //   ref={inputRef}
                        id='imageadd'
                        onChange={onSelectFile}
                        style={{ display: "none" }}
                    />
                    <div className="container-cropper">
                        {/* {alerter ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection:'column',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "1.5rem",
                                    color: "orange",
                                    zIndex: 1000,
                                    transform:'translateY(40vh)'
                                }}
                            >
                                <WarningIcon />
                                Please select an image 
                            </div>
                        ) : null} */}
                        {image ? (
                            <>
                                <div className="cropper imageaddcropper">
                                    <Cropper
                                        image={image}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={29 / 10}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}

                                    />
                                </div>

                                <div className="slider1">
                                    <div>
                                        <ZoomOutIcon
                                            style={{ color: "grey", marginRight: "5px" }}
                                        />
                                    </div>
                                    <Slider
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoom}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                    <div>
                                        <ZoomInIcon style={{ color: "grey", marginLeft: "5px" }} />
                                    </div>
                                </div>
                                {loader ? (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "48vh",
                                            right: "49vw",
                                            color: "var(--cl_orange)",
                                            zIndex: 1000,
                                        }}
                                    >
                                        <CircularProgress size={50} color="inherit" />
                                        {/* Compressing */}
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "45vh",
                                    fontFamily: 'Inter, sans-serif',
                                    color: alerter ? "orange" : 'black',
                                }}
                            >

                                {
                                    loader ? <div
                                        style={{
                                            position: "absolute",
                                            top: "48vh",
                                            right: "49vw",
                                            color: "var(--cl_orange)",
                                            zIndex: 1000,
                                        }}
                                    >
                                        <CircularProgress size={50} color="inherit" />
                                        {/* Compressing */}
                                    </div> : (<>
                                        {alerter && <WarningIcon />}
                                        <h2>{alerter ? 'Please select an image' : 'Choose your image'}</h2>
                                    </>
                                    )
                                }

                            </div>
                        )}
                    </div>

                    <div className="container-buttons" style={{ color: 'white' }}>

                        <Button
                            variant="contained"
                            //   color="black"
                            onClick={triggerFileSelectPopup}
                            style={{ marginRight: "10px", backgroundColor: 'white', fontFamily: 'Inter, sans-serif' }}
                        >
                            Add
                        </Button>
                        <Button variant="contained" color="inherit" style={{ backgroundColor: 'var(--cl_orange)', fontFamily: 'Inter, sans-serif' }} onClick={onUpload}>
                            {/* upload */}
                            Save
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
