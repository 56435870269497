import React from 'react';
import { Profile } from './store/story.interface';

interface IProp {
	viewStoryFx: () => void;
	item: Profile;
	setCurrentStoryKey: any;
	Itemkey: number
}

const StoryTile = ({ viewStoryFx, item, setCurrentStoryKey, Itemkey }: IProp) => {
	return (
		<div
			onClick={() => {
				setCurrentStoryKey(Itemkey)
				viewStoryFx()
			}
			}
			className='story__tile__container'
			style={{
				// backgroundImage: 'url(https://lh3.googleusercontent.com/FiGlZjyMvjuWLiKJ0t-1Qssdqp70f_nQM9MAmGprUixXswbGnSeizLfmxsl4NDzEYw)',
				backgroundImage: `url(${item?.story_list && (item.story_list[0]?.story_file_thumbnail || item.story_list[0]?.story_image_thumbnail_1)})`,

				backgroundColor: 'linear-gradient(233deg, rgba(255,255,255,0.06486344537815125) 0%, rgba(222,220,218,1) 20%, rgba(168,168,168,1) 100%);',
				height: '170px',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'
			}}>
			<div className='story__tile__image'>
				<img src={item?.dp} alt='' />
			</div>
			<p className='story__tile__username'>{item?.firstname} {item?.surname}</p>
		</div>
	);
};

export default StoryTile;
