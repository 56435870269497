import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'

const UpcomingReportedContent = ({ upcoming, content, setOpen, index, setindex, ScrollPos }: any) => {

    const { reportedeventsmoderation, reportedeventsmoderationpagination, reportedpostsmoderation, reportedpostsmoderationpagination,
        reportedeventsmoderationerror, reportedeventsmoderationloading, reportedpostsmoderationerror, reportedpostsmoderationloading } = useAppSelector(state => state.moderator)
    return (
        <div className='contentmoderator_minicontent'>
            <div className='cm_minicontent_header'>
                <h3>Upcoming content</h3>
                <a href='' onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setOpen(true)
                }}>Show all</a>
            </div>
            {upcoming === 'event' ? (
                <div className='cm_content_mapped'>
                    {!(Object.keys(content).length === 0) ? (
                        content.map((data: any, key: number) => {
                            return (
                                <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'} onClick={() => {
                                    setindex(key + index + 1)
                                    if (reportedeventsmoderation.length - (index + 1) <= 3) {
                                        if (reportedeventsmoderationpagination) {
                                            ScrollPos({
                                                top: 1
                                            })
                                        }
                                    }
                                }}>
                                    <img src={data.event_image[0].image_thumbnail} />
                                    <div className='cm_contentitem_info'>
                                        <h4>{data.name}</h4>
                                        <p>{data.description}</p>
                                        <div className='cm_contentitem_user'>
                                            <img src={data.profile_dp} />
                                            <span>{data.profile_firstname + ' ' + data.profile_surname}</span>
                                        </div>
                                        {/* {!upcoming && <button className='cm_content_statbtn cm_content_statbtn_green'>
                                        <Approved /> Approved
                                    </button>} */}

                                    </div>
                                </div>
                            )
                        })) : (
                        !reportedeventsmoderationloading ?
                            reportedeventsmoderationerror === null ?
                                <div className='noData'>
                                    {/* <Warning /> */}
                                    {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                    <span>Events will be displayed here.</span>
                                </div>
                                : null
                            : null
                    )
                    }
                </div>
            ) : (<div className='cm_content_mapped'>
                {
                    !(Object.keys(content).length === 0) ? (
                        content.map((data: any, key: number) => {
                            return (
                                <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'} onClick={() => {
                                    setindex(key + index + 1)
                                    if (reportedpostsmoderation.length - (index + 1) <= 3) {
                                        if (reportedpostsmoderationpagination) {
                                            ScrollPos({
                                                top: 1
                                            })
                                        }
                                    }
                                }}>
                                    <img src={data.post_file[0].posted_file_thumbnail} />
                                    <div className='cm_contentitem_info'>
                                        <h4>{data.event.name}</h4>
                                        <p>{data.description}</p>
                                        <div className='cm_contentitem_user'>
                                            <img src={data.profile.dp_thumbnail} />
                                            <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                                        </div>
                                        {/* {!upcoming && <button className='cm_content_statbtn cm_content_statbtn_green'>
                                    <Approved /> Approved
                                </button>} */}

                                    </div>
                                </div>
                            )
                        })) : (
                        !reportedpostsmoderationloading ?
                            reportedpostsmoderationerror === null ?
                                <div className='noData'>
                                    {/* <Warning /> */}
                                    {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                    <span>Posts will be displayed here.</span>
                                </div>
                                : null
                            : null
                    )
                }
            </div>)
            }
        </div>
    )
}

export default UpcomingReportedContent