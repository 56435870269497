import React, { useState, useEffect } from 'react';

import {
  chatselectedreset, chatselected,
  chatsuccess, chatrequest, chatfailed, userssearchrequest,
  usersearchfailed, usersearch, chatopen, groupcreationfailure, groupcreationrequest, addcreatedgroup, frienduserssearchrequest
} from '../../chat/store/ChatSlice';
import Api from '../../../../app/api';

import { BsPlus } from 'react-icons/bs'
import { ReactComponent as Group } from '../../../../assets/svg/chat/groupchat.svg';
import { BiArrowBack } from 'react-icons/bi'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Chatuserlist from '../common/Chatuserlist';
import { useHistory } from 'react-router-dom';
import ChatSearch from '../common/ChatSearch';
import Scrollbars from 'react-custom-scrollbars-2';
import { IoCloseOutline } from 'react-icons/io5';
import ProfileDialog from '../../../smallcomponents/cropmodals/ProfileDialog'
import { fetchchatlist } from '../store/action';



const ChatuserlistLayout = ({ children }: { children: any }) => {

  const dispatch = useAppDispatch()
  const history = useHistory()
  const { selectedchatdetails, selectedchats } = useAppSelector(state => state.chat)
  const [newgroupactive, setnewgroupactive] = useState(false)
  const [newchatmessage, setnewchatmessage] = useState(false)
  const [activestage, setactivestage] = useState(1)
  const [grpname, setgrpname] = useState('')
  const [grpdesc, setgrpdesc] = useState('')
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState<any>(null)
  // const [selectedprofiles, setselectedprofiles] = useState<any>([])
  useEffect(() => {
    fetchchatlist(history, dispatch, 1);
  }, [])


  const changegroupicon = (response: any) => {
    const form_data = new FormData();
    form_data.append("group_icon", change);
    let data: any = response;
    data['group_icon'] = window.URL.createObjectURL(new Blob([change]))
    data['group_icon_thumbnail'] = window.URL.createObjectURL(new Blob([change]))
    const grpiconsuccess = (res: any) => {
      console.log(res)
      dispatch(addcreatedgroup(data))
    }
    const grpiconfailed = (err: any) => {
      console.log(err)
    }
    Api('put', `/api/v1/ws/chat/group/icon/${response.chat_id}/`, form_data, grpiconsuccess, grpiconfailed, history, true, true)
  }

  const grpsuccess = (res: any) => {
    console.log(res)
    let response = res.data
    response['chat_id'] = response.id
    response['mute_status'] = false
    response['blocked_status'] = false
    response['reported_status'] = false
    response['exited_status'] = false
    response['starred_status'] = false
    response['permissions'] = {
      'add_members': true,
      'can_edit': true,
      'remove_members': true,
      'can_send_message': true,
      'add_admin': true
    }
    // delete response['id']
    if (change) {
      changegroupicon(response)
    } else {
      dispatch(addcreatedgroup(response))
    }

  }
  const grpfailed = (err: any) => {
    console.log(err)
    dispatch(groupcreationfailure(err))
  }
  const CreateNewgroup = () => {
    let members: any = []
    selectedchatdetails.map((mem: any, key: number) => {
      members = [...members, mem.friend_info.id]
    })
    let post_data = {
      "chat_name": grpname,
      "chat_description": grpdesc,
      "chat_type": "group",
      "chat_members": members
    }
    dispatch(groupcreationrequest())

    Api('post', '/api/v1/ws/chat/', post_data, grpsuccess, grpfailed, history, true, true)
    setnewgroupactive(false)
    setactivestage(1)
    // setselectedprofiles([])
    dispatch(chatselectedreset())
    setgrpname('')
    setgrpdesc('')
  }

  return (
    <div className='chat_users_container'>
      <div className='chat_users_wrapper'>
        <div className='chat_btns_container'>
          <button className='BtnOrange cursor' style={{ color: 'white' }} onClick={() => {
            setnewchatmessage(true)
          }}>
            <BsPlus style={{ color: 'white' }} />New Chat</button>
          <button className='BtnWhite cursor' onClick={() => {
            setnewgroupactive(true)
            dispatch(chatselectedreset())
          }}><Group /> Create Group</button>
        </div>
        {children}
      </div>
      {
        <div className={newchatmessage ? 'sendmessage_container sendmessage_container_active' : 'sendmessage_container sendmessage_container_inactive'}>
          <div className='sendmessage_content'>
            <div className='sendmessage_search'>
              <div className='sendmessage_backbtn'>
                <BiArrowBack className='cursor' onClick={() => {
                  dispatch(frienduserssearchrequest({ page: 1, keyword: '', loading: false, chattype: '' }))
                  setnewchatmessage(false)

                }} />
              </div>
              {newchatmessage && <ChatSearch chatwindow={'sendmessage'} />}
            </div>
            <Chatuserlist thewindow={'friend'} setnewchatmessage={setnewchatmessage} />

          </div>
        </div>
      }
      {
        <div className={
          newgroupactive ? 'addgroup_container addgroup_container_active'
            : 'addgroup_container addgroup_container_inactive'
        }>
          <div className='addgroup_screenscontainer'>
            <div className={activestage === 1 ? 'addgroup_screen1 addgroup_screen1_active' : 'addgroup_screen1 addgroup_screen1_inactive'}>
              <div className='addgroup_screen1_header'>
                <BiArrowBack onClick={() => {
                  dispatch(chatselectedreset())
                  setnewgroupactive(false)
                }} />
                <h2>Add group members</h2>
              </div>
              <div className='addgroup_screen1_content'>
                <div className='addgroup_memberadd'>
                  <Scrollbars className='addgroup_addedmembers' autoHide>
                    {
                      selectedchatdetails.map((prof: any, key: number) => {
                        const { chat_name, chat_type, friend_info, recent_message, unread_msg_count, group_icon_thumbnail, chat_id } = prof
                        return (
                          <div className='new_group_added_mem'
                            style={{ width: '100%', display: 'flex', gap: '8px', alignItems: 'center', padding: '5px 24px', backgroundColor: 'white', borderRadius: '.5rem', }}
                          >
                            <img src={friend_info ? friend_info.dp_thumbnail : ''} style={{ height: '30px', width: '30px', borderRadius: '50%' }} />
                            <h4>{friend_info ? friend_info.firstname + friend_info.surname : null}</h4>
                            <IoCloseOutline className='cursor'
                              onClick={() => {
                                dispatch(chatselected({ chat_id: chat_id, details: prof }))
                              }

                              }
                            />
                          </div>
                        )
                      })
                    }

                  </Scrollbars>
                  {/* <input type='text' className='addgroup_memberinput' autoFocus value={searchuser1} style={{ backgroundColor: '#F5F5F6', borderRadius: '8px' }} onChange={(e) => {
                    console.log(e.target.value)
                    setsearchuser1(e.currentTarget.value)
                  }} /> */}
                  <div className='sendmessage_search'>

                    <ChatSearch chatwindow={'main'} />
                  </div>
                  {/* <ChatSearch chatwindow={'group'}/> */}
                </div>

                <Chatuserlist thewindow={'group'} />
              </div>
              <div className='addgroup_screen1_footer'>
                <div className={selectedchats.length < 1 ? 'addgroup_screen1_footericonwrapper' : 'addgroup_screen1_footericonwrapper addgroup_screen1_footericonwrapper_active'} onClick={() => {
                  setactivestage(2)
                  // if (!(selectedprofiles.length < 1)) {
                  //   setactivestage(2)
                  // }

                }}>
                  <BiArrowBack />
                </div>

              </div>
            </div>
            <div className={activestage === 2 ? 'addgroup_screen2 addgroup_screen2_active' : 'addgroup_screen2 addgroup_screen2_inactive'}>
              <div className='addgroup_screen2_header'>
                <BiArrowBack onClick={() => {
                  setactivestage(1)
                }} />
                <h2>New group</h2>
              </div>
              <div className='addgroup_screen2_content'>
                {open && <ProfileDialog
                  dialogStatus={open}
                  dialogAction={setOpen}
                  // fetchProfile={fetchProfile}
                  setChange={setChange}
                  change={'group'}
                  currentDp={'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png'}
                />
                }
                <div className='addgroup_groupdetails'>
                  <div className='addgroup_groupicon cursor' onClick={() => {
                    setOpen(true)
                  }}>
                    <img src={change !== null ? window.URL.createObjectURL(new Blob([change])) : 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png'} />
                    <div className='addgroup_groupicon_icon'>

                    </div>
                  </div>
                  <div className='addgroup_detailsinput'>
                    <input type='text' placeholder='Group name' className='addgroup_name' onChange={(e) => {
                      setgrpname(e.currentTarget.value)
                      console.log(e.currentTarget.value.length)
                    }} />
                    <input type='text' placeholder='Description' className='addgroup_description' onChange={(e) => {
                      setgrpdesc(e.currentTarget.value)

                    }} />
                  </div>
                  <div className={grpname.length === 0 ? 'addgroup_final' : 'addgroup_final addgroup_final_active'}>
                    <BiArrowBack onClick={() => {
                      if (grpname.length !== 0) {
                        CreateNewgroup()
                      }
                      // setactivestage(1)
                    }} />
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>}
    </div>
  );
};

export default ChatuserlistLayout;
