export const NumberListFx = () => {
	let array: { label: string; value: any }[] = [];
	for (let index = 1; index < 1000; index++) {
		array.push({ label: `${index}`, value: index });
	}
	return array;
};


export const DatesFx = () => {
	let array: { label: string; value: any }[] = [];
	for (let index = 1; index < 32; index++) {
		array.push({ label: `${index}`, value: index });
	}
	return array;
};



export const monthArray  = [
	{ label: "Jan", value: 1 }
	, { label: "Feb", value: 2 }
	, { label: "Mar", value: 3 }
	, { label: "Apr", value: 4 }
	, { label: "May", value: 5 }
	, { label: "Jun", value: 6 }
	, { label: "Jul", value: 7 }
	, { label: "Aug", value: 8 }
	, { label: "Sep", value: 9 }
	, { label: "Oct", value: 10 }
	, { label: "Nov", value: 11 }
	, { label: "Dec", value: 12 }
]