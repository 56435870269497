import React, { useEffect } from 'react';
import ReceivedMessage from './ReceivedMessage';
import SentMessage from './SentMessage';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { fetchsinglechatmessages } from '../store/action';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const Chatmessageslist = () => {
  const dispatch = useAppDispatch();
  const history = useHistory()

  const { profile } = useAppSelector(state => state.profile)
  const { chatmessages, chatmessageserror, chatmessagesloading,
    chatmessagespage, chatmessagespagination, openedchatdetails, openedchatkey } = useAppSelector(state => state.chat)

  useEffect(() => {
    // fetchchathistory()
    // fetchchatdetail()
    fetchsinglechatmessages(openedchatdetails.chat_id, 1, history, dispatch)
  }, [openedchatkey])

  const ScrollPos = (val: any) => {
    const { top } = val
    console.log(top)

    if (top <= -0.9) {
      // console.log('scroll please')
      if (chatmessagespagination && !chatmessagesloading) {
        fetchsinglechatmessages(openedchatdetails.chat_id, chatmessagespage, history, dispatch)
      }
    }

  }
  let thedate: any = null;
  const datesAreOnSameDay = (first: any, second: any) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const Yesterday = (first: any, second: any) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate() - 1;

  const ChecktheDay = (date: any) => {
    let today = new Date()

    dayjs.utc(date).local().format('DD MM YYYY')
    return (datesAreOnSameDay(new Date(dayjs.utc(date).local().format('YYYY-MM-DDTHH:mm:ss')), today) ? 'Today'
      : Yesterday(new Date(dayjs.utc(date).local().format('YYYY-MM-DDTHH:mm:ss')), today) ? 'Yesterday'
        : dayjs.utc(date).local().format('ddd, D MMM, YYYY')
    )
  }

  // console.log('---------------------------thus is test-------------------' + chatmessages);


  return (
    <Scrollbars className='chat_messages' autoHide onUpdate={ScrollPos}>
      {
        chatmessages ? chatmessages.map((msg: any, key: number) => {
          const { sender, created_at } = msg;
          let now = new Date()
          // console.log('now', dayjs.utc(now).local().format('YYYY-MM-DD'))
          let converteddate = dayjs.utc(created_at).local().format('YYYY-MM-DD HH:mm:ss')
          // console.log('msg date', dayjs.utc(created_at).local().format('YYYY-MM-DD'))
          // let prevdate = key > 0 ? dayjs.utc(chatmessages[key -1].created).local().format('YYYY-MM-DD HH:mm:ss'): null
          let prevdate = thedate
          // console.log('previousdate', prevdate)

          thedate = converteddate
          let today = datesAreOnSameDay(now, new Date(converteddate))

          let tommorow = chatmessages[key + 1] ? dayjs.utc(chatmessages[key + 1].created_at).local().format('YYYY-MM-DD HH:mm:ss') : ''
          let same = tommorow !== '' ? datesAreOnSameDay(new Date(tommorow), new Date(converteddate)) : null
          return (
            // profile.id !== sender.id ?
            //   <ReceivedMessage msg={msg} k={key} />
            //   : <SentMessage msg={msg} k={key} />

            today ? (
              chatmessages[key + 1] ? (
                datesAreOnSameDay(new Date(tommorow), new Date(converteddate)) ? (
                  profile.id !== sender.id ? (
                    <ReceivedMessage msg={msg} k={key} />
                  ) : <SentMessage msg={msg} k={key} />
                ) : (
                  <>
                    {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} />
                      : <SentMessage msg={msg} k={key} />}
                    <div className='datetab_chat'>
                      <hr></hr>
                      <span>
                        {ChecktheDay(now)}
                      </span>
                      <hr></hr>

                    </div>
                  </>
                )

              ) : (
                <>
                  {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} />
                    : <SentMessage msg={msg} k={key} />}
                  <div className='datetab_chat'>
                    <hr></hr>
                    <span>
                      {ChecktheDay(now)}
                    </span>
                    <hr></hr>

                  </div>
                </>
              )

            ) : (
              tommorow === '' ? (
                <>

                  {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} />
                    : <SentMessage msg={msg} k={key} />}
                  <div className='datetab_chat'>
                    <hr></hr>
                    <span>
                      {ChecktheDay(converteddate)}
                    </span>
                    <hr></hr>

                  </div>
                </>
              ) : (
                same ? (
                  profile.id !== sender.id ? (
                    <ReceivedMessage msg={msg} k={key} />
                  ) : <SentMessage msg={msg} k={key} />
                ) : (
                  <>
                    {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} />
                      : <SentMessage msg={msg} k={key} />}
                    <div className='datetab_chat'>
                      <hr></hr>
                      <span>
                        {ChecktheDay(converteddate)}
                      </span>
                      <hr></hr>

                    </div>
                  </>
                )
              )

            )
          )
        }) : null
      }
      {(chatmessagespage !== 1 && chatmessagesloading && chatmessageserror === null) &&
        <div className="progresslayout" style={{ maxHeight: "15px", color: 'var(--cl_orange)' }}>
          <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
        </div>
      }

    </Scrollbars>
  );
};

export default Chatmessageslist;
