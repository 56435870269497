import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { baseurl } from '../../../../Data/Data';
import ModalLayout from '../../../utils/ModalLayout';
import { ReactComponent as Tick } from '../../../../assets/svg/lgcheck.svg';
import { useAppSelector } from '../../../../app/hooks';
import { MdPersonAddAlt1 } from 'react-icons/md';
import { BiArrowBack } from 'react-icons/bi';
import { MdAddAPhoto } from 'react-icons/md';
// import ProfileDialog from '../../../smallcomponents/cropmodals/ProfileDialog';
import imageCompression from 'browser-image-compression';
import { makeStyles } from '@material-ui/core/styles';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../../smallcomponents/cropmodals/crop';
import { dataURLtoFile } from '../../../smallcomponents/cropmodals/dataURLtoFile';

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[2],
		padding: theme.spacing(2, 4, 3),
	},
	imgContainer: {
		position: 'relative',
		flex: 1,
		padding: 16,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	img: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
	cropContainer: {
		position: 'relative',
		width: '100%',
		height: 200,
		background: '#333',
		[theme.breakpoints.up('sm')]: {
			height: 400,
		},
	},
}));

// import InviteItem from './InviteItem';

const SelectProfileModal = ({ open, handleClose, alternateProfile , addProfileName,setalternateProfile,
	setaddProfileName,
	addProfileDp,
	setaddProfileDp }: any) => {
	const classes = useStyles();

	const [userList, setuserList] = useState([]);
	const [loading, setloading] = useState(false);
	const [error, seterror] = useState('');
	const { lat, lng } = useAppSelector((state) => state.profile);
	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);

	const SearchProfile = (text: string) => {
		setloading(true);
		const request = axios.CancelToken.source();
		const token = localStorage.getItem(`access_token`);
		// let data;
		// console.log('searching user');
		// data = {
		// 	'search_type': 'people',
		// 	'keyword': text,
		// };

		let data = {
			search_type: 'people',
			keyword: text,
			latitude: lat,
			longitude: lng,
		};

		let theurl = `/api/v1/search/1/12/?search_for=business`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/search/1/12/?search_for=business&auth_profile=${parseddetails[0].id}`;
			}
		}

		var config: any = {
			method: 'post',
			url: baseurl + theurl,
			cancelToken: request.token,
			headers: {
				'Authorization': 'Bearer ' + token,
			},
			data: data,
		};

		axios(config)
			.then((res) => {
				if (res?.status === 200) {
					setuserList(res?.data || []);
				}
				setloading(false);

				console.log(res);
				console.log(res.data);
			})
			.catch((error) => {
				// const errorMsg = error.message
				setloading(false);

				console.log(error);
				// console.log(errorMsg);
			});
	};

	useEffect(() => {
		SearchProfile('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//  IMAGE ADD
	const [image, setImage] = useState<any>(null);
	const [croppedArea, setCroppedArea] = useState(null);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [minzoom, setminZoom] = useState(1);

	const [loader, setLoader] = useState(false);
	const [alerter, setAlerter] = useState(false);

	const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
		setCroppedArea(croppedAreaPixels);
	};

	// const clear = () => {
	//   dialogAction(false);
	//   setImage(null);
	//   setAlerter(false);
	// };
	const textalert = () => {
		setAlerter(true);
	};
	const Setitup = (file: any) => {
		const reader: any = new FileReader();
		reader.readAsDataURL(file);
		reader.addEventListener('load', () => {
			if (reader) {
				setImage(reader.result);
			}
		});
	};

	const triggerFileSelectPopup = () => {
		let target = document.getElementById('userdpedit__2');
		if (target) {
			target.click();
		}
	};

	const onSelectFile = async (event: any) => {
		console.log('thi sis image', event);
		if (event.target.files && event.target.files.length > 0) {
			if (event.target.files && event.target.files.length > 0) {
				if (event.target.files[0].size > 1000000) {
					console.log('greater');
					// const options = {
					//   maxSizeMB: 1,
					//   maxWidthOrHeight: 800,
					//   useWebWorker: true
					// };
					const nav: any = navigator;
					const { userAgentData } = nav;
					console.log(userAgentData.platform);
					const options =
						userAgentData?.platform === 'macOS'
							? {
								maxSizeMB: 1,
								// maxWidthOrHeight: 800,
								maxWidthOrHeight: 1392,
								useWebWorker: true,
							}
							: {
								maxSizeMB: 5,
								maxWidthOrHeight: 1920,
								useWebWorker: true,
							};
					try {
						const compressedFile = await imageCompression(event.target.files[0], options);
						console.log(compressedFile);
						Setitup(compressedFile);
						sethideDP(true);

					} catch (error) {
						console.log(error);
						// alert(error.message)
					}
				} else {
					console.log('lesser');
					Setitup(event.target.files[0]);
					sethideDP(true);

				}
			}
			// const reader = new FileReader();
			// reader.readAsDataURL(event.target.files[0]);
			// reader.addEventListener("load", () => {
			//   setImage(reader.result);
			// });
		}
	};

	const [addNew, setAddNew] = useState(false);
	const [hideDP, sethideDP] = useState<any>(false);



	const save_fx = async () => {

		const canvas = await getCroppedImg(image, croppedArea);
		const canvasDataUrl = await canvas.toDataURL("image/jpeg");
		const convertedURLtoFile = await dataURLtoFile(
			canvasDataUrl,
			"cropped-image.jpeg"
		);

		setaddProfileDp(convertedURLtoFile)


		sethideDP(false);
	};

	return (
		<>
			<ModalLayout open={open} handleClose={handleClose} style={{ height: '100vh', overflow: 'hidden', zIndex: 1000 }}>
				{addNew ? (
					<React.Fragment>
						<div style={{ padding: '20px', backgroundColor: 'white', height: '100%' }}>
							<div
								className='AddEventContentTitle'
								style={{ paddingBottom: '0px', height: '60px', gap: '20px', alignItems: 'center', display: 'flex' }}>
								<span>
									<BiArrowBack
										onClick={() => { setAddNew(false); sethideDP(false); }}
										style={{ width: '20px', height: '20px', cursor: 'pointer' }}
									/>
								</span>
								<h4> {addNew ? 'Add New Profile' : 'Choose an business profile'} </h4>
							</div>
							{!hideDP && (
								<>
									<div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
										<div
											className='business_signup_dp_container cursor'
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<div className='business_signup_dp'>
												{addProfileDp && image ? (
													<img
														alt=''
														src={window.URL.createObjectURL(new Blob([addProfileDp]))}
														onClick={() => {
															sethideDP(true);
														}}
													/>
												) : (
													<>
														<div
															className='business_signup_dp_icon'
															onClick={() => {
																triggerFileSelectPopup();
															}}>
															<MdAddAPhoto />
														</div>
													</>
												)}
											</div>
											<label style={{ padding: '10px' }}> Upload Profile Dp</label>
										</div>

										<label> Profile Name</label>
										<input
											type='text'
											className='AddEventSectInput'
											value={addProfileName}
											autoFocus
											onChange={(e) => {
												setaddProfileName(e.target.value);
												// SearchProfile(e.target.value);
											}}
										/>
									</div>

									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
											gap: '20px',
											width: '100%',
											borderTop: '1px solid var(--cl_light_grey)',
											padding: '10px',
										}}>
										<div
											style={{
												display: 'flex',
												alignSelf: 'flex-end',
												gap: '10px',
												padding: '20px 0px 0px 0px',
												justifyContent: 'flex-end',
											}}>
											<button style={{ minWidth: '100px' }} className={'mainBtn'} onClick={handleClose}>
												save
											</button>
										</div>
									</div>
								</>
							)}

							{
								image && hideDP ? (
									<>
										<div className={classes.cropContainer} style={{ minWidth: '450px', minHeight: '300px' }}>
											<Cropper
												image={image}
												crop={crop}
												zoom={zoom}
												aspect={1}
												onCropChange={setCrop}
												onZoomChange={setZoom}
												onCropComplete={onCropComplete}
												maxZoom={15}
												zoomSpeed={0.15}
												onMediaLoaded={(mediaSize) => {
													console.log('mediaSize', mediaSize);
												}}
												minZoom={minzoom}
											/>
										</div>

										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'center',
												gap: '20px',
												width: '100%',
												borderTop: '1px solid var(--cl_light_grey)',
												padding: '10px 0px',
											}}>
											<button className="mainBtnInv" style={{ width: '110px', height: '40px', border: '.5px solid var(--cl_light_grey)' }}
												onClick={triggerFileSelectPopup}> Choose </button>
											<button
												className='mainBtn'
												style={{ width: '110px', height: '40px', marginRight: '0px', border: 'none' }}
												onClick={save_fx}>
												Save Picture
											</button>
										</div>
									</>
								) : null
								//   <div className="profile-update-dp" style={(change=== 'group' || change === 'business' || change === 'groupicon')?{borderRadius:'50%'}:{}}>
								//     <img src={currentDp} alt="Cropped_image" className="Profile-update-PageDpImg " style={(change=== 'group' || change === 'business'|| change === 'groupicon')?{borderRadius:'50%'}:{}}/>
								//   </div>
							}

							<input type='file' accept='image/*' id='userdpedit__2' onChange={onSelectFile} style={{ display: 'none' }} />
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div style={{ padding: '20px', backgroundColor: 'white', height: '100%' }}>
							<div
								className='AddEventContentTitle'
								style={{ paddingBottom: '0px', height: '60px', justifyContent: 'space-between', alignItems: 'center' }}>
								<h4> {addNew ? 'Add New Profile' : 'Choose an business profile'} </h4>
								<span>
									<MdPersonAddAlt1
										onClick={() =>{ setAddNew(true) ;setalternateProfile(null)}}
										style={{ color: 'var(--cl_orange)', width: '35px', height: '35px', cursor: 'pointer' }}
									/>
								</span>
							</div>

							<input
								type='text'
								className='AddEventSectInput'
								autoFocus
								onChange={(e) => {
									SearchProfile(e.target.value);
								}}
							/>

							<div
								className='invitesect2'
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									flexWrap: 'nowrap',
									height: '70vh',
									overflow: 'auto',
								}}>
								{!loading && error === ''
									? userList.map((item: any, key: number) => {
										return (
											<InviteItem
												user={item}
												k={key}
												check={false}
												alternateProfile={alternateProfile}
												setalternateProfile={setalternateProfile}
											/>
										);
									})
									: loading && error !== ''
										? [...Array(6)].map((item, key) => {
											return <div className='AddEventUserItem skeleton-loader'></div>;
										})
										: null}
							</div>
							<div
								style={{
									display: 'flex',
									alignSelf: 'flex-end',
									gap: '10px',
									padding: '20px 0px 0px 0px',
									justifyContent: 'flex-end',
								}}>
								{/* <button style={{ minWidth: '100px' }} className='mainBtnInv' onClick={clear}>
						Reset
					</button> */}

								<button style={{ minWidth: '100px' }} className={'mainBtn'} onClick={handleClose}>
									Save
								</button>
							</div>
						</div>
					</React.Fragment>
				)}
			</ModalLayout>
			{/* {openImage && <ProfileDialog
				dialogStatus={openImage}
				dialogAction={setOpenImage}
				// fetchProfile={fetchProfile}
				setChange={setChange}
				change={'business'}
				currentDp={change ? window.URL.createObjectURL(new Blob([change])) : 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/default-profile.png'}
			/>
			} */}
		</>
	);
};

export default SelectProfileModal;

const InviteItem = ({
	user,
	k,
	check,
	alternateProfile,
	setalternateProfile
}: {
	user: any;
	k: number;
	check: boolean;
	alternateProfile : any;
	setalternateProfile : any
}) => {
	const { dp, firstname, lastname, id, username } = user;
	// const [checked, setchecked] = useState(check);

	return (
		<div className='AddEventUserItem'>
			<div className='AddEventUserSec2'>{username}</div>
			<div className='AddEventUserSec1'>
				<div
					className={ (id === alternateProfile?.id )? 'lgbox checked' : 'lgbox notchecked'}
					onClick={(e) => {
						// setchecked(!checked);

						// selectProfile((prev: any) => (prev === id ? null : id));
						setalternateProfile((prev: any) => (prev?.id === id ? null : user));

					}}>
					{(id === alternateProfile?.id ) && <Tick />}
				</div>
				<img src={dp} alt='P_I_C' className='AddEventUserDpImg' />

				<div className='AddEventUsrName'>{firstname + ' ' + lastname}</div>
			</div>
		</div>
	);
};
