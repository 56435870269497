import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router';
import MiniEventDetail from '../../smallcomponents/minieventdetail/MiniEventDetail';
import { NavLink } from 'react-router-dom';
import './posts.css'
import All from './components/All';
import Photos from './components/Photos';
import Videos from './components/Videos';
import { useAppSelector } from '../../../app/hooks';
import { errors } from '../../../Data/Error';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";

const Posts = () => {
    const { eventId }: any = useParams()
    const history = useHistory()
    const { path } = useRouteMatch()
    const location = useLocation()

    const { loading, error } = useAppSelector(state => state.event)

    // function handleProfile(user: any) {
    //     history.push(`/user/${user}`);
    // }
    return (
        <div className="EventMediaLayout container minvh100">
            <MiniEventDetail mode={'post'} />
            <div className='menuContainer'>
                <div className='navContainer'>
                    <NavLink className='links subnavLink' to={`/event/${eventId}/posts/all`} activeClassName="subnavLinkActive">
                        All
                    </NavLink>
                    <NavLink className='links subnavLink' to={`/event/${eventId}/posts/photos`} activeClassName="subnavLinkActive">
                        Photos
                    </NavLink>
                    <NavLink className='links subnavLink' to={`/event/${eventId}/posts/videos`} activeClassName="subnavLinkActive">
                        Videos
                    </NavLink>
                </div>

            </div>
            <div className='contentContainer'>
                {/* <TransitionGroup style={{ width: '100%' }}>
                    <CSSTransition
                        key={location.key}
                        classNames="page"
                        timeout={300}
                    > */}

                        {/* <Switch> */}
                        <Switch>
                            <Route path={'/event/:eventId/posts/all'}>
                                {(!loading && error === null) && <All />}
                                {loading &&
                                    <div className='mediaGrid'>
                                        {[...Array(10)].map(() => {
                                            return <div className='videoTileContainer skeleton'></div>
                                        })}
                                    </div>
                                }
                                {
                                    <div className='mediaGrid'>
                                        {error !== null &&
                                            (error.status === null ? (
                                                <div className='error noData min400px' >
                                                    <div className='errormsg'>
                                                        <Nointernet />
                                                        <h2>{errors.internet.heading}</h2>
                                                        <span>{errors.internet.message}</span>
                                                        {/* <button className='mainBtn'>Refresh the page</button> */}
                                                    </div>
                                                </div>
                                            ) : error.status === 500 ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <Server />
                                                        <h2>{errors.server.heading}</h2>
                                                        <span>{errors.server.message}</span>
                                                        {/* <button className='mainBtn'>Try again</button> */}
                                                    </div>
                                                </div>
                                            ) : error.status === 401 ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <NotFound />
                                                        <h2>{errors.notFound.heading}</h2>
                                                        <span>{errors.notFound.message}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <NotFound />
                                                        <h2>{errors.common.heading}</h2>
                                                        <span>{errors.common.message}</span>
                                                        {/* <button className='mainBtn'>Refresh the page</button> */}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </Route>
                            <Route path={`/event/:eventId/posts/photos`}>

                                {(!loading && error === null) && <Photos />}
                                {loading &&
                                    <div className='mediaGrid'>
                                        {[...Array(10)].map(() => {
                                            return <div className='videoTileContainer skeleton'></div>
                                        })}
                                    </div>
                                }
                                {
                                    <div className='mediaGrid'>
                                        {error !== null &&
                                            (error.status === null ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <Nointernet />
                                                        <h2>{errors.internet.heading}</h2>
                                                        <span>{errors.internet.message}</span>
                                                        {/* <button className='mainBtn'>Refresh the page</button> */}
                                                    </div>
                                                </div>
                                            ) : error.status === 500 ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <Server />
                                                        <h2>{errors.server.heading}</h2>
                                                        <span>{errors.server.message}</span>
                                                        {/* <button className='mainBtn'>Try again</button> */}
                                                    </div>
                                                </div>
                                            ) : error.status === 401 ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <NotFound />
                                                        <h2>{errors.notFound.heading}</h2>
                                                        <span>{errors.notFound.message}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <NotFound />
                                                        <h2>{errors.common.heading}</h2>
                                                        <span>{errors.common.message}</span>
                                                        {/* <button className='mainBtn'>Refresh the page</button> */}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </Route>
                            <Route path={`/event/:eventId/posts/videos`}>
                                {(!loading && error === null) && <Videos />}
                                {loading &&
                                    <div className='mediaGrid'>
                                        {[...Array(10)].map(() => {
                                            return <div className='videoTileContainer skeleton'></div>
                                        })}
                                    </div>
                                }
                                {
                                    <div className='mediaGrid'>
                                        {error !== null &&
                                            (error.status === null ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <Nointernet />
                                                        <h2>{errors.internet.heading}</h2>
                                                        <span>{errors.internet.message}</span>
                                                        {/* <button className='mainBtn' onClick={Refresh}>Refresh the page</button> */}
                                                    </div>
                                                </div>
                                            ) : error.status === 500 ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <Server />
                                                        <h2>{errors.server.heading}</h2>
                                                        <span>{errors.server.message}</span>
                                                        {/* <button className='mainBtn' onClick={Refresh}>Try again</button> */}
                                                    </div>
                                                </div>
                                            ) : error.status === 401 ? (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <NotFound />
                                                        <h2>{errors.notFound.heading}</h2>
                                                        <span>{errors.notFound.message}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='error noData min400px'>
                                                    <div className='errormsg'>
                                                        <NotFound />
                                                        <h2>{errors.common.heading}</h2>
                                                        <span>{errors.common.message}</span>
                                                        {/* <button className='mainBtn' onClick={Refresh}>Refresh the page</button> */}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                }
                            </Route>

                            <Redirect from={path} to={`/event/${eventId}/posts/all`} />
                        </Switch>
                    {/* </CSSTransition>
                </TransitionGroup> */}
            </div>

        </div>
    )
}

export default Posts
