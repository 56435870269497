import {   createSlice,   } from '@reduxjs/toolkit';
import { OtherProfileState } from './interface';

const initialState: OtherProfileState = {
    loading: true,
    profile: {},
    status: false,
    events: [],
    stories: [],
    error: null,
    media: [],
    medialoading: false,
    mediapage: 1,
    mediapagination: true,
    mediaerror: null,
};

export const otherProfileSlice = createSlice({
    name: 'userprofile',
    initialState,
    reducers: {
        userprofileRequest: (state) => {
            state.loading = true;
        },
        userprofileSuccess: (state, action) => {
            state.loading = false;
            state.profile = action.payload;
            state.media = action.payload.media;
            state.events = action.payload.events;
            state.stories = action.payload.stories;
            state.status = action.payload.public_status;
            state.error = null;
        },
        userprofileFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        userchangestat: (state, action) => {
            switch (action.payload.type) {
                case 'block':
                    let per = state.profile.permissions
                    if (per) {
                        if(state.profile.is_following){
                            if(per.is_blocked){
                                state.profile.is_following = false
                            }
                        }
                        per.is_blocked = !per.is_blocked
                        
                    }
                    break;
                case 'follow':
                    state.profile.is_following = action.payload.following;
                    state.profile.follow_request = action.payload.req;
                    break;

                default:
                    break;
            }
        },
        userprofilemediarequest: (state) =>{
            state.medialoading = true;
            state.mediaerror = null;
        },
        userprofilemediasuccess: (state,action: any)=>{
            state.medialoading = false;
            state.media =  action.payload.no === 2 ? action.payload.data :state.media.concat(action.payload.data);
            state.mediapage = action.payload.no;
            state.mediapagination = action.payload.pagination;
            state.mediaerror= null;
        },
        userprofilemediafailed: (state,action: any)=>{
            state.medialoading = false;
            state.mediaerror = action.payload
        }
    },
})

export const { userprofileRequest, userprofileSuccess, userprofileFailed,userchangestat,
    userprofilemediarequest,userprofilemediasuccess,userprofilemediafailed } = otherProfileSlice.actions
export default otherProfileSlice.reducer