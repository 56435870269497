import React, { useEffect, useRef, useState } from 'react';
import { mine, your } from './dummydata';
import MiniEventTabs from '../../smallcomponents/minieventtabs/MiniEventTabs';
import MapImage from '../../smallcomponents/mapimage/MapImage';
import MapLocation from '../../smallcomponents/maplocation/MapLocation';
import EventTile from '../../smallcomponents/eventtile/EventTile';
import { ReactComponent as Cancel } from '../../../assets/svg/Cancel.svg';
import { ReactComponent as CancelIcon } from '../../../assets/svg/cancelIcon.svg';

import { ReactComponent as DashboardIcon } from '../../../assets/svg/Dashboard.svg';
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Invite } from '../../../assets/svg/invite.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import { ReactComponent as Dat } from '../../../assets/svg/eventdate.svg';
import './event.css';
import { useHistory, useParams } from 'react-router-dom';
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router';
import Api from '../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { eventSuccess, eventFailed, eventRequest, updaterating, subscriptionchange, likeEvent, generateQrSucess } from './store/eventSlice';
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader';
import Rating from 'react-rating';
import { ReactComponent as Star1 } from '../../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../../assets/svg/star2.svg';
import dayjs from 'dayjs';
import { Scrollbars } from 'react-custom-scrollbars-2';
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader';
import Posts from '../posts/Posts';
import Views from '../views/Views';
import EventAttending from '../eventAttending/EventAttending';
import ParticipantsPage from '../participants/ParticipantsPage';
import CancelEvent from './components/CancelEvent';
import Dashboard from './components/Dashboard';
import { CircularProgress } from '@material-ui/core';
import { LoadScript } from '@react-google-maps/api';
import { editcreateStart } from '../../Tabs/addevent/store/editEventSlice';
import { baseurl, noData } from '../../../Data/Data';
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Padlock } from '../../../assets/svg/padlock.svg';
import { errors } from '../../../Data/Error';
import { Filter } from '../../../Data/Data';
import InvitePeople from '../../smallcomponents/Invitemorepeople/InvitePeople';
import SingleImageModal from '../../smallcomponents/modals/SingleImageModal';
import { ReactComponent as Ticketicon } from '../../../assets/svg/ticket.svg';
import BuyEventtickets from './components/BuyEventtickets';
import EventFooter from './components/EventFooter';
import EventShareModal from '../../smallcomponents/modals/EventShareModal';
import MiniEventReview from './components/MiniEventReview';
import EventReviews from './components/EventReviews';
import EventTickets from './components/EventTickets';

import PostPreview from './components/PostPreview';
// import CheckIcon from '@mui/icons-material/Check';
// import SaveIcon from '@mui/icons-material/Save';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import utc from 'dayjs/plugin/utc';
import Footer from '../../footer/Footer';
import { FailedToast, SucessToast } from '../../utils/toasts';
import ViewClaimModal from './../../Tabs/claimableEvents/ViewClaimModal';
import BuyEventTicketModal from './components/BuyEventTicketModal';
import SimpleModal from '../../smallcomponents/modals/MediaModal1';
import WarningModal from '../../smallcomponents/modals/Warning';
import { ReactComponent as WhiteLike } from '../../../assets/svg/WhiteLike.svg';
import { ReactComponent as OrangeLike } from '../../../assets/svg/orange_like.svg';
import RSVP from './components/RSVP';
import { AiOutlineQrcode } from 'react-icons/ai';
import { RWebShare } from 'react-web-share';
import QRcodeModal from './QRcodeModal';
import { ImGift } from 'react-icons/im';
import { IoTicketSharp } from 'react-icons/io5';
import { MdRsvp ,MdArticle} from 'react-icons/md';
import Auth from '../../../app/auth';
import axios from 'axios';
import AttendeeListModal from './components/AttendeeListModal';
dayjs.extend(utc);

const libraries: any = ['places'];

const Event = () => {
	const history = useHistory();
	const { path } = useRouteMatch();
	const { eventId }: any = useParams();
	let location = useLocation();
	const dispatch = useAppDispatch();

	const [value, setValue] = React.useState(null);
	const [reviewload, setreviewload] = useState(false);
	const [reviewvisible, setreviewvisible] = useState(false);

	const [claimload, setclaimload] = useState(false);
	const [alredyClaimed, setalredyClaimed] = useState(false);

	const [openinvite, setinvite] = useState(false);
	const [openSingle, setOpenSingle] = useState(false);
	const [currentimage, setcurrent] = useState<any>();

	const [galleyIndex, setgalleyIndex] = useState<number>(0);
	const [qrloading, setqrloading] = useState<boolean>(false);

	const success = (data: any) => {
		dispatch(eventSuccess(data));
		console.log(data);
	};
	const failed = (data: any) => {
		console.log(data);
		dispatch(eventFailed(data));
	};

	const { event, error, loading, prevdata } = useAppSelector((state) => state.event);

	const { profile: prof } = useAppSelector((state) => state.profile);
	const isSuperAdmin = useAppSelector((state) => state?.profile?.profile?.is_super_admin);

	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);
	const Refresh = () => {
		dispatch(eventRequest());
		let theurl = `/api/v1/event/${eventId}/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/${eventId}/?auth_profile=${parseddetails[0].id}`;
			}
		}
		return Api('get', theurl, null, success, failed, history, true, true);
	};

	const [allPost, setallPost] = useState<any>([]);
	const ViewPostFx = (id: any) => {
		const success = (data: any) => {
			console.log('DATA', data);

			setallPost(data);
		};
		const failed = (data: any) => {};

		// dispatch(updaterating(2))
		let theurl = `/api/v1/post/event/all/${id}/1/8/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/post/event/all/${id}/1/8/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('get', theurl, null, success, failed, history, true, true);
	};

	const {
		event_image,
		latitude,
		longitude,
		similar_events,
		name,
		description,
		total_rating,
		start_date,
		end_date,
		assigned_role,
		invited,
		invited_count,
		attending,
		attending_count,
		cancel_status,
		cancel_message,
		permissions,
		creator,
		is_reviewed,
		review_rating,
		organiser,
		tags,
		category,
		id,
		public_status,
		main_category,
		slug,
		location: maplocation,
		location_image,
		start_timezone,
		end_timezone,
		tickets,
		only_invited_guests,
		subscribers_count,
		review_rating_list,
		review_rating_count,
		is_claimable,
		alternate_profile,
		profile,
		review_info,
		repeat_event,
		like_details,
		qr_code_url,
		shop_details,
		custom_form,
	} = event;

	useEffect(() => {
		window.scrollTo(0, 0);
		// if((Object.keys(event).length === 0)){
		dispatch(eventRequest());
		let theurl = `/api/v1/event/${eventId}/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/${eventId}/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('get', theurl, null, success, failed, history, true, true);

		ViewPostFx(eventId);
		// }
	}, [eventId]);
	var ifConnected = window.navigator.onLine;
	const [initial, setinitial] = useState(total_rating);
	const [reviewed, setreviewed] = useState(is_reviewed);
	const refalert = useRef(null);
	console.log('########################################## event ###################################', like_details);

	const ReviewEvent = (value: any) => {
		console.log(value);
		let target: any = refalert.current;
		let perm = permissions?.write_review;
		console.log(perm);
		if (is_reviewed === false) {
			// Review(newValue)
			// console.log(is_reviewed === false && perm)
			// setinitial(value)
			dispatch(updaterating(value));
			setreviewvisible(true);
			setValue(value);
			// PostReview()
		} else {
			console.log(refalert);
			setinitial(total_rating);
			if (target) {
				target.style.display = 'block';
				setTimeout(() => {
					target.style.display = 'none';
				}, 3000);
			}
		}
	};

	const [reviewMessage, setMessage] = useState('');
	const success1 = (data: any) => {
		setreviewload(false);
		setreviewvisible(false);
		setMessage('');
		setinitial(total_rating);
		console.log(data);
		dispatch(updaterating(data.data.total_rating));
	};
	const failed1 = (data: any) => {
		console.log(data);
		setreviewload(false);
		setreviewvisible(false);
		setMessage('');
		setinitial(total_rating);
	};
	function PostReview() {
		let data = {
			'event': event.id,
			'rating': value,
			'review': reviewMessage,
		};
		// dispatch(updaterating(2))
		let theurl = `/api/v1/event/review/write/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/review/write/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('post', theurl, data, success1, failed1, history, true, true);
	}
	const [subloading, setsubloading] = useState(false);
	let er: any = {};
	const [suberror, setsuberror] = useState(er);
	const subsuccess = (data: any) => {
		console.log(data);
	};
	const subfailed = (data: any) => {
		console.log(data);
		setsuberror(data);
	};
	const EventSubscribe = () => {
		// Api
	};

	const [index, setindex] = useState(0);
	const [open, setOpen] = useState(false);

	const [openRSVP, setOpenRSVP] = useState(false);


	const [attendeeModal, setattendeeModal] = useState(false);


	const handleCloseRSVP = () => setOpenRSVP(false);

	const handleClose = () => setOpen(false);

	const [modalActive, setModalActive] = useState(false);

	const [galleryView, setgalleryView] = useState(false);

	const ClaimEventFx = (id: any) => {
		const success = (data: any) => {
			console.log('DATA');
			setclaimload(false);
			setalredyClaimed(true);
			SucessToast('Claim request send successfully');
		};
		const failed = (data: any) => {
			console.log(data);
			setclaimload(false);
			FailedToast(data?.dev_data);
		};

		let data = {
			'event': id,
		};
		// dispatch(updaterating(2))
		let theurl = `/api/v1/event/claim/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/claim/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('post', theurl, data, success, failed, history, true, true);
	};

	const [ViewClaims, setViewClaims] = useState(false);
	const ViewClaimClose = () => setViewClaims(false);

	const [open2, setopen2] = useState(false);

	let w: any = {
		head: '',
		message: '',
		button: 'Okay',
		link: null,
	};
	const [war, setwar] = useState(w);
	const DeleteEventsuccess = (data: any) => {
		// console.log(data)
		// setsc({
		//     head: 'Your account has been DeleteEventd',
		//     message: '',
		//     button: 'Okay',
		//     link: null
		// })
		// setopen(true)
		// setTimeout(() => {
		//     setopen(false)
		//     setsc(null)
		//     handleClick()
		//     history.push('/login');
		// }, 3000);
		history.push('/profile');
	};
	const DeleteEventfailed = (data: any) => {
		// console.log(data)
		// const {
		//     status
		// } = data;
		// let head;
		// let message;
		// if (status === null) {
		//     head = 'Check your internet connection and try again.';
		//     message = "We couldn't connect to the server"
		// } else if (status === 500) {
		//     head = 'Somethings wrong with our Server!';
		//     message = 'Please be patient while we try to fix it.'
		// } else {
		//     head = "Something's not right.";
		//     message = 'please try again later'
		// }
		// seter({
		//     head: head,
		//     message: message,
		//     button: 'Okay',
		//     link: null
		// })
		// setTimeout(() => {
		//     setopen1(false)
		//     seter(null)
		// }, 5000);
		// setopen1(true)
	};
	const DeleteEvent = () => {
		setwar({
			head: 'Are you sure?',
			message: 'Do you want to delete this event?',
			button: 'Confirm',
			button1: 'Cancel',
			link: null,
		});
		setopen2(true);
	};

	const DeleteEventFx = () => {
		let crnt: any = localStorage.getItem('mtusr');
		let current = JSON.parse(crnt);

		let c: any = localStorage.getItem('pdetails');
		let parsedc = JSON.parse(c);
		// {{baseurl}}/api/v1/event/delete/254/?auth_profile=52
		let url = current.is_business_profile
			? `/api/v1/event/delete/${event?.id}/&auth_profile=${parsedc[0].id}`
			: `/api/v1/event/delete/${event?.id}/`;
		Api('delete', url, null, DeleteEventsuccess, DeleteEventfailed, history, true, true);
	};
	const [eventLiked, setEventLiked] = useState<any>(like_details?.is_liked);
	const [eventLikeCount, setEventLikeCount] = useState<any>(like_details?.like_count);
	useEffect(() => {
		setEventLikeCount(like_details?.like_count);
		setEventLiked(like_details?.is_liked);
	}, [like_details]);
	// useEffect(() => {
	// 	setEventLikeCount(like_details?.like_count)
	// 	setEventLiked(like_details?.is_liked)

	// })

	const eventLikeSuccess = (res: any) => {
		console.log('eeeeeeeevent like count', like_details);

		// setEventLikeCount(Number(eventLikeCount + 1))
		// setEventLiked(true)
		dispatch(
			likeEvent({
				eventIsLike: like_details?.is_liked,
				eventLikeCount: like_details?.like_count,
			})
		);
	};
	const eventLikeFailed = () => {
		setEventLikeCount(like_details?.like_count);
	};

	const eventLike = () => {
		// setEventLiked(!like_details?.is_liked);
		// if (eventLiked) {
		// 	setEventLikeCount(Number(like_details?.like_count) - 1)
		// } else {
		// 	setEventLikeCount(Number(like_details?.like_count) + 1)
		// }
		let url = '/api/v1/event/like/';

		let data = {
			'event': id,
		};

		// setEventLikeCount(Number())
		Api('post', url, data, eventLikeSuccess, eventLikeFailed, history, true, true);
	};

	const generateQRcode = () => {
		setqrloading(true);
		let url = `/api/v1/event/share/qr/${slug}/`;
		const sucess = (res: any) => {
			setqrloading(false);

			dispatch(
				generateQrSucess({
					qr_code_url: res?.qr_url,
				})
			);
		};
		const failed = () => {
			setqrloading(false);
		};

		Api('get', url, '', sucess, failed, history, true, true);
	};



	// {{baseurl}}/api/v1/event/list/rsvp-pdf/b07717f9-9389-4a2a-a198-671acec29f27/


	const [qrModal, setQrModal] = useState(false);

	const URL = process.env.REACT_APP_SHARE_URL || '';

	const currentURL = URL + `/event/${slug}`;


	return (
		<>
			<Switch>
				<Route exact path={`/event/:eventId`}>
					{error === null ? (
						loading === false ? (
							cancel_status ? (
								<div className='event container'>
									<div className='eventImage'>
										{event_image
											? event_image.slice(0, 1).map((item: any, key: number) => {
													const { image } = item;
													return (
														<img
															alt='event_imagecover_image'
															src={image}
															onClick={() => {
																setcurrent(image);
																setOpenSingle(true);
															}}
														/>
													);
											  })
											: null}
									</div>
									<div className='eventContent min500px !tw-block md:!tw-grid'>
										<div className='eventcol1'>
											<h2>{'Event ' + name + ' has been canceled'}</h2>
											<div className='eventUserDiv'>
												<img alt='P_I_C' src={is_claimable ? alternate_profile?.dp : profile?.dp} />
												<span>
													by{' '}
													{is_claimable
														? alternate_profile?.firstname
														: profile?.firstname + '' + profile?.surname}
												</span>
											</div>
											<div className='eventDescription'>
												<p>{cancel_message}</p>
											</div>
										</div>
									</div>
									<div className='similarEvents'>
										<div className='commonHeading'>
											<h2>
												Similar events
												<Arrow />
											</h2>
										</div>
										<div className='eventGrid'>
											{similar_events
												? Object.keys(similar_events).length === 0
													? !loading && (
															<div className='noData'>
																<Warning />
																<h2>{noData.similar.heading}</h2>
																<span>{noData.similar.message}</span>
															</div>
													  )
													: similar_events.map((item: any, key: number) => {
															return <EventTile event={item} k={key} />;
													  })
												: null}
										</div>
									</div>
								</div>
							) : (
								<div className='event container'>
									{modalActive && (
										<EventShareModal data={event} open={modalActive} setOpen={setModalActive} type={'event'} />
									)}
									<div className='eventImage'>
										{event_image
											? event_image.slice(0, 1).map((item: any, key: number) => {
													const { image } = item;
													return (
														<img
															alt='Event cover_image'
															src={image}
															onClick={() => {
																setcurrent(image);
																setOpenSingle(true);
															}}></img>
													);
											  })
											: null}
									</div>
									<div className='eventContent !tw-block md:!tw-grid'>
										<div className='eventcol1'>
											<h2>{name}</h2>
											<div className='eventUserDiv '>
												<img alt='P_I_C' src={is_claimable ? alternate_profile?.dp : profile?.dp}></img>
												<span
													className='eventprofilename cursor'
													onClick={() => {
														if (profile?.id === prof.id) {
															history.push('/profile');
														} else {
															history.push(`/user/${profile?.username}`);
														}
													}}>
													by{' '}
													{is_claimable
														? alternate_profile?.firstname
														: profile?.firstname + '' + profile?.surname}
												</span>
	

												{(permissions?.write_review || permissions?.read_review) && (
													<div className='ratingpreview'>
														<div
															className='eventUsrSub'
															style={{
																display: 'flex',
																alignItems: 'center',
																gap: '5px',
																maxWidth: '150px',
															}}>
															<span>{total_rating + '.0/5.0'}</span>
															<Rating
																initialRating={total_rating}
																emptySymbol={<Star2 />}
																fullSymbol={<Star1 />}
																readonly
															/>
														</div>
														<small
															ref={refalert}
															style={{ display: 'none', color: 'var(--cl_orange' }}
															className='signupvalidate'>
															You have already reviewed this event or it has been blocked!
														</small>
													</div>
												)}
											</div>
											{permissions?.can_view && (
											<div
													onClick={eventLike}
													style={{
														height: '48px',
														width: '100%',
														backgroundColor: like_details?.is_liked
															? 'var(--cl_orange)'
															: 'var(--cl_light_orange)',
														borderRadius: '12px',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
													}}>
													{like_details?.is_liked ? <WhiteLike /> : <OrangeLike />}
													<span
														style={{
															color: like_details?.is_liked ? 'white' : 'var(--cl_orange)',
															marginLeft: '10px',
														}}>{`${like_details?.like_count} ${
														like_details?.like_count === 1 ? 'like' : 'likes'
													}`}</span>
												</div>)}







<div className=" tw-w-full  tw-flex  tw-py-2 md:tw-hidden">

{ifConnected && latitude && longitude ? (

	<MapLocation
		latitude={JSON.parse(latitude)}
		longitude={JSON.parse(longitude)}
		locationtext={maplocation}
		location_image={location_image}
		eventpage={true}
	/>
) : (
	latitude && longitude && <div className='singleeventmapscontainer skeleton'></div>
)}
	</div>





											{permissions?.can_view && (
												<div className='eventDescription'>
													<p>{description}</p>
												</div>
											)}
											{(permissions?.write_review || permissions?.read_review) && (
												<MiniEventReview
													permissions={permissions}
													reviewList={review_rating_list}
													count={review_rating_count}
													review_info={review_info}
												/>
											)}


											





{/* ##################################################################################################################################################### */}






											<div className="tw-flex   md:!tw-hidden">
												{permissions?.can_view && (
<>
<div className='tw-flex tw-flex-col tw-w-full   tw-gap-2'>

											
												<MiniEventTabs active={'none'} />

											
										
										


												{permissions?.rsvp && (
													<div className='eventCancel !tw-w-full' onClick={() => setOpenRSVP(true)}>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
															<MdRsvp size={22} className='tw-text-orange-500' />
														</div>
														<div className='eventOptions'>
															<div
																className={
																	permissions?.going
																		? 'mainBtnInv bg-green'
																		: permissions?.cantgo
																		? 'mainBtnInv !tw-bg-red-500 !tw-text-white'
																		: 'mainBtnInv'
																}
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	fontWeight: 500,
																	fontSize: '.85rem',
																}}>
																{permissions?.going
																	? 'Your are going'
																	: permissions?.cantgo
																	? `Can't go`
																	: 'Update RSVP'}
															</div>
														</div>
													</div>
												)}

												{permissions?.buy_ticket && (
													<div className='eventCancel' onClick={() => setOpen(true)}>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
															<IoTicketSharp size={22} className='tw-text-orange-500' />
														</div>

														<div className='eventOptions'>
															<h5>Get your tickets</h5>
															<span>Collect yout tickets.</span>
														</div>

														{/* <div
															className='mainBtn'
															style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
															onClick={() => {
																setOpen(true);
															}}>
															Buy tickets
														</div> */}
													</div>
												)}
										
												{/* </LoadScript> */}
												<div className='eventDate !tw-w-full'>
													<Dat />
													<div className='eventDateDetailed'>
														<h5>
															{dayjs.utc(start_date).local().format('DD') ===
															dayjs.utc(end_date).local().format('DD')
																? dayjs.utc(start_date).local().format('ddd, DD MMM YYYY')
																: dayjs.utc(start_date).local().format('ddd, DD MMM') +
																  ' - ' +
																  dayjs.utc(end_date).local().format('ddd, DD MMM YYYY')}
														</h5>
														<span>
															{dayjs.utc(start_date).local().format('hh:mm A') +
																' - ' +
																dayjs.utc(end_date).local().format('hh:mm A')}
														</span>
														{start_timezone && end_timezone && (
															<span>
																{start_timezone === end_timezone
																	? start_timezone
																	: start_timezone - end_timezone}
															</span>
														)}
													</div>
												</div>

												{is_claimable && permissions?.claim_event && currentparsed?.is_business_profile && (
													<div className='buyticket_event_2'>
														{/* <h2>Claim Event</h2> */}

														<div
															className='mainBtn'
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																pointerEvents: alredyClaimed ? 'none' : 'all',
															}}
															onClick={() => {
																// history.push(`/event/${eventId}/tickets`)
																// setOpen(true)
																ClaimEventFx(event?.id);
															}}>
															{permissions?.claim_request || alredyClaimed
																? 'Claim Requested'
																: 'Claim Event'}
														</div>
													</div>
												)}
												{is_claimable && isSuperAdmin && (
													<div className='buyticket_event_2'>
														{/* <h2>Claim Requests</h2> */}

														<div
															className='mainBtn'
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																pointerEvents: alredyClaimed ? 'none' : 'all',
															}}
															onClick={() => {
																setViewClaims(true);
															}}>
															View Claims
														</div>
													</div>
												)}
												<div className='eventAssigned !tw-w-full'>
													<h3>Assigned Role</h3>
													<div className='eventAssignedUsers'>
														{assigned_role && !(Object.keys(assigned_role).length === 0) ? (
															<Scrollbars style={{ height: 136 }}>
																{assigned_role.map((item: any, key: number) => {
																	const { firstname, dp, id, role, surname, username, status } =
																		item;
																	console.log('Assigned Role :', item);
																	return (
																		<div
																			className='featuredprofile'
																			id={`${key}`}
																			onClick={() => {
																				// history.push(`/user/${id}`);
																				if (profile?.id === prof.id) {
																					history.push('/profile');
																				} else {
																					history.push(`/user/${username}`);
																				}
																			}}>
																			<img alt='P_I_C' src={dp}></img>
																			<div className='featuredNames'>
																				<h3>
																					{role}{' '}
																					<span
																						style={{
																							paddingLeft: '3px',
																							color: 'var(--cl_orange)',
																							fontStyle: 'italic',
																						}}>
																						{status === 'pending' && '(Pending)'}
																					</span>
																				</h3>
																				<span>{'@' + username}</span>
																			</div>
																		</div>
																	);
																})}
															</Scrollbars>
														) : (
															<div className='notAssigned'>
																<span>No roles have been assigned yet</span>
															</div>
														)}
													</div>
												</div>
												<div className='eventParticipants !tw-w-full'>
													<h3>
														Participants
														<a
															onClick={() => {
																history.push(`/event/${eventId}/participants`);
															}}>
															See all
														</a>
													</h3>
													<div className='eventParticipantsDp '>
														{attending && !(Object.keys(attending).length === 0) ? (
															attending.slice(0, 5).map((item: any, key: number) => {
																const { id, dp, firstname, surname, username } = item;
																return (
																	<img
																		alt='P_I_C'
																		src={dp}
																		title={firstname + ' ' + surname}
																		className='cursor'
																		onClick={() => {
																			// history.push(`/user/${id}`)
																			if (profile?.id === prof.id) {
																				history.push('/profile');
																			} else {
																				history.push(`/user/${username}`);
																			}
																		}}
																	/>
																);
															})
														) : (
															<span className='no'>There are no participants</span>
														)}
														{attending_count && attending_count > 5 ? (
															<div className='eventParticipantsCount'>
																<span>
																	{attending_count > 5 ? '+' + (attending_count - 5) : null}
																</span>
															</div>
														) : null}
													</div>
												</div>
												{permissions?.can_view && shop_details && (
													<a
														target='_blank'
														rel='noreferrer '
														href={
															shop_details?.shop_link && shop_details?.shop_link.startsWith('https://')
																? shop_details?.shop_link
																: `https://${shop_details?.shop_link}`
														}>
														<div className='eventDashboard !tw-w-full'>
															<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
																<ImGift size={22} style={{ color: 'var(--cl_orange)' }} />
															</div>
															<div className='eventOptions'>
																<h5>{shop_details?.shop_title}</h5>
																<span>Purchase a gift</span>
															</div>
														</div>
													</a>
												)}
	{permissions?.can_edit && (
													<div
														className='eventDashboard !tw-w-full'
														onClick={() => {
															dispatch(
																editcreateStart({
																	id: id,
																	current: 1,
																	reached: 7,
																	imageid: event_image && event_image[0].id,
																	url: event_image && event_image[0].image,
																	imgtype: 'link',
																	title: name,
																	org: organiser.map((item: any, key: any) => {
																		return item.id;
																	}),
																	tags: tags.map((item: any, key: any) => {
																		return item.id;
																	}),
																	categories:
																		category?.length !== 0
																			? category?.map((item, key) => {
																					return item.id;
																			  })
																			: [],
																	desc: description,
																	rating: review_rating,
																	eventtype: main_category.id,
																	lat: latitude ? JSON.parse(latitude) : '',
																	lng: latitude ? JSON.parse(longitude) : '',
															
																	start: start_date,
																	end: end_date,
																	from: start_date,
																	to: end_date,
																	invites: invited,
																	assigned: organiser,
																	orglist: organiser.map((item: any, keys: any) => {
																		return {
																			username: item.username,
																			firstname: item.firstname,
																			lastname: item.surname,
																			id: item.id,
																			dp: item.dp,
																		};
																	}),
																	taglist: tags.map((item: any, keys: any) => {
																		return {
																			username: item.username,
																			firstname: item.firstname,
																			lastname: item.surname,
																			id: item.id,
																			dp: item.dp,
																		};
																	}),
																	only_invited_guests,
																	is_claimable,
																	current_alternate_profile: alternate_profile,
																	public_status,
																	location: maplocation,
																	repeat_event,
																	start_timezone,
																	end_timezone,
																	tickets,
																	shop_details,
																	custom_form,

																
																})
															);
															history.push(`/${'edit'}`);
														}}>
														<Edit />
														<div className='eventOptions'>
															<h5>Edit event</h5>
															<span>Change some details</span>
														</div>
														<Arrow />
													</div>
												)}
												{permissions?.can_invite && (
													<div
														className='eventCancel !tw-w-full'
														onClick={() => {
															setinvite(true);
														}}>
														<Invite />
														<div className='eventOptions'>
															<h5>Invite more people</h5>
															<span>Make your party huge</span>
														</div>
														<Arrow />
													</div>
												)}
												{creator && (
													<div
														className='eventDashboard !tw-w-full'
														onClick={() => {
															history.push(`/event/${eventId}/dashboard`);
														}}>
														<DashboardIcon />
														<div className='eventOptions'>
															<h5>Dashboard</h5>
															<span>Manage your event</span>
														</div>
														<Arrow />
													</div>
												)}

{creator &&( 

													<div
														className='eventDashboard !tw-w-full'
														onClick={()=>setattendeeModal(true)}

													
														>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
														
																<MdArticle size={22} style={{ color: 'var(--cl_orange)' }} />

															</div>

														
														<div className='eventOptions '>
															<h5>Attendees List</h5>
															<span>Download Attendees list</span>
														</div>
														<Arrow />
													</div>
												)}
												{permissions?.can_cancel && (
													<div
														className='eventCancel !tw-w-full'
														onClick={() => {
															history.push(`/event/${eventId}/cancel`);
														}}>
														<CancelIcon />
														<div className='eventOptions'>
															<h5>Cancel event</h5>
															<span>Let user know why</span>
														</div>
														<Arrow />
													</div>
												)}
												{(qr_code_url || creator) && (
													<div
														className='eventCancel !tw-w-full'
														onClick={() => {
															if (qr_code_url) {
																setQrModal(true);
															} else {
																generateQRcode();
															}
														}}>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
															{qrloading ? (
																<CircularProgress size={22} className='tw-text-orange-500' style={{ color: 'var(--cl_orange)' }} />
															) : (
																<AiOutlineQrcode size={22} className='tw-text-orange-500' />
															)}
														</div>
														{qr_code_url ? (
															<div className='eventOptions'>
																<h5>Scan QR Code</h5>
																<span>Share this event via QR</span>
															</div>
														) : (
															// </RWebShare>
															<div className='eventOptions'>
																<h5>Generate QR Code</h5>
																<span>Share this event via QR</span>
															</div>
														)}

														<Arrow />
													</div>
												)}

												{permissions?.delete_ticket && (
													<div
														className='eventCancel !tw-w-full'
														onClick={() => {
															DeleteEvent();
														}}>
														<Cancel />
														<div className='eventOptions'>
															<h5>Delete event</h5>
															<span>Let user know why</span>
														</div>
														<Arrow />
													</div>
												)}
											</div>
</>
												)
												}
											</div>


{/* ##################################################################################################################################################### */}















<div className="tw-hidden md:tw-flex">
	{permissions && (
												<EventFooter
													id={id}
													permissions={permissions}
													subscribers_count={subscribers_count}
													sharemodal={setModalActive}
												/>
											)}

</div>
											

											{/* <=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=> */}

											<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
												<div className='commonHeading'>
													<h2>
														Gallery <Arrow />
													</h2>
												</div>
												{/* <div style={{ border: "1px solid green", display: "flex", gap: "3px", flexWrap: "wrap", alignItems: "flex-start" ,padding:"10px 0px" ,justifyContent:"flex-start" }}> */}
												<div className='test_grid_layout' style={{ display: 'grid', gridGap: '3px' }}>
													{Array.isArray(allPost) && allPost.length > 0 ? (
														allPost.map((item: any, key) => {
															return (
																<div
																	onClick={() => {
																		setgalleyIndex(key);
																		setgalleryView(true);
																	}}
																	style={{
																		borderRadius: '10px',
																		display: 'grid',
																		// maxWidth: '150px',
																		maxHeight: '180px',
																		width: '100%',
																		height: '100%',
																		overflow: 'hidden',
																		position: 'relative',
																	}}>
																	<img
																		src={item?.post_thumbnail_1}
																		alt=''
																		style={{
																			objectFit: 'cover',
																			width: '100%',
																			height: '100%',
																			borderRadius: '10px',
																		}}
																	/>
																	<div
																		style={{
																			position: 'absolute',
																			zIndex: 1,
																			display: 'flex',
																			gap: '4px',
																			padding: '8px',
																			// justifyContent: 'space-around',
																			bottom: '0px',
																			width: '100%',
																		}}>
																		<div
																			style={{
																				display: 'flex',
																				width: '30px',
																				height: '30px',
																				overflow: 'hidden',
																				borderRadius: '50%',
																				boxShadow:
																					'box-shadow: rgba(255, 255, 255, 0.945) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;',
																			}}>
																			<img
																				style={{
																					objectFit: 'cover',
																					width: '100%',
																					height: '100%',
																				}}
																				src={item?.profile_dp}
																				alt=''
																			/>
																		</div>
																		<div style={{ display: 'flex', alignItems: 'center' }}>
																			<p
																				style={{
																					color: 'white',
																					textShadow: '1px 2px 3px #666',
																					fontSize: '12px',
																					fontWeight: 500,
																				}}>
																				{item?.profile_firstname +
																					' ' +
																					item.profile_surname}
																			</p>
																		</div>
																	</div>
																</div>
															);
														})
													) : (
														<div className='noData' style={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
															<Warning />
															<h2>{noData.similar.heading}</h2>
															<span>{'Event posts are shown here'}</span>
														</div>
													)}
												</div>
											</div>

											{/* <=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=><=> */}
										</div>
										{/*
									//* current buy ticket

										{permissions?.can_view &&
										permissions?.buy_ticket &&
										Array.isArray(event?.tickets) &&
										event?.tickets.length > 0 && (
											<div className='buyticket_event_1'>
												<h2>Buy tickets</h2>
								
												<div
													className='mainBtn'
													style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
													onClick={() => {
														// history.push(`/event/${eventId}/tickets`)
														setOpen(true);
													}}>
													Buy tickets
												</div>
											</div>
										)}


									 */}

										{/* <div className='buyticket_event_1'>

										<div
											className='mainBtn'
											style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											onClick={() => {
												setOpen(true);
											}}>
											RSVP
										</div>
									</div> */}

										{permissions?.can_view && (permissions?.can_edit || permissions?.can_invite) && (
											<div className='eventcol2 !tw-hidden md:!tw-flex'>
												{permissions?.can_edit && (
													<div
														className='eventDashboard'
														onClick={() => {
															dispatch(
																editcreateStart({
																	id: id,
																	current: 1,
																	reached: 7,
																	imageid: event_image && event_image[0].id,
																	url: event_image && event_image[0].image,
																	imgtype: 'link',
																	title: name,
																	org: organiser.map((item: any, key: any) => {
																		return item.id;
																	}),
																	tags: tags.map((item: any, key: any) => {
																		return item.id;
																	}),
																	categories:
																		category?.length !== 0
																			? category?.map((item, key) => {
																					return item.id;
																			  })
																			: [],
																	desc: description,
																	rating: review_rating,
																	eventtype: main_category.id,
																	lat: latitude ? JSON.parse(latitude) : '',
																	lng: latitude ? JSON.parse(longitude) : '',
																	// start: dayjs(start_date).format('YYYY-MM-DD'),
																	// end: dayjs(end_date).format('YYYY-MM-DD'),
																	// from: dayjs(start_date).format('hh:mm'),
																	// to: dayjs(end_date).format('hh:mm'),
																	start: start_date,
																	end: end_date,
																	from: start_date,
																	to: end_date,
																	invites: invited,
																	assigned: organiser,
																	orglist: organiser.map((item: any, keys: any) => {
																		return {
																			username: item.username,
																			firstname: item.firstname,
																			lastname: item.surname,
																			id: item.id,
																			dp: item.dp,
																		};
																	}),
																	taglist: tags.map((item: any, keys: any) => {
																		return {
																			username: item.username,
																			firstname: item.firstname,
																			lastname: item.surname,
																			id: item.id,
																			dp: item.dp,
																		};
																	}),
																	only_invited_guests,
																	is_claimable,
																	current_alternate_profile: alternate_profile,
																	public_status,
																	location: maplocation,
																	repeat_event,
																	start_timezone,
																	end_timezone,
																	// ticketcategories : tickets && tickets.length >= 1 ? tickets.map((item)=>{
																	tickets,
																	shop_details,
																	custom_form,

																	// {
																	// 	"id": 296,
																	// 	"pricing_category": "Paid",
																	// 	"category_name": "DAIMOND",
																	// 	"description": null,
																	// 	"price": 10.0,
																	// 	"ticket_count": 1000,
																	// 	"max_quantity": 1,
																	// 	"available_tickets": 1000,
																	// 	"is_purchased": false,
																	// 	"hide_status": false,
																	// 	"collect_tax_from_users": true,
																	// 	"stripe_product_id": "prod_NNUvvqLhf4wKfd",
																	// 	"stripe_price_id": "price_1McjvHSE73io8AUj9s054ol0"
																	// }

																	// })  : [],
																})
															);
															history.push(`/${'edit'}`);
														}}>
														<Edit />
														<div className='eventOptions'>
															<h5>Edit event</h5>
															<span>Change some details</span>
														</div>
														<Arrow />
													</div>
												)}
												{permissions?.can_invite && (
													<div
														className='eventCancel'
														onClick={() => {
															setinvite(true);
														}}>
														<Invite />
														<div className='eventOptions'>
															<h5>Invite more people</h5>
															<span>Make your party huge</span>
														</div>
														<Arrow />
													</div>
												)}
											</div>
										)}

										{permissions?.can_view && (
											<div className='eventcol3 !tw-hidden md:!tw-grid'>
												<div
													onClick={eventLike}
													style={{
														height: '48px',
														width: '100%',
														backgroundColor: like_details?.is_liked
															? 'var(--cl_orange)'
															: 'var(--cl_light_orange)',
														borderRadius: '12px',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'center',
													}}>
													{like_details?.is_liked ? <WhiteLike /> : <OrangeLike />}
													<span
														style={{
															color: like_details?.is_liked ? 'white' : 'var(--cl_orange)',
															marginLeft: '10px',
														}}>{`${like_details?.like_count} ${
														like_details?.like_count === 1 ? 'like' : 'likes'
													}`}</span>
												</div>
												<MiniEventTabs active={'none'} />

												{permissions?.rsvp && (
													<div className='eventCancel' onClick={() => setOpenRSVP(true)}>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
															<MdRsvp size={22} className='tw-text-orange-500' />
														</div>
														<div className='eventOptions'>
															<div
																className={
																	permissions?.going
																		? 'mainBtnInv bg-green'
																		: permissions?.cantgo
																		? 'mainBtnInv !tw-bg-red-500 !tw-text-white'
																		: 'mainBtnInv'
																}
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'center',
																	fontWeight: 500,
																	fontSize: '.85rem',
																}}>
																{permissions?.going
																	? 'Your are going'
																	: permissions?.cantgo
																	? `Can't go`
																	: 'Update RSVP'}
															</div>
														</div>
													</div>
												)}

												{permissions?.buy_ticket && (
													<div className='eventCancel' onClick={() => setOpen(true)}>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
															<IoTicketSharp size={22} className='tw-text-orange-500' />
														</div>

														<div className='eventOptions'>
															<h5>Get your tickets</h5>
															<span>Collect yout tickets.</span>
														</div>

														{/* <div
															className='mainBtn'
															style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
															onClick={() => {
																setOpen(true);
															}}>
															Buy tickets
														</div> */}
													</div>
												)}

												{ifConnected && latitude && longitude ? (
													<MapLocation
														latitude={JSON.parse(latitude)}
														longitude={JSON.parse(longitude)}
														locationtext={maplocation}
														location_image={location_image}
														eventpage={true}
													/>
												) : (
													latitude && longitude && <div className='singleeventmapscontainer skeleton'></div>
												)}
												{/* </LoadScript> */}
												<div className='eventDate'>
													<Dat />
													<div className='eventDateDetailed'>
														<h5>
															{dayjs.utc(start_date).local().format('DD') ===
															dayjs.utc(end_date).local().format('DD')
																? dayjs.utc(start_date).local().format('ddd, DD MMM YYYY')
																: dayjs.utc(start_date).local().format('ddd, DD MMM') +
																  ' - ' +
																  dayjs.utc(end_date).local().format('ddd, DD MMM YYYY')}
														</h5>
														<span>
															{dayjs.utc(start_date).local().format('hh:mm A') +
																' - ' +
																dayjs.utc(end_date).local().format('hh:mm A')}
														</span>
														{start_timezone && end_timezone && (
															<span>
																{start_timezone === end_timezone
																	? start_timezone
																	: start_timezone - end_timezone}
															</span>
														)}
													</div>
												</div>

												{is_claimable && permissions?.claim_event && currentparsed?.is_business_profile && (
													<div className='buyticket_event_2'>
														{/* <h2>Claim Event</h2> */}

														<div
															className='mainBtn'
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																pointerEvents: alredyClaimed ? 'none' : 'all',
															}}
															onClick={() => {
																// history.push(`/event/${eventId}/tickets`)
																// setOpen(true)
																ClaimEventFx(event?.id);
															}}>
															{permissions?.claim_request || alredyClaimed
																? 'Claim Requested'
																: 'Claim Event'}
														</div>
													</div>
												)}
												{is_claimable && isSuperAdmin && (
													<div className='buyticket_event_2'>
														{/* <h2>Claim Requests</h2> */}

														<div
															className='mainBtn'
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																pointerEvents: alredyClaimed ? 'none' : 'all',
															}}
															onClick={() => {
																setViewClaims(true);
															}}>
															View Claims
														</div>
													</div>
												)}
												<div className='eventAssigned'>
													<h3>Assigned Role</h3>
													<div className='eventAssignedUsers'>
														{assigned_role && !(Object.keys(assigned_role).length === 0) ? (
															<Scrollbars style={{ height: 136 }}>
																{assigned_role.map((item: any, key: number) => {
																	const { firstname, dp, id, role, surname, username, status } =
																		item;
																	console.log('Assigned Role :', item);
																	return (
																		<div
																			className='featuredprofile'
																			id={`${key}`}
																			onClick={() => {
																				// history.push(`/user/${id}`);
																				if (profile?.id === prof.id) {
																					history.push('/profile');
																				} else {
																					history.push(`/user/${username}`);
																				}
																			}}>
																			<img alt='P_I_C' src={dp}></img>
																			<div className='featuredNames'>
																				<h3>
																					{role}{' '}
																					<span
																						style={{
																							paddingLeft: '3px',
																							color: 'var(--cl_orange)',
																							fontStyle: 'italic',
																						}}>
																						{status === 'pending' && '(Pending)'}
																					</span>
																				</h3>
																				<span>{'@' + username}</span>
																			</div>
																		</div>
																	);
																})}
															</Scrollbars>
														) : (
															<div className='notAssigned'>
																<span>No roles have been assigned yet</span>
															</div>
														)}
													</div>
												</div>
												<div className='eventParticipants'>
													<h3>
														Participants
														<a
															onClick={() => {
																history.push(`/event/${eventId}/participants`);
															}}>
															See all
														</a>
													</h3>
													<div className='eventParticipantsDp'>
														{attending && !(Object.keys(attending).length === 0) ? (
															attending.slice(0, 5).map((item: any, key: number) => {
																const { id, dp, firstname, surname, username } = item;
																return (
																	<img
																		alt='P_I_C'
																		src={dp}
																		title={firstname + ' ' + surname}
																		className='cursor'
																		onClick={() => {
																			// history.push(`/user/${id}`)
																			if (profile?.id === prof.id) {
																				history.push('/profile');
																			} else {
																				history.push(`/user/${username}`);
																			}
																		}}
																	/>
																);
															})
														) : (
															<span className='no'>There are no participants</span>
														)}
														{attending_count && attending_count > 5 ? (
															<div className='eventParticipantsCount'>
																<span>
																	{attending_count > 5 ? '+' + (attending_count - 5) : null}
																</span>
															</div>
														) : null}
													</div>
												</div>
												{permissions?.can_view && shop_details && (
													<a
														target='_blank'
														rel='noreferrer '
														href={
															shop_details?.shop_link && shop_details?.shop_link.startsWith('https://')
																? shop_details?.shop_link
																: `https://${shop_details?.shop_link}`
														}>
														<div className='eventDashboard'>
															<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
																<ImGift size={22} style={{ color: 'var(--cl_orange)' }} />
															</div>
															<div className='eventOptions'>
																<h5>{shop_details?.shop_title}</h5>
																<span>Purchase a gift</span>
															</div>
														</div>
													</a>
												)}

												{creator && (
													<div
														className='eventDashboard'
														onClick={() => {
															history.push(`/event/${eventId}/dashboard`);
														}}>
														<DashboardIcon />
														<div className='eventOptions'>
															<h5>Dashboard</h5>
															<span>Manage your event</span>
														</div>
														<Arrow />
													</div>
												)}

{creator &&( 

													<div
														className='eventDashboard'
														onClick={()=>setattendeeModal(true)}
													
														>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
														
																<MdArticle size={22} style={{ color: 'var(--cl_orange)' }} />

															</div>

														
														<div className='eventOptions'>
															<h5>Attendees List</h5>
															<span>Download Attendees list</span>
														</div>
														<Arrow />
													</div>
												)}
												{permissions?.can_cancel && (
													<div
														className='eventCancel'
														onClick={() => {
															history.push(`/event/${eventId}/cancel`);
														}}>
														<CancelIcon />
														<div className='eventOptions'>
															<h5>Cancel event</h5>
															<span>Let user know why</span>
														</div>
														<Arrow />
													</div>
												)}
												{(qr_code_url || creator) && (
													<div
														className='eventCancel'
														onClick={() => {
															if (qr_code_url) {
																setQrModal(true);
															} else {
																generateQRcode();
															}
														}}>
														<div className='tw-bg-red-100  tw-flex tw-items-center tw-justify-center tw-rounded-full  tw-p-2'>
															{qrloading ? (
																<CircularProgress size={22} className='tw-text-orange-500' style={{ color: 'var(--cl_orange)' }} />
															) : (
																<AiOutlineQrcode size={22} className='tw-text-orange-500' />
															)}
														</div>
														{qr_code_url ? (
															<div className='eventOptions'>
																<h5>Scan QR Code</h5>
																<span>Share this event via QR</span>
															</div>
														) : (
															// </RWebShare>
															<div className='eventOptions'>
																<h5>Generate QR Code</h5>
																<span>Share this event via QR</span>
															</div>
														)}

														<Arrow />
													</div>
												)}

												{permissions?.delete_ticket && (
													<div
														className='eventCancel'
														onClick={() => {
															DeleteEvent();
														}}>
														<Cancel />
														<div className='eventOptions'>
															<h5>Delete event</h5>
															<span>Let user know why</span>
														</div>
														<Arrow />
													</div>
												)}
											</div>
										)}
										{/* <PostPreview/> */}
									</div>
									{!permissions?.can_view && (
										<div className='privateProfile' style={{ marginTop: '30px' }}>
											<div className='noData'>
												{/* <Warning /> */}
												<div
													className='noDatasvgcontainer warningSvg'
													style={{ backgroundColor: 'var(--cl_orange)' }}>
													<Padlock />
												</div>
												<h2>{noData.privateevent.heading}</h2>
												<span>{noData.privateevent.message + ' ' + name}</span>
												<button
													className={permissions?.is_subscribed ? 'mainBtnInv minwid150' : 'mainBtn'}
													onClick={EventSubscribe}>
													{permissions?.is_subscribed ? 'Requested' : 'Request to subscribe'}
												</button>
											</div>
										</div>
									)}

									<div className='similarEvents'>
										<div className='commonHeading'>
											<h2>
												Similar events
												<Arrow />
											</h2>
										</div>
										<div className='eventGrid'>
											{similar_events
												? Object.keys(similar_events).length === 0
													? !loading && (
															<div className='noData'>
																<Warning />
																<h2>{noData.similar.heading}</h2>
																<span>{noData.similar.message}</span>
															</div>
													  )
													: similar_events.map((item: any, key: number) => {
															return <EventTile event={item} k={key} />;
													  })
												: null}
										</div>
									</div>

									{openSingle && (
										<SingleImageModal
											dialogAction={setOpenSingle}
											dialogStatus={openSingle}
											currentimg={currentimage}
										/>
									)}
									{openinvite && <InvitePeople dialogAction={setinvite} dialogStatus={openinvite} eventId={event?.id} />}
									{ViewClaims && (
										<ViewClaimModal
											open={ViewClaims}
											handleClose={ViewClaimClose}
											eventid={event?.id}
											Refresh_event={Refresh}
										/>
									)}

									{galleryView && (
										<SimpleModal
											data={allPost}
											index={galleyIndex}
											type={'all'}
											open={galleryView}
											setOpen={setgalleryView}
										/>
									)}
								</div>
							)
						) : (
							<div className='event container'>
								{prevdata ? (
									<div className='eventImage'>
										{event_image
											? event_image.slice(0, 1).map((item: any, key: number) => {
													const { image } = item;
													return (
														<img
															alt='Event cover image'
															src={image}
															onClick={() => {
																setcurrent(image);
																setOpenSingle(true);
															}}></img>
													);
											  })
											: null}
									</div>
								) : (
									<div className='eventImage skeleton'></div>
								)}

								<div className='eventContent !tw-block md:!tw-grid'>
									{prevdata ? (
										<div className='eventcol1'>
											<h2>{name}</h2>
											<div className='eventUserDiv'>
												<img alt='P_I_C' src={is_claimable ? alternate_profile?.dp : profile?.dp}></img>
												<span
													className='eventprofilename cursor'
													onClick={() => {
														if (is_claimable) return;

														if (profile?.id === prof.id) {
															history.push('/profile');
														} else {
															history.push(`/user/${profile?.username}`);
														}
													}}>
													by{' '}
													{is_claimable
														? alternate_profile?.firstname
														: profile?.firstname + '' + profile?.surname}
												</span>
											</div>

											<div className='eventDescription'>
												<p>{description}</p>
											</div>
										</div>
									) : (
										<div className='eventcol1'>
											<h2 className='skeleton minwid100 minhe20 maxwid150'></h2>
											<div className='eventUserDiv'>
												<div
													style={{ borderRadius: '50%', height: '32px', width: '32px' }}
													className='skeleton'></div>
												<span className='eventprofilename cursor skeleton  minwid100 minhe10 maxwid150'></span>
											</div>

											<div className='eventDescription'>
												<p className='skeleton minwid100 minhe10 maxwid150'></p>
											</div>
										</div>
									)}

									<div className='eventcol2'>
										<div className='eventDashboard skeleton'></div>
										<div className='eventCancel skeleton'></div>
									</div>
									<div className='eventcol3'>
										<MiniEventTabs active={'none'} />
										<div className='singleeventmapscontainer skeleton'></div>
										<div className='eventDate skeleton'></div>
										<div className='eventAssigned skeleton'></div>
										<div className='eventParticipants skeleton'></div>
										<div className='eventDashboard skeleton'></div>
										<div className='eventCancel skeleton'></div>
									</div>
								</div>
								<div className='similarEvents'>
									<div className='commonHeading'>
										<h2>
											Similar events
											<Arrow />
										</h2>
									</div>
									<div className='eventGrid'>
										{[...Array(6)].map(() => {
											return <EventTileLoader />;
										})}
									</div>
								</div>
							</div>
						)
					) : error.status === null ? (
						<div className='profile error'>
							<div className='errormsg'>
								<Nointernet />
								<h2>{errors.internet.heading}</h2>
								<span>{errors.internet.message}</span>
								<button className='mainBtn' onClick={Refresh}>
									Refresh the page
								</button>
							</div>
						</div>
					) : error.status === 500 ? (
						<div className='profile error'>
							<div className='errormsg'>
								<Server />
								<h2>{errors.server.heading}</h2>
								<span>{errors.server.message}</span>
								<button className='mainBtn' onClick={Refresh}>
									Try again
								</button>
							</div>
						</div>
					) : error.status === 401 ? (
						<div className='profile error'>
							<div className='errormsg'>
								<NotFound />
								<h2>{errors.notFound.heading}</h2>
								<span>{errors.notFound.message}</span>
							</div>
						</div>
					) : (
						<div className='profile error'>
							<div className='errormsg'>
								<NotFound />
								<h2>{errors.common.heading}</h2>
								<span>{errors.common.message}</span>
								<button className='mainBtn' onClick={Refresh}>
									Refresh the page
								</button>
							</div>
						</div>
					)}

					{open2 && (
						<WarningModal
							open={open2}
							setOpen={setopen2}
							// success={suc}
							warning={war}
							fun={DeleteEventFx}
						/>
					)}
					<Footer />
				</Route>
				<Route path={`/event/:eventId/posts`}>
					<Posts />
				</Route>
				<Route path={`/event/:eventId/views`}>
					<Views />
				</Route>
				<Route path={`/event/:eventId/attending`}>
					<EventAttending />
				</Route>
				<Route path={`/event/:eventId/participants`}>
					<ParticipantsPage id={id} />
				</Route>
				<Route path={`/event/:eventId/cancel`}>
					<CancelEvent />
				</Route>
				<Route path={`/event/:eventId/dashboard`}>
					<Dashboard />
				</Route>
				<Route path={`/event/:eventId/reviews`}>
					<EventReviews />
				</Route>
				<Route path={`/event/:eventId/tickets`}>
					<EventTickets />
				</Route>
				<Redirect to={path} />

				{/* <Redirect to={`/event/${eventId}`} /> */}
			</Switch>

			<QRcodeModal
				url={qr_code_url}
				currentURL={currentURL}
				title={name}
				text={description}
				open={qrModal}
				handleClose={() => setQrModal(false)}
			/>
			{open && <BuyEventTicketModal open={open} handleClose={handleClose} event={event} />}
			{openRSVP && <RSVP open={openRSVP} handleClose={handleCloseRSVP} Refresh={Refresh} />}
			{ attendeeModal && <AttendeeListModal  open={attendeeModal} handleClose={()=>setattendeeModal(false)} event={event} Refresh={Refresh}  />} 
		</>
	);
};

export default Event;
