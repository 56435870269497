import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as Arrowright } from '../../../../assets/svg/cm/arrowright.svg'
import { AiOutlineDelete } from 'react-icons/ai'
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import { ReactComponent as BsPlayFill } from '../../../../assets/svg/video.svg';
import dayjs from "dayjs";
import MediaModal1 from "../../../smallcomponents/modals/MediaModal1";

import { InView } from 'react-intersection-observer';
import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { postapprove, reportedpostapprove, reportedCommentsSuccess, reportedCommentsRequest, reportedCommentsInitial } from '../store/ContentmoderatorSlice'
import Api from '../../../../app/api'
import { useHistory } from 'react-router-dom'

const ContentPostItem = ({ data, indexno, postsection, currentPostId }: any) => {
  const [optionvisible, setoptionvisible] = useState(false)
  const feedRef = useRef(null);
  const history = useHistory();
  const { profile } = useAppSelector(state => state.profile)
  const [modalActive, setActive] = useState(false)
  const [index, setindex] = useState(0)
  const dispatch = useAppDispatch()


  const [successmodal, setsuccessmodal] = useState(false)
  const [errormodal, seterrormodal] = useState(false)
  const [warningmodal, setwarningmodal] = useState(false)


  let theData: any = []
  const [sc, setsc] = useState<any>(null)
  const [er, seter] = useState<any>(null)
  const [war, setwar] = useState<any>(null)
  // console.log('-----------------------main post-----------------', data)
  const success = (response: any) => {
    // console.log('--------------------------------deaaaalllllllllsssssss-------------------', response)
    dispatch(reportedCommentsSuccess({
      data: response,
      pagination: response.length < 5 ? false : true
    }))
  }

  const failed = () => {

  }
  useEffect(() => {
    dispatch(reportedCommentsRequest({ no: 1 }))
    dispatch(reportedCommentsInitial)
    currentPostId(data?.post_id)
    // console.log('post id', data?.post_id)
    let theUrl = `/api/v1/permissions/content-moderation/comments/${data?.post_id}/1/5/`






    Api('get', theUrl, null, success, failed, history, true, true)

    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      let targt: any = feedRef.current
      if (optionvisible && targt && !targt.contains(e.target)) {
        setoptionvisible(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [optionvisible, data])

  function HandleTime(date: any) {
    const then = new Date(date).getTime();
    const now = new Date().getTime();
    const gap = now - then

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const d = Math.floor(gap / day);
    const h = Math.floor((gap % day) / hour);
    const m = Math.floor((gap % hour) / minute);
    const s = Math.floor((gap % minute) / second);
    return d !== 0
      ? d + (d === 1 ? " day ago" : " days ago")
      : d === 0 && h !== 0
        ? h + (h === 1 ? " hour ago" : " hrs ago")
        : d === 0 && h !== 0 && m !== 0
          ? m + (m === 1 ? " min ago" : " mins ago")
          : s + (s === 1 ? " second ago" : " seconds ago");
  }

  const escalatesuccess = () => {
    setsc({
      head: 'Post escalated',
      message: '',
      button: 'Okay',
      link: null
    })
    setsuccessmodal(true)
    if (postsection) {
      dispatch(postapprove({
        index: indexno,
        status: 'escalated'
      }))
    } else {
      dispatch(reportedpostapprove({
        index: indexno,
        status: 'escalated'
      }))
    }
    setTimeout(() => {
      setsuccessmodal(false)
      setsc(null)
      // dispatch(feedDelete(id))


    }, 5000);


  }
  const escalatefailed = (data: any) => {
    const {
      status, text, dev_data, app_data,
    } = data;
    let head;
    let message;
    if (status === null) {
      head = 'Check your internet connection and try again.';
      message = "We couldn't connect to the server"
    } else if (status === 500) {
      head = 'Somethings wrong with our Server!';
      message = 'Please be patient while we try to fix it.'
    } else {
      head = "Something's not right.";
      message = 'please try again later'
    }
    seter({
      head: head,
      message: message,
      button: 'Okay',
      link: null
    })
    setTimeout(() => {
      seterrormodal(false)
      seter(null)
    }, 5000);
    seterrormodal(true)
  }


  const Escalate = () => {
    let post: any = {
      "post": data.post_id
    }
    //reported case
    let url = '/api/v1/permissions/content-moderation/post/reported/escalate/'

    if (profile.role === 'Admin') {
      //if its admin
      url = '/api/v1/permissions/content-moderation/escalate/super-admin/'
      if (postsection) {
        post = {
          content_type: "post",
          content_type_id: data.post_id
        }
      } else {
        post = {
          content_type: "reported_post",
          content_type_id: data.post_id
        }
      }
    } else {
      if (postsection) {
        //post section case
        url = '/api/v1/permissions/content-moderation/post/escalate/'
      }
    }

    // let url: any = postsection ? '/api/v1/permissions/content-moderation/post/escalate/' : '/api/v1/permissions/content-moderation/post/reported/escalate/'
    Api('post', url, post, escalatesuccess, escalatefailed, history, true, true)
  }

  function Checkformat(str: any) {
    const n = 3;
    let format = str.slice(str.length - n)
    console.log(format)
    if (format === "peg") {
      format = str.slice(str.length - 4)
    }
    // console.log(format);
    let r = '';
    const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'GIF', 'jpeg', 'gif']
    const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'MOV', 'mov', 'AVI', 'avi']


    if (photoformats.includes(format)) {
      r = 'photo'
      // console.log('photo')
    }
    else if (videoformats.includes(format)) {
      r = 'video'
      // console.log('video')
    }
    else {
      r = 'unknown'
    }

    // console.log(str.slice(str.length - n));
    return (r);
  }

  const [inv, setinv] = useState(false)

  let len = data ? data.post_file ? data.post_file.length : 0 : 0

  let diffe;
  if (len > 5) {
    // setDiff(len - 5)
    diffe = len - 5;
  }
  return (
    <div className='cm_postitem'>
      {
        modalActive && <MediaModal1 data={data.post_file} index={index} type={'feed'}
          open={modalActive} setOpen={setActive}
        />
      }
      <div className='cm_postitem_info'>
        <div className='cm_postitem_user cursor' onClick={() => {
          history.push(`/user/${data.profile.username}`)
        }}>
          <img src={data.profile.dp_thumbnail} />
          <div className='cm_postitem_detail'>
            <h4>{data.profile.firstname + ' ' + data.profile.surname}</h4>
            <small>Uploaded {HandleTime(data.created_at)}</small>
          </div>
        </div>
        <div className='cm_postitem_arrowright'>
          <Arrowright />
        </div>
        <div className='cm_postitem_event cursor' onClick={() => {
          history.push(`/event/${data.event.slug}`)
        }}>
          <img src={data.event.event_image[0].image} />
          <div className='cm_postitem_detail'>
            <h4>{data.event.name}</h4>
            <small>Event</small>
          </div>
        </div>
        {profile && profile.role !== null && <div className="FeedItemDropdown" ref={feedRef} onClick={e => {
          e.preventDefault()
          setoptionvisible(!optionvisible)
        }}>
          <span className='dots'>
            <More />
          </span>{optionvisible ? <div className='feedoptions'>
            <span className='feedoptiontrianglecontainer'><span></span></span>
            {profile && profile.role !== null && <li onClick={() => {
              setwar({
                head: 'Are you sure?',
                message: 'Do you want to escalate the post?',
                button: 'Confirm',
                button1: 'Cancel',
                link: null
              })
              setwarningmodal(true)
            }}>
              {/* <AiOutlineDelete /> */}
              Escalate post</li>}
          </div> : null}

        </div>}
      </div>
      <div className='cm_postitem_container'>
        {/* <div className='cm_postitem_imgcontainer'>
          <img src={data.post_file[0].posted_file} onClick={() => {
            setActive(true)
          }} />
        </div> */}









        <div
          className={`cm_postitem_imgcontainer FeedItemImageContainer ${len <= 1
            ? "one"
            : len === 2
              ? " FeedItemImageContainer2"
              : len === 3
                ? " FeedItemImageContainer3"
                : len === 4
                  ? " FeedItemImageContainer4"
                  : " FeedItemImageContainer5"
            }`}

        // style={
        //   postliked === true
        //     ? {
        //       borderWidth: '3px', borderStyle: 'solid', borderImageSlice: '1', animation: 'feedlike 5s linear forwards'
        //     }
        //     : {}
        // }
        >
          {len > 5 ? (
            data.post_file.slice(0, 5).map((posts: any, keys: number) => {
              const { id, posted_file, posted_file_thumbnail } = posts
              const format = Checkformat(posted_file)
              return (
                format === 'video' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={e => {
                    //   setModalVideo(data.post_file);
                    //   handleClick(keys);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                    style={

                      {
                        display: "flex",
                        alignItems: "center",
                        objectFit: "cover",
                        position: "relative",
                        cursor: "pointer",
                      }

                    }
                  >
                    <video className="feedvideo" style={{ objectFit: "cover" }}

                    >
                      <source src={posted_file} type="video/mp4" />
                      <source src={posted_file} type="video/mkv" />
                      <source src={posted_file} type="video/quicktime" />
                      Your browser does not support the video tag
                    </video>
                    <div className="videooverlayicon" style={{ height: '100%' }}>
                      <BsPlayFill />
                    </div>
                  </div>
                ) : format === 'photo' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={() => {
                    //   handleClick(keys);
                    //   setModalImg(posted_file);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                  >
                    <img
                      src={posted_file}
                      alt="Feed"
                      className="feedItemImage1"
                    ></img>
                  </div>
                ) : <span style={{ height: '614px', width: '100%' }}>Unsupported format</span>

              );
            })
          ) : len === 1 ? (
            data.post_file.slice(0, 1).map((posts: any, keys: number) => {
              const { id, posted_file, posted_file_thumbnail } = posts
              const format = Checkformat(posted_file)
              return (
                format === 'video' ? (
                  <InView as="div"
                    threshold={[1]}
                    onChange={(inView: any, entry: any) => {
                      // console.log(inView, event.name)
                      const vid = entry.target.firstChild.firstChild
                      const icon = entry.target.firstChild.lastChild
                      if (inView === true) {
                        setinv(true)
                        if (vid.paused === true) {
                          // vid.play()
                          icon.classList.add("disappear");
                          if (vid.videoHeight >= vid.videoWidth) {
                            vid.classList.remove("cover");
                            vid.classList.add("contain");
                          } else if (vid.videoHeight <= vid.videoWidth) {
                            console.log('smaller height')
                          }
                          vid.play();
                          vid.controls = true
                        }
                      } else {
                        if (vid.paused === false) {
                          vid.pause()
                        }
                        setinv(false)
                      }
                      // console.log(inView, event.name)
                    }}
                  >
                    <div
                      className="onevid"
                      onClick={(e) => {
                        console.log('clicked')
                        const vid: any = e.currentTarget.children[0];
                        const icon: any = e.currentTarget.children[1];
                        if (!vid.playing) {
                          icon.classList.add("disappear");
                          if (vid.videoHeight >= vid.videoWidth) {
                            vid.classList.remove("cover");
                            vid.classList.add("contain");
                          } else if (vid.videoHeight <= vid.videoWidth) {
                            console.log('smaller height')
                          }
                          vid.play();
                          vid.controls = true
                        } else {
                          icon.classList.remove("disappear");
                          vid.pause();
                          vid.controls = false
                        }
                      }}
                    >
                      <video muted className="feedvideo cover"
                        // style={{aspectRatio:aspect_ratio}}
                        onPause={e => {
                          const icon: any = e.currentTarget.nextSibling
                          icon.classList.remove("disappear");
                          icon.classList.add("cover");
                          e.currentTarget.controls = false
                        }}
                        onEnded={e => {
                          const icon: any = e.currentTarget.nextSibling
                          icon.classList.remove("disappear");
                          icon.classList.add("cover");
                          e.currentTarget.controls = false
                        }}
                      >
                        <source src={posted_file} type="video/mp4" />
                        <source src={posted_file} type="video/mkv" />
                        <source src={posted_file} type="video/quicktime" />
                        Your browser does not support the video tag
                      </video>
                      <div className="videooverlayicon cover"
                        // style={{ backgroundImage: `url(${posted_file_thumbnail})`,height: '100%',backgroundRepeat:'no-repeat' }}
                        style={{ height: '100%' }}
                      >
                        <BsPlayFill className="feedvideoicon" />
                      </div>
                    </div>
                  </InView>
                ) : format === 'photo' ? (
                  <div
                    className="oneimage"
                    // onClick={() => {
                    //   handleClick(keys);
                    //   setModalImg(posted_file);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                  >
                    {/* <Imageonload posted_file={posted_file}/> */}
                    <img
                      src={posted_file}
                      alt="Feed"
                    ></img>
                    {/* + `?t=${new Date().getTime()}` */}
                  </div>
                ) : <span style={{ height: '614px', width: '100%' }}>Unsupported format</span>

              );
            })
          ) : len <= 5 && len > 0 ? (
            data.post_file.slice(0, 5).map((posts: any, keys: number) => {
              const { id, posted_file, posted_file_thumbnail } = posts
              const format = Checkformat(posted_file)
              return (
                format === 'video' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={e => {
                    //   setModalVideo(data.post_file);
                    //   handleClick(keys);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                    style={

                      {
                        display: "flex",
                        alignItems: "center",
                        objectFit: "cover",
                        position: "relative",
                        cursor: "pointer",
                      }

                    }
                  >
                    <video className="feedvideo"
                      style={{ objectFit: "cover" }}
                    >
                      <source src={posted_file} type="video/mp4" />
                      <source src={posted_file} type="video/mkv" />
                      <source src={posted_file} type="video/quicktime" />
                      Your browser does not support the video tag
                    </video>
                    <div className="videooverlayicon" style={{ height: '100%' }}>
                      <BsPlayFill className="feedvideoicon" />
                    </div>
                  </div>
                ) : format === 'photo' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={() => {
                    //   handleClick(keys);
                    //   setModalImg(posted_file);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                  >
                    <img
                      src={posted_file}
                      alt="Feed"
                      className="feedItemImage1"
                    ></img>
                  </div>
                ) : <span style={{ height: '614px', width: '100%' }}>Unsupported format</span>

              );
            })
          ) : len === 0 ? (
            <div
              className="oneimage"
            // onClick={() => {
            //   handleClick(keys);
            //   setModalImg(posted_file);
            // }}
            // style={postliked === true ? { transform: "scale(0.985)" } : {}}
            >
              {/* <img
                                            src={Default}
                                            alt=""
                                          // className="feedItemImage1"
                                          ></img> */}
            </div>
            // <div
            //   className="feedItemImageWrapper"
            //   onClick={() => {
            //     handleClick(key);
            //     setModalImg(Default);
            //   }}
            //   style={{ backgroundImage: `url(${Default})` }}
            // ></div>
          ) : null}
          <h2
            className={`lencount${len > 5 ? " lencountactive" : " lencountinactive"
              }`}
          >
            {"+" + diffe}
          </h2>
        </div>





































      </div>
      {successmodal &&
        <SuccessModal
          open={successmodal}
          setOpen={setsuccessmodal}
          // success={suc}
          success={sc}
        />
      }
      {errormodal &&
        <ErrorModal
          open={errormodal}
          setOpen={seterrormodal}
          // success={suc}
          error={er}
        />
      }
      {warningmodal &&
        <WarningModal
          open={warningmodal}
          setOpen={setwarningmodal}
          // success={suc}
          warning={war}
          fun={Escalate}
        />
      }
    </div>
  )
}

export default ContentPostItem