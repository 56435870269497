import React, { useState, useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ReactComponent as Phone } from '../../../../assets/svg/chat/chatphone.svg';
import { ReactComponent as Menu } from '../../../../assets/svg/chat/menudot.svg';
import { ReactComponent as Badge } from '../../../../assets/svg/chat/chatbadge.svg';
import { ReactComponent as Pin } from '../../../../assets/svg/chat/chatpin.svg';
import { ReactComponent as Attach } from '../../../../assets/svg/chat/chatattach.svg';
import { ReactComponent as Stickers } from '../../../../assets/svg/chat/chatsticker.svg';
import { ReactComponent as Send } from '../../../../assets/svg/chat/sendmsg.svg';
import Api from '../../../../app/api';
import WarningModal from '../../../smallcomponents/modals/Warning'
import { addmessagetochat, changegroupicon, chatreplyactive, insertsendmessages } from '../store/ChatSlice';
import dayjs from 'dayjs';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import imageCompression from 'browser-image-compression';
// import { getCroppedImg } from '../../smallcomponents/cropmodals/crop';
// import { dataURLtoFile } from '../../../smallcomponents/cropmodals/dataURLtoFile';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as PinIcon } from '../../../../assets/svg/mappin.svg';
import { ReactComponent as Bigpin } from '../../../../assets/svg/Location.svg';
import { IoMdDownload } from 'react-icons/io';
import { BsPlayFill } from 'react-icons/bs';
import { RiImageEditFill } from 'react-icons/ri';
import SingleImageModal from '../../../smallcomponents/modals/SingleImageModal';
import ProfileDialog from '../../../smallcomponents/cropmodals/ProfileDialog';
import { AddAPhoto, EmojiEmotions } from '@material-ui/icons';

const style: any = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	// minHeight: '300px',
	minWidth: 400,
	width: '30vw',
	bgcolor: 'background.paper',
	borderRadius: 8,
	overflow: 'hidden',
	boxShadow: 24,
	// p: 4,
};

const ChatmessagelistLayout = ({ children }: { children: any }) => {
	const { profile } = useAppSelector((state) => state.profile);
	const { openedchatdetails, openedchatkey, socket, replymode, replydetails } = useAppSelector((state) => state.chat);

	const dispatch = useAppDispatch();

	const [groupdetails, setgroupdetails] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [open2, setOpen2] = useState(false);
	const [war, setwar] = useState<any>(null)


	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [isActive, setIsActive] = useState(false);
	const [emojiactive, setemojiactive] = useState(false);
	const [chatinputvalue, setchatinputvalue] = useState<any>('');
	const [photos, setphotos] = useState<any>({});

	const dropdownRef = useRef(null);

	// console.log('~~~~~~~~~~~~~~~~~~~~~~~~~socket~~~~~~~~~~~~~~~~~~~~~~~~~', socket);
	// console.log('__________________openedchatdetails_____________________', openedchatdetails)

	const vis: any = useRef();
	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			let targt: any = vis.current;
			if (isActive && targt && !targt.contains(e.target)) {
				setIsActive(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [isActive]);
	const ImgUpload = (img: any) => {
		var timestamp: any = new Date().getUTCMilliseconds() + Math.floor(Math.random() * 2) + 777;
		let post_dat = new FormData();
		post_dat.append('chat_file', img);
		post_dat.append('chat', openedchatdetails.chat_id);
		post_dat.append('message_type', 'image');
		post_dat.append('message', '');
		post_dat.append('reply_reference_id', '');
		post_dat.append('app_reference_id', timestamp);

		dispatch(
			insertsendmessages({
				id: 0,
				uploaded_files: [
					{
						'id': 0,
						'message_reference_id': 0,
						'uploaded_file': window.URL.createObjectURL(new Blob([img])),
						'uploading_file': JSON.stringify(img),
					},
				],
				sender: {
					id: profile.id,
					dp: profile.dp,
					dp_thumbnail: profile.dp,
					firstname: profile.firstname,
					surname: profile.surname,
					username: profile.username,
				},
				reply_reference_id: null,
				contact_info: null,
				shared_info: null,
				message_type: 'text',
				message: chatinputvalue,
				active_status: true,
				read_status: false,
				delivered_status: false,
				chat_reference_id: openedchatdetails.chat_id,
				reply_status: false,
				created_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
				updated_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
				location_image: null,
				addressline_1: null,
				addressline_2: null,
				app_reference_id: timestamp,
				shared_reference_id: null,
				share_category: null,
				justsend: true,
			})
		);
		const Onuploadsuccess = (res: any) => {
			console.log(res);
			dispatch(addmessagetochat(res.data));
		};
		const onuploadfailed = (err: any) => {
			console.log(err);
		};
		Api('post', '/api/v1/ws/chat/file/v2/', post_dat, Onuploadsuccess, onuploadfailed, true, true);
	};

	const onSelectImages = async (event: any) => {
		console.log(event);

		if (event.target.files && event.target.files.length > 0) {
			// setphotos(event.target.files[0])

			// setphotos(event.target.files)
			// setActive(true)
			if (event.target.files && event.target.files.length > 0) {
				const nav: any = navigator;
				const { userAgentData } = nav;
				let options;
				if (userAgentData?.platform === 'macOS') {
					if (event.target.files[0].size > 1000000) {
						options = {
							maxSizeMB: 1,
							// maxWidthOrHeight: 800,
							maxWidthOrHeight: 1392,
							useWebWorker: true,
						};
						try {
							const compressedFile = await imageCompression(event.target.files[0], options);
							console.log(compressedFile);
							// Setitup(compressedFile)
							setphotos(compressedFile);
							ImgUpload(compressedFile);
							// setLoader(false)
						} catch (error) {
							console.log(error);
							// setLoader(false)
						}
					} else {
						console.log('lesser');
						setphotos(event.target.files[0]);
						ImgUpload(event.target.files[0]);
						// setLoader(false)
					}
				} else {
					options = {
						maxSizeMB: 5,
						maxWidthOrHeight: 1920,
						useWebWorker: true,
					};
					if (event.target.files[0].size > 2999999) {
						try {
							const compressedFile = await imageCompression(event.target.files[0], options);
							console.log(compressedFile);
							setphotos(compressedFile);
							ImgUpload(compressedFile);
							// setLoader(false)
						} catch (error) {
							console.log(error);
							// setLoader(false)
						}
					} else {
						console.log('lesser than 3mb');
						setphotos(event.target.files[0]);
						ImgUpload(event.target.files[0]);
						// setLoader(false)
					}
				}
			}

			// console.log(event.target.files)
			// console.log(event.target.files[0])
		}
	};

	const Sentmessage = () => {
		// console.log(socket);
		// var timestamp = Date.now() + Math.random()
		// var timestamp = Date.now()
		// console.log(math)
		// let {socket: socketparsed} = JSON.parse(socket)
		let ctid = openedchatdetails.chat_id;
		var timestamp = new Date().getUTCMilliseconds() + Math.floor(Math.random() * 2) + 777;
		console.log(timestamp);
		console.log(typeof timestamp);
		console.log(socket);

		// let thesocket = socket['socket']
		let thesocket = socket.socket;
		console.log("thesocket", thesocket);
		if (thesocket) {
			// alert('message sending')

			console.log({
				id: 0,
				uploaded_files: [],
				sender: {
					id: 49,
					dp: profile.dp,
					dp_thumbnail: profile.dp,
					firstname: profile.firstname,
					surname: profile.surname,
					username: profile.username,
				},
				reply_reference_id: null,
				contact_info: null,
				shared_info: null,
				message_type: 'text',
				message: chatinputvalue,
				active_status: true,
				read_status: false,
				delivered_status: false,
				chat_reference_id: openedchatdetails.chat_id,
				reply_status: false,
				created_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
				updated_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
				location_image: null,
				addressline_1: null,
				addressline_2: null,
				app_reference_id: timestamp,
				shared_reference_id: null,
				share_category: null,
				justsend: true,
			});
			dispatch(
				insertsendmessages({
					id: 0,
					uploaded_files: [],
					sender: {
						id: profile.id,
						dp: profile.dp,
						dp_thumbnail: profile.dp,
						firstname: profile.firstname,
						surname: profile.surname,
						username: profile.username,
					},
					reply_reference_id: replymode ? replydetails : null,
					contact_info: null,
					shared_info: null,
					message_type: 'text',
					message: chatinputvalue,
					active_status: true,
					read_status: false,
					delivered_status: false,
					chat_reference_id: openedchatdetails.chat_id,
					reply_status: replymode ? true : false,
					created_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
					updated_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
					location_image: null,
					addressline_1: null,
					addressline_2: null,
					app_reference_id: timestamp,
					shared_reference_id: null,
					share_category: null,
					justsend: true,
				})
			);
			// setTimeout(() => {
			thesocket.send(
				JSON.stringify({
					'message': chatinputvalue,
					'message_type': 'text',
					'contact_info': null,
					'reply_reference_id': replymode ? replydetails.id : null,
					'connection_type': 'chat',
					'chat_id': ctid,
					'chat_msg_id': null,
					'app_reference_id': timestamp,
				})
			);
			// }, 10000);

			setchatinputvalue('');
			if (replymode) {
				dispatch(
					chatreplyactive({
						mode: false,
						details: {},
					})
				);
			}
		}

		// alert('message send')
	};

	const Keypress = (e: any) => {
		if (e.key === 'Enter') {
			// alert('keypresses')
			if (chatinputvalue !== '') {
				// alert('message found')
				Sentmessage();
			}
		}
	};

	const [curimg, setcurimg] = useState<any>(null);
	const [modalopen, setmmodalopen] = useState(false);

	const [open1, setOpen1] = useState(false);
	const IconChange: any = (file: any) => {
		setOpen(true);
		dispatch(changegroupicon({ dp: file.group_icon, thumbnail: file.group_icon_thumbnail }));
	};

	return (
		<div className='chat_messages_container' style={{ position: 'relative' }}>
			{/* {
        modalactive && <GooglemapModal
          dialogStatus={modalactive}
          dialogAction={setmodalactive} />
      } */}
			{openedchatkey !== null ? (
				<div
					className={
						emojiactive
							? 'chat_messages_content chat_messages_content_emoji_inactive'
							: 'chat_messages_content chat_messages_content_emoji_active'
					}>
					{open1 && (
						<ProfileDialog
							dialogStatus={open1}
							dialogAction={setOpen1}
							// fetchProfile={fetchProfile}
							setChange={IconChange}
							change={'groupicon'}
							currentDp={curimg}
						/>
					)}
					{modalopen && <SingleImageModal dialogStatus={modalopen} dialogAction={setmmodalopen} currentimg={curimg} />}
					<Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' >
						<Box sx={style}>
							<div className='groupdetailsmodal' style={{ minHeight: "150%", }}>
								{/* <div className='closegrpdetmodal cursor' onClick={handleClose}>
									<CloseIcon />
								</div> */}
								<div className='groupinfo_container'>
									<div className='groupinfoimage groupinfoimage_creator' style={{ position: 'relative' }}>
										<img
											src={
												openedchatdetails.chat_type === 'group'
													? openedchatdetails.group_icon
													: openedchatdetails.friend_info
														? openedchatdetails.friend_info.dp
														: openedchatdetails.group_icon_thumbnail
															? openedchatdetails.group_icon_thumbnail
															: ''
											}
											alt=''
											onClick={() => {
												setcurimg(
													openedchatdetails.chat_type === 'group'
														? openedchatdetails.group_icon
														: openedchatdetails.friend_info
															? openedchatdetails.friend_info.dp
															: openedchatdetails.group_icon
																? openedchatdetails.group_icon
																: ''
												);
												handleClose();
												setmmodalopen(true);
											}}
										/>
										{openedchatdetails.chat_type === 'group' && openedchatdetails.permissions.can_edit && (
											<div
												className='groupinfoimage_overlay'
												onClick={() => {
													setcurimg(
														openedchatdetails.chat_type === 'group'
															? openedchatdetails.group_icon
															: openedchatdetails.friend_info
																? openedchatdetails.friend_info.dp
																: openedchatdetails.group_icon
																	? openedchatdetails.group_icon
																	: ''
													);
													handleClose();
													setOpen1(true);
												}}>
												<div style={{ color: `white`, padding: "10px", borderRadius: "50%", backgroundColor: `var(--cl_orange)` }}>
													{/* <RiImageEditFill style={{ color: "white" }} /> */}
													<AddAPhoto />

												</div>
											</div>
										)}
										{/* <div className="fade__image"></div> */}
										<div className='profile_name_chatmessage'>
											<h2 >
												{openedchatdetails.chat_type === 'group'
													? openedchatdetails.chat_name
													: openedchatdetails.friend_info.firstname + openedchatdetails.friend_info.surname}
											</h2>
										</div>
									</div>


									<div className="profile_popup_body">
										<div style={{ color: "black", fontWeight: "700" }}>{openedchatdetails.chat_type === 'private' ? "About" : "Group Description"}</div>
										<span style={{ color: 'var(--cl_textgrey)' }}>{openedchatdetails.chat_description}</span>
									</div>
								</div>
							</div>
						</Box>
					</Modal>
					<div className='chat_user_intro_header'>
						<div
							className='chat_user_intro_header_left cursor'
							onClick={() => {
								// setgroupdetails(true)
								setOpen(true);
							}}>
							<div className='chat_user_intro_header_left_img'>
								<img
									src={
										openedchatdetails.chat_type === 'group'
											? openedchatdetails.group_icon_thumbnail
											: openedchatdetails.friend_info
												? openedchatdetails.friend_info.dp_thumbnail
												: openedchatdetails.group_icon_thumbnail
													? openedchatdetails.group_icon_thumbnail
													: ''
									}
									alt=''
								/>
								{openedchatdetails.chat_type === 'group' ? null : openedchatdetails.friend_info ? (
									openedchatdetails.friend_info.online_status ? (
										<div className='chat_user_intro_header_left_status'></div>
									) : null
								) : null}
							</div>
							<div className='chatmsguserdetails' >
								<h3>
									{openedchatdetails.chat_type === 'group'
										? openedchatdetails.chat_name
										: openedchatdetails.friend_info.firstname + openedchatdetails.friend_info.surname}
								</h3>
								{openedchatdetails.chat_type === 'group' ? null : openedchatdetails.friend_info ? (
									<small>{openedchatdetails.friend_info.online_status ? 'Online' : null}</small>
								) : null}
								<div style={{
									display: 'flex', flexWrap: 'nowrap', gap: "4px", color: "var(--cl_textgrey )", fontSize: '.8rem', padding: "4px 0px",
									// border: '1px solid red',
									//  whiteSpace: 'nowrap',
									//  width: 'calc(50%)',
									//  minWidth:"300px"
									//  wordBreak:'break-word',

									//  overflow: 'hidden',
									textOverflow: 'ellipsis'
								}} >
									{openedchatdetails.chat_type === 'group' &&
										(openedchatdetails.chat_description ? (openedchatdetails.chat_description.length > 200 ? openedchatdetails.chat_description.slice(0, 200) + '...' : openedchatdetails.chat_description)
											: (Array.isArray(openedchatdetails.chat_members) ? openedchatdetails.chat_members.map((item: any, key: any) => <p> {item?.firstname + item?.surname}{openedchatdetails.chat_members.length - 1 === key ? "" : ','}</p>) : ""))}
								</div>
							</div>
						</div>
						<div className='chat_user_intro_header_right'>
							{/* <div className='chat_send_iconwrapper whiteicon'>
								<Phone />
							</div> */}
							<div
								ref={vis}
								className='whiteiconwrapper dropdownContainer'
								onClick={() => {
									setIsActive(!isActive);
								}}>
								<Menu />
								<div ref={dropdownRef} className={`dropdown settingsDropdown ${isActive ? 'active' : 'inactive'}`}>
									<span></span>
									<ul>
										{/* <li><a href="/personal-account" onClick={onClick}> New personal account </a></li> */}
										<li>
											<a
												href='/'
												onClick={(e) => {
													e.stopPropagation();
													e.preventDefault();
													setOpen(true);
												}}>
												Info
											</a>
										</li>
										{/* <li>
											<a
												href='/'
												onClick={(e) => {

													e.stopPropagation();
													e.preventDefault();
													
													setOpen2(true)
												}}>
												Clear chat
											</a>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
					{children}
					{

					}
					<div className={emojiactive ? 'chat_send chat_sendactive' : 'chat_send chat_sendinactive'}>
						{/* <div className={emojiactive? 'mainchatemojipicker mainchatemojipickeractive': 'mainchatemojipicker mainchatemojipickerinactive'}>
                <Picker onEmojiClick={onEmojiClick} />
              </div> */}
						{openedchatdetails && openedchatdetails.permissions && openedchatdetails.permissions.can_send_message ? (
							openedchatdetails.blocked_status ? (
								<div className='chat_send_wrapper' style={{ height: '4.625rem' }}>
									<div
										style={{
											display: 'flex',
											alignContent: 'center',
											justifyContent: 'center',
											color: 'var(--cl_textgrey)',
										}}>
										Unblock to start messaging
									</div>
								</div>
							) : (
								<div className='chat_send_wrapper' style={{ position: 'relative' }}>
									{replymode && (
										<div
											style={{
												position: 'absolute',
												height: '100px',
												width: '90%',
												backgroundColor: 'var(--cl_bggrey)',
												bottom: 80,
												right: '5%',
												borderRadius: '8px',
											}}>
											<CloseIcon
												className='cursor'
												style={{
													marginLeft: 'auto',
													float: 'right',
													height: '20px',
													width: '20px',
													margin: '5px 5px',
												}}
												onClick={() => {
													dispatch(chatreplyactive({ mode: false, details: {} }));
												}}
											/>
											{replydetails.message_type === 'location' ? (
												<p
													style={{
														height: '100%',
														padding: '12px 16px',
														display: 'flex',
														alignItems: 'center',
														gap: '1rem',
													}}>
													<Bigpin style={{ height: '60px' }} />
													{replydetails.addressline_2}
												</p>
											) : replydetails.message_type === 'file' ? (
												<p
													style={{
														height: '100%',
														padding: '12px 16px',
														display: 'flex',
														alignItems: 'center',
														gap: '1rem',
													}}>
													{/* <Bigpin style={{height:'60px'}}/> */}
													<IoMdDownload color='var(--cl_orange)' />
													{replydetails.uploaded_files[0].uploaded_file.replace(
														'https://d20rv3nuwgisca.cloudfront.net/staging/media/chat/chat_files/',
														''
													)}
												</p>
											) : replydetails.message_type === 'contact' ? (
												<p
													style={{
														height: '100%',
														padding: '12px 16px',
														display: 'flex',
														alignItems: 'center',
														gap: '1rem',
													}}>
													<img
														style={{
															height: '40px',
															width: '40px',
															objectFit: 'cover',
															objectPosition: 'center',
															borderRadius: '50%',
														}}
														src={
															'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png'
														}
													/>
													<div>
														<h3>{replydetails.contact_info.name}</h3>
														<span>{replydetails.contact_info.mobile}</span>
													</div>
												</p>
											) : replydetails.message_type === 'image' ? (
												<p
													style={{
														height: '100%',
														padding: '12px 16px',
														display: 'flex',
														alignItems: 'center',
														gap: '1rem',
													}}>
													<img
														style={{
															height: '50px',
															width: '50px',
															objectFit: 'cover',
															objectPosition: 'center',
															borderRadius: '5px',
														}}
														src={replydetails.uploaded_files[0].uploaded_file}
													/>
													image
												</p>
											) : replydetails.message_type === 'video' ? (
												<p
													style={{
														height: '100%',
														padding: '12px 16px',
														display: 'flex',
														alignItems: 'center',
														gap: '1rem',
													}}>
													<BsPlayFill />
													Video
												</p>
											) : (
												<p
													style={{
														height: '100%',
														padding: '12px 16px',
														display: 'flex',
														alignItems: 'center',
														gap: '1rem',
													}}>
													{replydetails.message}
												</p>
											)}
										</div>
									)}
									<input
										className='chat_input'
										style={{ paddingLeft: '15px' }}
										value={chatinputvalue}
										onChange={(e) => {
											setchatinputvalue(e.currentTarget.value);
										}}
										onKeyPress={Keypress}
									/>
									<div className='chat_send_icons'>
										<div className='chat_additional_icons'>
											<div
												className='chat_send_iconwrapper whiteicon'
												onClick={(e) => {
													let target: any = e.currentTarget;
													target.firstChild.click();
												}}>
												<input
													type='file'
													name='myImage'
													// accept="image/x-png,image/gif,image/jpeg"
													accept='image/*'
													// multiple
													style={{ display: 'none' }}
													onChange={onSelectImages}
												/>
												<Attach />
											</div>
											{/* <div className='chat_send_iconwrapper whiteicon'>
												<Badge />
											</div> */}
											{/* <div
												className='chat_send_iconwrapper whiteicon'
												onClick={() => {
													// setmodalactive(true)
												}}>
												<Pin />
											</div> */}
											<div style={{ color: "white" }}
												className='chat_send_iconwrapper whiteicon'
												onClick={() => {
													setemojiactive(!emojiactive);
												}}>
												{/* <Stickers /> */}
												<EmojiEmotions />
											</div>
										</div>
										<div className='chat_send_iconwrapper orangeicon' onClick={Sentmessage}>
											<Send />
										</div>
									</div>
								</div>
							)
						) : (
							<div className='chat_send_wrapper' style={{ height: '4.625rem' }}>
								<div
									style={{
										display: 'flex',
										alignContent: 'center',
										justifyContent: 'center',
										color: 'var(--cl_textgrey)',
									}}>
									You have exited the group
								</div>
							</div>
						)}
						{/* <Picker set='apple' /> */}
						{/* <Picker onSelect={this.addEmoji} /> */}
						<Picker
							onSelect={(e: any) => {
								console.log(e);
								setchatinputvalue(chatinputvalue + e.native);
							}}
						/>
						{/* <Picker style={{ position: 'absolute', bottom: '20px', right: '20px' }} /> */}
						{/* <Picker i18n={{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }} /> */}
					</div>
				</div>
			) : <div style={{ color: "lightgray", marginLeft: "30%", width: "260px", padding: "6px", backgroundColor: "rgba(255,255,255,0.5)", justifyContent: "center", justifyItems: "center", marginTop: "43%", borderRadius: "11px" }}><span>Select a chat to start messaging</span></div>}

			{/* {open2 &&
				<WarningModal
					open={open2}
					setOpen={setOpen2}
					// success={suc}
					warning={war} fun={undefined}					//   fun={DeleteGroup}
				/>
			} */}

		</div>
	);
};

export default ChatmessagelistLayout;
