import React, { useEffect, useRef, useCallback } from "react";
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader'
import FeaturedProfileItem from '../../smallcomponents/featuredprofileitem/FeaturedProfileItem'
// import Api from "../../../app/api";
import { followingFailed } from "./store/followingSlice";
import { FetchFollwingPageData } from "./store/action";
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { noData } from "../../../Data/Data";

const Following = () => {
    const { profileid }: any = useParams()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { following, error, loading, page, pagination } = useAppSelector(state => state.following)
    const { profile } = useAppSelector(state => state.profile)
    useEffect(() => {
        window.scrollTo(0, 0);
        const success = (res: any) => {
        }
        const failed = (res: any) => {
            dispatch(followingFailed(res))
        }
        if (Object.keys(profile).length !== 0) {
            FetchFollwingPageData(dispatch, history, profile.id, success, failed, 1, [])
        }

    }, [dispatch, history, profile])

    const observer: any = useRef()

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && pagination) {
                let page_no = page + 1;
                if (!loading) {
                    FetchFollwingPageData(dispatch, history, profile, () => { }, () => { }, page_no, following);
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [dispatch, history, pagination, page, profile, following, loading])

    return (
        <div className='following container minvh100'>
            <div className='commonHeading'>
                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                <h2>Following profiles</h2>
            </div>
            {<div className="featuredProfilesList">
                {
                    (Object.keys(following).length === 0) ? (
                        (loading === false && error === null) &&
                        // <div className='eventGrid'>
                        <div className='noData'>
                            <Warning />
                            <h2>{noData.profilefollowing.heading}</h2>
                            <span>{noData.profilefollowing.message}</span>
                            <div className='loadmore'>
                                <button className='mainBtn' onClick={() => history.push('/profile')}>Go back to profile page</button>
                            </div>
                        </div>
                        // </div>
                    ) : (
                        following?.map((item, key) => {
                            if (following?.length === key + 1) {
                                return (
                                    <div ref={lastBookElementRef}>
                                        <FeaturedProfileItem featured={item} k={key} unf={true} place={'following'} />
                                    </div>
                                )
                            } else {
                                return (<FeaturedProfileItem featured={item} k={key} unf={true} place={'following'} />)
                            }
                        })
                    )
                }
                {loading &&
                    [...Array(10)].map(() => {
                        return (
                            <FeaturedProfileLoader />
                        )
                    })

                }
            </div>}

        </div>
    )

}


export default Following
