import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useAppDispatch } from '../../../../app/hooks';
import { participantSearch,participantSearchLoad,participantSearchFailed,inviteSearchLoad,inviteSearch,inviteSearchFailed } from '../store/addEventSlice';
import { baseurl } from '../../../../Data/Data';

const Invitesearch = ({mode}:{mode: string}) => {

    const[searchuser,setsearchuser] = useState(null)
    const dispatch = useAppDispatch();
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    useEffect(() => {
        const request = axios.CancelToken.source()
        let data;
        console.log("searching user")
        data = {
            "search_type": "people",
            "keyword": searchuser

        }
        const token = localStorage.getItem(`access_token`);
        let theurl = `/api/v1/search/1/12/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/search/1/12/?auth_profile=${parseddetails[0].id}`
          }
    
        }
        var config: any = {
            method: 'post',
            url: baseurl+theurl,
            cancelToken: request.token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };
        if (searchuser !== null && searchuser !== '') {
            if(mode === 'invite'){
                dispatch(inviteSearchLoad())
            }else {
                dispatch(participantSearchLoad())
            }

            axios(config)
            .then((res) => {
                if(mode === 'invite'){
                dispatch(inviteSearch(res.data))
                }else{
                    dispatch(participantSearch(res.data))
                }
                // console.log("users")
                // console.log(res.data)
            })
            .catch((error) => {
                // const errorMsg = error.message
                if(mode === 'invite'){
                dispatch(inviteSearchFailed(error))
                }else{
                    dispatch(participantSearchFailed(error))
                }
                // console.log(error);
                // console.log(errorMsg);
            });
        }else{
            let theurl = `/api/v1/search/friends/1/12/`
            if (unparseddetails) {
              if (currentparsed.is_business_profile) {
                theurl = `/api/v1/search/friends/1/12/?auth_profile=${parseddetails[0].id}`
              }
        
            }
            config = {
                method: 'post',
                url: baseurl+theurl,
                cancelToken: request.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                data: {
                    "keyword":""
                }
            }
            
            if(mode === 'invite'){
                dispatch(inviteSearchLoad())
            }else {
                dispatch(participantSearchLoad())
            }

            axios(config)
            .then((res) => {
                if(mode === 'invite'){
                dispatch(inviteSearch(res.data))
                }else{
                    dispatch(participantSearch(res.data))
                }
                // console.log("users")
                // console.log(res.data)
            })
            .catch((error) => {
                // const errorMsg = error.message
                if(mode === 'invite'){
                dispatch(inviteSearchFailed(error.response))
                }else{
                    dispatch(participantSearchFailed(error.response))
                }
                // console.log(error);
                // console.log(errorMsg);
            });
        }
        return () => request.cancel()
    }, [searchuser]);
    return (
        <input type="text" className="AddEventSectInput"  autoFocus
        onChange={(e) => {
            const target: any = e.currentTarget
            setsearchuser(target.value)
        }} 
        />
    )
}

export default Invitesearch
