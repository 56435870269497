import { osName } from 'react-device-detect';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// import { store } from "../app/store";
import { profileuserlocation } from '../components/Tabs/profile/store/profileSlice';
dayjs.extend(utc);

// export const sitelink = 'https://mutualevents.com/'
// export const baseurl = 'https://deep.mutualevents.co'

// export const sitelink = 'https://beta51c04.mutualevents.co/';
// export const baseurl = 'https://51c046c6-73be-447a-a38c-d999464b1b85.mutualevents.co';

export const sitelink = process.env.REACT_APP_SITELINK || "";
export const baseurl = process.env.REACT_APP_BASEURL || "";
export const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY || "";
export const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT || "";




// export const MAP_API_KEY = 'AIzaSyDUSwoK-9evWPpXmmEmUWLHzTX5gFonXds';


// export const MAP_API_KEY = 'AIzaSyDg50OZZ_O8KwNg0aS3MIx2e0hdXprhEXU' //Local

// export const MAP_API_KEY ='AIzaSyDlj_WsW0i67qxoZGYK-9y-ZoY0v3_hIX8' //mine

// export const MAP_API_KEY = 'AIzaSyCVHWug3PRbkfovRw5CqYhcQAUFSwTj10g'
//other

export function colorHelper(color: any, data: any) {
	/* function receives 2 arguments color and data*/
	const black = '\x1b[30m';
	const red = '\x1b[31m';
	const green = '\x1b[32m';
	const yellow = '\x1b[33m';
	const blue = '\x1b[34m';
	const magenta = '\x1b[35m';
	const cyan = '\x1b[36m';
	const white = '\x1b[37m';
	const arr = [];

	/* Storing the color codes in Array */
	arr[0] = black;
	arr[1] = red;
	arr[2] = green;
	arr[3] = yellow;
	arr[4] = blue;
	arr[5] = magenta;
	arr[6] = cyan;
	arr[7] = white;
	console.log(arr[color] + data);
}

export const timeIntervals = () => {
	let dateAra = [];

	let time: any = '2018-04-04T18:30:00.000Z';

	console.log('day :', dayjs(time).format('hh:mm'));

	console.log('day2 :', dayjs(time).add(30, 'minute').format('hh:mm'));

	for (let i = 0; i < 48; i++) {
		dateAra.push(dayjs(time).add(30, 'minute').format('hh:mm:a'));
		time = dayjs(time).add(30, 'minute');
	}
	console.log('RETURN:', dateAra);
};

export const TimeIntervals = [
	{ 'time': '12:30:am' },
	{ 'time': '01:00:am' },
	{ 'time': '01:30:am' },
	{ 'time': '02:00:am' },
	{ 'time': '02:30:am' },
	{ 'time': '03:00:am' },
	{ 'time': '03:30:am' },
	{ 'time': '04:00:am' },
	{ 'time': '04:30:am' },
	{ 'time': '05:00:am' },
	{ 'time': '05:30:am' },
	{ 'time': '06:00:am' },
	{ 'time': '06:30:am' },
	{ 'time': '07:00:am' },
	{ 'time': '07:30:am' },
	{ 'time': '08:00:am' },
	{ 'time': '08:30:am' },
	{ 'time': '09:00:am' },
	{ 'time': '09:30:am' },
	{ 'time': '10:00:am' },
	{ 'time': '10:30:am' },
	{ 'time': '11:00:am' },
	{ 'time': '11:30:am' },
	{ 'time': '12:00:pm' },
	{ 'time': '12:30:pm' },
	{ 'time': '01:00:pm' },
	{ 'time': '01:30:pm' },
	{ 'time': '02:00:pm' },
	{ 'time': '02:30:pm' },
	{ 'time': '03:00:pm' },
	{ 'time': '03:30:pm' },
	{ 'time': '04:00:pm' },
	{ 'time': '04:30:pm' },
	{ 'time': '05:00:pm' },
	{ 'time': '05:30:pm' },
	{ 'time': '06:00:pm' },
	{ 'time': '06:30:pm' },
	{ 'time': '07:00:pm' },
	{ 'time': '07:30:pm' },
	{ 'time': '08:00:pm' },
	{ 'time': '08:30:pm' },
	{ 'time': '09:00:pm' },
	{ 'time': '09:30:pm' },
	{ 'time': '10:00:pm' },
	{ 'time': '10:30:pm' },
	{ 'time': '11:00:pm' },
	{ 'time': '11:30:pm' },
	{ 'time': '12:00:am' },
];

export const noData = {
	hosting: {
		heading: "We think you'll make a fantastic host!",
		message: 'Once you create an event of your own, you will be able to find it here.',
	},
	attending: {
		heading: "It looks like you've got some free time on your plate!",
		message: 'When you agree to attend events, this is where those events will appear',
	},
	media: {
		heading: 'Welcome to a library of memories.',
		message: 'All your posted images and video files will be stored here.',
	},
	events: {
		heading: "We think you'll make a fantastic host!",
		message: 'Once you create an event of your own, you will be able to find it here.',
	},
	recommended: {
		heading: '',
		message: 'When you select your event interests, we will start to provide recommendations on events best suited to you!',
	},
	popular: {
		heading: 'Stay tuned for the biggest and the best.',
		message: 'This is where mass events with a large number of attendees will appear.',
	},
	toprated: {
		heading: 'Searching for the crème de la crème?',
		message: 'Events that have been reviewed and rated highly will soon appear here.',
	},
	yours: {
		heading: 'There are no events for you today!',
		message: "if there are events for you you'll find it here",
	},
	story: {
		heading: 'We wanna see what’s happening.',
		message: 'Post a story to share your event experience with your connections!',
	},
	discover: {
		heading: "It looks like there's nothing here",
		message: "if there are events you are looking for, you'll find it here",
	},
	similar: {
		heading: "It looks like there's nothing here",
		message: 'Events similar to the above will be shown here',
	},
	privateprofile: {
		heading: 'This profile is private',
		message: 'You need to follow to learn more about',
	},
	privateevent: {
		heading: 'This event is private',
		message: 'You need to subscribe to learn more about the event',
	},
	profilefollowers: {
		heading: 'All your BFFs in one place.',
		message: 'Connections you make with other Mutual Events users will be located here.',
	},
	profilefollowing: {
		heading: 'You can take control!',
		message: 'Manage who you are following right here. ',
	},
	profilepage: {
		heading: 'It’s all about you, you, you!',
		message: 'Your profile page is where your events, posts, followers, and activity will be displayed. ',
	},
	posts: {
		heading: 'Let’s get social!',
		message: 'Posts you make on events you are attending will be paraded here.',
	},
	myevents: {
		heading: 'How jampacked can you make your calendar?',
		message: 'All your past, present and future events will be shown here.',
	},
	notification: {
		heading: 'There’s nothing to tell you… yet!',
		message: 'All your event notifications can be found here, including event invitations, RSVPs, posts to your events, comments on your events and posts, follow requests, and event approvals.',
	},
	followersevents: {
		heading: 'Let’s snoop on your friends…',
		message: 'This is where events hosted by people you are connected with will appear.',
	},
	peoplesearch: {
		heading: 'Good company makes all the difference!',
		message: 'Search here to see who is using Mutual Events and build your connections.',
	},
	eventsearch: {
		heading: 'Got a particular event in mind?',
		message: 'Type a specific event name to find what you’re looking for.',
	},
	discoversearch: {
		heading: 'Events don’t just happen; they exist, waiting, and we need to go out and find them!',
		message: 'Use the Mutual Events search platform to browse a range of events.',
	},
	nearme: {
		heading: 'It’s a peaceful neighbourhood today…',
		message: 'When there are events happening in close proximity to you, they will be shown on this page.',
	},
	todays: {
		heading: 'It seems to be a quiet day…',
		message: 'When you start to follow more events, daily happenings will appear here!',
	},
	entertainment: {
		heading: 'The show must go on!',
		message: 'Once you start following shows, festivals, concerts, and entertainment events, this is where they will appear. ',
	},
	travel: {
		heading: 'To travel is to live…',
		message: 'All your travel related events will appear here. Start searching for them now!',
	},
	sporting: {
		heading: 'Find out who’s winning!',
		message: 'When you start to follow top games and matches, your sporting related events will appear here.',
	},
	food: {
		heading: 'Hey there, connoisseur!',
		message: 'This is where all your food and drink related events will be shown.',
	},
	kids: {
		heading: 'Let’s find something to keep the little munchkins busy.',
		message: 'When you follow and attend children’s events, they will appear on this page. ',
	},
	cultural: {
		heading: 'The beauty of the world is in the diversity of its people.',
		message: 'Start filling this page with your cultural related events.',
	},
	family: {
		heading: 'Uhh, when is Grandma’s birthday again?',
		message: 'Once you start to follow family events, they will be displayed right here.',
	},
	corporate: {
		heading: 'Quick, the boss is coming. Look busy!',
		message: 'This is where all your corporate and business events can be found.',
	},
};
export const headings = {
	recommended: {
		main: 'Recommended for you',
		sub: 'Experience events based on your interests.',
	},
	popular: {
		main: 'Popular Event',
		sub: 'See the most popular events',
	},
	toprated: {
		main: 'Top rated Events',
		sub: 'Experience events based on class',
	},
};
export const internetError = {
	code: 404,
	main: 'internet error',
	message: 'please try again',
};

export const UTCtimezones = [
	{
		'value': 'Dateline Standard Time',
		'abbr': 'DST',
		'offset': -12,
		'isdst': false,
		'text': '(UTC-12:00) International Date Line West',
		'utc': ['Etc/GMT+12'],
	},
	{
		'value': 'UTC-11',
		'abbr': 'U',
		'offset': -11,
		'isdst': false,
		'text': '(UTC-11:00) Coordinated Universal Time-11',
		'utc': ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago'],
	},
	{
		'value': 'Hawaiian Standard Time',
		'abbr': 'HST',
		'offset': -10,
		'isdst': false,
		'text': '(UTC-10:00) Hawaii',
		'utc': ['Etc/GMT+10', 'Pacific/Honolulu', 'Pacific/Johnston', 'Pacific/Rarotonga', 'Pacific/Tahiti'],
	},
	{
		'value': 'Alaskan Standard Time',
		'abbr': 'AKDT',
		'offset': -8,
		'isdst': true,
		'text': '(UTC-09:00) Alaska',
		'utc': ['America/Anchorage', 'America/Juneau', 'America/Nome', 'America/Sitka', 'America/Yakutat'],
	},
	{
		'value': 'Pacific Standard Time (Mexico)',
		'abbr': 'PDT',
		'offset': -7,
		'isdst': true,
		'text': '(UTC-08:00) Baja California',
		'utc': ['America/Santa_Isabel'],
	},
	{
		'value': 'Pacific Daylight Time',
		'abbr': 'PDT',
		'offset': -7,
		'isdst': true,
		'text': '(UTC-07:00) Pacific Time (US & Canada)',
		'utc': ['America/Los_Angeles', 'America/Tijuana', 'America/Vancouver'],
	},
	{
		'value': 'Pacific Standard Time',
		'abbr': 'PST',
		'offset': -8,
		'isdst': false,
		'text': '(UTC-08:00) Pacific Time (US & Canada)',
		'utc': ['America/Los_Angeles', 'America/Tijuana', 'America/Vancouver', 'PST8PDT'],
	},
	{
		'value': 'US Mountain Standard Time',
		'abbr': 'UMST',
		'offset': -7,
		'isdst': false,
		'text': '(UTC-07:00) Arizona',
		'utc': [
			'America/Creston',
			'America/Dawson',
			'America/Dawson_Creek',
			'America/Hermosillo',
			'America/Phoenix',
			'America/Whitehorse',
			'Etc/GMT+7',
		],
	},
	{
		'value': 'Mountain Standard Time (Mexico)',
		'abbr': 'MDT',
		'offset': -6,
		'isdst': true,
		'text': '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
		'utc': ['America/Chihuahua', 'America/Mazatlan'],
	},
	{
		'value': 'Mountain Standard Time',
		'abbr': 'MDT',
		'offset': -6,
		'isdst': true,
		'text': '(UTC-07:00) Mountain Time (US & Canada)',
		'utc': [
			'America/Boise',
			'America/Cambridge_Bay',
			'America/Denver',
			'America/Edmonton',
			'America/Inuvik',
			'America/Ojinaga',
			'America/Yellowknife',
			'MST7MDT',
		],
	},
	{
		'value': 'Central America Standard Time',
		'abbr': 'CAST',
		'offset': -6,
		'isdst': false,
		'text': '(UTC-06:00) Central America',
		'utc': [
			'America/Belize',
			'America/Costa_Rica',
			'America/El_Salvador',
			'America/Guatemala',
			'America/Managua',
			'America/Tegucigalpa',
			'Etc/GMT+6',
			'Pacific/Galapagos',
		],
	},
	{
		'value': 'Central Standard Time',
		'abbr': 'CDT',
		'offset': -5,
		'isdst': true,
		'text': '(UTC-06:00) Central Time (US & Canada)',
		'utc': [
			'America/Chicago',
			'America/Indiana/Knox',
			'America/Indiana/Tell_City',
			'America/Matamoros',
			'America/Menominee',
			'America/North_Dakota/Beulah',
			'America/North_Dakota/Center',
			'America/North_Dakota/New_Salem',
			'America/Rainy_River',
			'America/Rankin_Inlet',
			'America/Resolute',
			'America/Winnipeg',
			'CST6CDT',
		],
	},
	{
		'value': 'Central Standard Time (Mexico)',
		'abbr': 'CDT',
		'offset': -5,
		'isdst': true,
		'text': '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
		'utc': ['America/Bahia_Banderas', 'America/Cancun', 'America/Merida', 'America/Mexico_City', 'America/Monterrey'],
	},
	{
		'value': 'Canada Central Standard Time',
		'abbr': 'CCST',
		'offset': -6,
		'isdst': false,
		'text': '(UTC-06:00) Saskatchewan',
		'utc': ['America/Regina', 'America/Swift_Current'],
	},
	{
		'value': 'SA Pacific Standard Time',
		'abbr': 'SPST',
		'offset': -5,
		'isdst': false,
		'text': '(UTC-05:00) Bogota, Lima, Quito',
		'utc': [
			'America/Bogota',
			'America/Cayman',
			'America/Coral_Harbour',
			'America/Eirunepe',
			'America/Guayaquil',
			'America/Jamaica',
			'America/Lima',
			'America/Panama',
			'America/Rio_Branco',
			'Etc/GMT+5',
		],
	},
	{
		'value': 'Eastern Standard Time',
		'abbr': 'EST',
		'offset': -5,
		'isdst': false,
		'text': '(UTC-05:00) Eastern Time (US & Canada)',
		'utc': [
			'America/Detroit',
			'America/Havana',
			'America/Indiana/Petersburg',
			'America/Indiana/Vincennes',
			'America/Indiana/Winamac',
			'America/Iqaluit',
			'America/Kentucky/Monticello',
			'America/Louisville',
			'America/Montreal',
			'America/Nassau',
			'America/New_York',
			'America/Nipigon',
			'America/Pangnirtung',
			'America/Port-au-Prince',
			'America/Thunder_Bay',
			'America/Toronto',
		],
	},
	{
		'value': 'Eastern Daylight Time',
		'abbr': 'EDT',
		'offset': -4,
		'isdst': true,
		'text': '(UTC-04:00) Eastern Daylight Time (US & Canada)',
		'utc': [
			'America/Detroit',
			'America/Havana',
			'America/Indiana/Petersburg',
			'America/Indiana/Vincennes',
			'America/Indiana/Winamac',
			'America/Iqaluit',
			'America/Kentucky/Monticello',
			'America/Louisville',
			'America/Montreal',
			'America/Nassau',
			'America/New_York',
			'America/Nipigon',
			'America/Pangnirtung',
			'America/Port-au-Prince',
			'America/Thunder_Bay',
			'America/Toronto',
		],
	},
	{
		'value': 'US Eastern Standard Time',
		'abbr': 'UEDT',
		'offset': -5,
		'isdst': false,
		'text': '(UTC-05:00) Indiana (East)',
		'utc': ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis'],
	},
	{
		'value': 'Venezuela Standard Time',
		'abbr': 'VST',
		'offset': -4.5,
		'isdst': false,
		'text': '(UTC-04:30) Caracas',
		'utc': ['America/Caracas'],
	},
	{
		'value': 'Paraguay Standard Time',
		'abbr': 'PYT',
		'offset': -4,
		'isdst': false,
		'text': '(UTC-04:00) Asuncion',
		'utc': ['America/Asuncion'],
	},
	{
		'value': 'Atlantic Standard Time',
		'abbr': 'ADT',
		'offset': -3,
		'isdst': true,
		'text': '(UTC-04:00) Atlantic Time (Canada)',
		'utc': ['America/Glace_Bay', 'America/Goose_Bay', 'America/Halifax', 'America/Moncton', 'America/Thule', 'Atlantic/Bermuda'],
	},
	{
		'value': 'Central Brazilian Standard Time',
		'abbr': 'CBST',
		'offset': -4,
		'isdst': false,
		'text': '(UTC-04:00) Cuiaba',
		'utc': ['America/Campo_Grande', 'America/Cuiaba'],
	},
	{
		'value': 'SA Western Standard Time',
		'abbr': 'SWST',
		'offset': -4,
		'isdst': false,
		'text': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
		'utc': [
			'America/Anguilla',
			'America/Antigua',
			'America/Aruba',
			'America/Barbados',
			'America/Blanc-Sablon',
			'America/Boa_Vista',
			'America/Curacao',
			'America/Dominica',
			'America/Grand_Turk',
			'America/Grenada',
			'America/Guadeloupe',
			'America/Guyana',
			'America/Kralendijk',
			'America/La_Paz',
			'America/Lower_Princes',
			'America/Manaus',
			'America/Marigot',
			'America/Martinique',
			'America/Montserrat',
			'America/Port_of_Spain',
			'America/Porto_Velho',
			'America/Puerto_Rico',
			'America/Santo_Domingo',
			'America/St_Barthelemy',
			'America/St_Kitts',
			'America/St_Lucia',
			'America/St_Thomas',
			'America/St_Vincent',
			'America/Tortola',
			'Etc/GMT+4',
		],
	},
	{
		'value': 'Pacific SA Standard Time',
		'abbr': 'PSST',
		'offset': -4,
		'isdst': false,
		'text': '(UTC-04:00) Santiago',
		'utc': ['America/Santiago', 'Antarctica/Palmer'],
	},
	{
		'value': 'Newfoundland Standard Time',
		'abbr': 'NDT',
		'offset': -2.5,
		'isdst': true,
		'text': '(UTC-03:30) Newfoundland',
		'utc': ['America/St_Johns'],
	},
	{
		'value': 'E. South America Standard Time',
		'abbr': 'ESAST',
		'offset': -3,
		'isdst': false,
		'text': '(UTC-03:00) Brasilia',
		'utc': ['America/Sao_Paulo'],
	},
	{
		'value': 'Argentina Standard Time',
		'abbr': 'AST',
		'offset': -3,
		'isdst': false,
		'text': '(UTC-03:00) Buenos Aires',
		'utc': [
			'America/Argentina/La_Rioja',
			'America/Argentina/Rio_Gallegos',
			'America/Argentina/Salta',
			'America/Argentina/San_Juan',
			'America/Argentina/San_Luis',
			'America/Argentina/Tucuman',
			'America/Argentina/Ushuaia',
			'America/Buenos_Aires',
			'America/Catamarca',
			'America/Cordoba',
			'America/Jujuy',
			'America/Mendoza',
		],
	},
	{
		'value': 'SA Eastern Standard Time',
		'abbr': 'SEST',
		'offset': -3,
		'isdst': false,
		'text': '(UTC-03:00) Cayenne, Fortaleza',
		'utc': [
			'America/Araguaina',
			'America/Belem',
			'America/Cayenne',
			'America/Fortaleza',
			'America/Maceio',
			'America/Paramaribo',
			'America/Recife',
			'America/Santarem',
			'Antarctica/Rothera',
			'Atlantic/Stanley',
			'Etc/GMT+3',
		],
	},
	{
		'value': 'Greenland Standard Time',
		'abbr': 'GDT',
		'offset': -3,
		'isdst': true,
		'text': '(UTC-03:00) Greenland',
		'utc': ['America/Godthab'],
	},
	{
		'value': 'Montevideo Standard Time',
		'abbr': 'MST',
		'offset': -3,
		'isdst': false,
		'text': '(UTC-03:00) Montevideo',
		'utc': ['America/Montevideo'],
	},
	{
		'value': 'Bahia Standard Time',
		'abbr': 'BST',
		'offset': -3,
		'isdst': false,
		'text': '(UTC-03:00) Salvador',
		'utc': ['America/Bahia'],
	},
	{
		'value': 'UTC-02',
		'abbr': 'U',
		'offset': -2,
		'isdst': false,
		'text': '(UTC-02:00) Coordinated Universal Time-02',
		'utc': ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
	},
	{
		'value': 'Mid-Atlantic Standard Time',
		'abbr': 'MDT',
		'offset': -1,
		'isdst': true,
		'text': '(UTC-02:00) Mid-Atlantic - Old',
		'utc': [],
	},
	{
		'value': 'Azores Standard Time',
		'abbr': 'ADT',
		'offset': 0,
		'isdst': true,
		'text': '(UTC-01:00) Azores',
		'utc': ['America/Scoresbysund', 'Atlantic/Azores'],
	},
	{
		'value': 'Cape Verde Standard Time',
		'abbr': 'CVST',
		'offset': -1,
		'isdst': false,
		'text': '(UTC-01:00) Cape Verde Is.',
		'utc': ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
	},
	{
		'value': 'Morocco Standard Time',
		'abbr': 'MDT',
		'offset': 1,
		'isdst': true,
		'text': '(UTC) Casablanca',
		'utc': ['Africa/Casablanca', 'Africa/El_Aaiun'],
	},
	{
		'value': 'UTC',
		'abbr': 'UTC',
		'offset': 0,
		'isdst': false,
		'text': '(UTC) Coordinated Universal Time',
		'utc': ['America/Danmarkshavn', 'Etc/GMT'],
	},
	{
		'value': 'GMT Standard Time',
		'abbr': 'GMT',
		'offset': 0,
		'isdst': false,
		'text': '(UTC) Edinburgh, London',
		'utc': ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
	},
	{
		'value': 'British Summer Time',
		'abbr': 'BST',
		'offset': 1,
		'isdst': true,
		'text': '(UTC+01:00) Edinburgh, London',
		'utc': ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
	},
	{
		'value': 'GMT Standard Time',
		'abbr': 'GDT',
		'offset': 1,
		'isdst': true,
		'text': '(UTC) Dublin, Lisbon',
		'utc': ['Atlantic/Canary', 'Atlantic/Faeroe', 'Atlantic/Madeira', 'Europe/Dublin', 'Europe/Lisbon'],
	},
	{
		'value': 'Greenwich Standard Time',
		'abbr': 'GST',
		'offset': 0,
		'isdst': false,
		'text': '(UTC) Monrovia, Reykjavik',
		'utc': [
			'Africa/Abidjan',
			'Africa/Accra',
			'Africa/Bamako',
			'Africa/Banjul',
			'Africa/Bissau',
			'Africa/Conakry',
			'Africa/Dakar',
			'Africa/Freetown',
			'Africa/Lome',
			'Africa/Monrovia',
			'Africa/Nouakchott',
			'Africa/Ouagadougou',
			'Africa/Sao_Tome',
			'Atlantic/Reykjavik',
			'Atlantic/St_Helena',
		],
	},
	{
		'value': 'W. Europe Standard Time',
		'abbr': 'WEDT',
		'offset': 2,
		'isdst': true,
		'text': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		'utc': [
			'Arctic/Longyearbyen',
			'Europe/Amsterdam',
			'Europe/Andorra',
			'Europe/Berlin',
			'Europe/Busingen',
			'Europe/Gibraltar',
			'Europe/Luxembourg',
			'Europe/Malta',
			'Europe/Monaco',
			'Europe/Oslo',
			'Europe/Rome',
			'Europe/San_Marino',
			'Europe/Stockholm',
			'Europe/Vaduz',
			'Europe/Vatican',
			'Europe/Vienna',
			'Europe/Zurich',
		],
	},
	{
		'value': 'Central Europe Standard Time',
		'abbr': 'CEDT',
		'offset': 2,
		'isdst': true,
		'text': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		'utc': [
			'Europe/Belgrade',
			'Europe/Bratislava',
			'Europe/Budapest',
			'Europe/Ljubljana',
			'Europe/Podgorica',
			'Europe/Prague',
			'Europe/Tirane',
		],
	},
	{
		'value': 'Romance Standard Time',
		'abbr': 'RDT',
		'offset': 2,
		'isdst': true,
		'text': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
		'utc': ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Europe/Paris'],
	},
	{
		'value': 'Central European Standard Time',
		'abbr': 'CEDT',
		'offset': 2,
		'isdst': true,
		'text': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
		'utc': ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb'],
	},
	{
		'value': 'W. Central Africa Standard Time',
		'abbr': 'WCAST',
		'offset': 1,
		'isdst': false,
		'text': '(UTC+01:00) West Central Africa',
		'utc': [
			'Africa/Algiers',
			'Africa/Bangui',
			'Africa/Brazzaville',
			'Africa/Douala',
			'Africa/Kinshasa',
			'Africa/Lagos',
			'Africa/Libreville',
			'Africa/Luanda',
			'Africa/Malabo',
			'Africa/Ndjamena',
			'Africa/Niamey',
			'Africa/Porto-Novo',
			'Africa/Tunis',
			'Etc/GMT-1',
		],
	},
	{
		'value': 'Namibia Standard Time',
		'abbr': 'NST',
		'offset': 1,
		'isdst': false,
		'text': '(UTC+01:00) Windhoek',
		'utc': ['Africa/Windhoek'],
	},
	{
		'value': 'GTB Standard Time',
		'abbr': 'GDT',
		'offset': 3,
		'isdst': true,
		'text': '(UTC+02:00) Athens, Bucharest',
		'utc': ['Asia/Nicosia', 'Europe/Athens', 'Europe/Bucharest', 'Europe/Chisinau'],
	},
	{
		'value': 'Middle East Standard Time',
		'abbr': 'MEDT',
		'offset': 3,
		'isdst': true,
		'text': '(UTC+02:00) Beirut',
		'utc': ['Asia/Beirut'],
	},
	{
		'value': 'Egypt Standard Time',
		'abbr': 'EST',
		'offset': 2,
		'isdst': false,
		'text': '(UTC+02:00) Cairo',
		'utc': ['Africa/Cairo'],
	},
	{
		'value': 'Syria Standard Time',
		'abbr': 'SDT',
		'offset': 3,
		'isdst': true,
		'text': '(UTC+02:00) Damascus',
		'utc': ['Asia/Damascus'],
	},
	{
		'value': 'E. Europe Standard Time',
		'abbr': 'EEDT',
		'offset': 3,
		'isdst': true,
		'text': '(UTC+02:00) E. Europe',
		'utc': [
			'Asia/Nicosia',
			'Europe/Athens',
			'Europe/Bucharest',
			'Europe/Chisinau',
			'Europe/Helsinki',
			'Europe/Kiev',
			'Europe/Mariehamn',
			'Europe/Nicosia',
			'Europe/Riga',
			'Europe/Sofia',
			'Europe/Tallinn',
			'Europe/Uzhgorod',
			'Europe/Vilnius',
			'Europe/Zaporozhye',
		],
	},
	{
		'value': 'South Africa Standard Time',
		'abbr': 'SAST',
		'offset': 2,
		'isdst': false,
		'text': '(UTC+02:00) Harare, Pretoria',
		'utc': [
			'Africa/Blantyre',
			'Africa/Bujumbura',
			'Africa/Gaborone',
			'Africa/Harare',
			'Africa/Johannesburg',
			'Africa/Kigali',
			'Africa/Lubumbashi',
			'Africa/Lusaka',
			'Africa/Maputo',
			'Africa/Maseru',
			'Africa/Mbabane',
			'Etc/GMT-2',
		],
	},
	{
		'value': 'FLE Standard Time',
		'abbr': 'FDT',
		'offset': 3,
		'isdst': true,
		'text': '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
		'utc': [
			'Europe/Helsinki',
			'Europe/Kiev',
			'Europe/Mariehamn',
			'Europe/Riga',
			'Europe/Sofia',
			'Europe/Tallinn',
			'Europe/Uzhgorod',
			'Europe/Vilnius',
			'Europe/Zaporozhye',
		],
	},
	{
		'value': 'Turkey Standard Time',
		'abbr': 'TDT',
		'offset': 3,
		'isdst': false,
		'text': '(UTC+03:00) Istanbul',
		'utc': ['Europe/Istanbul'],
	},
	{
		'value': 'Israel Standard Time',
		'abbr': 'JDT',
		'offset': 3,
		'isdst': true,
		'text': '(UTC+02:00) Jerusalem',
		'utc': ['Asia/Jerusalem'],
	},
	{
		'value': 'Libya Standard Time',
		'abbr': 'LST',
		'offset': 2,
		'isdst': false,
		'text': '(UTC+02:00) Tripoli',
		'utc': ['Africa/Tripoli'],
	},
	{
		'value': 'Jordan Standard Time',
		'abbr': 'JST',
		'offset': 3,
		'isdst': false,
		'text': '(UTC+03:00) Amman',
		'utc': ['Asia/Amman'],
	},
	{
		'value': 'Arabic Standard Time',
		'abbr': 'AST',
		'offset': 3,
		'isdst': false,
		'text': '(UTC+03:00) Baghdad',
		'utc': ['Asia/Baghdad'],
	},
	{
		'value': 'Kaliningrad Standard Time',
		'abbr': 'KST',
		'offset': 3,
		'isdst': false,
		'text': '(UTC+02:00) Kaliningrad',
		'utc': ['Europe/Kaliningrad'],
	},
	{
		'value': 'Arab Standard Time',
		'abbr': 'AST',
		'offset': 3,
		'isdst': false,
		'text': '(UTC+03:00) Kuwait, Riyadh',
		'utc': ['Asia/Aden', 'Asia/Bahrain', 'Asia/Kuwait', 'Asia/Qatar', 'Asia/Riyadh'],
	},
	{
		'value': 'E. Africa Standard Time',
		'abbr': 'EAST',
		'offset': 3,
		'isdst': false,
		'text': '(UTC+03:00) Nairobi',
		'utc': [
			'Africa/Addis_Ababa',
			'Africa/Asmera',
			'Africa/Dar_es_Salaam',
			'Africa/Djibouti',
			'Africa/Juba',
			'Africa/Kampala',
			'Africa/Khartoum',
			'Africa/Mogadishu',
			'Africa/Nairobi',
			'Antarctica/Syowa',
			'Etc/GMT-3',
			'Indian/Antananarivo',
			'Indian/Comoro',
			'Indian/Mayotte',
		],
	},
	{
		'value': 'Moscow Standard Time',
		'abbr': 'MSK',
		'offset': 3,
		'isdst': false,
		'text': '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
		'utc': ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd', 'Europe/Minsk'],
	},
	{
		'value': 'Samara Time',
		'abbr': 'SAMT',
		'offset': 4,
		'isdst': false,
		'text': '(UTC+04:00) Samara, Ulyanovsk, Saratov',
		'utc': ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk'],
	},
	{
		'value': 'Iran Standard Time',
		'abbr': 'IDT',
		'offset': 4.5,
		'isdst': true,
		'text': '(UTC+03:30) Tehran',
		'utc': ['Asia/Tehran'],
	},
	{
		'value': 'Arabian Standard Time',
		'abbr': 'AST',
		'offset': 4,
		'isdst': false,
		'text': '(UTC+04:00) Abu Dhabi, Muscat',
		'utc': ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
	},
	{
		'value': 'Azerbaijan Standard Time',
		'abbr': 'ADT',
		'offset': 5,
		'isdst': true,
		'text': '(UTC+04:00) Baku',
		'utc': ['Asia/Baku'],
	},
	{
		'value': 'Mauritius Standard Time',
		'abbr': 'MST',
		'offset': 4,
		'isdst': false,
		'text': '(UTC+04:00) Port Louis',
		'utc': ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
	},
	{
		'value': 'Georgian Standard Time',
		'abbr': 'GET',
		'offset': 4,
		'isdst': false,
		'text': '(UTC+04:00) Tbilisi',
		'utc': ['Asia/Tbilisi'],
	},
	{
		'value': 'Caucasus Standard Time',
		'abbr': 'CST',
		'offset': 4,
		'isdst': false,
		'text': '(UTC+04:00) Yerevan',
		'utc': ['Asia/Yerevan'],
	},
	{
		'value': 'Afghanistan Standard Time',
		'abbr': 'AST',
		'offset': 4.5,
		'isdst': false,
		'text': '(UTC+04:30) Kabul',
		'utc': ['Asia/Kabul'],
	},
	{
		'value': 'West Asia Standard Time',
		'abbr': 'WAST',
		'offset': 5,
		'isdst': false,
		'text': '(UTC+05:00) Ashgabat, Tashkent',
		'utc': [
			'Antarctica/Mawson',
			'Asia/Aqtau',
			'Asia/Aqtobe',
			'Asia/Ashgabat',
			'Asia/Dushanbe',
			'Asia/Oral',
			'Asia/Samarkand',
			'Asia/Tashkent',
			'Etc/GMT-5',
			'Indian/Kerguelen',
			'Indian/Maldives',
		],
	},
	{
		'value': 'Yekaterinburg Time',
		'abbr': 'YEKT',
		'offset': 5,
		'isdst': false,
		'text': '(UTC+05:00) Yekaterinburg',
		'utc': ['Asia/Yekaterinburg'],
	},
	{
		'value': 'Pakistan Standard Time',
		'abbr': 'PKT',
		'offset': 5,
		'isdst': false,
		'text': '(UTC+05:00) Islamabad, Karachi',
		'utc': ['Asia/Karachi'],
	},
	{
		'value': 'India Standard Time',
		'abbr': 'IST',
		'offset': 5.5,
		'isdst': false,
		'text': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
		'utc': ['Asia/Kolkata', 'Asia/Calcutta'],
	},
	{
		'value': 'Sri Lanka Standard Time',
		'abbr': 'SLST',
		'offset': 5.5,
		'isdst': false,
		'text': '(UTC+05:30) Sri Jayawardenepura',
		'utc': ['Asia/Colombo'],
	},
	{
		'value': 'Nepal Standard Time',
		'abbr': 'NST',
		'offset': 5.75,
		'isdst': false,
		'text': '(UTC+05:45) Kathmandu',
		'utc': ['Asia/Kathmandu'],
	},
	{
		'value': 'Central Asia Standard Time',
		'abbr': 'CAST',
		'offset': 6,
		'isdst': false,
		'text': '(UTC+06:00) Nur-Sultan (Astana)',
		'utc': ['Antarctica/Vostok', 'Asia/Almaty', 'Asia/Bishkek', 'Asia/Qyzylorda', 'Asia/Urumqi', 'Etc/GMT-6', 'Indian/Chagos'],
	},
	{
		'value': 'Bangladesh Standard Time',
		'abbr': 'BST',
		'offset': 6,
		'isdst': false,
		'text': '(UTC+06:00) Dhaka',
		'utc': ['Asia/Dhaka', 'Asia/Thimphu'],
	},
	{
		'value': 'Myanmar Standard Time',
		'abbr': 'MST',
		'offset': 6.5,
		'isdst': false,
		'text': '(UTC+06:30) Yangon (Rangoon)',
		'utc': ['Asia/Rangoon', 'Indian/Cocos'],
	},
	{
		'value': 'SE Asia Standard Time',
		'abbr': 'SAST',
		'offset': 7,
		'isdst': false,
		'text': '(UTC+07:00) Bangkok, Hanoi, Jakarta',
		'utc': [
			'Antarctica/Davis',
			'Asia/Bangkok',
			'Asia/Hovd',
			'Asia/Jakarta',
			'Asia/Phnom_Penh',
			'Asia/Pontianak',
			'Asia/Saigon',
			'Asia/Vientiane',
			'Etc/GMT-7',
			'Indian/Christmas',
		],
	},
	{
		'value': 'N. Central Asia Standard Time',
		'abbr': 'NCAST',
		'offset': 7,
		'isdst': false,
		'text': '(UTC+07:00) Novosibirsk',
		'utc': ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk'],
	},
	{
		'value': 'China Standard Time',
		'abbr': 'CST',
		'offset': 8,
		'isdst': false,
		'text': '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
		'utc': ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
	},
	{
		'value': 'North Asia Standard Time',
		'abbr': 'NAST',
		'offset': 8,
		'isdst': false,
		'text': '(UTC+08:00) Krasnoyarsk',
		'utc': ['Asia/Krasnoyarsk'],
	},
	{
		'value': 'Singapore Standard Time',
		'abbr': 'MPST',
		'offset': 8,
		'isdst': false,
		'text': '(UTC+08:00) Kuala Lumpur, Singapore',
		'utc': ['Asia/Brunei', 'Asia/Kuala_Lumpur', 'Asia/Kuching', 'Asia/Makassar', 'Asia/Manila', 'Asia/Singapore', 'Etc/GMT-8'],
	},
	{
		'value': 'W. Australia Standard Time',
		'abbr': 'WAST',
		'offset': 8,
		'isdst': false,
		'text': '(UTC+08:00) Perth',
		'utc': ['Antarctica/Casey', 'Australia/Perth'],
	},
	{
		'value': 'Taipei Standard Time',
		'abbr': 'TST',
		'offset': 8,
		'isdst': false,
		'text': '(UTC+08:00) Taipei',
		'utc': ['Asia/Taipei'],
	},
	{
		'value': 'Ulaanbaatar Standard Time',
		'abbr': 'UST',
		'offset': 8,
		'isdst': false,
		'text': '(UTC+08:00) Ulaanbaatar',
		'utc': ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
	},
	{
		'value': 'North Asia East Standard Time',
		'abbr': 'NAEST',
		'offset': 8,
		'isdst': false,
		'text': '(UTC+08:00) Irkutsk',
		'utc': ['Asia/Irkutsk'],
	},
	{
		'value': 'Japan Standard Time',
		'abbr': 'JST',
		'offset': 9,
		'isdst': false,
		'text': '(UTC+09:00) Osaka, Sapporo, Tokyo',
		'utc': ['Asia/Dili', 'Asia/Jayapura', 'Asia/Tokyo', 'Etc/GMT-9', 'Pacific/Palau'],
	},
	{
		'value': 'Korea Standard Time',
		'abbr': 'KST',
		'offset': 9,
		'isdst': false,
		'text': '(UTC+09:00) Seoul',
		'utc': ['Asia/Pyongyang', 'Asia/Seoul'],
	},
	{
		'value': 'Cen. Australia Standard Time',
		'abbr': 'CAST',
		'offset': 9.5,
		'isdst': false,
		'text': '(UTC+09:30) Adelaide',
		'utc': ['Australia/Adelaide', 'Australia/Broken_Hill'],
	},
	{
		'value': 'AUS Central Standard Time',
		'abbr': 'ACST',
		'offset': 9.5,
		'isdst': false,
		'text': '(UTC+09:30) Darwin',
		'utc': ['Australia/Darwin'],
	},
	{
		'value': 'E. Australia Standard Time',
		'abbr': 'EAST',
		'offset': 10,
		'isdst': false,
		'text': '(UTC+10:00) Brisbane',
		'utc': ['Australia/Brisbane', 'Australia/Lindeman'],
	},
	{
		'value': 'AUS Eastern Standard Time',
		'abbr': 'AEST',
		'offset': 10,
		'isdst': false,
		'text': '(UTC+10:00) Canberra, Melbourne, Sydney',
		'utc': ['Australia/Melbourne', 'Australia/Sydney'],
	},
	{
		'value': 'West Pacific Standard Time',
		'abbr': 'WPST',
		'offset': 10,
		'isdst': false,
		'text': '(UTC+10:00) Guam, Port Moresby',
		'utc': ['Antarctica/DumontDUrville', 'Etc/GMT-10', 'Pacific/Guam', 'Pacific/Port_Moresby', 'Pacific/Saipan', 'Pacific/Truk'],
	},
	{
		'value': 'Tasmania Standard Time',
		'abbr': 'TST',
		'offset': 10,
		'isdst': false,
		'text': '(UTC+10:00) Hobart',
		'utc': ['Australia/Currie', 'Australia/Hobart'],
	},
	{
		'value': 'Yakutsk Standard Time',
		'abbr': 'YST',
		'offset': 9,
		'isdst': false,
		'text': '(UTC+09:00) Yakutsk',
		'utc': ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
	},
	{
		'value': 'Central Pacific Standard Time',
		'abbr': 'CPST',
		'offset': 11,
		'isdst': false,
		'text': '(UTC+11:00) Solomon Is., New Caledonia',
		'utc': ['Antarctica/Macquarie', 'Etc/GMT-11', 'Pacific/Efate', 'Pacific/Guadalcanal', 'Pacific/Kosrae', 'Pacific/Noumea', 'Pacific/Ponape'],
	},
	{
		'value': 'Vladivostok Standard Time',
		'abbr': 'VST',
		'offset': 11,
		'isdst': false,
		'text': '(UTC+11:00) Vladivostok',
		'utc': ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok'],
	},
	{
		'value': 'New Zealand Standard Time',
		'abbr': 'NZST',
		'offset': 12,
		'isdst': false,
		'text': '(UTC+12:00) Auckland, Wellington',
		'utc': ['Antarctica/McMurdo', 'Pacific/Auckland'],
	},
	{
		'value': 'UTC+12',
		'abbr': 'U',
		'offset': 12,
		'isdst': false,
		'text': '(UTC+12:00) Coordinated Universal Time+12',
		'utc': [
			'Etc/GMT-12',
			'Pacific/Funafuti',
			'Pacific/Kwajalein',
			'Pacific/Majuro',
			'Pacific/Nauru',
			'Pacific/Tarawa',
			'Pacific/Wake',
			'Pacific/Wallis',
		],
	},
	{
		'value': 'Fiji Standard Time',
		'abbr': 'FST',
		'offset': 12,
		'isdst': false,
		'text': '(UTC+12:00) Fiji',
		'utc': ['Pacific/Fiji'],
	},
	{
		'value': 'Magadan Standard Time',
		'abbr': 'MST',
		'offset': 12,
		'isdst': false,
		'text': '(UTC+12:00) Magadan',
		'utc': ['Asia/Anadyr', 'Asia/Kamchatka', 'Asia/Magadan', 'Asia/Srednekolymsk'],
	},
	{
		'value': 'Kamchatka Standard Time',
		'abbr': 'KDT',
		'offset': 13,
		'isdst': true,
		'text': '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
		'utc': ['Asia/Kamchatka'],
	},
	{
		'value': 'Tonga Standard Time',
		'abbr': 'TST',
		'offset': 13,
		'isdst': false,
		'text': "(UTC+13:00) Nuku'alofa",
		'utc': ['Etc/GMT-13', 'Pacific/Enderbury', 'Pacific/Fakaofo', 'Pacific/Tongatapu'],
	},
	{
		'value': 'Samoa Standard Time',
		'abbr': 'SST',
		'offset': 13,
		'isdst': false,
		'text': '(UTC+13:00) Samoa',
		'utc': ['Pacific/Apia'],
	},
];
//another way too much calculation
// finding timezone /adddevent/participant

// let thedt = new Date()
// function findthis(dt: any){
//     let offset = dt.offset
//     let currenttimezoneoffset = thedt.getTimezoneOffset()
//     let contains = false
//     if(JSON.stringify(offset).includes('-')){
//         contains = true;
//         offset = Math.abs(offset)
//     }
//     if(JSON.stringify(currenttimezoneoffset).includes('-')){

//         currenttimezoneoffset = Math.abs(currenttimezoneoffset)
//     }
//     // console.log(dt.offset)
//     // console.log()
//     return offset*60 === currenttimezoneoffset

// }
// let ind = UTCtimezones.findIndex(findthis)
// console.log(ind)
// console.log(UTCtimezones[ind])

export const updatedtimezones = [
	{
		'value': 'Pacific/Midway',
		'label': '(GMT-11:00) Midway Island, Samoa',
		'offset': -11,
		'abbrev': 'SST',
		'altName': 'Samoa Standard Time',
	},
	{
		'value': 'Pacific/Honolulu',
		'label': '(GMT-10:00) Hawaii',
		'offset': -10,
		'abbrev': 'HAST',
		'altName': 'Hawaii-Aleutian Standard Time',
	},
	{
		'value': 'America/Juneau',
		'label': '(GMT-8:00) Alaska',
		'offset': -8,
		'abbrev': 'AKDT',
		'altName': 'Alaska Daylight Time',
	},
	{
		'value': 'America/Dawson',
		'label': '(GMT-7:00) Dawson, Yukon',
		'offset': -7,
	},
	{
		'value': 'America/Phoenix',
		'label': '(GMT-7:00) Arizona',
		'offset': -7,
		'abbrev': 'MST',
		'altName': 'Mountain Standard Time',
	},
	{
		'value': 'America/Tijuana',
		'label': '(GMT-7:00) Tijuana',
		'offset': -7,
		'abbrev': 'PDT',
		'altName': 'Pacific Daylight Time',
	},
	{
		'value': 'America/Los_Angeles',
		'label': '(GMT-7:00) Pacific Time',
		'offset': -7,
		'abbrev': 'PDT',
		'altName': 'Pacific Daylight Time',
	},
	{
		'value': 'America/Boise',
		'label': '(GMT-6:00) Mountain Time',
		'offset': -6,
		'abbrev': 'MDT',
		'altName': 'Mountain Daylight Time',
	},
	{
		'value': 'America/Chihuahua',
		'label': '(GMT-6:00) Chihuahua, La Paz, Mazatlan',
		'offset': -6,
		'abbrev': 'HEPMX',
		'altName': 'Mexican Pacific Daylight Time',
	},
	{
		'value': 'America/Regina',
		'label': '(GMT-6:00) Saskatchewan',
		'offset': -6,
		'abbrev': 'CST',
		'altName': 'Central Standard Time',
	},
	{
		'value': 'America/Belize',
		'label': '(GMT-6:00) Central America',
		'offset': -6,
		'abbrev': 'CST',
		'altName': 'Central Standard Time',
	},
	{
		'value': 'America/Chicago',
		'label': '(GMT-5:00) Central Time',
		'offset': -5,
		'abbrev': 'CDT',
		'altName': 'Central Daylight Time',
	},
	{
		'value': 'America/Mexico_City',
		'label': '(GMT-5:00) Guadalajara, Mexico City, Monterrey',
		'offset': -5,
		'abbrev': 'CDT',
		'altName': 'Central Daylight Time',
	},
	{
		'value': 'America/Bogota',
		'label': '(GMT-5:00) Bogota, Lima, Quito',
		'offset': -5,
		'abbrev': 'COT',
		'altName': 'Colombia Standard Time',
	},
	{
		'value': 'America/Detroit',
		'label': '(GMT-4:00) Eastern Time',
		'offset': -4,
		'abbrev': 'EDT',
		'altName': 'Eastern Daylight Time',
	},
	{
		'value': 'America/Caracas',
		'label': '(GMT-4:00) Caracas, La Paz',
		'offset': -4,
		'abbrev': 'VET',
		'altName': 'Venezuela Time',
	},
	{
		'value': 'America/Santiago',
		'label': '(GMT-4:00) Santiago',
		'offset': -4,
		'abbrev': 'CLT',
		'altName': 'Chile Standard Time',
	},
	{
		'value': 'America/Sao_Paulo',
		'label': '(GMT-3:00) Brasilia',
		'offset': -3,
		'abbrev': 'BRT',
		'altName': 'Brasilia Standard Time',
	},
	{
		'value': 'America/Montevideo',
		'label': '(GMT-3:00) Montevideo',
		'offset': -3,
		'abbrev': 'UYT',
		'altName': 'Uruguay Standard Time',
	},
	{
		'value': 'America/Argentina/Buenos_Aires',
		'label': '(GMT-3:00) Buenos Aires, Georgetown',
		'offset': -3,
		'abbrev': 'America/Argentina/Buenos_Aires',
		'altName': 'America/Argentina/Buenos_Aires',
	},
	{
		'value': 'America/St_Johns',
		'label': '(GMT-2:30) Newfoundland and Labrador',
		'offset': -2.5,
		'abbrev': 'HETN',
		'altName': 'Newfoundland Daylight Time',
	},
	{
		'value': 'America/Godthab',
		'label': '(GMT-2:00) Greenland',
		'offset': -2,
	},
	{
		'value': 'Atlantic/Cape_Verde',
		'label': '(GMT-1:00) Cape Verde Islands',
		'offset': -1,
		'abbrev': 'CVT',
		'altName': 'Cape Verde Standard Time',
	},
	{
		'value': 'Atlantic/Azores',
		'label': '(GMT+0:00) Azores',
		'offset': 0,
		'abbrev': 'AZOST',
		'altName': 'Azores Summer Time',
	},
	{
		'value': 'Etc/GMT',
		'label': '(GMT+0:00) UTC',
		'offset': 0,
		'abbrev': 'GMT',
		'altName': 'ETC/GMT',
	},
	{
		'value': 'Africa/Casablanca',
		'label': '(GMT+0:00) Casablanca, Monrovia',
		'offset': 0,
		'abbrev': 'WET',
		'altName': 'Western European Standard Time',
	},
	{
		'value': 'Europe/London',
		'label': '(GMT+1:00) Edinburgh, London',
		'offset': 1,
		'abbrev': 'BST',
		'altName': 'British Summer Time',
	},
	{
		'value': 'Europe/Dublin',
		'label': '(GMT+1:00) Dublin',
		'offset': 1,
		'abbrev': 'BST',
		'altName': 'British Summer Time',
	},
	{
		'value': 'Europe/Lisbon',
		'label': '(GMT+1:00) Lisbon',
		'offset': 1,
		'abbrev': 'WEST',
		'altName': 'Western European Summer Time',
	},
	{
		'value': 'Atlantic/Canary',
		'label': '(GMT+1:00) Canary Islands',
		'offset': 1,
		'abbrev': 'WEST',
		'altName': 'Western European Summer Time',
	},
	{
		'value': 'Africa/Algiers',
		'label': '(GMT+1:00) West Central Africa',
		'offset': 1,
		'abbrev': 'CET',
		'altName': 'Central European Standard Time',
	},
	{
		'value': 'Europe/Belgrade',
		'label': '(GMT+2:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
		'offset': 2,
		'abbrev': 'CEST',
		'altName': 'Central European Summer Time',
	},
	{
		'value': 'Europe/Sarajevo',
		'label': '(GMT+2:00) Sarajevo, Skopje, Warsaw, Zagreb',
		'offset': 2,
		'abbrev': 'CEST',
		'altName': 'Central European Summer Time',
	},
	{
		'value': 'Europe/Sarajevo',
		'label': '(GMT+2:00) Sarajevo, Skopje, Warsaw, Zagreb',
		'offset': 2,
		'abbrev': 'CEST',
		'altName': 'Central European Summer Time',
	},
	{
		'value': 'Europe/Amsterdam',
		'label': '(GMT+2:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
		'offset': 2,
		'abbrev': 'CEST',
		'altName': 'Central European Summer Time',
	},
	{
		'value': 'Africa/Cairo',
		'label': '(GMT+2:00) Cairo',
		'offset': 2,
		'abbrev': 'EET',
		'altName': 'Eastern European Standard Time',
	},
	{
		'value': 'Africa/Harare',
		'label': '(GMT+2:00) Harare, Pretoria',
		'offset': 2,
		'abbrev': 'CAT',
		'altName': 'Central Africa Time',
	},
	{
		'value': 'Europe/Bucharest',
		'label': '(GMT+3:00) Bucharest',
		'offset': 3,
		'abbrev': 'EEST',
		'altName': 'Eastern European Summer Time',
	},
	{
		'value': 'Europe/Helsinki',
		'label': '(GMT+3:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
		'offset': 3,
		'abbrev': 'EEST',
		'altName': 'Eastern European Summer Time',
	},
	{
		'value': 'Europe/Athens',
		'label': '(GMT+3:00) Athens, Minsk',
		'offset': 3,
		'abbrev': 'EEST',
		'altName': 'Eastern European Summer Time',
	},
	{
		'value': 'Asia/Jerusalem',
		'label': '(GMT+3:00) Jerusalem',
		'offset': 3,
		'altName': 'Israel Daylight Time',
	},
	{
		'value': 'Europe/Moscow',
		'label': '(GMT+3:00) Istanbul, Moscow, St. Petersburg, Volgograd',
		'offset': 3,
		'abbrev': 'MSK',
		'altName': 'Moscow Standard Time',
	},
	{
		'value': 'Asia/Kuwait',
		'label': '(GMT+3:00) Kuwait, Riyadh',
		'offset': 3,
		'abbrev': 'AST',
		'altName': 'Arabian Standard Time',
	},
	{
		'value': 'Africa/Nairobi',
		'label': '(GMT+3:00) Nairobi',
		'offset': 3,
		'abbrev': 'EAT',
		'altName': 'East Africa Time',
	},
	{
		'value': 'Asia/Baghdad',
		'label': '(GMT+3:00) Baghdad',
		'offset': 3,
		'abbrev': 'AST',
		'altName': 'Arabian Standard Time',
	},
	{
		'value': 'Asia/Dubai',
		'label': '(GMT+4:00) Abu Dhabi, Muscat',
		'offset': 4,
		'abbrev': 'GST',
		'altName': 'Gulf Standard Time',
	},
	{
		'value': 'Asia/Tehran',
		'label': '(GMT+4:30) Tehran',
		'offset': 4.5,
		'abbrev': 'IRDT',
		'altName': 'Iran Daylight Time',
	},
	{
		'value': 'Asia/Kabul',
		'label': '(GMT+4:30) Kabul',
		'offset': 4.5,
		'abbrev': 'AFT',
		'altName': 'Afghanistan Time',
	},
	{
		'value': 'Asia/Baku',
		'label': '(GMT+5:00) Baku, Tbilisi, Yerevan',
		'offset': 5,
		'abbrev': 'AZT',
		'altName': 'Azerbaijan Standard Time',
	},
	{
		'value': 'Asia/Yekaterinburg',
		'label': '(GMT+5:00) Ekaterinburg',
		'offset': 5,
		'abbrev': 'YEKT',
		'altName': 'Yekaterinburg Standard Time',
	},
	{
		'value': 'Asia/Karachi',
		'label': '(GMT+5:00) Islamabad, Karachi, Tashkent',
		'offset': 5,
		'abbrev': 'PKT',
		'altName': 'Pakistan Standard Time',
	},
	{
		'value': 'Asia/Kolkata',
		'label': '(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi',
		'offset': 5.5,
		'abbrev': 'IST',
		'altName': 'India Standard Time',
	},
	{
		'value': 'Asia/Colombo',
		'label': '(GMT+5:30) Sri Jayawardenepura',
		'offset': 5.5,
		'abbrev': 'IST',
		'altName': 'India Standard Time',
	},
	{
		'value': 'Asia/Kathmandu',
		'label': '(GMT+5:45) Kathmandu',
		'offset': 5.75,
		'abbrev': 'UTC+5.75',
		'altName': 'Kathmandu Time',
	},
	{
		'value': 'Asia/Dhaka',
		'label': '(GMT+6:00) Astana, Dhaka',
		'offset': 6,
		'abbrev': 'BST',
		'altName': 'Bangladesh Standard Time',
	},
	{
		'value': 'Asia/Almaty',
		'label': '(GMT+6:00) Almaty, Novosibirsk',
		'offset': 6,
		'abbrev': 'ALMT',
		'altName': 'East Kazakhstan Time',
	},
	{
		'value': 'Asia/Rangoon',
		'label': '(GMT+6:30) Yangon Rangoon',
		'offset': 6.5,
		'abbrev': 'Asia/Yangon',
		'altName': 'Asia/Yangon',
	},
	{
		'value': 'Asia/Bangkok',
		'label': '(GMT+7:00) Bangkok, Hanoi, Jakarta',
		'offset': 7,
		'abbrev': 'ICT',
		'altName': 'Indochina Time',
	},
	{
		'value': 'Asia/Krasnoyarsk',
		'label': '(GMT+7:00) Krasnoyarsk',
		'offset': 7,
		'abbrev': 'KRAT',
		'altName': 'Krasnoyarsk Standard Time',
	},
	{
		'value': 'Asia/Shanghai',
		'label': '(GMT+8:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
		'offset': 8,
		'abbrev': 'CST',
		'altName': 'China Standard Time',
	},
	{
		'value': 'Asia/Kuala_Lumpur',
		'label': '(GMT+8:00) Kuala Lumpur, Singapore',
		'offset': 8,
		'abbrev': 'MYT',
		'altName': 'Malaysia Time',
	},
	{
		'value': 'Asia/Taipei',
		'label': '(GMT+8:00) Taipei',
		'offset': 8,
		'abbrev': 'CST',
		'altName': 'Taipei Standard Time',
	},
	{
		'value': 'Australia/Perth',
		'label': '(GMT+8:00) Perth',
		'offset': 8,
		'abbrev': 'AWST',
		'altName': 'Australian Western Standard Time',
	},
	{
		'value': 'Asia/Irkutsk',
		'label': '(GMT+8:00) Irkutsk, Ulaanbaatar',
		'offset': 8,
		'abbrev': 'IRKT',
		'altName': 'Irkutsk Standard Time',
	},
	{
		'value': 'Asia/Seoul',
		'label': '(GMT+9:00) Seoul',
		'offset': 9,
		'abbrev': 'KST',
		'altName': 'Korean Standard Time',
	},
	{
		'value': 'Asia/Tokyo',
		'label': '(GMT+9:00) Osaka, Sapporo, Tokyo',
		'offset': 9,
		'abbrev': 'JST',
		'altName': 'Japan Standard Time',
	},
	{
		'value': 'Australia/Darwin',
		'label': '(GMT+9:30) Darwin',
		'offset': 9.5,
		'abbrev': 'ACST',
		'altName': 'Australian Central Standard Time',
	},
	{
		'value': 'Australia/Adelaide',
		'label': '(GMT+9:30) Adelaide',
		'offset': 9.5,
		'abbrev': 'ACST',
		'altName': 'Australian Central Standard Time',
	},
	{
		'value': 'Asia/Yakutsk',
		'label': '(GMT+10:00) Yakutsk',
		'offset': 10,
		'abbrev': 'YAKT',
		'altName': 'Yakutsk Standard Time',
	},
	{
		'value': 'Australia/Sydney',
		'label': '(GMT+10:00) Canberra, Melbourne, Sydney',
		'offset': 10,
		'abbrev': 'AEST',
		'altName': 'Australian Eastern Standard Time',
	},
	{
		'value': 'Australia/Brisbane',
		'label': '(GMT+10:00) Brisbane',
		'offset': 10,
		'abbrev': 'AEST',
		'altName': 'Australian Eastern Standard Time',
	},
	{
		'value': 'Australia/Hobart',
		'label': '(GMT+10:00) Hobart',
		'offset': 10,
		'abbrev': 'AEST',
		'altName': 'Australian Eastern Standard Time',
	},
	{
		'value': 'Asia/Vladivostok',
		'label': '(GMT+10:00) Vladivostok',
		'offset': 10,
		'abbrev': 'VLAT',
		'altName': 'Vladivostok Standard Time',
	},
	{
		'value': 'Pacific/Guam',
		'label': '(GMT+10:00) Guam, Port Moresby',
		'offset': 10,
		'abbrev': 'ChST',
		'altName': 'Chamorro Standard Time',
	},
	{
		'value': 'Asia/Magadan',
		'label': '(GMT+11:00) Magadan, Solomon Islands, New Caledonia',
		'offset': 11,
		'abbrev': 'MAGT',
		'altName': 'Magadan Standard Time',
	},
	{
		'value': 'Asia/Kamchatka',
		'label': '(GMT+12:00) Kamchatka, Marshall Islands',
		'offset': 12,
		'abbrev': 'PETT',
		'altName': 'Petropavlovsk-Kamchatski Standard Time',
	},
];

export const ProfanityFilter = [
	'anus',
	'arse',
	'arsehole',
	'ass',
	'ass-hat',
	'ass-jabber',
	'ass-pirate',
	'assbag',
	'assbandit',
	'assbanger',
	'assbite',
	'assclown',
	'asscock',
	'asscracker',
	'asses',
	'assface',
	'assfuck',
	'assfucker',
	'assgoblin',
	'asshat',
	'asshead',
	'asshole',
	'asshopper',
	'assjacker',
	'asslick',
	'asslicker',
	'assmonkey',
	'assmunch',
	'assmuncher',
	'assnigger',
	'asspirate',
	'assshit',
	'assshole',
	'asssucker',
	'asswad',
	'asswipe',
	'axwound',
	'bampot',
	'bastard',
	'beaner',
	'bitch',
	'bitchass',
	'bitches',
	'bitchtits',
	'bitchy',
	'blow job',
	'blowjob',
	'bollocks',
	'bollox',
	'boner',
	'brotherfucker',
	'bullshit',
	'bumblefuck',
	'butt plug',
	'butt-pirate',
	'buttfucka',
	'buttfucker',
	'camel toe',
	'carpetmuncher',
	'chesticle',
	'chinc',
	'chink',
	'choad',
	'chode',
	'clit',
	'clitface',
	'clitfuck',
	'clitweasel',
	'clusterfuck',
	'cock',
	'cockass',
	'cockbite',
	'cockburger',
	'cockface',
	'cockfucker',
	'cockhead',
	'cockjockey',
	'cockknoker',
	'cockmaster',
	'cockmongler',
	'cockmongruel',
	'cockmonkey',
	'cockmuncher',
	'cocknose',
	'cocknugget',
	'cockshit',
	'cocksmith',
	'cocksmoke',
	'cocksmoker',
	'cocksniffer',
	'cocksucker',
	'cockwaffle',
	'coochie',
	'coochy',
	'coon',
	'cooter',
	'cracker',
	'cum',
	'cumbubble',
	'cumdumpster',
	'cumguzzler',
	'cumjockey',
	'cumslut',
	'cumtart',
	'cunnie',
	'cunnilingus',
	'cunt',
	'cuntass',
	'cuntface',
	'cunthole',
	'cuntlicker',
	'cuntrag',
	'cuntslut',
	'dago',
	'damn',
	'deggo',
	'dick',
	'dick-sneeze',
	'dickbag',
	'dickbeaters',
	'dickface',
	'dickfuck',
	'dickfucker',
	'dickhead',
	'dickhole',
	'dickjuice',
	'dickmilk',
	'dickmonger',
	'dicks',
	'dickslap',
	'dicksucker',
	'dicksucking',
	'dicktickler',
	'dickwad',
	'dickweasel',
	'dickweed',
	'dickwod',
	'dike',
	'dildo',
	'dipshit',
	'docking',
	'doochbag',
	'dookie',
	'douche',
	'douche-fag',
	'douchebag',
	'douchewaffle',
	'dumass',
	'dumb ass',
	'dumbass',
	'dumbfuck',
	'dumbshit',
	'dumshit',
	'dyke',
	'fag',
	'fagbag',
	'fagfucker',
	'faggit',
	'faggot',
	'faggotcock',
	'fagnut',
	'fagtard',
	'fatass',
	'fellatio',
	'feltch',
	'flamer',
	'fuck',
	'fuckass',
	'fuckbag',
	'fuckboy',
	'fuckbrain',
	'fuckbutt',
	'fuckbutter',
	'fucked',
	'fucker',
	'fuckersucker',
	'fuckface',
	'fuckhead',
	'fuckhole',
	'fuckin',
	'fucking',
	'fucknose',
	'fucknut',
	'fucknutt',
	'fuckoff',
	'fucks',
	'fuckstick',
	'fucktard',
	'fucktart',
	'fuckup',
	'fuckwad',
	'fuckwit',
	'fuckwitt',
	'fudgepacker',
	'gay',
	'gayass',
	'gaybob',
	'gaydo',
	'gayfuck',
	'gayfuckist',
	'gaylord',
	'gaytard',
	'gaywad',
	'goddamn',
	'goddamnit',
	'gooch',
	'gook',
	'gringo',
	'guido',
	'handjob',
	'hard on',
	'heeb',
	'hell',
	'ho',
	'hoe',
	'homo',
	'homodumbshit',
	'honkey',
	'humping',
	'jackass',
	'jagoff',
	'jap',
	'jerk off',
	'jerkass',
	'jigaboo',
	'jizz',
	'jungle bunny',
	'junglebunny',
	'kike',
	'kooch',
	'kootch',
	'kraut',
	'kunt',
	'kyke',
	'lameass',
	'lardass',
	'lesbian',
	'lesbo',
	'lezzie',
	'masturbate',
	'mcfagget',
	'mick',
	'minge',
	'mothafucka',
	'mothafuckin',
	'motherfucker',
	'motherfucking',
	'muff',
	'muffdiver',
	'munging',
	'nazi',
	'negro',
	'nigaboo',
	'nigga',
	'nigger',
	'niggerish',
	'niggers',
	'niglet',
	'nignog',
	'nut sack',
	'nutsack',
	'paki',
	'panooch',
	'pecker',
	'peckerhead',
	'penis',
	'penisbanger',
	'penisfucker',
	'penispuffer',
	'piss',
	'pissed',
	'pissed off',
	'pissflaps',
	'polesmoker',
	'pollock',
	'poon',
	'poonani',
	'poonany',
	'poontang',
	'porch monkey',
	'porchmonkey',
	'prick',
	'punanny',
	'punta',
	'pussies',
	'pussy',
	'pussylicking',
	'puto',
	'queef',
	'queer',
	'queerbait',
	'queerhole',
	'renob',
	'rimjob',
	'ruski',
	'sand nigger',
	'sandnigger',
	'schlong',
	'scrote',
	'shit',
	'shitass',
	'shitbag',
	'shitbagger',
	'shitbrains',
	'shitbreath',
	'shitcanned',
	'shitcunt',
	'shitdick',
	'shitface',
	'shitfaced',
	'shithead',
	'shithole',
	'shithouse',
	'shitspitter',
	'shitstain',
	'shitter',
	'shittiest',
	'shitting',
	'shitty',
	'shiz',
	'shiznit',
	'skank',
	'skeet',
	'skullfuck',
	'slut',
	'slutbag',
	'smeg',
	'snatch',
	'spic',
	'spick',
	'splooge',
	'spook',
	'suckass',
	'tard',
	'testicle',
	'thundercunt',
	'tit',
	'titfuck',
	'tits',
	'tittyfuck',
	'twat',
	'twatlips',
	'twats',
	'twatwaffle',
	'uglyfuck',
	'unclefucker',
	'va-j-j',
	'vag',
	'vagina',
	'vajayjay',
	'vjayjay',
	'wank',
	'wankjob',
	'wetback',
	'whore',
	'whorebag',
	'whoreface',
	'wop',
];

export function Filter(comment: string) {
	let words = comment;
	console.log(comment);
	let splitted = comment.split(' ');
	console.log(splitted);
	const notneeded = splitted.map((item: any) => {
		if (ProfanityFilter.includes(item.toLowerCase())) {
			let found = item;
			let len = item.length;
			let star = '*';
			let stars = star.repeat(len);
			let replace = words.replace(found, stars);
			words = replace;
		}
		return null;
	});
	console.log(notneeded);
	//assigned to noteneeded cause of warning
	console.log(words);
	return words;
}

export const WordCorrection = (word: string) => {
	var re = /^[a-zA-Z0-9_.]+$/;
	return re.test(word);
};

export function Checkformat(str: any) {
	const n = 3;
	let format = str.slice(str.length - n);
	if (format === 'peg') {
		format = str.slice(str.length - 4);
	}
	let r = '';
	const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'GIF', 'jpeg', 'gif'];
	const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'MOV', 'mov', 'AVI', 'avi'];

	if (photoformats.includes(format)) {
		r = 'photo';
	} else if (videoformats.includes(format)) {
		r = 'video';
	} else {
		r = 'unknown';
	}
	return r;
}

export const StartToEnd = (start_date: any, end_date: any) => {
	// let start_date = "2022-11-04T01:20:00Z"
	// let end_date = "2022-11-05T13:20:00Z"
	let interval;
	// let endsup = 'st'
	// let startsup = 'rd'
	if (dayjs(start_date).format('YYYY') === dayjs(end_date).format('YYYY')) {
		if (dayjs(start_date).format('MMM') === dayjs(end_date).format('MMM')) {
			if (dayjs(start_date).format('DD') === dayjs(end_date).format('DD')) {
				interval = dayjs(start_date).local().format('hh:mm A') + ' - ' + dayjs(end_date).local().format('hh:mm A DD MMM YYYY');
			} else {
				interval = dayjs(start_date).local().format('DD ddd hh:mm A') + ' - ' + dayjs(end_date).local().format('hh:mm A DD ddd MMM YYYY');
			}
		} else {
			interval = dayjs(start_date).local().format('DD MMM hh:mm A') + ' - ' + dayjs(end_date).local().format('hh:mm A DD MMM YYYY');
		}
	} else {
		interval = dayjs(start_date).local().format('DD MMM YYYY hh:mm A') + ' - ' + dayjs(end_date).local().format('hh:mm A DD MMM YYYY');
	}
	return interval;
};

export const UTCtoLocal = (time : string) => {
	return dayjs.utc(time).local()

}


var options = {
	enableHighAccuracy: true,
	timeout: 5000,
	maximumAge: 0,
};

export const Getlocation = (dispatch: any) => {
	let countryName = '';
	let cityname = '';
	let lat = '';
	let lng = '';
	// let Glat = '';
	// let Glng = '';

	var unknown: any = '-';
	//browser
	var nVer = navigator.appVersion;
	var nAgt = navigator.userAgent;
	var browser = navigator.appName;
	var version = '' + parseFloat(navigator.appVersion);
	var majorVersion = parseInt(navigator.appVersion, 10);
	var nameOffset, verOffset, ix;

	// Opera
	if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
		browser = 'Opera';
		version = nAgt.substring(verOffset + 6);
		if ((verOffset = nAgt.indexOf('Version')) !== -1) {
			version = nAgt.substring(verOffset + 8);
		}
	}
	// MSIE
	else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
		browser = 'Microsoft Internet Explorer';
		version = nAgt.substring(verOffset + 5);
	}
	// Chrome
	else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
		browser = 'Chrome';
		version = nAgt.substring(verOffset + 7);
	}
	// Safari
	else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
		browser = 'Safari';
		version = nAgt.substring(verOffset + 7);
		if ((verOffset = nAgt.indexOf('Version')) !== -1) {
			version = nAgt.substring(verOffset + 8);
		}
	}
	// Firefox
	else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
		browser = 'Firefox';
		version = nAgt.substring(verOffset + 8);
	}
	// MSIE 11+
	else if (nAgt.indexOf('Trident/') !== -1) {
		browser = 'Microsoft Internet Explorer';
		version = nAgt.substring(nAgt.indexOf('rv:') + 3);
	}
	// Other browsers
	else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
		browser = nAgt.substring(nameOffset, verOffset);
		version = nAgt.substring(verOffset + 1);
		if (browser.toLowerCase() === browser.toUpperCase()) {
			browser = navigator.appName;
		}
	}
	// trim the version string
	if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
	if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
	if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

	majorVersion = parseInt('' + version, 10);
	if (isNaN(majorVersion)) {
		version = '' + parseFloat(navigator.appVersion);
		majorVersion = parseInt(navigator.appVersion, 10);
	}

	// mobile version
	var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

	// cookie
	var cookieEnabled = navigator.cookieEnabled ? true : false;

	if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
		document.cookie = 'testcookie';
		cookieEnabled = document.cookie.indexOf('testcookie') !== -1 ? true : false;
	}
	// system
	var os = unknown;
	var clientStrings = [
		{ s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
		{ s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
		{ s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
		{ s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
		{ s: 'Windows Vista', r: /Windows NT 6.0/ },
		{ s: 'Windows Server 2003', r: /Windows NT 5.2/ },
		{ s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
		{ s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
		{ s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
		{ s: 'Windows 98', r: /(Windows 98|Win98)/ },
		{ s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
		{ s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
		{ s: 'Windows CE', r: /Windows CE/ },
		{ s: 'Windows 3.11', r: /Win16/ },
		{ s: 'Android', r: /Android/ },
		{ s: 'Open BSD', r: /OpenBSD/ },
		{ s: 'Sun OS', r: /SunOS/ },
		{ s: 'Linux', r: /(Linux|X11)/ },
		{ s: 'iOS', r: /(iPhone|iPad|iPod)/ },
		{ s: 'Mac OS X', r: /Mac OS X/ },
		{ s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
		{ s: 'QNX', r: /QNX/ },
		{ s: 'UNIX', r: /UNIX/ },
		{ s: 'BeOS', r: /BeOS/ },
		{ s: 'OS/2', r: /OS\/2/ },
		{ s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
	];
	for (var id in clientStrings) {
		var cs = clientStrings[id];
		if (cs.r.test(nAgt)) {
			os = cs.s;
			break;
		}
	}

	var osVersion: any = '';

	// if (/Windows/.test(os)) {
	//     let o: any = /Windows (.*)/.exec(os)
	//     osVersion = o[1];
	//     os = 'Windows';
	// } else if (os === 'Mac OS X') {
	//     let o: any = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)
	//     osVersion = o[1]
	// } else if (os === 'Android') {
	//     let o: any = /Android ([\.\_\d]+)/.exec(nAgt)
	//     osVersion = o[1];
	// } else if ('iOS') {
	//     osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
	//     osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
	// }

	let jscd: any = {
		browser: browser,
		browserVersion: version,
		mobile: mobile,
		os: os,
		osVersion: osVersion,
		cookies: cookieEnabled,
	};
	console.log(jscd);

	var navigator_info = window.navigator;
	var screen_info = window.screen;
	var uid: any = navigator_info.mimeTypes.length;
	uid += navigator_info.userAgent.replace(/\D+/g, '');
	uid += navigator_info.plugins.length;
	uid += screen_info.height || '';
	uid += screen_info.width || '';
	uid += screen_info.pixelDepth || '';
	let sys: any = navigator;
	const userAgentData = sys.userAgentData;
	let platform = 'windows';
	if (userAgentData) {
		platform = userAgentData.platform;
	} else {
		platform = osName;
	}

	axios.get('https://ipapi.co/json/', {
		transformRequest: (data, headers) => {
			delete headers.common['Authorization'];
			return data;
		},
	})
		.then((response) => {
			let data = response.data;
			console.log(data);
			countryName = data.country_name;
			cityname = data.city;
			lat = data.latitude;
			lng = data.longitude;

			dispatch(
				profileuserlocation({
					lat: lat,
					lng: lng,
					city: cityname,
					country: countryName,
					uuid: uid,
					os: platform,
					osversion: osVersion,
					browsername: platform + browser + version,
				})
			);
			console.log({
				lat: lat,
				lng: lng,
				city: cityname,
				country: countryName,
				uuid: uid,
				os: platform,
				osversion: osVersion,
				browsername: platform + browser + version,
			});
		})
		.catch((error) => {
			console.log(error);
		});
	if (navigator?.geolocation) {
		navigator.geolocation.getCurrentPosition(
			(pos: any) => {
				var crd = pos.coords;
				// Glat = crd.latitude;
				// Glng = crd.longitude;

				console.log('Your current position is:');
				console.log(`Latitude : ${crd.latitude}`);
				console.log(`Longitude: ${crd.longitude}`);
				console.log(`More or less ${crd.accuracy} meters.`);

				dispatch(
					profileuserlocation({
						lat: crd.latitude,
						lng: crd.longitude,
						city: cityname,
						country: countryName,
						uuid: uid,
						os: platform,
						osversion: osVersion,
						browsername: platform + browser + version,
					})
				);
				console.log({
					lat: lat,
					lng: lng,
					city: cityname,
					country: countryName,
					uuid: uid,
					os: platform,
					osversion: osVersion,
					browsername: platform + browser + version,
				});
			},
			(err: any) => {
				console.warn(`ERROR(${err.code}): ${err.message}`);
			},
			options
		);
	} else {
		console.warn('Geolocation is not supported by the browser');
		dispatch(
			profileuserlocation({
				lat: lat,
				lng: lng,
				city: cityname,
				country: countryName,
				uuid: uid,
				os: platform,
				osversion: osVersion,
				browsername: platform + browser + version,
			})
		);
		console.log({
			lat: lat,
			lng: lng,
			city: cityname,
			country: countryName,
			uuid: uid,
			os: platform,
			osversion: osVersion,
			browsername: platform + browser + version,
		});
	}
};

export function HandleTime(date: any) {
	const then = new Date(date).getTime();
	const now = new Date().getTime();
	const gap = now - then;

	const second = 1000;
	const minute = second * 60;
	const hour = minute * 60;
	const day = hour * 24;

	const d = Math.floor(gap / day);
	const h = Math.floor((gap % day) / hour);
	const m = Math.floor((gap % hour) / minute);
	const s = Math.floor((gap % minute) / second);
	return d !== 0
		? d + (d === 1 ? ' day ago' : ' days ago')
		: d === 0 && h !== 0
		? h + (h === 1 ? ' hour ago' : ' hrs ago')
		: d === 0 && h !== 0 && m !== 0
		? m + (m === 1 ? ' min ago' : ' mins ago')
		: s + (s === 1 ? ' second ago' : ' seconds ago');
}
