import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as Thumb } from '../../../assets/svg/thumbup.svg';
import { useHistory } from 'react-router';
import './eventshare.css'
import { AiFillCopy, AiOutlineCopy, AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookSquare, FaTelegram } from 'react-icons/fa'
import { ImLinkedin } from 'react-icons/im'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
} from "react-share";
import Api from '../../../app/api';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
    const top = 48;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 700,
        minHeight: 350,
        height: 500,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 8, 3),
    },
}));

export default function SimpleModal({ open, setOpen, data, type }: { open: any, setOpen: any, data: any, type: string }) {
    const history = useHistory()
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    //   const [open, setOpen] = React.useState(false);
    const [copy, setcopy] = useState(false);
    const { id, event_image,
        name, slug } = data;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const URL = process.env.REACT_APP_SHARE_URL || ''
    // const URL = `https://staging-app.mutualevents.com`
    // const URL = `https://app.mutualevents.com`;
    // const URL = `https://app.mutualevents.com`;
    // const pathnow = window.location.pathname;
    const currentURL = type === 'event' ? URL + `/event/${slug}`
        : type === 'feed' ? URL + `/feed/${slug}` : '';


    // const success = (data: any) => {

    //     console.log(data)
    // }
    // const failed = (data: any) => {
    //     console.log(data)

    // }
    // useEffect(() => {
    //     Api('get',`/event/b100b3bd-8736-4bc2-a24d-c18559609675/`,null,success,failed,true,true)
    // }, [])
    const body = (
        <div style={modalStyle} className={classes.paper} >
            <div className='ShareWrapper'>
                <img alt='Event cover image' src={event_image[0].image}></img>
                <div className='shareContent'>
                    <div className='noData'>
                        {/* <Warning /> */}
                        <h2>{name}</h2>
                        <span>Now share it with your friends on social media</span>
                        <div className="NewEventCreatedSocialDiv">
                            <FacebookShareButton url={currentURL} quote={`Checkout this event - ${name}.\njoin us in Mutual Events now`}  ><FaFacebookSquare /></FacebookShareButton>
                            <TelegramShareButton url={currentURL} title={`Checkout this event - ${name}.\njoin us in Mutual Events now`}  ><FaTelegram /></TelegramShareButton>
                            <TwitterShareButton url={currentURL} title={name} via={'join us in Mutual Events now'}><AiOutlineTwitter /></TwitterShareButton>
                            <LinkedinShareButton url={currentURL} title={`Checkout this event - ${name}.\njoin us in Mutual Events now`}  ><ImLinkedin /></LinkedinShareButton>
                        </div>
                        <span>or copy the link and share it</span>
                        <div className='shareInputDiv'>
                            <input
                                type="text"
                                value={currentURL}
                                className="shareinput"
                            />
                            <div className={copy ? 'copyDiv copyDivactive' : 'copyDiv copyDivInactive'} onClick={() => {
                                navigator.clipboard.writeText(currentURL);
                                setcopy(true);
                            }}>{copy ? <AiOutlineCopy /> : <AiFillCopy />}</div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            {body}
        </Modal>
    );
}
