import React from 'react'
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import { ReactComponent as Attending } from '../../../../assets/svg/attending.svg';
import { ReactComponent as Posts } from '../../../../assets/svg/posts.svg';
import { ReactComponent as Views } from '../../../../assets/svg/views.svg';
import { ReactComponent as Dat } from '../../../../assets/svg/eventdate.svg';
import { ReactComponent as Pin } from '../../../../assets/svg/chat/chatpin.svg';
import { MdSchedule } from 'react-icons/md'
import { ReactComponent as Star1 } from '../../../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../../../assets/svg/star2.svg';
import Rating from 'react-rating'





const ContentEventitemLoader = () => {
    return (
        <div className='cm_postitem'>
            <div className='cm_postitem_info'>

                <div className='cm_postitem_event cursor'>
                <div style={{height:'32px',width:'32px',borderRadius:'8px'}} className='skeleton'></div>
                    <div className='cm_postitem_detail'>
                    <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                        <small className='skeleton minhe10' style={{ minWidth:'120px',maxWidth: '50px',display:'block',marginTop:'5px' }}> </small>
                    </div>
                </div>
                <div className="FeedItemDropdown">
                    <span className='dots'>
                        <More />
                    </span>

                </div>
            </div>
            <div className='cm_postitem_container'>
                <div className='cm_postitem_imgcontainer skeleton' style={{minHeight:'40vh'}}>
                    {/* <img src={data.event_image[0].image} onClick={() => {
                        setActive(true)
                    }} /> */}
                </div>

            </div>
            <div className='cm_eventitem_details'>
                <div className='cm_eventitem_heading'>
                    <h3 className='skeleton minhe10' style={{ maxWidth: '200px',minWidth:'70px',width:'100%',maxHeight:'20px' }}></h3>
                    <div className='ratingpreview'>
                        <div className="eventUsrSub" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}>
                        </span>
                            <Rating
                                initialRating={0}
                                emptySymbol={<Star2 />}
                                fullSymbol={<Star1 />}
                                readonly
                            />
                        </div>
                    </div>
                </div>
                <div className='cm_eventitem_more' style={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr 1fr'}}>
                    {/* <div className=''></div> */}
                    <div className='cm_contentitem_user' style={{ margin: '0', alignItems: 'center' }}>
                    <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                    <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>
                    </div>
                    <div className='cm_eventitem_moreitem'>
                        <Pin style={{ stroke: 'black' }} />
                        <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px',height:'10px',width:'100%' }}></span>
                    </div>
                    <div className='cm_eventitem_moreitem'>
                        <Dat />
                        <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px',height:'10px',width:'100%' }}></span>
                    </div>
                    <div className='cm_eventitem_moreitem'>
                        <MdSchedule />
                        <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px',height:'10px',width:'100%' }}></span>
                    </div>
                </div>
                <p className='skeleton minhe10' style={{ minWidth: '50px'}}></p>
                <div className='cm_eventitem_eventinf'>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <div className='eventTabIcon' style={{ height: '32px', width: '32px' }}>
                                <Posts />
                            </div>
                            <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <div className='eventTabIcon' style={{ height: '32px', width: '32px' }}>
                                <Views />
                            </div>
                            <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <div className='eventTabIcon' style={{ height: '32px', width: '32px' }}>
                                <Attending />
                            </div>
                            <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>


                        </div>
                    </div>
                    <button className='mainBtnInv cursor skeleton' style={{ maxWidth: '120px',minWidth:'90px',maxHeight:'40px' }} >
                        
                    </button>
                </div>
            </div>

        </div>
    )
}

export default ContentEventitemLoader