import React from 'react'
import { useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';

const PasswordStrength = ({ password, setpassword, type, setsignupvalidate, signupvalidate, setscore }: {
    password: any, setpassword: any, type: any, setsignupvalidate: any, signupvalidate: any, setscore: any
}) => {
    return (
        <div className='passwordcomponent'>
            <div className="signupLabelDiv">
                <label>{'Password'}</label>
                <small className={signupvalidate === '*required' ? "validateerror signuppasswordvalidate" : "Signupvalidate signuppasswordvalidate"}>
                    {signupvalidate}
                </small>
            </div>

            <input
                style={{ backgroundColor: 'var(--cl_lightgrey)' }}
                type="password"
                placeholder="Password"
                className="credentials signupPassword"
                required
            value={password}
            onChange={(e) => {
                if(signupvalidate === '*required'){
                    setsignupvalidate(null)
                }
                setpassword(e.currentTarget.value)
                // setSignuppassword(e.currentTarget.value);
                // if (e.currentTarget.value !== null) {
                //     if (
                //         e.currentTarget.previousSibling.lastChild.classList.contains(
                //             "validateerror"
                //         )
                //     ) {
                //         e.currentTarget.previousSibling.lastChild.classList.remove(
                //             "validateerror"
                //         );
                //     }
                // }
            }}
            ></input>
            <PasswordStrengthBar password={password} minLength={6} onChangeScore={(score: any, feedback: any) => { console.log(score); setscore(score) }} />
        </div>
    )
}

export default PasswordStrength
