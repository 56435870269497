import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

// const ProtectedRoute = ({ isAuth, Component, ...rest }: any) => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, [Component])
//     console.log(isAuth)
//     return (
//         // isAuth? <Route {...rest} component={Component}/>: <Redirect to= '/login' />
//         <Route {...rest} render={(props: any) => {
//             isAuth ? <Component {...rest} /> : <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
//         }} />
//     )
// }

// export default ProtectedRoute

// import React, { useEffect } from 'react'
// import {Route,Redirect} from 'react-router-dom'

function ProtectedRoute({isAuth,component: Component,...rest}: any) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [Component])
    return (
        <Route {...rest} render={(props)=>{
            if(isAuth){
                return <Component {...rest}/>
            }else {
                return <Redirect to={{pathname:'/login',
                 state: {from: props.location}
            }}/>
            }
        }}/>
    )
}

export default ProtectedRoute
