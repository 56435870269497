import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
//components
import NotificationDropdown from '../components/topbar/notification/NotificationDropdown';
import SettingsDropdown from '../components/topbar/settings/SettingsDropdown';

import { AiOutlineMenuUnfold } from 'react-icons/ai'
import { ReactComponent as Search } from '../assets/svg/mainsearch.svg';
import { ReactComponent as Beta } from '../assets/svg/beta.svg';
// import Logo from '../../assets/logo/logomargin.png'
import Logo from '../assets/logo/mainlogo2x.png'
import logom from '../assets/carousel/logom.png'
import { ReactComponent as Settings } from '../assets/svg/settings.svg';
import { ReactComponent as Bell } from '../assets/svg/bell.svg';
import { ReactComponent as SearchWl } from '../assets/svg/searchwl1.svg';

// import { ReactComponent as Logo } from '../../assets/logo/mainlogo.svg';
import landing from '../assets/images/landing.png'
import Api from '../app/api';
import '../components/topbar/topbar.css'
import { useAppDispatch, useAppSelector } from '../app/hooks';
// import { topSearchEventsRequest, topSearchEventsFailed, topSearchEventsSuccess, topSearchPeopleFailed, topSearchPeopleRequest, topSearchPeopleSuccess } from '../pages/topbarsearch/store/TopSearchSlice';
import { clearAdd } from '../components/Tabs/addevent/store/addEventSlice';
import Searchdropdown from '../components/topbar/Searchdropdown';
import LoginPopup from './components/loginwl_popup';
// import { getMessaging, onMessage } from "firebase/messaging";
// import { onBackgroundMessage } from "firebase/messaging/sw";


// declare global {
//     interface Window {
//       registration?: any;
//     }
//   }
const Topbar: React.FC = () => {

    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     // ...
    // });

    // onBackgroundMessage(messaging, (payload) => {
    //     console.log('[firebase-messaging-sw.js] Received background message ', payload);
    //     // Customize notification here
    //     const notificationTitle = 'Background Message Title';
    //     const notificationOptions = {
    //       body: 'Background Message body.',
    //       icon: '/firebase-logo.png'
    //     };
    //     // serviceWorkerRegistration = self.registration
    //     self.registration.showNotification(notificationTitle,
    //       notificationOptions);
    //   });


    const history = useHistory();
    const dispatch = useAppDispatch()
    // const lat = '10.8505159'
    // const lng = '76.2710833'
    const [isActive, setIsActive] = useState(false);
    const [open3, setOpen3] = useState(false)
    const [war3, setWar3] = useState(null)
    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (isActive && targt && !targt.contains(e.target)) {
                setIsActive(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive])




    return (
        <div className='topBar'>
            <div className='topBarContainer'>
                <div className='topBarLeft'>
                    {/* <div className='logochanged'>
                        <img alt='Mutual Events' src={landing} />
                        <h3><span>Mutual</span><span>Events</span></h3>

                    </div> */}
                    <div className='logoMain' onClick={() => history.push('/')}>
                        <Beta />
                        {/* <Logo /> */}


                        <img alt='Logo' src={logom || 'https://d20rv3nuwgisca.cloudfront.net/general/logo/mutualevents-minimal-logo.png'} />
                    </div>
                    {/* <Searchdropdown /> */}

                </div>

                <div className='topBarRight'>
                    <div className='mainTabscontainer' >
                        <div className='menubtn' ref={vis} onClick={() => {
                            setIsActive(!isActive)
                        }}><AiOutlineMenuUnfold /></div>
                        <div className={`mainTabs ${isActive ? 'active' : 'inactive'}`} >
                            <span></span>
                            <NavLink className='links navLink' exact to="/" activeClassName="navLinkActive">
                                <SearchWl />
                            </NavLink>
                            <NavLink className='links navLink' onClick={() => { setOpen3(true) }} to="#" activeClassName="navLinkinActive">
                                Browse
                            </NavLink>
                            <NavLink className='links navLink' to="/feedwl" activeClassName="navLinkActive">
                                Feed
                            </NavLink>
                            <NavLink className='links navLink' onClick={() => { setOpen3(true) }} to="#" activeClassName="navLinkinActive">
                                Events
                            </NavLink>
                            <NavLink className='links navLink' onClick={() => { setOpen3(true) }} to="#" activeClassName="navLinkinActive">
                                Profile
                            </NavLink>
                        </div>

                    </div>
                    <div className='dropdowns'>
                        <div className='settingsicon'>
                            {/* <SettingsDropdown /> */}
                            <Settings onClick={() => {
                                setOpen3(true)
                            }} />
                        </div>
                        <div className='bellcontainer'>
                            <div className='dropdownContainer bell'>
                                <Bell onClick={() => {
                                    setOpen3(true)
                                }} />                        </div>

                        </div>

                    </div>
                    <div className='navBtn hidden'>
                        <NavLink to="#" className='mainBtn' activeClassName="mainBtnActive" onClick={(e) => {
                            dispatch(clearAdd())
                            setOpen3(true)
                            //for testing something remove it

                            // e.preventDefault()
                            // e.stopPropagation()

                            // var config: any = {
                            //     method: 'get',
                            //     url: 'https://51c046c6-73be-447a-a38c-d999464b1b85.mutualevents.co/api/v1/permissions/content-moderation/super-admin/download/list/46/',
                            //     headers: {
                            //         'Authorization': 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjUwNzEzNTAyLCJqdGkiOiI1NTk4OTg3NmJkMTk0ZTIwOTQyNDk1MmE0YjY2NTdmMyIsInVzZXJfaWQiOjUwfQ.P9attexj4fYTqm65vjSxgC-MRQdQkWjLm7jAH4unIUg'
                            //     },
                            //     responseType: 'blob'
                            // };
                            // axios(config)
                            //     .then((res: any) => {
                            //         console.log(res.data)
                            //         const url = window.URL.createObjectURL(new Blob([res.data]));
                            //         const link = document.createElement('a');
                            //         link.href = url;
                            //         link.setAttribute('download', `46.csv`); //or any other extension
                            //         document.body.appendChild(link);
                            //         link.click()

                            //     })
                            //     .catch((error: any) => {
                            //         // const errorMsg = error.message
                            //         console.log(error)
                            //     });
                        }}>Add event</NavLink>

                    </div>

                    {/* <div className='navBtn md:hidden !tw-max-w-min'>
                        <NavLink to='/login' style={{ backgroundColor: "var(--cl_light_orange)", color: "var(--cl_orange)" }} className='mainBtn' activeClassName="mainBtnActive" onClick={(e) => {
                            dispatch(clearAdd())

                        }}> Sign in </NavLink>

                    </div> */}
                    <div className='navBtn hidden md:block '>
                        <NavLink to='/login' style={{ backgroundColor: "var(--cl_light_orange)", color: "var(--cl_orange)" }} className='mainBtn' activeClassName="mainBtnActive" onClick={(e) => {
                            dispatch(clearAdd())

                        }}> Sign up / Log in </NavLink>

                    </div>
                </div>
            </div>
            {open3 &&
                <LoginPopup open3={open3} setOpen3={setOpen3} war={war3} />
            }
        </div>
    )
}

export default Topbar
