import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

const NavRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <div className='nav__route__container'>
      <h2 className='nav__route__header'>
        Header
      </h2>
      <div className='nav__route__wrapper'>
        {/* <NavLink to={path + '/manage_subscription'} className="setting_nav_item" activeClassName="setting_nav_item nav_active" >Subscription plans</NavLink> */}

        <NavLink className="nav__routes__links" activeClassName="nav__active" to={path} exact>
          Home
        </NavLink>
        <NavLink className="nav__routes__links" activeClassName="nav__active" to={path + '/payment'}>
          Payment
        </NavLink>
        <NavLink className="nav__routes__links" activeClassName="nav__active" to={path + '/reports'}>
          Reports
        </NavLink>
        <NavLink className="nav__routes__links" activeClassName="nav__active" to={path + '/payouts'}>
          Payout
        </NavLink>

      </div>

    </div>
  )
}

export default NavRoutes