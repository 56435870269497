import React from 'react'

function UserEventsDetail() {
    return (
        <div>


        </div>
    )
}

export default UserEventsDetail