import React,{useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg'
import { ReactComponent as NotFound } from '../../../../assets/svg/development.svg';
import BuyEventtickets from './BuyEventtickets';

const EventTickets = () => {
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    
}, [])
  return (
    <div className='container minvh100'>
      <div className='commonHeading'>
        <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
        <h3>Tickets</h3>

      </div>
      {/* <div className='error min500px'>
        <div className='errormsg'>
          <NotFound />
          <h2>Under development</h2>
        </div>
      </div> */}
      {/* <BuyEventtickets /> */}
    </div>
  )
}

export default EventTickets